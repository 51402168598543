import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';

import withStyles from '@material-ui/core/styles/withStyles';

// HN this is the session data related for the user 
import { AuthUserContext } from '../Session';
//import {GetExternalRecommendation} from '../Modules/GetExternalRecommendation';
import ContentItemCard, {ContentProgramOverwrite} from '../Content/ContentItemCard';
import Masonry from 'react-masonry-css';

import CircularProgress from '@material-ui/core/CircularProgress';

import {AddEngagementPoints} from '../Modules/AddEngagementPoints';
import Skeleton from '@material-ui/lab/Skeleton';
//HN added thumbs up
import BookMarkVote from './BookMarkVote';
import pixel from "../../images/pixel.png";
import ContentCommentHighlight from './ContentCommentHighlight';
import DialogContentItem from '../Content/DialogContentItem';
import {AvatarCard} from '../Modules/AvatarImage';
import AvatarImage from '../Modules/AvatarImage';

import { IsMobileDevice } from '../Modules/IsMobileDevice';
import { FindDictByKey, FindTextValueInDict } from '../Modules/LoadCategoriesAndQualities';

//import Button from '@material-ui/core/Button';
import VizSensor from 'react-visibility-sensor';

import LoadingProgress from '../Modules/LoadingProgress';

//import CancelIcon from '@material-ui/icons/Cancel';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
//import CardActions from '@material-ui/core/CardActions';
//import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from "@material-ui/core/CardMedia";
//import {FixedSizeList, FixedSizeGrid as GridWindow } from 'react-window';
import { List as ListVirtual, WindowScroller, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import SwipeableViews from 'react-swipeable-views';
import SwipeStepper, {customWidthStyle, customWidthSlideStyle} from '../Modules/SwipeStepper';
// use react-window instead
import Grid from '@material-ui/core/Grid';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

import * as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';
//const VisibilitySensor = require('react-visibility-sensor');

// Help
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';

function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function delay(milliseconds){
    return new Promise(resolve => {
        setTimeout(resolve, milliseconds);
    });
}

const styles = theme => ({
    masonry:{
        width: "100%",
        display: "-webkit-box",
        display: '-ms-flexbox',
        display: "flex",
        paddingRight: 10
    },
    masonryColumn :{
        paddingLeft: 10,
        backgroundClip: "padding-box",
    },
    grow: {
        flexGrow: 1,
    },
    root: {
        height: '100%',
    },
    error: {
        color: theme.palette.themeRed,
        marginTop: 20,
    },
});

class HomeCardContentItems extends Component {
    // HN added in as it was used as this in Goalvoerview.js
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        // HN check if a quality is set
        //this.usersCurrentLeadershipQuality = this.context.currentLeadershipQuality;
        //if (this.usersCurrentLeadershipQuality === '') {
        //  this.usersCurrentLeadershipQuality = 'focus';
        //}
        var myQuality = '';
        var limit = 20;
        //console.log('my props contentimtes', this.props)

        if (props.qualityFilter !== undefined && props.qualityFilter !== '') {
            myQuality = props.qualityFilter;
        }
        else {
            myQuality = props.user?.currentLeadershipQuality || 'default';
        }
        if (props.visualLimit && props.visualLimit > 0) {
            limit = props.visualLimit
        }
        var allQualitiesLoaded = [];
        if (this.props.allQualitiesLoaded !== undefined && this.props.allQualitiesLoaded.length > 0) {
            allQualitiesLoaded = this.props.allQualitiesLoaded
        }

        this.state = {
            loading: true,
            loadingText: 'loading content',
            open: false,
            tips: [],
            noTips: false,
            refKey : "myscroller",
            contentEngagementRating: 1,
            tipsCounter: 0,
            // HN try to add this variable
            //userLeadershipQuality: this.props.user.currentLeadershipQuality,
            dialogueData: [],
            contentIds: [],
            allQualitiesLoaded: allQualitiesLoaded,
            currentLeadershipQuality: myQuality,
            arrayPos: 0,
            limit: limit,
            activeStep: 0,
        };
        //console.log("init", myQuality)
    }

    componentDidMount() {
        this._isMounted = true;
        //console.log('mountesd', this.props);
        this.setState({ loading: true });
        //this.mounted = true;
        if(this.state.tipsCounter < 1){
        //console.log("fired", this.state.tipsCounter, this.state.currentLeadershipQuality)
            this.chooseSearch(this.props.specialQuery, this.state.currentLeadershipQuality)
        };
    }

    chooseSearch(specialQuery, qualityFilter) {
        let tips = [];
        var randomId = makeid(2);
        if (specialQuery !== undefined && specialQuery === 'randomQuotes') {
            //console.log('id',randomId)
            this.loadContentFromFirestore(qualityFilter,['quotesrandomup', 'quotesrandomdown'], tips, 10, randomId);
            //this.loadContentFromFirestore(this.state.currentLeadershipQuality, 'quotesrandomup', tips, 10, randomId);
            //this.loadContentFromFirestore(this.state.currentLeadershipQuality, 'quotesrandomdown', tips, 10, randomId);
        }
        else if (specialQuery !== undefined && specialQuery === 'contentByIds') {
            this.loadContentFromFirestore(this.props.qualityFilter, 'contentByIds', tips, this.state.limit, randomId);
        }
        else if (specialQuery !== undefined && specialQuery === 'bookmarks' && this.props.user.contentBookmarked !== undefined) {
            //console.log('bookmarks',this.props.user.contentBookmarked)
            this.loadContentFromFirestore(this.props.user.contentBookmarked,
                ['contentRandomUpArray','contentRandomDownArray'],
                tips, this.state.limit, randomId);
        }
        else if (specialQuery !== undefined && specialQuery === 'randomTipsByUtterance') {
            //console.log('id',randomId)
            this.loadContentFromFirestore(qualityFilter, ['tipsrandomup','tipsrandomdown'], tips, 10, randomId);
        }
        else if (specialQuery !== undefined && specialQuery === 'tipsSave') {
            //console.log('id',randomId)
            this.loadContentFromFirestore(qualityFilter, ['tips', 'category'], tips, this.state.limit , randomId);
        }
        else if (specialQuery === undefined || specialQuery === '') {
            if(this.props.extraTopics===false && this.props.orderByLikes){
                this.loadContentFromFirestore(qualityFilter,
                    [ 'tips'],
                    tips, this.state.limit / 2, randomId);
            }
            else if(this.props.extraTopics===false){
                this.loadContentFromFirestore(qualityFilter,
                    [ 'tipsrandomup', 'tipsrandomdown'],
                    tips, this.state.limit / 2, randomId);
            }
            else if(this.props.orderByLikes){
                this.loadContentFromFirestore(qualityFilter,
                    ['tips', 'category'],
                    tips, this.state.limit / 2, randomId);
            }
            else if(false && this.props.user.engagementCounter!== undefined && this.props.user.engagementCounter>=15){
                this.loadContentFromFirestore(qualityFilter,
                    [ 'tipsrandomup', 'tipsrandomdown', 'category', 'quotesrandomup', 'quotesrandomdown'],
                    tips, this.state.limit / 2, randomId);
            }
            else{
                this.loadContentFromFirestore(qualityFilter,
                    [ 'tipsrandomup', 'tipsrandomdown', 'category'],
                    tips, this.state.limit / 2, randomId);
            }
        
        //this.loadContentFromFirestore(this.state.currentLeadershipQuality, 'tipsrandomup', tips, this.state.limit / 2, randomId);
        //this.setState({ loading: false });
        //this.loadContentFromFirestore(this.state.currentLeadershipQuality, 'tips', tips, this.state.limit);
        //this.loadContentFromFirestore(this.state.currentLeadershipQuality, 'quotesrandomup', tips, this.state.limit / 2, randomId);
        //this.loadContentFromFirestore(this.state.currentLeadershipQuality, 'quotesrandomdown', tips, this.state.limit, randomId);
        //this.loadContentFromFirestore(this.state.currentLeadershipQuality, 'tipsrandomdown', tips, this.state.limit * 2, randomId);

        }
    }

    // this function detects if the leadership quality has changed so that it can reload the skill ttips
    shouldComponentUpdate(nextProps, nextState) { 
        if (nextProps.rerender === false) {  
            //console.log('no rerender')
            return false;  
        }  
        else if (this.props.allowUpdate === undefined || this.props.allowUpdate === true) {
            if (this._isMounted && nextProps.user?.currentLeadershipQuality !== undefined
                && nextProps.qualityFilter !== this.state.currentLeadershipQuality
                && (nextProps.specialQuery !== undefined && nextProps.specialQuery !== 'bookmarks') ) {
                //console.log('forced update',nextProps, this.state.currentLeadershipQuality);
                
                //this.setState({ currentLeadershipQuality: nextProps.user.currentLeadershipQuality });
                let tips = [];
                var randomId = makeid(2);
                //console.log('updatec',nextProps);
                //console.log('next',nextProps.user.currentLeadershipQuality);
                //console.log('current',this.state.currentLeadershipQuality);

                var myCat = '';
                if (this.props.specialQuery !== undefined && this.props.specialQuery === 'tipsSave') {
                this.setState({ tipsCounter: 0, tips: tips, contentIds: [], currentLeadershipQuality: nextProps.qualityFilter },
                    () => {
                    this.chooseSearch(this.props.specialQuery, nextProps.qualityFilter);
                    });
                }
                else if (nextProps.specialQuery !== undefined && nextProps.specialQuery === 'bookmarks' && nextProps.user.contentBookmarked !== undefined) {
                //console.log('bookmarksupdate',nextProps.user.contentBookmarked)
                this.setState({ tips: tips, tipsCounter: 0, contentIds: [] });
                this.loadContentFromFirestore(nextProps.user?.contentBookmarked, 'contentRandomUpArray', tips, this.state.limit, randomId);
                this.loadContentFromFirestore(nextProps.user?.contentBookmarked, 'contentRandomDownArray', tips, this.state.limit, randomId);
                }
                else if ((nextProps.specialQuery === undefined || nextProps.specialQuery === '') && nextProps.qualityFilter !== this.state.currentLeadershipQuality){
                    this.setState({ currentLeadershipQuality: nextProps.qualityFilter });
                    this.setState({ tips: tips, tipsCounter: 0 });
                    this.loadContentFromFirestore(nextProps.qualityFilter, 'tipsrandomup', tips, this.state.limit / 2, randomId);
                    this.loadContentFromFirestore(nextProps.qualityFilter, 'tips', tips, this.state.limit);
                    this.loadContentFromFirestore(nextProps.qualityFilter, 'tipsrandomdown', tips, this.state.limit * 2, randomId);
                    if (this.props.leadershipQualities !== undefined && this.props.leadershipQualities.length > 0) {
                        myCat = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, nextProps.qualityFilter, 'category');
                        this.loadContentFromFirestore(myCat, 'category', tips, this.state.limit * 2);
                    }
                    if(this.props.user?.engagementCounter!== undefined && this.props.user?.engagementCounter>=15){
                        this.loadContentFromFirestore(nextProps.qualityFilter, 'quotesrandomup', tips, this.state.limit / 2, randomId);
                        this.loadContentFromFirestore(nextProps.qualityFilter, 'quotesrandomdown', tips, this.state.limit, randomId);
                    }
                }
            }
            return true;
        }
        return true;
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.unsubscribeBookmark !== undefined && typeof this.unsubscribeBookmark === "function" ) {
            this.unsubscribeBookmark();
        }
        if (this.unsubscribe !== undefined && typeof this.unsubscribe === "function") {
            this.unsubscribe();
        }
        this.setState({
            loading: false,
            tipsCounter: 0,
            dialogueData: [],
            contentIds: [],
            tips: []
        });
    }

    async loadContentFromFirestore(leadershipQualitySearchTerm, type, tempArray, limits, randomId = '0', pointer=0) {
        //console.log('search type limits',limits);
        var diagnosticFlag = false;
        if ( this.state.tipsCounter <= this.state.limit) {
            var contentIds = this.state.contentIds;
            var selectionType = "tips";
            var searching = '';
            if (type instanceof Array) {
                selectionType = type[pointer];
            }
            else if(selectionType !== undefined) {
                selectionType = type;
            }
            //console.log('search topic',selectionType, "'"+leadershipQualitySearchTerm+"'", this.props);
            var db = undefined;
            if (selectionType === "tips") {
                searching = 'loading voted tips';
                db = this.props.firebase.contents()
                .where('category', '==', 'tips')
                .where('leadership_quality', 'array-contains', leadershipQualitySearchTerm)
                .orderBy('contentVotes', 'desc')
            }
            else if (selectionType === "tipsrandomup") {
                searching = 'loading more tips';
                db = this.props.firebase.contents()
                .where('category', '==', 'tips')
                .where('leadership_quality', 'array-contains', leadershipQualitySearchTerm)
                .where(this.props.firebase.fieldPath.documentId(), '>=', randomId)
            }
            else if (selectionType === "tipsrandomdown") {
                searching = 'loading some more tips';
                db = this.props.firebase.contents()
                .where('category', '==', 'tips')
                .where('leadership_quality', 'array-contains', leadershipQualitySearchTerm)
                .where(this.props.firebase.fieldPath.documentId(), '<=', randomId)
            }
            else if (selectionType === "quotes") {
                searching = 'loading quotes';
                db = this.props.firebase.contents()
                .where('category', '==', 'quotes')
                .where('leadership_quality', 'array-contains', leadershipQualitySearchTerm)
                .orderBy('contentVotes', 'desc')
            }
            else if (selectionType === "quotesrandomup") {
                searching = 'loading more quotes';
                db = this.props.firebase.contents()
                .where('category', '==', 'quotes')
                .where('leadership_quality', 'array-contains', leadershipQualitySearchTerm)
                .where(this.props.firebase.fieldPath.documentId(), '>=', randomId)
            }
            else if (selectionType === "quotesrandomdown") {
                searching = 'loading some more quotes';
                db = this.props.firebase.contents()
                .where('category', '==', 'quotes')
                .where('leadership_quality', 'array-contains', leadershipQualitySearchTerm)
                .where(this.props.firebase.fieldPath.documentId(), '<=', randomId)
            }
            else if (selectionType === "quotesnewest") {
                searching = 'loading newest quotes';
                db = this.props.firebase.contents()
                .where('category', '==', 'quotes')
                .where('leadership_quality', 'array-contains', leadershipQualitySearchTerm)
                .orderBy('createdAt', 'desc')
            }
            else if (selectionType === "contentRandomUpArray") {
                searching = 'loading bookmarks';
                //console.log('bookmarks up search',leadershipQualitySearchTerm)

                leadershipQualitySearchTerm=leadershipQualitySearchTerm.slice(0,9);
                db = this.props.firebase.contents()
                .where(this.props.firebase.fieldPath.documentId(), 'in', leadershipQualitySearchTerm)
                .where(this.props.firebase.fieldPath.documentId(), '>=', randomId)
            }
            else if (selectionType === "contentRandomDownArray") {
                searching = 'loading more bookmarks';
                //console.log('bookmarks search',leadershipQualitySearchTerm)
                leadershipQualitySearchTerm=leadershipQualitySearchTerm.slice(0,9);

                db = this.props.firebase.contents()
                .where(this.props.firebase.fieldPath.documentId(), 'in', leadershipQualitySearchTerm)
                .where(this.props.firebase.fieldPath.documentId(), '<=', randomId)
            }
            else if (selectionType === "contentByIds") {
                //console.log('bookmarks search',leadershipQualitySearchTerm)
                leadershipQualitySearchTerm=leadershipQualitySearchTerm.slice(0,9);
                //leadershipQualitySearchTerm=(leadershipQualitySearchTerm?.slice(0,9) || ["wqertyu"]);
                searching = 'loading favorites';
                db = this.props.firebase.contents()
                .where(this.props.firebase.fieldPath.documentId(), 'in', leadershipQualitySearchTerm)
            }
            else if (selectionType === "category") {
                var myCat = leadershipQualitySearchTerm;
                if (this.props.leadershipQualities !== undefined && this.props.leadershipQualities.length > 0) {
                    try {
                        myCat = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, leadershipQualitySearchTerm, 'category');
                    }
                    catch (e) {
                        myCat = leadershipQualitySearchTerm;
                    }
                }
                else{ myCat = leadershipQualitySearchTerm};
                searching = 'loading related tips';
                //console.log(myCat)
                db = this.props.firebase.contents()
                .where('category', '==', 'tips')
                .where('leadership_category', '==', myCat)
                .orderBy('createdAt', 'desc')
            }
            else {
                searching = 'loading newest tips';
                db = this.props.firebase.contents()
                .where('category', '==', 'tips')
                .where('leadership_quality', 'array-contains', leadershipQualitySearchTerm)
                .orderBy('createdAt', 'desc')
            }
            if(diagnosticFlag) 
                console.log("counter", "limix", this.state.tipsCounter , this.state.limit, this.props)
            const timersIn = [250, 500, 750, 1000];
            await delay(timersIn[Math.floor(Math.random()*timersIn.length)]);
            if(diagnosticFlag) 
                console.log("counter", "await", this.state.tipsCounter , this.state.limit, this.props)
            if(this.state.tipsCounter < this.state.limit) {
                if(diagnosticFlag) 
                    console.log("cstarted quer", limits)
                this.unsubscribe = db
                .where('active', '==', true)
                .limit(2.5)
                .limit(Math.round(limits) || 10)
                .get()
                .then(snapshot => {
                    if(diagnosticFlag) 
                        console.log("snapshot")
                    if(this._isMounted){
                        if (snapshot.empty) {
                            if(diagnosticFlag) 
                                console.log("empty")
                            if (type instanceof Array && type.length > (pointer+1)) {
                                console.log('calling the next type empty', type[pointer+1],  pointer+1)
                                if(this._isMounted){
                                    this.loadContentFromFirestore(leadershipQualitySearchTerm, type, tempArray, limits, randomId, pointer+1)
                                }
                            }
                            else {
                                this.setState({loading: false});
                                console.log('issue in loading its empty')
                            }
                        }
                        else if(this._isMounted ){
                            if(diagnosticFlag) 
                                console.log("trying")
                            try{ /// this exception handling is to break the iteration when limits achieved
                                snapshot.forEach(doc => {
                                    if(this._isMounted){
                                        this.setState({loadingText: searching});
                                        this.setState({ noTips: false });
                                        if(diagnosticFlag) {
                                            console.log('data found from:  ', doc.id);
                                            console.log('available contents:  ', this.state.contentIds);
                                        }
                                        if (!(this.state.contentIds.includes(doc.id.toString()))
                                            && this.state.tipsCounter < this.state.limit
                                            && !(this.props.allQualitiesLoaded.includes(doc.id.toString()))) {
                                            //console.log('Title ', doc.data().title);

                                            this.setState({ searching: searching });
                                            // this is the array to ensure no double contentIds are involved and added
                                            contentIds.push(doc.id.toString())
                                            this.setState({ contentIds: contentIds })
                                            if (this.props.setParentAllQualitiesLoaded !== undefined) {
                                                this.props.setParentAllQualitiesLoaded(doc.id.toString());
                                            }
                                            //console.log('externa props', this.props.allQualitiesLoaded)

                                            // THIS code below including the if program is to ensure that not allowed program and contents are not visualized
                                            var myProgram = "a_cat_that_never_exists";
                                            var qualityText = "Your skill";
                                            var publicContent = false;
                                            var assignedCategory = doc.data().leadership_category;
                                            try {
                                                var selectedCat = FindDictByKey(this.props.firebase, this.props.leadershipCategories,  assignedCategory);
                                                //console.log('cat',selectedCat)
                                                if(selectedCat!==undefined && selectedCat.program!==undefined){
                                                    myProgram = selectedCat.program
                                                }
                                                if(selectedCat!==undefined && selectedCat.public){
                                                    //console.log('public',selectedCat.public)
                                                    publicContent = selectedCat.public
                                                }
                                            } catch (e) {
                                                myProgram = "a_cat_that_never_exists";
                                            }
                                            try {
                                                qualityText = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, leadershipQualitySearchTerm);
                                            } catch (e) {
                                                qualityText = "Your skill"
                                            }
                                            if (publicContent || this.props.user?.programs.includes(myProgram) || myProgram === "default" 
                                                || selectionType === "contentByIds" || this.props.specialQuery === 'bookmarks'  ) {
                                                    if(this._isMounted ){
                                                        var counter = this.state.tipsCounter + 1;
                                                        this.setState({ tipsCounter: counter })
                                                        var contentDict = doc.data();
                                                        //console.log('dic', contentDict)
                                                        contentDict = ContentProgramOverwrite(contentDict, this.props.user);
                                                        tempArray.push({ ...contentDict, id: doc.id, qualityText: qualityText, leadershipQualitySearchTerm });
                                                        this.setState({ tips: tempArray, loading: false});
                                                        //this.setState({ tips: tempArray, loading: false });
                                                    }//check is mounted
                                            };// end check if program
                                        }; // end if contet ids in
                                        if (this.state.tipsCounter >= 0) {
                                            this.setState({loading: false});
                                        }
                                        if (this.state.tipsCounter >= this.state.limit){
                                            this.setState({loading: false});
                                            //console.log('break')
                                            throw new Error('Enough content displayed')
                                        }
                                    }//end if mounted inside
                                });

                                if (type instanceof Array && type.length > (pointer+1) && this.state.tipsCounter < 4) {
                                    if (diagnosticFlag)
                                        console.log('calling the next type as < 4', type[pointer+1],  pointer+1)
                                    this.loadContentFromFirestore(leadershipQualitySearchTerm, type, tempArray, limits, randomId, pointer+1)
                                }
                                else if (type instanceof Array && type.length > (pointer+1)) {
                                    console.log('setting handler', type[pointer+1],  pointer+1);
                                    this.setState({loading: false, limit: this.state.limit+10});
                                    //setTimeout(() => {
                                    //    this.loadContentFromFirestore(leadershipQualitySearchTerm, type, tempArray, limits, randomId, pointer+1)
                                    //  },10000);
                                }
                                else{
                                    this.setState({loading: false});
                                }
                            }
                            catch(exception) {
                                this.setState({loading: false});
                            }
                        }
                        //this.setState({ loading: false });
                    }//end ifmounted
                    else{
                        console.log('this is not mounted')
                    }
                })
                .catch(error => {
                    console.log('this is error', error)
                    this.setState({loading: false});
                })
            }
            else{
                this.setState({loading: false});
                if(diagnosticFlag) 
                    console.log("not matching the chasr", this.state.tipsCounter , ' limit: ',  this.state.limit)
            }
        }//end counter max reached
    }; // end loadContentTipsFromFirestore function

    handleOpen (contentData, type="content") {
        this.setState({
            open: true,
            openCommentsPopup: type==="comment"? true: false,
            dialogueData: contentData,
            contentId: contentData.id,
        });
        if (this.state['userEngagement' + contentData.id] === undefined) {
            this.getBookMarks(contentData.id);
        }
    }

    handleClose = () => {
        this.setState({
            open: false,
        });
    }

    dialogueContent = () => {
        return(
            <DialogContentItem
                user={this.props.user}
                defaultSet={this.props.defaultSet || undefined}
                open={this.state.open}
                handleClose={ () => {
                    this.setState({open : false})
                }}
                specialQuery={'contentByIds'}
                contentData={this.state.dialogueData}
                contentId = {this.state.contentId}
                unbookMarkOption={this.props.unbookMarkOption || false}
                jumpToComment={this.state.openCommentsPopup  || false}
                leadershipCategories={this.props.leadershipCategories}
                leadershipQualities={this.props.leadershipQualities}
                openCommentsPopup={this.state.openCommentsPopup || false}
                handlerMessageSubmit = {
                    this.props.handlerMessageSubmit === undefined
                        ?  undefined
                        :  (text) => {this.submitInspiration(text)}
                }
                removeItemFromList={ (removeContentId) => {
                    if(this._isMounted){
                        let loadedTips = this.state.tips;
                        //console.log(this.state.tips)
                        try{
                            if(loadedTips !== null && loadedTips !== undefined && loadedTips.length > 0){
                                let myArray = loadedTips.filter((item) => {
                                    return item.id !== removeContentId;
                                });
                                this.setState({tips: myArray, activeStep: 0});
                            }
                            if(this.props.removeItemFromList !== undefined){
                               this.props.removeItemFromList(removeContentId);
                            }
                        }
                        catch(err){
                            
                        }
                        //console.log(this.state.tips)
                    }
                }}
                />
            )
    }   
    submitInspiration = (text) => {
        if(this.props.handlerMessageSubmit!== undefined){
            this.props.handlerMessageSubmit(text);
            //this.setState({open:false});
        }
    }

    render() {
        const { theme, classes } = this.props;
        const { loading } = this.state;
        this.cache = new CellMeasurerCache({
            defaultHeight: 300,
            fixedWidth: true
        });

        if (this.props.preview) {
        //console.log('tips', this.state.tips)
            return (
                <div>
                    {this.state.loading 
                    ?
                        <div className={classes.grow}>
                            <CircularProgress style={theme.loading} />
                        </div>
                    :
                        <Grid container spacing={2} direction='row' justifyContent="space-evenly" alignItems="flex-start">
                            {this.state.tips.length > 0 &&
                                this.state.tips.map((t, index) => (
                                    (t.url)
                                    ?
                                    <Grid item key={index}>
                                        <div style={{
                                            backgroundImage: `url(${t.url})`,
                                            backgroundPosition: "center 30%",
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat",
                                            width: this.props.previewWidth,
                                            height: this.props.previewHeight,
                                            }}>
                                        </div>
                                    </Grid>
                                    :
                                    <Grid item key={index}>
                                        <div style={{
                                            //backgroundImage: `url(${createGradientImage(theme.palette.secondary.light, "#FFFFFF")})`,
                                            //backgroundImage: `url(${pixel})`, 
                                            background: 'linear-gradient(215deg, #FFFFFF 10%, '+theme.palette.secondary.light+' 90%)',
                                            //backgroundPosition: "center center",
                                            //backgroundSize: "cover",
                                            //backgroundRepeat: "no-repeat",
                                            width: this.props.previewWidth,
                                            height: this.props.previewHeight,
                                            }} key={index}>
                                        </div>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    }
                </div>
            )
        }
        else if (this.props.swipe) {
            //console.log('this.state.tips', this.state.tips)
            return(
                (true || (this.state.tips!== undefined && this.state.tips!== null))
                ? 
                    <div>
                        {((this.props.titleOfGroup !== undefined && this.props.titleOfGroup !== ""))
                            &&
                            <Grid container 
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                >
                                <Grid item xs={this.props.linkToContent===false ? 12 : 9}>
                                    <div style={{position: 'relative'}}>
                                        <h4 style={theme.CardContent.titleText}>{this.props.titleOfGroup}</h4>
                                        {this.props.linkToContent===false && this.props.tutorialOpen
                                        &&
                                            <div style={{position: 'absolute', top: -4, right: -6}}>
                                                <IconButton style={{marginRight: 0}} onClick={() => {if(this.props.tutorialOpen) this.props.tutorialOpen('content')}}>
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </div>
                                        }
                                    </div>
                                </Grid>
                                {this.props.linkToContent!==false 
                                &&
                                    <Grid item xs={3} style={{textAlign: 'right'}}>
                                        <Link to={ROUTES.CONTENT}>
                                            See All &gt;
                                        </Link>
                                    </Grid>
                                }
                            </Grid>
                        }
                        {this.dialogueContent() }
                        {this.state.tips?.length > 0 && !loading
                            ?
                                <SwipeableViews
                                    key={('reload'+this.state.tips.length)}
                                    style={customWidthStyle('70vw', ((window.innerWidth <= 480 && this.state.tips?.length===1) ? 'calc(100vw - 40px)' : '320px'), (this.state.tips.length + 0))}
                                    slideStyle={customWidthSlideStyle('70vw', ((window.innerWidth <= 480 && this.state.tips?.length===1) ? 'calc(100vw - 40px)' : '320px'), (this.state.tips.length + 0))}
                                    axis={'x'}
                                    index={this.state.tips.length===1 ? 0 : this.state.activeStep}
                                    onChangeIndex={this.handleStepChange}
                                    enableMouseEvents = {this.state.open === true? false : true}
                                    disabled = {this.state.open === true? true : false}
                                    resistance = {true}
                                >
                                    {
                                        this.state.tips.map((t, index) => {
                                            return(
                                                <Grid
                                                    item
                                                    key={t.id + t.leadership_category}
                                                    >
                                                    {
                                                        this.renderSingleContentItemCardSwipe(t,index)
                                                        //this.renderSingleContentItemCardNew(t,index)
                                                    }
                                                </Grid>
                                            )
                                        })
                                    }
                                </SwipeableViews>
                            :
                                <Card key={'loading'} 
                                    style={{
                                        ...(this.props.raised
                                            ?   theme.CardContentItem.rootSwipeShadow 
                                            :   theme.CardContentItem.rootSwipe),
                                        width: '100%', 
                                        maxWidth: 320, 
                                        height: 240,
                                        borderRadius: theme.borderRadius
                                    }}
                                >
                                    {loading 
                                        ?    <Skeleton variant="rect" width={'100%'} height={'100%'}/>
                                        :    <Skeleton variant="rect" width={'100%'} height={'100%'}/>
                                    }
                                </Card>
                        }
                        <SwipeStepper
                            handlerSteps = {(step) => this.setState({activeStep:step}) }
                            steps={ this.state.tips.length || 0}
                            activeStep = {this.state.activeStep || 0}
                        />
                    </div>
                :   ""
            )
        }
        else if (this.props.masonry) {
            return (
                <div ref="myscroll" >
                    {this.props.titleOfGroup !== undefined && this.props.titleOfGroup !== ""
                        &&
                            <div style={{position: 'relative'}}>
                                <h4 style={theme.CardContent.titleText}>{this.props.titleOfGroup}</h4>
                                {this.props.tutorialOpen 
                                &&
                                    <div style={{position: 'absolute', top: -4, right: -6}}>
                                        <IconButton style={{marginRight: 0}} onClick={() => {if(this.props.tutorialOpen) this.props.tutorialOpen('content')}}>
                                            <HelpOutlineIcon />
                                        </IconButton>
                                    </div>
                                }
                            </div>
                    }
                    {loading 
                        ?
                            <Card style={{...theme.CardContentItem.rootFeed, height: '90vh', width: "100%"}}>
                                <LoadingProgress 
                                    defaultSet={this.props.defaultSet}
                                    type={'LoadingPageAnimated'} loadingText={'loading learnings'} />
                            </Card>
                        :
                            <Grid container spacing={0} >
                                {this.state.tips.length > 0
                                    ?
                                        <Masonry
                                                breakpointCols={this.props.masonryCols !== undefined ? this.props.masonryCols : 2}
                                                className={classes.masonry}
                                                columnClassName={classes.masonryColumn}
                                            >
                                            {this.state.tips.map((i, index) => (
                                                <div key={index}>
                                                    { this.renderSingleContentItemCardNew(i, index)}
                                                </div>
                                            ))}
                                        </Masonry>
                                    :
                                        <div style={{...theme.textSupportCenter, padding: 50}}>More content available after your first chats</div>
                                }
                                {this.dialogueContent() }
                            </Grid>
                    }
                </div>
            )
        }
        else return (
            <div ref="myscroll" >
                {this.props.titleOfGroup !== undefined && this.props.titleOfGroup !== "" && (this.state.tips === undefined || this.state.tips.length > 0)
                    &&
                    <div style={{position: 'relative'}}>
                        <h4 style={theme.CardContent.titleText}>{this.props.titleOfGroup}</h4>  
                        {this.props.tutorialOpen 
                        &&
                            <div style={{position: 'absolute', top: -4, right: -6}}>
                                <IconButton style={{marginRight: 0}} onClick={() => {if(this.props.tutorialOpen) this.props.tutorialOpen('content')}}>
                                    <HelpOutlineIcon />
                                </IconButton>
                            </div>
                        }
                    </div>
                }
                {loading 
                ?
                    <Card style={{...theme.CardContentItem.rootFeed, height: '90vh', width: "100%"}}>
                        <LoadingProgress 
                            defaultSet={this.props.defaultSet}
                            type={'LoadingPageAnimated'} loadingText={'loading learnings'} />
                    </Card>
                :
                <Grid container spacing={2} style={{width: '100%', margin:0 }} >
                    {this.state.tips.length > 0
                        ?
                            <WindowScroller ref={ref => (this.windowScroller = ref)}>
                                    {({ height, isScrolling, onChildScroll, scrollTop }) => 
                                        (
                                        //console.log(height, isScrolling, onChildScroll, scrollTop); 
                                            <AutoSizer disableHeight>
                                                {({width}) => (
                                                    <ListVirtual
                                                        ref={ref => {
                                                            this.list = ref;
                                                        }}
                                                        autoHeight
                                                        deferredMeasurementCache={this.cache}
                                                        height={height}
                                                        isScrolling={isScrolling}
                                                        onScroll={onChildScroll}
                                                        rowCount={this.state.tips.length}
                                                        rowHeight = {this.cache.rowHeight}
                                                        rowRenderer={this._dynamicRowRenderer}
                                                        scrollTop={scrollTop}
                                                        width={width}
                                                        style={{ outline: 'none', paddingTop:10}}
                                                    />
                                                )}
                                            </AutoSizer>
                                        )
                                    }
                                </WindowScroller>
                        
                        :
                            <div style={{width: "100%", padding: 50}}>
                                {this.props.showDisclaimer !== false 
                                &&
                                    <span style={theme.textSupportCenter}>More content available after your first chats</span>
                                }
                            </div>
                    }
                    {this.dialogueContent() }
                </Grid>
                }
            </div>
        );
    }

    _rowRenderer = ({index, isScrolling, isVisible, key, style}) => {
        //const { index} = props;
        //console.log('props', isScrolling)
        return (
            <div style={style} key={key}>
                {((IsMobileDevice() && window.innerWidth <= 500) || this.props.direction==='column')
                    ? this.renderSingleContentItemCard(this.state.tips[index], index)
                    : this.renderSingleContentItemCardDesktop(this.state.tips[index], index)
                }
            </div>
        )
    }
    _dynamicRowRenderer  = ({index, isScrolling, isVisible, key, style, parent}) => {
        //console.log(index, key, style)
        return (
            <CellMeasurer
                cache={this.cache}
                columnIndex={0}
                key={key}
                overscanRowCount={10}
                parent={parent}
                ref={element => { this.cellMeasurer = element; }}
                rowIndex={index}
                style={style}
            >
                    <div style={style} key={key}>
                        {this.props.commentView
                            ?
                                this.renderSingleContentItemCardCommunity(this.state.tips[index], index)
                            : ((IsMobileDevice() && window.innerWidth <= 500) || this.props.direction==='column')
                                ? this.renderSingleContentItemCardNew(this.state.tips[index], index)
                                : this.renderSingleContentItemCardDesktop(this.state.tips[index], index)
                        }
                    </div>
            </CellMeasurer>
        );
    }

  
    renderSingleContentItemCard = (t, index) => {
        const {theme} = this.props;
        var bgColor = theme.palette.secondary.light;
        //var catText = "A category";
        try {
            bgColor = FindTextValueInDict(this.props.firebase, this.props.leadershipCategories,  t.leadership_category, 'backgroundColor');
        } catch (e) {
            bgColor = theme.palette.secondary.light;
            //console.log('catch:', bgColor);
        }

        //console.log('what is in t:',index, t);
        return ( 
        <VizSensor 
            partialVisibility={true}
            onChange={(isVisible) => { 
                if(isVisible && this.state['isVisible_'+t.id]===undefined && this._isMounted) { 
                    this.setState({['isVisible_'+t.id]: true, ['isVisible_Bookmark_'+t.id]: true});
                    //console.log(this.state)
                }
            }}
            >
        
            <Card key={t.id + t.leadership_category}
                style={
                    this.props.raised
                        ?   theme.CardContentItem.rootSwipeShadow
                        :   theme.CardContentItem.rootSwipe
                }
                >
                {
                    this.props.hideImage !== undefined && this.props.hideImage === true
                    ?
                        ""
                    :   (t.url) 
                            //&& this.state["imgViz_"+t.id] 
                            ?
                            <CardMedia
                                style={{
                                    ...theme.CardContentItem.CardMedia,
                                    marginLeft: 10,
                                    marginRight: 10,
                                    borderRadius: theme.borderRadius,                    
                                }}
                                //image={t.url}
                                image={this.state['isVisible_'+t.id] ? t.url : ''}
                                onClick={() => this.handleOpen(t)}
                            >
                                <div style={theme.CardContentItem.ribbonSkill}>
                                    {this.props?.overWriteQualityValue?.length > 3
                                        ?
                                            <AvatarCard dict={this.props.leadershipQualities} type='quality' item={this.props.overWriteQualityValue} squareSize='20' />
                                        :
                                            t.leadership_quality?.length === 1
                                            ?   <AvatarCard dict={this.props.leadershipQualities} type='quality' item={t.leadership_quality[0]} squareSize='20' />
                                            :   <AvatarCard dict={this.props.leadershipCategories} type='category' item={t.leadership_category} squareSize='20' />
                                    }
                                </div>
                            </CardMedia>
                            :
                            <CardMedia
                                style={{
                                    ...theme.CardContentItem.CardMedia,
                                    marginLeft: 10,
                                    marginRight: 10,
                                    borderRadius: theme.borderRadius,   
                                    maxHeight: 400,   
                                    background: 'linear-gradient(215deg, #FFFFFF 10%, '+bgColor+' 90%)',           
                                }}
                                //image={createGradientImage(bgColor, "#FFFFFF")}
                                //image = {pixel}
                                onClick={() => this.handleOpen(t)}
                            >
                                <div style={theme.CardContentItem.ribbonSkill}>
                                    {this.props?.overWriteQualityValue?.length > 3
                                        ?
                                            <AvatarCard dict={this.props.leadershipQualities} type='quality' item={this.props.overWriteQualityValue} squareSize='20' />
                                        :
                                            t.leadership_quality?.length === 1
                                            ?   <AvatarCard dict={this.props.leadershipQualities} type='quality' item={t.leadership_quality[0]} squareSize='20' />
                                            :   <AvatarCard dict={this.props.leadershipCategories} type='category' item={t.leadership_category} squareSize='20' />
                                    }
                                </div>
                                {/***
                                <div style={theme.CardContentItem.ribbon}>
                                <Typography style={theme.CardContentItem.ribbon.Typography}>
                                    &nbsp;#{t.tags !== undefined ? t.tags[0] : t.qualityText}
                                </Typography>
                                </div>
                                */}
                            </CardMedia>
                }
                {/**
                 </VizSensor>
                */}
                <CardContent style={theme.CardContentItem.CardContent}
                    onClick={() => this.handleOpen(t)}
                >
                    <Grid container spacing={2} >
                        <Grid item xs={12}>
                            <h3 style={theme.CardContentItem.TextTitle}>
                                {
                                    t.title !== undefined && t.title.length > 30
                                    ? t.title.substring(0,29) + '...'
                                    : t.title
                                }
                            </h3>
                        </Grid>
                        {/**
                        <Grid item xs={2}>
                            {t.text !== undefined && t.text !== ''
                                ? <IconButton><HelpOutlineIcon /> </IconButton> 
                                : <IconButton><KeyboardArrowRightIcon /> </IconButton> 
                            }
                        </Grid>
                        */}
                        <Grid item xs={12}>
                            <span style={theme.CardContentItem.TextSubTitle}>
                                {
                                    t.subtitle !== undefined && t.subtitle.length > 100
                                    ? t.subtitle.substring(0,99) + '...'
                                    : t.subtitle
                                }
                            </span>
                        </Grid>
                        {/**
                        <Grid item xs={12}>
                            <AvatarCard textBlock={catText} type='category' item={t.leadership_category} squareSize='20' backgroundColor={bgColor} />
                        </Grid>
                        */}
                        <Grid item xs={12}>
                            {this.state['isVisible_Bookmark_'+t.id]
                                &&
                                <ContentCommentHighlight 
                                    loadHandler={ (loaded) => {
                                        if (this.list) {
                                            this.setState({refKey: "scroller_"+t.id})
                                            //console.log(loaded, this.list, this.windowScroller);
                                            //this.setState({refKey: "scroller_"+t.id});
                                            //this.windowScroller.updatePosition()
                                            //this.list.forceUpdate()
                                            this.list.recomputeRowHeights()
                                            //this.list.recomputeRowHeights();
                                            //this.list.forceUpdateGrid();
                                        }
                                    }}
                                    contentId={t.id} 
                                    visualCommentsLimit={this.props.visualCommentsLimit || 2} 
                                />
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </VizSensor>
        ) // end return
    }

    renderSingleContentItemCardNew = (contentData, index) => {
        return(
            <ContentItemCard
                user={this.props.user}
                previewComments={this.props.previewComments}
                listIndex={index}
                handlerListIndex={ (visualIndex) => { this.setState({visualIndex});  } }
                loadHandler={ () => {
                    if (this.list!==undefined && this.list!== null){
                        this.list.recomputeRowHeights()}
                    }
                }
                raised={this.props.raised || false}
                contentData={contentData}
                handleOpen={ (contentId, type="content") => this.handleOpen(contentData, type)}
                handleOpenComment={ (contentId, type="comment") => this.handleOpen(contentData, type)}
                handleOpenRead={ (contentId, type="read") => this.handleOpen(contentData, type)}
                contentId = {contentData.id}
                leadershipCategories={this.props.leadershipCategories}
                leadershipQualities={this.props.leadershipQualities}
            />
        )
    }

    renderSingleContentItemCardDesktop = (t, index) => {
        const {theme} = this.props;
        var bgColor = "white";
        var catText = "A category";
        var icon = undefined;
        try {
            var catDict = FindDictByKey(this.props.firebase, this.props.leadershipCategories,  t.leadership_category);
            catText = catDict.text;
            bgColor = catDict.backgroundColor;
            if(catDict.icon !== undefined && catDict.icon!=="") icon = catDict.icon;
        } 
        catch (e) {
            bgColor = "white";
        //console.log('catch:', bgColor);
        }

        //console.log('what is in t:',index, t);
        //console.log('what prop:', this.props);
        return ( 
            <VizSensor 
                partialVisibility={true}
                onChange={(isVisible) => { 
                    if(isVisible && this.state['isVisible_'+t.id]===undefined && this._isMounted) { 
                        this.setState({['isVisible_'+t.id]: true, ['isVisible_Bookmark_'+t.id]: true});
                        //console.log('t.leadership_category', t.leadership_category)
                    }
                }}
                >
                <Card key={t.id + t.leadership_category} 
                    style={{
                        ...theme.rootComments,  
                        opacity : this.state['hover'+t.id] ? 0.3 : 1
                    }}
                    onMouseEnter={(this.state['hover'+t.id] !== true) ? () => {this.setState({['hover'+t.id]: true})} : void(0)}
                    onMouseLeave={(this.state['hover'+t.id] === true) ? () => {this.setState({['hover'+t.id]: false})} : void(0)}
                    >
                    {
                        this.props.hideImage !== undefined && this.props.hideImage === true
                        ?
                            
                            <CardContent style={theme.CardContentItem.CardContentFeed} 
                                onClick={() => this.handleOpen()} >
                                <Grid container 
                                    spacing={2} 
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center" 
                                    >
                                    <Grid item xs={12} style={{maxWidth:"85vw", width:"100%"}}>
                                        <Grid container 
                                            spacing={2} 
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            >
                                            <Grid item >
                                                {t?.leadership_quality?.length === 1
                                                    ?
                                                        <AvatarImage 
                                                            textBlock={catText} 
                                                            dict={this.props.leadershipQualities} 
                                                            type='quality'
                                                            image={icon}
                                                            item={t.leadership_quality[0]} 
                                                            squareSize='20' 
                                                            backgroundColor={bgColor} />
                                                    :   
                                                        <AvatarImage 
                                                            textBlock={catText} 
                                                            dict={this.props.leadershipCategories} 
                                                            type='category'
                                                            image={icon}
                                                            item={t.leadership_category} 
                                                            squareSize='20' 
                                                            backgroundColor={bgColor} />
                                                }
                                            </Grid>
                                            <Grid item  style={{maxWidth:"65vw", width:"100%"}}>
                                                {t.category === "quotes"
                                                    ? <span style={{color: theme.palette.themeGrey}}>{t.subtitle}</span> 
                                                    : <>
                                                        <strong>{t.title}</strong> 
                                                        <br/>
                                                        <span style={{color: theme.palette.themeGrey}}>{t.source}</span> 
                                                    </>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/**
                                    <Grid item xs={1} >
                                    </Grid> */}
                                    <Grid item xs={12}>
                                        {t.category === "quotes" 
                                            ? <p>{t.title}</p> 
                                            : <p>{t.subtitle}</p> 
                                        }
                                    </Grid>
                                </Grid>
                            </CardContent>
                        :   (t.url) 
                            //&& this.state["imgViz_"+t.id] 
                            ?
                            <CardMedia
                                style={{...theme.CardContentItem.CardMediaDesktop, width:'40%', borderRadius: theme.borderRadius, }}
                                image={this.state['isVisible_'+t.id] ? t.url : ''}
                                //image={t.url}
                                onClick={() => this.handleOpen(t)}
                            >
                                <div style={theme.CardContentItem.ribbonSkill}>
                                    {this.props?.overWriteQualityValue?.length > 3
                                        ?
                                            <AvatarCard dict={this.props.leadershipQualities} type='quality' item={this.props.overWriteQualityValue} squareSize='20' />
                                        :
                                            t.leadership_quality?.length === 1
                                            ?   <AvatarCard dict={this.props.leadershipQualities} type='quality' item={t.leadership_quality[0]} squareSize='20' />
                                            :   <AvatarCard dict={this.props.leadershipCategories} type='category' item={t.leadership_category} squareSize='20' />
                                    }
                                </div>
                            </CardMedia>
                            :
                            <CardMedia
                                style={{...theme.CardContentItem.CardMediaDesktop, maxHeight: 300, 
                                    width:'40%',  borderRadius: theme.borderRadius, 
                                    background: 'linear-gradient(215deg, #FFFFFF 10%, '+bgColor+' 90%)', 
                                }}
                                //image={createGradientImage(bgColor, "#FFFFFF")}
                                image = {pixel}
                                onClick={() => this.handleOpen(t)}
                            >
                                <div style={theme.CardContentItem.ribbonSkill}>
                                    {this.props?.overWriteQualityValue?.length > 3
                                        ?
                                            <AvatarCard dict={this.props.leadershipQualities} type='quality' item={this.props.overWriteQualityValue} squareSize='20' />
                                        :
                                            t.leadership_quality?.length === 1
                                            ?   <AvatarCard dict={this.props.leadershipQualities} type='quality' item={t.leadership_quality[0]} squareSize='20' />
                                            :   <AvatarCard dict={this.props.leadershipCategories} type='category' item={t.leadership_category} squareSize='20' />
                                    }
                                </div>
                            </CardMedia>
                    }
                    <CardContent style={{...theme.CardContentItem.CardContent, flex: '1 1 auto'}}
                            onClick={() => this.handleOpen(t)} 
                        >
                        <Grid container spacing={2} >
                            {(this.props.hideImage === undefined || this.props.hideImage === false)
                            &&
                                <> 
                                    <Grid item xs={12}  >
                                        <h3 style={theme.CardContentItem.TextTitle}>{t.title}</h3>
                                    </Grid>
                                    <Grid item xs={12}   >
                                        <span style={theme.CardContentItem.TextSubTitle}>
                                        {
                                            t.subtitle !== undefined && t.subtitle.length > 100
                                            ? <p>{t.subtitle.substring(0,99)}...</p> 
                                            : <p>{t.subtitle}</p> 
                                        }
                                        </span>
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12}  >
                                {this.state['isVisible_Bookmark_'+t.id]   &&
                                    <ContentCommentHighlight loadHandler={ (loaded, commentHighlights) => {
                                        if (this.list) {
                                            this.setState({refKey: "scroller_"+t.id})
                                            //this.setState({[commentsHigh+t.id]: commentHighlights})
                                            //console.log(loaded, this.list, this.windowScroller)
                                            //this.windowScroller.updatePosition()
                                            this.list.recomputeRowHeights()
                                            //this.list.forceUpdate()
                                            //this.list.recomputeRowHeights();
                                            //this.list.forceUpdateGrid();
                                        }
                                    }} contentId={t.id} visualCommentsLimit={this.props.visualCommentsLimit}/>
                                }
                            </Grid>
                        </Grid>

                        </CardContent>
                </Card>
            </VizSensor>
        ) // end return
    }
    
    renderSingleContentItemCardCommunity = (t, index) => {
        const {theme} = this.props;
        var bgColor = "white";
        var catText = "A category";
        var icon = undefined;
        try {
            var catDict = FindDictByKey(this.props.firebase, this.props.leadershipCategories,  t.leadership_category);
            catText = catDict.text;
            bgColor = catDict.backgroundColor;
            if(catDict.icon !== undefined && catDict.icon!=="") icon = catDict.icon;
        } 
        catch (e) {
            bgColor = "white";
        //console.log('catch:', bgColor);
        }
        return ( 
            <VizSensor 
                partialVisibility={true}
                onChange={(isVisible) => { 
                    if(isVisible && this.state['isVisible_'+t.id]===undefined && this._isMounted) { 
                        this.setState({['isVisible_'+t.id]: true, ['isVisible_Bookmark_'+t.id]: true});
                        //console.log('t.leadership_category', t.leadership_category)
                    }
                }}
                >
                <Card key={t.id + t.leadership_category} 
                    style={{
                        ...(this.props.raised
                            ?   theme.CardContentItem.rootSwipeShadow
                            :   theme.CardContentItem.rootSwipe
                        ),
                        marginBottom:20,
                        opacity : this.state['hover'+t.id] ? 0.7 : 1
                    }}
                    onMouseEnter={(this.state['hover'+t.id] !== true) ? () => {this.setState({['hover'+t.id]: true})} : void(0)}
                    onMouseLeave={(this.state['hover'+t.id] === true) ? () => {this.setState({['hover'+t.id]: false})} : void(0)}
                    >
                    <CardContent style={{...theme.CardContentItem.CardContentFeed}} 
                        onClick={() => this.handleOpen(t, "comment")} >
                        <Grid container 
                            spacing={0} 
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center" 
                            >
                            <Grid item xs={12} style={{maxWidth:"85vw", width:"100%"}}>
                                <Grid container 
                                    spacing={2} 
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    >
                                    <Grid item >
                                        {t?.leadership_quality?.length === 1
                                            ?
                                                <AvatarImage 
                                                    textBlock={catText} 
                                                    dict={this.props.leadershipQualities} 
                                                    type='quality'
                                                    image={icon}
                                                    item={t.leadership_quality[0]} 
                                                    squareSize='20' 
                                                    backgroundColor={bgColor} />
                                            :   
                                                <AvatarImage 
                                                    textBlock={catText} 
                                                    dict={this.props.leadershipCategories} 
                                                    type='category'
                                                    image={icon}
                                                    item={t.leadership_category} 
                                                    squareSize='20' 
                                                    backgroundColor={bgColor} />
                                        }
                                    </Grid>
                                    <Grid item style={{maxWidth:"65vw", width:"80%"}}>
                                        {t.category === "quotes"
                                            ? <span style={{color: theme.palette.themeGrey}}>{t.subtitle}</span> 
                                            : <>
                                                <strong>{t.title}</strong> 
                                                <br/>
                                                <span style={{color: theme.palette.themeGrey}}>{t.source}</span> 
                                            </>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        {t.category === "quotes" 
                                            ? <p><q>{t.title}</q></p> 
                                            : <p><strong>{t.subtitle}</strong></p> 
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {this.state['isVisible_Bookmark_'+t.id]   &&
                                    <ContentCommentHighlight 
                                        loadHandler={ (loaded, commentHighlights) => {
                                            if (this.list) {
                                                this.setState({refKey: "scroller_"+t.id})
                                                //this.setState({[commentsHigh+t.id]: commentHighlights})
                                                //console.log(loaded, this.list, this.windowScroller)
                                                //this.windowScroller.updatePosition()
                                                this.list.recomputeRowHeights()
                                                //this.list.forceUpdate()
                                                //this.list.recomputeRowHeights();
                                                //this.list.forceUpdateGrid();
                                            }
                                        }} 
                                        contentId={t.id} 
                                        sorting={'commentDate'}
                                        visualCommentsLimit={this.props.visualCommentsLimit}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </VizSensor>
        ) // end return
    }

    renderSingleContentItemCardSwipe = (t, index) => {
        const {theme} = this.props;
        var bgColor = theme.palette.backPaper;
        //var catText = "A category";
        try {
            var itemDict = FindDictByKey(this.props.firebase, this.props.leadershipCategories,  t.leadership_category);
            bgColor = itemDict.backgroundColor;
            //catText = itemDict.text;
        //console.log('try:', bgColor);
        } catch (e) {
            bgColor = theme.palette.backPaper;
        
        }
        //bgColor = 'red'
        //console.log('catch bgColor', bgColor);
        //console.log('what is in t:',index, t);
        return ( 
        <VizSensor 
            partialVisibility={true}
            onChange={(isVisible) => { 
                if(isVisible && this.state['isVisible_'+t.id]===undefined && this._isMounted ) { 
                    this.setState({['isVisible_'+t.id]: true, ['isVisible_Bookmark_'+t.id]: true});
                    //console.log('t.leadership_category', t.leadership_category)
                }
                else if(isVisible && this._isMounted) { 
                    this.setState({ ['isVisible_Bookmark_'+t.id]: true});
                    //console.log('t.leadership_category', t.leadership_category)
                }
                else {
                    this.setState({['isVisible_Bookmark_'+t.id]: false}); 
                }
            }}
            >
            { true && 
            <Card key={t.id + t.leadership_category} 
                style={{
                    ...(this.props.raised
                        ?   theme.CardContentItem.rootSwipeShadow 
                        :   theme.CardContentItem.rootSwipe)
                    ,
                    opacity : this.state['hover'+t.id] ? 0.3 : 1
                }}
                onMouseEnter={(this.state['hover'+t.id] !== true) ? () => {this.setState({['hover'+t.id]: true})} : void(0)}
                onMouseLeave={(this.state['hover'+t.id] === true) ? () => {this.setState({['hover'+t.id]: false})} : void(0)}
                >
                {(t.url) 
                //&& this.state["imgViz_"+t.id] 
                    ?
                    <CardMedia
                        style={theme.CardContentItem.CardMedia}
                        image={this.state['isVisible_'+t.id] ? t.url : ''}
                        onClick={() => this.handleOpen(t)}
                    >
                        <div style={theme.CardContentItem.ribbonSkill}>
                            {this.props?.overWriteQualityValue?.length > 3
                                ?
                                    <AvatarCard dict={this.props.leadershipQualities} type='quality' item={this.props.overWriteQualityValue} squareSize='20' />
                                :
                                    t.leadership_quality?.length === 1
                                    ?   <AvatarCard dict={this.props.leadershipQualities} type='quality' item={t.leadership_quality[0]} squareSize='20' />
                                    :   <AvatarCard dict={this.props.leadershipCategories} type='category' item={t.leadership_category} squareSize='20' />
                            }
                        </div>
                    </CardMedia>
                    :
                    <CardMedia
                        style={{...theme.CardContentItem.CardMedia, maxHeight:400, 
                            background: 'linear-gradient(215deg, #FFFFFF 10%, '+bgColor+' 90%)', 
                        }}
                        //image={this.state['isVisible_'+t.id] ?  createGradientImage(bgColor, "#FFFFFF") : '' }
                        //image={this.state['isVisible_'+t.id] ?  pixel : '' }
                        onClick={() => this.handleOpen(t)}
                    >
                        <div style={theme.CardContentItem.ribbonSkill}>
                            {this.props?.overWriteQualityValue?.length > 3
                                ?
                                    <AvatarCard dict={this.props.leadershipQualities} type='quality' item={this.props.overWriteQualityValue} squareSize='20' />
                                :
                                    t.leadership_quality?.length === 1
                                    ?   <AvatarCard dict={this.props.leadershipQualities} type='quality' item={t.leadership_quality[0]} squareSize='20' />
                                    :   <AvatarCard dict={this.props.leadershipCategories} type='category' item={t.leadership_category} squareSize='20' />
                            }
                        </div>
                    </CardMedia>
                }
                <CardContent style={{...theme.CardContentItem.CardContentSwipe}}
                    onClick={() => this.handleOpen(t)}
                >
                    <Grid container spacing={2} >
                        <Grid item xs={10}>
                            <strong style={theme.CardContentItem.TextSubTitle}>
                                {(t?.category === 'tips' && (t?.premium === true || (t?.program !== 'default' && !(t?.premium === false))) )
                                &&
                                    <span style={{position:'relative',
                                        top:2,
                                        left:-4,
                                        color:theme.palette.themeAltGold}}
                                        >
                                        <ShoppingBasketIcon style={{ color:theme.palette.themeAltGold, fontSize: '1rem' }}/>
                                    </span>
                                }
                                {
                                    t.title !== undefined && t.title.length > 23
                                    ? t.title.substring(0,20) + '...'
                                    : t.title
                                }
                            </strong>
                            <br/>
                            <span>
                                {
                                    t.subtitle !== undefined && t.subtitle.length > 24
                                    ? t.subtitle.substring(0,21) + '...'
                                    : t.subtitle
                                }
                            </span>
                        </Grid>
                        <Grid item xs={2}>
                                {this.state['isVisible_Bookmark_'+t.id] 
                                && !this.state.addExtraAction
                                    &&
                                    <BookMarkVote
                                        userId={this.context.uid}
                                        contentData={t}
                                        contentId={t.id}
                                        commentsIconOnly={true}
                                        unbookMarkOption={this.props.unbookMarkOption || false}
                                        handleOpen={(contentId, type="content") => this.handleOpen(t, type)}
                                        handleOpenComment={(contentId, type="comment") => this.handleOpen(t, type)}
                                        handleOpenRead={(contentId, type="read") => this.handleOpen(t, type)}
                                        //openCommentsPopup={this.state.OpenCommentsPopup || false}
                                        //userEngagement={this.state[t.id]} 
                                        //setParent={ v => this.setState({[t.id] : v}) }
                                    />
                                }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            }
        </VizSensor>
        ) // end return
    }

    handleStepChange = step => {
        this.setState({activeStep : step});
    };
    handleNext = () => {
        this.setState({activeStep : this.state.activeStep + 1});
    };
    handleBack = () => {
        this.setState({activeStep : this.state.activeStep - 1});
    };

    getBookMarks = (contentId) => {
        if ( this._isMounted) {
            this.unsubscribeBookmark = this.props.firebase.contentBookmark(contentId, this.context.uid)
            //this.props.firebase.contentBookmark(contentId, this.context.uid)
                //.get().then(docEngagement => {
                .onSnapshot(docEngagement => {
                    if(this._isMounted ){
                        if (docEngagement.exists) {
                            var userEngagement = docEngagement.data();
                            var contentEngagementRating = 1;
                            if(userEngagement.read) {contentEngagementRating +=1}
                            if(userEngagement.comment !== undefined && userEngagement.comment.length > 20  ) {contentEngagementRating +=1}
                            else if (userEngagement.comment !== undefined && userEngagement.comment.length > 2  ) {contentEngagementRating +=0.5}
                            if(userEngagement.actions !== undefined && userEngagement.actions.length >= 1 ) {contentEngagementRating +=1}
                            if(userEngagement.vote !== undefined && userEngagement.vote !== '' ) {contentEngagementRating +=1}
                            //this.setState({ tips: shuffle(tips) });
                            //console.log('loaded engagement:', docEngagement.data());
                            if(this._isMounted){
                                this.setState({
                                    ['userEngagement' + contentId]: userEngagement,
                                    contentEngagementRating
                                });
                            }
                        }//docengagement
                        else {
                            if(this._isMounted){
                                this.setState({
                                    ['userEngagement' + contentId]: []
                                });
                            }
                        }//end else
                        if( this.state['userEngagement' + contentId] === undefined
                            || ( this.state['userEngagement' + contentId] !== undefined && !( this.state['userEngagement' + contentId].opened) ) ){
                            var engagement = {
                                userId: this.context.uid,
                                points: 1,
                                type:   'content',
                                message: 'Opened: ' + this.state.dialogueData.title,
                                origin: 'resources',
                                }
                            if(this.state.dialogueData.leadership_category !== undefined){
                                engagement['leadership_category'] = this.state.dialogueData.leadership_category;
                            }
                            if(this.state.dialogueData.leadership_quality  !== undefined){
                                engagement['leadership_quality'] = this.state.dialogueData.leadership_quality ;
                            }
                            AddEngagementPoints(this.props.firebase, engagement);
                            this.props.firebase.contentBookmark(contentId, this.context.uid).set({
                                createdAt: this.props.firebase.fieldValue.serverTimestamp(), 
                                origin: 'resources',
                                opened: true,
                                contentId: this.state.contentId,
                                userId: this.context.uid
                            }, {merge:true }); 
                        }
                    }//end if ismounted
                });
        }
    }
}


export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(HomeCardContentItems);