import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import { AuthUserContext } from '../../Session';
import { withFirebase } from '../../Firebase';
//expansion
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});


class PromptSupport extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            defaultValue: [],
            openSetting: false,
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps) {
        if (this._isMounted && this.props.prompt !== prevProps.prompt) {
            this.setState({
                prompt: this.props.prompt
            })
        }
    }


    render() {
        const { classes } = this.props;
        //console.log('state', this.props)
        return (
            <div className={classes.grow}>
                <Accordion key={"accord_quick"}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-label={"Account-quick"}
                        aria-controls={"Account-quick"}
                        id={"Account-quick"}
                        >
                        Prompt Support
                    </AccordionSummary>
                    <AccordionDetails> 
                        <div style={{width: '100%'}}>
                            <TextField
                                style={{ float: 'right', width: '100%', fontSize: '16px'}}
                                fullWidth
                                key={"prompt"}
                                id={"prompt"}
                                multiline={true}
                                value={this.state.prompt || this.props.prompt || "What is "}
                                onChange={(e) => this.setState({prompt: e.target.value})}
                            />
                            <p>
                                <Button
                                    disabled={this.state.loading || false}
                                    onClick={() => this.getNewSentencesRockyAI(this.state.prompt || this.props.prompt )}
                                >
                                    Prompt It
                                </Button>
                            </p>
                            {this.state.resultPrompt?.length > 2 &&
                                <TextField
                                    style={{ float: 'right', width: '100%', fontSize: '16px'}}
                                    fullWidth
                                    key={"result"}
                                    id={"restul"}
                                    multiline={true}
                                    value={this.state.resultPrompt || ""}
                                />
                            }
                        </div>
                    </AccordionDetails> 
                </Accordion>
            </div>
        );
    }

    getNewSentencesRockyAI = async (text) => {
        var url = 'https://us-central1-rocky-beta-dev.cloudfunctions.net/generate_sentences'
        let data = {
            'input': (text || ""),
            'phrase': "open",
            'rewrite_num' : 0,
            'level' : 4,
            'uniqueness': 3,
            'max_tokens': 1000,
        }
        let formData = {
            method: 'post',
            body: JSON.stringify(data)
        }
        this.setState({loading: true})
        console.log('rewrite examples from', text )
        var response = await requestAPI(url, formData);
        let result = await response.json();
        console.log('results', text, result)
        if(typeof result === 'string'){
            this.setState({resultPrompt: result, loading: false})
        }
        else{
            this.setState({resultPrompt: result.join("\n\n"), loading: false})
        }
        return response;
    }

}

function requestAPI(url, options) {
    //console.log('options', options)
    //console.log(options)
    return fetch(url, {
        mode: 'cors',
        headers: {
            //'Access-Control-Allow-Origin': '*',
            //'Access-Control-Allow-Headers': "*",
            'Content-Type': 'application/json',
        },
        ...options
    });
}

PromptSupport.propTypes = {
    classes: PropTypes.object.isRequired
};

//const condition = authUser => !!authUser;

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(PromptSupport);
