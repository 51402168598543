import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../../Firebase';

import SettingContentArray from '../Modules/SettingContentArray';

//popup
import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

//expansion
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = {
    label: {
        fontSize: '0.8em',
        fontWeight: 700,
        paddingTop: 3,
        paddingRight: 10,
        minWidth: 60,
    },
    right : {
        position: 'absolute',
        right: 5
    },
    messages: {
        padding: 0,
        maxHeight: 320,
        overflow: 'scroll',
    },
};

class DialogIntentSummary extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        var selectedIntent = "";
        this.descriptionSentiment = {
            inform_positive_short: 'A positive response or sentiment that should lead to a follow-up question.',
            inform_positive_long: 'Information about an accomplishment or intention with description.',
            inform_neutral_short: 'An indication about a topic or goal is provided and requires a follow-up question',
            inform_neutral_long: 'A conclusion about a topic is provided.',
            inform_negative_short: 'A negative response or sentiment that requires a follow-up question.',
            inform_negative_long: 'A failure reason is provided.',
            chat: 'A question was asked.',
        }
        this.state = {
            selectedType: "all",
            selectedIntent,
            loading: true,
            messages: [],
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ loading: true });
    }

    componentWillUnmount() {
        this._isMounted = false;
        if(this.unsubscribe!==undefined) this.unsubscribe();
    }

    componentDidUpdate(prevProps)  {
        var newValues = '';
        var oldValues = '';
        if(this.props.intentClassName !== undefined && this.props.intentClassName !== ""){
            newValues = this.props.intentClassName; 
        }
        if(prevProps.intentClassName !== undefined && prevProps.intentClassName !== ""){
            oldValues = prevProps.intentClassName;
        }
        //console.log('opened', this.props.nluIntent )
        if(newValues !== '' && newValues !== oldValues && this._isMounted) {
            this.loadIntentItems(newValues);
        }
        return true
    }

    loadIntentItems(className){
        //console.log('newValues', className)
        if(className!== undefined && className.length>1){
            if(this.unsubscribe!== undefined){
                this.unsubscribe();
            }
            this.unsubscribe = this.props.firebase
            .agentIntentClasses()
            .where('active', '==', true)
            .where('class', 'in', [className, (className+'_future'), (className+'_future')])
            .onSnapshot(snapshot => { 
                var intentSelection = [];
                snapshot.forEach(doc =>{
                    intentSelection.push({...doc.data(), id: doc.id})
                })
                if(this._isMounted){
                    this.setState({intentSelection: intentSelection})
                    //console.log('intentSelection', intentSelection)
                }
            })   
        }
    }

    render() {
        //console.log('state', this.state, this.props)
        return (
            <div>
                {this.state.intentSelection !== undefined && this.state.intentSelection.length > 0 &&
                    this.dialogueIntents()
                }
            </div>
        );
    }

    closeDialogs = () => {
        this.props.closeDialogue()
        this.setState({
            //openDialogueUtterance:false, 
            //selectedSentiment:undefined
        })
    }

    loadIntents = (sentiment) => {
        //console.log('finding error 0')
        var selectedIntent = this.state.selectedIntent;
        this.setState({selectedSentiment : undefined, selectedIntent: undefined})
        //console.log('finding error 1', sentiment, selectedIntent)
        if(this._isMounted && sentiment!==undefined && sentiment!==""){
            this.setState({errorMessage : 'Loading intents list!'})
            //this.setState({selectedSentiment : undefined, selectedIntent: ""})
            //console.log('added: ', sentiment)
            if(this.state[sentiment]===undefined){
                //console.log('finding error 2')
                var intentList = [];
                this.props.firebase
                .agentIntentClasses()
                .where('active', '==', true)
                .where('sentiment', '==', sentiment)
                .where('tenses', 'array-contains', 'default')
                .orderBy('description', 'asc')
                .get().then(snapshot => {
                    //console.log('finding error 3')
                    snapshot.forEach(doc =>{
                        //console.log('finding error 4')
                        var dataRead = doc.data();
                        if(dataRead.intent !== undefined && dataRead.intent !== "" && dataRead.description !== undefined && dataRead.description !== ""){
                            //intentList.push({...doc.data(), id: doc.id})
                            var exampleResponse = dataRead.intent;
                            if(dataRead.responses !== undefined && dataRead.responses.length>0){
                                exampleResponse = dataRead.responses[0];
                            }
                            intentList.push({intent: dataRead.intent, description: dataRead.description, exampleResponse : exampleResponse, id: doc.id})
                        }
                    })
                    if(this._isMounted && intentList.length>0){
                        //console.log('finding error 5')
                        //console.log('added: ', sentiment, intentList)
                        this.setState({[sentiment] : intentList})
                        this.setState({selectedSentiment : sentiment})
                    }
                })
            }
            else{
                this.setState({selectedSentiment : sentiment, selectedIntent})
            }
        }
        else if(this._isMounted ){
            this.setState({selectedSentiment : sentiment, selectedIntent})

        }
    }

    addExampletoIntent = (example, tense, sentiment=undefined, intent=undefined) => {
        if(this._isMounted){
            if (sentiment === undefined){
                sentiment = this.state.selectedSentiment
            }
            if (intent === undefined){
                intent = this.state.selectedIntent
            }
            //console.log('home: ' + this.props.groupIntent, this.props.groupSentiment )
            if(sentiment!==undefined && intent!==undefined){
                //console.log('Searching for ('+tense+ '): ' + intent, sentiment)
                this.setState({errorMessage : 'Searching for ('+tense+ '): ' + intent});
                //ar index = 0;
                this.props.firebase
                .agentIntentClasses()
                .where('sentiment', '==', sentiment)
                .where('intent', '==', intent)
                .where('tenses', 'array-contains', tense)
                .get().then(snapshot => {
                    snapshot.forEach((doc) =>{
                        //console.log( tense + ":", example)
                        var upDateSet = {}
                        var key = 'examples';
                        if(this.props.selectedExplanation!== undefined &&  this.props.selectedExplanation!== ""){
                            upDateSet["responses"] = this.props.firebase.fieldValue.arrayUnion(this.props.selectedExplanation.trim())
                        }
                        if(tense === 'future'){
                            upDateSet["examplesFuture"] =  this.props.firebase.fieldValue.arrayUnion(example.trim());
                            key = 'examplesFuture';
                        }
                        else if (tense === 'past'){
                            upDateSet["examplesPast"] =  this.props.firebase.fieldValue.arrayUnion(example.trim());
                            key = 'examplesPast';
                        }
                        else{
                            upDateSet["examples"] = this.props.firebase.fieldValue.arrayUnion(example.trim());
                            key = 'examples';
                        }
                        this.props.firebase.agentIntent(doc.id).update(upDateSet)
                        if(this._isMounted){
                            //console.log('found '+ index + ":", doc.data(), upDateSet)
                            this.setState({errorMessage : 'Added '+example.trim() +' to ('+tense+ ') ' + intent})
                            if(this.props.addIntent!== undefined) {
                                this.props.addIntent(intent, tense)
                            }
                            if(this.props.handlerRemoveItem!== undefined) {
                                var removeText = example;
                                if (this.props.nluIntent.text !== undefined && this.props.nluIntent.text !== ""){
                                    removeText = this.props.nluIntent.text;
                                }
                                //console.log('remove', removeText)
                                this.props.handlerRemoveItem(removeText, doc.id, key)
                            }
                        }
                        //index += 1;
                    })
                    //console.log('added: ', sentiment, intentList)
                })
            }
            else{
                this.setState({errorMessage : 'Please choose sentiment and intent first!'})
            }
        }
    }

    dialogueIntents = () => {
        const {theme} = this.props;
        //const {selectedText} = this.props;
        this.previewText = "";
        this.previewTextArray = [];

        //console.log('props', this.props)
        //console.log('intent', this.props.nluIntent)
        return(
            <RemoveScroll enabled={this.props.openDialogueIntents || false}>
                <Dialog
                    key={'dialogueutterance'}
                    open={this.props.openDialogueIntents|| false}
                    onClose={this.closeDialogs}
                >
                    <DialogTitle style={theme.dialogTitle}  >
                        <IconButton onClick={this.closeDialogs}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span style={theme.dialogTitle.titleText}>
                            Info about A.I. Intent
                        </span>
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent}>
                        {this.props.openDialogueIntents === true &&
                         <div>
                            {this.state.intentSelection !== undefined &&
                                this.state.intentSelection.map( (item, index) => (
                                    <Accordion index={item.id}
                                        TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-label="AI"
                                            aria-controls="AI"
                                            id="AI"
                                            >
                                            <div>
                                                <strong>{item.description}</strong>
                                                <br/><br/>{item.responses!== undefined ? item.responses[0] : ''}
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div>
                                                <p>
                                                    {item.sentiment + ' > ' + item.class}
                                                </p>
                                                <p>
                                                    <strong>{this.descriptionSentiment[item.sentiment]}</strong>
                                                    <br/><br/>{item.guide}
                                                </p>
                                                {item.examples !== undefined && item.examples.length > 0 &&
                                                    <>
                                                        <SettingContentArray
                                                            dbUpdateLink={this.props.firebase.agentIntent(item.id)}
                                                            title={"Affirmative response of bot"} 
                                                            content={item} 
                                                            noLabel={true}
                                                            params={['responses']}
                                                        />
                                                        <SettingContentArray
                                                            title={"User Input Examples"} 
                                                            access={false}
                                                            content={item} 
                                                            showShort={true}
                                                            noLabel={true}
                                                            params={['examples']}
                                                        />
                                                    </>
                                                }
                                                {item.examplesPast !== undefined && item.examplesPast.length > 0 &&
                                                    <>
                                                        <SettingContentArray
                                                            dbUpdateLink={this.props.firebase.agentIntent(item.id)}
                                                            title={"Affirmative response (past tense)"} 
                                                            content={item} 
                                                            noLabel={true}
                                                            params={['responsesPast']}
                                                        />
                                                        <SettingContentArray
                                                            title={"User Input Examples"} 
                                                            access={false}
                                                            showShort={true}
                                                            content={item} 
                                                            noLabel={true}
                                                            params={['examplesPast']}
                                                        />
                                                    </>
                                                }
                                                {item.examplesFuture !== undefined && item.examplesFuture.length > 0 &&
                                                    <>
                                                        <SettingContentArray
                                                            dbUpdateLink={this.props.firebase.agentIntent(item.id)}
                                                            title={"Affirmative response (future tense)"} 
                                                            content={item} 
                                                            noLabel={true}
                                                            params={['responsesFuture']}
                                                        />
                                                        <SettingContentArray
                                                            title={"User Input Examples"} 
                                                            access={false}
                                                            content={item} 
                                                            showShort={true}
                                                            noLabel={true}
                                                            params={['examplesFuture']}
                                                        />
                                                    </>
                                                }
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </div>
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }
}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(DialogIntentSummary);
