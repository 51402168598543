import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import { AddAnalytics } from '../Modules/AddAnalytics';
import {stylesSignin, linksInfo, pageText, PushForwardAfterSigning} from '../SignIn';
import { UpdateExistingUserSignUp } from '../SignUp';
// auto push after reload with social
import ChatBlock from '../Routine/ChatBlock';
import {ReturnCoachAccountSettings} from './SignInCoachPage';
import {AddCodeToExistingAccountSet, GetUltimimUserSetup} from '../Modules/DefaultCode';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

//import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import {StoreMobileDeviceInfo } from '../Modules/StoreMobileDeviceInfo';
//modules from other file
import { GetDefaultUserSetup, MyInstallationOfflineDataSet } from '../Modules/DefaultCode';
import { DataCacheWrite} from "../Modules/DataCache";

import EmailIcon from '@material-ui/icons/Email';
//import logo from "../../images/rockyai-logo-image-only.png";
import logo from "../../images/rocky-logo-animated.gif";
//import logoCaption from "../../images/rockyai-logo-caption.png";
//import googleIcon from "../../images/google.jpg";
import googleIcon from "../../images/google_button.png";
//import facebookIcon from "../../images/facebook.jpg";
import facebookIcon from "../../images/facebook_button.png";
//import appleIcon from "../../images/apple.png";
import appleIcon from "../../images/appleid_button.png";
//import twitterIcon from "../../images/twitter.jpg";

// MUI stuff
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import LoadingProgress from '../Modules/LoadingProgress';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from '@material-ui/core/IconButton';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
//import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';

//import CircularProgress from '@material-ui/core/CircularProgress';

/// Anonymous sign up
//import Switch from '@material-ui/core/Switch';

import queryString from 'query-string';
import { IsMobileDevice } from "../Modules/IsMobileDevice";
import { CheckCodeAndTokenAppSumo } from "../Modules/URLQueries";

class SignInPage extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        var loginType = 'normal';
        var showDetails = undefined;
        //console.log('window locoaion', window.location)
        if (window?.location?.search?.length > 2){
            //var query = queryString.parse(window.location.search)
            var urlSearch = window.location.href.slice(window.location.href.indexOf('?'));
            var query = queryString.parse(urlSearch);
            //console.log("detail loc", query)
            if(query.scope!==undefined && query.scope === "profile:user_id"){
                loginType = "alexaLinking";
            }
        }
        var code = CheckCodeAndTokenAppSumo(this);
        if(window?.location?.href.includes("showdetails=false")){
            showDetails = false;
            window.sessionStorage.setItem('showdetails', false);
        }
        var flagExistingAccount = false;
        if(window?.location?.href.includes("flagExistingAccount=true")){
            flagExistingAccount = true;
        }
        var loadedCode = undefined;
        const urlCoachCode = window?.sessionStorage?.getItem('urlCoachCode') || "";
        if(urlCoachCode?.length > 3 && urlCoachCode[0] === "#"){
            loadedCode = urlCoachCode;
        }
        this.state = { ...INITIAL_STATE ,loginType, showDetails, flagExistingAccount, loadedCode};
    }

    componentDidMount() {
        const { theme } = this.props;
        this._isMounted = true;
        if(this._isMounted){
            if(window.StatusBar !== undefined && this.state.firebaseConnectionError !== true){
                window.StatusBar.overlaysWebView(false);
                window.StatusBar.backgroundColorByHexString(theme.palette.themeAltLoading);
                window.StatusBar.styleLightContent();
            }
        }
        //console.log('SigninPage Mpunting', this.state, this.props)
        //this.internetConnectionCheck();
        AddAnalytics('screen_view', 'PageSignIn', 'SignInUser');
        GetUltimimUserSetup(this.props.firebase, MyInstallationOfflineDataSet, (this.props.defaultSet || {}), (this.props.defaultSet?.programCode || "")).then((newResult) => {
            this.setState({installationSet: newResult});
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    internetConnectionCheck() {
        this.props.firebase.onDisconnectSimple(this, (connected) => {
            if(this._isMounted){
                if(connected === false && this.state.firebaseConnectionError === undefined){
                    this.setState({firebaseConnectionMessage : 'checking your internet...'})
                }
                else if(connected === false){
                    this.setState({firebaseConnectionMessage : 'Trying to connect with the internet!'})
                }
            }
        });
    }

    pushWelcome = () => {
        var iframeLogin = false;
        if(process?.env?.REACT_APP_DEVICE_PLATFORM==="BROWSER"){
            try{
                if(window.parent !== window.self){
                    //console.log('iframe 1x', window.parent, window.self)
                    iframeLogin = true;
                    window.parent.postMessage({ loggedIn: true });
                    //console.log('iframe 2x', window.parent, window.self)
                    window.parent.postMessage('loggedIn: true');
                    //console.log('iframe 3x', window.parent, window.self)
                    //window.top.location.href = '#/home'
                }
            }
            catch(e){
                console.log('e', e)
            }
        }
        var page = ROUTES.WELCOMECHAT;
        if(iframeLogin){
            try{
                window.top.location.href = '#'+page+"?showdetails="+this.state.showDetails;
            }
            catch(e){
                this.props.history.push( {pathname: page})
            }
        }
        else{
            this.props.history.push( {pathname: page})
        }
    }

    pushExisting = () => {
        this.setState({flagExistingAccount: true});
        var iframeLogin = false;
        if(process?.env?.REACT_APP_DEVICE_PLATFORM==="BROWSER"){
            try{
                if(window.parent !== window.self){
                    //console.log('iframe 1x', window.parent, window.self)
                    iframeLogin = true;
                    window.parent.postMessage({ loggedIn: true });
                    //console.log('iframe 2x', window.parent, window.self)
                    window.parent.postMessage('loggedIn: true');
                    //console.log('iframe 3x', window.parent, window.self)
                    //window.top.location.href = '#/home'
                }
            }
            catch(e){
                console.log('e', e)
            }
        }
        var page = ROUTES.SIGN_IN;
        if(iframeLogin){
            try{
                this.setState({flagExistingAccount: false});
                page = ROUTES.HOME;
                window.top.location.href = '#'+page+"?showdetails="+this.state.showDetails+"&flagExistingAccount=true";
            }
            catch(e){
            }
        }
        else{
        }
    }
    
    render () {
        const { classes, theme, defaultSet } = this.props;
        const { error } = this.state;
        //console.log('Sign in Page state reload', this.state, this.props)
        var showProgram = false;
        var innerWidth = 0;
        if(defaultSet?.programDescription?.length > 100 && this.state.showDetails !== false){
            showProgram = true;
            innerWidth = window.innerWidth;
        }
        if(this.state.firebaseConnectionError===true){
            return(
                <div style={theme.rootFrame}>
                    <div className={classes.rootAnimated}>
                        <div style={{paddingTop: '50%', paddingBottom:20}}>
                            <LoadingProgress 
                                defaultSet={this.props.defaultSet}
                                type={'LoadingPageAnimated'} color='light' loadingText={"I'm getting ready"} />
                        </div>
                        <p style={theme.textCenterPadding}>
                            &nbsp; {this.state.firebaseConnectionMessage}
                        </p>
                    </div>
                </div>
            )
        }
        else{
            return(
                <div style={theme.rootFrame}>
                    <div className={(IsMobileDevice() || this.props.defaultSet?.programColorBack?.includes('#')) ? classes.rootAnimated : classes.root}>
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            {showProgram && innerWidth>=1000 &&
                                <Grid item xs style={{ maxWidth: 'calc(100% - 600px)'}}>
                                    {this.renderDefaultProgram(innerWidth)}
                                </Grid>
                            }
                            <Grid item xs style={{ maxWidth: 520 }}>
                                <div style={{
                                        ...((showProgram && innerWidth>=1000)? theme.WelcomeLoginDT : theme.WelcomeLogin),
                                        //marginLeft: (showProgram && innerWidth>=1000 )? 0 : 10, 
                                        //borderTopLeftRadius: (showProgram && innerWidth>=1000) ? 0 : theme.borderRadius, 
                                        //borderBottomLeftRadius: (showProgram && innerWidth>=1000) ? 0 : theme.borderRadius, 
                                        }}
                                    >
                                    
                                    {!(this.props.errorMessage?.length > 0) && (this.state.flagExistingAccount !== true && this.state.loginType !== "alexaLinking")
                                    ?   
                                        <>
                                            <div style={theme.logoContainer}>
                                                {this.renderTitle()}
                                            </div>
                                            <div>
                                                <ChatBlock
                                                    style={{marginTop:10, marginBottom:10}}
                                                    key={'askName'}
                                                    variant={'askName'}
                                                    messages={[{
                                                        text: this.props.defaultSet?.programIntro?.Hello?.length > 0 ? this.props.defaultSet.programIntro.Hello : ["Hello", "I am your AI Coach", "Do we already know each other?"],
                                                        isCoach: true, 
                                                        uid: 'askName'
                                                    } ]} 
                                                    typingBotFinishTypingNow={this.state.askName || false}
                                                    handlerTypingDone={() => {
                                                        if(this._isMounted){
                                                            this.setState({askName: true});
                                                            //console.log('message end')
                                                            if(this.refAskName !== null && this.refAskName !== undefined){
                                                                //console.log('message scrooll')
                                                                this.refAskName.scrollIntoView({ behavior: "smooth" });
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div style={{paddingTop: 40, paddingBottom: 80}}>
                                                <div className={classes.socialLogins}>
                                                    {this.state.loadedCode?.includes("#")
                                                    ?
                                                        <Button 
                                                            onClick={() => {this.props.history.push( {pathname: ROUTES.SIGN_UP})}} 
                                                        >
                                                            It's my first signup
                                                        </Button>
                                                    :
                                                        <Button 
                                                            onClick={this.pushWelcome} 
                                                        >
                                                            {defaultSet?.programAvatarName?.length > 0
                                                                ?   ("I am new to " + defaultSet.programAvatarName)
                                                                :   defaultSet?.programURL?.length > 0
                                                                    ?   "I am new to here"
                                                                    :   "I am new to Rocky"
                                                            }
                                                        </Button>
                                                    }
                                                </div>
                                                <table width="100%" style={{paddingTop: 10, paddingBottom: 10}}>
                                                    <tbody>
                                                        <tr>
                                                            <td><hr /></td>
                                                            <td style={{width:1, padding: '0 10px', whiteSpace: 'nowrap'}}>
                                                                <span className={classes.socialLoginPrompt}>or</span>
                                                            </td>
                                                            <td><hr /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className={classes.socialLogins}>
                                                    <Button onClick={this.pushExisting} >
                                                        I have an account
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                    :
                                        <>
                                            <div style={theme.logoContainer}>
                                                {this.renderTitle()}
                                            </div>
                                            {this.props.errorMessage?.length > 0
                                            &&  
                                                <div style={{textAlign: 'center'}}>
                                                    <p className={classes.error}>
                                                        {this.props.errorMessage}
                                                    </p>
                                                </div>
                                            }
                                            <div style={{width: "100%", textAlign: 'left'}}>
                                                <Button onClick={() => this.setState({flagExistingAccount: false})} variant="outlined">
                                                    {theme.WelcomeLogin.buttonTextDark === true
                                                        ?   <KeyboardArrowLeft style={{color: 'black'}}/>
                                                        :   <KeyboardArrowLeft />
                                                    }
                                                    {theme.WelcomeLogin.buttonTextDark === true
                                                        ?   <span style={{color: 'black'}}>Back</span>
                                                        :   'Back'
                                                    }
                                                </Button>
                                            </div>
                                            <div>
                                                {(this.props.defaultSet?.programUX?.SocLog === false)
                                                ?   ""
                                                :   
                                                    <>
                                                        <div style={theme.logoContainer}>
                                                            <p style={theme.divCenter}>
                                                                <span className={classes.socialLoginPrompt}>
                                                                    Sign in to continue&nbsp;
                                                                    {this.state.loginType === "alexaLinking"
                                                                        &&  <strong> <br/> and connect with Alexa,&nbsp;</strong>
                                                                    }
                                                                    with&nbsp;
                                                                </span>
                                                            </p>
                                                        </div>  
                                                        <SignInSocialForward 
                                                            loginType={this.state.loginType} 
                                                            showOnboarding={this.state.showdetails || true}
                                                            myInstallationOfflineDataSet={this.state.installationSet || undefined}
                                                            defaultSet={defaultSet || {}}
                                                        />
                                                        <table width="100%">
                                                            <tbody>
                                                                <tr>
                                                                    <td><hr /></td>
                                                                    <td style={{width:1, padding: '0 10px', whiteSpace: 'nowrap'}}>
                                                                        <span className={classes.socialLoginPrompt}>or</span>
                                                                    </td>
                                                                    <td><hr /></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </>
                                                }
                                                <SignInForm 
                                                    loginType={this.state.loginType}
                                                    showEmailSignInForm={this.props.defaultSet?.programUX?.SocLog === false ? true : undefined}
                                                    showOnboarding={this.state.showdetails || true}
                                                    myInstallationOfflineDataSet={this.state.installationSet || undefined}
                                                    defaultSet={defaultSet || {}}
                                                /> 
                                            </div>
                                            <div style={{flexGrow: 2, flexDirection: 'column'}}>
                                            </div>
                                            <div>
                                                <table width="100%">
                                                    <tbody>
                                                        <tr>
                                                            <td><hr /></td>
                                                            <td style={{width:1, padding: '0 10px', whiteSpace: 'nowrap'}}>
                                                                <span className={classes.socialLoginPrompt}>Don't have an account?</span>
                                                            </td>
                                                            <td><hr /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className={classes.socialLogins}>
                                                    <Link to={{ pathname: ROUTES.SIGN_UP, state: {checkAnonymous: true}}}>
                                                        <Button  
                                                            variant='outlined'
                                                            //color='secondary'
                                                            className={classes.buttonBottom}
                                                        >
                                                            <span style={{color: theme.palette.secondary.main}}>Sign Up</span>
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div> 
                                        </>
                                    }
                                    <div className={classes.footer}>
                                        {linksInfo(defaultSet)}
                                    </div>
                                </div>
                            </Grid>
                            {showProgram && innerWidth < 1000 &&
                                <Grid item xs={12} style={{ maxWidth: 520 }}>
                                    {this.renderDefaultProgram()}
                                </Grid>
                            }
                        </Grid>
                    </div>
                </div>
            )
        }
    };//end render

    renderTitle(){
        const {classes, theme, defaultSet} = this.props;
        return(
            <React.Fragment>
                <img src={defaultSet?.programLogo?.length > 5 
                    ?   defaultSet.programLogo
                    :   logo
                    }
                    className={classes.logo} alt="Rocky.ai Logo Chatbot Journaling" 
                />
                <p style={theme.divCenter}>
                    <strong>
                        {defaultSet?.programTitle?.length > 5 
                            ?   defaultSet.programTitle
                            :   'Welcome to you AI Coach'
                        }
                    </strong><br/>
                    <span>
                        {defaultSet?.programSubTitle?.length > 5 
                            ?   defaultSet.programSubTitle
                            :   "Your Personal Development AI Coach"
                                //'Your Personal Development Sparring Partner'
                        }
                        
                    </span>
                </p>
            </React.Fragment>
        )
    }

    renderDefaultProgram(innerWidth=0){
        const { classes, theme, defaultSet } = this.props;
        //console.log('theme', classes.innerHTML)
        return(
            <div style={{
                    ...theme.WelcomeLogin, 
                    padding: 20, 
                    marginRight: 10, 
                    //borderTopRightRadius: innerWidth>=1000 ? 0 : theme.borderRadius, 
                    //borderBottomRightRadius: innerWidth>=1000 ? 0 : theme.borderRadius, 
                    width: 'auto', 
                    maxWidth: 'auto',
                    //borderStyle: "solid",
                    //borderWidth: 1,
                    //borderColor: theme.palette.backPaper
                }}
            >
                <div>
                    <h1>
                        {defaultSet.programTitle}
                    </h1>
                    <h2>
                        {defaultSet.programSubTitle}
                    </h2>
                    <div style={{width: '100%', textAlign: 'center'}}>
                        {defaultSet?.programImage?.includes("http")
                            &&
                            <img
                                src={defaultSet.programImage}
                                style={{...theme.logo, width: '100%', maxWidth: 600}} 
                                alt="AI Coaching Chatbot" 
                            />
                        }
                    </div>
                    <div
                        className={classes.innerHTML}
                        onClick = {(event) => {
                            try{
                                const link = event.target.getAttribute('href');
                                if(link !== undefined && link !== null && link !== ""){
                                    event.preventDefault();
                                    window.open(link, "_blank")
                                }
                            }
                            catch(e){
                                console.log('No Link', e)
                            }
                        }}
                        dangerouslySetInnerHTML={{ __html: defaultSet.programDescription }}>
                    </div>
                </div>
                <div >
                    &nbsp;
                </div>
                <div style={theme.logoContainer}>
                    {defaultSet.programBrand?.length > 10
                        &&
                        <img
                            onClick={() => {
                                if (defaultSet.programSupportURL?.length>10){ window.open((defaultSet?.programSupportURL ), "_blank")}
                                else{ void(0)}
                            }}
                            src={defaultSet.programBrand}
                            style={theme.logo} 
                            alt="AI Coaching Chatbot" 
                        />
                    }
                    {defaultSet.programCompany?.length > 10
                        &&
                        <div
                            onClick={() => {
                                if (defaultSet.programSupportURL?.length>10){ window.open((defaultSet?.programSupportURL ), "_blank")}
                                else{ void(0)}
                            }}
                        >
                            {defaultSet.programCompany}
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const INITIAL_STATE = {
    email: "",
    password: "",
    error: null
};

class SignInFormBase extends Component {
    constructor(props) {
        super(props);
        if(props.showEmailSignInForm){
            INITIAL_STATE['showEmailSignInForm'] = props.showEmailSignInForm
        }
        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { email, password } = this.state;
        var error = {};
        if(password!== undefined && password!=='' && password.length>1){
            this.setState({redirectLoginDetected: true, error: null})
            //console.log('loging')
            this.props.firebase
                .doSignInWithEmailAndPassword(email, password)
                .then((authUser) => {
                    //console.log('doSignInWithEmailAndPassword', authUser)
                    var receivedUserId = authUser.user.uid;
                    StoreMobileDeviceInfo(this.props.firebase.user(receivedUserId));
                    //console.log('SinginPage listener esle results', receivedUserId);
                    //this.setState({redirectHome : true});
                    this.doublecheckNewUserSignin('email login', email, undefined, receivedUserId);
                })
                .catch(error => {
                    if (error.code === pageText.ERROR_CODE_USER_NOT_FOUND) {
                        error.message = pageText.ERROR_MSG_USER_NOT_FOUND;
                    }
                    if (error.code === pageText.ERROR_CODE_WRONG_PASSWORD) {
                        error.message = pageText.ERROR_MSG_WRONG_PASSWORD;
                        this.props.firebase.fetchSignInMethodsForEmail(email)
                        .then(result => {
                            if(result.includes('google.com')){
                                error.message = pageText.ERROR_MSG_SOCIAL_GOOGLE;
                                this.setState({ error });
                            }
                            else if(result.includes('apple.com')){
                                error.message = pageText.ERROR_MSG_SOCIAL_APPLE;
                                this.setState({ error });
                            }
                            else if(result.includes('facebook.com')){
                                error.message = pageText.ERROR_MSG_SOCIAL_FACEBOOK;
                                this.setState({ error });
                            }
                            else if(result.includes('microsoft.com')){
                                error.message = pageText.ERROR_MSG_SOCIAL_MICROSOFT;
                                this.setState({ error });
                            }
                        })
                    }
                    if (error.code === pageText.ERROR_CODE_INVALID_EMAIL) {
                        error.message = pageText.ERROR_MSG_INVALID_EMAIL;
                    }
                    //console.log('error listener', error.code , error);
                    this.setState({ error, redirectLoginDetected: false });
                });
            //console.log('loging preventDefault')
            event.preventDefault();
        }
        else if (email === ''){
            error = {code: '000', message: 'Please add your email!'};
            //console.log('error listener 2', error);
            this.setState({ error, redirectLoginDetected: false });
            event.preventDefault();
        }
        else{ 
            error = {code: '000', message: 'Please add your password!'};
            //console.log('error listener 3', error);
            this.setState({ error, redirectLoginDetected: false });
            event.preventDefault();
        }
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value.trim() });
    };

    doublecheckNewUserSignin = (signInMethod, email, username, uid) => {
        if(uid!==undefined && uid!==null && uid!==''){
            //console.log('doublechek', username, uid)
            var userExisting = true;
            this.props.firebase.user(uid).get()
            .then(  (userCheck) =>  {
                //console.log('usercheck', userCheck, userCheck.data())
                //if(userCheck === undefined || userCheck === null || userCheck.data()===undefined 
                //|| userCheck.data()===null || (Object.keys(userCheck.data()).length <= 2)){
                if(!userCheck.exists) {
                    var data = userCheck.data();
                    if (!("username" in data)) {
                        userExisting = false;
                        // deleted or missing user content, start new signup
                        //console.log('no userdata available')
                        signInMethod = signInMethod + " after undetected user data";
                        if(this.state.username!==undefined && this.state.username.length>1){
                            username = this.state.username;
                        }
                        else if(this.props.username!==undefined && this.props.username.length>1){
                            username = this.props.username;
                        }
                        if(username===undefined || username ==='') username = 'Padawan';
                        this.addNewUserSignin(signInMethod, email, username, uid)
                        .then( (result )=> {
                            //console.log( 'doublecheck', result)
                            if(result.success) {
                                setTimeout(() => {
                                    console.log('Timed Stored user set')
                                    PushForwardAfterSigning(this);
                                    /***
                                    
                                    //this.props.history.replace(ROUTES.PROFILE);
                                    if(this.props.loginType === "alexaLinking"){
                                        this.props.history.push(ROUTES.ALEXA_SIGN_IN);
                                    }
                                    else if(result.onboardingRequired === false){
                                        this.props.history.push(ROUTES.HOME);
                                    }
                                    else{
                                        this.props.history.push(ROUTES.ONBOARDING);
                                    }
                                    */
                                }, 600);
                            }
                            else {
                                console.log('push sign up')
                                this.props.history.push(ROUTES.SIGN_UP);
                            }
                        })
                    }
                }
                if(userExisting) {
                    //console.log('Check succesfull user data exist', userCheck.data())
                    //this.props.history.push(ROUTES.HOME);
                    var userDataSet = {...userCheck.data(), uid: uid};
                    if(this.props.installationSet?.addingCode !== undefined){
                        var tempSet = AddCodeToExistingAccountSet(userDataSet, this.props.installationSet);
                        if(tempSet?.programs?.every(v => userDataSet.programs?.includes(v) && tempSet.programCode === userDataSet.programCode)){
                            console.log('a tempset included');
                        }
                        else{
                            console.log('missing tempset');
                            userDataSet = tempSet;
                            this.props.firebase.user(uid).update(userDataSet);
                        }
                        //console.log('added overwrite xx users account', userDataSet)
                    }
                    //console.log('datacahse write', this.state, this.props)
                    DataCacheWrite('authUser', {uid: uid, timezone: userDataSet['timezone'], roles: userDataSet['roles']}, 1);
                    //DataCacheWrite('authUser', userDataSet, 1);
                    //console.log('datacahse writeeou after', this.state, this.props)
                    var userData = userCheck.data();
                    var usernameOld = userData.username;
                    var username = usernameOld;
                    if(this.state.username!==undefined && this.state.username.length>1){
                        username = this.state.username;
                    }
                    else if(this.props.username!==undefined && this.props.username.length>1){
                        username = this.props.username;
                    }
                    setTimeout(() => {
                        console.log('timeout done')
                        //console.log('Stored user set', userData)
                    }, 600);
                    ////// Ensure that the user is not repeating the onboarding
                    //userDataSet['onboardingDone'] = true;
                    PushForwardAfterSigning(this, userDataSet);
                    //////
                    if(username !== usernameOld){
                        //console.log('udpate user name ofr existing user', username)
                        this.props.firebase.user(uid).update({ username: username });
                    }
                }
            })
            .catch(error => {
                console.log('Doublecheck error', error)
                this.setState({redirectLoginDetected: false})
            })
        }
        else{
            console.log('No Id delivered with doublecheck', uid)
            this.setState({redirectLoginDetected: false})
        }
    }

    addNewUserSignin = async (signInMethod, email, username, uid ) => {
        try{
            var newSet = await GetDefaultUserSetup(this.props.firebase);
            if(this.props.installationSet?.newUser !== undefined){
                newSet = Object.assign(newSet, this.props.installationSet?.newUser);
                console.log('added special users signing account', newSet)
            }
            const timestamp = this.props.firebase.fieldValue.serverTimestamp();
            if (email && email !== ''){
                newSet = Object.assign(newSet, {
                    email,
                    username,
                    journeyStartedAt: timestamp,
                    signInMethod,
                    uid: uid
                });
                //console.log('init db to be stores', newSet);
                //console.log('user id', result.user.uid);
                return this.props.firebase.user(uid).set(newSet, { merge: true })
                .then( ( )=> {
                    DataCacheWrite('authUser', {uid: uid, timezone: newSet['timezone'], roles: newSet['roles']}, 1);
                    //DataCacheWrite('authUser', newSet, 1)
                    //console.log('going home after 1', this.props.history)
                    //console.log('registered user', this.state)
                    //console.log('registered user', this.state.readyToLoad)
                    //console.log('registered user', this.props)
                    //console.log('going home after2', this.props.history)
                    var onboarded = false;
                    if(newSet?.onboardingDone !== undefined && newSet?.onboardingDone===true ){
                        onboarded = true;
                    }
                    return {success: true, onboardingDone: onboarded}; 
                   /////////// need change is false
                })
                
            } else{
                //console.log('no email', result)
                var error = new Error(pageText.ERROR_MSG_SOCIAL_FAILED); 
                this.setState({error});
                return {success: false, onboardingDone: false};
            }        
        } catch (error) {
            //console.log('error', error.message)
            error.message = pageText.ERROR_MSG_GETDEFAULTSETTING_FAILED;
            this.setState({ error });
            return {success: false, onboardingDone: false};
        }
    }

    render() {
        const { classes, theme } = this.props;
        const { email, password, error } = this.state;
        //console.log('SigninPage SigningForm update login state', this.state)
        //const isInvalid = password === "" || email === "";
        return (
            <div>
                <Dialog
                    open={this.state.redirectLoginDetected || false}
                    onClose={()=>{this.setState({redirectLoginDetected: false})}}
                    PaperProps={{style:{borderRadius: theme.borderRadius}}}
                >
                    <DialogContent>
                        <div className={classes.form}>
                            {!error &&
                                <LoadingProgress
                                    defaultSet={this.props.defaultSet}
                                    type={'LoadingPageAnimated'} loadingText={"loading login"} />
                            }
                            {error 
                                ?   <p className={classes.error}>{error.message}</p>
                                :   this.props.errorMessage?.length > 0
                                    ?   <p className={classes.error}>{this.props.errorMessage}</p>
                                    :   ""
                            }
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{this.setState({redirectLoginDetected: false})}} color="primary">
                        Close
                        </Button>
                    </DialogActions>
                </Dialog>
                {this.state.showEmailSignInForm !== true
                    &&
                    <div className={classes.socialLogins}>
                        <Button 
                            onClick={() => this.setState({showEmailSignInForm: true})}
                            className={classes.buttonBottom}
                            //variant = "contained"
                            >
                            <EmailIcon/>&nbsp;Log In with Email
                        </Button>
                    </div>
                }
                {this.state.showEmailSignInForm === true
                    &&
                    <form onSubmit={this.onSubmit}>
                        <TextField
                            name="email"
                            autoComplete="username"
                            label={pageText.form_email}
                            value={email}
                            onChange={this.onChange}
                            type="text"
                            variant="outlined"
                            placeholder={pageText.form_email}
                            fullWidth
                            className={classes.textField}
                        />
                        <TextField
                            name="password"
                            autoComplete="current-password"
                            label={pageText.form_password}
                            value={password}
                            onChange={this.onChange}
                            type={this.state.showPassword ? "text" : "password"}
                            variant="outlined"
                            placeholder={pageText.form_password}
                            fullWidth
                            className={classes.textField}
                            InputProps={{ // <-- This is where the toggle button is added.
                                endAdornment: (
                                <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => this.setState({showPassword: !this.state.showPassword})}
                                        >
                                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                </InputAdornment>
                                )
                            }}
                        />
                        {error && error !== null &&
                            error.code !== pageText.ERROR_CODE_USER_NOT_FOUND && error.code !== pageText.ERROR_CODE_WRONG_PASSWORD
                            ?
                                <p className={classes.error}>{error.message}</p>
                            :
                                ""
                        }

                        {error && error !== null &&
                            error.code === pageText.ERROR_CODE_USER_NOT_FOUND
                            ?
                                <p className={classes.error}>{error.message} <Link to={ROUTES.SIGN_UP}>{pageText.linkResetNEW}</Link></p>
                            :
                                ""
                        }
                        {error && error !== null &&
                            error.code === pageText.ERROR_CODE_WRONG_PASSWORD
                            ?
                                <p className={classes.error}>{error.message} <Link to={ROUTES.PASSWORD_FORGET}>{pageText.linkResetPW}</Link></p>
                            :
                                    ""
                        }
                        <div>
                            <Link to={ROUTES.PASSWORD_FORGET}>
                                    {pageText.linkForgot}
                            </Link>
                        </div>
                        <div className={classes.socialLogins}>
                            <Button className={classes.buttonBottom}
                                variant = "contained"
                                //disabled={isInvalid}
                                type="submit"  >
                                {pageText.linkLogin}
                            </Button>
                        </div>
                    </form>
                }
                <div className={classes.socialLogins}>
                    <p style={{textAlign: 'center', margin: 20, fontSize: 14}}> 
                        By continuing, you agree to the
                        {" "}
                        <span style={{color: theme.palette.secondary.main, textDecoration: 'underline'}}
                            onClick={ () => window.open(ROUTES.LinkTermsURL(this), "_blank")}>
                            Terms of Service
                        </span>
                        {". "}
                        Read our 
                        {" "}
                        <span style={{color: theme.palette.secondary.main, textDecoration: 'underline'}}
                            onClick={ () => window.open(ROUTES.LinkPrivacyURL(this), "_blank")}>
                            Privacy Policy
                        </span>
                    </p>
                </div>
            </div>
        );
    }
}

class SignInSocialBaseForward extends Component {
    constructor(props) {
        super(props);
        //console.log('initiated SignInSocialBaseForward', props)
        //this.shrinkBackTrick = React.createRef();
        this.windowHeight = window.innerHeight;
        this.windowWidth = window.innerWidth;
        //console.log('dataSet loaded and initiated SignInSocialBaseForward', dataSet)
        this.state = { 
            error: null,
            redirectLoginDetected: false,
            readyToLoad: false
        };
    }

    componentDidMount() {
        //console.log('Did Mount')
        if (window.sessionStorage.getItem('pending')) {
            window.sessionStorage.removeItem('pending');
            //console.log('redirect',window.sessionStorage )
            this.setState({redirectLoginDetected: true, error: null});
            //console.log('loging 1')
            //this.redirectListener();
        }
        this.windowHeight = window.innerHeight;
        this.windowWidth = window.innerWidth;
    }
    componentDidUpdate(){
        if(this.state.readyToLoad){
            //console.log('UPDATE STATE TO PUSH')
            PushForwardAfterSigning(this, (this.props.installationSet || undefined));
            /***
            if(this.props.loginType === "alexaLinking"){
                this.props.history.push(ROUTES.ALEXA_SIGN_IN);
            }
            else{
                //this.props.history.replace(ROUTES.HOME);
                this.props.history.push(ROUTES.ONBOARDING);
            }
            */
        }
    }

    redirectListener() {
        try{
            this.props.firebase.socialLoginRedirectResultListener()
            .then((result) =>  {
                //console.log("redicret stop")
                this.redirectWithResult(result);
            })
            .catch(error => {
                if (error.code === pageText.ERROR_CODE_ACCOUNT_EXISTS) {
                error.message = pageText.ERROR_MSG_ACCOUNT_EXISTS;
                    }
                else if (error.code === pageText.CODE_INVALID_CREDENTIAL) {
                    error.message = pageText.ERROR_MSG_INVALID_CREDENTIAL;
                }
                console.log('redict error', error)
                this.setState({ error });
            });
        } catch (error){
            console.log('redirect listener error', error)
        }
    }

    redirectWithResult(result){
        //console.log('result social', result)
        if (result.credential && result.credential !== null) {
            this.setState({redirectLoginDetected: true, error:null});
            //console.log('loging w')
            // This gives you a Google Access Token. You can use it to access the Google API.
            // var token = socialAuthUser.credential.accessToken;
            //console.log('user', result)
            var signInMethod = "Social via Browser";
            //var signInMethod = result.credential.signInMethod;
            var email = result.additionalUserInfo.profile.email;
            var username = result.additionalUserInfo.profile.name;
            if(this.state.username!==undefined && this.state.username.length>1){
                username = this.state.username;
            }
            else if(this.props.username!==undefined && this.props.username.length>1){
                username = this.props.username;
            }
            if(username===undefined || username==='') username = 'Padawan';
            var uid = result.user.uid;
            if(result.additionalUserInfo.isNewUser){
                console.log('new user created with social app signup')
                //console.log('going home after3', this.props.history)
                return this.addNewUser(signInMethod, email, username, uid)
                .then( (success )=> {
                    if(success){
                        //console.log('going home before4 success', success)
                        this.setState({readyToLoad : true});
                        //console.log('going home after4', this.props.history)
                    }
                    else{
                        var errorS = {message: 'Cannot connect with social login, please use an email to sign up'};
                        this.setState({redirectLoginDetected: true, error: errorS});
                    }
                })
            }
            else{
                //console.log('listener esle results', result, username);
                //this.setState({redirectHome : true});
                this.doublecheckNewUser(signInMethod, email, username, uid);
                //console.log('afer push');
            }
            // ...
        }//end if credential
        else{
            var errorP = {message: 'Cannot connect with social login, please use an email to sign up'};
            this.setState({redirectLoginDetected: true, error: errorP});
        }
    }

    addNewUser = async (signInMethod, email, username, uid) => {
        var newSet = this.state.installationSet;
        try{
            const timestamp = this.props.firebase.fieldValue.serverTimestamp();
            if (email && email !== ''){
                newSet = Object.assign(newSet, {
                    email,
                    username,
                    journeyStartedAt: timestamp,
                    signInMethod,
                    uid: uid
                });
                var onboardingSet = '';
                if(window.sessionStorage.getItem('onboardingResponses')){
                    try{
                        var arrayOnboardingResponses = window.sessionStorage.getItem('onboardingResponses').split(',');
                        newSet['onboardingResponses'] = arrayOnboardingResponses;
                        onboardingSet = arrayOnboardingResponses;
                    }
                    catch(err) {
                        console.log('Error getting onboardingResponses 3', err)
                    }
                    window.sessionStorage.removeItem('onboardingResponses');
                }
                AddAnalytics('sign_up', signInMethod, onboardingSet);
                if(this.props.isCoach){
                    var updateExtraSet = ReturnCoachAccountSettings(this, uid, newSet);
                    newSet = Object.assign(newSet, updateExtraSet);
                }
                //console.log('init db to be stores', newSet);
                //console.log('user id', result.user.uid);
                return this.props.firebase.user(uid).set(newSet, { merge: true })
                    .then( ( )=> {
                    DataCacheWrite('authUser', {uid: uid, timezone: newSet['timezone'], roles: newSet['roles']}, 1);
                    //console.log('registered user new', this.state)
                    //this.props.history.replace(ROUTES.PROFILE);
                    PushForwardAfterSigning(this, newSet);
                    /***
                    if(newSet.onboardingRequired === false || newSet?.roles?.includes('REMOTECOACH') || newSet?.roles?.includes('COACHADMIN')){
                        this.props.history.push(ROUTES.HOME);
                    }
                    else if(newSet.onboardingDone !== true){
                        this.props.history.push(ROUTES.ONBOARDING);
                    }
                    else{
                        this.props.history.push(ROUTES.HOME);
                    }
                    */
                    //DataCacheWrite('authUser', newSet, 1)
                    //console.log('going home after 1', this.props.history)
                    //console.log('registered user', this.state)
                    //console.log('registered user', this.state.readyToLoad)
                    //console.log('registered user', this.props)
                    //console.log('going home after2', this.props.history)
                    return true;
                })
                
            }
            else{
                //console.log('no email', result)
                var error = new Error(pageText.ERROR_MSG_SOCIAL_FAILED); 
                this.setState({error});
                return false;
            }        
        } catch (error) {
            //console.log('error', error.message)
            error.message = pageText.ERROR_MSG_GETDEFAULTSETTING_FAILED;
            this.setState({ error });
            return false;
        }
    }
    doublecheckNewUser = (signInMethod, email, username, uid) => {
        if(uid!==undefined && uid!==null && uid!==''){
            this.props.firebase.user(uid).get()
            .then( (userCheck) =>  {
                var userExisting = true;
                //console.log('usercheck', userCheck, userCheck.data())
                //if(userCheck === undefined || userCheck === null || userCheck.data()===undefined 
                    //|| userCheck.data()===null || (Object.keys(userCheck.data()).length <= 2)){
                var username = '';
                if(!userCheck){
                    userExisting = false;
                    this.setState({readyToLoad : false});
                    //console.log('usercheck', userCheck, userCheck.data())
                }
                else if(!userCheck?.exists) {
                    var data = userCheck.data();
                    if (!("username" in data)) {
                        userExisting = false;
                        // deleted or missing user content, start new signup
                        //console.log('no userdata available')
                        signInMethod = signInMethod + " after undetected user data";
                        //var signInMethod = result.credential.signInMethod;
                        if(this.state.username!==undefined && this.state.username.length>1){
                            username = this.state.username;
                        }
                        else if(this.props.username!==undefined && this.props.username.length>1){
                            username = this.props.username;
                        }
                        if(username===undefined || username ==='') username = 'Padawan';
                        this.addNewUser(signInMethod, email, username, uid)
                        .then( (success )=> {
                            //console.log('newuser', success)
                            setTimeout(() => {
                                this.setState({readyToLoad : true});
                            }, 1000);
                            //console.log('going  home before4 success', success)
                            //console.log('going home after4', this.props.history)
                        })
                    }
                }
                if(userExisting) {
                    var userDataSet = {...userCheck.data(), uid: uid};
                    //// addingcode
                    if(this.props.installationSet !== undefined){
                        UpdateExistingUserSignUp(this, uid, this.props.installationSet, username);
                    }
                    if(this.props.installationSet?.addingCode !== undefined){
                        var tempSet = AddCodeToExistingAccountSet(userDataSet, this.props.installationSet);
                        if(tempSet?.programs?.every(v => userDataSet.programs?.includes(v)) && tempSet.programCode === userDataSet.programCode){
                            console.log('a tempset included');
                        }
                        else{
                            console.log('missing tempset');
                            userDataSet = tempSet;
                            this.props.firebase.user(uid).update(userDataSet);
                        }
                        //console.log('added overwrite xx users account', userDataSet)
                    }
                    DataCacheWrite('authUser', {uid: uid, timezone: userDataSet['timezone'], roles: userDataSet['roles']}, 1);
                    //DataCacheWrite('authUser', userDataSet, 1);
                    var usernameOld = userCheck.data().username;
                    username = usernameOld;
                    if(this.state.username!==undefined && this.state.username.length>1){
                        username = this.state.username;
                    }
                    else if(this.props.username!==undefined && this.props.username.length>1){
                        username = this.props.username;
                    }
                    if(username !== usernameOld){
                        //console.log('udpate user name for redirect user', username)
                        this.props.firebase.user(uid).update({ username: username });
                    }
                    if(this.props.isCoach){
                        var updateExtraSet = ReturnCoachAccountSettings(this, uid, userDataSet);
                        if(updateExtraSet.userBatch !== undefined){
                            this.props.firebase.user(uid).update(updateExtraSet);
                        }
                    }
                    if(window.sessionStorage.getItem('onboardingResponses')){
                        try{
                            var arrayOnboardingResponses = window.sessionStorage.getItem('onboardingResponses').split(',');
                            this.props.firebase.user(uid).update({onboardingResponses: arrayOnboardingResponses});
                        }
                        catch(err) {
                            console.log('Error getting onboardingResponses 4', err)
                        }
                        window.sessionStorage.removeItem('onboardingResponses');
                    }
                    setTimeout(() => {
                        console.log('Timed Stored user set after login')
                    }, 1000);
                    ////// Ensure that the user is not repeating the onboarding
                    PushForwardAfterSigning(this, userDataSet);
                }
            })
            .catch(error => {
                console.log('Doublecheck error', error)
            })
        }
        else{
            console.log('missing uid')
        }
    }

    checkSignupInstallationSet = async () => {
        var newSet = this.props.myInstallationOfflineDataSet || MyInstallationOfflineDataSet;
        //console.log(' addNewUser', newSet)
        if(newSet?.["offlineSetUsed"] === true){
            newSet = await GetUltimimUserSetup(this.props.firebase, newSet, this.props.defaultSet, this.props.defaultSignUpCode, this.props.defaultSet?.programCode || "")
            if(this.props.installationSet?.newUser !== undefined){
                newSet = Object.assign(newSet, this.props.installationSet?.newUser);
                //console.log('added special users account', newSet)
            }
            this.setState({installationSet: newSet});
            //console.log(' addNewUser GetDefault', newSet)
        }
        this.setState({installationSet: newSet});
        //console.log('test break', newSet)
        //newSet = undefined;
        if(this.props.coachcode?.includes("#")){
            //console.log('coachcode added', this.props.coachcode)
            var codeSet = await this.props.firebase.signupCodeByName(this.props.coachcode).get()
            //console.log('coachcode ', codeSet)
            if(codeSet.exists){
                //console.log('coachcode ', codeSet)
                codeSet = codeSet.data().addingCode; 
                newSet['code'] = this.props.coachcode;
                newSet = AddCodeToExistingAccountSet(newSet, codeSet);
                this.setState({installationSet: newSet});
                return newSet;
            }
            else{
                //console.log('Failed coach code ', codeSet)
                var error = new Error("This coach-code does not exist"); 
                this.setState({error});
                return undefined;
            }
        }
        else{
            return newSet;
        }
    }

    onSubmitGoogle = () => {
        this.checkSignupInstallationSet().then(installationSet => {
            //console.log('installationSet', installationSet)
            if(installationSet !== undefined){
                if(this.props.username!== undefined){
                    window.sessionStorage.setItem('username', this.props.username);
                    //console.log('username', this.props.username)
                }
                if(this.signInRedictType === true){
                    window.sessionStorage.setItem('pending', 1);
                    this.props.firebase.doRedirectWithGoogle();
                }
                else if(false){
                    this.setState({openGoogleDialogue: true})
                }
                else{
                    this.props.firebase.doSignInWithGoogle()
                    .then((result) =>  {
                        //console.log('result social pop up', result)
                        this.redirectWithResult(result);
                    })
                    .catch(error => {
                        if (error.code === pageText.ERROR_CODE_ACCOUNT_EXISTS) {
                        error.message = pageText.ERROR_MSG_ACCOUNT_EXISTS;
                            }
                        else if (error.code === pageText.CODE_INVALID_CREDENTIAL) {
                            error.message = pageText.ERROR_MSG_INVALID_CREDENTIAL;
                        }
                        console.log('redict error', error)
                        this.setState({ error });
                    })
                }
            }
        });
    };
    onSubmitFacebook = () => {
        this.checkSignupInstallationSet().then(installationSet => {
            //console.log('installationSet', installationSet)
            if(installationSet !== undefined){
                if(this.props.username!== undefined){
                    window.sessionStorage.setItem('username', this.props.username);
                }
                if(this.signInRedictType === true){
                    window.sessionStorage.setItem('pending', 1);
                    this.props.firebase.doRedirectWithFacebook();
                }
                else if(false){
                    this.setState({openFacebookDialogue: true})
                }
                else{
                    this.props.firebase.doSignInWithFacebook()
                    .then((result) =>  {
                        //console.log('result social pop up', result)
                        this.redirectWithResult(result);
                    })
                    .catch(error => {
                        if (error.code === pageText.ERROR_CODE_ACCOUNT_EXISTS) {
                        error.message = pageText.ERROR_MSG_ACCOUNT_EXISTS;
                            }
                        else if (error.code === pageText.CODE_INVALID_CREDENTIAL) {
                            error.message = pageText.ERROR_MSG_INVALID_CREDENTIAL;
                        }
                        console.log('redict error', error)
                        this.setState({ error });
                    })
                }
            }
        });
    };
    onSubmitApple = () => {
        this.checkSignupInstallationSet().then(installationSet => {
            //console.log('installationSet', installationSet)
            if(installationSet !== undefined){
                if(this.props.username!== undefined){
                    window.sessionStorage.setItem('username', this.props.username);
                }
                if(this.signInRedictType === true){
                    window.sessionStorage.setItem('pending', 1);
                    this.props.firebase.doRedirectWithApple();
                }
                else{
                    this.props.firebase.doSignInWithApple()
                    .then((result) =>  {
                        //console.log('result social pop up', result)
                        this.redirectWithResult(result);
                    })
                    .catch(error => {
                        if (error.code === pageText.ERROR_CODE_ACCOUNT_EXISTS) {
                        error.message = pageText.ERROR_MSG_ACCOUNT_EXISTS;
                            }
                        else if (error.code === pageText.CODE_INVALID_CREDENTIAL) {
                            error.message = pageText.ERROR_MSG_INVALID_CREDENTIAL;
                        }
                        console.log('redict error', error)
                        this.setState({ error });
                    })
                }
            }
        });
    };
    onSubmitTwitter = () => {
        this.checkSignupInstallationSet().then(installationSet => {
            //console.log('installationSet', installationSet)
            if(installationSet !== undefined){
                if(this.props.username!== undefined){
                    window.sessionStorage.setItem('username', this.props.username);
                }
                window.sessionStorage.setItem('pending', 1);
                this.props.firebase.doRedirectWithTwitter();
            }
        });
    };

    TESTrenderGoogleDialog(){
        const {classes, theme} = this.props;
        window.addEventListener('message', event => {
            console.log('event iframe', event.origin, event);
            if (event.data.loggedIn && event.data.domain) {
                console.log('event data', event.data)
                this.props.history.push(event.origin + '/profile');
            }
        });
        return(
            <Dialog 
                open={this.state.openGoogleDialogue || false} 
                onClose={() => this.setState({openGoogleDialogue: false})}
                fullScreen={IsMobileDevice()}
                PaperProps={{
                    style: theme.dialogWindow
                }} 
                maxWidth="sm"
            >
                <DialogContent style={{
                        ...theme.dialogWindow, 
                        padding: 0, 
                        margin: 0, 
                        '&::-webkit-scrollbar': {
                            display: 'none'
                        }
                    }}>
                    {this.state.error && <DialogContentText error>{"error"}</DialogContentText>}
                    {/***
                    <embed type="text/html" src="http://cexh.localhost:3000/#/signin" width="100%" height="100%"/>
                     */}
                    <iframe 
                        style={{
                            borderWidth: 0,
                            border: 'none'
                        }}
                        allowpaymentrequest= 'true'
                        width= '100%'
                        height= '100%'
                        loading= 'eager'
                        sandbox= "allow-forms  allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-top-navigation"
                        referrerPolicy= "origin-when-cross-origin"
                        src="http://cexh.localhost:3000/#/signin"
                        //src="https://app.rocky.ai/#/signin"
                    />
                </DialogContent>
            </Dialog>
        )
    }
    TESTrenderFacebookDialog(){
        console.log('loaoding script')
        const script = document.createElement("script");
        script.async = true;
        script.type = "application/javascript"
        script.crossorigin = "anonymous"
        script.src = "https//connect.facebook.net/en_US/sdk.js";
        script.onload = () => {
            if(window?.FB !== undefined && window.FB.init !== undefined){
                window.FB.init({
                    /**********************************************************************
                     * TODO(Developer): Change the value below with your Facebook app ID. *
                     **********************************************************************/
                    appId: '444495856497740',
                    status: true,
                    xfbml: true,
                    version: 'v2.6',
                });
                console.log("FB init success");
                window.FB.Event?.subscribe('auth.authResponseChange', this.checkLoginState);
            }
        };
        script.onerror = error => {
            console.error(error + 'script load fail!');
        }
        document.body.appendChild(script);
        const {classes} = this.props;
        return(
            <Dialog open={this.state.openFacebookDialogue || false} onClose={() => this.setState({openFacebookDialogue: false})}>
                <DialogTitle>Login</DialogTitle>
                <DialogContent>
                    <DialogContentText>Please enter your email and password.</DialogContentText>
                    {this.state.error && <DialogContentText error>{"error"}</DialogContentText>}
                </DialogContent>
                <DialogActions>

                    <Button className={classes.buttonFacebook} 
                        onClick={() => {
                            window?.FB?.login((response) => {
                                console.log('response', response)
                            }, {scope: 'public_profile,email'})
                        }} 
                    >
                        <img src={facebookIcon} className={classes.socialLogo} alt="Sign in with Apple" />
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
    checkLoginState(response) {
        if (response.authResponse) {
            // User is signed-in Facebook.
            this.unsubscribe = this.props.firebase.checkSignIn((firebaseUser) => {
                console.log('checklogin state',firebaseUser)
                this.unsubscribe();
                
            });
        } 
        else {
        }
    }


    render() {
        const { classes, theme } = this.props;
        const { error, redirectLoginDetected } = this.state;
        //console.log('redirectLoginDetected', redirectLoginDetected)
        return (
            <div>
                <Dialog
                    open={redirectLoginDetected || false}
                    PaperProps={{style:{borderRadius: theme.borderRadius}}}
                    onClose={()=>{this.setState({redirectLoginDetected: false})}}
                >
                    <DialogContent>
                        <div className={classes.form}>
                        {!error && 
                            <LoadingProgress 
                                defaultSet={this.props.defaultSet}
                                type={'LoadingPageAnimated'} loadingText={"loading login"} />
                        }
                        {error && <p className={classes.error}>{error.message}</p>}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{this.setState({redirectLoginDetected: false})}} color="primary">
                        Close
                        </Button>
                    </DialogActions>
                </Dialog>
                {redirectLoginDetected
                    ?
                        <div>
                            <p className={classes.error}>Loading...</p>
                        </div>
                    :
                        <div>
                            <div className={classes.socialLogins}> 
                                {false && this.state.openGoogleDialogue 
                                &&
                                    this.TESTrenderGoogleDialog()
                                }
                                {false && this.state.openFacebookDialogue
                                &&
                                    this.TESTrenderFacebookDialog()
                                }
                                {/**     */}
                                <Button className={classes.buttonApple} onClick={() => this.onSubmitApple()} >
                                    <img src={appleIcon} className={classes.socialLogo} alt="Sign in with Apple" />
                                </Button>
                                <Button className={classes.buttonGoogle} onClick={() => this.onSubmitGoogle()} >
                                    <img src={googleIcon} className={classes.socialLogo} alt="Sign in with Google" />
                                </Button>
                                <Button className={classes.buttonFacebook} onClick={() => this.onSubmitFacebook()} >
                                    <img src={facebookIcon} className={classes.socialLogo} alt="Sign in with Facebook"  />
                                </Button>
                                {/**
                                <Button className={classes.buttonTwitter} onClick={this.onSubmitTwitter}  variant="outlined">
                                    <img src={twitterIcon} className={classes.socialLogo} alt="Sign in via Twitter" />
                                </Button>
                                */}
                            </div> 
                            {error && <p className={classes.error}>{error.message}</p>}
                        </div>
                }
            </div>
        );
    }
    onSubmitGoogleNative = () => {
        this.setState({redirectLoginDetected: true, error:null});
        this.checkSignupInstallationSet().then(installationSet => {
            //console.log('installationSet', installationSet)
            if(installationSet !== undefined){
                //console.log('loging2')
                try{
                    window.plugins.googleplus.logout();
                    var config = {'webClientId' : '42021279944-p70jugr8hg4ahknc2fs04ls4h9c0lc5k.apps.googleusercontent.com'};
                    window.plugins.googleplus.login(config, (resultGoogle) => {
                        this.bringBackViewPort();
                        //calling api after login success
                        //console.log('google request', resultGoogle)
                        //this is not signinging in with firebase on app level
                        if(resultGoogle !== undefined && 'accessToken' in resultGoogle && 'idToken' in resultGoogle ){
                            //console.log('param', result.authResponse.accessToken);
                            var username = resultGoogle.givenName;
                            //var accessToken = resultGoogle.accessToken;
                            //const credential = this.props.firebase.createCredentialForGoogleAlternative(accessToken);
                            var idToken = resultGoogle.idToken;
                            const credential = this.props.firebase.createCredentialForGoogleAlternative(idToken);
                            //console.log('credential', credential);
                            this.props.firebase.doSignInWithCredential(credential)
                            .then((result) => { 
                                if(result.credential) {
                                    this.setState({redirectLoginDetected: true, error:null});
                                    //console.log('loging 16')
                                    // This gives you a Google Access Token. You can use it to access the Google API.
                                    // var token = socialAuthUser.credential.accessToken;
                                    //console.log('firestore user', result)
                                    var signInMethod = result.credential.signInMethod;
                                    var email = result.additionalUserInfo.profile.email;
                                    if(username === undefined || username === ""){
                                        username = result.additionalUserInfo.profile.name;
                                    }
                                    if(this.state.username!==undefined && this.state.username.length>1){
                                        username = this.state.username;
                                    }
                                    else if(this.props.username!==undefined && this.props.username.length>1){
                                        username = this.props.username;
                                    }
                                    if(username===undefined || username ==='') username = 'Padawan';
                                    var uid = result.user.uid;
                                    if(result.additionalUserInfo.isNewUser){
                                        //console.log('new user created with social app signup')
                                        //console.log('going home after3', this.props.history)
                                        return this.addNewUser(signInMethod, email, username, uid)
                                        .then( (success )=> {
                                            //RequestNotificationPermission();
                                            StoreMobileDeviceInfo(this.props.firebase.user(uid));
                                            this.setState({readyToLoad : true});
                                            //console.log('going home before4 success', success)
                                            //console.log('going home after4', this.props.history)
                                        })
                                    }
                                    else{
                                        //RequestNotificationPermission();
                                        StoreMobileDeviceInfo(this.props.firebase.user(result.user.uid));
                                        //console.log('listener esle results', result);
                                        //this.setState({redirectHome : true});
                                        this.doublecheckNewUser(signInMethod, email, username, uid);
                                        //console.log('afer push');
                                    }
                                    // ...
                                };//end if credential
                            })
                            .catch((error) => {
                                //console.log('signin credential', error)
                                if (error.code === pageText.ERROR_CODE_ACCOUNT_EXISTS) {
                                    error.message = pageText.ERROR_MSG_ACCOUNT_EXISTS;
                                }
                                else if (error.code === pageText.CODE_INVALID_CREDENTIAL) {
                                    error.message = pageText.ERROR_MSG_INVALID_CREDENTIAL;
                                }
                                this.setState({ error:error });
                            });
                        }
                        //console.log(JSON.stringify(result))
                    }, 
                    (error) => {
                        this.bringBackViewPort();
                        //authenication error callback
                        error["code"] = error.errorCode;
                        error["message"] = error.errorMessage;
                        if (error.errorCode === '4201') {
                            error["message"] = pageText.ERROR_MSG_SOCIAL_CANCELLED;
                        }
                        else {
                            error["message"] = pageText.ERROR_MSG_INVALID_CREDENTIAL;
                        }
                        this.setState({ error});
                        console.log('Error login Facebook', error);
                    });
                }
                catch (error){
                    this.bringBackViewPort();
                    error["message"] = pageText.ERROR_MSG_INVALID_CREDENTIAL;
                    console.log("Error plugin", error)
                }
            }
        })
    };
    onSubmitGoogleNativeOld = () => {
        this.setState({redirectLoginDetected: true, error:null});
        this.checkSignupInstallationSet().then(installationSet => {
            //console.log('installationSet', installationSet)
            if(installationSet !== undefined){
                //console.log('loging 7')
                try{
                    if(this.appAuth === undefined){
                        window.addEventListener('signinsuccess', (event) => {
                            this.bringBackViewPort();
                            //console.log('details', JSON.stringify(event.detail));
                            //console.log('signingoogle', event.detail.googleIdToken,event.detail.id );
                            // is now signed in on app level as well with this plugin
                            if(event.detail.googleIdToken !== undefined && event.detail.googleIdToken !== ''
                            && event.detail.id !== undefined && event.detail.id !== ''){
                                //console.log('credentials start');
                                const credential = this.props.firebase.createCredentialForGoogleAlternative(event.detail.googleIdToken);
                                /// below was the recent one
                                //const credential = this.props.firebase.createCredentialForGoogle(event.detail.googleIdToken);
                                //const credential = this.props.firebase.createCredentialForGoogle(event.detail.token);
                                //console.log('credentials', credential);
                                this.props.firebase.doSignInWithCredential(credential)
                                .then(() => {
                                    var signInMethod = event.detail.signInMethod;
                                    var email = event.detail.email;
                                    var username = event.detail.name;
                                    if(this.state.username!==undefined && this.state.username.length>1){
                                        username = this.state.username;
                                    }
                                    else if(this.props.username!==undefined && this.props.username.length>1){
                                        username = this.props.username;
                                    }
                                    if(username===undefined || username ==='') username = 'Padawan';
                                    var uid = event.detail.id;
                                    if(event.detail.isNewUser) {
                                        return this.addNewUser(signInMethod, email, username, uid)
                                        .then( (success )=> {
                                            //RequestNotificationPermission();
                                            StoreMobileDeviceInfo(this.props.firebase.user(event.detail.id));
                                            this.setState({readyToLoad : true});
                                            //console.log('going home before4 success', success)
                                            //console.log('going home after4', this.props.history)
                                        })
                                    }
                                    else{
                                        //this.setState({ ...INITIAL_STATE });
                                        //RequestNotificationPermission();
                                        StoreMobileDeviceInfo(this.props.firebase.user(event.detail.id));
                                        console.log('signed INNN!!!!!!');
                                        this.doublecheckNewUser(signInMethod, email, username, uid);
                                    }
                                })
                                .catch((error) => {
                                    if (error.code === pageText.ERROR_CODE_ACCOUNT_EXISTS) {
                                        error.message = pageText.ERROR_MSG_ACCOUNT_EXISTS;
                                    }
                                    else if (error.code === pageText.CODE_INVALID_CREDENTIAL) {
                                        error.message = pageText.ERROR_MSG_INVALID_CREDENTIAL;
                                    }
                                    //console.log('Error with signin', error);
                                    this.setState({ error:error });
                                });
                            }
                            else{
                                //console.log('else singing');
                                var missingTokenError = new Error(pageText.ERROR_MSG_INVALID_CREDENTIAL)
                                this.setState({ error:missingTokenError});
                            }
                        }, false);
                        // on an error during sign in this even will raised
                        window.addEventListener('signinfailure', (event) => {
                            this.bringBackViewPort();
                            //console.log('signinfailure', JSON.stringify(event.detail));
                            var googleError = event.detail; 
                            if (event.detail.code === "SIGN_IN_CANCELLED"){
                                googleError['message'] = pageText.ERROR_MSG_SOCIAL_CANCELLED;
                            }
                            else{
                                googleError['message'] = pageText.ERROR_MSG_INVALID_CREDENTIAL;
                            }
                            this.setState({ error:googleError});
                        }, false);
                        this.appAuth = new window.FirebaseAuth();
                        //console.log('first click', this.appAuth);
                        this.appAuth.signOut();
                        this.appAuth.signIn();
                    }
                    else{
                        if(this.appAuth !== undefined){
                            //console.log('click', this.appAuth);
                            this.appAuth.signOut();
                            this.appAuth.signIn();
                        }
                    }
                }
                catch (error){
                    this.bringBackViewPort();
                    error["message"] = pageText.ERROR_MSG_INVALID_CREDENTIAL;
                    console.log("Error plugin", error)
                }
            }
        })
    };
    onSubmitFacebookNative = () => {
        this.setState({redirectLoginDetected: true, error:null});
        this.checkSignupInstallationSet().then(installationSet => {
            //console.log('installationSet', installationSet)
            if(installationSet !== undefined){
                //console.log('loging 8')
                try{
                    window.facebookConnectPlugin.logout();
                    var perms = ["email","public_profile"];
                    window.facebookConnectPlugin.login(perms, (resultFacebook) => {
                        this.bringBackViewPort();
                        //calling api after login success
                        //this is not signinging in with firebase on app level
                        if(resultFacebook.authResponse !== undefined && 'accessToken' in resultFacebook.authResponse ){
                        //console.log('param', result.authResponse.accessToken);
                        var accessToken = resultFacebook.authResponse.accessToken;
                        //var accessToken = window.facebookConnectPlugin.getAccessToken();
                        //console.log('id token', idToken);
                        //const credential = this.props.firebase.createCredentialForFacebook(accessToken);
                        const credential = this.props.firebase.createCredentialForFacebookAlternative(accessToken);
                        //console.log('credential', credential);
                        this.props.firebase.doSignInWithCredential(credential)
                        .then( (result) => {
                            if(result.credential) {
                                this.setState({redirectLoginDetected: true, error:null});
                                //console.log('loging 9')
                                // This gives you a Google Access Token. You can use it to access the Google API.
                                // var token = socialAuthUser.credential.accessToken;
                                //console.log('user', result)
                                var signInMethod = result.credential.signInMethod;
                                var email = result.additionalUserInfo.profile.email;
                                var username = result.additionalUserInfo.profile.name;
                                if(this.state.username!==undefined && this.state.username.length>1){
                                    username = this.state.username;
                                }
                                else if(this.props.username!==undefined && this.props.username.length>1){
                                    username = this.props.username;
                                }
                                if(username===undefined || username ==='') username = 'Padawan';
                                var uid = result.user.uid;
                                if(result.additionalUserInfo.isNewUser){
                                    //console.log('new user created with social app signup')
                                    //console.log('going home after3', this.props.history)
                                    
                                    return this.addNewUser(signInMethod, email, username, uid)
                                    .then( (success )=> {
                                        //RequestNotificationPermission();
                                        StoreMobileDeviceInfo(this.props.firebase.user(uid));
                                        this.setState({readyToLoad : true});
                                        //console.log('going home before4 success', success)
                                        //console.log('going home after4', this.props.history)
                                    })
                                }
                                else{
                                    //RequestNotificationPermission();
                                    StoreMobileDeviceInfo(this.props.firebase.user(result.user.uid));
                                    //console.log('listener esle results', result);
                                    //this.setState({redirectHome : true});
                                    this.doublecheckNewUser(signInMethod, email, username, uid);
                                    //console.log('afer push');
                                }
                                // ...
                            };//end if credential
                        })
                        .catch((error) => {
                            //console.log('signin credential', error)
                            if (error.code === pageText.ERROR_CODE_ACCOUNT_EXISTS) {
                            error.message = pageText.ERROR_MSG_ACCOUNT_EXISTS;
                            }
                            else if (error.code === pageText.CODE_INVALID_CREDENTIAL) {
                            error.message = pageText.ERROR_MSG_INVALID_CREDENTIAL;
                            }
                            this.setState({ error:error });
                        });
                        }
                        //console.log(JSON.stringify(result))
                    }, 
                    (error) => {
                        this.bringBackViewPort();
                        //authenication error callback
                        error["code"] = error.errorCode;
                        error["message"] = error.errorMessage;
                        if (error.errorCode === '4201') {
                            error["message"] = pageText.ERROR_MSG_SOCIAL_CANCELLED;
                        }
                        else {
                            error["message"] = pageText.ERROR_MSG_INVALID_CREDENTIAL;
                        }
                        this.setState({ error});
                        console.log('Error login Facebook', error);
                    });
                }
                catch (error){
                    this.bringBackViewPort();
                    error["message"] = pageText.ERROR_MSG_INVALID_CREDENTIAL;
                    console.log("Error plugin", error)
                }
            }
        })
    };
    onSubmitAppleNative = () => {
        this.setState({redirectLoginDetected: true, error:null});
        this.checkSignupInstallationSet().then(installationSet => {
            //console.log('installationSet', installationSet)
            if(installationSet !== undefined){
                //console.log('loging 1111')
                try{
                    var options = {
                        requestedScopes: [0, 1]
                    };
                    window.cordova.plugins.SignInWithApple.signin(options, (resultApple) => {
                        this.bringBackViewPort();
                        //calling api after login success
                        //this is not signinging in with firebase on app level
                        if(resultApple !== undefined && 'identityToken' in resultApple ){
                        //console.error('resultapple', resultApple);
                        //console.log('apple result first', resultApple)
                        var fullName = resultApple.fullName;
                        var username = "Padawan"
                        if(fullName.nickname !== undefined && fullName.nickname  !== ''){
                            username = fullName.nickname;
                        }
                        else if(fullName.givenName !== undefined && fullName.givenName  !== ''){
                            username = fullName.givenName;
                        }
                        else if(fullName.familyName !== undefined && fullName.familyName  !== ''){
                            username = fullName.familyName;
                        }
                        var accessToken = resultApple.identityToken;
                        //console.error('id token', accessToken);
                        const unhashedNonce = this.generateNonce(10);
                        //console.error('nonce', unhashedNonce);
                        const credential = this.props.firebase.createCredentialForApple(accessToken, unhashedNonce);
                        //console.log('credential', credential);
                        this.props.firebase.doSignInWithCredential(credential)
                        .then( (result) => {
                            //console.error('result', result);
                            //console.log('result after signup',JSON.stringify(result))
                            if(result.credential) {
                            this.setState({redirectLoginDetected: true});
                            //console.log('loging 12')
                                // This gives you a Google Access Token. You can use it to access the Google API.
                                // var token = socialAuthUser.credential.accessToken;
                                //console.log('apple user', result)
                                //console.log('user', console.log('result strng',JSON.stringify(result)))
                                var signInMethod = 'apple.com';
                                var email = result.additionalUserInfo.profile.email;
                                if(email === undefined) email = '';
                                if(this.state.username!==undefined && this.state.username.length>1){
                                    username = this.state.username;
                                }
                                else if(this.props.username!==undefined && this.props.username.length>1){
                                    username = this.props.username;
                                }
                                if(username===undefined || username ==='') username = 'Padawan';
                                var uid = result.user.uid;
                                if(result.additionalUserInfo.isNewUser){
                                    //console.log('new user created with social app signup')
                                    //console.log('newuser data', this.props.history)                  
                                    return this.addNewUser(signInMethod, email, username, uid)
                                    .then( (success )=> {
                                        //RequestNotificationPermission();
                                        StoreMobileDeviceInfo(this.props.firebase.user(uid));
                                        this.setState({readyToLoad : true});
                                        //console.log('going home before4 success', success)
                                        //console.log('going home after4', this.props.history)
                                    })
                                }
                                else{
                                    //RequestNotificationPermission();
                                    //console.log('apple user', result)
                                    StoreMobileDeviceInfo(this.props.firebase.user(result.user.uid));
                                    //console.log('listener esle results', result);
                                    //this.setState({redirectHome : true});
                                    this.doublecheckNewUser(signInMethod, email, username, uid);
                                    //console.log('afer push');
                                }
                                // ...
                            };//end if credential
                        })
                        .catch((error) => {
                                //console.log('signin credential', error)
                                if (error.code === pageText.ERROR_CODE_ACCOUNT_EXISTS) {
                                    error.message = pageText.ERROR_MSG_ACCOUNT_EXISTS;
                                }
                                else if (error.code === pageText.CODE_INVALID_CREDENTIAL) {
                                    error.message = pageText.ERROR_MSG_INVALID_CREDENTIAL;
                                }
                                this.setState({ error:error });
                        });
                        }
                        //console.log('result strng',JSON.stringify(resultApple))
                    }, 
                    (error) => {
                        this.bringBackViewPort();
                        //authenication error callback
                        if (error.error === 'PLUGIN_ERROR') {
                            error['message'] = pageText.ERROR_MSG_APPLE_VERSION;
                        }
                        else if (error.code === '1001' || error.code === '1003') {
                            error['message'] = pageText.ERROR_MSG_SOCIAL_CANCELLED;
                        }
                        else if (error.code === '1000' ) {
                            error['message'] = pageText.ERROR_MSG_INVALID_CREDENTIAL;
                        }
                        else{
                            error['message'] = error.localizedDescription;
                        }
                        this.setState({error});
                        console.log('Error login', error);
                    });
                }
                catch (error){
                    this.bringBackViewPort();
                    error["message"] = pageText.ERROR_MSG_INVALID_CREDENTIAL;
                    console.log("Error plugin", error)
                }
            }
        })
    };
    generateNonce = (length) => {
        const crypto = require('crypto-browserify');
        let nonce = crypto.randomBytes(16).toString('base64');
        return nonce.substr(0, length);
    }
    bringBackViewPort = () => {
        //console.log('bring back view Port', window.Keyboard)
        //// on iOS it is actually possible that the native app will resize the viewport, so we need a method to bring the viewport back
        //// what works is to open the keyboard that resizes the viewport for nay reason from some corodva lib
        try{
            if(this.windoHeight !== undefined && this.windowWidth !== undefined){
                document.documentElement.style.setProperty('--vh', `${this.windowHeight}px`);
                document.documentElement.style.setProperty('--vw', `${this.windowWidth}px`);
                document.getElementById("viewport").setAttribute("content", "width=" + this.windowWidth + ", height=" + this.windowHeight + ", minimum-scale=0.99, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover");
                //console.log('updates viewport', document.getElementById("viewport"))
            }

            var changedHeight = window.innerHeight;
            var changedWidth = window.innerWidth;
            //console.log(this.windowHeight + 'x' + this.windowWidth + ' - ' + changedHeight + 'x' + changedWidth)
            if(changedHeight !== this.windowHeight && this.windowHeight !== undefined){
                //width=device-width,user-scalable=no,initial-scale=1,minimum-scale=0.99,maximum-scale=1,viewport-fit=cover
                //document.getElementById("viewport").setAttribute("content", "width=" + this.windowWidth + ", height=" + this.windowHeight + ", minimum-scale=0.99, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover");
                document.getElementsByTagName('body')[0].style.height = this.windowHeight + "px";
                window.resizeTo(this.windowWidth, this.windowHeight);
                //console.log('changed height')
            }
            if(changedWidth !== this.windowWidth && this.windowWidth !== undefined){
                //document.getElementById("viewport").setAttribute("content", "width=" + this.windowWidth + ", height=" + this.windowHeight + ", minimum-scale=0.99, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover");
                document.getElementsByTagName('body')[0].style.width = this.windowWidth + "px";
                window.resizeTo(this.windowWidth, this.windowHeight);
                //console.log('changed width')
            }
        } catch (error){console.log('focus error', error)}
    }
}

const SignInForm = compose(
    withStyles(stylesSignin, { withTheme: true }),
    withRouter,
    withFirebase
)(SignInFormBase);


const SignInSocialForward = compose(
    withStyles(stylesSignin, { withTheme: true }),
    withRouter,
    withFirebase
)(SignInSocialBaseForward);

SignInPage.propTypes = {
    classes: PropTypes.object.isRequired
};
export default compose(
    withRouter,
    withStyles(stylesSignin, { withTheme: true }),
    withFirebase,
)(SignInPage);

export { SignInForm, SignInSocialForward };