import React from 'react';

// Icons
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import SchoolIcon from '@material-ui/icons/School';
import AssessmentIcon from '@material-ui/icons/Assessment';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import HighlightIcon from '@material-ui/icons/Highlight';
import NatureIcon from '@material-ui/icons/Nature';
import ExtensionIcon from '@material-ui/icons/Extension';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';

import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AlarmIcon from '@material-ui/icons/Alarm';
import CloudIcon from '@material-ui/icons/Cloud';
import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverage';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import HotelIcon from '@material-ui/icons/Hotel';
import ExploreIcon from '@material-ui/icons/Explore';
import ErrorIcon from '@material-ui/icons/Error';

import ToysIcon from '@material-ui/icons/Toys';
import WorkIcon from '@material-ui/icons/Work';
import TimerIcon from '@material-ui/icons/Timer';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsVoiceIcon from '@material-ui/icons/SettingsVoice';
import SecurityIcon from '@material-ui/icons/Security';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import HealingIcon from '@material-ui/icons/Healing';
import GroupIcon from '@material-ui/icons/Group';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';

import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import CachedIcon from '@material-ui/icons/Cached';
import EventNoteIcon from '@material-ui/icons/EventNote';
import HearingIcon from '@material-ui/icons/Hearing';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import CallMadeIcon from '@material-ui/icons/CallMade';

import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';

const iconNames = {
    unhappy: <SentimentVeryDissatisfiedIcon/> ,
    good: <ThumbUpIcon/> ,
    happy:  <SentimentVerySatisfiedIcon/>,
    fun:  <SentimentVerySatisfiedIcon/>,
    bad:  <ThumbDownIcon/> ,
    love:   <FavoriteIcon/>,
    frustrated: <CloudIcon/> ,
    calm: <EmojiFoodBeverageIcon/> ,
    relax: <EmojiFoodBeverageIcon/> ,
    smart:  <EmojiObjectsIcon/> ,
    tired: <HotelIcon/>,
    curious: <ExploreIcon/> ,
    failing: <ErrorIcon/>,
    mindset: <CloudQueueIcon /> ,
    priority: <PriorityHighIcon /> ,
    task : <AssignmentIcon />,
    awareness: <ExtensionIcon /> ,
    assessment: <AssessmentIcon /> ,
    miss : <CallMissedOutgoingIcon /> ,
    goal: <EmojiFlagsIcon /> ,
    quality : <AccessibilityNewIcon /> ,
    activity:  <FitnessCenterIcon />,
    improvement:  <ChangeHistoryIcon /> ,
    challenge:  <HighlightIcon />,
    expectation: <NatureIcon /> ,
    work:  <WorkIcon/>,
    think: <CachedIcon/>,
    speak: <QuestionAnswerIcon/> ,
    rest:  <HotelIcon/>,
    listen: <HearingIcon/> ,
    learn:  <SchoolIcon/> ,
    eat: <RestaurantMenuIcon/> ,
    exercise:  <FitnessCenterIcon/> ,
    enjoy:<SentimentVerySatisfiedIcon/> ,
    solve:  <ExtensionIcon/> ,
    plan:  <CalendarTodayIcon />,
    engage: <ErrorIcon/>,
    write:  <EventNoteIcon/>,
    leisure:  <ToysIcon/>,
    schedule:  <TimerIcon/> ,
    business:  <ShoppingCartIcon/>,
    communication:  <SettingsVoiceIcon/> ,
    confidence: <SecurityIcon/> ,
    energy: <SecurityIcon/> ,
    learning:  <SchoolIcon/> ,
    food:  <RestaurantMenuIcon/> ,
    people: <GroupIcon/> ,
    negative: <TrendingDownIcon/>,
    positive: <CallMadeIcon/>,
}

export function IconPicker(bot_utter){
    if(bot_utter){
        if (Object.keys(iconNames).includes(bot_utter)){
            return iconNames[bot_utter]
        }
        else if (bot_utter.includes("mindset")){
          return <CloudQueueIcon /> ;
        }
        else if (bot_utter.includes("people") || bot_utter.includes("partner")){
          return <EmojiEventsIcon /> ;
        }
        else if(bot_utter.includes("priorit") || bot_utter.includes("priorit")){
          return <PriorityHighIcon /> ;
        }
        else if(bot_utter.includes("event")  || bot_utter.includes("meet")){
            return <CalendarTodayIcon /> ;
          }
        else if(bot_utter.includes("task") || bot_utter.includes("idea")){
          return <AssignmentIcon /> ;
        }
        else if(bot_utter.includes("aware")  || bot_utter.includes("incomple")){
          return <ExtensionIcon /> ;
        }
        else if(bot_utter.includes("asses") || bot_utter.includes("satisf")){
          return <AssessmentIcon /> ;
        }
        else if(bot_utter.includes("alert") || bot_utter.includes("busy") || bot_utter.includes("time") ){
            return <AlarmIcon /> ;
        }
        else if(bot_utter.includes("miss") || bot_utter.includes("impossib") ){
          return <CallMissedOutgoingIcon /> ;
        }
        else if(bot_utter.includes("goal") || bot_utter.includes("outcome")){
          return <EmojiFlagsIcon /> ;
        }
        else if(bot_utter.includes("qualit") || bot_utter.includes("frust")){
          return <AccessibilityNewIcon /> ;
        }
        else if(bot_utter.includes("activit") || bot_utter.includes("exerc")){
          return <FitnessCenterIcon /> ;
        }
        else if(bot_utter.includes("financ") || bot_utter.includes("money") || bot_utter.includes("wealth")){
            return <LocalAtmIcon /> ;
        }
        else if(bot_utter.includes("improve") || bot_utter.includes("success")){
          return <ChangeHistoryIcon /> ;
        }
        else if(bot_utter.includes("challeng")  || bot_utter.includes("fail") ){
          return <HighlightIcon /> ;
        }
        else if(bot_utter.includes("expec") || bot_utter.includes("motivat") ){
          return <NatureIcon /> ;
        }
        else if(bot_utter.includes("help") || bot_utter.includes("famil")){
          return <NaturePeopleIcon /> ;
        }
        else if(bot_utter.includes("heal")){
            return <HealingIcon /> ;
        }
        else{
          return <CenterFocusStrongIcon />;
        }
      }
      else{
        return <CenterFocusStrongIcon />; 
      }
}