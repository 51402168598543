import {StoreAppError} from './StoreAppError';

export function FindTextValueInDict(db, dict, searchValue, item='text') {
    var returnValue = searchValue;
    if(searchValue !== undefined){
        if(item!=='text'){
            returnValue = undefined;
        }
        if(dict !== undefined && searchValue !== undefined){
            try{
                returnValue = dict.find(c => c.value === searchValue)[item]
            }
            catch(error){
                //StoreAppError(db, error.message, 'FindTextValueInDict', searchValue, ('item value: ' + item))
            }
        }
    }
    else{
        console.log('searching for undefined searchValue')
    }
    return returnValue
};

export const ObjectsAreIdentical = (obj1, obj2) => {
    if((obj1 === undefined || obj1 === null) && (obj2 === undefined || obj2 === null)){
        return true;
    }
    // Check if object references are the same (not a shallow comparison)
    if (obj1 === obj2) {
        return true;
    }
  
    // Check if objects have the same number of keys
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
        return false;
    }
  
    // Iterate through keys and compare values
    for (const key in obj1) {
        if (obj2?.hasOwnProperty(key) && JSON.stringify(obj1?.[key]) !== JSON.stringify(obj2?.[key])) {
            //console.log("json differ", obj1?.[key], obj2?.[key])
            return false;
        }
    }
  
    // If all keys match, objects are considered shallow equal
    return true;
};