import React, { Component } from 'react';
//import { Link } from "react-router-dom";
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import GaugeChart from 'react-gauge-chart'
import Card from '@material-ui/core/Card';

const styles = theme => ({
	desktopPadding: {
	 	minHeight: 40,
    },
    listItem: {
        boxShadow: 1,
        border: '0px',
        borderRadius: theme.borderRadius,
    },
});

class CardSentimentGauge extends Component {
    //static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = { 
            loading: true
        };
    } 

    componentDidMount() {
        this._isMounted = true;
        var sentiments = {};
        if(this.props.sentiments !== undefined){
            sentiments = this.props.sentiments;
        }
        var sentimentPositive = sentiments?.total?.positive?.engagements || 0;
        var sentimentIntention = sentiments?.total?.intention?.engagements || 0;
        var sentimentNeutral = sentiments?.total?.neutral?.engagements || 0;
        var sentimentNegative = sentiments?.total?.negative?.engagements || 0;
        var averagePoints = (0.1*sentimentNegative +0.4*sentimentNeutral + 0.6*sentimentIntention +1.4*sentimentPositive)/(sentimentNegative +sentimentNeutral +sentimentIntention +sentimentPositive);
        //console.log('calc', sentimentNegative, sentimentNeutral, sentimentIntention, sentimentPositive, averagePoints)
        sentiments['averageScore'] = averagePoints;
        //console.log('av', sentiments['averageScore'])
        this.setState({sentiments});
    }
    componentWillUnmount (){
        this._isMounted = false;
    }

    render() {
        const { theme } = this.props;
        const { sentiments } = this.state;
        return(
            <div style={{height: 250, minWidth: 200}}>
                <Card style={theme.CardGoals}
                    onClick={ () =>  {if(this.props.tutorialOpen !== undefined) this.props.tutorialOpen('insights'); }}
                >
                    <div style={{padding:10}}>
                        <strong>{this.props.title || 'Average Mindset'}</strong>
                    </div>
                    <div style={{textAlign:"center", maxHeight: 200, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <RenderMemoGauge 
                            id={this.props.title || 'Average Mindset'}
                            title={this.props.title || 'Average Mindset'}
                            style={{textAlign:"center", maxHeight: 200, maxWidth: 360}}
                            //nrOfLevels={4} 
                            //arcsLength={[0.2, 0.3, 0.35, 0.15]}
                            //colors={['#FF5F6D', theme.palette.themeAlertOrange, theme.palette.themeAlertGreen, theme.palette.themeAlertGreen]} 
                            nrOfLevels={3} 
                            arcsLength={[0.2, 0.3, 0.5]}
                            colors={['#FF5F6D', theme.palette.themeAlertOrange, theme.palette.themeAlertGreen]} 
                            arcWidth={0.4}
                            needleColor={theme.palette.themeBlue}
                            textColor={theme.palette.black}
                            formatTextValue={value => {
                                //console.log('va', value)
                                    if(value>=75){ return '🚀'}
                                    else if(value>=50){ return ('🎯')}
                                    else if(value<25){ return '🗯️'}
                                    else{ return '🧭'}
                                }}
                            hideText = {true}
                            percent={sentiments?.averageScore || 0.5}
                        />
                    </div>
                    <p style={theme.textSupportCenter}>
                        {sentiments?.averageScore !== undefined
                            ?
                                sentiments?.averageScore >=0.75
                                    ?   <span style={{color: theme.palette.themeAlertGreen}}>🚀 Winning Mindset</span>
                                    :   sentiments?.averageScore >=0.5
                                        ?   <span style={{color: theme.palette.themeAlertGreen}}>🎯 Acting Mindset</span>
                                        :   sentiments?.averageScore < 0.2
                                            ?   <span style={{color: theme.palette.themeAlertRed}}>🗯️ Feeling Mindset</span>
                                            :   <span style={{color: theme.palette.themeAlertOrange}}>🧭 Thinking Mindset</span>
                            :
                                'n/a'
                        }
                    </p>
                </Card>
            </div>
        )
    }
}

const RenderMemoGauge = React.memo((props) => {
    //console.log('props', props)
    var percent = props.percent || 0.5;
    if (percent > 0.9) {
        percent = 0.9
    }
    return(
        <GaugeChart 
            id={props.title}
            style={props.style}
            nrOfLevels={props.nrOfLevels} 
            arcsLength={props.arcsLength}
            colors={props.colors} 
            arcWidth={props.arcWidth} 
            needleColor={props.needleColor} 
            textColor={props.textColor} 
            formatTextValue={props.formatTextValue} 
            hideText = {props.hideText} 
            percent={percent} 
        />
    )
}, (prevProps, nextProps) => {
    if (prevProps.percent === nextProps.percent) {
        return true; // props are equal no rerender
    }
    return false; // props are not equal -> update the component
})


export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true}),
)(CardSentimentGauge);
