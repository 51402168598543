import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
//import PropTypes from 'prop-types';
import {SetStateLoadingBatches} from '../Charts/LoadingBatches';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import { DataCacheRead, DataCacheWrite} from "../Modules/DataCache";
import Chip from '@material-ui/core/Chip';
import Badge from '@material-ui/core/Badge';
import Backdrop from '@material-ui/core/Backdrop';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import LinearProgress from '@material-ui/core/LinearProgress';

//import Paper from '@material-ui/core/Paper';

//import Filter3Icon from '@material-ui/icons/Filter3';
//import Filter2Icon from '@material-ui/icons/Filter2';
//import Filter1Icon from '@material-ui/icons/Filter1';
import StarsIcon from '@material-ui/icons/Stars';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import StarTwoToneIcon from '@material-ui/icons/StarTwoTone';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';
//import ExposurePlus1Icon from '@material-ui/icons/ExposurePlus1';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ModeCommentIcon from '@material-ui/icons/ModeComment';
//import AvatarImage from '../Modules/AvatarImage';
import Avatar from '@material-ui/core/Avatar';
import DialogCardBadges from '../Charts/DialogCardBadges';

import logo from "../../images/rocky-logo-square.png";
import badgeIntention from "../../images/Badge_Intention.png";
import badgeAccomplishment from "../../images/Badge_Accomplishment.png";
import badgeHappiness from "../../images/Badge_Happiness.png";
import badgeSpinWheel from "../../images/Badge_Spinwheel.gif";

import confetti from "../../images/animatedconfetti.gif";
import dust from "../../images/animateddust.gif";

//.... github.com/jlkri/use-animate-presense
import Zoom from '@material-ui/core/Zoom';
import Slide from '@material-ui/core/Slide';
import theme from '../../themes';
import { QualityID } from '../Modules/LoadCategoriesAndQualities';

const animationTime = 1800;
const squareSize = 30;
const styles = theme => ({
    overrideColorPrimary: { 
        backgroundColor: "transparent",
    },
    root: {
        flexGrow: 1,
        display:"inline-block",
        backgroundColor: "transparent",
    },
    progressBar: {
        width: 80,
        display:"inline-block"
    },
    popoverSlideAnimation: {
        opacity: "0.4",
        boxShadow: "none",
        border: '0px',
        //position: 'absolute',
        //backgroundColor: "pink",
        zIndex: 1400,
        //height: 200,
        //width: 200,
        //left: '50%',
        //marginLeft: '-50px',
        //top: '50%',
        //marginTop: '-50px',
        overlayStyle:{backgroundColor: theme.palette.themeRed},
        paper: { 
            backgroundColor: "blue", 
        }
    },
    StarsIcon:{
        color: theme.palette.themeAltGold,
        backgroundColor: "transparent",
        height: squareSize,
        width: squareSize,
    },
    StarsIcon2:{
        color: theme.palette.themeAlertGreen,
        backgroundColor: "transparent",
        height: squareSize,
        width: squareSize,
    },
    StarsIcon3:{
        color: theme.palette.themeAlertRed,
        backgroundColor: "transparent",
        height: squareSize,
        width: squareSize,
    },
    Plus2Icon:{
        color: theme.palette.themeAlertGreen,
        backgroundColor: "transparent",
        opacity: 0.8,
        height: 44,
        width: 44,
    },
    Plus1Icon:{
        color: theme.palette.themeAltGold,
        backgroundColor: "transparent",
        opacity: 0.7,
        height: 44,
        width: 44,
    }
});

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: '45%',
        top: '45%',
        border: 'transparent',
        padding: '0 4px',
        color: theme.palette.black,
        backgroundColor: 'transparent',
        fontSize: '22px'
    },
}))(Badge);

const StyledChip = withStyles({
    root: {
        backgroundColor: 'transparent'
    }
})(Chip);

class StatsBar extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            dialogCardOpen : false,
            loading: false,
            stats: {},
            labelShort: "1",
            labelTotal: "1",
            completed: 5,
            completedTotal: 5,
            totalXP: 0,
            shownBadges: []
        };

        //console.log("after Bott Utters", this.state.botUtterSets);
    }

 
    addDataMeasures(stats, measure, points, engagements, category, labelShort, type){
        if(!(category in stats)){
            stats[category]={}
        }
        if(!(measure in stats[category]) && measure !== undefined && measure !== 'default'){
            stats[category][measure] = {
                points:  points,
                engagements: engagements,
                labelShort: labelShort,
                type: type
            }
        }
        else if (measure !== undefined && measure !== 'default'){
            stats[category][measure] = {
                points:  points + stats[category][measure].points,
                engagements: engagements + stats[category][measure].engagements,
                labelShort: labelShort,
                type: type
            }
        }
    }
    replaceDataMeasures(stats, measure, data, labelShort, type){
        //console.log('s', measure, labelShort)
        if(!(data.category in stats)){
            stats[data.category ]={}
        }
        stats[data.category][measure] = data;
        stats[data.category][measure]['labelShort'] = labelShort;
        stats[data.category][measure]['type'] = type;
    }

    componentDidMount() {
        this._isMounted = true;
        //console.log(this.context)


        var stats = {};
        var oldStats = {};

        ///// write into cache parameter set and Loading ///////////////////
        let badgeStats = {};
        var updatedBadgeStats = {}
        var cacheStore = null;
        if(this.props.type==="weekly"){
            cacheStore = DataCacheRead('badgeStatsWeekly_'+this.context.uid)
        }
        else{
            cacheStore = DataCacheRead('badgeStats_'+this.context.uid)
        } 
        //console.log('badgeFamily',badgeFamily, cacheStore, db, userId);
        if(cacheStore!== null && cacheStore!== undefined && cacheStore.cacheAvailable && cacheStore.cacheData !== undefined) {
            badgeStats = cacheStore.cacheData;
            if(this._isMounted){
                this.setState(badgeStats);
            }
        }
        //////////////////////////////////////////////////////////////////
        if(this.props.type===undefined || this.props.type!=="weekly"){
            this.unsubscribeBadges = this.props.firebase.journeyBadges(this.context.uid)
            .where('active', '==', true)
            .where('completed', '==', false)
            .where('category', 'in', ['leadership_category', 'total'])
            .onSnapshot(snapshot => {
                if (snapshot.size) {
                    updatedBadgeStats = {};
                    if(this._isMounted){
                        snapshot.forEach(doc => {
                            //console.log(doc.data())
                            var currentBadgeInfo = doc.data();
                            var name = currentBadgeInfo.name;
                            var period = name.lastIndexOf('_');
                            var categoryName = name.substring(0, period);
                            if(this._isMounted){
                                this.setState({['batch_'+categoryName] : currentBadgeInfo})
                            }
                            ///// write into cache parameter set and Loading ///////////////////
                            updatedBadgeStats['batch_'+categoryName] = currentBadgeInfo;
                            //////////////////////////////////////////////////////////////////
                            //console.log('currentBadgeInfo', currentBadgeInfo)
                            if (currentBadgeInfo?.name?.includes("total_points")) {
                                if(this._isMounted){
                                    var updateSet = {batchNextTotal : currentBadgeInfo};
                                    if(currentBadgeInfo?.target > 0 && this.context?.engagementCounter > 0){
                                        var targetValue = currentBadgeInfo?.target || 100;
                                        if(targetValue>=1000){
                                            targetValue= Math.round(targetValue/1000)+"K";
                                        }
                                        updateSet['labelTotal'] = this.context?.engagementCounter + "/" + targetValue;
                                        var completedFactor = Math.ceil(this.context?.engagementCounter  / currentBadgeInfo?.target * 100)
                                        if (completedFactor>100) completedFactor = 100;
                                        //console.log('completedFactor', completedFactor)
                                        updateSet['completed'] = completedFactor;
                                        updateSet['completedTotal'] = completedFactor;
                                    }
                                    if(this._isMounted) this.setState(updateSet);
                                }
                                ///// write into cache parameter set and Loading ///////////////////
                                updatedBadgeStats['batchNextTotal'] = currentBadgeInfo;
                                //console.log('batchNextTotal :', currentBadgeInfo)
                            }
                        })
                        //console.log('updte:', updatedBadgeStats)
                        DataCacheWrite('badgeStats_'+this.context.uid, updatedBadgeStats, 2);
                        //var cacheStore = DataCacheRead('badgesComplete_'+badgeFamily+'_'+userId)
                        SetStateLoadingBatches(this, this.props.firebase, this.context.uid, 'all', 10, true);
                        //console.log('overwrite')
                        ///// write into cache parameter set and Loading ///////////////////
                    }//end is mounted
                }
            })
        }
        else if(this.props.type==="weekly"){
            if(this._isMounted ){
                var targetTotalEngagements = 50;
                if(this.context.mobileNotifications === "daily" || this.context.emailNotifications === "daily"){ targetTotalEngagements = 80; }
                if(this.context.mobileNotifications === "casually" || this.context.emailNotifications === "casually"){ targetTotalEngagements = 50; }
                if(this.context.mobileNotifications === "weekly" || this.context.emailNotifications === "weekly"){ targetTotalEngagements = 20; }
                var currentTotalEngagements = 0;
                var currentTotalPoints = 0;
                var completedTotal = 0;
                var targetValue = targetTotalEngagements || 100;
                if(targetValue>=1000){
                    targetValue= Math.round(targetValue/1000)+"K";
                }
                var labelTotal = currentTotalEngagements + "/" + targetValue;
                var date = new Date();
                var selectDayFrom = new Date(date.setDate(date.getDate() - 7));
                var statsList = {};
                this.unsubscribe = this.props.firebase
                .insightsEngagement()
                .where('userId', '==', this.context.uid)
                .where('createdAt', '>=', selectDayFrom)
                .onSnapshot(snapshot => {
                    if (snapshot.size) {
                        if(this._isMounted){
                            this.setState({loading:true});
                        }
                        if (statsList === undefined ) statsList = {};

                        currentTotalEngagements = 0;
                        currentTotalPoints = 0;

                        snapshot.forEach(doc => {
                            var engagementSet = doc.data();
                            if (statsList[engagementSet.type] === undefined ) statsList[engagementSet.type] =  []; 
                            statsList[engagementSet.type].push(engagementSet)
                            this.addDataMeasures(stats, 'points', engagementSet.points, 1, 'total', 'All points', 'all')
                            this.addDataMeasures(stats, 'engagement', 1, 1, 'total', 'All engagements', 'all')
                            currentTotalEngagements += 1;
                            currentTotalPoints += engagementSet.points;
                            //console.log('data', data);
                        }); // end of snapshot
                        

                        //completedTotal = Math.ceil(currentTotalEngagements / targetTotalEngagements * 100)
                        //if (currentTotalEngagements>targetTotalEngagements)  targetTotalEngagements = currentTotalEngagements;
                        //console.log("Currenttotal", targetTotal, completedTotal)
                        //labelTotal = currentTotalEngagements + "/" +targetTotalEngagements;


                        completedTotal = Math.ceil(currentTotalPoints / targetTotalEngagements * 100)
                        if (currentTotalPoints>targetTotalEngagements) targetTotalEngagements = currentTotalPoints;
                        //labelTotal = currentTotalPoints + "/" +targetTotalEngagements;
                        labelTotal = currentTotalPoints;

                        updatedBadgeStats['stats'] = stats;
                        updatedBadgeStats['loading'] = false;
                        updatedBadgeStats['updateValues'] = true;
                        //console.log("1 week date", stats)
                    } 
                    else{
                        console.log("no data weekly", selectDayFrom)
                    }
                    if(this._isMounted){
                        this.setState({
                            statsList, 
                            stats,
                            labelTotal,
                            completedTotal
                        })
                    }
                });
            }//end is mounted
        }
        if(this.props.type==="realtime"){
            this.unsubscribeInsights = this.props.firebase.insight(this.context.uid)
            .onSnapshot(snapshot => {
                if(this._isMounted ){
                    stats = {};
                    if(this._isMounted){
                        this.setState({loading:true});
                    }
                    if (this.state.stats !== undefined ) oldStats = this.state.stats; 
                    var engagementSet = snapshot.data();
                    //var showMoment = false;
                    //console.log('userstats', engagementSet)
                    if(engagementSet!== undefined && engagementSet.engagements !== undefined){
                        if(engagementSet.engagements.leadership_category !== undefined){
                            stats['leadership_category'] = engagementSet.engagements.leadership_category
                        }
                        if(engagementSet.engagements["1stRank"] !== undefined){
                            stats['1stRank'] = engagementSet.engagements["1stRank"]
                        }
                        if(engagementSet.engagements["type"] !== undefined){
                            stats['type'] = engagementSet.engagements["type"]
                        }
                        if(engagementSet.engagements.leadership_quality !== undefined){
                            stats['leadership_quality'] = QualityID(engagementSet.engagements.leadership_quality)
                        }
                        if(engagementSet.engagements.total !== undefined){
                            //console.log('labels', engagementSet.bot_utter);
                            this.replaceDataMeasures(stats, 'points', engagementSet.engagements.total.points, 'All points', 'all')
                            this.replaceDataMeasures(stats, 'engagement', engagementSet.engagements.total.points, 'All engagements', 'all')
                        }
                    }
                    else{
                        this.addDataMeasures(stats, 'points', 1, 1, 'total', 'All points', 'all')
                        this.addDataMeasures(stats, 'engagement', 1, 1, 'total', 'All engagements', 'all')
                    }

                    if (this.state.stats !== undefined && (this.state.inAnimation === undefined || this.state.inAnimation === false)){
                        if(oldStats.total === undefined || (oldStats.total !== undefined && oldStats.total.points.points !== stats.total.points.points)){
                            this.compareOldNewStats(stats, oldStats, engagementSet);
                        }
                        if(this._isMounted){
                            this.setState({stats: stats, loading:false, updateValues:true});
                            ///// write into cache parameter set and Loading ///////////////////
                            updatedBadgeStats['stats'] = stats;
                            updatedBadgeStats['loading'] = false;
                            updatedBadgeStats['updateValues'] = true;
                            DataCacheWrite('engagementStats_'+this.context.uid, stats, 2)
                        }
                    } 
                }//end is mounted
            //console.log("created data set", this.state)
            });
        }
    }
  
    componentWillUnmount() {
        this._isMounted = false;
        if (this.unsubscribe !== undefined) this.unsubscribe();
        if (this.unsubscribeBadges !== undefined) this.unsubscribeBadges();
        if (this.unsubscribeInsights !== undefined) this.unsubscribeInsights();
    }

    compareOldNewStats(stats, oldStats, engagementSet=undefined){
        if(this.state.inAnimation === undefined || this.state.inAnimation === false) {
            var momentFlag = '';
            if(this.props.animateMoments!==false && engagementSet?.latest?.intentClass?.length>1){
                //console.log('stats found last intent', engagementSet.latest.intentClass, engagementSet.latest);
                if(engagementSet.latest.intentClass.includes('inform_intention')){
                    momentFlag = 'intention';
                }
                else if(engagementSet.latest.intentClass.includes('inform_accomplishment')){
                    momentFlag = 'accomplishment';
                }
                else if(engagementSet.latest.intentClass.includes('inform_sentiment')){
                    momentFlag = 'happiness';
                }
                //console.log('moment flag', momentFlag,  oldStats, stats)
            }
            //console.log('comparekicked of', oldStats, stats)
            var oldPoints = 0;
            var newPoints = 0;
            var shownBadges;
            var extraPoints = 0;
            var useTotal=true;
            var nameBatch = '';
            var target = 1;
            for (const [key, value] of Object.entries(oldStats)) {
                if(this._isMounted && ['total', 'leadership_category', 'type'].includes(key)  ){
                    this.setState({inAnimation:true});
                    //console.log('key', key)
                    if(key ==='leadership_category' && this._isMounted){
                        for (const [keyCategory] of Object.entries(value)) {
                            try{
                                oldPoints = oldStats[key][keyCategory].points;
                                newPoints = stats[key][keyCategory].points;
                                extraPoints = newPoints-oldPoints;
                                if(extraPoints > 0) {
                                    nameBatch = 'batch_'
                                        + 'leadership_category_'
                                        + keyCategory
                                    if(this._isMounted){
                                        this.setState({activeCategory: nameBatch, currentCategory: keyCategory});
                                    }
                                    if(this.state.stats!== undefined && this.state[nameBatch] !== undefined ){
                                        target = this.state[nameBatch].target;
                                        var nameBatchGeneral = nameBatch+'_'+target;
                                        shownBadges = this.state.shownBadges || [];
                                        if(newPoints>= target && this._isMounted && !shownBadges.includes(nameBatchGeneral)){
                                            shownBadges = shownBadges.push(nameBatchGeneral);
                                            this.setState({badgeSuccess: nameBatch, shownBadges});
                                            //console.log('badgeSuccess', nameBatch)
                                            this.animateBadge(extraPoints);
                                            useTotal = false;
                                            //break;
                                        }
                                    }
                                    //console.log(keyCategory, extraPoints, nameBatch)
                                }
                            }
                            catch(error) {console.log('no points')};
                        }          
                    }
                    else if(((key ==='total' && useTotal)) && this._isMounted){
                        try{
                            this.setState({activeCategory: ''})
                            oldPoints = oldStats.total.points.points;
                            newPoints = stats.total.points.points;
                            extraPoints = newPoints-oldPoints;
                            //console.log(extraPoints,newPoints, oldPoints )
                            if(extraPoints > 0) {
                                nameBatch = 'batchNextTotal';
                                if(this.state.stats!== undefined && this.state[nameBatch] !== undefined ){
                                    target = this.state[nameBatch].target;
                                    var nameBatchTarget = 'batchNextTotal_'+target;
                                    //console.log('badgeSuccess', nameBatch)
                                    //if(true){
                                    shownBadges = this.state.shownBadges || [];
                                    if((newPoints >= target ) && this._isMounted && !shownBadges.includes(nameBatchTarget)){
                                        shownBadges = shownBadges.push(nameBatchTarget);
                                        this.setState({badgeSuccess: nameBatch, shownBadges});
                                        //console.log('badgeSuccess', nameBatch)
                                        this.animateBadge(extraPoints);
                                        break;
                                    }
                                    //else if(!useTotal){
                                    else{
                                        if(momentFlag!==''){
                                            this.animateMoment(momentFlag);
                                        }
                                        else if(this.props.animateEveryPoint !== false){
                                            this.animatePoints(extraPoints);
                                        }
                                    }
                                }
                                //else if(!useTotal){
                                else{
                                    //console.log('total', this.state, extraPoints)
                                    if(momentFlag!==''){
                                        this.animateMoment(momentFlag);
                                    }
                                    else if(this.props.animateEveryPoint !== false){
                                        this.animatePoints(extraPoints);
                                    }
                                }
                            }
                        }
                        catch(error) {console.log('no points')};
                    }
                }
            }
            if(this._isMounted){
                this.setState({inAnimation:false});
            }
        }
    }

    animatePoints(points, showPlus1Trigger = false){
        if(this._isMounted && this.state.sliderPoints!==true){
            //console.log('points', points)
            if(points > 3){
                //console.log('points', points)
                points = 3;
            }
            this.setState({sliderPoints: true, extraPoints: points, showPlus1Trigger: showPlus1Trigger})
            if(this.props.animationDoneHandler !== undefined){
                this.props.animationDoneHandler();
                //this.setState({showPlus1Trigger: true});
            }
            //var box = document.getElementById('stage-animate')
            //box.style.backgroundColor = "green"
            //balls(box)
            //console.log('timeout stats bar', points, box)
            setTimeout(() => {
                if(this._isMounted){
                    var stateUpdate = {sliderPoints: false, badgeSuccess:'', showPlus1Trigger: false};
                    //console.log('unset sliders')
                    this.setState(stateUpdate);
                    if(points>1){ 
                        this.animatePoints(points-1, true)
                    }
                    else{
                        if(this.state.batchNextTotal !== undefined && this.state.badgeTotal !== undefined && this.state.batchNextTotal.parent !== this.state.badgeTotal.id){
                            console.log('delete missing total', this.state.badgeTotal.id)
                            this.props.firebase.documentIdByCollection('users/'+this.context.uid+'/badges', this.state.badgeTotal.id).delete();
                        }
                        else if(this.state.batchNextTotal === undefined && this.state.badgeTotal !== undefined && this.state.badgeTotal.completed === true){
                            console.log('delete missing next', this.state.badgeTotal.id)
                            this.props.firebase.documentIdByCollection('users/'+this.context.uid+'/badges', this.state.badgeTotal.id).delete();
                        }
                        //console.log('delete badge',this.state)
                        //if(this.state.badgeTotal?.completed === true && this.state.badgeTotal?.id?.length>0 && this.state.batchNextTotal?.completed === false){
                        //    //console.log('delete missing total', this.state.badgeTotal.id, this.state)
                        //    //this.props.firebase.documentIdByCollection('users/'+this.context.uid+'/badges', this.state.badgeTotal.id).delete();
                        //}
                    }
                }
            }, (animationTime/points));
        }
    }
    animateBadge(points){
        if(this._isMounted && this.state.sliderPoints!==true){
            //console.log('points', points)
            if(points > 3){
                //console.log('points', points)
                points = 3;
            }
            this.setState({sliderPoints: true, extraPoints: points, showPlus1Trigger: false})
            if(this.props.animationDoneHandler !== undefined){
                this.props.animationDoneHandler();
                //this.setState({showPlus1Trigger: true});
            }
            if(this.state[this.state.badgeSuccess]?.id?.length > 0){
                this.props.firebase.documentIdByCollection('users/'+this.context.uid+'/badges', this.state[this.state.badgeSuccess].id).update({completed: true})
                //console.log('auto update badge id', this.state[this.state.badgeSuccess].id)
            }
            //var box = document.getElementById('stage-animate')
            //box.style.backgroundColor = "green"
            //balls(box)
            //console.log('timeout stats bar', points, box)
            setTimeout(() => {
                if(this._isMounted){
                    var stateUpdate = {sliderPoints: false, badgeSuccess:'', showPlus1Trigger: false};
                    //console.log('unset sliders')
                    this.setState(stateUpdate)
                }
            }, (animationTime*4));
        }
    }
    animateMoment(momentType){
        if(this._isMounted && this.state.sliderPoints!==true){
            this.setState({sliderPoints: true, badgeMoment: momentType, showPlus1Trigger: false})
            if(this.props.animationDoneHandler !== undefined){
                this.props.animationDoneHandler();
            }
            setTimeout(() => {
                if(this._isMounted){
                    var stateUpdate = {sliderPoints: false, badgeMoment: undefined, showPlus1Trigger: false};
                    //console.log('unset sliders')
                    this.setState(stateUpdate)
                }
            }, (animationTime*3));
        }
    }

    componentDidUpdate(prevProps){
        if(this._isMounted && this.props.animateMomentByName?.length>1 && this.props.animateMomentByName !== prevProps.animateMomentByName){
            //console.log('this.props.animateMomentByName', this.props.animateMomentByName)
            //var event = this.props.animateMomentByName;
            this.animateMoment(this.props.animateMomentByName);
            if(this.props.animationDoneHandler) {
                this.props.animationDoneHandler();
            }
        }
        else if(this._isMounted && this.props.animatePlus1Trigger === true && this.state.sliderPoints !== true ){
            //console.log('external trigger', this.props)
            this.animatePoints(1, true);
        }
        if(this.state.updateValues && this._isMounted){
            this.setState({updateValues:false});
            //console.log(this.state.stats)
            var labelTotal = "1/3";
            var labelShort = "1/3";
            var completedTotal = 5;
            //var completed = this.state.completed || 5;
            if(this.state.stats!== undefined && this.state.stats.total !== undefined && this._isMounted){
                labelTotal = this.state.stats.total.points.points;
                //completed =  this.state.stats.total.points.points / this.state.stats.total.points.engagements
                this.setState({labelTotal, completedTotal});
            }
            if( this.state.stats!== undefined && this.state.stats.total !== undefined 
                && this.state.batchNextTotal !== undefined && this._isMounted){
                var targetTotal = this.state.batchNextTotal.target;
                var currentTotalPoints = this.state.stats.total.points.points
                if( this.state.stats!== undefined && this.state.stats.total !== undefined ){
                    currentTotalPoints =  this.state.stats.total.points.points
                } 
                completedTotal = Math.ceil(currentTotalPoints / targetTotal * 100)
                if (completedTotal>100) completedTotal= 100;
                //console.log("Currenttotal", targetTotal, completedTotal)
                var targetValue = targetTotal || 100;
                if(targetValue>=1000){
                    targetValue= Math.round(targetValue/1000)+"K";
                }
                labelTotal = currentTotalPoints + "/" + targetValue;
                this.setState({labelTotal, completedTotal});
            }

            if(this.state.stats!== undefined && this.state.stats.total !== undefined 
                && this.state[this.state.activeCategory] !== undefined  && this._isMounted){
                var target = this.state[this.state.activeCategory].target;
                var currentValue = 0;
                //console.log('options',this.state.stats['category'])
                if( this.state.stats!== undefined &&  this.state.stats['category']!== undefined &&
                    this.state.stats['category'][this.state.currentCategory]!== undefined ){
                    currentValue = this.state.stats['category'][this.state.currentCategory].points ;
                    //console.log('target',  this.state[this.state.activeCategory], this.state.stats['category'][this.state.currentCategory])
                } 
                
                var completedNew = Math.ceil(currentValue / target * 100)
                if (completedNew>100) completedNew= 100;
                //console.log("currentValue", completedNew, target)
                labelShort = currentValue + "/" +target;
                this.setState({labelShort, completed: completedNew});
            }
        }//end if loading
    }

    render() {
        const { classes } = this.props;
        const {labelShort, completed, labelTotal, completedTotal, dialogCardOpen} = this.state;
        var labelXP = labelTotal;
        if(this.props.type === 'total' && this.context?.engagementCounter>0){
            //labelXP = this.context.engagementCounter;
            //console.log('stat', this.state.completed, completed)
        }
        //console.log('stat', this.state)
        return (
            <span 
                style={{
                    flexGrow: 1,
                    //display:"inline-block",
                    height: this.props.hide? 'auto' : 'auto',
                    backgroundColor: "transparent", 
                    position: 'relative', 
                    float : (this.props.verticalAlign || 'right'),
                    zIndex: '14000 !important',
                    top : this.props.hide? -400 : 0 ,
                    width : this.props.hide? 40 : 'auto' 
                }}
            >
                <div 
                    style={{ 
                        position: 'relative',   
                        width: "auto",
                        height: this.props.hide? 'auto' : 'auto' 
                    }}
                >
                    {this.props.type==="realtime" 
                    &&
                        <div //className={classes.animationPosition}
                            style={{
                                position: "absolute",
                                right: this.props.hide? 0 : 96,
                                zIndex: 1500,
                                top : this.props.hide? 400 : 0 }}
                        >
                            {
                                /////// This is the first part that brings the slider start from left to right
                            }
                            <Zoom in={(this.state.sliderPoints || false) && !this.props.animatePlus1Trigger } timeout={animationTime*2} >
                                <Slide
                                    direction="right"
                                    in={(this.state.sliderPoints || false) && !this.props.animatePlus1Trigger}
                                    //in={true}
                                    //display ={"block"}
                                    timeout={animationTime} 
                                    mountOnEnter
                                    unmountOnExit
                                    >
                                        {false && this.state.badgeSuccess !== undefined && this.state.badgeSuccess !== '' 
                                            && this.state[this.state.badgeSuccess] !== undefined &&
                                            this.state[this.state.badgeSuccess].iconUrl !== undefined && this.state[this.state.badgeSuccess].iconUrl !== ''
                                            ?
                                                <img src={this.state[this.state.badgeSuccess].iconUrl} 
                                                    alt={this.state[this.state.badgeSuccess].title}
                                                    style={{
                                                        width: squareSize
                                                    }}
                                                />
                                            :
                                                <Avatar className={this.state.extraPoints!== undefined && this.state.extraPoints> 1 ? this.state.extraPoints> 2 ? classes.StarsIcon3 : classes.StarsIcon2 : classes.StarsIcon}>
                                                    <StarTwoToneIcon  className={this.state.extraPoints!== undefined && this.state.extraPoints> 1 ? this.state.extraPoints> 2 ? classes.StarsIcon3 : classes.StarsIcon2 : classes.StarsIcon}/>
                                                </Avatar>
                                        }
                                </Slide>
                            </Zoom>
                            {
                                /////// This is the first part that brings the slider start from left to right
                            }
                            {//true
                                this.state.sliderPoints 
                                && this.state.badgeSuccess !== undefined && this.state.badgeSuccess !== '' 
                                && this.state[this.state.badgeSuccess] !== undefined 
                                && this.state[this.state.badgeSuccess].iconUrl !== undefined && this.state[this.state.badgeSuccess].iconUrl !== ''
                                //&& !this.state.showPlus1Trigger
                                ?
                                    <Backdrop 
                                        open={this.state.sliderPoints || false }
                                        //open={true}
                                        onClick={() => this.setState({sliderPoints: false})}
                                        style={{
                                            position: 'fixed',
                                            zIndex: '15000 !important',
                                            inset: 0
                                        }}
                                        //invisible={true}
                                        >
                                        <Slide 
                                            //direction={this.state.sliderPoints? "up": "right"} 
                                            direction={this.state.sliderPoints? "up": "up"} 
                                            //in={true}
                                            in={this.state.sliderPoints || false } 
                                            timeout={animationTime*4} 
                                            mountOnEnter unmountOnExit>
                                            <div 
                                                //elevation={1000} 
                                                style={{
                                                    position: 'absolute',
                                                    left: 'calc(50vW - (100px))',
                                                    bottom: 0,
                                                    top: '40vH',
                                                    zIndex: '15002 !important',
                                                    width: 200,
                                                    backgroundColor: 'transparent',
                                                    color: this.props.theme.palette.themeAltGold,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                Level Up!
                                                <br/>
                                                <strong>{this.state[this.state.badgeSuccess]?.title || ''}</strong>
                                                <br/>
                                                <img 
                                                    src={this.state[this.state.badgeSuccess]?.iconUrl || logo} 
                                                    alt={this.state[this.state.badgeSuccess]?.title || 'Congratulations'}
                                                    style={{
                                                        opacity: 1,
                                                        width: '15vH',
                                                        maxWidth: 150
                                                    }}
                                                />
                                                <br/>
                                                <strong>{this.state[this.state.badgeSuccess]?.description || 'Congratulations'}</strong>
                                            </div>
                                        </Slide>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                //left: '-25vW',
                                                left: 'calc(50vW - (300px))',
                                                bottom: 0,
                                                top: '60vH',
                                                zIndex: '15001 !important',
                                                backgroundColor: 'transparent',
                                                color: 'transparent',
                                            }}>
                                            <img 
                                                src={confetti} 
                                                alt={'Congratulations'}
                                                style={{
                                                    opacity: 0.6,
                                                    //width: '150vW',
                                                    width: 600
                                                }}
                                            />
                                        </div>
                                    </Backdrop>
                                :
                                    this.state.badgeMoment?.length > 1
                                    ?
                                        <Backdrop 
                                            open={this.state.sliderPoints || false } 
                                            //open={true}
                                            onClick={() => this.setState({sliderPoints: false})}
                                            style={{
                                                position: 'fixed',
                                                zIndex: '15000 !important',
                                                inset: 0,
                                                opacity: 0.6
                                            }}
                                            >
                                            <Slide 
                                                direction={this.state.sliderPoints? "up": "up"} 
                                                in={this.state.sliderPoints || false } 
                                                //in={true}
                                                timeout={animationTime*4} 
                                                mountOnEnter unmountOnExit>
                                                <div 
                                                    //elevation={1000} 
                                                    style={{
                                                        position: 'absolute',
                                                        left: 'calc(50vW - (100px))',
                                                        bottom: 0,
                                                        top: '40vH',
                                                        zIndex: '15002 !important',
                                                        width: 200,
                                                        backgroundColor: 'transparent',
                                                        color: this.props.theme.palette.themeAltGold,
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    <strong>
                                                        {
                                                        this.state.badgeMoment==='spin'
                                                            ?   'Joker Time!'
                                                            :   this.state.badgeMoment==='intention'
                                                                ?   'Great Idea'
                                                                :   this.state.badgeMoment ==='happiness'
                                                                    ?   'So Exciting'
                                                                    :   'Well Done!'
                                                        }
                                                    </strong>
                                                    <br/>
                                                    <img 
                                                        src={
                                                            this.state.badgeMoment==='spin'
                                                            ?   badgeSpinWheel
                                                            :   this.state.badgeMoment==='accomplishment'
                                                                    ?   badgeAccomplishment 
                                                                    :   this.state.badgeMoment ==='happiness'
                                                                        ?   badgeHappiness
                                                                        :   badgeIntention
                                                            } 
                                                        alt={'Congratulations'}
                                                        style={{
                                                            opacity: 1,
                                                            width: '15vH',
                                                            maxWidth: 150
                                                        }}
                                                    />
                                                    <br/>
                                                    {this.state.badgeMoment==='spin'
                                                        ?   <strong>Question Wild Card<br/>Max. 3 available</strong>
                                                        :   ['accomplishment', 'intention', 'happiness'].includes(this.state.badgeMoment)
                                                            ?   <strong>Added{this.state.badgeMoment==='accomplishment' ? ' Victory' : this.state.badgeMoment==='intention' ? ' Goal': ' New Goal'}</strong>
                                                            :   <strong>You are awesome!</strong>
                                                    }
                                                </div>
                                            </Slide>
                                            {this.state.badgeMoment==='accomplishment'
                                            ?
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        //left: '-25vW',
                                                        left: 'calc(50vW - (300px))',
                                                        bottom: 0,
                                                        top: '60vH',
                                                        zIndex: '15001 !important',
                                                        backgroundColor: 'transparent',
                                                        color: 'transparent',
                                                    }}>
                                                    <img 
                                                        src={confetti} 
                                                        alt={'Congratulations'}
                                                        style={{
                                                            opacity: 0.6,
                                                            //width: '150vW',
                                                            width: 600
                                                        }}
                                                    />
                                                </div>
                                            : 
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        //left: '-25vW',
                                                        left: 'calc(50vW - (300px))',
                                                        bottom: 0,
                                                        top: '80vH',
                                                        zIndex: '15001 !important',
                                                        backgroundColor: 'transparent',
                                                        color: 'transparent',
                                                    }}>
                                                    <img 
                                                        src={dust} 
                                                        alt={'Congratulations'}
                                                        style={{
                                                            opacity: 0.6,
                                                            //width: '150vW',
                                                            width: 600
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </Backdrop>
                                    :
                                        (this.props.showPlus1 && this.state.showPlus1Trigger === true)
                                        ?
                                            <>
                                                <Slide direction={this.state.sliderPoints? "up":"right"} in={this.state.sliderPoints || false } timeout={animationTime*2}  mountOnEnter unmountOnExit>
                                                    <div 
                                                        //elevation={1000} 
                                                        style={{
                                                            position: 'absolute',
                                                            bottom: 0,
                                                            left: "calc('50% - 20px')",
                                                            zIndex: '15001 !important',
                                                            backgroundColor: 'transparent',
                                                            color: 'transparent',
                                                        }}
                                                    >
                                                        {this.state.extraPoints > 1
                                                            ?
                                                                <StyledBadge badgeContent={'+'+this.state.extraPoints}>
                                                                    <FavoriteIcon className={classes.Plus2Icon}/>
                                                                </StyledBadge>
                                                            :
                                                                <StyledBadge badgeContent={'+1'}>
                                                                    <ModeCommentIcon className={classes.Plus1Icon}/>
                                                                </StyledBadge>
                                                        }
                                                    </div>
                                                </Slide>
                                            </>
                                        :   ''
                            }                
                        </div>
                    }
                    {this.state[this.state.activeCategory] !== undefined && this.state.activeCategory !== undefined  && this.state.activeCategory !== '' 
                    &&
                        <Tooltip title={this.state[this.state.activeCategory].title + ": " + labelShort} aria-label="add"
                            onClick={this.props.handleClick !== undefined
                                ?   () => this.props.handleClick()
                                :   (e) => {this.setState({dialogCardOpen : true})}}
                        >
                        {
                            this.state[this.state.activeCategory].iconUrl !== undefined && this.state[this.state.activeCategory].iconUrl !== ''
                            ?
                                <StyledChip
                                    icon={
                                        <Avatar variant="square" alt="+" src={this.state[this.state.activeCategory].iconUrl
                                    }  
                                    style={{opacity: this.state.sliderPoints? 0: 1, backgroundColor:"transparent", width: this.props.size==="large"? "4rem" : 25, height: this.props.size==="large"? "4rem" : 25}} />}
                                    label={
                                        <span className={classes.progressBar}>
                                            <LinearProgress variant="determinate" value={completed} color="secondary" />
                                            <span style={{color: this.props.darkmode? theme.palette.white : theme?.palette?.darkmode ? theme.palette.themeText : theme.palette.secondary.main }}>
                                                {this.props.labelShort!==undefined && this.props.labelShort!==""
                                                    ?   this.props.labelShort + ": " + labelXP
                                                    :   "XP: " + labelXP
                                                }
                                            </span>
                                        </span>
                                    }
                                    clickable
                                    //color="primary"
                                />
                            :
                                <StyledChip
                                    icon={
                                        <TrackChangesIcon 
                                            style={{opacity: this.state.sliderPoints? 0: 1, color: this.props.theme.palette.themeAltGold, width: this.props.size==="large"? "4rem" : 25, height: this.props.size==="large"? "4rem" : 25}} 
                                        />
                                    }
                                    label={
                                        <span className={classes.progressBar}>
                                            <LinearProgress variant="determinate" value={completed} color="secondary" />
                                            <span style={{color: this.props.darkmode? theme.palette.white : theme?.palette?.darkmode ? theme.palette.themeText : theme.palette.secondary.main }}>
                                                {this.props.labelShort!==undefined && this.props.labelShort!==""
                                                    ?   this.props.labelShort + ": " + labelXP
                                                    :   "XP: " + labelXP
                                                }
                                            </span>
                                        </span>
                                    }
                                    clickable
                                    color="primary"
                                />
                        }
                        </Tooltip>
                    }
                    {this.state.stats !== undefined && (this.state.activeCategory === undefined  || this.state.activeCategory === '' )
                        &&
                        <Tooltip 
                            title={"Total Points: " + labelXP} 
                            aria-label="add" 
                            onClick={this.props.handleClick !== undefined
                                ?   () => this.props.handleClick()
                                :   (e) => { this.setState({dialogCardOpen : true})}}
                        >
                        {this.state.badgeTotal !== undefined && this.state.badgeTotal.iconUrl !== undefined && this.state.badgeTotal.iconUrl !== ''
                            ?
                                <StyledChip
                                    icon={
                                        <Avatar variant="square" alt="+" src={this.state.badgeTotal.iconUrl} 
                                            style={{
                                                opacity: this.state.sliderPoints? 0: 1, 
                                                backgroundColor:"transparent",  
                                                width: this.props.size==="large"? "4rem" : 25, 
                                                height: this.props.size==="large"? "4rem" : 25,
                                                webKitFilter: (this.props.avatarGreyOut ? 'grayscale(1)' : 'grayscale(0)'), /* Google Chrome, Safari 6+ & Opera 15+ */
                                                filter: (this.props.avatarGreyOut ? 'grayscale(1)' : 'grayscale(0)'), /* Microsoft Edge and Firefox 35+ */
                                            }}
                                        />}
                                    label={
                                        <span className={classes.progressBar}>
                                            <LinearProgress variant="determinate" value={completedTotal} color="secondary" />
                                            <span style={{color: this.props.darkmode? theme.palette.white : theme?.palette?.darkmode ? theme.palette.themeText : theme.palette.secondary.main }}>
                                                {this.props.labelShort!==undefined && this.props.labelShort!==""
                                                    ?   this.props.labelShort + ": " + labelXP
                                                    :   "XP: " + labelXP
                                                }
                                            </span>
                                        </span>
                                    }
                                    clickable
                                    color="primary"
                                />
                            :
                                <StyledChip
                                    icon={
                                        completedTotal > 90
                                        ?   <CheckCircleIcon  style={{opacity: this.state.sliderPoints? 0: 1, color:"green", width: this.props.size==="large"? "4rem" : 25, height: this.props.size==="large"? "4rem" : 25}} />
                                        :   completedTotal > 70
                                            ?   <StarsIcon style={{backgroundColor:"transparent", color: this.props.theme.palette.themeAltGold, width: this.props.size==="large"? "4rem" : 25, height: this.props.size==="large"? "4rem" : 25}}/>
                                            :   completedTotal > 40
                                                ? <StarHalfIcon style={{backgroundColor:"transparent", color: this.props.theme.palette.themeAltGold, width: this.props.size==="large"? "4rem" : 25, height: this.props.size==="large"? "4rem" : 25}}/>
                                                : <StarTwoToneIcon style={{backgroundColor:"transparent", color: this.props.theme.palette.themeAltGold, width: this.props.size==="large"? "4rem" : 25, height: this.props.size==="large"? "4rem" : 25}}/>
                                        
                                    }
                                    label={
                                        <span className={classes.progressBar}>
                                            <LinearProgress variant="determinate" value={completedTotal} color="secondary" />
                                            <span style={{color: this.props.darkmode? theme.palette.white : theme?.palette?.darkmode ? theme.palette.themeText : theme.palette.secondary.main }}>
                                                {this.props.labelShort!==undefined && this.props.labelShort!==""
                                                    ?   this.props.labelShort + ": " + labelXP
                                                    :   "XP: " + labelXP
                                                }
                                            </span>
                                        </span>
                                    }
                                    clickable
                                    color="primary"
                                />
                        }
                        </Tooltip>
                    }
                </div>
                <DialogCardBadges 
                    handleOpen={dialogCardOpen || false}
                    handleClose={() => {if (this._isMounted) {this.setState({dialogCardOpen: false})}}}
                />
            </span>
        );
    }
}


export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(StatsBar);
