import React, { Component } from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';

import './ProgressHeatMap.css';

import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    grow: {
      flexGrow: 1,
    },
});


class ProgressHeatMap extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        var [data, options] = this.loadChartData()
        this.state = { data, options};
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadChartData(){
        var options = []
        var data = []
        if(this.props.values !== undefined && this.props.values !== null ){
            this.props.values.map(item => {
                var set=item;
                if(item.date !== undefined){
                    set['date'] = item.date.substring(0, 10);
                }
                data.push(set);
                return true;
            })
        }
        return [data, options]
    }

    componentDidUpdate(prevProps)  {
        if(this._isMounted){
            var newValues = '';
            var oldValues = '';
            if(this.props.values !== undefined && this.props.values !== ""){
                newValues = this.props.values
            }
            if(prevProps.values !== undefined && prevProps.values !== ""){
                oldValues = prevProps.values
            }
            if(newValues !== oldValues && newValues!=="" && oldValues!=="") {
                var [data, options] = this.loadChartData();
                this.setState({ data, options});
                //console.log('updated values',  data, options)
            }
        }
        return true
    }
    
    render() {
        return(
            <CalendarHeatmap
                startDate={this.props.startDate || undefined}
                endDate={this.props.endDate || undefined}
                values={this.state.data || []}
                classForValue={(action) => {
                    if (!action) {
                        return 'color-empty';
                    }
                    else if (action.done) {
                        return 'color-themeAlertGreen';
                    }
                    else if (action.failed) {
                        return 'color-themeBlack';
                    }
                    else if(action.urgent) {
                        return 'color-themeAlertRed';
                    }
                    else if(action.important) {
                        return 'color-themeAlertBlue';
                    }
                    else if(action.onTrack) {
                        return 'color-themeAlertGreen';
                    }
                    else if(action.offTrack) {
                        return 'color-themeAlertOrange';
                    }
                    else{
                        return 'color-filled';
                    }
                }}
                onClick={(action) => {
                    this.props.onClick!==undefined && action!==null && action!==undefined 
                        ?   this.props.onClick(action)
                        :   void(0)
                }}
                weekdayLabels={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
                showMonthLabels={this.props.showMonthLabels || false}
                showWeekdayLabels={this.props.showWeekdayLabels || false}
                //showOutOfRangeDays={this.props.showOutOfRangeDays || false}
                showOutOfRangeDays={true}
            />
        )
    }
}

export default compose(
    withStyles(styles, { withTheme: true })
)(ProgressHeatMap);
