import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { AuthUserContext } from '../../Session';
import { withFirebase } from '../../Firebase';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
//import TextField from '@material-ui/core/TextField';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import PromptSupport from '../../Admin/Modules/PromptSupport';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});


class SettingContentMapSelect extends Component {
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        this.state = {
            defaultValue: [],
            openSetting: false,
        };
    }

    // Username Handling
    handleSettingOpen = () => {
        this.props.params.map((p) => {
            if(this.props.content[p]!== undefined){
                return this.setState({[p] : this.props.content[p], [(p+'Change')]: this.props.content[p]});
            }
            else{
                return this.setState({[p] : this.state.defaultValue, [(p+'Change')]:this.state.defaultValue});
            }
        })
        this.setState({
            openSetting: true,
        });
    }

    handleSettingChange = (param, entry, index, event) => {
        //console.log("change", this.state, param, entry,  event.target.value, index)
        /****  */
        var myObject = {...this.state[(param+'Change')]};
        if (myObject === undefined || myObject === null){ myObject = {} }
        if(event.target.value !== ''){
            myObject[entry] = event.target.value
            //console.log('new param', myObject)
            this.setState({
                [(param+'Change')]: myObject
            });
        } 
        
    }

    handleSettingClose = () => {
        this.setState({
            openSetting: false,
        });
        this.props.params.map((p) => {
            return this.setState({[p] : this.state.defaultValue, [p+'Change']:this.state.defaultValue});
        })
    }

    handleSettingSubmit = () => {
        this.props.params.map((p) => {
            if(this.state[(p+'Change')] !== null && this.state[(p+'Change')] !== undefined && this.state[(p+'Change')] !== this.props.content[p] ){
                var myObject = {...this.state[(p+'Change')]};
                this.props.dbUpdateLink.update({[p]: myObject      });
                //console.log('new setting', myObject)
            }
            return '';
        })        
        this.handleSettingClose();
    }

    render() {
        const { content, params, title, selectOptions, selectOptionsText, labels , mapOptions, mapOptionsText} = this.props;
        const { theme, classes } = this.props;

        return (
        content
        ?
            <div className={classes.grow}>
                <Card style={theme.cardAdminSettings} onClick={this.handleSettingOpen}>
                    <Grid container>
                        <Grid item xs={9} >
                            <h6><a to="#">{title}</a>
                            {this.props.mandatory 
                                &&  <strong style={{color:theme.palette.themeRed}}>&nbsp;*</strong>
                            }
                            {this.props.mandatory===false
                                &&  <span style={{fontSize: 'small', fontWeight:'normal'}}>&nbsp;(optional)</span>
                            }
                            </h6>
                          </Grid>
                        <Grid item xs >  </Grid>
                        <Grid item xs >
                            {(this.props.access === undefined || this.props.access === true)
                            &&
                                <IconButton onClick={this.handleSettingOpen}> 
                                    <EditIcon fontSize="small" style={{color:"red"}}/>
                                </IconButton>
                            }
                        </Grid>
                    </Grid>
                    {
                        params.map((p, index) => (
                            <p style={theme.textSettings} key={"current_"+p}>
                                <span style={{ float: 'right', fontWeight: 700, maxWidth:'75%' }}>
                                    {mapOptions!== undefined && mapOptions.map((m, index) => (
                                        content[p]!== undefined && typeof content[p] === "object"
                                        ? 
                                            <li key={"sort_"+index}>
                                                {m} 
                                                {mapOptionsText!== undefined? " ("+mapOptionsText[index]+")" : ""} 
                                                { ": " }
                                                {selectOptions!== undefined && selectOptionsText !== undefined && content[p][m]!==undefined && selectOptions.indexOf(content[p][m]) >= 0
                                                    ?   selectOptionsText[ selectOptions.indexOf(content[p][m]) ]
                                                    :   content[p][m]
                                                }
                                            </li>
                                        :   <li key={"sort_"+index}>
                                                {m} 
                                                { mapOptionsText!== undefined? " ("+mapOptionsText[index]+")" : "" }
                                                { ": [n/a]"}
                                            </li>
                                        ))
                                    }
                                </span>
                                <span>{labels!==undefined? labels[index]: p}</span>
                            </p>
                        ))
                    }
                </Card>
                <Dialog
                    open={this.state.openSetting}
                    onClose={this.handleSettingClose}
                    fullWidth
                    maxWidth="sm">
                    <DialogTitle>Change Setting</DialogTitle>
                    <form>
                        <DialogContent>
                            { this.state.openSetting && params.map((p, index) => (
                                <div style={{width: `100%`}} key={"change_"+p}> 
                                    <p>{title}</p>
                                    <strong>{labels!==undefined? labels[index]: p}</strong>
                                        {mapOptions!== undefined && mapOptions.map((m, indexLow) => (
                                            <div key={indexLow+"_org_"+index+"_"+indexLow}  style={{height: 50}}> 
                                                {mapOptionsText!== undefined 
                                                    ?   mapOptionsText[indexLow]
                                                        //<strong >{this.state[p][m]}</strong>
                                                    :   <strong ></strong>
                                                }
                                                {/***/
                                                <Select
                                                    style={{ float: 'right', width:'60%'}}
                                                    value={this.state[(p+'Change')] !== undefined && this.state[(p+'Change')][m] !== undefined ? this.state[(p+'Change')][m] : this.props.content[p] !== undefined && this.props.content[p][m]  !== undefined  ?  this.props.content[p][m] : ""}
                                                    onChange={(e) => this.handleSettingChange(p, m, indexLow, e)}
                                                    name={p}
                                                    disabled={ this.state[(p+'Change')] !== undefined && this.state[(p+'Change')][m] !=="" && this.state[(p+'Change')][m] !==undefined && !(selectOptions.includes(this.state[(p+'Change')][m])) && !this.context.roles.includes("ADMIN")? true:false}
                                                    >
                                                    {
                                                        selectOptions.map((entry, indexDeep) => {
                                                            return (
                                                                <MenuItem key={"select_"+entry} value={entry}
                                                                    selected={entry === m}
                                                                >
                                                                    {
                                                                    selectOptionsText !== undefined && selectOptionsText[indexDeep] !== undefined && selectOptionsText[indexDeep] !== ""
                                                                        ? selectOptionsText[indexDeep]
                                                                        : entry
                                                                    }
                                                                </MenuItem>
                                                            );
                                                        })
                                                    }
                                                </Select>
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            )) }
                            {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                                ?
                                <p style={theme.textErrorCenterPadding}>
                                    {this.state.errorMessage}
                                </p>
                                : <p> </p>
                            }
                            {this.props.promptSupport?.length > 2 &&
                                <PromptSupport 
                                    prompt = {this.props.promptSupport || ""}
                                />
                            }
                        </DialogContent>
                    </form>
                    <DialogActions>
                        <Button onClick={this.handleSettingClose}>
                            Cancel
                        </Button>
                        <Button onClick={this.handleSettingSubmit}>
                           Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        :   <div> </div>
                
        );
    }
}

SettingContentMapSelect.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(SettingContentMapSelect);
