import React, { Component, useEffect } from 'react';
import { AuthUserContext } from '../Session';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import HearingIcon from '@material-ui/icons/Hearing';
import IconButton from '@material-ui/core/IconButton';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

//import bubbleAnimated from "../../images/animatedbubble.gif";
import SmsIcon from '@material-ui/icons/Sms';
import { ObjectsAreIdentical } from './FindTextValueInDict';
import ClearIcon from '@material-ui/icons/Clear';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    "@global": {
        "@keyframes pulse": {
            "0%": {
                opacity: 1,
                //transform: "translateY(5rem)"
            },
            "50%": {
                opacity: 0.2,
                //transform: "translateY(5rem)"
            },
            "100%": {
                opacity: 1,
                //transform: "translateY(0)"
            }
        }
    },
});

var _listeningActive = false;
//console.log('reloaded Speech ', _listeningActive)
var _renderKey = undefined
function _startListening(renderKey, browserSupportsContinuousListening, user = {}){
    if(renderKey?.length > 0 ){
        _renderKey = renderKey;
    }
    //console.log('timeuted to set _listeningActive', renderKey, _listeningActive)
    if(_listeningActive !== true){
        try{
            SpeechRecognition.startListening({
                    language: user?.features?.language?.length > 2 ? user.features.language : 'en-US', 
                    continuous: browserSupportsContinuousListening 
                })
            setTimeout(() => {
                _listeningActive = true
            }, 300);
        } catch  (e){
            console.log('speech error', e)
            _listeningActive = false
        }
    }
}
function _stopListening(){
    if(_listeningActive){
        try{
            SpeechRecognition.stopListening()
            setTimeout(() => {
                //console.log('SpeechRecognition.stopListening()')
                _listeningActive = false
            }, 500);
        } catch  (e){
        }
    }
} 
function _stopListeningNow(){
    if(true){
        try{
            SpeechRecognition.abortListening()
            setTimeout(() => {
                //console.log('SpeechRecognition.abortListening()')
                _listeningActive = false
            }, 500);
        } catch  (e){
        }
    }
} 

const Dictaphone = ({existingText = "", onRecognizeText = undefined, size = 'unknown', resetText = false, renderKey=undefined, user={}}) => {
    let {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
        browserSupportsContinuousListening,
        isMicrophoneAvailable,
    } = useSpeechRecognition();
    var flagResetted = false;
    if(resetText === true && transcript?.length > 0){
        console.log('resetText', existingText, transcript , renderKey)
        flagResetted = true;
        try{
            _stopListeningNow();
            resetTranscript();
            onRecognizeText("", renderKey);
        } catch (e){}
    }
    //console.log('browserSupportsSpeechRecognition existingText', existingText, transcript, listening, renderKey)
    //console.log('browserSupportsSpeechRecognition transcript', transcript)
    //console.log('browserSupportsSpeechRecognition call', browserSupportsSpeechRecognition, browserSupportsContinuousListening, isMicrophoneAvailable)
    if(_renderKey?.length > 0 && _renderKey !== renderKey){
        listening = false;
        transcript = existingText;
        //console.log('this is nout our key', _renderKey, renderKey)
    }
    /// ensure the 
    if(listening === false){
        _listeningActive = false
    }
    //console.log('user effect before', _renderKey, renderKey, flagResetted)
    useEffect(() => {
        try{
            //console.log('user effect ', _renderKey, renderKey, flagResetted)
            if(flagResetted === true){
                console.log('user effect flagResettedflagResetted', _renderKey, renderKey)
                onRecognizeText("", renderKey);
            }
            else if(listening === false){
                //console.log('listenng effecgt false', _renderKey, renderKey)
            }
            else{
                //// this does not work, because auto correction of the text
                if (false && existingText?.length > 0 && existingText?.length > transcript?.length) {
                    console.log('Overwrite longer text', existingText, transcript, _renderKey, renderKey)
                    _stopListeningNow();
                    onRecognizeText(existingText, renderKey);
                }
                else if (transcript?.length > 1 && transcript !== existingText) {
                    //console.log('Overwrite capitalizedText')
                    const capitalizedText = transcript[0].toUpperCase() + transcript.slice(1);
                    onRecognizeText(capitalizedText, renderKey);
                }
            }
        } catch (e){
            console.log('useffect error', e)
        }
    }, [transcript, onRecognizeText]);

    /// needed for making the change possible
    var checkTranscript = transcript + ""
    if (transcript?.length > 1) {
        checkTranscript = transcript[0].toUpperCase() + transcript.slice(1);
    }
    //console.log("checkTranscript", checkTranscript, existingText)
    if (!browserSupportsSpeechRecognition) {
        return ""
    }
    else if (listening ) {
        return(
            <>
                <IconButton
                    edge={size === 'large' ? false : "start"}
                    aria-label="Send message"
                    onMouseDown={() => { _stopListening() }} 
                    onTouchStart={() => { _stopListening() }} 
                >
                    <HearingIcon 
                        size={size === 'large' ? "large" : "medium"}
                        style={{ 
                            color: transcript !== existingText ? 'red' : 'green',
                            animation: 'pulse 2s infinite ease-in-out'
                        }}  
                    />
                </IconButton>
                {checkTranscript?.length > 1 && size !== 'large'
                &&
                    <IconButton
                        aria-label="Reset texgt"
                        onClick={() => {
                            try{
                                _stopListeningNow();
                                resetTranscript();
                                onRecognizeText("", renderKey);
                            }
                            catch(e){

                            }
                        }}
                    >
                        <ClearIcon 
                            size={'small'}
                        />
                    </IconButton>
                }
            </>
        )
    }
    else if (!isMicrophoneAvailable) {
        return(
            <IconButton
                edge={size === 'large' ? false : "start"}
                aria-label="Send message"
                onMouseDown={() => _startListening(renderKey, browserSupportsContinuousListening, user)} 
                onTouchStart={() => _startListening(renderKey, browserSupportsContinuousListening, user)} 
            >
                <MicOffIcon 
                    size={size === 'large' ? "large" : "medium"}
                    style={{color: listening ? 'green' : 'red' }}  
                />
            </IconButton>
        )
    }
    else{
        return(
            <>
                <IconButton
                    edge={size === 'large' ? false : "start"}
                    aria-label="Send message"
                    onMouseDown={() => _startListening(renderKey, browserSupportsContinuousListening, user)} 
                    onTouchStart={() => _startListening(renderKey, browserSupportsContinuousListening, user)} 
                >
                    <MicIcon 
                        style={{ color: 'green' }} 
                        size={size === 'large' ? "large" : "medium"}
                    />
                </IconButton>
                {checkTranscript?.length > 1 && size !== 'large'
                &&
                    <IconButton
                        aria-label="Reset texgt"
                        onClick={() => {
                            try{
                                _stopListeningNow();
                                resetTranscript();
                                onRecognizeText("", renderKey);
                            }
                            catch(e){

                            }
                        }}
                    >
                        <ClearIcon 
                            size={'small'}
                        />
                    </IconButton>
                }
            </>
        )
    }
}

class SpeechToTextButton extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            isRecognitionAvailable : false,
            isPermissionGiven : false,
            timeLeft : 1000,
            bubbleActive: true,
            tempText: '',
            tempDetected: ''
        }
    }

    componentDidMount() {
        this._isMounted = true;
        try{
            if(process?.env?.REACT_APP_DEVICE_PLATFORM==="BROWSER"){
                //console.log('browser supports speech')
                //this._getSpeechLocalBrowser()
            }
            else{
                window.plugins?.speechRecognition?.isRecognitionAvailable(
                    (result) => {this.checkPermission(result)}, 
                    (error) => {console.log('error speech module', error) }
                )
            }
        } 
        catch(error){this.setState({error : 'no window plugin for speech function', bubbleActive: true})}
    }
    componentWillUnmount(){
        this._isMounted = false;
        if(this.state.isRecognitionAvailable){
            this.stopListeningNow();
        }
        if(process?.env?.REACT_APP_DEVICE_PLATFORM==="BROWSER"){
            //console.log('browserSupportsSpeechRecognition aboart')
            try{
                _stopListeningNow()
                _listeningActive = false;
            }
            catch(error){}
        }
    }
    componentDidUpdate(prevProps){
        if(!(this.props.existingText?.length > 0) && prevProps?.existingText?.length > 0){
            //console.log('did update prev text', this.props.existingText, prevProps?.existingText)
            this.setState({resetText: true})
        }
        else if(this.state.resetText === true){
            //console.log('did update prev text', this.props.existingText, prevProps?.existingText)
            this.setState({resetText: false})
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        // Shallow comparison of props and state
        if(ObjectsAreIdentical(this.props, nextProps) && ObjectsAreIdentical(this.state, nextState)){
            //console.log('this.props.data !== nextProps.data', this.props, nextProps)
            return false
        }
        else{
            //console.log('different props', this.props, nextProps, this.state, nextState)
            return true
        }
    }

    checkPermission = (result) => {
        try{
            window.plugins.speechRecognition.hasPermission(
                (permission) => {
                    if(permission) this.setState({isRecognitionAvailable:true, isPermissionGiven:true});
                    else this.setState({isRecognitionAvailable:true, isPermissionGiven:false});
                    if(this.props.confirmRecognitionAvailable !== undefined){
                        this.props.confirmRecognitionAvailable(true);
                    }
                },
                (error) => { console.log('error with permission check', error) }
            )
        } 
        catch(error){this.setState({error : 'cannot check permission for speech function', bubbleActive: true})}
    }

    requestPermission = () => {
        try{
            window.plugins.speechRecognition.requestPermission(
                () => {
                    this.setState({isPermissionGiven:true});  
                    this.startListening()},
                (error) => {console.log('failed permission request', error) }
            )
        } 
        catch(error){this.setState({error : 'cannot get permission for speech function', bubbleActive: true})}
    }

    render() {
        const { isRecognitionAvailable, isPermissionGiven} = this.state;
        const { theme } = this.props;
        //console.log('this.props speechto text', this.props )
        if(process?.env?.REACT_APP_DEVICE_PLATFORM==="BROWSER"){
            return(
                <Dictaphone 
                    existingText={this.props.existingText || ""}
                    renderKey={this.props.renderKey || undefined}
                    onRecognizeText={(textDetected, renderKey) => {
                        //console.log('transcript', textDetected, renderKey)
                        if((renderKey?.length > 1 && renderKey===this.props.renderKey) || renderKey===undefined || renderKey ===""){
                            if(this.props.returnText !== undefined){
                                this.props.returnText(textDetected);
                            }
                        }
                    }}
                    resetText={this.state.resetText === true ? true : false}
                    size={this.props.size || 'whatever'}
                    user={this.context}
                />
            )
        }
        else if(this.state.bubbleActive && this.props.isNonFocus) {
            if(isRecognitionAvailable && !isPermissionGiven ){
                return(
                    <IconButton
                        edge={this.props.size === 'large' ? false : "start"}
                        aria-label="Send message"
                        onMouseDown={() => this.requestPermission()}
                        onTouchStart={() => this.requestPermission()}
                    >
                        <MicOffIcon 
                            size={this.props.size === 'large' ? "large" : "medium"}
                            style={{  color: this.state.listeningActive ? 'green' : '#e53935' }}  
                        />
                    </IconButton>
                )
            }
            else if (isRecognitionAvailable) {
                return(
                    <IconButton
                        edge={this.props.size === 'large' ? false : "start"}
                        aria-label="Send message"
                        onMouseDown={() => this.startListening()} 
                        onTouchStart={() => this.startListening()} 
                    >
                        <MicIcon 
                            style={{ color: '#E53935' }} 
                            size={this.props.size === 'large' ? "large" : "medium"}
                        />
                    </IconButton>
                )
            }
            else{
                return(
                    <IconButton
                        edge={this.props.size === 'large' ? false : "start"}
                        aria-label="Send message"
                        onTouchStart={() => {
                            this.setState({bubbleActive : false})
                            this.props.setFocusHandler()
                        }}
                        onMouseDown={() => {
                            this.setState({bubbleActive : false})
                            this.props.setFocusHandler()
                        }}
                    >
                        <SmsIcon 
                            size={this.props.size === 'large' ? "large" : "medium"}
                            style={{ color: '#e53935' }} 
                        />
                    </IconButton>
                    //<img src={bubbleAnimated} style={{ width:32, height:32 }} alt="Add your comment" />
                )
            }
                
        }
        else if(isRecognitionAvailable && isPermissionGiven){
            return(
                <>
                    <IconButton
                        edge={this.props.size === 'large' ? false : "start"}
                        style={{backgroundColor: this.state.listeningActive ? theme.palette.themeRed : 'transparent'}}
                        aria-label="Record Voice"
                        onMouseDown={() => {
                            this.state.listeningActive 
                                ?   void(0)
                                :   this.startListening()
                        }}
                        onTouchStart={() => {
                            //console.log('keydonw')
                            this.state.listeningActive 
                                ?   void(0)
                                :   this.startListening()
                        }}
                        onTouchEnd={() => { this.wrapUpListening();}}
                        onTouchCancel={() => {this.wrapUpListening();}}
                        onTouchMove={() => {this.wrapUpListening();}}
                        onMouseUp={() => {this.wrapUpListening();}}
                    >
                        {this.state.listeningActive 
                            ?
                                <HearingIcon 
                                    size={this.props.size === 'large' ? "large" : "medium"}
                                    style={{ 
                                        color:  'white',
                                        animation: 'pulse 2s infinite ease-in-out'
                                    }} 
                                />
                            :
                                <MicIcon 
                                    size={this.props.size === 'large' ? "large" : "medium"}
                                    style={{ color: 'grey' }} 
                                />
                        }
                    </IconButton>
                    {this.props.existingText?.length > 1 && this.props.size !== 'large'
                    &&
                        <IconButton
                            aria-label="Reset text app"
                            onClick={() => {
                                try{
                                    if(this.props.returnText!== undefined){
                                        this.props.returnText("");
                                    }
                                    this.stopListeningNow();
                                }
                                catch(e){

                                }
                            }}
                        >
                            <ClearIcon 
                                size={'small'}
                            />
                        </IconButton>
                    }
                </>
            )
        }
        else if (isRecognitionAvailable && !isPermissionGiven) {
            return(
                <IconButton
                    edge={this.props.size === 'large' ? false : "start"}
                    aria-label="Record Voice"
                    onTouchStart={() => this.requestPermission()} 
                    >
                    <MicOffIcon 
                        size={this.props.size === 'large' ? "large" : "medium"}
                        style={{ color: 'grey' }} 
                    />
                </IconButton>
            )
        }
        else {
            return(
                <div></div>
            )
        }
    }

    startListening = () => {
        this.setState({buttonReleased: false });
        let options = {
            language : this.context?.features?.language?.length > 2 ? this.context.features.language : 'en-US',
            showPartial : true,
            showPopup: false
        }
        if(this.wrapId !== undefined && this.wrapId!== null){
            clearTimeout(this.wrapId);
        }
        this.stopListeningNow();
        this.setState({ listeningActive: true, bubbleActive:false});
        if(this.props.returnText !== undefined && this.props.returnText !== null){
            // do not need to verwrite
            //this.props.returnText(this.props.existingText || "");
            this.setState({ listeningActive: true, recordPressed: true });
            try{
                var textTemp = (this.props.existingText || '').trim();
                window.plugins.speechRecognition.startListening(
                    (textArray) => {
                        if(this._isMounted){
                            var textDetected = textArray[0];
                            //console.log('arraytempText', textDetected, textTemp); 
                            if(textTemp?.length> 0 && (textDetected === '' || textDetected === undefined)){
                                textDetected = textTemp;
                            }
                            else if(textTemp?.length> 0 && textTemp !== textDetected && !textDetected.includes(textTemp)){
                                var extensionText = textDetected.charAt(0).toLowerCase() + textDetected.slice(1);
                                textDetected = textTemp + ' ' + extensionText;
                            }
                            if(this.props.returnText!== undefined){
                                this.props.returnText(textDetected);
                            }
                            if(this.buttonReleased !== false){
                                this.counterDown(4500);
                            }
                        }
                    },
                    (error) => { 
                        console.log('error with recognition', error);
                        //this.setState({listeningActive: false, bubbleActive: false });
                        this.counterDown(1500);
                    },
                    options);
            } 
            catch(error){
                this.props.returnText('Sorry, cannot access microphone!');
                this.setState({listeningActive: false, bubbleActive: false });
                this.counterDown(300);
            }
        }
    }
    counterDown = (milliseconds=300) => {
        if(window.device?.platform === 'iOS' && this.timerId !== undefined && this.timerId!== null){
            clearTimeout(this.timerId);
            //console.log('timeout')
        }
        //var delayInMilliseconds = counter; //1 second
        this.timerId = setTimeout( () => {
            if(window.device?.platform === 'iOS'){
                this.stopListening();
            }
            this.setState({listeningActive: false, bubbleActive: false });
        }, milliseconds);
    }
    wrapUpListening =() => {
        this.setState({buttonReleased: true });
    }
    stopListening = () => {
        if(window.device?.platform === 'iOS' && this.state.listeningActive){
            try{
                window.plugins.speechRecognition.stopListening(
                    () => { this.setState({ listeningActive: false, timeLeft: 2000, bubbleActive:false }) },
                    (error) => {console.log('failed stop listening', error) }
                )
            }
            catch(error){console.log('failed stop listening', error) }
        }
    }
    stopListeningNow = () => {
        if(window.device?.platform === 'iOS' && this.state.listeningActive){
            try{
                window.plugins.speechRecognition.stopListening(
                    () => { this.setState({ listeningActive: false, timeLeft: 0 , bubbleActive:false}) },
                    (error) => {console.log('failed stop listening', error) }
                )
            }
            catch(error){console.log('failed stop listening', error) }
        }
    }
}

export default compose(
    withStyles(styles, { withTheme: true })
)(SpeechToTextButton);