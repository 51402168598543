//import palette from '../palette';

function muiStyle (palette) { return {
    root: {
        color: palette.themeAltGold,
        height: 9,
    },
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: palette.secondary.main,
        border: '3px solid '+palette.secondary.main,
        marginTop: -6,
        marginLeft: -12,
        '&:focus,&:hover,&$active': {
            boxShadow: 'inherit',
        },
    },
    mark:{
        backgroundColor: palette.secondary.main,
        height: '20%',
    },
    active: {},
    track: {
        backgroundColor: palette.themeAltGold,
        opacity: 0.7,
        height: 9,
        borderRadius: 10,
    },
    rail: {
        height: 9,
        borderRadius: 10,
    }
}}

export default muiStyle;