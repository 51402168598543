import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import { FindTextValueInDict } from '../Modules/LoadCategoriesAndQualities';
import withStyles from '@material-ui/core/styles/withStyles';
import {IsMobileDevice} from '../Modules/IsMobileDevice';
import { AddAnalytics } from '../Modules/AddAnalytics';

import imageVisionBackground from '../../images/satisfied5.png';

//// List lements
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Grid from '@material-ui/core/Grid';
//import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {RemoveScroll} from 'react-remove-scroll';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {ExtendDialogueMessage} from '../Routine/DiaryManual';

import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        backgroundColor: '#2d2c4c',
    },
});

class DialogChangeGoal extends Component {  
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            currentAchievementGoal: this.props.user?.achievementGoal || '',
            tempCurrentAchievementGoal: this.props.user?.achievementGoal || '',
            currentLeadershipQuality: '',
            goalSuggestions: [],
            loading: true,
        };
    }

    loadGoalSuggestions() {
        var goalSuggestions = [];
        var goalCounter = 0;
        if(this._isMounted) {
            this.setState({
                loading: true,
            });
        }
        var currentLeadershipQuality = this.props.user.currentLeadershipQuality;

        if(this.props.leadershipQualities !== undefined){
            try {
                var extraGoals = FindTextValueInDict(undefined, this.props.leadershipQualities, currentLeadershipQuality, 'goalSuggestions');
                if(extraGoals?.length >=1){
                    extraGoals.forEach(item => {
                        //console.log(item)
                        goalSuggestions.push({ text: item, gid:"goal_"+goalCounter });
                        goalCounter +=1;
                    })
                }
            }
            catch (error){
                //console.log('no extra goals', error)
                goalCounter = 0;
            }
        } 

        //console.log(currentLeadershipQuality, this.props.leadershipQualities)
        if (goalCounter <=0 ){
            this.generalGoals = this.props.firebase
            .onboardingContents()
            .where('category', '==', 'goalSuggestions')
            .where('active', '==', true)
            .get()
            .then(snapshot => {
                if (snapshot.empty) {
                    if (goalCounter <=0 ) goalSuggestions.push({ text: "Regularly reflect and being mindful", gid:"default" });
                    return;
                }
                snapshot.forEach(doc => {                
                    if (goalCounter <=3 ) goalSuggestions.push({ ...doc.data(), gid: doc.id });
                    goalCounter +=1;
                });
                if(this._isMounted) {
                    this.setState({ 
                        goalSuggestions: goalSuggestions ,
                        currentLeadershipQuality,
                        loading: false
                    });
                }
                //console.log('goals', this.state.goalSuggestions)
            });
        }
        else if(this._isMounted) {
            this.setState({ 
                goalSuggestions: goalSuggestions ,
                currentLeadershipQuality,
                loading: false
            });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadGoalSuggestions();
    }

    componentDidUpdate(){
        if(this.state.loading === false
            && this.state.currentLeadershipQuality !==  this.props.user.currentLeadershipQuality){
            this.loadGoalSuggestions();
            //console.log('uopdate')
        }
    }

    
    componentWillUnmount(){
        this._isMounted =false;
        //this.unsubscribe();
    }

    handleCurrentAchievementGoalChange = (event) => { 
        if(event.target.value !== undefined && event.target.value.length <= 160){
            this.setState({
                currentAchievementGoal: event.target.value,
                tempCurrentAchievementGoal: event.target.value
            });
        }
        else{
            var value = event.target.value;
            value = value.substring(0,156) + "...";
            this.setState({
                currentAchievementGoal: value,
                tempCurrentAchievementGoal: value
            });
        }
    }
    handleCurrentAchievementGoalSuggestion = (text) =>{
        //console.log('text', text, this.state.tempCurrentAchievementGoal )
        if(this.props.openCurrentAchievementGoal){
            //var oldText = this.state.tempCurrentAchievementGoal || this.state.currentAchievementGoal;
            //var newText = (oldText + ' And ' + text);
            var newText = text;
            if (newText.length > 160){
                newText = newText.substring(0,156) + "...";
            }
            if(this.dialogRef!==undefined && this.dialogRef!==null && this.dialogRef.scrollTop!==undefined ) {
                //this.dialogRef.scrollTop = 0;
                this.dialogRef.scrollIntoView({ behavior: "smooth" });
            }
            this.setState({
                currentAchievementGoal: newText,
            }, 
                () => {
                    if(false && this.props.type === 'quick'){
                        this.handleCurrentAchievementGoalSubmit()
                    }
                }
            );
        }     
    }

    handleClose = () => {
        this.setState ({
            currentAchievementGoal: this.props.user?.achievementGoal || ''
        });
        this.props.handleCurrentAchievementGoalClose();
    }

    handleCurrentAchievementGoalSubmit = () => {
        // this.setState({ saveInitData: false });
        if(this.state.currentAchievementGoal!==undefined && this.state.currentAchievementGoal.length>10 && this.state.currentAchievementGoal !== this.context.currentAchievementGoal){
            this.props.firebase.user(this.context.uid).update({
                achievementGoal: this.state.currentAchievementGoal
            });
            this.props.firebase.userCollection(this.context.uid, 'topics').add({
                achievementGoal: this.state.currentAchievementGoal,
                currentLeadershipQuality: this.props.user.currentLeadershipQuality,
                createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                origin : 'goalchangedialogue'
            });
            var d = new Date();    
            var date = new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString().slice(0,10);
            var category = 'goals';
            var key = 'achievementGoal';
            var updateSet = {
                createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                date: date,
                category: category,
                question: 'New personal vision:',
                comment: this.state.currentAchievementGoal,
                key: key,
                source: 'self',
                userId: this.context.uid
            }
            //console.log('comment', comment, key, date, this.props.user.uid)
            this.props.firebase.insightAssessment(this.context.uid, date+'_'+category+'_'+key).set( updateSet , {merge:true});
            ExtendDialogueMessage(this.props.firebase, this.context,  this.state.currentAchievementGoal,  "What is your current north star goal?", 'utter_ask_expectations', '', 'profile');
            AddAnalytics('goal_completion', 'GoalChangeVision');
        }
        //this.props.handleCurrentAchievementGoalClose();
    }

    showGoalSuggestion = () => {
        return (
            <List >
                { this.state.goalSuggestions.map( (g) => {
                    //console.log('adding', g.gid);
                    // to not call instead of passing the fuinction in render
                    // https://stackoverflow.com/questions/33846682/react-onclick-function-fires-on-render
                    return(
                        <ListItem key={g.gid} 
                            onClick={() => {this.handleCurrentAchievementGoalSuggestion(g.text)}}
                            style={{paddingLeft:0, paddingRight:0}}>
                            <ListItemIcon style={{marginRight:10, minWidth:'auto',  color:"#e53935"}}>
                                <AddCircleIcon /> 
                            </ListItemIcon>
                            <ListItemText>
                                {g.text}
                            </ListItemText>
                        </ListItem>
                    )                    
                })}
            </List>
        )
    }

    render(){
        const { theme } = this.props;
        return(
        <RemoveScroll enabled={this.props.openCurrentAchievementGoal || false}> 
            <Dialog
                open={this.props.openCurrentAchievementGoal || false}
                onClose={() => this.handleClose()}
                fullScreen={IsMobileDevice()}
                PaperProps={{style:theme.dialogWindow}}
                TransitionComponent={Transition}
                maxWidth="sm"
                >
                <DialogTitle style={theme.dialogTitle} >
                    <Grid container spacing={0} direction="row"
                        justifyContent="space-between"
                        alignItems="center">
                        <Grid item >
                            <IconButton onClick={() => this.handleClose()}>
                                <ArrowBackIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <span style={theme.dialogTitle.titleText}>
                            Vision Statement
                            </span>
                        </Grid>
                        <Grid item>
                            <span style={theme.dialogTitle.titleLink} onClick={() => this.handleClose()}>
                                Close
                            </span>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent style={theme.dialogContent}>
                    {this.props.openCurrentAchievementGoal &&
                        <div ref={el => this.dialogRef = el}>
                            <p style={theme.textSupportCenterPadding}>Your <strong>vision</strong> should serve as a <strong>north star</strong> for your professional or personal life:</p>
                            <form >
                                <TextField
                                    //autoFocus
                                    id="achievementgoal"
                                    //label="Vision statement"
                                    onChange={ this.handleCurrentAchievementGoalChange}
                                    multiline
                                    InputProps={{
                                        style:{fontSize: '16px'}
                                    }}
                                    style={theme.textInputMultiline}
                                    value={this.state.currentAchievementGoal}
                                    variant='outlined' 
                                />
                            </form>
                            <div style={{width: '100%', textAlign: 'right'}}>
                                {this.state.currentAchievementGoal !== this.props.user?.achievementGoal
                                    &&
                                    <>
                                        <Button 
                                            variant='contained'
                                            color='primary'
                                            onClick={
                                            () => this.setState({
                                                currentAchievementGoal: this.props.user?.achievementGoal || '',
                                                tempCurrentAchievementGoal: this.props.user?.achievementGoal || '',
                                            })
                                        }>
                                            Cancel
                                        </Button>
                                        &nbsp;&nbsp;
                                        <Button 
                                            //variant='contained' 
                                            onClick={this.handleCurrentAchievementGoalSubmit}>
                                            Save
                                        </Button>
                                    </>
                                }
                            </div>
                            <p>Checklist:</p>
                            <p>
                                <strong>Visionary</strong>
                                &nbsp;–  Set a goal so big, so lofty, that everyday you need to spend some time working towards achieving that goal.
                            </p>
                            <p>
                                <strong>Aspirational</strong>
                                &nbsp;–  Think long-term and high-level. Your vision should motivate, inspire, and uplift yourself.
                            </p>
                            <p>
                                <strong>North Star</strong>
                                &nbsp;–  Like a captain uses the north star to navigate, your vision should help you to stay on course, no matter where you are.
                            </p>
                            <p>
                                <strong>Be intentional</strong>
                                &nbsp;– Set a vision that is aligned to your dreams and authentic to you, then mastery and accomplishment will soon be yours.
                            </p>
                            {/***
                            <p>
                                <strong>Use a personal strength </strong>
                                &nbsp;– Choose one strength you’re most proud of or would most like to work on. Use that strength in a new way.
                            </p>
                            <p>
                                <strong>Quality not quantity </strong>
                                &nbsp;– Remember it’s not the number of goals set, but a harmonious balance of our performance, energy, and use of a strength to consider when setting a goal. If your strengths are not of benefit towards achieving the goal, then really consider if it’s something you want to do.
                            </p>
                            <p>
                                <strong>Be aware </strong>
                                &nbsp;– Acknowledge any weaknesses and learned behaviours that could neutralize accomplishment.
                            </p>
                             */}
                            <p>Aspirations:</p>
                            { this.state.goalSuggestions.length > 0
                                ?   this.showGoalSuggestion() 
                                :   <div></div> 
                            }
                        </div>
                    }
                </DialogContent>
                <DialogActions style={{
                    ...theme.dialogAction,
                    background: `url(${imageVisionBackground})`,
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    minHeight: 50 
                    }}>
                    &nbsp;
                </DialogActions>
            </Dialog>
        </RemoveScroll>  
        )// end return
        
    }//end render

};//end class

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(DialogChangeGoal);

//  module.exports.handleCurrentAchievementGoalOpen = DialogChangeGoal.handleCurrentAchievementGoalOpen();
