import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';

import Card from '@material-ui/core/Card';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
//modules
import {ConvertTimestampToDateString} from '../Modules/ConvertTimestampToDateString';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});

class ShowCurrentSubscription extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: ''
        };
    }

    render() {
        const {user, theme} = this.props;
        if(user!== undefined && user.purchasedProduct!==undefined){
            return(
                <div style={{textAlign: 'left'}}>
                    <p>Current Plan:</p>
                    {((user?.roles?.includes("ADMIN") || user?.roles?.includes("COACHADMIN") || user?.roles?.includes("REMOTECOACH")) || user?.purchasedProduct?.title?.length > 2)
                    &&
                        <Card style={theme.CardPlanBorder}>
                            <p><strong>{user.purchasedProduct.title}</strong></p>
                            <p>{user.purchasedProduct.description}</p>
                            <div style={{textAlign: 'left'}}>
                                {(user?.roles?.includes("ADMIN") || user?.roles?.includes("COACHADMIN") || user?.roles?.includes("REMOTECOACH"))
                                &&  
                                    <>
                                        {user?.seatsMonthlyToGive > 0
                                        && 
                                            <p style={theme.textSettings}>
                                                <span style={theme.textSettings.settingsFixedTextValue}>{user?.seatsMonthlyToGive || 0}</span>
                                                <span style={theme.textSettings.settingsTextDescription}>Monthly Seats Available:</span>
                                            </p>
                                        }
                                        {user?.seatsToGive > 0
                                        && 
                                            <p style={theme.textSettings}>
                                                <span style={theme.textSettings.settingsFixedTextValue}>{user?.seatsToGive || 0}</span>
                                                <span style={theme.textSettings.settingsTextDescription}>Extra Seats Available (Not Time-Bound):</span>
                                            </p>
                                        }
                                        {!(user?.seatsToGive > 0 && user?.seatsMonthlyToGive > 0)
                                        && 
                                            <p style={theme.textSettings}>
                                                <span style={theme.textSettings.settingsFixedTextValue}>{(user?.seatsToGive || 0 ) + (user?.seatsMonthlyToGive || 0)}</span>
                                                <span style={theme.textSettings.settingsTextDescription}>Seats Available:</span>
                                            </p>
                                        }
                                    </>
                                }
                            </div>
                        </Card>
                    }
                    <p>State:&nbsp;
                        <span style={{ float: 'right'}}>
                            {user.purchasedProduct.owned && user.purchasedProduct.state === 'active'
                                ?  <strong><CheckCircleIcon style={{ ...theme.inlineIconLeft , color: 'green'}} /> &nbsp; {user.purchasedProduct.owned? 'Subscribed' : 'Inactive'}</strong>
                                :  <strong><WarningIcon style={{ ...theme.inlineIconLeft , color:theme.palette.themeRed}}/> &nbsp; {user.purchasedProduct.owned? 'Subscribed' : 'Inactive'}</strong>
                            }
                        </span>
                    </p>
                    {user.purchasedProduct.owned && user.purchasedProduct !== undefined 
                    &&
                        <p>Product:&nbsp;
                            <span style={{ float: 'right'}}>
                                <strong>
                                    {user.purchasedProduct.premium
                                        ?   "Premium"
                                        :   "Essentials"
                                    }
                                </strong>
                            </span>
                        </p>
                    }
                    {(user.purchasedProduct.startDate!==undefined || user.purchasedProduct.purchaseDate!==undefined)
                    &&
                        <p>Start Date: &nbsp;
                            <span style={{ float: 'right'}}>
                                {user.purchasedProduct.startDate!==undefined
                                    ?   <strong>{ConvertTimestampToDateString(user.purchasedProduct.startDate, true)}</strong>
                                    :   user.purchasedProduct.purchaseDate!==undefined
                                        ?   <strong>{ConvertTimestampToDateString(user.purchasedProduct.purchaseDate, true)}</strong>
                                        :   "-"
                                }
                            </span>
                        </p>
                    }
                    {user.purchasedProduct.expiryDate !== undefined
                        ?
                            <p>Expiration Date:&nbsp;
                                <span style={{ float: 'right'}}>
                                    {user.purchasedProduct.expiryDate!==undefined
                                        ? <strong>{ConvertTimestampToDateString(user.purchasedProduct.expiryDate, true)}</strong>
                                        : "-"
                                    }
                                </span>
                            </p>
                        :   this.props.coach !== true && user.purchasedProduct?.owned !== true
                            ?
                                <p>Available Chats:&nbsp;
                                    <span style={{ float: 'right'}}>
                                        {user.freeDialogues!==undefined
                                            ? <strong>{user.freeDialogues - user.dialogueCounter }</strong>
                                            : "-"
                                        }
                                    </span>
                                </p>
                            :   ""
                    }
                    {user.purchasedProduct.quantity!== undefined && user.purchasedProduct.quantity > 0 &&
                        <p>Months/Items: &nbsp;
                            <span style={{ float: 'right'}}>
                                <strong>{user.purchasedProduct?.quantityTotal? (user.purchasedProduct.quantityTotal +'/') : ''}{user.purchasedProduct.quantity || '0'} </strong>
                            </span> 
                        </p>
                    }
                    {user.purchasedProduct.type && user.purchasedProduct.type !=='' &&
                        <p>Type: &nbsp;
                            <span style={{ float: 'right'}}>
                                {user.purchasedProduct.type}
                            </span> 
                        </p>
                    }
                </div>
            )
        }
        else{
            return (
                <div>
                    No subscription history
                </div>
            );
        }
    }
}

export default compose(
    withStyles(styles, { withTheme: true }),
)(ShowCurrentSubscription);
