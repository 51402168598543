import React, { Component} from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { AddAnalytics } from '../Modules/AddAnalytics';

import { QualityID } from '../Modules/LoadCategoriesAndQualities';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import {CheckXPLevel} from '../Charts/XPLevels';
import SnackbarInfo from '../Modules/SnackbarInfo';

import DialogFlagError from '../Modules/DialogFlagError';


//expansion
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// used for the ThumbUps
import Grid from '@material-ui/core/Grid';
//import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import FavoriteIcon from '@material-ui/icons/Favorite';
import EditIcon from '@material-ui/icons/Edit';
import AddCommentIcon from '@material-ui/icons/AddComment';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PeopleIcon from '@material-ui/icons/People';
import SendIcon from '@material-ui/icons/Send';

import {ExtendDialogueMessage} from '../Routine/DiaryManual';

import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
//import CancelIcon from '@material-ui/icons/Cancel';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import DeleteIcon from '@material-ui/icons/Delete';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import InputAdornment from '@material-ui/core/InputAdornment';

import SpeechToTextButton from '../Modules/SpeechToTextButton';

import TextField from "@material-ui/core/TextField";


const styles = theme => ({
    iconSelected: {
        color: theme.palette.secondary.main,
    },
      iconNotSelected: {
          color: 'grey',
    },
});

class ContentComments extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        //console.log('initia;', this.props)
        var myComment = '';
        var editComment = false;
        var editCommentVisible = false;
        var myCommentsBack = [];
        var myCommentVotes = 0;
        var userEngagement = this.props.userEngagement;
        
        if (userEngagement.comment !== undefined && userEngagement.comment !== '') {
            myComment = userEngagement.comment;
            if(userEngagement.commentVisible){
                editCommentVisible = userEngagement.commentVisible
            }
        }
        if (userEngagement.commentVotes !== undefined && userEngagement.commentVotes > 0) {
            myCommentVotes = userEngagement.commentVotes;
        }
        if (userEngagement.commentsBack!== undefined && typeof userEngagement.commentsBack.isArray ) {
            myCommentsBack = userEngagement.commentsBack;
        }
        if( myComment==='') {editComment = true};
        

        this.state = {
            open: false,
            commentHide : "none",
            editComment : editComment,
            commentVisible : editCommentVisible,
            commentsBack :  myCommentsBack,
            commentVotes : myCommentVotes,
            contentId: this.props.contentId,
            comment: myComment,
            oldComment: myComment,
            otherComments:[],
            userVotedFlag: [],
            userId: this.props.userId,
            xpLevels: {}
        };
    }

    componentDidMount() {
        this._isMounted = true;
        const xpLevels = {
            social: CheckXPLevel(this.context, 'social'),
        }
        this.setState({isMounted : true, xpLevels});
        this.getCommentsFromOthers(this.props.sorting || 'createdAt');
        this.removeNewMessages();
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState({isMounted : false, commentInWork:''});
        if(this.unsubscribeComments !== undefined) this.unsubscribeComments();
    }
    componentDidUpdate(prevProps) {
        //console.log('props', prevProps, this.props)
        if(prevProps!==undefined && prevProps.userEngagement !== undefined && prevProps.userEngagement !== this.props.userEngagement ){
            if(this.props.userEngagement !== undefined && this.props.userEngagement.comment !== undefined){
                //console.log('different props')
                var myComment = '';
                var editComment = false;
                var editCommentVisible = false;
                var myCommentsBack = [];
                var myCommentVotes = 0;
                var userEngagement = this.props.userEngagement;
                if (userEngagement.comment !== undefined && userEngagement.comment !== '') {
                    myComment = userEngagement.comment;
                    if(userEngagement.commentVisible){
                        editCommentVisible = userEngagement.commentVisible
                    }
                }
                if (userEngagement.commentVotes !== undefined && userEngagement.commentVotes > 0) {
                    myCommentVotes = userEngagement.commentVotes;
                }
                if (userEngagement.commentsBack!== undefined && typeof userEngagement.commentsBack.isArray ) {
                    myCommentsBack = userEngagement.commentsBack;
                }
                if( myComment==='') {editComment = true};
                this.setState ( {
                    editComment : editComment,
                    commentVisible : editCommentVisible,
                    commentsBack :  myCommentsBack,
                    commentVotes : myCommentVotes,
                    comment: myComment,
                    oldComment: myComment,
                });
            }
        }
    }

    removeNewMessages(){
        //var messageDict = { }
        if(this.context.newMessages!== undefined && this.props.contentId!==undefined ){
            try{ 
                //messageDict = this.context.newMessages.find(q => (q.contentId === this.props.contentId ));
                //console.log('messagrease',messageDict)
                //this.props.firebase.user(this.context.uid).update({newMessages : this.props.firebase.fieldValue.arrayRemove(messageDict)})
                var messageList = this.context.newMessages.filter(q => (q.contentId === this.props.contentId ));
                //if(messageList!==undefined && messageList!== null && messageList.length>0){ 
                    //console.log('messagrease',messageList)
                    //this.props.firebase.user(this.context.uid).update({newMessages : this.props.firebase.fieldValue.arrayRemove(messageList)})
                //}
                messageList.forEach((item) => {
                    this.props.firebase.user(this.context.uid).update({newMessages : this.props.firebase.fieldValue.arrayRemove(item)})
                })

            }
            catch (error) {
                //messageDict = {}
            }
        }
    }

    render() {
        const { theme } = this.props;
        const {contentData, userEngagement} = this.props;
        var contentUsersBlocked = this.context?.contentUsersBlocked || [];
        //console.log('contentUsersBlocked', contentUsersBlocked)
        return (
        contentData !== undefined && userEngagement !== undefined 
        ?
            <div>
                <div style={theme.divCenterPadding}>
                    <span style={theme.CardContentItem.TextSubTitle}>{contentData.title} </span>
                </div>
                {this.state.xpLevels?.social?.valid !== true
                    &&
                    <SnackbarInfo 
                        alertText = {this.state.alertText || 'Try to collect more XP.'}
                        openOnClick = {true}
                        alertOpenEvent = {this.state.alertOpenEvent || false}
                        closeHandler = {() => this.setState({alertOpenEvent: false})}
                    />
                }
                <div>
                    {this.state.editComment===true
                        ?
                            <Grid container justifyContent="flex-end"  spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        name="comment"
                                        style={ {fontSize: '16px'} }
                                        //label="What's on your mind?"
                                        value={this.state.comment}
                                        onChange={
                                            this.state.xpLevels?.social?.valid === true
                                            ?   (e) => {this.onChangeComment(e)}
                                            :   () => this.setState({alertOpenEvent: true, alertText: this.state.xpLevels?.social?.text})
                                        }
                                        type="text"
                                        variant="outlined"
                                        placeholder="Share what's on your mind with other people."
                                        fullWidth
                                        multiline
                                        InputProps={{
                                            style:{fontSize: '16px'},
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SpeechToTextButton 
                                                        renderKey={'comments'}
                                                        returnText={(result) => this.setState({comment : result})}
                                                        existingText={this.state.comment || ""}
                                                        />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="Send message"
                                                        disabled={(this.state.comment !== undefined && this.state.comment.length>9) ? false : true  }
                                                        onClick={
                                                            this.state.xpLevels?.social?.valid === true
                                                                ?   () => {this.saveContentComment(this.state.contentId)}
                                                                :   () => this.setState({alertOpenEvent: true, alertText: this.state.xpLevels?.social?.text})
                                                            }
                                                        > 
                                                        <SendIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        :
                            <Grid container alignItems="flex-start">
                                <Grid item xs={2}>
                                    <AccountCircleIcon style={{ color: theme.palette.primary.dark, fontSize: 30  }} />
                                </Grid>
                                <Grid item xs={8}>
                                    <em> Me </em>
                                </Grid>
                                <Grid item xs={10}>
                                    <q>{this.state.comment }</q>
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton onClick={() => {this.editContentComment(this.state.contentId)}}> 
                                        <EditIcon style={styles.iconNotSelected} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                    }
                    {this.state.comment.length > 20 &&
                            <Grid container alignItems="flex-start" style={{marginTop: 20}}>
                                {this.state.commentVisible===true 
                                ? 
                                    <Grid container alignItems="center" direction="row">
                                        <Grid item xs={2}  onClick={() => {this.visibleContentCommentOff(this.state.contentId)}}>
                                            <VisibilityIcon style={styles.iconSelected}/>
                                        </Grid>
                                        <Grid item xs={5}>
                                            Username visible
                                        </Grid>
                                        <Grid item xs={5}>
                                            <strong> Upvotes - {this.state.commentVotes}</strong>
                                        </Grid>
                                    </Grid>
                                :
                                    <Grid container alignItems="center" direction="row">
                                        <Grid item xs={2} onClick={() => {this.visibleContentCommentOn(this.state.contentId)}}>
                                            <VisibilityOffIcon style={styles.iconSelected}/>
                                        </Grid>
                                        <Grid item xs={5}>
                                            Name is hidden
                                        </Grid>
                                        <Grid item xs={5}>
                                            <strong> Upvotes - {this.state.commentVotes}</strong>
                                        </Grid>
                                    </Grid>
                                }
                                {userEngagement.commentsBack!== undefined && typeof userEngagement.commentsBack.isArray && 
                                    userEngagement.commentsBack.map((cb, index) => {
                                        if(cb?.userId?.length > 2 && !contentUsersBlocked.includes(cb.userId)){
                                            return(
                                                <Grid container  alignItems="center" key={"receivedcomments_"+index}>
                                                    <Grid item xs={2} >
                                                        <SubdirectoryArrowRightIcon />
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {cb.userId === this.context.uid 
                                                        ?  <strong style={{color:'red'}}>Me</strong>
                                                        :  cb.userName !== undefined && cb.userName !== 'Padawan'
                                                            ?   <em><strong>{cb.userName.split(" ")[0]}</strong></em>
                                                            :   <em>User</em>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        {cb.userId === this.context.uid &&
                                                            <IconButton onClick={() => {this.removeContentCommentBack(this.state.contentId, this.context.uid, cb)}}> 
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={2} >
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <q style={{color:theme.palette.secondary.light}}>{cb.comment}</q> 
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                        else return ""
                                    })
                                }
                                {userEngagement.commentsBack!== undefined && typeof userEngagement.commentsBack.isArray && userEngagement.commentsBack.length > 0 && 
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item xs={2}>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <TextField
                                                name="commentback"
                                                style={ {fontSize: '16px'} }
                                                //label="Your comment"
                                                type="text"
                                                variant="outlined"
                                                placeholder="Add a comment..."
                                                fullWidth
                                                multiline
                                                InputProps={{
                                                    style:{fontSize: '16px'},
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="Send message"
                                                                onClick={() => {this.saveContentCommentBack(this.state.contentId, this.context.uid, this.state["commentback_"+this.context.uid])}}
                                                                >
                                                                <SendIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                value={this.state["commentback_"+this.context.uid]}
                                                //onChange={(e) => {this.setState({ ["commentback_"+this.context.uid] : e.target.value})}}
                                                onChange={(e) => {this.onChangeCommentBack(e, ("commentback_"+this.context.uid) )}}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        
                    }
                    <div style={theme.divSpaceVertical}></div>
                    {this.renderOtherComments(contentUsersBlocked)}
                </div>
            </div>
        :
            ''
        )// end return
    }
    renderOtherComments = (contentUsersBlocked = []) => {
        const {theme} = this.props;
        return(
            <div> 
                {this.state.otherComments.length>0 &&
                    <div >
                        <Divider/>
                    </div>
                }
                {this.state.otherComments.length>0 
                    && this.state.otherComments.map( (c, indexC) => {
                        if(c?.userId?.length > 2 && !contentUsersBlocked.includes(c.userId)){
                            return(
                                <Card style={{...theme.cardCommentsOthers, paddingLeft:0, paddingRight:0}} key={c.userId}>
                                    <Grid container alignItems="center" spacing={0}  style={{width: '100%'}}>
                                        <Grid item xs={2}>
                                            <PeopleIcon style={{ color: theme.palette.primary.dark, fontSize: 30  }}/>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <em><strong>{c.userName.split(" ")[0]}</strong>&nbsp;commented</em>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <DialogFlagError
                                                title="Report issue with comment"
                                                issue={"Issue with comment - " + c.text}
                                                item={"Content comments - " + c.userName + " - " + c.userId + " - " + this.props.contentId}
                                                dict={c}
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{paddingLeft: 40}}>
                                            <p><q style={{color:theme.palette.secondary.light}}>
                                                {
                                                    (c.text !== undefined && c.text.length > 200 && !(this.state['show_'+indexC]))
                                                    ?   <span onClick={() => {this.setState({['show_'+indexC]: true})}}>
                                                            {c.text.substring(0,198)}...
                                                        </span> 
                                                    :   <span>{c.text}</span>
                                                }
                                            </q></p> 
                                        </Grid>
                                        <Grid item xs={1}>
                                        </Grid>
                                        <Grid item xs={1}>
                                            {this.state.userVotedFlag.includes(c.userId)
                                                ? <FavoriteIcon style={styles.iconSelected} fontSize="small" />
                                                : <FavoriteIcon style={styles.iconNotSelected} fontSize="small" />
                                            }
                                        </Grid>
                                        <Grid item xs={1}>
                                            {c.commentVotes} 
                                        </Grid>
                                        <Grid item xs={4}> 
                                            <Button disabled={this.state.userVotedFlag?.includes(c.userId) ? true : false}
                                                onClick={() => {this.upvoteCommentFromOthers(c.commentVotes, c.userId)}}
                                                >
                                                Upvote
                                            </Button>
                                        </Grid>
                                        <Grid item xs={4} style={{textAlign:'right'}}>
                                            {(this.state['showsubcomment_'+c.userId] === undefined || this.state['showsubcomment_'+c.userId] === false)
                                            &&
                                                <Button   
                                                    onClick={
                                                        this.state.xpLevels?.social?.valid === true
                                                            ?   () => {
                                                                    //// this is used if available to hide the bookmark section
                                                                    if(this.props.typingCommentFieldOpen !== undefined){
                                                                        this.props.typingCommentFieldOpen(true)
                                                                    }
                                                                    this.setState({['showsubcomment_'+c.userId] : true, ["commentback_"+c.userId] : ''})
                                                                }
                                                            :   () => this.setState({alertOpenEvent: true, alertText: this.state.xpLevels?.social?.text})
                                                    }
                                                >
                                                    Reply
                                                </Button>
                                            }
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={12}>
                                            {this.state['showsubcomment_'+c.userId] !== undefined && this.state['showsubcomment_'+c.userId] === true 
                                            &&
                                            <TextField
                                                    autoFocus={true}
                                                    name="commentback"
                                                    //label="Your comment"
                                                    style={ {fontSize: '16px', marginTop: 10, marginBottom: 10} }
                                                    type="text"
                                                    variant="outlined"
                                                    placeholder="Add a comment..."
                                                    fullWidth
                                                    multiline
                                                    InputProps={{
                                                        style:{fontSize: '16px'},
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    edge="end"
                                                                    aria-label="Send message"
                                                                    onClick={() => {
                                                                        this.saveContentCommentBack(this.state.contentId, c.userId, this.state["commentback_"+c.userId]);
                                                                        this.setState({['showsubcomment_'+c.userId] : false})
                                                                        }}
                                                                        >
                                                                    <SendIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    value={this.state["commentback_"+c.userId]}
                                                    //onChange={(e) => {this.setState({ ["commentback_"+c.userId] : e.target.value})}}
                                                    onChange={(e) => {this.onChangeCommentBack(e, ("commentback_"+c.userId) )}}
                                                />
                                            }
                                        </Grid>
                                        <br/>
                                        {c.commentsBack!== undefined && typeof c.commentsBack.isArray 
                                            &&
                                            <Grid item xs={12}>
                                                <Accordion 
                                                    style={{border: 0, width: '100%', boxShadow:'none', backgroundColor: "transparent"}} 
                                                    elevation={0}
                                                    TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
                                                    >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-label="Account-Expand"
                                                        aria-controls="Account-content"
                                                        id="Account-header"
                                                        style={{borderBottom:'none !important'}}
                                                    >
                                                        <strong style={{paddingLeft: 30}}>Show Replies ({c.commentsBack.length}) </strong>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container alignItems="center" spacing={0}  style={{width: '100%'}}>
                                                        {c.commentsBack.map((cb, index) => {
                                                            if(cb?.userId?.length > 2 && !contentUsersBlocked.includes(cb.userId)){
                                                                return(
                                                                    <React.Fragment key={"subcomments_"+index}>
                                                                        {
                                                                            //  <Grid container alignItems="center" key={"subcomments_"+index} style={{width: '100%'}}>
                                                                        }
                                                                        <Grid item xs={1}>
                                                                        </Grid>
                                                                        <Grid item xs={1} >
                                                                            <SubdirectoryArrowRightIcon/>
                                                                        </Grid>
                                                                        <Grid item xs={8}>
                                                                            {cb.userId === this.context.uid 
                                                                            ?  <strong style={{color:'red'}}>Me</strong>
                                                                            :  cb.userName !== undefined && cb.userName !== 'Padawan'
                                                                                ?   <em><strong>{cb.userName.split(" ")[0]}</strong></em>
                                                                                :   <em>User</em>
                                                                            }
                                                                        </Grid>
                                                                        <Grid item xs={2}>
                                                                            {cb.userId === this.context.uid 
                                                                                ?
                                                                                    <IconButton onClick={() => {this.removeContentCommentBack(this.state.contentId, c.userId, cb)}}> 
                                                                                        <DeleteIcon />
                                                                                    </IconButton>
                                                                                :
                                                                                    <DialogFlagError
                                                                                        title="Report issue with comment"
                                                                                        issue={"Issue with comment - " + cb.userName + ' - ' + cb.userId + " - " + cb.comment}
                                                                                        item={"Content comments - "+ this.props.contentId}
                                                                                        dict={cb}
                                                                                    />
                                                                            }
                                                                        </Grid>
                                                                        <Grid item xs={2} >
                                                                        </Grid>
                                                                        <Grid item xs={10}>
                                                                            <p><q style={{color:theme.palette.secondary.light}}>{cb.comment}</q></p> 
                                                                        </Grid>
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                            else return (
                                                                <React.Fragment key={"subblockcomments_"+index}>
                                                                    <Grid item xs={2} >
                                                                    </Grid>
                                                                    <Grid item xs={10}>
                                                                        <p><q style={{color:theme.palette.secondary.light}}>Blocked user</q></p> 
                                                                    </Grid>
                                                                </React.Fragment>
                                                            )
                                                        })}
                                                        </Grid>
                                                    </AccordionDetails> 
                                                </Accordion>
                                            </Grid>
                                        }
                                    </Grid>
                                </Card>  
                            )
                        }
                        else return ""             
                    })
                }
            </div> 
        )// end return
    }

    onChangeComment = event => {
        var text = event.target.value;
        //var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        var urlRegex = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
        text = text.replace(urlRegex, '*');
        this.setState({ comment: text, commentInWork: text});
    };
    onChangeCommentBack = (event, stateName) => {
        var text = event.target.value;
        var urlRegex = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
        text = text.replace(urlRegex, '*');
        //console.log('text', text)
        this.setState({ [stateName]: text});
    };
    saveContentComment = contentId => {
        if(this.state.comment !== ''){
            this.setState({editComment : false,  oldComment: this.state.comment, commentVisible:true});
            const timestamp = this.props.firebase.fieldValue.serverTimestamp();
            if(this.state.comment !== ''){
                this.props.firebase.contentBookmark( this.state.contentId, this.context.uid).set({
                    origin: 'resources',
                    createdAt: timestamp,
                    contentId: this.state.contentId,
                    userId: this.context.uid,
                    userName: this.context.username,
                    comment: this.state.comment,
                    leadership_quality: QualityID(this.props.contentData.leadership_quality),
                    leadership_category: this.props.contentData.leadership_category,
                    //commentVisible: this.state.commentVisible
                    commentVisible: true,
                    commentVotes: this.props.firebase.fieldValue.increment(1)
                }, {merge:true }); 
            }
            //this.props.firebase.content( this.state.contentId).set({
            //    commentVotes: this.props.firebase.fieldValue.increment(2)
            //}, {merge:true }); 
            if(this.state.commentVisible){
                this.addEngagementPoints(3,'social');
                this.props.firebase.content( this.state.contentId).set({
                    commentsCount: this.props.firebase.fieldValue.increment(1)
                }, {merge:true }); 
            }
            else{
                this.addEngagementPoints(2,'content');
            }
            //// this is used if available to hide the bookmark section
            if(this.props.typingCommentFieldOpen !== undefined){
                this.props.typingCommentFieldOpen(false)
            }
            ExtendDialogueMessage(this.props.firebase, this.context, this.state.comment, "What are your thoughts on: "+this.props.contentData.title, 'utter_ask_awareness', '', 'content');
            if(this.props.handlerMessageSubmit!== undefined){
                this.props.handlerMessageSubmit(this.state.comment)
            }
        }

    }
    editContentComment = contentId => {
        //// this is used if available to hide the bookmark section
        if(this.props.typingCommentFieldOpen !== undefined){
            this.props.typingCommentFieldOpen(true)
        }
        this.setState({editComment : true});
    }
    visibleContentCommentOn= contentId => {
        this.setState({commentVisible : true});
        if(this.state.comment !== ''){
            this.props.firebase.contentBookmark( this.state.contentId, this.context.uid).set({
                contentId: this.state.contentId,
                userId: this.context.uid,
                commentVisible: true,
            }, {merge:true });
            //this.saveContentComment();
        }
    }
    visibleContentCommentOff= contentId => {
        this.setState({commentVisible : false});
        this.props.firebase.contentBookmark( this.state.contentId, this.context.uid).set({
            contentId: this.state.contentId,
            userId: this.context.uid,
            commentVisible: false,
          }, {merge:true }); 
    }
    
    addEngagementPoints = (points, type) => {
        //console.log('data', this.props.content)
        const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const timestamp = this.props.firebase.fieldValue.serverTimestamp();
        var d = new Date();
        var myDay = weekdays[d.getDay()]
        //var qualitySearchTerm = '' 
        //if(typeof this.props.contentData.leadershipQualitySearchTerm === 'string'){
        //    qualitySearchTerm = this.props.contentData.leadershipQualitySearchTerm;
        //}  
        //console.log('engage', timestamp)
        this.props.firebase.insightsEngagement().add({
            createdAt: timestamp, 
            day: myDay,
            message: this.state.comment, 
            origin: 'resources',
            contentTitle: this.props.contentData.title,
            leadership_quality: QualityID(this.props.contentData.leadership_quality),
            leadership_category: this.props.contentData.leadership_category,
            contentId: this.state.contentId,
            points: points,
            type: type,
            userId: this.context.uid,
          }); 
    }

    getCommentsFromOthers = (sorting) => {
        if(this.state.userId !== ''){
            
            var db = undefined;
            if(sorting === "commentVotes"){
                db = this.props.firebase.contentBookmarks(this.state.contentId)
                .where('commentVisible','==', true)
                .orderBy('createdAt','desc')
                //.orderBy('commentVotes','desc')
                .limit(10)
            }
            else{
                db = this.props.firebase.contentBookmarks(this.state.contentId)
                .where('commentVisible','==', true)
                .orderBy('createdAt','desc')
                .limit(10)
            }
            this.unsubscribeComments = db.onSnapshot(snapshot => {
                if (snapshot.emtpy) {
                  return;
                }
                var counterComments = 0;
                var otherComments = [];
                var highestVotes = 1;
                snapshot.forEach(doc => {
                    if(this._isMounted ){
                        //console.log('updated')
                        // will only add commments that are not own and not already in this array
                        if(doc.data().userId !==  this.state.userId 
                            && !otherComments.find(userId => userId.userId ===doc.data().userId)
                            &&  doc.data().comment!== undefined && doc.data().comment.length > 20
                            ){
                            //console.log('uid', this.state.userId)
                            //console.log('dcuid', doc.data().userId)
                            counterComments += 1;
                            var votes = 0
                            if(doc.data().commentVotes === undefined || doc.data().commentVotes < 1){
                                votes = 0
                            }
                            else{
                                votes = doc.data().commentVotes
                            }
                            var userName = 'User';
                            if(doc.data().userName !== undefined && doc.data().userName.length > 2 && doc.data().commentVisible && doc.data().userName !== 'Padawan'){
                                userName = doc.data().userName;
                            }
                            var newData = {
                                commentVotes:votes, 
                                userId: doc.data().userId, 
                                userName: userName,
                                text: doc.data().comment, 
                                createdAt: doc.data().timestamp,
                                commentsBack : doc.data().commentsBack,
                            };
                            if(votes > highestVotes){
                                otherComments.unshift(newData);
                                highestVotes = votes;
                            }
                            else otherComments.push(newData);
                        }
                    }//end if mounted
                });// for each
                if(counterComments > 0){
                    if(this._isMounted){
                        this.setState({otherComments: otherComments});
                    }
                    //console.log('comments', otherComments)
                }
            }) //end then
        }
    }
    upvoteCommentFromOthers = (currentVotes, otherUserId) => {
        //console.log('voted up &', currentVotes);
        var userVotedFlag = this.state.userVotedFlag;
        //console.log('voted up id', this.state.contentId);
        this.props.firebase.contentBookmark( this.state.contentId, otherUserId).set({
            commentVotes: this.props.firebase.fieldValue.increment(1)
        }, {merge:true }); 
        //mark this user as commented
        userVotedFlag.push(otherUserId);
        this.setState({otherUserId: otherUserId, userVotedFlag: userVotedFlag});
        this.addEngagementPoints(1,'social');
    }

    saveContentCommentBack = (contentId, otherUserId, text) => {
        //console.log('conte', contentId);
        //console.log('id',otherUserId);
        //console.log('text',text);
        //const timestamp = this.props.firebase.fieldValue.serverTimestamp();
        if(text !== ''){
            //console.log(this.context)
            this.props.firebase.contentBookmark( contentId, otherUserId).update({
                leadership_quality: QualityID(this.props.contentData.leadership_quality),
                leadership_category: this.props.contentData.leadership_category,
                commentsBack : this.props.firebase.fieldValue.arrayUnion(
                    {
                        origin: 'resources',
                        //createdAt: timestamp,
                        userId: this.context.uid,
                        userName: this.context.username,
                        comment: text,
                    }
                )
            }); 
        }
        this.addEngagementPoints(2,'social');
        //// this is used if available to hide the bookmark section
        if(this.props.typingCommentFieldOpen !== undefined){
            this.props.typingCommentFieldOpen(false)
        }
        AddAnalytics('goal_completion', 'GoalUserCommentsReply');
    }
    removeContentCommentBack = (contentId, otherUserId, item) => {
        if(item !== ''){
            this.props.firebase.contentBookmark( contentId, otherUserId).update({
                commentsBack : this.props.firebase.fieldValue.arrayRemove(item)
            }); 
        }
    }
} // end class

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(ContentComments);

  