import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import withStyles from '@material-ui/core/styles/withStyles';
import ProgressDoughnut from '../Charts/ProgressDoughnut';

import StarsRoundedIcon from '@material-ui/icons/StarsRounded';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
//import List from '@material-ui/core/List';
//import ListItem from '@material-ui/core/ListItem';


//import ListItemText from '@material-ui/core/ListItemText';


// added plus for choosing daily topic
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
//import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
});

class ActionFabButton extends Component {
    constructor(props) {
        super(props)
        this.state = {
            readyTitle: false,
            readySubtitle: false
        }
    }
    render(){
        const {theme} = this.props;
        const PropsIcon = this.props.icon;
        //console.log('AFB props', this.props)
        return(
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs style={{
                    justifyContent :'center',
                    textAlign :'-webkit-center'
                }}
                onMouseEnter={(this.state.hover !== true) ? () => {this.setState({hover: true})} : void(0)}
                onMouseLeave={(this.state.hover === true) ? () => {this.setState({hover: false})} : void(0)}>
                    <div style={{
                            ...theme.divCenter,
                            justifyContent :'center',
                            textAlign :'-webkit-center',
                            overflow: 'hidden',
                            height: this.props.height, 
                            width: this.props.height, 
                            //width:"100%", 
                            //minWidth: 100,
                            maxHeight:'fit-content', 
                            //maxWidth:'fit-content', 
                            padding:0, 
                            margin:4, 
                            boxShadow: 1, 
                        }}
                        >
                            {/**** */}
                        <ProgressDoughnut
                            percentage = {this.props.percentage}
                            overWriteText = {" "}
                            colorMain = {this.props.colorCircle || 'green'}
                            cutoutPercentage = {96}
                        />
                        <div style={{position:'relative',
                            textAlign: 'center',
                            zIndex: 3,
                            top: '-100%',
                            padding: 4,
                            //padding: '10%' ,
                            //padding: (this.props.height*0.12) ,
                            //top: (-1* this.props.height),
                            height: '100%',
                            width: '100%',
                        }}
                            >
                            <Avatar style={ {
                                    boxShadow:"none", 
                                    backgroundColor: this.state.hover? theme.palette.themeAltGold : theme.palette.backPrimary,
                                    width: '100%',
                                    height: '100%',
                                    //width: this.props.height*0.8, 
                                    //height: this.props.height*0.8
                                    "&:hover": {
                                        borderColor: "white",
                                        backgroundColor: "green"
                                    }
                                }}
                                >
                                {/***/}
                                {this.props.icon !== undefined && this.props.icon !== ''
                                    ?
                                        <PropsIcon
                                            style={{
                                                color: this.props.colorButton, 
                                                width: '80%',
                                                height: '80%'
                                                //width: this.props.height*0.8, 
                                                //height: this.props.height*0.8
                                            }}
                                        /> 
                                    :   this.props.image !== undefined && this.props.image !== ''
                                        ?
                                            <Avatar
                                                variant="rounded"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    backgroundColor: this.props.colorButton || theme.palette.themeAltGold, 
                                                    //background : 'radial-gradient(circle, rgba(0,0,0,0.5) 20%, '+this.state.backgroundColor+' 70%)',
                                                    //height: 'auto',
                                                    //width: 'auto',
                                                    height: '80%',
                                                    width: '80%',
                                                    borderRadius: this.props.colorButton === 'transparent' ? 0 : 50,
                                                    margin: 0,
                                                    padding: 3,
                                                    opacity: '0.94',
                                                    webKitFilter: (this.props.avatarNotVisible ? 'blur(2px) grayscale(1)' : this.props.avatarGreyOut ? 'grayscale(1)' : 'grayscale(0)'), /* Google Chrome, Safari 6+ & Opera 15+ */
                                                    filter: (this.props.avatarNotVisible ? 'blur(2px) grayscale(1)' : this.props.avatarGreyOut ? 'grayscale(1)' : 'grayscale(0)'), /* Microsoft Edge and Firefox 35+ */
                                                }}
                                                src= {this.props.image}
                                                alt="R"
                                            />
                                        :
                                            <StarsRoundedIcon
                                                style={{
                                                    color: this.props.colorButton, 
                                                    width: '80%',
                                                    height: '80%'
                                                    //width: this.props.height*0.8, 
                                                    //height: this.props.height*0.8
                                                }}
                                            /> 
                                }
                            </Avatar>
                        </div>
                    </div>
                </Grid>
                
                <Grid item xs={12}>
                    <div style={{
                        color: this.props.colorText || undefined,
                        textAlign: 'center'
                        //color: theme.palette.secondary.main
                    }}
                        >
                        {this.props.text}
                    </div>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(ActionFabButton);