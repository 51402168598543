import HelpOutlineIcon from '@material-ui/icons/HelpOutline';


export function ExternalHelpLink(theme,  text, link, target=undefined){
    return(
        <span style={{width: '100%', textAlign:'right', display: 'block'}}>
            <a href={link} target={target?.length > 1 ? target : "_blank"}>
                <HelpOutlineIcon style={theme.inlineIconRight}/>
                &nbsp;
                {text}
            </a>
        </span>
    )
}
