import React, { Component } from 'react';
import { Chart, Radar } from 'react-chartjs-2';

import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    grow: {
      flexGrow: 1,
    },
});


class ProgressRadar extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        var [data, options] = this.loadChartData();
        this.state = { data, options};
    }

    componentDidMount() {
        this._isMounted = true;
        Chart.pluginService.register({
            afterDraw: function (chart) {
                if (chart.config.data.labels) {
                    //Get ctx from string
                    /****
                    var ctx = chart.chart.ctx;
                    ctx.save();
                    var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
                    var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
                    console.log(chart.config.data, centerX, centerY)
                    chart.config.data.labels.forEach((value, index) => {
                        if(typeof chart.config.data.labels[index] === 'object'){
                            let image = new Image();
                            image.src = value;
                            console.log(" image.src",  image.src)
                            ctx.drawImage(image, centerX, centerY)
                        }
                    })
                    ctx.restore();
                     */
                }
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadChartData(){
        var values = [0,0,0];
        var labels = ['', '', ''];
        if(this.props.values !== undefined &&  this.props.values !== null && this.props.values.length > 0){
            values = this.props.values;
        }
        if(this.props.labels !== undefined &&  this.props.labels !== null && this.props.labels.length > 0){
            labels = this.props.labels;
        }
        //var colorText = this.props.theme.palette.secondary.dark;
        //if(this.props.colorText !== undefined && this.props.colorText !== null && this.props.colorText[0] === "#" ){
        //    colorText = this.props.colorText;
        //}
        var colorMain = this.props.theme.palette.secondary.light;
        if(this.props.colorMain !== undefined && this.props.colorMain !== null && this.props.colorMain[0] === "#" ){
            colorMain = this.props.colorMain;
        }
        var colorBackGround = this.props.theme.palette.themeNaviGrey;
        if(this.props.colorBackGround !== undefined && this.props.colorBackGround !== null && this.props.colorBackGround[0] === "#" ){
            colorBackGround = this.props.colorBackGround;
        }
        const data = {
            labels: labels,
            datasets: [{
                //label: 'My Engagement', // removed in options
                backgroundColor: colorBackGround,
                borderColor: colorMain,
                pointBackgroundColor: colorMain,
                pointBorderColor: colorMain,
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#fff',
                data: values
            },]
        };
        var ticks = {display:false, suggestedMin: 0, maxTicksLimit : 5}
        if (this.props.suggestedMax!==undefined && this.props.suggestedMax>1){
            ticks['suggestedMax'] = this.props.suggestedMax;
        }
        const options = {
            events: [],
            responsive : true,
            legend: {display: false},
            maintainAspectRatio : false,
            aspectRatio: 1,
            scale: {
                ticks: ticks
            }
        };
        return [data, options]
    }

    componentDidUpdate(prevProps)  {
        if(this._isMounted){
            var newValues = '';
            var oldValues = '';
            if(this.props.values !== undefined && this.props.values !== ""){
                newValues = this.props.values
            }
            if(prevProps.values !== undefined && prevProps.values !== ""){
                oldValues = prevProps.values
            }
            if(newValues !== oldValues && newValues!=="" && oldValues!=="") {
                var [data, options] = this.loadChartData();
                this.setState({ data, options});
                //console.log('updated values',  data, options)
            }
        }
        return true
    }

    render() {
        return(
            <Radar
                options={this.state.options}
                data={this.state.data}
            />
        )
    }
}

export default compose(
    withStyles(styles, { withTheme: true })
)(ProgressRadar);
