import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../../Firebase';
import { AuthUserContext } from '../../Session';
import LoadingProgress from '../../Modules/LoadingProgress';
//popup
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

// Modules for parameter change
import SettingContentBase from '../Modules/SettingContentBase';
import SettingContentActive from '../Modules/SettingContentActive';
import SettingContentNumber from '../Modules/SettingContentNumber';
import SettingContentArray from '../Modules/SettingContentArray';
import SettingContentUploadImage from '../Modules/SettingContentUploadImage';
import SettingContentMapSelect from '../Modules/SettingContentMapSelect';
//import SettingContentRichText from '../Modules/SettingContentRichText';
import SettingContentArraySelect from '../Modules/SettingContentArraySelect';
import SettingContentArrayCheckbox from '../Modules/SettingContentArrayCheckbox';
import SettingContentSelect from '../Modules/SettingContentSelect';

import Grid from '@material-ui/core/Grid';

const styles = {
    right : {
        position: 'absolute',
        right: 5
    },
};

class DialogQuestionnaireItem extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        var id = this.props.currentQuestionId;
        var errorMessage = '';

        
        //console.log('location',id)
        this.state = {
            loading: false,
            content: null,
            id: id,
            errorMessage: errorMessage,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.getleadershipCategoriesArray();
        this.getleadershipQualitiesArray();
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.unscrubscribe !== undefined ) this.unsubscribe();
    }

    componentDidUpdate() {
        //console.log('init', this.props.questionId)
        console.log('props', this.props)
        if (this.props.questionId && this.state.id !== this.props.questionId) {
            //console.log('myidopen', this.props.contentId)
            this.setState({ loading: true, id: this.props.questionId });
            this.unsubscribe = this.props.firebase
                .questionnaireQuestion(this.props.questionId)
                .onSnapshot(snapshot => {
                    if(this._isMounted ){
                        console.log('data',snapshot.data())
                        this.setState({
                            content: snapshot.data(),
                            loading: false,
                        });
                    }//end ifmounted
                });
        }
    }

    getleadershipCategoriesArray()
    {
        var myArray = [];
        var myArrayText = [];
        this.props.firebase
        .leadershipCategories()
        .orderBy('text', 'asc')
        .where('active', '==', true)
        .get()
        .then(snapshot => {
            if (snapshot.empty) {
            return;
            }
            snapshot.forEach(doc => {
            if(this.context.programs.includes(doc.data().program)){
                myArray.push(doc.data().value);
                myArrayText.push(doc.data().text);
            }
            });
        });
        if(this._isMounted){
            this.setState({
                leadershipCategories: myArray,
                leadershipCategoriesText: myArrayText
            });
        }
    }
    getleadershipQualitiesArray()
    {
        var myArray = [];
        var myArrayText = [];
        this.props.firebase
        .leadershipQualities()
        .where('active', '==', true)
        .orderBy('category', 'asc')
        .orderBy('text', 'asc')
        .get()
        .then(snapshot => {
            if (snapshot.empty) {
            return;
            }
            snapshot.forEach(doc => {
            if(this.context.programs.includes(doc.data().program)){
                myArray.push(doc.data().value);
                myArrayText.push( "(" + doc.data().category + ") " + doc.data().text);
            };
            });
        });
        if(this._isMounted){
            this.setState({
                leadershipQualities: myArray,
                leadershipQualitiesText: myArrayText
            });
        }
         
    }

    render() {
        const { content, loading } = this.state;
        
         
        console.log('my context', this.props.questionnaireSettings?.resultTexts, this.props)
        return (
            <Dialog
                open={this.props.openDialog}
                onClose={this.props.closeDialog}
            >
                <DialogTitle >
                    <IconButton onClick={this.props.closeDialog}>
                        (X)
                    </IconButton>
                    Modify Question 
                </DialogTitle>
                <DialogContent >
                    <div>

                    <Button onClick={() => { this.handleDuplicateContent( content )  }}>
                        Duplicate
                    </Button>
                    <h5 style={styles.subtitle}>Content ({this.props.questionId})</h5>
                    {loading && 
                        <LoadingProgress 
                            defaultSet={this.props.defaultSet}
                            type={'LoadingPageAnimated'} 
                            loadingText={"many, many, data"} 
                            firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                            allowRefresh={true}
                        />
                    }

                    {this.state.errorMessage !== '' && 
                        <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                    }
                    {content && (
                        <div>
                            <SettingContentActive 
                                dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                title={"Active Question"} 
                                content={content} 
                                labels={['Published in the app']}
                                params={['active']}
                                />
                            <SettingContentBase 
                                dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                title={"Title of (optional) and question"} 
                                content={content}
                                max={120}
                                params={['title','question']}
                                />
                            <SettingContentUploadImage
                                key={"image_question"}
                                dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                title={"Optional: Picture for Question"} 
                                previewImageSize={true}
                                content={content} 
                                params={['imageUrl_question']}
                                uploadDatabase={this.props.firebase.storageImages('questions/'+this.state.id+'_question')}
                                />
                            <SettingContentNumber 
                                dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                title={"Question number:"} 
                                content={content} 
                                min={0}
                                max={100}
                                params={['position']}
                                />
                            <SettingContentSelect 
                                dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                title={"Assessment Variant"} 
                                content={content} 
                                params={['category']}
                                selectOptions={['OCEAN', 'PERMA-V', 'MBTI', 'DISC', 'CUSTOM']}
                                />
                            <SettingContentSelect 
                                dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                title={"Question response type"} 
                                content={content} 
                                params={['type']}
                                selectOptions={['single', 'multiple', 'matrix', 'rating', 'likert', 'text', 'ranking']}
                                />
                            {content.type === "likert" &&
                                <SettingContentSelect 
                                    dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                    title={"Response scale variant"} 
                                    content={content} 
                                    params={['variant']}
                                    selectOptions={['agreement', 'frequency', 'importance', 'quality', 'performance']}
                                    selectOptionsText={['Disagree <> Agree', 'Never <> Always', 'Unimportant <> Important', 'Poor <> Excellent', 'Below <> Above']}
                                    />
                            }
                            {(content.type === "single" || content.type === "multiple" || content.type === "ranking"  )&&
                                <SettingContentArray
                                    dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                    title={"Response Options"} 
                                    content={content} 
                                    params={['responses']}
                                    max={100}
                                    />
                            }
                            {(content.type === "rating"  )&&
                                <>
                                    <SettingContentBase 
                                        dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                        title={"Scale edge values"} 
                                        content={content}
                                        max={30}
                                        params={['scaleTextStart','scaleTextEnd']}
                                        />
                                    <SettingContentNumber 
                                        dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                        title={"Scale Start:"} 
                                        content={content} 
                                        min={-10}
                                        max={9}
                                        params={['scalePointsStart']}
                                        />
                                    <SettingContentNumber 
                                        dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                        title={"Scale Start:"} 
                                        content={content} 
                                        min={1}
                                        max={100}
                                        params={['scalePointsEnd']}
                                        />
                                </>
                            }
                            {content.category === "OCEAN" && (content.type === "single" || content.type === "multiple" ) && content.responses!==undefined && content.responses.length>1 &&
                                content.responses.map((item, indexItem) => (
                                    <>
                                        <SettingContentMapSelect
                                            key={"OCEAN_"+indexItem}
                                            dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                            title={"Response: " + indexItem + " - '" + item + "'"} 
                                            content={content} 
                                            labels={["Points Big 5, OCEAN"]}
                                            params={['pointsResponse'+indexItem]}
                                            mapOptions={['O','C', 'E', 'A', 'N']}
                                            mapOptionsText={['Openness','Consciousness', 'Extroversion', 'Agreeableness','Neuroticism']}
                                            selectOptions={[-12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                                            />
                                        <SettingContentUploadImage
                                            key={"image"+indexItem}
                                            dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                            title={"Optional: Picture " + indexItem} 
                                            previewImageSize={true}
                                            content={content} 
                                            params={['imageUrl'+indexItem]}
                                            uploadDatabase={this.props.firebase.storageImages('questions/'+this.state.id+'_'+indexItem)}
                                            />
                                    </>
                                ))
                            }
                            {content.category === "CUSTOM" && (content.type === "single" || content.type === "multiple" ) && content.responses?.length>0 && this.props.questionnaireSettings?.resultTexts !== undefined &&
                                content.responses.map((item, indexItem) => (
                                    <Grid
                                        container
                                        spacing={0}
                                        direction="row"
                                        alignItems="top"
                                        justifyContent="center"
                                    >
                                        <Grid item xs={6}>
                                            <SettingContentUploadImage
                                                key={"image"+indexItem}
                                                dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                                title={"Optional: Picture " + indexItem} 
                                                previewImageSize={true}
                                                content={content} 
                                                params={['imageUrl'+indexItem]}
                                                uploadDatabase={this.props.firebase.storageImages('questions/'+this.state.id+'_'+indexItem)}
                                                />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SettingContentMapSelect
                                                key={"CUSTOM_"+indexItem}
                                                dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                                title={"Response: " + indexItem + " - '" + item + "'"} 
                                                content={content} 
                                                labels={["Points for each answer"]}
                                                params={['pointsResponse'+indexItem]}
                                                mapOptions={Object.keys(this.props.questionnaireSettings.resultTexts) || []}
                                                //mapOptionsText={['Openness','Consciousness', 'Extroversion', 'Agreeableness','Neuroticism']}
                                                selectOptions={[-10, -8, -5, -2, 0, 2, 5, 8, 10]}
                                                />
                                        </Grid>
                                    </Grid>
                                ))
                            }
                            {content.category === "CUSTOM" && (content.type === "rating"  ) && this.props.questionnaireSettings?.resultTexts !== undefined &&
                                <SettingContentMapSelect
                                    key={"CUSTOM_rating"}
                                    dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                    title={"Rating Points: "} 
                                    content={content} 
                                    labels={["Points for each answer"]}
                                    params={['pointsScaleRating']}
                                    mapOptions={Object.keys(this.props.questionnaireSettings.resultTexts) || []}
                                    //mapOptionsText={['Openness','Consciousness', 'Extroversion', 'Agreeableness','Neuroticism']}
                                    selectOptions={[10, 2, 1, 0, -1, -2, -10]}
                                    />
                            }
                            {content.category === "OCEAN" && (content.type === "likert" ) &&
                                <SettingContentMapSelect
                                    key={"OCEAN_key"}
                                    dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                    title={"Factor (default: 6) (if reverse: -6)"} 
                                    content={content} 
                                    labels={["Points Big 5, OCEAN"]}
                                    params={['pointsScaleLikert']}
                                    mapOptions={['O', 'C', 'E', 'A', 'N']}
                                    mapOptionsText={['Openness','Consciousness', 'Extroversion', 'Agreeableness','Neuroticism']}
                                    selectOptions={[-6, -3,  0,  3,  6]}
                                    />
                            }
                            {content.category === "CUSTOM" && (content.type === "likert" ) &&
                                <SettingContentMapSelect
                                    key={"customkey"}
                                    dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                    title={"Factor (default: 5) (if reverse: -5)"} 
                                    content={content} 
                                    labels={["Points x Factor (strongly agree = 2 points)"]}
                                    params={['pointsScaleLikert']}
                                    mapOptions={Object.keys(this.props.questionnaireSettings.resultTexts) || []}
                                    selectOptions={[-6, -5, -3, 0, 3, 5, 6]}
                                    />
                            }
                            {content.category === "PERMA-V" && (content.type === "rating" ) &&
                                <SettingContentMapSelect
                                    key={"PERMA_key"}
                                    dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                    title={"Factor (10 for counting positive, -10 for negated factor, 0 for irrelevance)"} 
                                    content={content} 
                                    labels={["Points PERMA-V"]}
                                    params={['pointsScaleRating']}
                                    mapOptions={['pP','pE', 'pR', 'pM', 'pA', 'pV']}
                                    mapOptionsText={['Positive Emotions','Engagement', 'Relationships', 'Meaning', 'Accomplishment', 'Life-Balance']}
                                    selectOptions={[10, 0, -10]}
                                    />
                            }
                            <SettingContentSelect 
                                dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                title={"Leadership category/topic (priority/balance/...)"} 
                                content={content} 
                                params={['leadership_category']}
                                selectOptions={this.state.leadershipCategories}
                                selectOptionsText={this.state.leadershipCategoriesText}
                                />
                            <SettingContentArraySelect
                                dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                title={"Assigned dev. areas / skills / qualities"} 
                                content={content} 
                                params={['leadership_quality']}
                                selectOptions={this.state.leadershipQualities}
                                selectOptionsText={this.state.leadershipQualitiesText}
                                />
                            <SettingContentSelect 
                                dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                title={"Original program"} 
                                content={content} 
                                params={['program']}
                                selectOptions={this.context.programs}
                                />
                            <SettingContentBase 
                                dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                title={"Sources and external link"} 
                                content={content} 
                                params={['source', 'sourceUrl']}
                                />
                            {content.utteranceDescriptions !== undefined && content.utteranceDescriptions.utter !== undefined && content.utteranceDescriptions.utter.length > 1
                                ?
                                    <SettingContentArrayCheckbox
                                        dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                        title={"Add AI labels"} 
                                        content={content}
                                        noLabel={true}
                                        params={['utteranceClass']}
                                        groupFilter={['balance', 'self', 'mindset', 'learning', 'productivity', 'work', 'career', 'success', 'business', 'people', 'communication']}
                                        selectOptions={content.utteranceDescriptions.utter}
                                        selectOptionsText={content.utteranceDescriptions.response}
                                        />
                                :
                                    <SettingContentArray
                                        dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                        title={"Manage AI labels"} 
                                        content={content}
                                        noLabel={true}
                                        params={['utteranceClass']}
                                        />
                            }
                            <hr/>
                            <SettingContentArray 
                                dbUpdateLink={this.props.firebase.questionnaireQuestion(this.state.id)}
                                title={"Internal author"} 
                                content={content} 
                                params={['author']}
                                />

                        </div>
                    )}
                    </div>
                </DialogContent>
            </Dialog> 
        );
    }


    handleDuplicateContent = (content) => {
        if(content!== undefined ){
            var newContent = {...content};
            newContent['status'] = 'inactive';
            newContent['question'] = 'COPY: ' + content.question;
            this.props.firebase.questionnaireQuestions().add(newContent);
            this.setState({errorMessage : 'Copied content without tags and status inactive'})
        }
        else{
            this.setState({errorMessage : 'Original data missing for a copy'})
        }
    }

}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(DialogQuestionnaireItem);
