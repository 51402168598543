//import palette from '../palette';

function muiStyle (palette) { return {
    root: {
        color: 'black',
        colorPrimary: {
            '&$checked': {
                color: 'black'
            },
            color: 'black'
        }
    }
}}

export default muiStyle;