import React, { Component } from 'react';
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import LoadingProgress from '../Modules/LoadingProgress';
import { DataCacheRead, DataCacheWrite} from "../Modules/DataCache";
import {ContentProgramOverwrite} from '../Content/ContentItemCard';

import HomeCardContentItems from '../Content/HomeCardContentItems';
import { QualityID } from '../Modules/LoadCategoriesAndQualities';


class RepliesOnContent extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        //console.log('probs in JournalWeek ',this.probs);
        var contentFavorite = [];
        if (this.props.loadExtraContentList !== undefined && this.props.loadExtraContentList.length> 0){
            //console.log('loaded content', this.props.loadExtraContentList )
            contentFavorite = contentFavorite.concat(this.props.loadExtraContentList )
        }
        if (this.props.user.contentBookmarked !== undefined){
            contentFavorite = contentFavorite.concat(this.props.user.contentBookmarked )
        }
        if (this.props.user.contentLiked !== undefined){
            contentFavorite = contentFavorite.concat(this.props.user.contentLiked )
        }
        this.state = {
            loading: false,
            loadingText: 'loading replies to content',
            contentSelection: [],
            contentFavorite: contentFavorite,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        let contentSelection = [];
        let contentItems = [];
        var loadContext, searchRefIds;
        this.setState({ loading: true });

        if (this.props.loadExtraContentList === undefined || this.props.loadExtraContentList.length <= 0){
            var cacheStoreDialogues = DataCacheRead('latestComments_'+this.props.commentKey+'_'+this.props.commentQuality+'_'+this.context.uid)
            if(cacheStoreDialogues!== undefined && cacheStoreDialogues.cacheAvailable) {
                //console.log(cacheStoreDialogues)
                this.setState({
                    contentSelection: cacheStoreDialogues.cacheData,
                    loading: false 
                })
            }
        }
        
        // weekly Journals
        this.unsubscribe = this.props.firebase.contentBookmarkEngagements()
        if(this.props.commentKey === '' || this.props.commentKey === undefined || this.props.commentKey === 'commentFavorites'){
            searchRefIds = this.state.contentFavorite.slice(0,9);
            if(searchRefIds === undefined || searchRefIds.length<1){
                searchRefIds = ['wZa0CobAZ9shPQfYAT2h']
            }
            this.unsubscribe = this.unsubscribe
            .where("comment",">",'0')
            .where("contentId","in",searchRefIds)
            .orderBy("comment", "desc")
        }
        else if (this.props.commentKey === 'commentResponses'){
            if (this.props.loadExtraContentList !== undefined && this.props.loadExtraContentList.length> 0){
                loadContext = this.props.loadExtraContentList;
                if(loadContext.length>9){
                    loadContext = loadContext.slice(0,9)
                }
                //console.log('loaded subscribe', this.props.loadExtraContentList )
                //contentFavorite = contentFavorite.concat(this.props.loadExtraContentList )
                this.unsubscribe = this.unsubscribe
                .where("comment",">",'0')
                .where("contentId","in",loadContext)
                .orderBy("comment", "desc")
            }
            else{
                this.unsubscribe = this.unsubscribe
                .where("comment",">",'0')
                .where("userId","==",this.context.uid)
                //.orderBy("commentsBack", "desc")
                .orderBy("comment", "desc")
                .orderBy("createdAt", "desc")
            }
        }
        else if(this.props.commentKey === 'commentComments'){
            if (this.props.loadExtraContentList !== undefined && this.props.loadExtraContentList.length> 0){
                loadContext = this.props.loadExtraContentList;
                if(loadContext.length>9){
                    loadContext = loadContext.slice(0,9)
                }
                //console.log('loaded subscribe', this.props.loadExtraContentList )
                //contentFavorite = contentFavorite.concat(this.props.loadExtraContentList )
                this.unsubscribe = this.unsubscribe
                .where("comment",">",'0x')
                .where("contentId","in",loadContext)
                .orderBy("comment", "desc")
            }
            else{
                console.log('loaded subscribess  ss ' ,this.context.uid)
                this.unsubscribe = this.unsubscribe
                .where("comment",">=",'0x')
                .where("userId","==",this.context.uid)
                .orderBy("comment", "desc")
                .orderBy("createdAt", "desc")
            }
        }
        else if(this.props.commentKey === 'commentQuality' && this.props.commentQuality !== undefined){
            this.unsubscribe = this.unsubscribe
            //.where("userId","!=",this.context.uid)
            .where("comment",">",'0')
            .where('leadership_quality','array-contains', QualityID(this.props.commentQuality))
            //.where("createdAt",">",'0')
            //.where("contentId","in",searchRefIds)
            .orderBy("comment", "desc")
            .orderBy("createdAt", "desc")
            .limit(50)
        }
        else if(this.props.commentKey === 'commentCategory' && this.props.commentCategory !== undefined){
            this.unsubscribe = this.unsubscribe
            //.where("userId","!=",this.context.uid)
            .where("comment",">",'0')
            .where("leadership_category","==",this.props.commentCategory)
            //.where("createdAt",">",'0')
            //.where("contentId","in",searchRefIds)
            .orderBy("comment", "desc")
            .orderBy("createdAt", "desc")
            .limit(50)
        }
        else{
            searchRefIds = this.state.contentFavorite.slice(0,9);
            if(searchRefIds === undefined || searchRefIds.length<1){
                searchRefIds = ['wZa0CobAZ9shPQfYAT2h']
            }
            this.unsubscribe = this.unsubscribe
            .where("comment",">",'0')
            .where("contentId","in",searchRefIds)
            .orderBy("comment", "desc")
        }

        this.unsubscribe
        .get()
        .then(snapshot => {
            if (snapshot.size) {
                snapshot.forEach(doc => {
                    //console.log(doc.data())
                    var contentItem = doc.data();
                    contentItem = ContentProgramOverwrite(contentItem, this.context);
                    //console.log('contentItem', contentItem)
                    if(contentItem.contentId!== undefined && contentItem.comment !== undefined
                        && !(contentSelection.includes(contentItem.contentId ))
                        ){
                        if(this.props.commentKey === 'commentQuality' && this.props.commentQuality !== undefined){
                            contentSelection.push(contentItem.contentId)
                            contentItems.push(contentItem)
                        }
                        else if(this.props.commentKey === 'commentCategory' && this.props.commentCategory !== undefined){
                            contentItems.push(contentItem)
                            contentSelection.push(contentItem.contentId)
                        }
                        else{
                            contentItems.push(contentItem)
                            contentSelection.push(contentItem.contentId)
                        }
                    }
                });
                if(this._isMounted){
                    this.setState({ contentSelection, loading: false, contentItems });
                }
                DataCacheWrite(('latestComments_'+this.props.commentKey+'_'+this.props.commentQuality+'_'+this.context.uid), contentSelection, 3600)
            } 
            else if(this._isMounted){
                this.setState({loading: false});
            }
            //console.log('from commenting', contentSelection, contentItems)
        }); 
    }

    componentWillUnmount() {
        //if(this.unsubscribe !== undefined) {this.unsubscribe();}
        //if(this.unsubscribeLikes !== undefined) {this.unsubscribeLikes();}
        this.setState({ loading: false });
        this._isMounted = false;
    }
    shouldComponentUpdate(nextProps, nextState) {  
        if (nextProps.rerender === false) {  
            //console.log('no rerender')
            return false;  
        }  
        //console.log(' rerender')
        return true;  
    }

    render(){
        return(
            <div>
                {this.state.loading 
                ?
                    <LoadingProgress 
                        defaultSet={this.props.defaultSet}
                        type={'LoadingPageAnimated'} loadingText={this.state.loadingText} />
                :
                /***
                    this.state.contentItems!==undefined && this.state.contentItems.length>0 
                    ?
                        this.state.contentItems.map((i, index) => (
                            <div key={index}>
                                <ContentItemCard
                                    user={this.props.user}
                                    listIndex={index}
                                    handlerListIndex={ (visualIndex) => this.setState({visualIndex})}
                                    contentData={i}
                                    handleOpen={ (contentId, type="content") => this.handleOpen(i, type)}
                                    handleOpenRead={ (contentId, type="read") => this.handleOpen(i, type)}
                                    handleOpenComment={ (contentId, type="comment") => this.handleOpen(i, type)}
                                    contentId = {i.id}
                                    leadershipCategories={this.props.leadershipCategories}
                                    leadershipQualities={this.props.leadershipQualities}
                                />
                            </div>
                        ))
                             */
                    this.state.contentSelection!==undefined && this.state.contentSelection.length>0 
                    ?
                        <HomeCardContentItems
                            user={this.props.user}
                            defaultSet={this.props.defaultSet || undefined}
                            visualLimit={40}
                            specialQuery={'contentByIds'}
                            hideImage={true}
                            commentView={true}
                            visualCommentsLimit = {this.props.visualCommentsLimit!== undefined && this.props.visualCommentsLimit> 0 ? this.props.visualCommentsLimit : 5}
                            qualityFilter = {this.state.contentSelection}
                            leadershipCategories={this.props.leadershipCategories}
                            leadershipQualities={this.props.leadershipQualities}
                            allQualitiesLoaded = { []} />
                    :
                    <div style={{margin: 20}}>Soon more comments of like-minded people will be available here. You can ask questions in the Learnings section as well.</div>
                }
            </div>
        )
    }
}


export default compose(
    withFirebase,
)(RepliesOnContent);
