export const isInStandaloneMode = () => {
    if(process?.env?.REACT_APP_DEVICE_PLATFORM === "BROWSER"){
        try{
            var result =  ( 
                (('standalone' in window.navigator) && window.navigator.standalone) 
                || window?.document?.referrer?.includes('android-app://')
                || window?.document?.referrer?.includes('?mode=standalone')
                || (window.matchMedia('(display-mode: standalone)').matches) 
            )
            if (result){
                //console.log('standalone refer', window?.document?.referrer)
                //console.log('standalone confirmed', true)
                //console.log('standalone info', window.navigator)
            }
            return result
        } catch (err){
            console.log('no standalone')
            return false;
        } 
    }
    else return false;
}

export function StoreMobileDeviceInfo(dbuser) {
    document.addEventListener("deviceready", () => storeDeviceInfo(dbuser), false);
    var flagPwa = isInStandaloneMode();
    if(flagPwa || process?.env?.REACT_APP_DEVICE_PLATFORM === "BROWSER"){
        storePWAInfo(dbuser, flagPwa)
    }
};
export function StoreMobileDeviceToken(dbuser, forcePermission=false) {
    //console.log('StoreMobileDeviceToken', dbuser)
    document.addEventListener("deviceready", () => storeDeviceToken(dbuser, forcePermission), false);
    if(isInStandaloneMode() || process?.env?.REACT_APP_DEVICE_PLATFORM === "BROWSER"){
        storePWAToken(dbuser, forcePermission)
    }
};

export function RequestNotificationPermission(fb, dbuser){
    try {
        console.log('Ask for permission')
        if(isInStandaloneMode() || process?.env?.REACT_APP_DEVICE_PLATFORM === "BROWSER"){
            fb.getBrowserPermission(dbuser);
        }
        else{
            window.cordova?.plugins?.firebase?.messaging.requestPermission({forceShow: true}).then(function() {
                console.log("Push messaging is allowed");
                dbuser.update({ mobileDevicePushNotificationAllowed: true});
                StoreMobileDeviceToken(dbuser, true)
            });
        }
    }
    catch(err){
        //console.log('noplugin')
        return false;
    } 
};

function externalLinksMobile() {
    try{
        window.open = function(URL, type, specs){
            if(type==='_blank'){
                type="_system";
            }
            window.cordova.InAppBrowser.open(URL, type, specs);
        }
    }
    catch(err){
        console.log('noplugin inappbrowser', err)
    }  
}
function externalLinksWeb() {
    try{
        //console.log('register window.open')
        window.open = function (open) {
            return function (url, name, features) {
                //console.log('open window extra', URL)
                // set name if missing here
                name = name || "default_window_name";
                name = '_blank';
                return open.call(window, url, name, features);
            };
        }(window.open);
    }
    catch(err){
        console.log('Failure open new tab', err)
    }  
}

function storePWAInfo(dbuser, flagPwa=false) {
    console.log('store PWA info')
    externalLinksWeb();
    // https://articles.wesionary.team/prompt-installation-in-pwa-is-a-nightmare-unless-you-know-this-36cb0005e2b2
    /***
     *     
    const Device = UserAgent.getDevice()
    const OS = UserAgent.getOS()
    return {
        device: {
            isMobile: Device.type == "mobile",
            isTablet: Device.type == "tablet",
            isAndroid: OS.name == "Android",
            isIOS: OS.name == "iOS",
        },
        pwa: {
            isStandalone: (window: Window & typeof globalThis) => window.matchMedia('(display-mode: standalone)').matches,
        },
        userAgent: UserAgent.getUA(),
        browser: UserAgent.getBrowser()
    }
     */
    try{ 
        console.log('store device info')
        storePWAToken(dbuser);
        var mobileDeviceInfo = {}
        mobileDeviceInfo = {
            model: window.navigator.userAgent,
            platform:  flagPwa? "PWA" : "Browser",
            version:  "1" ,
            manufacturer: window.navigator.language,
        }
        dbuser
        .update({
            mobileDeviceInfo: mobileDeviceInfo,
        });
        return true;
    }
    catch(err){
        console.log('noplugin', err)
        return false;
    }  
}
function storePWAToken(dbuser, forcePermission) {
    //console.log('store PWA token')
    externalLinksWeb();
    try{
        window.addEventListener('popstate', popStateListener, false);
    }
    catch(e){
        console.log('error pop', e)
    }
    try { 
        try{
            var token = window?.localStorage?.getItem("mobilePWAToken");
            if(token?.length > 2){
                dbuser
                .update({
                    mobilePWAToken: token,
                });
            }
            if(forcePermission){
                Notification.requestPermission().then((permission) => {
                    if (permission === "granted") {
                        dbuser
                        .update({
                            mobilePWAPushNotificationAllowed: true
                        });
                    }
                    else{
                        dbuser
                        .update({
                            mobilePWAPushNotificationAllowed: false,
                            mobilePWAToken: "NOPUSHPWAACCESS"
                        });
                    }
                })
            }
        } catch (err){
            console.log('No PWA check', err)
        } 
    }
    catch(err){
        console.log('no plugin for messaging')
        return false;
    }  
}

//module to detect mobile device info
function storeDeviceInfo(dbuser) {
    externalLinksMobile();
    try{ 
        console.log('store device info')
        storeDeviceToken(dbuser);
        var mobileDeviceInfo = {}
        mobileDeviceInfo = {
            model:  window.device.model ,
            platform:  window.device.platform ,
            version:  window.device.version ,
            manufacturer: window.device.manufacturer,
        }
        dbuser
        .update({
            mobileDeviceInfo: mobileDeviceInfo,
        });
        window.removeEventListener("deviceready", () => storeDeviceInfo(dbuser));
        return true;
    }
    catch(err){
        console.log('noplugin', err)
        window.removeEventListener("deviceready", () => storeDeviceInfo(dbuser));
        return false;
    }  
}
//module to detect mobile device info
function storeDeviceToken(dbuser, forcePermission=false) {
    externalLinksMobile();
    try { 
        var mobileDevicePushNotificationAllowed = false;
        var appVersion = "1.9.3" 
        if (window!== undefined && window.AppVersion !== undefined && window.AppVersion.version !== undefined  ){
            appVersion =  window.AppVersion.version 
        }
        if(window.device.platform === 'Android'){
            try{
                document.addEventListener("backbutton", onBackKeyDown, false);  
            }
            catch(err){
                console.log('No back button check')
            } 
            try {
                if(forcePermission){
                    window.cordova.plugins.firebase.messaging.requestPermission({forceShow: true}).then(function() {
                        console.log("Push messaging is allowed");
                        mobileDevicePushNotificationAllowed = true;
                        dbuser.update({ mobileDevicePushNotificationAllowed: mobileDevicePushNotificationAllowed});
                    });
                }
                window.cordova.plugins.firebase.messaging.getToken().then((token) => {
                    //console.log('token', token)
                    dbuser
                    .update({
                        //emailNotifications: 'inactive',
                        appVersion: appVersion,
                        mobileDeviceToken: token,
                        mobileDevice: window.device.platform, 
                        //mobileDevicePushNotificationAllowed: mobileDevicePushNotificationAllowed
                    });
                });
                window.removeEventListener("deviceready", () => storeDeviceToken(dbuser));
                return true;
            }
            catch(err){
                //console.log('failed token', err)
                return false;
            } 
        }
        else if(window.device.platform === 'iOS'){
            try {
                if(forcePermission){
                    window.cordova.plugins.firebase.messaging.requestPermission({forceShow: true}).then(function() {
                        console.log("Push messaging is allowed");
                        mobileDevicePushNotificationAllowed = true;
                        dbuser.update({ mobileDevicePushNotificationAllowed: mobileDevicePushNotificationAllowed});
                    });
                }
                window.cordova.plugins.firebase.messaging.getToken().then((token) => {
                    //console.log('token', token)
                    dbuser
                    .update({
                        //emailNotifications: 'inactive',
                        appVersion: appVersion,
                        mobileDeviceToken: token,
                        mobileDevice: window.device.platform, 
                        //mobileDevicePushNotificationAllowed: mobileDevicePushNotificationAllowed
                    });
                }); 
                document.addEventListener("gesturestart", onGestureStart, false); 
                window.removeEventListener("deviceready", () => storeDeviceToken(dbuser));
                return true;
            }
            catch(err){
                //console.log('failed token')
                return false;
            } 
        }
        /***
         */
        try {
            // Reset the badgevalue as app is started
            window.cordova.plugins.firebase.messaging.setBadge(0);
        } catch (err){
            console.log('no plugin for badge')
        } 
    }
    catch(err){
        console.log('no plugin for messaging')
        window.removeEventListener("deviceready", () => storeDeviceToken(dbuser));
        return false;
    }  
}

export function CheckMobileNotificationActivated (user){
    var access = false;
    if((user?.mobileDeviceToken?.length > 20 && user?.mobileDevicePushNotificationAllowed === true)){
        access = true;
    }
    else if((user?.mobilePWAToken?.length > 20 && user?.mobilePWAPushNotificationAllowed === true)){
        access = true;
        // NOPUSHPWAACCESS
        // NOPUSHPWMACCESS
    }
    return access
}

const popStateListener = (e) => {
    var page = window?.location?.hash || 'start';
    var oldPage = window?.sessionStorage?.getItem('latestPage') || undefined;
    //console.log('window.history', page, oldPage, window.history, window.history.state)
    //console.log('popStateListener state', e.state, page, e, window.history, document?.referrer)
    window.history.pushState('pop', null);
    if (true || e.state === 'no-back') { // Use state to control back button
        handleBackPWA(e, page, oldPage);
    }
};
const handleBackPWA = (e, page, oldPage) => { 
    try{ 
        //console.log('handleBack', e)
        if (window.document.activeElement !== undefined && window.document.activeElement !== null && window.document.activeElement.className.includes("MuiDialog")) {
            e.preventDefault(); 
            //console.log('its a dialog', window.document, window.document.activeElement)
            var elements = window.document.getElementsByClassName('MuiBackdrop-root')
            //console.log(elements)
            if (elements!== undefined){
                //elements[0].style.opacity = 0;
                //elements[0].click(); // does not work maybe only for IE
                for(let i=0; i < elements.length; i++){
                    /////window['var'] is a way to make dynamic vairblae
                    /** 
                    window['evt'+index] = document.createEvent("HTMLEvents");
                    window['evt'+index].initEvent("click", true, true);
                    element.dispatchEvent(window['evt'+index]);
                    */
                    //console.log('backdrops', elements[i])
                    var evt;
                    try{
                        evt = document.createEvent("HTMLEvents");
                        evt.initEvent("click", true, true);
                    }
                    catch (e){
                        evt = new Event('click', { 'view': window, 'bubbles': true, 'cancelable': true } );
                    }
                    elements[i].dispatchEvent(evt);
                }
            }
            window.history.pushState(page, null);
            window.sessionStorage.setItem('latestPage', page);
        }
        else if (window.document.activeElement !== undefined && window.document.activeElement.offsetParent !== undefined 
            && window.document.activeElement.offsetParent !== null &&  window.document.activeElement.offsetParent.className.includes("MuiDialog")) {
            //console.log('its a papa dialog', window.document, window.document.activeElement)
            e.preventDefault(); 
            var elementsOffset = window.document.getElementsByClassName('MuiBackdrop-root')
            //console.log(elementsOffset)
            if (elementsOffset!== undefined){
                //elementsOffset[0].style.opacity = 0;
                //elementsOffset[0].click(); // does not work maybe only for IE
                for(let i=0; i < elementsOffset.length; i++){
                    //console.log('backdrops', elementsOffset[i])
                    var evt2;
                    try{
                        evt2 = document.createEvent("HTMLEvents");
                        evt.initEvent("click", true, true);
                    }
                    catch (e){
                        evt2 = new Event('click', { 'view': window, 'bubbles': true, 'cancelable': true } );
                    }
                    elementsOffset[i].dispatchEvent(evt2);
                }
            }
            window.history.pushState(page, null);
            window.sessionStorage.setItem('latestPage', page);
        }
        else if(!page.includes("#/home")){
            //console.log('Back Button is Pressed and go to home', page, oldPage, window.location); 
            e.preventDefault();
            window.history.pushState(page, null);
            window.sessionStorage.setItem('latestPage', page);
            if(!window.location.hash.includes('welcome') 
                //&& !window.location.hash.includes('onboarding') && !window.location.hash.includes('routine')
            ){
                if(page === oldPage){
                    console.log('old page repeat')
                    //window.history.pushState("no-back", null);
                    //window.sessionStorage.setItem('latestPage', "");
                    //window.location.hash = '#/home';
                }
            }
        }
        else if(page.includes("#/home") && page === oldPage){
            console.log('Back Button at home', page); 
            window.history.pushState("no-back", null);
            window.sessionStorage.setItem('latestPage', "");
            e.preventDefault();
        }
        else{
            console.log('Back Button at nowhere', page); 
            window.history.pushState("no-back", null);
            window.sessionStorage.setItem('latestPage', "");
            e.preventDefault();
        }
    }
    catch(err){
        console.log('error with finding dialog with backbutton press')
        e.preventDefault();
    }
}
function onBackKeyDown(e) { 
    try{
        if (window.document.activeElement !== undefined && window.document.activeElement !== null && window.document.activeElement.className.includes("MuiDialog")) {
            e.preventDefault(); 
            //console.log('its a dialog', window.document, window.document.activeElement)
            var elements = window.document.getElementsByClassName('MuiBackdrop-root')
            //console.log(elements)
            if (elements!== undefined){
                //elements[0].style.opacity = 0;
                //elements[0].click(); // does not work maybe only for IE
                for(let i=0; i < elements.length; i++){
                    /////window['var'] is a way to make dynamic vairblae
                    /** 
                    window['evt'+index] = document.createEvent("HTMLEvents");
                    window['evt'+index].initEvent("click", true, true);
                    element.dispatchEvent(window['evt'+index]);
                    */
                    //console.log('backdrops', elements[i])
                    var evt;
                    try{
                        evt = document.createEvent("HTMLEvents");
                        evt.initEvent("click", true, true);
                    }
                    catch (e){
                        evt = new Event('click', { 'view': window, 'bubbles': true, 'cancelable': true } );
                    }
                    elements[i].dispatchEvent(evt);
                }
                //window.document.activeElement.style.display = 'none';
            }
            //window.document.activeElement.style.display = 'none';
            //window.document.body.click();
        }
        else if (window.document.activeElement !== undefined && window.document.activeElement.offsetParent !== undefined 
            && window.document.activeElement.offsetParent !== null &&  window.document.activeElement.offsetParent.className.includes("MuiDialog")) {
            //console.log('its a papa dialog', window.document, window.document.activeElement)
            e.preventDefault(); 
            var elementsOffset = window.document.getElementsByClassName('MuiBackdrop-root')
            //console.log(elementsOffset)
            if (elementsOffset!== undefined){
                //elementsOffset[0].style.opacity = 0;
                //elementsOffset[0].click(); // does not work maybe only for IE
                for(let i=0; i < elementsOffset.length; i++){
                    //console.log('backdrops', elementsOffset[i])
                    var evt2;
                    try{
                        evt2 = document.createEvent("HTMLEvents");
                        evt.initEvent("click", true, true);
                    }
                    catch (e){
                        evt2 = new Event('click', { 'view': window, 'bubbles': true, 'cancelable': true } );
                    }
                    elementsOffset[i].dispatchEvent(evt2);
                }
                //window.document.activeElement.style.display = 'none';
            }
            //window.document.body.removeChild(window.document.activeElement);
            //window.document.body.click();
        }
        else{
            //console.log('Back Button is Pressed!'); 
            e.preventDefault();
            if(!window.location.hash.includes('welcome') 
                //&& !window.location.hash.includes('onboarding') 
                //&& !window.location.hash.includes('routine')
                ){
                window.location.hash = '#/home';
            }
        }
    }
    catch(err){
        console.log('error with finding dialog with backbutton press')
        e.preventDefault();
    }
}
function onGestureStart(e) { 
    try{
        e.preventDefault(); 
        console.log('Stoping gesture control'); 
    }
    catch(err){
        console.log('error with ios gesture control')
        return true
    }
}