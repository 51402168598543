
import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    date :{
        width: '44px',
        height: '44px',
        backgroundColor: theme.palette.secondary.dark,
        paddingTop: '0px',
        border: '1px',
        borderColor: theme.palette.secondary.dark,
        margin: 'auto',
        lineHeight: 'normal',
        borderRadius: theme.borderRadius,
        //borderRadius: "15%",
      }, 
      month: {
        display: 'block',
        textAlign: 'center',
        color: theme.palette.primary.main,
        fontSize: '11px',
        paddingTop: '4px',
        textTransform: 'uppercase',
      },
      day:{
        backgroundColor: theme.palette.primary.main,
        display: 'block',
        textAlign: 'center',
        marginBottom: '0px',
        marginLeft: '2px',
        marginRight: '2px',
        paddingBottom: '0px',
        color: theme.palette.secondary.dark,
        borderBottomLeftRadius: theme.borderRadius,
        borderBottomRightRadius: theme.borderRadius,
        //borderBottomLeftRadius: "15%",
        //borderBottomRightRadius: "15%",
        fontSize: '18px',
        fontWeight: 'bold',
      },
});

class DateBadge extends Component {
    render() {
        const { classes } = this.props;
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var valueMonth = this.props.valueMonth;
        //console.log(typeof(this.props.valueMonth ))
        if(this.props.valueMonth instanceof Number || typeof(this.props.valueMonth) === "number"){ 
            //console.log("date is a number")
            valueMonth = months[this.props.valueMonth];
        }
        var valueDay = this.props.valueDay;
        var measure = 'Day';
        var counter = 0;
        var showDays = false;
        if(this.props.showDays && this.props.date !== undefined){
            try{
                //console.log('date', this.props.date)
                var today = new Date();
                var Difference_In_Time = today.getTime() - this.props.date.getTime();
                // To calculate the no. of days between two dates
                counter = Math.round(Difference_In_Time / (1000 * 3600 * 24));
                if(counter>0){
                    showDays = true;
                    if(counter>98){
                        //// CHANGE TO WEEKS
                        counter=Math.round(counter/7);
                        measure = 'Week';
                        if(counter>26){
                            //// CHANGE TO MONTHS
                            counter=Math.round(counter/4);
                            measure = 'Month';
                            if(counter>26){
                                //// CHANGE TO MONTHS
                                counter=Math.round(counter/12);
                                measure = 'Year';
                            }
                        }
                    }
                }
            }
            catch(error){
                console.log(error, this.props.date)
            }
        }
        return(
            <div className={classes.date}>
                <span className={classes.month}>
                    {showDays? measure : valueMonth} 
                </span>
                <span className={classes.day}>
                    {showDays? counter : valueDay}
                </span>
            </div>
        )
    }
}

export default compose(
    withStyles(styles, { withTheme: true })
)(DateBadge);
