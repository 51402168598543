import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import * as ROUTES from '../../constants/routes';
import { Link } from "react-router-dom";
// core
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import DrawerBrandOverview from '../Coach/DrawerBrandOverview';
import SettingSubscriptionStripe from '../Profile/SettingSubscriptionStripe';
import {AccessCheckCreatorTrialPhase} from '../Coach/CoachFeaturesActive';
import TutorialPage from '../Onboarding/TutorialPage';
import HomeProfile from '../Profile/HomeProfile';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ManageShop from './ManageShop';
import backgroundImageSignup from "../../images/bluerunningtrack3.jpg";

import {AddConversation} from '../Support/MessagesUser';

import {RenderFullBrandPreview} from '../Coach/HomeCoach';
import BadgeCard from '../Modules/BadgeCard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import DialogSettingPlan from '../Profile/DialogSettingPlan';
import SettingPlan from '../Profile/SettingPlan';
import BuildIcon from '@material-ui/icons/Build';
import HomeIcon from '@material-ui/icons/Home';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';

// Modules
import LoadingProgress from '../Modules/LoadingProgress';
import SnackbarInfo from '../Modules/SnackbarInfo';
import CoachGroupSetting from '../Coach/CoachGroupSetting';
//import { DataCacheRead, DataCacheWrite} from "../Modules/DataCache";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PublicIcon from '@material-ui/icons/Public';
import Button from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import LanguageIcon from '@material-ui/icons/Language';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
///// Manage the navigation bar appear
import VizSensor from 'react-visibility-sensor';
import pixel from "../../images/pixel.png";

import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
//import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Card from '@material-ui/core/Card';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import { AccessCheckCoachCreator } from '../../constants/roles';
import SettingContentBase from '../Admin/Modules/SettingContentBase';
import SettingContentSelect from '../Admin/Modules/SettingContentSelect';
import { ExternalHelpLink } from '../Modules/ExternalLinks';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});

class ManageCoachAdminPage extends Component {
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        var startTab = "Experience Settings";
        this.dialogRef = React.createRef();
        this.state = {
            loading: true,
            value: startTab,
            user: null,
            scrolled: false,
            headerVisible: true,
            'Experience Settings': true,
        };
    }
    handleChange = (event, newValue) => {
        if(newValue === "Home"){
            this.props?.history?.push(ROUTES.HOME)
        }
        else{
            try{
                if(this.dialogRef !== undefined ) this.dialogRef.current.scrollIntoView({behaviour: 'smooth'});
                //document.body.scrollTop = document.documentElement.scrollTop = 0;
                //if(this.dialogRef !== undefined && this.dialogRef.scrollTop !== undefined ) this.dialogRef.scrollTop  = 0;
            }
            catch (err) {
                console.log('No scroll to top');
            }
            this.setState({
                value: newValue,
                [newValue] : true,
            });
        }
    }
    componentDidMount() {
        var startTab = this.state.value;
        if (this.context.roles?.includes('COACHADMIN') && !(this.context.programCode?.length > 2 && this.context.adminPrograms?.includes(this.context.programCode))){
            startTab = "Experience Settings"
        }
        try{
            if(this.dialogRef !== undefined ) this.dialogRef.current.scrollIntoView({behaviour: 'smooth'});
        } catch  (err) {
            console.log('No scroll to top');
        }
        this.setState({ loading: false, value: startTab });
        this.componentDidUpdate({})
    }
    componentDidUpdate(prevProps) {
        //console.log('props', prevProps, this.props.location)
        if(this.props.location?.state?.value !== undefined && !(this.props.location?.state?.value === prevProps?.location?.state?.value )){
            var defaultState = {  }
            Object.keys(this.props.location.state).map(key => {
                //console.log(key, this.props.location.state[key])
                defaultState[key] = this.props.location.state[key];
                return true
            })
            //console.log('props', defaultState, this.props.location)
            //console.log('state', defaultState)
            this.setState(defaultState);
        }
        //console.log('state', this.state)
    }

    componentWillUnmount() {
        //this.unsubscribeDialogues();
        this.setState({ loading: true });
        //window.removeEventListener('scroll', this.handleScroll);
    }


    render() {
        const { theme } = this.props;
        const { loading,  value } = this.state;
        const {user, leadershipCategories, leadershipQualities, screen} = this.props;
        //console.log('homemanagemen content', this.props, this.context.adminPrograms)
        if(user !== null ){
            var previewAvailable = this.props.defaultSet?.programMinPrograms?.length > 0 && this.context.adminPrograms?.some(programItem => this.props.defaultSet.programMinPrograms.includes(programItem)) ? true : false;
            if(this.props.defaultSet?.programCode?.length > 0 && this.context.adminPrograms?.includes(this.props.defaultSet.programCode)){
                previewAvailable = true;
            }
            if(this.context.programCode?.length > 2 && this.context.adminPrograms?.includes(this.context.programCode)){
                previewAvailable = true;
            }
            //console.log("new user", user)
            return(
                <Grid key="splitsidenavi" container direction="row" ref={this.dialogRef} >
                    <DrawerBrandOverview 
                        user={this.context}
                        bottomPosition={true}
                        defaultSet={this.props.defaultSet || undefined}
                        leadershipCategories={leadershipCategories}
                        leadershipQualities={leadershipQualities}
                    /> 
                    <DialogSettingPlan 
                        defaultSet={this.props.defaultSet || undefined}
                        user={this.context}
                        coach={true}
                        handleOpen={this.state.DialogSettingPlanOpen}
                        premiumOnly={true}
                        purchaseSuccessFlag={() => {
                            this.setState({nonSubscriber: false, premiumSubscriber: true})
                        }}
                        purchaseSuccessHandler={() => {
                            this.setState({DialogSettingPlanOpen: false, nonSubscriber: false, premiumSubscriber: true})
                        }}
                        handleClose={() => this.setState({DialogSettingPlanOpen : false})}
                    />
                    <Grid item xs={screen==="desktop" ? 2 : false}>
                        {screen==="desktop"
                            &&
                            <Card style={theme.CardDesktopLeft}>
                                <Card style={theme.CardNaviLeft}>
                                    <div 
                                        style={{...theme.cardBorder, backgroundColor: theme.palette.themeAlertRed+'0F', borderColor: theme.palette.themeAlertRed, textAlign: 'center'}}
                                    >
                                        <p><strong>
                                            <BuildIcon style={theme.inlineIconLeft}/>App Settings
                                        </strong></p>
                                        <span style={theme.textSupport}>
                                            Manage app, users & brand
                                        </span>
                                    </div>
                                    {false && 
                                    <div style={{width: '100%', textAlign: 'center'}}>
                                        <Button 
                                            variant="outlined" color="secondary"
                                            onClick={() => {
                                                this?.props?.history?.push(ROUTES.HOME)
                                            }}
                                        >
                                            <span>
                                                <HomeIcon style={theme.inlineIconLeft}/>
                                                Home
                                            </span>
                                        </Button>
                                    </div>
                                    }
                                    {this.renderTabs("vertical")}
                                </Card>
                            </Card>
                        }
                    </Grid>
                    <Grid item xs={screen==="desktop" ? 10 : 12}>
                        {loading
                            ?
                                <div>
                                    <LoadingProgress type={'LoadingPageAnimated'} loadingText={"loading client information"} />
                                </div>
                            :
                                <Card style={screen==="desktop"? theme.CardDesktopMain : theme.CardAppMain}>
                                    <VizSensor 
                                        partialVisibility={"top"}
                                        onChange={(isVisible) => { 
                                            if(screen!== "desktop" && isVisible && this._isMounted) { 
                                                this.setState({headerVisible: true});
                                                //console.log('visible top cart')
                                            }
                                            else if (screen!== "desktop" && this._isMounted){
                                                this.setState({headerVisible: false});
                                                //console.log('hding top cart')
                                            }
                                        }}>
                                        <div style={{height: 1}}>  <img src={ pixel} alt=''/></div>
                                    </VizSensor>

                                    {user !== null && leadershipQualities.length > 0 
                                        &&
                                        <Card style={{
                                                ...(this.props.screen === 'desktop' ? theme.CardDesktopTop : theme.CardTop), 
                                                position: this.state.headerVisible? 'relative' : 'fixed',
                                                zIndex: this.state.headerVisible? 0 : 100,
                                                top: 0,
                                                opacity: this.state.headerVisible? '1.0' : '0.94',
                                            }}>
                                            {this.state.headerVisible
                                                ?   screen==="desktop"
                                                    ?   
                                                        <h4 style={theme.CardContent.titleText}>
                                                            <BuildIcon style={theme.inlineIconLeft}/>&nbsp;App Settings
                                                        </h4>
                                                    :   <h1 style={theme.CardContent.titleText}><BuildIcon />&nbsp;{value}</h1>
                                                :   <div style={theme.textMonthCenter}><strong>&nbsp;{value}</strong></div>
                                            }
                                            {true &&
                                                this.renderTabs("horizontal")
                                            }
                                        </Card>      
                                    }
                                    <Card style={{
                                            ...theme.CardContent, 
                                            marginTop: this.state.headerVisible? 0 : 200,
                                            paddingBottom: this.state.headerVisible? 0 : '70vh',
                                            paddingTop: 20,
                                            borderRadius: theme.borderRadius,
                                            backgroundColor: theme.palette.backPaper
                                        }} >
                                        
                                        {user !== null && leadershipQualities.length > 0
                                        ?
                                            <div>
                                                <div value={"Brand Settings"} index={0} hidden={value !== "Brand Settings"}>
                                                    {(value==='Brand Settings' || this.state['Brand Settings']) 
                                                    &&
                                                        <Grid container direction="row">
                                                            <Grid item xs={6}>
                                                                <h6>Brand Settings</h6>
                                                                {false && !((this.context.purchasedProduct?.owned && this.context.purchasedProduct?.creator) || AccessCheckCreatorTrialPhase(this.context))
                                                                ?
                                                                    <div style={{marginTop: 20, marginRight: 20, textAlign: 'center', maxWidth: 500}}>
                                                                        <BadgeCard
                                                                            key={"creator"}
                                                                            backgroundColor= {"white"}
                                                                            image={undefined} 
                                                                            avatarImage = {<ImportantDevicesIcon fontSize="large" style={{color: theme.palette.themeAlertOrangee}}/>}
                                                                            title = {!AccessCheckCreatorTrialPhase(this.context)? "Activate Creator Tool" : "Manage coaching content"}
                                                                            subtitle = {"Subscribe to the business packages"}
                                                                            strongSubtitle = {false}
                                                                            onClick={ () => this.setState({DialogSettingPlanOpen: true})}  
                                                                            clickText= {'Subscribe'}
                                                                        />
                                                                    </div>
                                                                :
                                                                    <div style={{marginTop: 20, marginRight: 20, borderStyle: 'solid', borderWidth: 'thin', borderRadius: theme.borderRadius, padding: 20, width: "calc(100% - 20px)"}}>
                                                                        {this.context.programCode?.length > 2 && this.context.adminPrograms?.includes(this.context.programCode)
                                                                            ?
                                                                                <CoachGroupSetting 
                                                                                    defaultSet={this.props.defaultSet || undefined}
                                                                                    groupId={this.context.programCode}
                                                                                    handleClose={this.props.handleClose}
                                                                                    newGroup={this.props.newGroup}
                                                                                    partnerProgram={true}
                                                                                    resultCode={(code) => {this.setState({resultCode: code})}}
                                                                                    onlyBrand={true}
                                                                                    consultantsList={this.props.consultantsList}
                                                                                    leadershipCategories={leadershipCategories}
                                                                                    leadershipQualities={leadershipQualities}
                                                                                    user={user}
                                                                                    refreshQualities = {this.props.refreshQualities || undefined}
                                                                                    refreshCategories = {this.props.refreshCategories || undefined}
                                                                                    refreshDefaultSet={this.props.refreshDefaultSet || undefined}
                                                                                />
                                                                            :   
                                                                                <>
                                                                                    <CoachGroupSetting 
                                                                                        //// ONLY IF THEY NOW THE CODE
                                                                                        //// defaultSet={this.props.defaultSet || undefined}
                                                                                        //// groupId={this.context.programCode}
                                                                                        allowInherit={false}
                                                                                        handleClose={this.props.handleClose}
                                                                                        newGroup={true}
                                                                                        partnerProgram={true}
                                                                                        consultantsList={this.props.consultantsList}
                                                                                        leadershipCategories={leadershipCategories}
                                                                                        leadershipQualities={leadershipQualities}
                                                                                        user={user}
                                                                                        resultCode={(code) => {this.setState({resultCode: code})}}
                                                                                        refreshQualities = {this.props.refreshQualities || undefined}
                                                                                        refreshCategories = {this.props.refreshCategories || undefined}
                                                                                        refreshDefaultSet={this.props.refreshDefaultSet || undefined}
                                                                                    />
                                                                                </>
                                                                        }
                                                                    </div>
                                                                }
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <h6>Brand Preview</h6>
                                                                {(!(this.props.defaultSet?.programCode?.length > 0 && this.context.adminPrograms?.includes(this.props.defaultSet.programCode)))
                                                                    ?   <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Add your Domain-Code</span>
                                                                    :
                                                                        <>
                                                                            {(!(this.props.defaultSet?.programTitle?.length > 5) || !(this.props.defaultSet?.programSubTitle?.length > 10))
                                                                                && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Add your 'Brand Title' and '1-Sentence Pitch'</span>
                                                                            }
                                                                            {!(this.props.defaultSet?.programDescription?.length > 20)
                                                                                && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Add your full 'Brand Description' (min. 10 words)</span>
                                                                            }
                                                                            {(!(this.props.defaultSet?.programCompany?.length > 2) || !(this.props.defaultSet?.programEmail?.length > 2))
                                                                                && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Add your business information and your email address </span>
                                                                            }
                                                                            {(!(this.props.defaultSet?.programCompanyURL?.length > 2))
                                                                                && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Add the Company Info: URLs of your business, support and contact points</span>
                                                                            }
                                                                            {!(this.props.defaultSet?.programLogo?.length > 2)
                                                                                && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Add your brand icon and logo</span>
                                                                            }
                                                                            {(!(this.props.defaultSet?.programColorButton?.length > 2) || !(this.props.defaultSet?.programColorInfo?.length > 2))
                                                                                && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Add your primary and secondary brand color</span>
                                                                            }
                                                                        </>
                                                                }
                                                                {previewAvailable 
                                                                ?
                                                                    <div style={{
                                                                            marginTop: 20, marginBottom: 20, borderStyle: 'solid', borderWidth: 'thin', borderRadius: theme.borderRadius, marginLeft:0, padding: 20, backgroundColor: theme.palette.backPaper, width:"100%",
                                                                            backgroundImage: (theme?.palette?.themeAltBGImage?.includes("http") ? `url(${theme.palette?.themeAltBGImage})` : theme.palette.themeBlue !== theme.palette.themeAltBlue ? 'linear-gradient(180deg, '+theme.palette.backTertiary+' 40px, '+theme.palette.themeAltBlue+'52 50%, '+theme.palette.backTertiary+' calc(100vh - 40px))' : `url(${backgroundImageSignup})`),
                                                                            backgroundPosition: "center",
                                                                            backgroundSize: "cover",
                                                                            backgroundRepeat: "no-repeat"
                                                                        }}
                                                                    >
                                                                        {
                                                                            RenderFullBrandPreview(this, this.props.defaultSet)
                                                                        }
                                                                    </div>
                                                                :
                                                                    <div style={{
                                                                            marginTop: 20, marginBottom: 20, borderStyle: 'solid', borderWidth: 'thin', borderRadius: theme.borderRadius, marginLeft:0, padding: 20, backgroundColor: theme.palette.backPaper, width:"100%",
                                                                            
                                                                        }}
                                                                    >
                                                                        {
                                                                            this.renderSubDomain()
                                                                        }
                                                                    </div>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    {(value==='Brand Settings' || this.state['Brand Settings']) &&
                                                        <SnackbarInfo 
                                                            alertText = 'You can modify and add your content here.'
                                                            alertName = 'Brand Settings'
                                                            userId = {this.context.uid}
                                                            />
                                                    }
                                                </div>
                                                <div value={"Experience Settings"} index={0} hidden={value !== "Experience Settings"}>
                                                    {(value==='Experience Settings' || this.state['Experience Settings']) 
                                                    &&
                                                        <Grid container direction="row">
                                                            <Grid item xs={6}>
                                                                <h6>Experience Settings</h6>
                                                                <div style={{marginTop: 20, marginBottom: 20, borderStyle: 'solid', borderWidth: 'thin', borderRadius: theme.borderRadius, padding: 20, width: "calc(100% - 20px)"}}>
                                                                    {this.context.programCode?.length > 2 && this.context.adminPrograms?.includes(this.context.programCode)
                                                                        ?
                                                                            <CoachGroupSetting 
                                                                                defaultSet={this.props.defaultSet || undefined}
                                                                                groupId={this.context.programCode}
                                                                                handleClose={this.props.handleClose}
                                                                                newGroup={this.props.newGroup}
                                                                                partnerProgram={true}
                                                                                onlyDefaultUser={true}
                                                                                resultCode={(code) => {this.setState({resultCode: code})}}
                                                                                consultantsList={this.props.consultantsList}
                                                                                leadershipCategories={leadershipCategories}
                                                                                leadershipQualities={leadershipQualities}
                                                                                user={user}
                                                                                refreshQualities = {this.props.refreshQualities || undefined}
                                                                                refreshCategories = {this.props.refreshCategories || undefined}
                                                                                refreshDefaultSet={this.props.refreshDefaultSet || undefined}
                                                                            />
                                                                        :   
                                                                            <>
                                                                                <CoachGroupSetting 
                                                                                    //// ONLY IF THEY NOW THE CODE
                                                                                    //// defaultSet={this.props.defaultSet || undefined}
                                                                                    //// groupId={this.context.programCode}
                                                                                    allowInherit={false}
                                                                                    handleClose={this.props.handleClose}
                                                                                    newGroup={true}
                                                                                    partnerProgram={true}
                                                                                    resultCode={(code) => {this.setState({resultCode: code})}}
                                                                                    consultantsList={this.props.consultantsList}
                                                                                    leadershipCategories={leadershipCategories}
                                                                                    leadershipQualities={leadershipQualities}
                                                                                    user={user}
                                                                                    refreshQualities = {this.props.refreshQualities || undefined}
                                                                                    refreshCategories = {this.props.refreshCategories || undefined}
                                                                                    refreshDefaultSet={this.props.refreshDefaultSet || undefined}
                                                                                />
                                                                            </>
                                                                    }
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <h6>Product Access</h6>
                                                                {(!(this.props.defaultSet?.programCode?.length > 0 && this.context.adminPrograms?.includes(this.props.defaultSet.programCode)))
                                                                    ?   <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Add your Domain-Code</span>
                                                                    :
                                                                        <>
                                                                            {(!(this.props.defaultSet?.programURL?.length > 2))
                                                                                && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Activate your Web-App address</span>
                                                                            }
                                                                            {(!(this.props.defaultSet?.programMinPrograms?.length > 0) || !(this.props.defaultSet?.programMinPrograms?.includes(this.context.programCode)))
                                                                                && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Add your domain-code into the catalogue</span>
                                                                            }
                                                                            {!(this.props.defaultSet?.programAvatarName?.length > 2)
                                                                                && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Give your AI coach an 'Avatar Name'</span>
                                                                            }
                                                                            {!(this.props.defaultSet?.programLogo?.length > 2)
                                                                                && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Add your brand icon and logo</span>
                                                                            }
                                                                            {(!(this.props.defaultSet?.programColorButton?.length > 2) || !(this.props.defaultSet?.programColorInfo?.length > 2))
                                                                                && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Add your primary and secondary brand color</span>
                                                                            }
                                                                        </>
                                                                }
                                                                <SnackbarInfo 
                                                                    alertText = {this.state.alertText || ""}
                                                                    openOnClick = {true}
                                                                    alertOpenEvent = {this.state.openAlert || false}
                                                                    closeHandler = {() => {this.setState({openAlert: false})}}
                                                                />
                                                                {this.props.defaultSet?.programURL?.includes("http") && (this.context.purchasedProduct?.owned && this.context.purchasedProduct?.creator )
                                                                &&
                                                                    <div style={{
                                                                            marginTop: 20, 
                                                                            marginBottom: 20, 
                                                                            marginLeft: 0, 
                                                                            borderStyle: 'solid', 
                                                                            borderWidth: 'thin', 
                                                                            color: theme.palette.white, 
                                                                            borderRadius: theme.borderRadius, 
                                                                            padding: 20, width: "calc(100% - 20px)",
                                                                            backgroundColor: theme.palette.secondary.light
                                                                        }}>
                                                                            <h6>Your exclusive Web-App address:</h6>
                                                                            <br/><br/>
                                                                            <LinkIcon style={theme.inlineIconLeft}/>
                                                                            <strong>{this.props.defaultSet.programURL}</strong>
                                                                            &nbsp;
                                                                            <FileCopyIcon 
                                                                                onClick={() => {
                                                                                    var textURL = "https://" + this.context.programCode?.toLowerCase().replace("#", "") + "." + (this.state.preferredDomain || "my-ai.coach")
                                                                                    navigator?.clipboard?.writeText( textURL )
                                                                                    this.setState({openAlert: true, alertText: "Copied: " + textURL})
                                                                                }}
                                                                                fontSize="small" style={{...theme.inlineIconRight, color: 'white'}} 
                                                                            />
                                                                    </div>
                                                                }
                                                                <div style={{marginTop: 20, marginBottom: 20, marginLeft: 0, borderStyle: 'solid', borderWidth: 'thin', backgroundColor:  this.state.domainRequested ? theme.palette.themeAlertOrange : 'transparent', borderRadius: theme.borderRadius, padding: 20, width: "calc(100% - 20px)"}}>
                                                                    {this.props.defaultSet?.programURL?.includes("http") && !(this.props.defaultSet?.programURL?.includes("my-ai.coach") || this.props.defaultSet?.programURL?.includes("mydaily.coach"))
                                                                    ?   ""
                                                                    :   (this.context.programCode?.length > 2 && this.context.adminPrograms?.includes(this.context.programCode))
                                                                        ?
                                                                            <div>
                                                                                {(this.context.roles?.includes("COACHADMIN") || this.context.roles?.includes("TEAM") || this.context.roles?.includes("ADMIN") )
                                                                                &&
                                                                                    <>
                                                                                        <p><strong>Your exclusive Web-App address:</strong></p>
                                                                                        {true
                                                                                            ? 
                                                                                                ExternalHelpLink(theme, "Add your domain", "https://help.rocky.ai/rocky-for-creators/add-your-own-brand-set-up-domain-code", 'Help Domain')
                                                                                            :   ""
                                                                                        }
                                                                                        <span style={theme.textSupport}>If you have a subscription, you can activate your own exclusive web-address and use it in your automatic emails.</span>
                                                                                        <br/><br/>
                                                                                        <LinkIcon style={theme.inlineIconLeft}/>
                                                                                        https://<strong>{this.context.programCode?.toLowerCase().replace("#", "")}.</strong>
                                                                                        <Select
                                                                                            value={this.state.preferredDomain || 'my-ai.coach'}
                                                                                            onChange={(event) => this.setState({preferredDomain: event.target.value})}
                                                                                        >
                                                                                            <MenuItem value={'mydaily.coach'}
                                                                                            >
                                                                                                mydaily.coach
                                                                                            </MenuItem>
                                                                                            <MenuItem  value={'my-ai.coach'}
                                                                                            >
                                                                                                my-ai.coach
                                                                                            </MenuItem>
                                                                                            {/***
                                                                                            <MenuItem  value={'mayday.coach'}
                                                                                            >
                                                                                                mayday.coach
                                                                                            </MenuItem>
                                                                                            <MenuItem value={'aicoach.ing'}
                                                                                            >
                                                                                                aicoach.ing
                                                                                            </MenuItem>
                                                                                                */}
                                                                                        </Select>
                                                                                        &nbsp;
                                                                                        {!(this.context.purchasedProduct?.owned && this.context.purchasedProduct?.creator)
                                                                                            ?
                                                                                                <Button
                                                                                                    onClick={() => {
                                                                                                        var textURL = "https://" + this.context.programCode?.toLowerCase().replace("#", "") + "." + (this.state.preferredDomain || 'my-ai.coach')
                                                                                                        this.props.firebase.group(this.context.programCode).update({programURL: textURL})
                                                                                                        this.setState({openAlert: true, alertText: "Your exclusive domain requires you to own a subscription!"})
                                                                                                    }}
                                                                                                >
                                                                                                    Activate domain
                                                                                                </Button>
                                                                                            :
                                                                                                <Button
                                                                                                    onClick={() => {
                                                                                                        var textURL = "https://" + this.context.programCode?.toLowerCase().replace("#", "") + "." + (this.state.preferredDomain || 'my-ai.coach')
                                                                                                        navigator?.clipboard?.writeText( textURL )
                                                                                                        this.setState({openAlert: true, alertText: "Copied and activated: " + textURL})
                                                                                                        this.props.firebase.group(this.context.programCode).update({programURL: textURL})
                                                                                                        .then(() => {
                                                                                                            if(this.props.refreshDefaultSet !== undefined){
                                                                                                                var defaultContent = this.props.defaultSet || {};
                                                                                                                defaultContent['programURL'] = textURL;
                                                                                                                this.props.refreshDefaultSet(defaultContent, true);
                                                                                                            } 
                                                                                                        })
                                                                                                    }}
                                                                                                >
                                                                                                    Activate domain
                                                                                                </Button>
                                                                                        }
                                                                                        <br/><br/>
                                                                                        {this.state.domainRequested 
                                                                                        ?
                                                                                            'Request is sent! Please wait 24h for the activation.'
                                                                                        :
                                                                                            true
                                                                                            ?   ""
                                                                                            :
                                                                                                <Button 
                                                                                                    variant="outlined" color="secondary"
                                                                                                    disabled={!(this.context.purchasedProduct?.owned && this.context.purchasedProduct?.creator) ? true : false}
                                                                                                    onClick={() => {
                                                                                                        var message = "Please activate https://" + this.context.programCode?.toLowerCase().replace("#", "") + "." + (this.state.preferredDomain || 'my-ai.coach') + " as our AI coaching domain.";
                                                                                                        AddConversation(this, this.context.uid, 'default', 'default', undefined, message, "Request exclusive domain");
                                                                                                        this.setState({domainRequested: true});
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        Request Domain
                                                                                                    </span>
                                                                                                </Button>
                                                                                        }
                                                                                    </>
                                                                                }
                                                                                {(!(this.props.defaultSet?.programURL?.includes("http")) || !(this.context.purchasedProduct?.owned && this.context.purchasedProduct?.creator ))
                                                                                &&
                                                                                    <>
                                                                                        <p><strong>Your Affiliate-Link:</strong></p>
                                                                                        <span style={theme.textSupport}>If you currently don't have a subscription, you can use the following affiliate link to connect with your potential clients in which the clients have to subscribe to Rocky.ai.</span>
                                                                                        <br/><br/>
                                                                                        {
                                                                                        //["app.rocky.ai", "app.mydaily.coach", "app.my-ai.coach"]
                                                                                        ["app.rocky.ai"].map((item, index) => (
                                                                                            <div key={index}
                                                                                                onClick={() => {
                                                                                                    var textURL = "https://"+item+"/#/?coachcode="+this.context.programCode?.toLowerCase().replace("#", "")
                                                                                                    navigator?.clipboard?.writeText( textURL )
                                                                                                    this.setState({openAlert: true, alertText: "Copied: " + textURL})
                                                                                                }}
                                                                                            >
                                                                                                <LinkIcon style={theme.inlineIconLeft}/>
                                                                                                https://{item}/#/?coachcode=<strong>{this.context.programCode?.toLowerCase().replace("#", "")}</strong>
                                                                                                &nbsp; 
                                                                                                <FileCopyIcon fontSize="small" style={{...theme.inlineIconRight, color: theme.palette.secondary.main}} />
                                                                                            </div>
                                                                                        ))}
                                                                                        <br/><br/>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        :
                                                                            <div>
                                                                                {(this.context.roles?.includes("COACHADMIN") || this.context.roles?.includes("TEAM") || this.context.roles?.includes("ADMIN") )
                                                                                ?
                                                                                    <>
                                                                                        {this.renderSubDomain()}
                                                                                    </>
                                                                                :
                                                                                    <>
                                                                                        <strong>Your Affiliate-Links:</strong>
                                                                                        <br/><br/>
                                                                                        {["app.rocky.ai", "app.mydaily.coach", "app.my-ai.coach"].map((item, index) => (
                                                                                            <div key={index}>
                                                                                                <PublicIcon fontSize="small" style={{color: theme.palette.secondary.main}} />
                                                                                                &nbsp; https://{item}/#/?coachcode=<strong>YOURDOMAINCODE</strong>
                                                                                            </div>
                                                                                        ))}
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                    }
                                                                </div>

                                                                        
                                                                {!(this.context.purchasedProduct?.owned && this.context.purchasedProduct?.creator)
                                                                    &&
                                                                        <div style={{margin: 20, textAlign: 'center', maxWidth: 500}}>
                                                                            <BadgeCard
                                                                                key={"creator"}
                                                                                backgroundColor= {"white"}
                                                                                image={undefined} 
                                                                                avatarImage = {<ShoppingCartIcon fontSize="large" style={{color: theme.palette.themeAlertOrangee}}/>}
                                                                                title = {"Get your exclusive domain"}
                                                                                subtitle = {"Subscribe to the business packages"}
                                                                                strongSubtitle = {false}
                                                                                onClick={ () => this.setState({DialogSettingPlanOpen: true})}  
                                                                                clickText= {'Subscribe'}
                                                                            />
                                                                        </div>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    {(value==='Experience Settings' || this.state['Experience Settings']) &&
                                                        <SnackbarInfo 
                                                            alertText = 'You can modify and add your content here.'
                                                            alertName = 'Experience Settings'
                                                            userId = {this.context.uid}
                                                            />
                                                    }
                                                </div>
                                                <div value={"Shop Settings"} index={0} hidden={value !== "Shop Settings"}>
                                                    {!(this.props.defaultSet?.programCode?.length > 3 && this.context.adminPrograms?.includes(this.props.defaultSet.programCode))
                                                        ?   
                                                            <div style={{paddingTop: 20, paddingBottom: 20}}>
                                                                <p style={{color: theme.palette.themeAlertRed}}>No 'Domain-Code' assigned yet!</p>
                                                                Please add your brand first, then you can add your own Shop.
                                                                <br/>
                                                                <br/>
                                                                Go to the: &nbsp;
                                                                <Link to={ROUTES.COACH_ADMIN} onClick={() => this.handleChange(undefined, "Brand Settings")} style={{color : theme.palette.secondary.main }}>
                                                                    App Settings
                                                                </Link>
                                                            </div>
                                                        :
                                                            <>
                                                                {(value==='Shop Settings' || this.state['Shop Settings']) &&
                                                                    <Grid container direction="row">
                                                                        <Grid item xs={6}>
                                                                            <h6>Shop Settings</h6>
                                                                            <div style={{
                                                                                marginBottom: 20, 
                                                                                marginTop: 20, 
                                                                                maxWidth: 500, 
                                                                                borderStyle: 'solid', 
                                                                                borderWidth: 'thin', 
                                                                                borderRadius: theme.borderRadius, 
                                                                                padding: 20, 
                                                                                width: "calc(100% - 20px)"
                                                                            }}>
                                                                                <p>Your product offer</p>
                                                                                {true
                                                                                    ? 
                                                                                        ExternalHelpLink(theme, "Connect to Stripe", "https://help.rocky.ai/rocky-for-creators/add-stripe-for-your-subscribers", 'Help Stripe')
                                                                                    :   ""
                                                                                }
                                                                                {!(AccessCheckCoachCreator(this.context))
                                                                                ?   
                                                                                    <span>
                                                                                        You have no access-rights to change this setting
                                                                                        <br/><br/><strong>{this.props.defaultSet?.programShopURL || "n/a"}</strong>
                                                                                    </span>
                                                                                :
                                                                                    this.context.adminPrograms?.length > 0
                                                                                        ?
                                                                                            <>
                                                                                                <SettingContentSelect
                                                                                                    title={"Select Shop"} 
                                                                                                    mandatory={true}
                                                                                                    noLabel={true}
                                                                                                    content={{shopType: 
                                                                                                        this.props.defaultSet?.programShopURL?.includes('stripe.')
                                                                                                        ?   'stripe'
                                                                                                        :   this.props.defaultSet?.programShopURL?.includes('http')
                                                                                                            ?   this.props.defaultSet?.programShopURL === this.props.defaultSet?.programBookingURL
                                                                                                                ?   'meeting'
                                                                                                                :   'website'
                                                                                                            :   'rocky'
                                                                                                        
                                                                                                    }} 
                                                                                                    params={['shopType']}
                                                                                                    selectAtLeastOne={true}
                                                                                                    selectOptions={['stripe', 'website', 'meeting', 'rocky']}
                                                                                                    selectOptionsText={['Automated via Stripe', 'Manually via my support URL', 'Manually via my meeting link', 'Let Rocky.ai charge them']}
                                                                                                    updateState={(resultList) => {
                                                                                                        //console.log('resultList', resultList)
                                                                                                        if(resultList?.shopType?.length > 0){
                                                                                                            var shopType = resultList.shopType
                                                                                                            var defaultContent = this.props.defaultSet || {};
                                                                                                            if(resultList.shopType === "stripe"){
                                                                                                                if (!this.props.defaultSet?.programCustomerPortal?.includes('stripe.')){
                                                                                                                    this.setState({errorShopSettings: "Please add your Stripe Customer Portal Link that includes stripe.com"})
                                                                                                                }
                                                                                                                else{
                                                                                                                    this.props.firebase.group(this.props.defaultSet.programCode).update({programShopURL: this.props.defaultSet.programCustomerPortal})
                                                                                                                    defaultContent['programShopURL'] = this.props.defaultSet.programCustomerPortal
                                                                                                                    this.props.refreshDefaultSet(defaultContent, true);
                                                                                                                    this.setState({errorShopSettings: undefined})
                                                                                                                }
                                                                                                            }
                                                                                                            else if(resultList.shopType === "website"){
                                                                                                                if (!this.props.defaultSet?.programSupportURL?.includes("http")){
                                                                                                                    this.setState({errorShopSettings: "Please add your Support Website"})
                                                                                                                }
                                                                                                                else{
                                                                                                                    this.props.firebase.group(this.props.defaultSet.programCode).update({programShopURL: this.props.defaultSet.programSupportURL})
                                                                                                                    defaultContent['programShopURL'] = this.props.defaultSet.programSupportURL
                                                                                                                    this.props.refreshDefaultSet(defaultContent, true);
                                                                                                                    this.setState({errorShopSettings: undefined})
                                                                                                                }
                                                                                                            }
                                                                                                            else if(resultList.shopType === "meeting"){
                                                                                                                if (!this.props.defaultSet?.programBookingURL?.includes("http")){
                                                                                                                    this.setState({errorShopSettings: "Please add your Meeting Link"})
                                                                                                                }
                                                                                                                else{
                                                                                                                    this.props.firebase.group(this.props.defaultSet.programCode).update({programShopURL: this.props.defaultSet.programBookingURL})
                                                                                                                    defaultContent['programShopURL'] = this.props.defaultSet.programBookingURL
                                                                                                                    this.props.refreshDefaultSet(defaultContent, true);
                                                                                                                    this.setState({errorShopSettings: undefined})
                                                                                                                }
                                                                                                            }
                                                                                                            else if(resultList.shopType === "rocky"){
                                                                                                                if('programShopURL' in defaultContent){
                                                                                                                    delete defaultContent['programShopURL']
                                                                                                                }
                                                                                                                this.props.firebase.group(this.props.defaultSet.programCode).update({programShopURL: this.props.firebase.fieldValue.delete()})
                                                                                                                this.props.refreshDefaultSet(defaultContent, true);
                                                                                                                this.setState({errorShopSettings: undefined})
                                                                                                            }
                                                                                                            //console.log('resultList', resultList, shopType)
                                                                                                            //programNewCode = programNewCode.replace("#", "")
                                                                                                            //this.props.firebase.documentIdByCollection('shopArticles', this.state.cid).update({groupId: programNewCode})
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                                {this.state.errorShopSettings?.length > 0 
                                                                                                &&
                                                                                                    <p style={{textAlign: 'center', color: 'red'}}>{this.state.errorShopSettings}</p>
                                                                                                }
                                                                                                <SettingContentBase
                                                                                                    dbUpdateLink={this.props.firebase.group(this.props.defaultSet.programCode)}
                                                                                                    title={"Customer support"}
                                                                                                    mandatory={true}
                                                                                                    checkURLFormat={true}
                                                                                                    content={this.props.defaultSet || {}}
                                                                                                    labels={["Business website", "Contact web address", "Meeting and booking link", "Privacy Info URL", "Terms & Conditions Info URL", ]}
                                                                                                    params={['programCompanyURL', 'programSupportURL', 'programBookingURL', 'programPrivacyURL', 'programTermsURL']}
                                                                                                    infoText={["Your business URL", "Your contact info e.g https://www.rocky.ai/customersupport)", "e.g. Calendly where clients can book a meeting", "A link with your privacy info", "A link with your terms and conditions"]}
                                                                                                    updateState={(resultDict) => {
                                                                                                        if(this.props.refreshDefaultSet !== undefined){
                                                                                                            var defaultContent = this.props.defaultSet || {};
                                                                                                            if(resultDict?.programBookingURL?.includes("http")){
                                                                                                                defaultContent['programBookingURL'] = resultDict['programBookingURL'];
                                                                                                            }
                                                                                                            if(resultDict?.programSupportURL?.includes("http")){
                                                                                                                defaultContent['programSupportURL'] = resultDict['programSupportURL'];
                                                                                                            }
                                                                                                            if(resultDict?.programCustomerPortal?.includes("http")){
                                                                                                                defaultContent['programCustomerPortal'] = resultDict['programCustomerPortal'];
                                                                                                            }
                                                                                                            this.props.refreshDefaultSet(defaultContent, true);
                                                                                                        } 
                                                                                                    }}
                                                                                                />
                                                                                                {false 
                                                                                                &&
                                                                                                    <SettingContentBase
                                                                                                        dbUpdateLink={this.props.firebase.group(this.props.defaultSet.programCode)}
                                                                                                        title={"Customer support"}
                                                                                                        access={false}
                                                                                                        checkURLFormat={true}
                                                                                                        content={this.props.defaultSet}
                                                                                                        p
                                                                                                        arams={['programShopURL']}
                                                                                                    />
                                                                                                }
                                                                                                <h4>Integrate Stripe</h4>
                                                                                                <span>A Stripe integration is available for the White-Label version of Rocky.ai</span>
                                                                                                {true
                                                                                                &&
                                                                                                    <ManageShop
                                                                                                        refreshDefaultSet={this.props.refreshDefaultSet}
                                                                                                        defaultSet={this.props.defaultSet}
                                                                                                    />
                                                                                                }
                                                                                            </>
                                                                                        :
                                                                                            <div style={{paddingTop: 20}}>
                                                                                                <p style={{color: theme.palette.themeAlertRed}}>No 'Domain-Code' assigned yet!</p>
                                                                                                Please add your brand first, then you can add your own Programs and Quests.
                                                                                                <br/>
                                                                                                <br/>
                                                                                                Go to the: &nbsp;
                                                                                                    <Link to={ROUTES.COACH_ADMIN} onClick={() => this.handleChange(undefined, "Experience Settings")} style={{color : theme.palette.secondary.main }}>
                                                                                                        App Settings
                                                                                                    </Link>
                                                                                            </div>
                                                                                            
                                                                                }
                                                                                <br/>
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <div>
                                                                                <h6>Customer Shop</h6>
                                                                                {(!(this.props.defaultSet?.programCode?.length > 2))
                                                                                    ?   <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Add your Domain-Code</span>
                                                                                    :   <>
                                                                                            {(!(this.props.defaultSet?.programShopURL?.length > 2))
                                                                                                && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Activate your own Shop (Currently showing only Rocky.ai options)</span>
                                                                                            }
                                                                                            {(!(this.props.defaultSet?.programCustomerPortal?.length > 0) )
                                                                                                && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Add your Stripe Customer Portal Link</span>
                                                                                            }
                                                                                            {(!(this.props.defaultSet?.programSupportURL?.length > 2))
                                                                                                && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Add the URLs of your support and contact points</span>
                                                                                            }
                                                                                        </>
                                                                                }
                                                                                <br/>
                                                                                <br/>
                                                                                <h6>Live Shop
                                                                                    &nbsp;<span style={theme.textSupport}>{this.props.defaultSet.programCode || "Please add domain-code"}</span>
                                                                                </h6>
                                                                                <div style={{width: '100%', marginTop: 20, marginBottom: 20}}>
                                                                                    {this.props.defaultSet?.programShopURL?.includes('http') 
                                                                                        ?   this.props.defaultSet?.programShopURL?.includes('stripe.')
                                                                                            ?   <SettingSubscriptionStripe
                                                                                                    key={'strip'}
                                                                                                    coach={false}
                                                                                                    forceUser={true}
                                                                                                    realTime={true}
                                                                                                    sandbox={false}
                                                                                                    defaultSet={this.props.defaultSet}
                                                                                                    coachCode={this.props.defaultSet.programCode || 'NOT'}
                                                                                                    userId={this.context.uid}
                                                                                                    fallbackURL={this.props.defaultSet?.programShopURL?.replace("{{uid}}", this.context.uid)?.replace("{{email}}", this.context.email)}
                                                                                                    user={this.context}
                                                                                                    //premiumOnly={this.props.premiumOnly}
                                                                                                    showTerms={this.props.showTerms? true: false}
                                                                                                />
                                                                                            :   <>
                                                                                                    <span style={theme.textSupport}>Get access to our services:</span>
                                                                                                    <br/><br/>
                                                                                                    <Button onClick={() => {window.open(this.props.defaultSet?.programShopURL?.replace("{{uid}}", this.context.uid).replace("{{email}}", this.context.email), "_blank")}}>
                                                                                                        Open {this.props.defaultSet.programAvatarName} Shop
                                                                                                    </Button>
                                                                                                </>
                                                                                        :
                                                                                            <SettingSubscriptionStripe
                                                                                                coach={false}
                                                                                                forceUser={true}
                                                                                                realTime={true}
                                                                                                sandbox={false}
                                                                                                defaultSet={this.props.defaultSet}
                                                                                                coachCode={'ROCKY'}
                                                                                                userId={this.context.uid}
                                                                                                user={this.context}
                                                                                                //premiumOnly={this.props.premiumOnly}
                                                                                                showTerms={this.props.showTerms? true: false}
                                                                                            />
                                                                                    }
                                                                                </div>
                                                                                {this.props.defaultSet?.programShopURL?.includes('stripe.') 
                                                                                ?
                                                                                    <>
                                                                                        <h6>Test Mode
                                                                                            &nbsp;<span style={theme.textSupport}>{this.props.defaultSet.programCode || "Please add domain-code"}</span>
                                                                                        </h6>
                                                                                        <div style={{width: '100%', marginTop: 20}}>
                                                                                            <SettingSubscriptionStripe
                                                                                                coach={false}
                                                                                                forceUser={true}
                                                                                                sandbox={true}
                                                                                                realTime={true}
                                                                                                defaultSet={this.props.defaultSet}
                                                                                                coachCode={this.props.defaultSet?.programShopURL?.includes('http') ? this.props.defaultSet?.programShopURL?.includes('stripe.') ? this.props.defaultSet.programCode  : 'NOT' : 'ROCKY'}
                                                                                                userId={this.context.uid}
                                                                                                fallbackURL={this.props.defaultSet?.programShopURL?.replace("{{uid}}", this.context.uid)?.replace("{{email}}", this.context.email)}
                                                                                                user={this.context}
                                                                                                //premiumOnly={this.props.premiumOnly}
                                                                                                showTerms={false}
                                                                                            />
                                                                                        </div>
                                                                                    </>
                                                                                :   false && this.context.roles?.includes("COACHADMIN")
                                                                                    ?
                                                                                        <SettingSubscriptionStripe
                                                                                            coach={true}
                                                                                            realTime={true}
                                                                                            sandbox={false}
                                                                                            coachCode={'NEWCOACHCREATOR'}
                                                                                            userId={this.context.uid}
                                                                                            user={this.context}
                                                                                            //premiumOnly={this.props.premiumOnly}
                                                                                            showTerms={this.props.showTerms? true: false}
                                                                                        />
                                                                                    :   ''
                                                                                }
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                            </>
                                                    }
                                                </div>
                                                <div value={"Account Settings"} index={0} hidden={value !== "Account Settings"}>
                                                    {(value==='Account Settings' || this.state['Account Settings']) &&
                                                        <Grid container direction="row">
                                                            <Grid item xs={6}>
                                                                Personal Settings
                                                                <div style={{
                                                                    marginBottom: 20, 
                                                                    marginTop: 20, 
                                                                    maxWidth: 500, 
                                                                    borderStyle: 'solid', 
                                                                    borderWidth: 'thin', 
                                                                    borderRadius: theme.borderRadius, 
                                                                    padding: 20, 
                                                                    width: "calc(100% - 20px)"
                                                                }}>
                                                                    <HomeProfile
                                                                        showFutureSelf={false}
                                                                        showSubscriptions={false}
                                                                        coachAccount={true}
                                                                        defaultSet={this.props.defaultSet}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                Subscription Settings
                                                                <div style={{
                                                                    marginBottom: 20, 
                                                                    marginTop: 20, 
                                                                    maxWidth: 500, 
                                                                    borderStyle: 'solid', 
                                                                    borderWidth: 'thin', 
                                                                    borderRadius: theme.borderRadius, 
                                                                    padding: 20, 
                                                                    width: "calc(100% - 20px)"
                                                                }}>
                                                                    <SettingPlan 
                                                                        user={this.context}
                                                                        defaultSet={this.props.defaultSet || undefined}
                                                                        coach={true}
                                                                        showTerms={true}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    {(value==='Brand Settings' || this.state['Brand Settings']) &&
                                                        <SnackbarInfo 
                                                            alertText = 'You can modify and add your content here.'
                                                            alertName = 'Brand Settings'
                                                            userId = {this.context.uid}
                                                            />
                                                    }
                                                </div>
                                            </div>
                                        :
                                            <div>
                                                <p >We have a problem with your page access!</p>
                                            </div>
                                        }
                                    </Card>
                                    <Grid item xs={12}>
                                        <Button 
                                            onClick={() => {
                                                window.open("https://help.rocky.ai/rocky-for-creators", "_blank")
                                            }}
                                        >
                                            Detailed Help
                                        </Button>
                                        {false &&
                                        <Button
                                            variant="outlined" color="secondary"
                                            onClick={() => {this.setState({showTutorial : !(this.state.showTutorial) })}}
                                        >
                                            <span>
                                                <HelpOutlineIcon style={theme.inlineIconLeft}/>
                                                {this.state.showTutorial ? "Hide Tutorial" : "Show Tutorial"}
                                            </span>
                                        </Button>
                                        }
                                        {this.state.showTutorial
                                        &&
                                            <div style={{marginTop: 20, marginBottom: 20, borderStyle: 'solid', borderWidth: 'thin', borderRadius: theme.borderRadius, marginLeft:0, padding: 20, borderColor: theme.palette.secondary.light, backgroundColor: theme.palette.backPaper, width:"100%"}}>
                                                <TutorialPage 
                                                    showDesktop={true}
                                                    showStepper={false}
                                                    addDefaults={true}
                                                    badgeFamily={"teams"} 
                                                    handleClose={undefined}
                                                    handleScrollTop={() => { if(this.dialogRef!==undefined  && this.dialogRef!==null &&  this.dialogRef.scrollTop!==undefined ) this.dialogRef.scrollTop = 0}}
                                                />
                                            </div>
                                        }
                                    </Grid>
                                </Card> 
                        }
                    </Grid>
                </Grid>
            ); // end return
        }// end if user
        else {
            return(
                <Card style={theme.CardContent} ref={this.dialogRef}>
                    <p style={theme.CardContent.titleText}>Please wait a moment</p>
                </Card>
            )// end 2nd return
        } // end else
    }// end render

    renderTabs(orientation = "horizontal") {
        const { value } = this.state;
        const { user, leadershipQualities, leadershipCategories } = this.props;
        const { theme } = this.props;
        if(orientation === 'vertical'){
            return(
                <>
                    <Link to={ROUTES.HOME}>
                        <span><HomeIcon style={theme.inlineIconLeft}/>Home</span>
                    </Link>
                    <hr/>
                    <Link to={ROUTES.COACH_ADMIN} onClick={() => (this.handleChange(undefined, "Experience Settings" ))}>
                        <span><BuildIcon style={theme.inlineIconLeft}/>App Settings</span>
                    </Link>
                    {this.state.selectedCategory?.length > 2 
                    ?
                        <Link to={ROUTES.COACH_CONTENT} onClick={() => {
                            this.setState({selectedCategory: undefined, selectedQuality: undefined})
                            this.handleChange(undefined, value)
                        }}
                        >
                            <li style={{paddingTop: 8, paddingLeft: 5, whiteSpace: 'nowrap'}}> {value} </li>
                        </Link>
                    : 
                        <li style={{paddingTop: 8, paddingLeft: 5, whiteSpace: 'nowrap'}}> {value} </li>
                    }
                    <hr/>
                    <Link to={ROUTES.COACH_CONTENT}>
                        <span><ImportantDevicesIcon style={theme.inlineIconLeft}/>Creator Tool</span>
                    </Link>
                    <hr/>
                    <Link to={ROUTES.COACH}>
                        <span><RecordVoiceOverIcon style={theme.inlineIconLeft}/>Coach Panel</span>
                    </Link>
                </>
            )
        }
        else{ 
            return(
            <div style={{textAlign: "center"}}>
                {user !== null && leadershipQualities!== undefined && leadershipQualities.length > 0
                &&
                    <Tabs
                        value={value}
                        orientation={orientation}
                        onChange={this.handleChange}
                        aria-label="Coach Content"
                        variant="scrollable"
                        scrollButtons="auto"
                        initialselectedindex={'Articles & Videos'}
                        style={{
                            marginTop: 20,
                            marginBottom: 0,
                            marginLeft: orientation === 'vertical' ? -20 : 0,
                            width: 'calc(100% + 40px)'
                        }}
                        ref={el => this.containerLine = el}
                    >
                        {(this.context.roles?.includes("COACHADMIN") || this.context.roles?.includes("ADMIN") || this.context?.purchasedProduct?.creator === true )
                            &&
                            <Tab value={"Brand Settings"} label={<span><ColorLensIcon style={theme.inlineIconLeft} />Brand Settings</span>}/>
                        }
                        <Tab value={"Experience Settings"} label={<span><LaptopMacIcon style={theme.inlineIconLeft} />Experience Settings</span>}/>
                        <Tab value={"Shop Settings"} label={<span><AddShoppingCartIcon style={theme.inlineIconLeft} />Shop Settings</span>}/>
                        <Tab value={"Account Settings"} label={<span><AccountCircleIcon style={theme.inlineIconLeft} />Account Settings</span>}/>
                    </Tabs>    
                } 
            </div>
            )
        }
    }

    renderSubDomain(){
        const {theme} = this.props;
        return(
                <>
                Get your exclusive Web-App address:
                {true
                    ? 
                        ExternalHelpLink(theme, "Add your domain", "https://help.rocky.ai/rocky-for-creators/add-your-own-brand-set-up-domain-code", 'Help Domain')
                    :   ""
                }
                <br/><br/>
                <LinkIcon fontSize="small"/>
                &nbsp; 
                https://<strong style={{color: theme.palette.themeAlertRed}}>{this.state.resultCode?.length > 5 ? this.state.resultCode?.toLowerCase().replace("#", "") : "yourdomaincode"}.</strong>
                <Select
                    value={this.state.preferredDomain || 'my-ai.coach'}
                    onChange={(event) => this.setState({preferredDomain: event.target.value})}
                >
                    <MenuItem value={'mydaily.coach'}
                    >
                        mydaily.coach
                    </MenuItem>
                    <MenuItem value={'my-ai.coach'}
                    >
                        my-ai.coach
                    </MenuItem>
                    {/***
                    <MenuItem value={'rocky.ai'}
                    >
                        rocky.ai
                    </MenuItem>
                    <MenuItem  value={'mayday.coach'}
                    >
                        mayday.coach
                    </MenuItem>
                    <MenuItem value={'aicoach.ing'}
                    >
                        aicoach.ing
                    </MenuItem>
                        */}
                </Select>
                <br/><br/>
                <strong style={theme.textSuppor}>
                    You have to add your domain-code first!
                </strong>
                <br/><br/>
            </>
        )
    }
} // end class

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(ManageCoachAdminPage);
  
