import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter, Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { AuthUserContext } from '../Session';
import * as ROUTES from '../../constants/routes';
import { AddAnalytics } from '../Modules/AddAnalytics';
import {LoadUserCategoryAndQualities} from "../Modules/LoadUserData";
import ContentEngagementOverview from "../Content/ContentEngagementOverview";
import HomeIcon from '@material-ui/icons/Home';
import ContentHighlights from '../Content/ContentHighlights';
import HomeCardContentItems from './HomeCardContentItems';
import SnackbarInfo from '../Modules/SnackbarInfo';
import BadgeCard from '../Modules/BadgeCard';
import ContentQualitySwipe from '../Content/ContentQualitySwipe';
import JournalActivities from '../Days/JournalActivities';
import TopicSuggestions from '../Goals/TopicSuggestions';
import AvatarImage from '../Modules/AvatarImage';
/// Tutorial
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import DialogTutorialPage from '../Onboarding/DialogTutorialPage';
import Button from '@material-ui/core/Button';
import { withFirebase } from '../Firebase';
import { FindTextValueInDict } from '../Modules/LoadCategoriesAndQualities';
//import TabItemQuotes from '../Home/TabItemQuotes';
import DialogContentItem from '../Content/DialogContentItem';
import LoadingProgress from '../Modules/LoadingProgress';
import InfiniteContent from '../Content/InfiniteContent';

import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import ExploreIcon from '@material-ui/icons/Explore';
//import InfiniteScroll from "react-infinite-scroll-component";
//import ContentItemCard from '../Content/ContentItemCard';
//import Masonry from 'react-masonry-css';
///// Manage the navigation bar appear
import VizSensor from 'react-visibility-sensor';
import pixel from "../../images/pixel.png";

import {CheckXPLevel} from '../Charts/XPLevels';
import {CheckSubscriptionStateOwned} from '../Modules/CheckSubscriptionStatus';

import ContentSearchPanel from '../Content/ContentSearchPanel';

import WidgetsIcon from '@material-ui/icons/Widgets';
//import MenuBookIcon from '@material-ui/icons/MenuBook';
import Grid from '@material-ui/core/Grid';
//import badgeStart from "../../images/Badge_XP_congrats.png";
import DialogCardBadges from '../Charts/DialogCardBadges';
import StatsBar from '../Charts/StatsBar';

import Card from '@material-ui/core/Card';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { IsMobileDevice } from '../Modules/IsMobileDevice'
import {SetStateLoadingBatches} from '../Charts/LoadingBatches';
import QualityCard from '../Goals/QualityCard';
import { LoadStateEngagementStats } from '../Modules/LoadStateEngagementStats';

//import Button from '@material-ui/core/Button';

const styles = theme => ({
    masonry:{
        width: "100%",
        display: "-webkit-box",
        display: '-ms-flexbox',
        display: "flex",
        paddingRight: 10
    },
    masonryColumn :{
        paddingLeft: 10,
        backgroundClip: "padding-box",
    },
    grow: {
        flexGrow: 1,
    },
    highlightTab: {
        position: theme.highlightTab.position,
        left:  IsMobileDevice()? theme.HomeNoNavigationShiftContent.paddingLeft : theme.HomeNavigationShiftContent.paddingLeft ,
        top: IsMobileDevice()? theme.highlightTab.top : 60,
        backgroundColor:theme.highlightTab.backgroundColor, 
        zIndex:theme.highlightTab.zIndex,
    },
    unHighlightTab: {
        position: "flexible",
        //position: "fixed",
        //left:  0 ,
    },
});


class HomeFeed extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.dialogRef = React.createRef();
        this.containerLine = React.createRef();
        //console.log('props deliverd via children', props)
        //var startingTab = 'Discoveries';
        var startingTab = "Quests";
        if(this.props.user.contentLastNotification !== undefined && this.props.user.contentLastNotification !== ''){
            startingTab = 'Quests';
        }
        var contentBookmarked = [];
        if (this.props.user.contentBookmarked !== undefined && this.props.user.contentBookmarked.length >= 2) {
            //startingTab = 'Bookmarks';
            contentBookmarked = this.props.user.contentBookmarked;
        }
        this.state = {
            items: [],
            itemsLoaded: [],
            intentsLoaded: [],
            qualitiesLoaded: [],
            value: startingTab,
            initialMainQuality: '',
            loading: true,
            [startingTab] : true,
            ['Quests'] : true,
            scrolled: false,
            mainQualitiesIds: [],
            contentBookmarked: contentBookmarked,
            allQualitiesLoaded: [],
            headerVisible: true,
            searchKeyIndex: 1,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        var userGrowthPath = LoadUserCategoryAndQualities(this, this.context.uid, this.context, this.props.leadershipCategories, this.props.leadershipQualities)
        try{
            //document.body.scrollTop = document.documentElement.scrollTop = 0;
            if(this.dialogRef !== undefined ) {
                this.dialogRef.current.scrollIntoView({behaviour: 'smooth'});
                //this.dialogRef.scrollIntoView({behaviour: 'smooth'});
            }
        } catch  (err) {
            console.log('No scroll to top', err);
        }
        //console.log('Content Container mounted', this.props);
        var unlikedContent = [];
        if (this.props.user.contentUnliked !== undefined) unlikedContent = this.props.user.contentUnliked;
        var subscriber = CheckSubscriptionStateOwned(this.context);
        const xpLevels = {
            content: CheckXPLevel(this.context, 'content'),
            quotes: CheckXPLevel(this.context, 'quotes'),
            discover: CheckXPLevel(this.context, 'discover'),
            extraContent: CheckXPLevel(this.context, 'extraContent'),
        }
        //console.log('xpLevels', xpLevels)
        this.setState({
            initialMainQuality: this.props.user.currentLeadershipQuality,
            allQualitiesLoaded: unlikedContent,
            subscriber,
            loading: false,
            xpLevels
        })
        try {
            var sisterQualities = FindTextValueInDict(undefined, this.props.leadershipQualities, this.props.user.currentLeadershipQuality, 'sisterQualities');
            this.setState({ sisterQualities: sisterQualities });
            //console.log('sisterquali', sisterQualities)
        } catch  (err) {
            console.log('issue with finding the sister modules');
        }
        this.getLatestBatch();
        LoadStateEngagementStats(this, this.props.user.uid, false)
        //this.loadMoreContent();
        AddAnalytics('screen_view', 'PageTips');
    }
    componentWillUnmount() {
        this._isMounted = false;
        //this.setState({ loading: false });
    }
    componentDidUpdate() {
        if (false && this._isMounted && this.props.user.currentLeadershipQuality !== this.state.initialMainQuality) {
            
        }
    }

    findPreviousContentEngagement = (type="explore") => {
        if(this.props.user.uid){
            if((type==="explore" && this.state.type === undefined) || type === this.state.type){

            }
            if(this.state.contentEngagement?.contentIdsRead?.length > 0 ){
                this.setState({reloadingContentFlag: true}, () => {
                    this.setContentEngagementState(type, this.state.contentEngagement);
                });
            }
            else{
                this.setState({reloadingContentFlag: true});
                this.props.firebase
                .contentBookmarkEngagements()
                .where('userId', '==', this.props.user.uid)
                .orderBy('createdAt', 'desc')
                .get().then(snapshot => {
                    if(this._isMounted ){
                        //console.log('searching items', type);
                        var contentIdsRead = [];
                        var contentIdsBookmarked = [];
                        var contentIdsOpened = [];
                        snapshot.forEach(doc => {
                            var engagementSet = doc.data();
                            var contentId = engagementSet.contentId;
                            if(contentId?.length > 0){
                                //console.log('searching results', contentId, engagementSet, engagementSet.read, engagementSet.bookmark)
                                if(!contentIdsOpened.includes(contentId)){
                                    contentIdsOpened.push(contentId)
                                }
                                if((engagementSet.bookmark || engagementSet.vote === "up") && !contentIdsBookmarked.includes(contentId)){
                                    contentIdsBookmarked.push(contentId)
                                }
                                if((engagementSet.read || engagementSet.vote === "up" || engagementSet.vote === "down" || engagementSet.actions?.length > 0 || engagementSet.comment?.length > 0 )
                                    && !contentIdsRead.includes(contentId)){
                                        contentIdsRead.push(contentId)
                                }
                            }
                        })
                        this.setContentEngagementState(type, {contentIdsRead, contentIdsBookmarked, contentIdsOpened});
                    }
                })
            }
        }
    }
    setContentEngagementState(type, contentEngagement){
        //console.log('setContentEngagementState', type, contentEngagement)
        if(type === 'explore'){
            this.setState({
                type,
                contentEngagement: contentEngagement,
                reloadingContentFlag: false,
                contentToHide: undefined,
                contentToShowOnly: undefined
            });
        }
        else if(type === 'completed'){
            this.setState({
                type,
                contentEngagement: contentEngagement,
                reloadingContentFlag: false,
                contentToHide: [],
                contentToShowOnly: (contentEngagement.contentIdsRead || [])
            });
        }
        else if(type === 'unexplored'){
            this.setState({
                type,
                contentEngagement: contentEngagement,
                reloadingContentFlag: false,
                contentToHide: (contentEngagement.contentIdsOpened || []),
                contentToShowOnly: undefined
            });
        }
        else if(type === 'bookmarked'){
            this.setState({
                type,
                contentEngagement: contentEngagement,
                reloadingContentFlag: false,
                contentToHide: [],
                contentToShowOnly: (contentEngagement.contentIdsBookmarked || [])
            });
        }
    }

    loadCategoriesAndQualities() {
        //console.log('XXXX updating load cat')
        var userGrowthPath = LoadUserCategoryAndQualities(this, this.context.uid, this.context, this.props.leadershipCategories, this.props.leadershipQualities)
        if(this._isMounted && userGrowthPath !== undefined ){
            this.setState({
                mainQuality: userGrowthPath.mainQuality, 
            }, () => {
                this.setState({
                    ...userGrowthPath.oldCommentList,
                    mainCategory: userGrowthPath.mainCategory, 
                    mainQuality: userGrowthPath.mainQuality, 
                    programQualities: userGrowthPath.programQualities, 
                    mainQualitiesDicts: userGrowthPath.mainQualitiesDicts , 
                    sisterQualities: userGrowthPath.sisterQualities
                })
                //console.log('XXX userGrowthPath', this.state.mainQualitiesDicts)
            });
        }
    }

    handleChange = (event, newValue) => {
        if(newValue === "Home"){
            this.props?.history?.push(ROUTES.HOME)
        }
        else{
            this.setState({
                value: newValue,
                [newValue]: true
            });
            try{
                if(this.dialogRef !== undefined ){
                    this.dialogRef.current.scrollIntoView({behaviour: 'smooth'});
                    //this.dialogRef.scrollIntoView({behaviour: 'smooth'});
                }
                if(false && this.containerLine !== undefined){
                    var element = document.getElementById('TabDiscover')
                    element.scrollLeft = 100;
                    //console.log('centeredTabDiscover', element)
                    //this.containerLine.current.scrollLeft = 0;
                }
                //document.body.scrollTop = document.documentElement.scrollTop = 0;
                //console.log('scroll up', newValue)
            }
            catch (err) {
                console.log('No scroll to top', err);
            }
        }
    }

    handleOpen(contentData, type="content")  {
        //console.log("prob", contentData, type);
        var id = contentData.id
        if (id === undefined) {
            id = contentData.uid;
        }
        if(this.props.visualCommentsLimit!==undefined && this.props.visualCommentsLimit>0){
            //console.log('cisulacomment on')
            //openCommentsPopup = true;
        }
        this.setState({
            openContent: true,
            openCommentsPopup: type==="comment"? true: false,
            openReadPopup: type==="read"? true: false,
            dialogueData: contentData,
            contentId: id,
        });
        if (this.state['userEngagement' + id] === undefined) {
            //this.getBookMarks(contentData.id);
        }
    }


    discoverSearch(contentIdArray) {
        this.setState({
            resultOfContentSearch : undefined, 
        })
        this.setState({
            searchResults : true, 
            value: "Search",
            'Search': true,
            searchKeyIndex: this.state.searchKeyIndex + 1,
            items: [],
            hasMore: false,
            phasesLoaded: [],
            itemsLoaded: [],
            intentsLoaded: [],
            lastVisible: undefined,
            resultOfContentSearch: contentIdArray
        })
        //this.loadMoreContent(contentIdArray);
    }

    scrollEvent(scrollEvent){
        var positionTop = 0;
        var previousPositionTop = this.state.positionTop;
        if(scrollEvent!==undefined && scrollEvent.target!==undefined && scrollEvent.target.scrollTop !==undefined ){
            positionTop = scrollEvent.target.scrollTop;
            if(previousPositionTop !== undefined && previousPositionTop > 0 && positionTop !== undefined && positionTop > 0 && this._isMounted){
                if(positionTop < previousPositionTop ){
                    this.setState({positionTop : positionTop, scrollUp: true})
                } 
                else if(positionTop > previousPositionTop){
                    this.setState({positionTop : positionTop,  scrollUp: false})
                }  
                else {
                    this.setState({positionTop : positionTop})
                }    
            }
            else if(positionTop > 0 && this._isMounted){
                this.setState({positionTop : positionTop})
            }
            //console.log('phases', positionTop, previousPositionTop)
        }
    }

    render() {
        var { value, loading } = this.state;
        const { leadershipQualities, user, leadershipCategories , screen, screenWidth } = this.props;
        var { visualLimit } = this.props;
        const { theme } = this.props;
        if (visualLimit === null) {
            visualLimit = 20;
        }
        //console.log("homefeed stage", value, this.state)
        //console.log('props now', this.props)
        // with the move the data are asyn and cases need to be covered
        if (user !== null && leadershipQualities.length > 0) {
            return (
                <Grid key="splitsidenavi" container direction="row" ref={this.dialogRef} id='pageroot'>
                    <DialogTutorialPage 
                        defaultSet={this.props.defaultSet}
                        handleOpen={this.state.handleOpenTutorial || false}
                        handleBadgeFamily = {'content'}
                        handleClose={() => {this.setState({handleOpenTutorial: false})}}
                    />
                    <Grid item xs={screen==="desktop" ? 2 : false} >
                        {false && screen==="desktop"
                            &&
                            <Card style={theme.CardDesktopLeft}>
                                <Card style={theme.CardNaviLeft}>
                                    <h4 style={theme.CardContent.titleText}>Quests</h4>
                                    {this.renderTabs("vertical")}
                                </Card>
                            </Card>
                        }
                    </Grid>
                    <Grid item xs={screen==="desktop" ? 8 : 12} 
                        style={{
                            backgroundColor : screen!=="desktop" && !loading ? theme.palette.backPrimary : "transparent",
                            //height: '100%'
                        }}
                    >
                        {loading
                            ?
                                <div style={{height: '50vh', paddingTop: '25vh'}}>
                                    <LoadingProgress 
                                        defaultSet={this.props.defaultSet}
                                        type={'LoadingPageAnimated'} loadingText={"loading knowledge"} />
                                </div>
                            :
                            <Card style={
                                screen==="desktop"
                                    ?   theme.CardDesktopMain 
                                    :   {...theme.CardBack}}>
                                <div >
                                    <DialogContentItem
                                        user={this.props.user}
                                        defaultSet={this.props.defaultSet || undefined}
                                        open={this.state.openContent || false}
                                        handleClose={() => this.setState({openContent : false})}
                                        specialQuery={'contentByIds'}
                                        jumpToComment={this.state.openCommentsPopup  || false}
                                        jumpToRead={this.state.openReadPopup  || false}
                                        contentData={this.state.dialogueData}
                                        contentId = {this.state.contentId}
                                        leadershipCategories={this.props.leadershipCategories}
                                        leadershipQualities={this.props.leadershipQualities}
                                        />
                                    <DialogCardBadges 
                                        handleOpen={this.state.dialogCardOpen || false}
                                        handleClose={() => {this.setState({dialogCardOpen: false})}}
                                        badgeFamilyDetailSelection= {this.state.handleBadgeFamily}
                                    />
                                    <VizSensor 
                                        partialVisibility={"top"}
                                        onChange={(isVisible) => { 
                                            if(screen!== "desktop" && isVisible && this._isMounted) { 
                                                this.setState({headerVisible: true});
                                                //console.log('visible top cart')
                                            }
                                            else if (screen!== "desktop" && this._isMounted){
                                                this.setState({headerVisible: false});
                                                //console.log('hding top cart')
                                            }
                                        }}>
                                        <div style={{height: 1}}>  <img src={ pixel} alt=''/></div>
                                    </VizSensor>
                                    <Card style={{
                                            ...(this.props.screen === 'desktop' ? theme.CardDesktopTop : theme.CardTop), 
                                            position: this.state.headerVisible ? 'relative' : 'fixed',
                                            zIndex: this.state.headerVisible ? 0 : 100,
                                            top: 0,
                                            opacity: this.state.headerVisible ? '1.0' : '0.94',
                                        }}>
                                        {screen !== "desktop"
                                            ?
                                                <div style={{marginRight: -10}}>
                                                    {!this.state.headerVisible
                                                        && <div style={{width: 40, height:10, position: 'relative',  float : 'left'}}></div>
                                                    }
                                                    <StatsBar type='realtime' hide={!this.state.headerVisible ? true: false} />
                                                </div>
                                            :  
                                                <div style={{position: 'absolute', top: 6, right: 8}}>
                                                    <IconButton onClick={() => {this.setState({handleOpenTutorial: true})}}>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </div>
                                        }
                                        {this.state.headerVisible
                                            ?   <h2 style={theme.CardContent.titleText}>
                                                    <WidgetsIcon style={theme.inlineIconH2}/>&nbsp;Quests
                                                    {
                                                    // (value[0] === value[0].toUpperCase()) ? value : FindTextValueInDict(this.props.firebase, leadershipQualities, value) || "More"
                                                    }
                                                </h2>
                                            :   <div style={theme.textMonthCenter}><strong>{(value[0] === value[0].toUpperCase()) ? value : FindTextValueInDict(this.props.firebase, leadershipQualities, value) || "More"}</strong></div>
                                        }
                                        {!this.state.headerVisible
                                            &&
                                                <div style={{position: 'fixed', top: 3, right: 8}}>
                                                    <IconButton onClick={() => {this.setState({handleOpenTutorial: true})}}>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </div>
                                        }
                                        {false &&
                                            <div style={{width: '100%', display: (this.state.headerVisible || this.state.scrollUp) ? 'flex' : 'none', paddingBottom: screen!== "desktop" ? 0 : 20}}>
                                                <ContentSearchPanel 
                                                    onSubmit={(contentIdArray) => this.discoverSearch(contentIdArray) }
                                                />
                                            </div>
                                        }
                                        {screen !== "desktop" && (this.state.headerVisible || this.state.scrollUp)
                                            &&
                                            this.renderTabs()
                                        }
                                        {screen === "desktop" 
                                            &&
                                            this.renderTabs()
                                        }
                                    </Card>
                                    <div style={{margin: screenWidth !== undefined && screenWidth >= 600 ? 0 : 0}}>
                                        {this.state.contentBookmarked !== undefined && this.state.contentBookmarked.length >= 2
                                            &&
                                            <div value={"Bookmarks"} hidden={value !== "Bookmarks"}
                                                style={{marginLeft: screen==="desktop" ? 0 : -20, marginRight: screen==="desktop" ? 0 : -20 }}
                                            >
                                                <HomeCardContentItems
                                                    user={user}
                                                    defaultSet={this.props.defaultSet || undefined}
                                                    previewComments={true}
                                                    masonry = {true}
                                                    //masonry = {screenWidth !== undefined && screenWidth >= 600 ? true : false}
                                                    masonryCols = {screenWidth !== undefined && screenWidth >= 600 ? 2 : 1}
                                                    key={"Bookmarks"}
                                                    specialQuery='bookmarks'
                                                    visualLimit={visualLimit}
                                                    qualityFilter={user.currentLeadershipQuality}
                                                    leadershipCategories={leadershipCategories}
                                                    leadershipQualities={leadershipQualities}
                                                    allQualitiesLoaded={[]}
                                                    //allQualitiesLoaded={this.state.allQualitiesLoaded}
                                                    setParentAllQualitiesLoaded={v => this.setState({ allQualitiesLoaded: [...this.state.allQualitiesLoaded, v] })}
                                                    rerender={value !== "Bookmarks"? false : true}
                                                />
                                            </div>
                                        }
                                        {true &&
                                            <div value={"Coaching Plan"} index={1} hidden={value !== "Coaching Plan"}>
                                                <Card style={theme.CardContentItem.CardContentSpace}>
                                                    <ContentQualitySwipe 
                                                        user={user}
                                                        defaultSet={this.props.defaultSet || undefined}
                                                        noMargin={true}
                                                        screen={screen}
                                                        showCompletionLevel={true}
                                                        clickAction={(qualityKey) => {
                                                            this.setState({value: qualityKey, [qualityKey] : true})
                                                        }}
                                                        handlerContinue={() => setTimeout(() => { this.loadCategoriesAndQualities();}, 200)}
                                                        mainQualitiesDicts={this.state.mainQualitiesDicts || undefined}
                                                        engagementStats={this.state.engagementStats || undefined}
                                                        engagementBenchmarkQuality= {400}
                                                        leadershipCategories={leadershipCategories}
                                                        leadershipQualities={leadershipQualities}
                                                        refreshQualities = {this.props.refreshQualities || undefined}
                                                        refreshCategories = {this.props.refreshCategories || undefined}
                                                        rerender={value !== "Coaching Plan"? false : true}
                                                        tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                    />
                                                </Card>
                                                {user.dialogueCounter >= 1
                                                &&
                                                    <Card style={theme.CardContentItem.CardContentSpace}>
                                                        <TopicSuggestions
                                                            defaultSet={this.props.defaultSet || undefined}
                                                            handlerContinue={() => setTimeout(() => { this.loadCategoriesAndQualities();}, 200)}
                                                            engagementStats={this.state.engagementStats}
                                                            engagementBenchmarkQuality= {400}
                                                            leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities} 
                                                            user={user}
                                                            rerender={value !== "Coaching Plan"? false : true}
                                                        />
                                                    </Card>
                                                }
                                            </div> 
                                        }
                                        <div value={"Quests"} hidden={value !== "Quests"}>
                                            {(value === "Quests" || this.state["Quests"]) &&
                                                <>
                                                    {this.state.mainQualitiesDicts?.length > 0 
                                                    &&
                                                        <Card style={theme.CardContentItem.rootFeed}>
                                                            <h4 style={{...theme.CardContent.titleText, paddingLeft: 20}}>Quests</h4>
                                                            {this.state.mainQualitiesDicts?.map((qualityDict , index) => (
                                                                <div key={"quali_"+index} style={{margin: 20}}>
                                                                    <QualityCard
                                                                        qualityDict = {qualityDict}
                                                                        clickAction = {(key) => {this.setState({value: qualityDict.value, [qualityDict.value] : true})}}
                                                                    />
                                                                </div>
                                                            ))}
                                                            <p style={theme.textSupportCenter}> 
                                                                Open Quest &bull; Explore quest content
                                                            </p>
                                                        </Card>
                                                    }
                                                    <Card style={theme.CardContentItem.CardContentSpace}>
                                                        <h4 style={{...theme.CardContent.titleText}}>More</h4>
                                                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                                                            <Grid item xs style={{width:'100%'}}>
                                                                <BadgeCard
                                                                    backgroundColor = {"white"}
                                                                    image={undefined} 
                                                                    avatarImage = { <WidgetsIcon style={{ height: '80%', width: '80%'}}/> }
                                                                    title = {"Coaching Plan"}
                                                                    subtitle = {"Add new Quests to personalize your Coaching Plan"}
                                                                    strongSubtitle = {false}
                                                                    onClick={ () => this.setState({value: 'Coaching Plan'}) }
                                                                    clickText= {this.context.roles?.includes("COACHEE")
                                                                        ?   undefined
                                                                        :   "Change"
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid item xs style={{width:'100%'}}>
                                                                <BadgeCard
                                                                    backgroundColor = {"white"}
                                                                    image={undefined} 
                                                                    avatarImage = { <AvatarImage id={this.props.user?.currentLeadershipQuality || 'new'} type='quality' 
                                                                            key={user?.currentLeadershipQuality} 
                                                                            image={FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, user?.currentLeadershipQuality, 'icon')}
                                                                            vertical-align="middle" item={this.props.user?.currentLeadershipQuality} 
                                                                            squareSize={"auto"} backgroundColor={FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, user?.currentLeadershipQuality, 'backgroundColor')} 
                                                                        />  
                                                                    }
                                                                    title = {"Explore Content"}
                                                                    subtitle = {"Discover personalized content and recommendations"}
                                                                    strongSubtitle = {false}
                                                                    onClick={ () => this.setState({value: 'Discoveries'}) }
                                                                    clickText= {this.context.roles?.includes("COACHEE")
                                                                        ?   undefined
                                                                        :   "Open"
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <p style={theme.textSupportCenter}> 
                                                            Add new Quest &bull; Discover content
                                                        </p>
                                                    </Card>
                                                    {false &&
                                                    <Card style={this.state.flagNoHighlights === true ? {} : theme.CardContentItem.rootFeed}>
                                                        <ContentHighlights 
                                                            defaultSet={this.props.defaultSet || undefined}
                                                            user={user}
                                                            screen={screen}
                                                            linkToContent={false}
                                                            selectionHandler={(selectedContent) => {
                                                                console.log('selectedContent', selectedContent)
                                                                if(!(selectedContent?.length > 0)){
                                                                    this.setState({flagNoHighlights: true})
                                                                }
                                                                else{
                                                                    this.setState({flagNoHighlights: false})
                                                                }
                                                            }}
                                                            leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities} 
                                                            refreshQualities = {this.props.refreshQualities || undefined}
                                                            refreshCategories = {this.props.refreshCategories || undefined}
                                                            tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                        />
                                                    </Card>
                                                    }
                                                </>
                                            }
                                        </div>
                                        {(value==='Bookmarks' || this.state['Bookmarks']) &&
                                            <SnackbarInfo 
                                                alertText = 'Content is bookmarked if you clicked on the bookmark icon or if you have activated a follow-up action.'
                                                alertName = 'bookmarksintro'
                                                userId = {this.context.uid}
                                                />
                                        }
                                        <div value={'Search'} hidden={value !== "Search"}>
                                            {(value === 'Search' && this.state.searchResults ) &&
                                                this.state.resultOfContentSearch!==undefined &&
                                                <InfiniteContent 
                                                    user = {this.props.user}
                                                    defaultSet={this.props.defaultSet || undefined}
                                                    roleFiter = {false}
                                                    resultOfContentSearch = {this.state.resultOfContentSearch}
                                                    scrollEvent = {(scrollEvent) => this.scrollEvent(scrollEvent)}
                                                    handleOpen = {(contentData, type) => this.handleOpen(contentData, type)}
                                                    screenWidth = {screenWidth}
                                                    mainQualitiesIds = {this.state.mainQualitiesIds || undefined}
                                                    leadershipCategories={leadershipCategories}
                                                    leadershipQualities={leadershipQualities}
                                                    rerender={value !== "Search"? false : true}
                                                />
                                            }
                                        </div>
                                        {(value === 'Discoveries' || this.state['Discoveries'] ) && 
                                            <div value={"Discoveries"} index={0} hidden={value !== "Discoveries"}>
                                                {this.renderContentFilterButtons()}
                                                {(this.state.subscriber 
                                                    //|| (user.engagementCounter!== undefined && this.state.xpLevels?.discover?.valid )
                                                )
                                                    ?
                                                        <div value={"DiscoverScroll"} 
                                                            style={{width:"100%", marginLeft: screen==="desktop" ? 0 : 0, marginRight: screen==="desktop" ? 10 : 0 }}
                                                        >
                                                            {this.state.reloadingContentFlag !== true 
                                                            &&
                                                                <InfiniteContent 
                                                                    user = {this.props.user}
                                                                    defaultSet={this.props.defaultSet || undefined}
                                                                    contentToHide={this.state.contentToHide || undefined}
                                                                    resultOfContentSearch={this.state.contentToShowOnly || undefined}
                                                                    contentToShowOnly={this.state.contentToShowOnly || undefined}
                                                                    roleFiter = {true}
                                                                    scrollEvent = {(scrollEvent) => this.scrollEvent(scrollEvent)}
                                                                    handleOpen = {(contentData, type) => this.handleOpen(contentData, type)}
                                                                    screenWidth = {screenWidth}
                                                                    mainQualitiesIds = {this.state.mainQualitiesIds || undefined}
                                                                    leadershipCategories={leadershipCategories}
                                                                    leadershipQualities={leadershipQualities}
                                                                    rerender={value !== "Discoveries"? false : true}
                                                                />
                                                            }
                                                        </div>
                                                    :
                                                        <>
                                                            {this.state.reloadingContentFlag !== true 
                                                            &&
                                                                <InfiniteContent 
                                                                    user = {this.props.user}
                                                                    defaultSet={this.props.defaultSet || undefined}
                                                                    contentToHide={this.state.contentToHide || undefined}
                                                                    resultOfContentSearch={this.state.contentToShowOnly || undefined}
                                                                    contentToShowOnly={this.state.contentToShowOnly || undefined}
                                                                    visualLimit = {5}
                                                                    roleFiter = {true}
                                                                    scrollEvent = {(scrollEvent) => this.scrollEvent(scrollEvent)}
                                                                    handleOpen = {(contentData, type) => this.handleOpen(contentData, type)}
                                                                    screenWidth = {screenWidth}
                                                                    mainQualitiesIds = {this.state.mainQualitiesIds || undefined}
                                                                    leadershipCategories={leadershipCategories}
                                                                    leadershipQualities={leadershipQualities}
                                                                    rerender={value !== "Discoveries"? false : true}
                                                                />
                                                            }
                                                            <div>
                                                                {this.nextUnlockCard('Unlock Discovery', "Subscribe for full access...")}
                                                            </div>
                                                        </>
                                                }
                                            </div>
                                        }
                                        {(this.state.subscriber || (user.engagementCounter!== undefined && this.state.xpLevels?.quotes?.valid ))
                                            ?
                                                <div value={"Quotes"} hidden={value !== "Quotes"}>
                                                    {(value === 'Quotes' || this.state['Quotes']) &&
                                                        <InfiniteContent 
                                                            user = {this.props.user}
                                                            defaultSet={this.props.defaultSet || undefined}
                                                            scrollEvent = {(scrollEvent) => this.scrollEvent(scrollEvent)}
                                                            roleFiter = {true}
                                                            handleOpen = {(contentData, type) => this.handleOpen(contentData, type)}
                                                            quotes = {true}
                                                            screenWidth = {screenWidth}
                                                            mainQualitiesIds = {this.state.mainQualitiesIds || undefined}
                                                            leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities}
                                                            rerender={value !== "Quotes"? false : true}
                                                        />
                                                    }
                                                </div>
                                            :
                                                <div value={"Quotes"}  index={0} hidden={value !== "Quotes"}>
                                                    {this.nextUnlockCard('Unlock Quotes', this.state.xpLevels?.quotes?.text || "")}
                                                </div>
                                        }
                                        {this.state.mainQualitiesIds !== undefined
                                            ?
                                                this.state.mainQualitiesIds.map((sisterQuality, index) => {
                                                    return (
                                                        <div 
                                                            value={sisterQuality}
                                                            hidden={value !== sisterQuality}
                                                            key={sisterQuality+"_"+index}
                                                        >
                                                                { (value === sisterQuality || this.state[sisterQuality]) &&
                                                                    <>
                                                                        <Grid container direction="row" spacing={1}>
                                                                            <Grid item xs style={{minWidth: IsMobileDevice() ? 240 : 400}}>
                                                                                <Card style={theme.CardContentItem.CardContentSpace}>
                                                                                    <ContentEngagementOverview 
                                                                                        user={user}
                                                                                        defaultSet={this.props.defaultSet || undefined}
                                                                                        screenWidth={this.props.screenWidth || undefined}
                                                                                        updateScore = { (score) => { this.updateScore(score) } }
                                                                                        leadershipCategories = {this.props.leadershipCategories}
                                                                                        leadershipQualities = {this.props.leadershipQualities}
                                                                                        //userQualityPath = {usedQualities || undefined }
                                                                                        type={'quality'}
                                                                                        selectedItem={sisterQuality}
                                                                                        handlerPurchase={() => {
                                                                                            this.setState({subscriber: true});
                                                                                        }}
                                                                                        showChatBoot={false}
                                                                                        showTimeline={false}
                                                                                        nonSubscriber = {!this.state.subscriber}
                                                                                        //trialPeriod = {this.state.trialPeriod}
                                                                                        engagementStats={this.state.engagementStats}
                                                                                        engagementBenchmarkQuality = {400}
                                                                                        handlerContinue = {this.props.handlerContinue ? this.props.handlerContinue : undefined}
                                                                                    />
                                                                                </Card>
                                                                            </Grid>
                                                                            {false && 
                                                                                <Grid item xs style={{minWidth: IsMobileDevice() ? 240 : 400}}>
                                                                                    <Card style={{...theme.CardContentItem.CardContentSpace, padding: 0}}>
                                                                                        <JournalActivities 
                                                                                            leadershipCategories={this.props.leadershipCategories}
                                                                                            leadershipQualities={this.props.leadershipQualities} 
                                                                                            specialQuery={'contentByQuality'}
                                                                                            historyLimit={3}
                                                                                            quality = {sisterQuality}
                                                                                            user={this.props.user} 
                                                                                            checkMode={true}
                                                                                            groupItems={true}
                                                                                        />
                                                                                    </Card>
                                                                                </Grid>
                                                                            }
                                                                        </Grid>
                                                                        {this.renderContentFilterButtons()}
                                                                        {this.state.reloadingContentFlag !== true 
                                                                        &&
                                                                            <InfiniteContent 
                                                                                user = {this.props.user}
                                                                                defaultSet={this.props.defaultSet || undefined}
                                                                                key={sisterQuality}
                                                                                contentToHide={this.state.contentToHide || undefined}
                                                                                contentToShowOnly={this.state.contentToShowOnly || undefined}
                                                                                resultOfContentSearch={this.state.contentToShowOnly || undefined}
                                                                                ///// This has to be all elements from topic instead !!!!!!!!!!!!!!
                                                                                filterFlagCourse = {true}
                                                                                visualLimit = {this.state.subscriber? undefined : 5}
                                                                                handleOpen = {(contentData, type) => this.handleOpen(contentData, type)}
                                                                                scrollEvent = {(scrollEvent) => this.scrollEvent(scrollEvent)}
                                                                                filterQuality = {sisterQuality }
                                                                                overWriteQualityValue = {sisterQuality  || undefined}
                                                                                screenWidth = {screenWidth}
                                                                                leadershipCategories={leadershipCategories}
                                                                                leadershipQualities={leadershipQualities}
                                                                                rerender={value !== sisterQuality? false : true}
                                                                            />
                                                                        }
                                                                        {this.state.subscriber !== true &&
                                                                            <div>
                                                                                {this.nextUnlockCard('Unlock Quest', "Subscribe for full access..." || "")}
                                                                            </div>
                                                                        }
                                                                    </>
                                                                }
                                                        </div>
                                                    )
                                                })//end map
                                            :
                                                <div value={"sisterOption"}  index={0} hidden={value !== "sisterOption"}>
                                                    {this.nextUnlockCard('Unlock Extra Content', this.state.xpLevels?.extraContent?.text || "")}
                                                </div>
                                        }
                                    </div>
                                </div>
                            </Card>
                        }
                    </Grid>
                    <Grid item xs={screen==="desktop" ? 2 : false} ></Grid>
                </Grid>
            ); // end return
        }
        else {
            return (
                <Card style={theme.CardContent} ref={this.dialogRef}>
                    <p style={theme.CardContent.titleText}>Please wait a moment</p>
                </Card>
            )
        }

    }

    renderContentFilterButtons() {
        const {theme} = this.props;
        return(
            <Card style={{...theme.CardContentItem.CardContentSpace, width: "100%"}}>
                <h4 style={{...theme.CardContent.titleText}}>Learning Content</h4>
                <Grid container spacing={2} justifyContent="space-between" >
                    <Grid item xs style={{textAlign: "center"}}>
                        <Button variant={(this.state.searchtype === undefined || this.state.searchtype === 'explore') ? 'outlined' : undefined}
                            onClick={() => {
                                this.setState({searchtype : 'explore'});
                                this.findPreviousContentEngagement('explore');
                            }}
                            >
                            <span style={{minWidth: 120}}>
                                <ExploreIcon style={theme.inlineIconLeft}/>Suggested
                            </span>
                        </Button>
                    </Grid>
                    <Grid item xs style={{textAlign: "center"}}>
                        <Button variant={(this.state.searchtype === 'completed') ? 'outlined' : undefined}
                            onClick={() => {
                                this.setState({searchtype : 'completed'});
                                this.findPreviousContentEngagement('completed');
                            }}
                            >
                            <span style={{minWidth: 120}}>
                                <CheckCircleIcon style={theme.inlineIconLeft}/>Completed
                            </span>
                        </Button>
                    </Grid>
                    <Grid item xs style={{textAlign: "center"}}>
                        <Button variant={(this.state.searchtype === 'unexplored') ? 'outlined' : undefined}
                            onClick={() => {
                                this.setState({searchtype : 'unexplored'})
                                this.findPreviousContentEngagement('unexplored');
                            }}
                            >
                            <span style={{minWidth: 120}}>
                                <StarOutlineIcon style={theme.inlineIconLeft}/>Unexplored
                            </span>
                        </Button>
                    </Grid>
                    <Grid item xs style={{textAlign: "center"}}>
                        <Button variant={(this.state.searchtype === 'bookmarked') ? 'outlined' : undefined}
                            onClick={() => {
                                this.setState({searchtype : 'bookmarked'})
                                this.findPreviousContentEngagement('bookmarked');
                            }}
                        >
                            <span style={{minWidth: 120}}>
                                <BookmarkBorderIcon style={theme.inlineIconLeft}/>Favorites
                            </span>
                        </Button>
                    </Grid>
                </Grid>
                <p style={theme.textSupportCenter}> 
                    Explore content &bull; Filter recommendations
                </p>
            </Card>
        )
    }

    getLatestBatch(){
        if(this._isMounted){
            SetStateLoadingBatches(this, this.props.firebase, this.props.user.uid, 'xp_content', 10)
        }
    }
    handlerOpenBadgeDetails = (badgeFamily) => {
        if(badgeFamily !== undefined && badgeFamily!== "" && badgeFamily!== "all" && this.props.badgeFamilyDetailSelection === undefined){
            this.setState({ 
                handleBadgeFamily:badgeFamily,
            });
            this.setState({ 
                handleBadgeFamily:badgeFamily,
                dialogCardOpen: true,
            });
        }
        else{
            this.setState({ 
                handleBadgeFamily:'all',
                handleOpen: true,
            });

        }
    }
    nextUnlockCard = (title, subtitle) => {
        return(
            <div style={{margin: 10}}>
                <BadgeCard
                    key={title}
                    backgroundColor ={"transparent"}
                    image={undefined} 
                    avatarImage = { undefined }
                    title = {title}
                    subtitle = {subtitle}
                    strongSubtitle = {false}
                    button = { undefined }
                    onClick={ () => {
                        this.props.history.push({
                            pathname: ROUTES.PROFILE,
                            state: {showSubscriptions: true}
                        });
                    } }
                    clickText= { 'Unlock' }
                />
            </div>
        )
    }

    renderTabs(orientation = "horizontal") {
        const { value } = this.state;
        const { user, leadershipQualities, theme, screen } = this.props;
        if(orientation === 'vertical'){
            return(
                <>
                    <Link to={ROUTES.HOME}>
                        <span><HomeIcon style={theme.inlineIconLeft}/>Home</span>
                    </Link>
                    <hr/>
                    <Link to={ROUTES.CONTENT} onClick={() => (this.handleChange(undefined, "Coaching Plan"))}>
                        <span><WidgetsIcon style={theme.inlineIconLeft}/>Coaching Plan</span>
                    </Link>
                    {value !== "Coaching Plan" &&
                        <>
                            {this.state.mainQualitiesIds?.includes(value)
                            ?
                                <>
                                    <Link to={ROUTES.CONTENT} onClick={() => (this.handleChange(undefined, "Quests"))}>
                                        <li style={{paddingTop: 8, paddingLeft: 10, whiteSpace: 'nowrap'}}> {"Quests"} </li>
                                    </Link>
                                    <Link to={ROUTES.CONTENT} onClick={() => (this.handleChange(undefined, value))}>
                                        <li style={{paddingTop: 8, paddingLeft: 16, whiteSpace: 'nowrap'}}> {FindTextValueInDict(this.props.firebase, leadershipQualities, value)}  </li>
                                    </Link>
                                </>
                            :
                                <Link to={ROUTES.CONTENT} onClick={() => (this.handleChange(undefined, value))}>
                                    <li style={{paddingTop: 8, paddingLeft: 10, whiteSpace: 'nowrap'}}> {value} </li>
                                </Link>
                            }
                        </>
                    }
                </>
            )
        }
        else{
            return(
                <>
                {user !== null && leadershipQualities!== undefined && leadershipQualities.length > 0
                    &&
                    <Tabs
                        value={value}
                        orientation={orientation}
                        onChange={this.handleChange}
                        aria-label="Learning Content"
                        variant="scrollable"
                        scrollButtons={"auto"}
                        initialselectedindex={'Discoveries'}
                        ref={this.containerLine}
                        style={{
                            marginTop: 20,
                            marginBottom: (screen==="desktop" ? 0 : -1),
                            marginLeft: -20,
                            width: 'calc(100% + 40px)'
                        }}
                    >
                        {false && this.props.screen==="desktop" && orientation==="vertical" && 
                            <Tab value="Home" label={<span><HomeIcon style={theme.inlineIconLeft}/>Home</span>}/>
                        }
                        <Tab value={"Coaching Plan"} 
                            label="Coaching Plan"
                        />
                        {this.state.searchResults 
                        &&
                            <Tab value={"Search"}
                                label={'Search'}
                            />
                        }
                        <Tab value={"Quests"}
                            label = {"Quests"}
                            //label = {FindTextValueInDict(this.props.firebase, leadershipQualities, user.currentLeadershipQuality)}
                        />
                        {this.state[user.todaysLeadershipQuality] && user.todaysLeadershipQuality !== undefined && user.todaysLeadershipQuality !== ''
                            && this.state.xpLevels?.content?.valid
                                ?
                                    <Tab value={user.todaysLeadershipQuality}
                                        label = {FindTextValueInDict(this.props.firebase, leadershipQualities, user.todaysLeadershipQuality)}
                                    />
                                :   ''
                        }
                        {this.state.mainQualitiesIds !== undefined && this.state.mainQualitiesIds.length>0 
                            && this.state.mainQualitiesIds[0]!==undefined && this.state.mainQualitiesIds[0]!==''
                            ? 
                                this.state.mainQualitiesIds.map((sisterQuality, index) => {
                                    //ensure that sisterquality is not double with daily Module
                                    //console.log('sister', sisterQuality)
                                    try {
                                        var topicName = FindTextValueInDict(this.props.firebase, leadershipQualities, sisterQuality)
                                        return (
                                            user.todaysLeadershipQuality !== sisterQuality && this.state[sisterQuality]
                                            ?
                                                <Tab 
                                                    value={sisterQuality} 
                                                    key={sisterQuality+'_'+index}
                                                    label = {topicName }
                                                />
                                            : ''
                                        )
                                    }
                                    catch (err) {
                                        return (<Tab value={sisterQuality} label={sisterQuality}  key={'errors'}/>)
                                    }
                                })//end map
                            :   ''
                        }
                        <Tab value={"Discoveries"}  label={"Discoveries"} id={"TabDiscover"}/>
                        {this.state.contentBookmarked !== undefined && this.state.contentBookmarked.length >= 2
                        &&
                            <Tab value={"Bookmarks"}
                                label={'Bookmarks'}
                            />
                        }
                        {false && (this.state.subscriber || (user.programs!==undefined && user.programs.includes('default') )) //user.engagementCounter!== undefined && user.engagementCounter>=15
                        &&
                            <Tab value={"Quotes"}
                                label = {"Quotes"}
                            />
                        }
                    </Tabs>              
                } 
                </>
            )
        }
    }

}

export default compose(
    withFirebase,
    withRouter,
    withStyles(styles, { withTheme: true })
)(HomeFeed);