import { colors } from '@material-ui/core';

function paletteMode(darkMode, defaultSet = {}){
    const white = '#FEFFFE';
    const black = '#38372E';
    const themeDarkBlack = '#1b1f23' //'#424242' // '#2f3132'  #1b1f23
    const themeDarkBack = '#303030' //'#051313'
    const themeDarkPaper = '#a0a9ac'
    const themeGold = '#FCD750';
    const themeDarkBlue = '#3F4257';
    const themeTeal = '#26b1bf'//'#39CCCC';
    const themeLoading = '#3F4257';//##3F4257 the darker blue //#38B2CB
    const themeBlue = '#20A5E6';
    const themeLightGrey = '#F5F5F6';
    const themeNaviGrey = '#F1F0EE';    
    var themeAltDarkBlue = ""+themeDarkBlue;
    var themeAltGold = ""+themeGold;
    var themeAltTeal = ""+themeTeal;
    var themeAltLoading = ""+themeLoading;
    var themeAltBlue = ""+themeBlue;
    var themeAltLightGrey = ""+themeLightGrey;
    var themeAltNaviGrey = ""+themeNaviGrey;
    //const themeLoading = '#387BB0';//#42aebb
    //const themeNaviGrey = '#DCDCE1';
    //const themeBlue = '#2391AA';
    //const themeLightGrey = '#EDF5F8';
    const themeGrey = '#8E8F91';
    const themeMilk = '#FFFAFA';
    const themeRed = '#CC3939';
    //alert colors
    const themeAlertGreen = '#57A728';
    const themeAlertRed = '#CC3939';
    const themeAlertOrange = '#F5A81A';
    const themeAlertBlue = '#7FC9FF';
    var themeAltBGImage = undefined;
    //console.log('condarkModetext darkMode', darkMode, defaultSet)
    /***
     */
    try{
        if(true || darkMode !== true){
            if(Object.keys(defaultSet).length === 0){
                //console.log('empty defaultset')
                defaultSet = JSON.parse(window?.localStorage?.getItem('defaultSet'));
                if(!(defaultSet?.programCode?.length > 2)){
                    defaultSet = JSON.parse(window?.sessionStorage?.getItem('defaultSet'));
                    //console.log('got it from session storage')
                }
            }
            else{
                //console.log('sdfdsfg')
            }
            if(defaultSet?.programCode?.length > 2){
                //console.log('defaultSet easy', defaultSet, defaultSet?.programColorFront)
                themeAltLoading = themeDarkBlue;
                if(defaultSet?.programColorInfo?.includes('#')) themeAltTeal = defaultSet.programColorInfo;
                if(defaultSet?.programColorHover?.includes('#')) themeAltGold= defaultSet.programColorHover;
                if(defaultSet?.programColorButton?.includes('#')) themeAltBlue = defaultSet.programColorButton;
                if(darkMode !== true){
                    if(defaultSet?.programColorFront?.includes('#')) themeAltLightGrey = defaultSet.programColorFront;
                    if(defaultSet?.programColorBack?.includes('#')){
                        themeAltLoading = defaultSet?.programColorBack;
                        themeAltDarkBlue = defaultSet?.programColorBack;
                    }
                    if(defaultSet?.programColorPrimary?.includes('#')) themeAltNaviGrey = defaultSet?.programColorPrimary;
                }
                if(defaultSet?.programBGImage?.includes("http")){
                    themeAltBGImage = defaultSet.programBGImage;
                    //console.log('themeAltBGImage', themeAltBGImage)
                }
            }
            //console.log('context plaette', defaultSet, themeAltTeal, themeAltGold,  themeAltLightGrey, themeAltBlue)
        }
        //console.log(themeTeal, themeAltTeal, defaultSet)
    }
    catch(e){
        console.log('Error with defaultSet Storage', e)
    }
    return({
        darkMode,
        black,
        white,
        themeGold,
        themeTeal,
        themeBlue,
        themeGrey,
        themeMilk,
        themeRed,
        themeLightGrey,
        themeNaviGrey,
        themeDarkBlue,
        themeAlertGreen,
        themeAlertOrange,
        themeAlertRed,
        themeAlertBlue,
        themeAltDarkBlue,
        themeAltTeal,
        themeAltGold,
        themeAltLoading,
        themeAltBlue,
        themeAltLightGrey,
        themeAltNaviGrey,
        themeAltBGImage,
        themeLoading: darkMode? themeDarkBlack : themeLoading,
        backPrimary: darkMode? themeDarkBack : themeAltNaviGrey,
        backPrimaryInv: darkMode? themeAltNaviGrey : themeDarkBack,
        backSecondary: darkMode? themeAltDarkBlue : themeGrey ,
        backSecondaryInv: darkMode? themeGrey : themeAltDarkBlue,
        backTertiary: darkMode? themeDarkBlack : themeAltDarkBlue ,
        backTertiaryInv: darkMode? themeAltDarkBlue : themeDarkBlack,
        backPaper: darkMode? themeDarkBlack : themeAltLightGrey ,
        backPaperSecondary: darkMode? themeDarkPaper : '#dddfdd' ,
        themeText: darkMode? themeAltLightGrey : black ,
        themeTextInv: darkMode? black: themeAltLightGrey ,
        themeTextSecondary: darkMode? white : themeAltDarkBlue,
        primary: {
            contrastText: black,
            dark: themeAltGold,
            main: white,
            light: themeAltLightGrey
        },
        secondary: {
            contrastText: white,
            dark: black,
            main: darkMode? '#b6bbc0': themeAltBlue,
            light: darkMode? '#5f9196' : themeAltTeal
        },
        success: {
            contrastText: white,
            dark: colors.red[900],
            main: colors.red[600],
            light: colors.red[400]
        },
        info: {
            contrastText: white,
            dark: colors.blue[900],
            main: colors.blue[600],
            light: colors.blue[400]
        },
        warning: {
            contrastText: white,
            dark: colors.orange[900],
            main: colors.orange[600],
            light: colors.orange[400]
        },
        error: {
            contrastText: white,
            dark: colors.red[900],
            main: colors.red[600],
            light: colors.red[400]
        },
        text: {
            primary: black,
            secondary: white,
            link: themeAltTeal,
        },
        background: {
            default: white,
            paper: white
        },
        icon: themeAltBlue,
        divider: themeGrey,
    })
};

export const palette = paletteMode(false)
export const paletteDark = paletteMode(true)
export function UpdatedPalette(defaultSet = {}) {
    return paletteMode(false, defaultSet)
} 

export default palette;