import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter, Link } from 'react-router-dom';

import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { AddAnalytics } from '../Modules/AddAnalytics';
import JournalIntentionCheck from '../Days/JournalIntentionCheck';
import HomeIcon from '@material-ui/icons/Home';
import AssessmentTimeline from '../Charts/AssessmentTimeline';
import CardAssessmentShop from '../Assessments/CardAssessmentShop';
import CardAssessmentFactors from '../Charts/CardAssessmentFactors';
//import JournalList from './JournalList';
import JournalListSwipe from './JournalListSwipe';
import JournalActivities from './JournalActivities';
import LoadingProgress from '../Modules/LoadingProgress';
import GoalOverview from '../Goals/GoalOverview';
/// Tutorials
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import DialogTutorialPage from '../Onboarding/DialogTutorialPage';
import {CheckXPLevel} from '../Charts/XPLevels';
import {CheckSubscriptionStateOwned} from '../Modules/CheckSubscriptionStatus';
import BadgeCard from '../Modules/BadgeCard';

//import badgeStart from "../../images/Badge_XP_congrats.png";
import Grid from '@material-ui/core/Grid';


import DialogCardBadges from '../Charts/DialogCardBadges';
import TodayAdd from '../Home/TodayAdd';

import SnackbarInfo from '../Modules/SnackbarInfo';
import StatsBar from '../Charts/StatsBar';
//import DayList from './DayList';
import WordCloudList from './WordCloudList';
//import JourneyMap from '../Days/JourneyMap';

import MapGoals from '../Days/MapGoals';
import MapQualities from '../Days/MapQualities';
//import MapMindset from '../Days/MapMindset';
import MapTasks from '../Days/MapTasks';


///// Manage the navigation bar appear
import VizSensor from 'react-visibility-sensor';
import pixel from "../../images/pixel.png";

import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

/***
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionActions';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
 */
import Card from '@material-ui/core/Card';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {IsMobileDevice} from '../Modules/IsMobileDevice';
import {SetStateLoadingBatches} from '../Charts/LoadingBatches';



//import Grid from '@material-ui/core/Grid';
//import IconButton from '@material-ui/core/IconButton';
//import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';



const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    highlightTab: {
        position: theme.highlightTab.position,
        left:  IsMobileDevice()? theme.HomeNoNavigationShiftContent.paddingLeft : theme.HomeNavigationShiftContent.paddingLeft ,
        top: IsMobileDevice()? theme.highlightTab.top : 60,
        backgroundColor:theme.highlightTab.backgroundColor, 
        zIndex:theme.highlightTab.zIndex,
    },
    unHighlightTab: {
        position: "flexible",
        //backgroundColor:theme.highlightTab.backgroundColor, 
        backgroundColor: "transparent", 
    },
});



class HomeJournal extends Component {
    _isMounted = false;
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        this.dialogRef = React.createRef();

        var historyLimit = 9;
        if (this.props.historyLimit>0){
            historyLimit = this.props.historyLimit
        }
        var startTab = "Journey";
        if (this.props.location && this.props.location.state && this.props.location.state.startTab){
            startTab= this.props.location.state.startTab;
        }
        this.state = {
            loading: true,
            historyLimit: historyLimit,
            journalWeeks: [],
            value: startTab,
            user: null,
            scrolled: false,
            headerVisible: true,
            Activities: true,
        };
    }

    handleChange = (event, newValue) => {
        if(newValue === "Home"){
            this.props?.history?.push(ROUTES.HOME)
        }
        else{
            try{
                if(this.dialogRef !== undefined ) this.dialogRef.current.scrollIntoView({behaviour: 'smooth'});
            }
            catch (err) {
                console.log('No scroll to top');
            }
            this.setState({
                value: newValue,
                [newValue] : true,
            });
        }
    }
    componentDidMount() {
        this._isMounted = true;
        var subscriber = CheckSubscriptionStateOwned(this.context);
        var historyLimit = this.state.historyLimit;
        if(!subscriber){
            historyLimit = 3;
        }
        //console.log('historylimit', historyLimit)
        const xpLevels = {
            followUp: CheckXPLevel(this.context, 'followUp'),
            journal: CheckXPLevel(this.context, 'journal'),
        }
        this.setState({
            subscriber,
            xpLevels,
            historyLimit
        })
        this.getLatestBatch();
        try{
            if(this.dialogRef !== undefined ) this.dialogRef.current.scrollIntoView({behaviour: 'smooth'});
        } catch  (err) {
            console.log('No scroll to top');
        }
        AddAnalytics('screen_view', 'PageJournal');
    }

    componentWillUnmount() {
        this._isMounted = false;
        //this.unsubscribeDialogues();
        //this.setState({ loading: true });
        //window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        const { theme } = this.props;
        const { loading,  value } = this.state;
        const {user, leadershipCategories, leadershipQualities, screen} = this.props;
        //console.log(this.state)
        if(user !== null){
            //console.log("new user", user)
            return(
                <Grid key="splitsidenavi" container direction="row" ref={this.dialogRef} id='pageroot' style={{height:'100%'}}>
                    <DialogTutorialPage 
                        defaultSet={this.props.defaultSet}
                        handleOpen={this.state.handleOpenTutorial || false}
                        handleBadgeFamily = {'followup'}
                        handleClose={() => {this.setState({handleOpenTutorial: false})}}
                    />
                    <Grid item xs={screen==="desktop" ? 2 : false} >
                        {false && screen==="desktop"
                            &&
                            <Card style={theme.CardDesktopLeft}>
                                <Card style={theme.CardNaviLeft}>
                                    <h4 style={theme.CardContent.titleText}>Journey</h4>
                                    {this.renderTabs("vertical")}
                                </Card>
                            </Card>
                        }
                    </Grid>
                    <Grid item xs={screen==="desktop" ? 8 : 12} >
                        {loading
                            ?
                                <div>
                                    <LoadingProgress 
                                        defaultSet={this.props.defaultSet}
                                        type={'LoadingPageAnimated'} loadingText={"loading stats"} />
                                </div>
                            :
                                <Card style={screen==="desktop"? theme.CardDesktopMain : theme.CardAppMain}>
                                    <VizSensor 
                                        partialVisibility={"top"}
                                        onChange={(isVisible) => { 
                                            if(screen!=="desktop" && isVisible && this._isMounted) { 
                                                this.setState({headerVisible: true});
                                                //console.log('visible top cart')
                                            }
                                            else if (screen!=="desktop" && this._isMounted){
                                                this.setState({headerVisible: false});
                                                //console.log('hding top cart')
                                            }
                                        }}>
                                        <div style={{height: 1}}>  <img src={ pixel} alt=''/></div>
                                    </VizSensor>
                                    <Card style={{
                                            ...(this.props.screen === 'desktop' ? theme.CardDesktopTop : theme.CardTop), 
                                            position: this.state.headerVisible? 'relative' : 'fixed',
                                            zIndex: this.state.headerVisible? 10 : 100,
                                            top: 0,
                                            opacity: this.state.headerVisible? '1.0' : '0.94',
                                        }}>
                                        {screen !== "desktop"
                                            ?
                                                <div style={{position: 'relative'}}>
                                                    <div style={{position: 'absolute', top: 0, right: -10, zIndex: '14000 !important'}}>
                                                        <StatsBar 
                                                            type='realtime' 
                                                            hide={!this.state.headerVisible ? true: false}
                                                            animateMomentByName = {this.state.animateMomentByName || undefined}
                                                            animateMoments = {false}
                                                            animationDoneHandler = {() => this.setState({animatePlus1Trigger: false, animateMomentByName: undefined})}
                                                        />
                                                    </div>
                                                </div>
                                            :
                                                <div style={{position: 'absolute', top: 6, right: 8}}>
                                                    <IconButton onClick={() => {this.setState({handleOpenTutorial: true})}}>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </div>
                                        }
                                        {this.state.headerVisible
                                            ?   <h2 style={theme.CardContent.titleText}><AssignmentTurnedInIcon style={theme.inlineIconH2}/>&nbsp;Journey</h2> 
                                            :   <div style={theme.textMonthCenter}><strong>{value}</strong></div>
                                        }
                                        {!this.state.headerVisible
                                            &&
                                                <div style={{position: 'fixed', top: 3, right: 8}}>
                                                    <IconButton onClick={() => {this.setState({handleOpenTutorial: true})}}>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </div>
                                        }
                                        <div hidden={ !this.state.headerVisible}>
                                            <DialogCardBadges 
                                                    handleOpen={this.state.dialogCardOpen || false}
                                                    handleClose={() => {this.setState({dialogCardOpen: false})}}
                                                    badgeFamilyDetailSelection= {this.state.handleBadgeFamily}
                                                />
                                        </div>
                                        {user !== null && leadershipQualities.length > 0 && true //this.props.screen !== "desktop"
                                        &&
                                            this.renderTabs("horizontal")          
                                        }
                                        
                                    </Card>
                                    
                                    <Card style={{
                                            ...theme.CardBack, 
                                            marginTop: this.state.headerVisible? 0 : 200,
                                            paddingBottom: this.state.headerVisible? 0 : '30vh',
                                            overflow: value==='WordClouds'?'unset':'hidden',
                                            zIndex: 0,
                                        }} >
                                        
                                        {user !== null && leadershipQualities.length > 0
                                        ?
                                            <div>
                                                <div value={"Journey"} index={0} hidden={value !== "Journey"}>
                                                    {(value==='Journey' || this.state['Journey']) &&
                                                        <>
                                                            <Card style={theme.CardContentItem.rootFeed}>
                                                                <div style={{marginLeft: 20, marginRight: 20, marginBottom: 20}}>
                                                                    <h4 style={theme.CardContent.titleText}>
                                                                        {'Personal Vision'} 
                                                                    </h4>
                                                                    <GoalOverview
                                                                        user={user}
                                                                        showProgramlook={true}
                                                                        defaultSet={this.props.defaultSet || undefined}
                                                                        features={['goal']}
                                                                        type='quick'
                                                                        leadershipCategories={this.props.leadershipCategories}
                                                                        leadershipQualities={this.props.leadershipQualities}
                                                                    />
                                                                    <p style={theme.textSupportCenter}> 
                                                                        North-star goal &bull; Vision statement
                                                                    </p>
                                                                </div>
                                                            </Card>
                                                            <Card style={theme.CardContentItem.rootFeed}>
                                                                <div style={{marginLeft: 20, marginRight: 20, marginBottom: 20}}>
                                                                    <VizSensor
                                                                            partialVisibility={true}
                                                                            onChange={(isVisible) => { 
                                                                                //console.log('isVisible_Intention', isVisible, this.state)
                                                                                if(isVisible && this.state['isVisible_Intention'] !== true && this._isMounted) { 
                                                                                    this.setState({['isVisible_Intention'] : true});
                                                                                }
                                                                            }}
                                                                        >
                                                                        <div style={{height: (this.state['isVisible_Intention'] === true ? 0 : '70vh')}}>  <img src={pixel} alt=''/></div>
                                                                    </VizSensor>
                                                                    {this.state['isVisible_Intention'] &&
                                                                        <JournalIntentionCheck 
                                                                            user={user}
                                                                            screen={screen}
                                                                            showRecentGoalsOnly={true}
                                                                            subscriber={this.state.subscriber || false}
                                                                            open={this.state['open_Updates'] || false}
                                                                            updateScore={(score) => { if(this._isMounted) this.setState({actionUpdatesScore: score})}}
                                                                            leadershipCategories={leadershipCategories}
                                                                            leadershipQualities={leadershipQualities} 
                                                                            refreshQualities = {this.props.refreshQualities || undefined}
                                                                            refreshCategories = {this.props.refreshCategories || undefined}
                                                                            historyLimit={20}
                                                                            visualLimit={6}
                                                                            linkToContent = {true}
                                                                            handleChangeTab = {(newTab) => this.handleChange(undefined, newTab)}
                                                                        />
                                                                    }
                                                                </div>
                                                            </Card>
                                                            <Card style={theme.CardContentItem.rootFeed}>
                                                                <div style={{marginLeft: 20, marginRight: 20, marginBottom: 20}}>
                                                                    <VizSensor
                                                                            partialVisibility={false}
                                                                            onChange={(isVisible) => { 
                                                                                //console.log('isVisible_Timeline', isVisible, this.state)
                                                                                if(isVisible && this.state['isVisible_Timeline'] !== true && this._isMounted) { 
                                                                                    this.setState({['isVisible_Timeline'] : true});
                                                                                }
                                                                            }}
                                                                        >
                                                                        <div style={{height: (this.state['isVisible_Timeline'] === true ? 0 : 100)}}>  <img src={pixel} alt=''/></div>
                                                                    </VizSensor>
                                                                    {this.state['isVisible_Timeline'] &&
                                                                        <AssessmentTimeline
                                                                            user = {user}
                                                                            title = "Journal"
                                                                            assessmentKey = 'achievementGoal'
                                                                            assessmentText = "Motivations &bull; Highlights &bull; Mood"
                                                                            showAssessmentSlider = {true}
                                                                            assessmentQuestion = "How satisfied are you currently with:"
                                                                            linkToContent = {true}
                                                                            handleChangeTab = {(newTab) => this.handleChange(undefined, newTab)}
                                                                        />
                                                                    }
                                                                </div>
                                                            </Card>
                                                        </>
                                                    }
                                                    {(value==='Journey' || this.state['Journey']) &&
                                                        <SnackbarInfo 
                                                            alertText = 'Define your long term vision and north-star goal.'
                                                            alertName = 'visionintro'
                                                            userId = {this.context.uid}
                                                            />
                                                    }
                                                </div>
                                                <div value={"Sub-Goals"}  index={0} hidden={value !== "Sub-Goals"}>
                                                    {(value==='Sub-Goals' || this.state['Sub-Goals']) &&
                                                    <>
                                                        <JournalActivities 
                                                            checkMode={true}
                                                            filterMode={true}
                                                            groupItems={true}
                                                            showTips={true}
                                                            user={user} 
                                                            defaultSet={this.props.defaultSet || undefined}
                                                            leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities}
                                                            historyLimit={30}
                                                            showEmptyMessage={true}
                                                            hideDone={true}
                                                            visualLimit={this.state.historyLimit || 12}
                                                            handlerMoment={(moment) => this.setState({animateMomentByName: moment})}
                                                        />
                                                            {(!this.state.subscriber)
                                                                &&
                                                                <Card value={"Sub-Goals"} index={0} style={theme.CardContentItem.CardContentSpace} hidden={value !== "Sub-Goals"}>
                                                                    {this.nextUnlockCard('Limited to latest 3 goals', 'Subscribe to a plan to access all goals.')}
                                                                </Card>
                                                            }
                                                    </>
                                                    }
                                                    {(value==='Sub-Goals' || this.state['Sub-Goals']) &&
                                                        <SnackbarInfo 
                                                            alertText = 'Rocky identifies goals, events, or intentions during your interaction.'
                                                            alertName = 'goalpriointro'
                                                            userId = {this.context.uid}
                                                            />
                                                    }
                                                </div>

                                                <div value={"Assessments"} index={1} hidden={value !== "Assessments"}>
                                                    {true &&
                                                        <Card style={theme.CardContentItem.CardContentSpace}>
                                                            <CardAssessmentShop 
                                                                defaultSet={this.props.defaultSet || undefined}
                                                                showSummary = {true}
                                                                allowDialog = {true}
                                                                leadershipCategories={leadershipCategories}
                                                                leadershipQualities={leadershipQualities} 
                                                                openDefaultAssessment = {this.state.openAssessments || false}
                                                                openOCEANAssessment = {this.state.openOCEANAssessment || false}
                                                                handlerClose= {() => this.setState({openAssessments : false, openOCEANAssessment: false})}
                                                                user={user}
                                                                screen={screen}
                                                                rerender={value !== "Assessments"? false : true}
                                                                tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                            />
                                                            {user?.userAssessmentUpdatedAt !== undefined && (value === 'Assessments' || this.state['Assessments'])
                                                            &&
                                                                <div style={{paddingTop: 40}}>
                                                                    <CardAssessmentFactors 
                                                                        user={user}
                                                                        isCoach={this.props.isCoach || false}
                                                                        noMargin={true}
                                                                        statspage={true}
                                                                        //engagementStats={this.state.engagementStats || undefined}
                                                                        //mainCategory ={this.state.mainCategory} 
                                                                        //mainQuality ={this.state.mainQuality}
                                                                        //mainQualitiesDicts={this.state.mainQualitiesDicts}
                                                                        handlerNext={() => this.setState({openOCEANAssessment: true})}
                                                                        //personalityTraits={user.userAssessment?.['O'] ? user.userAssessment : undefined}
                                                                        //engagementBenchmarkQuality= {400}
                                                                        leadershipCategories={leadershipCategories}
                                                                        leadershipQualities={leadershipQualities} 
                                                                        tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                                    />
                                                                </div>
                                                            }
                                                        </Card>
                                                    }
                                                </div>
                                                <div value={"Goal-List"}  index={0} hidden={value !== "Goal-List"}>
                                                    {(value==='Goal-List' || this.state['Goal-List']) &&
                                                        <MapGoals 
                                                            user={user} 
                                                            leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities}
                                                            rerender={value !== "Goal-List"? false : true}
                                                        />
                                                    }
                                                    {(value==='Goal-List' || this.state['Goal-List']) &&
                                                        <SnackbarInfo 
                                                            alertText = 'The goals and activities collected from the chats. Click on the status of each item to change the details.'
                                                            alertName = 'goalmapintro'
                                                            userId = {this.context.uid}
                                                            />
                                                    }
                                                </div>
                                                <div value={"Modules"}  index={0} hidden={value !== "Modules"}>
                                                    {(value==='Modules' || this.state['Modules']) &&
                                                        <MapQualities 
                                                            user={user} 
                                                            leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities}
                                                            rerender={value !== "Modules"? false : true}
                                                        />
                                                    }
                                                    {(value==='Modules' || this.state['Modules']) &&
                                                        <SnackbarInfo 
                                                            alertText = 'The topics and activities collected from the chats. Click on the status of each item to change the details.'
                                                            alertName = 'goaltopicsintro'
                                                            userId = {this.context.uid}
                                                            />
                                                    }
                                                </div>
                                                <div value={"Tasks"}  index={0} hidden={value !== "Tasks"}>
                                                    {(value==='Tasks' || this.state['Tasks']) &&
                                                        <MapTasks 
                                                            user={user} 
                                                            leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities}
                                                            rerender={value !== "Tasks"? false : true}
                                                            />
                                                    }
                                                    {(value==='Tasks' || this.state['Tasks']) &&
                                                        <SnackbarInfo 
                                                            alertText = 'The tasks and activities collected from the chats. Click on the status of each item to change the details.'
                                                            alertName = 'goaltasksintro'
                                                            userId = {this.context.uid}
                                                            />
                                                    }
                                                </div>
                                                {/** 
                                                <div value={"Mindset"}  index={0} hidden={value !== "Mindset"}>
                                                    {(value==='Mindset' || this.state['Mindset']) &&
                                                        <MapMindset user={user} leadershipCategories={leadershipCategories}
                                                        leadershipQualities={leadershipQualities}/>
                                                    }
                                                    {(value==='Mindset' || this.state['Mindset']) &&
                                                        <SnackbarInfo 
                                                            alertText = 'The Goal Map show yous.'
                                                            alertName = 'goalmapintro'
                                                            userId = {this.context.uid}
                                                            />
                                                    }
                                                </div>
                                                */}
                                                <div value={"Victories"}  index={0} hidden={value !== "Victories"}>
                                                    {(value==='Victories' || this.state['Victories']) &&
                                                        <>
                                                            <JournalActivities 
                                                                checkMode={true}
                                                                user={user} 
                                                                defaultSet={this.props.defaultSet || undefined}
                                                                leadershipCategories={leadershipCategories}
                                                                leadershipQualities={leadershipQualities}
                                                                historyLimit={12}
                                                                specialQuery={'showDoneOnly'}
                                                                hideReminder={true}
                                                                visualLimit={12}
                                                                handlerMoment={(moment) => this.setState({animateMomentByName: moment})}
                                                                />
                                                            <JournalActivities 
                                                                checkMode={true}
                                                                user={user} 
                                                                defaultSet={this.props.defaultSet || undefined}
                                                                leadershipCategories={leadershipCategories}
                                                                leadershipQualities={leadershipQualities}
                                                                historyLimit={12}
                                                                showEmptyMessage={true}
                                                                hideDone={true}
                                                                hideOffTrack={true}
                                                                hideFailed={true}
                                                                hideNew={true}
                                                                visualLimit={this.state.historyLimit || 12}
                                                                handlerMoment={(moment) => this.setState({animateMomentByName: moment})}
                                                                />
                                                        </>
                                                    }
                                                    {(value==='Victories' || this.state['Victories']) &&
                                                        <SnackbarInfo 
                                                            alertText = 'Victories are accomplished or on-track intentions from the chats or your favorite content. Click on a box to set reminders.'
                                                            alertName = 'actionsintro'
                                                            userId = {this.context.uid}
                                                            />
                                                    }
                                                </div>
                                                {(this.state.subscriber || (this.state.xpLevels?.journal?.valid ))
                                                    ?
                                                        <div value={"Diary"}  index={0} hidden={value !== "Diary"}>
                                                            {/***
                                                            (value==='Diary' || this.state['Diary'])
                                                                && user.engagementCounter!== undefined && user.engagementCounter>=30
                                                                &&
                                                                <div style={{width:"100%", marginBottom:30}}>
                                                                    <Accordion style={{boxShadow:'none'}}
                                                                            onChange={(e,expanded) => {this.setState({additional_note_expanded: expanded})}}>
                                                                        <AccordionSummary
                                                                            expandIcon={this.state.additional_note_expanded? <CancelIcon /> : <AddCircleOutlineIcon />}
                                                                            aria-label="About-Expand"
                                                                            aria-controls="About-content"
                                                                            id="About-header"
                                                                            >
                                                                            Add additional note
                                                                        </AccordionSummary>
                                                                        <AccordionDetails  style={{padding:0}} >        
                                                                            <DiaryManual />
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </div> 
                                                                */
                                                            }
                                                            {(value==='Diary' || this.state['Diary']) &&
                                                                <JournalListSwipe 
                                                                    allowManualAdding={true} 
                                                                    monthlyView={true}
                                                                    rerender={value !== "Diary"? false : true}
                                                                    />
                                                                //<JournalList allowManualAdding={true} />
                                                            }
                                                            {(value==='Diary' || this.state['Diary']) &&
                                                                <SnackbarInfo 
                                                                    alertText = 'In the diary section you can find all the chats that you had with Rocky. Click on a related box to see the details.'
                                                                    alertName = 'diaryintro'
                                                                    userId = {this.context.uid}
                                                                    />
                                                            }
                                                        </div>
                                                    :
                                                        <Card value={"Diary"} index={0} style={theme.CardContentItem.CardContentSpace} hidden={value !== "Diary"}>
                                                            {this.nextUnlockCard('Unlock Chat Diary', this.state.xpLevels?.journal?.text || "")}  
                                                        </Card>
                                                }
                                                {
                                                /*** 
                                                * user.engagementCounter!== undefined && user.engagementCounter>=175
                                                    ?
                                                    <div value={"Journey"}  index={0} hidden={value !== "Journey"}>
                                                        {(value==='Journey' || this.state['Journey']) &&
                                                            <JourneyMap user={user} leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities}/>
                                                        }
                                                        {(value==='Journey' || this.state['Journey']) &&
                                                            <SnackbarInfo 
                                                                alertText = 'The journey is a summary of your goals and selected coaching modules.'
                                                                alertName = 'journeyintro'
                                                                userId = {this.context.uid}
                                                                />
                                                        }
                                                    </div>
                                                    :
                                                        <Card value={"Journey"} index={0} style={theme.CardContentItem.CardContentSpace} hidden={value !== "Journey"}>
                                                            {this.nextUnlockCard('Unlock Growth Journey with 175 Experience Points', 175)}  
                                                        </Card>
                                                    */
                                                }
                                                {user.engagementCounter!== undefined && user.engagementCounter>=250
                                                    ?
                                                        <div value={"WordClouds"} index={1} hidden={value !== "WordClouds"}>
                                                            {(value==='WordClouds' || this.state['WordClouds']) &&
                                                                <WordCloudList 
                                                                    rerender={value !== "WordClouds"? false : true}
                                                                />
                                                            }
                                                            {(value==='WordClouds' || this.state['WordClouds']) &&
                                                                <SnackbarInfo 
                                                                    alertText = 'In these word clouds you can see your most used key words of each month.'
                                                                    alertName = 'cloudsintro'
                                                                    userId = {this.context.uid}
                                                                    />
                                                            }
                                                        </div>
                                                    :
                                                        <Card value={"WordClouds"} index={1} style={theme.CardContentItem.CardContentSpace} hidden={value !== "WordClouds"}>
                                                            {this.nextUnlockCard(' Unlock Word Clouds with 250 Experience Points', 250)} 
                                                        </Card>
                                                }
                                                
                                            </div>
                                        :
                                            <div>
                                                <p >Please add a journal chat to see some information here</p>
                                                <TodayAdd
                                                    user={user}
                                                    defaultSet={this.props.defaultSet || undefined}
                                                />
                                            </div>
                                        }
                                    </Card>
                                </Card>
                    
                        }
                    </Grid>
                    <Grid item xs={screen==="desktop" ? 2 : false} ></Grid>
                </Grid>
            ); // end return
        }// end if user
        else {
            return(
                <Card style={theme.CardContent}>
                    <p style={theme.CardContent.titleText}>Please wait a moment</p>
                </Card>
            )// end 2nd return
        } // end else
    }// end render

    renderTabs(orientation = "horizontal") {
        const { value } = this.state;
        const { user, theme, screen } = this.props;
        if(orientation === 'vertical'){
            return(
                <>
                    <Link to={ROUTES.HOME}>
                        <span><HomeIcon style={theme.inlineIconLeft}/>Home</span>
                    </Link>
                    <hr/>
                    <Link to={ROUTES.JOURNAL} onClick={() => (this.handleChange(undefined, "Journey"))}>
                        <span><AssignmentTurnedInIcon style={theme.inlineIconLeft}/>Journey</span>
                    </Link>
                    {value !== "Journey" &&
                        <>
                            {true && 
                                <Link onClick={() => (this.handleChange(undefined, value))}>
                                    <li style={{paddingTop: 8, paddingLeft: 10, whiteSpace: 'nowrap'}}> {value} </li>
                                </Link>
                            }
                        </>
                    }
                </>
            )
        }
        else{
            return(
                <Tabs
                    value={value}
                    orientation={orientation}
                    onChange={this.handleChange}
                    aria-label="Coaching Modules"
                    variant="scrollable"
                    scrollButtons="auto"
                    initialselectedindex={
                        user.engagementCounter=== undefined ? "Vision"
                        :
                            user.engagementCounter>=125? "Sub-Goals" : "Vision"
                        }
                    style={{
                        marginTop: 20,
                        marginBottom: (screen==="desktop" ? 0 : -1),
                        marginLeft: -20,
                        width: 'calc(100% + 40px)'
                    }}
                    ref={el => this.containerLine = el}
                >
                    {false && this.props.screen==="desktop" && 
                        <Tab value="Home" label={<span><HomeIcon style={theme.inlineIconLeft}/>Home</span>}/>
                    }
                    {true
                        &&
                        <Tab value={"Journey"} 
                            label="Journey"/>
                    }
                    {false //user.engagementCounter!== undefined && user.engagementCounter>=0
                        &&
                        <Tab value={"Goal-List"} 
                        label="Goal-List"/>
                    }
                    {true //user.engagementCounter!== undefined && user.engagementCounter>=0
                        &&
                        <Tab value={"Sub-Goals"} 
                        label="Sub-Goals"/>
                    }
                    {true //user.engagementCounter!== undefined && user.engagementCounter>=0
                        &&
                        <Tab value={"Diary"} 
                        label="Chat Diary"/>
                    }
                    {true // user.engagementCounter!== undefined && user.engagementCounter>=125
                        &&
                        <Tab value={"Victories"} 
                        label="Victories"/>
                    }
                    <Tab value={"Assessments"} 
                        label="Assessments"/>
                    {false  //user.engagementCounter!== undefined && user.engagementCounter>=0
                        &&
                        <Tab value={"Modules"} 
                        label="Modules"/>
                    }
                    {false && user.engagementCounter!== undefined && user.engagementCounter>=25
                        &&
                        <Tab value={"Tasks"} 
                        label="Tasks"/>
                    }
                    {
                        /**
                        true //user.engagementCounter!== undefined && user.engagementCounter>=0
                        &&
                        <Tab value={"Mindset"} 
                        label="Mindset"/>
                        */
                    }
                    {//user.engagementCounter!== undefined && user.engagementCounter>=20
                    // &&
                    // <Tab value={"Journey"} 
                    //  label="Growth Journey"/>
                    }
                    {
                    //user.engagementCounter!== undefined && user.engagementCounter>=175
                    //    &&
                    //    <Tab value={"WordClouds"} 
                    //    label="Monthly Wordclouds"/>
                    }
                </Tabs> 
            )
        }
    }

    getLatestBatch(){
        if( this._isMounted){
            SetStateLoadingBatches(this, this.props.firebase, this.context.uid, 
                undefined , 10).then( () => {
                    //console.log('received handler')
                    if( this._isMounted){
                        this.setState({ loading: false });
                    }
                })
        }
    }

    handlerOpenBadgeDetails = (badgeFamily) => {
        if(badgeFamily !== undefined && badgeFamily!== "" && badgeFamily!== "all" && this.props.badgeFamilyDetailSelection === undefined){
            this.setState({ 
                handleBadgeFamily:badgeFamily,
            });
            this.setState({ 
                handleBadgeFamily:badgeFamily,
                dialogCardOpen: true,
            });
        }
        else{
            this.setState({ 
                handleBadgeFamily:'all',
                handleOpen: true,
            });

        }
    }
    nextUnlockCard = (title, subtitle) => {
        return(
            <div style={{margin: 0}}>
                <BadgeCard
                    key={title}
                    backgroundColor ={"transparent"}
                    image={undefined} 
                    avatarImage = { undefined }
                    title = {title}
                    subtitle = {subtitle}
                    strongSubtitle = {false}
                    button = { undefined }
                    onClick={ () => {
                        this.props.history.push({
                            pathname: ROUTES.PROFILE,
                            state: {showSubscriptions: true}
                        });
                    } }
                    clickText= { 'Unlock' }
                />
            </div>
        )
    }
} // end class

export default compose(
    withFirebase,
    withRouter,
    withStyles(styles, { withTheme: true })
)(HomeJournal);
  
