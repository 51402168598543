
import React from 'react';
import {default as RockyWebMediaPlayer} from "plyr-react";
/////OLDER version
//import "plyr-react/dist/plyr.css";
///// for newer versions
import "plyr-react/plyr.css"
import DialogFlagError from '../Modules/DialogFlagError';
import backgroundImage from "../../images/loading-results.jpg";

const RenderMediaAudio = React.memo((props) => {
    try{
        return(
            <div>
                <DialogFlagError
                    position="absolute"
                    title="Report issue with audio file"
                    issue={"Issue URL Audio - " + props.contentData.s3Url}
                    item={"Content URL Audio - " + props.contentData.title + " - " + props.contentData.id}
                    dict={props.contentData}
                />
                <RockyWebMediaPlayer
                    width={"100%"}
                    style={{height:90}}
                    //playr'
                    source={{
                        type: "audio",
                        sources: [{
                            //TEST AUDIO URL
                            //src : 'https://cdn.plyr.io/static/demo/Kishi_Bashi_-_It_All_Began_With_a_Burst.mp3',
                            src: props.contentData.s3Url,
                        }],
                        title : props.contentData.title,
                        poster: props.contentData.url || undefined,
                    }}
                    options={{
                        enabled: (props.enabled || true),
                    }}
                    //react-player'
                    url={props.contentData.s3Url}
                    title={props.contentData.title}
                    audio={props.contentData.s3Url}
                    thumbnail={props.contentData.url || undefined}
                />
            </div>
        )
    }
    catch(e){
        return(
            <span>Media File is currently not available.</span>
        )
    }
}, (prevProps, nextProps) => {
    if (prevProps.contentData?.s3Url && prevProps.contentData?.s3Url!== '' && prevProps.contentData?.s3Url === nextProps.contentData?.s3Url) {
      return true; // props are equal
    }
    return false; // props are not equal -> update the component
})

const RenderMediaVideo = React.memo((props) => {
    try{
        return(
            <div>
                <DialogFlagError
                    position="absolute"
                    title="Report issue with video"
                    issue={"Issue URL Video - " + props.contentData.s3Url}
                    item={"Content URL video - " + props.contentData.title + " - " + props.contentData.id}
                    dict={props.contentData}
                />
                <RockyWebMediaPlayer
                    width={"100%"}
                    //playr'
                    source={{
                        type: "video",
                        sources: [{
                            src: props.contentData.s3Url,
                            provider: 
                                props.contentData.s3Url.includes("imeo.com/") 
                                ?   'vimeo'
                                :   props.contentData.s3Url.includes("outube.com/") 
                                    ?   'youtube'
                                    :   undefined,
                            // TEST URL
                            //src: 'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4',
                        },
                        {
                            src: 'hso3oR8PJss',
                            provider: 'youtube',
                        }],
                        title : props.contentData.title,
                        poster: props.contentData.url || undefined,
                    }}
                    //pip={true}
                    playsInline={true}
                    options={{
                        controls: [
                            'play-large',
                            'rewind',
                            'play',
                            'fast-forward',
                            'progress',
                            'current-time',
                            'duration',
                            'mute',
                            'volume',
                            'settings',
                            //'pip',
                        ],
                        captions: { active: false, language: 'auto', update: false },
                        ads: { enabled: false, publisherId: '', tagUrl: '' },
                        youtube: {noCookie: true},
                        enabled: (props.enabled || true),
                    }}
                    //react-player'
                    url={props.contentData.s3Url}
                    title={props.contentData.title}
                    video={props.contentData.s3Url}
                    thumbnail={props.contentData.url || undefined}
                />
            </div>
        )
    }
    catch(e){
        return(
            <span>Media File is currently not available.</span>
        )
    }
}, (prevProps, nextProps) => {
    if (prevProps.contentData?.s3Url && prevProps.contentData?.s3Url!== '' && prevProps.contentData?.s3Url === nextProps.contentData?.s3Url) {
      return true; // props are equal
    }
    return false; // props are not equal -> update the component
})

const RenderMediaYoutube = React.memo((props) => {
    //console.log("child component", props);
    try{
        return(
            <div>
                <DialogFlagError
                    position="absolute"
                    title="Report issue with video"
                    issue={"Issue Youtube video - " + props.contentData.youtubeId}
                    item={"Content Youtube video - " + props.contentData.title + " - " + props.contentData.id}
                    dict={props.contentData}
                />
                <RockyWebMediaPlayer
                    //playr'
                    source={{
                        type: "video",
                        sources: [{
                            src: props.contentData.youtubeId,
                            provider: 'youtube'
                        },
                        {
                            src: 'hso3oR8PJss',
                            provider: 'youtube'
                        }],
                        title : props.contentData.title,
                        poster: props.contentData.url || backgroundImage,
                        previewThumbnails: { enabled: false },
                    }}
                    //pip={true}
                    options={{
                        controls: [
                            'play-large',
                            'rewind',
                            'play',
                            'fast-forward',
                            'progress',
                            'current-time',
                            'duration',
                            'mute',
                            'volume',
                            'settings',
                            //'pip',
                        ],
                        enabled: (props.enabled || true),
                        resetOnEnd: true
                    }}
                    //react-player'
                    url={'https://www.youtube.com/watch?'+props.contentData.youtubeId}
                    config={{
                        youtube: {
                            playerVars: { showinfo: 1 }
                        }
                    }}
                    title={props.contentData.title}
                />
            </div>
        )
    }
    catch(e){
        console.log(e)
        return(
            <span>Media File is currently not available.</span>
        )
    }
}, (prevProps, nextProps) => {
    if (prevProps.contentData?.youtubeId && prevProps.contentData?.youtubeId!== '' && prevProps.contentData?.youtubeId === nextProps.contentData?.youtubeId) {
      return true; // props are equal
    }
    return false; // props are not equal -> update the component
})

export {RenderMediaYoutube, RenderMediaVideo, RenderMediaAudio};