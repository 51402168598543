import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { withAuthorization } from '../Session';
import { AuthUserContext } from '../Session';
import * as ROLES from '../../constants/roles';

import HomeCoach from '../Coach/HomeCoach';

const styles = {};

class CoachPage extends Component {
    static contextType = AuthUserContext;

    render() {
        return (
            <div>
                <HomeCoach 
                    defaultSet={this.props.defaultSet || undefined}
                    user={this.props.user}
                    location={this.props.location || undefined}
                    history={this.props.history || undefined}
                    leadershipCategories = {this.props.leadershipCategories}
                    leadershipQualities = {this.props.leadershipQualities}
                    screen={this.props.screen}
                />
            </div>
        );
    }
}

CoachPage.propTypes = {
    classes: PropTypes.object.isRequired
}

const condition = authUser => 
authUser && 
  (   authUser.roles?.includes(ROLES.ADMIN)
      || authUser.roles?.includes(ROLES.TEAM)
      || authUser.roles?.includes(ROLES.COACHADMIN)
      || authUser.roles?.includes(ROLES.REMOTECOACH)
);

export default compose(
    withStyles(styles),
    // withEmailVerification,
    withAuthorization(condition),
)(CoachPage);