//import palette from '../palette';

function muiStyle (palette) { return {
    root: {
        //padding: 10,
        color: palette.black,
        "& .MuiSelect-icon": {
            color: palette.secondary.main
        }
    }
}}

export default muiStyle;