import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import LoadingProgress from '../../Modules/LoadingProgress';

import { withFirebase } from '../../Firebase';
import { withAuthorization,
	 // withEmailVerification
       } from '../../Session';
import { AuthUserContext } from '../../Session';
import * as ROLES from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';

// MUI stuff
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import Table from '@material-ui/core/Table';
//import TableCell from '@material-ui/core/TableCell';
//import TableHead from '@material-ui/core/TableHead';
//import TableRow from '@material-ui/core/TableRow';
//import TableBody from '@material-ui/core/TableBody';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
//import ButtonSwitch from '@material-ui/core/Switch';
import {Column, Table, SortDirection} from 'react-virtualized';
import {  AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css'
import _ from "lodash";

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// Icons
import AddIcon from '@material-ui/icons/Add';

// Modules for parameter change
import SettingContentBase from '../Modules/SettingContentBase';
import SettingContentActive from '../Modules/SettingContentActive';
//import SettingContentNumber from '../Modules/SettingContentNumber';
import SettingContentArray from '../Modules/SettingContentArray';
//import SettingContentImage from '../Modules/SettingContentImage';
import SettingContentUploadImage from '../Modules/SettingContentUploadImage';
//import SettingContentRichText from '../Modules/SettingContentRichText';
//import SettingContentArraySelect from '../Modules/SettingContentArraySelect';
import SettingContentSelect from '../Modules/SettingContentSelect';

const styles = {
    table: {
        minWidth: 100,
    },
    subtitle: {
        marginTop: 20,
        fontWeight: 700,
    },
    addButton: {
        marginTop: 20,
    },
};

class AdminBadgesPage extends Component {
    render() {
        return (
            <div style={{backgroundColor: "#DDDDDD"}}>
                <Switch>
                    <Route exact path={ROUTES.ADMIN_BADGES_DETAILS} component={BadgesItem} />
                    <Route exact path={ROUTES.ADMIN} component={BadgesList} />
                    <Route exact path={ROUTES.ADMIN_BADGES} component={BadgesList} />
                </Switch>
            </div>
        );
    }
}

class BadgesListBase extends Component {
    _isMounted = false;
    static contextType = AuthUserContext;
    constructor(props) {
        super(props);
        const sortBy = 'active';
        const sortDirection = SortDirection.ASC;

        this.state = {
            loading: false,
            contents: [],
            contentsTab: [],
            contentsLabels: [],
            title: '',
            author: '',
            program: '',
            open: false,
            sortBy,
            sortDirection,
        };
        //this.sortState = createMultiSort(this.sort);
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            loading: true,
            open: false, 
            title: '',
            author: '',
            program: '',
            showTab: '',
            errorMessage : ''
        });

        var name = '';

        this.unsubscribe = this.props.firebase
        .badges()
        .orderBy('active', 'desc')
        .onSnapshot(snapshot => {
            if(this._isMounted){
                let contentsTab = [];
                let contentsTabList = [];
                let contentsLabels = [];

                var keySorting = 'category'
                var content = {}
                
                snapshot.forEach(doc =>{
                if(this.context.roles?.includes('ADMIN')){
                    if(this.context.programs.includes(doc.data().program) || doc.data().program===undefined){
                        name = doc.data()[keySorting];
                        content = doc.data();
                        if(name === undefined){
                            name = '---undefined---'
                        }
                        if(!(name in contentsTab)){
                            //contentsTab[doc.data().category]={name:doc.data().category, contents: []};
                            contentsTab[name] = [];
                            contentsTabList[name]= [];
                            contentsLabels.push(name );
                        }
                        contentsTab[name].push({ ...doc.data(), uid: doc.id });
                        contentsTabList[name].push({
                            active: content.active? 'Yes': 'No',
                            description: content.description || '-',
                            target : content.target || '-',
                            title:  content.title.length>30 ? (content.title.substring(0,29)+'...'): content.title,
                            program: content.program || '-',
                            name: content.name || '-',
                            parent: content.parent || '-',
                            family: content.family || '-',
                        });
                    } // end if true
                } // end of if ADMIN
                else if(this.context.roles?.includes('COACHADMIN')){
                    if(this.context.programs.includes(doc.data().program) && doc.data().program !=='default'){
                        name = doc.data()[keySorting];
                        content.doc.data();
                        if(name === undefined){
                            name = '---undefined---'
                        }
                        if(!(name in contentsTab)){
                            //contentsTab[doc.data().category]={name:doc.data().category, contents: []};
                            contentsTab[name] = [];
                            contentsTabList[name] = [];
                            contentsLabels.push(name );
                        }
                        contentsTab[name].push({ ...doc.data(), uid: doc.id });
                        contentsTabList[name].push({
                            active: content.active? 'Yes': 'No',
                            description: content.description || '-',
                            target : content.target || '-',
                            title:  content.title.length>30 ? (content.title.substring(0,29)+'...'): content.title,
                            program: content.program || '-',
                            name: content.name || '-',
                            parent: content.parent || '-',
                            family: content.family || '-',
                        });
                    }//end if program / no default
                } // end of if ADMIN
                });//end of snapshot


                //console.log('contents', contentsTab)
                if(this.state.showTab === undefined || this.state.showTab === ''){
                    this.setState({showTab: name})
                }

                this.setState({
                    contentsTab: contentsTab,
                    contentsTabList,
                    contentsLabels,
                    loading: false,
                });
                //console.log('total',contentsTabList['total'][0])
            }//end if mounted
        });
        
    }

  _sortList = ({sortBy, sortDirection}) => {
    const list = this.state.contentsTabList[this.state.showTab];
    //console.log('sortingbefore', sortBy ,sortDirection)
    let newList = _.sortBy(list, [sortBy]);
    if (sortDirection === SortDirection.DESC) {
        newList.reverse();
      }
      //console.log('sorting',newList, this.state.showTab )
    return newList;
  }
  _sort = ({sortBy, sortDirection}) => {
    const sortedList = this.state.contentsTabList;
    sortedList[this.state.showTab] = this._sortList({sortBy, sortDirection});
    this.setState({sortBy, sortDirection, contentsTabList: sortedList});
  }

  handleOpen = () => {
    //console.log('handleOpen');
    this.setState({ open: true });
  };

  handleClose = () => {
    //console.log('handleClose');
    this.setState({ open: false });
  };

  handleChange = (event) => {
    //console.log([event.target.name] + ": " + event.target.value);
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = () => {
    if(this.state.title !== '' && this.state.author !== '' && this.state.category !== '')
    {
      const timestamp = this.props.firebase.fieldValue.serverTimestamp();
      this.props.firebase.badges().add({
        title: this.state.title,
        author: [this.state.author],
        program: this.state.program,
        active: false,
        createdAt: timestamp
      });
      this.setState({errorMessage : 'Added content (category undefined)'})
    
    }
    else{
      this.setState({errorMessage : 'All three values have to be defined to add a content'})
    }
    

    this.setState({
      title: '',
      author: '',
      program: '',
    });

    this.handleClose();
  }

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
    }

  handleTabChange = (event, newValue) => {
    //console.log('new tab', newValue)
    this.setState({
      showTab: newValue
    });
  }

    render() {
        const { showTab, contentsTab, contentsLabels, loading } = this.state;
        //console.log('votes', maxVotes)
        return (
            <div style={{height: '100vh'}}>
                {loading && 
                    <LoadingProgress 
                        defaultSet={this.props.defaultSet}
                        type={'LoadingPageAnimated'} 
                        loadingText={"many, many, data"} 
                        firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                        allowRefresh={true}
                    />
                }
                <div>
                  <IconButton style={styles.addButton} onClick={this.handleOpen}>
                    <AddIcon />
                  </IconButton>
                  <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    fullWidth
                    maxWidth="sm">
                    <DialogContent>
                      <DialogTitle>Add a new content set</DialogTitle>
                      <form>
                        <TextField
                          name="title"
                          type="text"
                          label="1. Title"
                          placeholder="Enter title"
                          value={this.state.title}
                          onChange={this.handleChange}
                          fullWidth
                          style={ {fontSize: '16px'} }
                          />
                        <TextField
                          name="author"
                          type="text"
                          label="2. Author"
                          placeholder="Enter content author name"
                          value={this.state.author}
                          onChange={this.handleChange}
                          fullWidth
                          style={ {fontSize: '16px'} }
                          />
                        <p>3. Select program</p>
                        <Select
                          value={this.state.program}
                          onChange={this.handleChange}
                          name='program'
                          >
                          {this.context.programs.map(entry => {
                                return (
                                    <MenuItem key={"add_"+entry} value={entry}
                                    >
                                    {entry}
                                    </MenuItem>
                                  );
                                })
                            
                          }
                            
                        </Select>
                        
                        </form>
                        <DialogActions>
                        <Button onClick={this.handleClose}>
                          Cancel
                        </Button>
                        <Button onClick={this.handleSubmit}>
                          Save
                        </Button>
                      </DialogActions>
                    </DialogContent>
                  </Dialog>
                </div>
                {this.state.errorMessage !== '' && 
                    <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                }
                <Tabs
                  variant="scrollable"
                  scrollButtons="auto"
                  onChange={this.handleTabChange}
                  initialselectedindex={0}
                  value={showTab}
                >
                  {contentsLabels.length>0 && 
                    contentsLabels.map( (key) => (
                      <Tab 
                        value={key} 
                        key={key}
                        label={key + " ("+contentsTab[key].length+")"}/>
                    ))
                  }
                </Tabs>
          
                {contentsLabels.length>0 && 
                  contentsLabels.map( (key) => (
                    
                    <div 
                      value={key} 
                      key={key} 
                      hidden={showTab !==key}>
                        <AutoSizer>
                        {({ height, width }) => (
                            <Table
                                width={width}
                                height={700}
                                headerHeight={20}
                                rowHeight={30}
                                rowCount={this.state.contentsTabList[key].length}
                                sort={this._sort}
                                sortBy={this.state.sortBy}
                                sortDirection={this.state.sortDirection}
                                rowGetter={({index}) => this.state.contentsTabList[key][index]}
                                onRowClick={({ event, index, rowData })  => {this.setState({
                                    currentContentId: this.state.contentsTabList[key][index].name, dialogOpen:true})}}
                            >
                                <Column label="Active" dataKey="active" width={100} disableSort={false}  />
                                <Column label="Title" dataKey="title" width={200} disableSort={false} />
                                <Column label="Description" dataKey="description" width={200} disableSort={false} />
                                <Column label="Target" dataKey="target" width={100} disableSort={false} />
                                <Column label="Program" dataKey="program" width={100} disableSort={false} />
                                <Column label="Family" dataKey="family" width={200} disableSort={false} />
                                <Column label="Parent" dataKey="parent" width={200} disableSort={false} />
                                <Column label="ID" dataKey="name" width={200} disableSort={false}  />
                            </Table>
                        )}
                        </AutoSizer>
                    </div>
                  ))
                }

                <BadgesItemDialog 
                    contentId={this.state.currentContentId}
                    openDialog={this.state.dialogOpen || false}
                    closeDialog={(e) => {this.setState({dialogOpen : false })}}
                    contentsLabels={contentsLabels || undefined}
                />
            </div>
        );
    }
}

class BadgesItemBase extends Component {
    _isMounted = false;
    static contextType = AuthUserContext;
    constructor(props) {
        super(props);
        var cid = this.props.match.params.id.toString();
        var errorMessage = '';
        var contentsLabels = ['tips, quotes'];

        // delivered from db section pool
        if(this.props.location['state'] !== undefined){
        contentsLabels = this.props.location.state.contentsLabels;
        }
        else{
        errorMessage = "Please load from original admin page";
        }
        //console.log('location',cid)
        this.state = {
            loading: false,
            content: null,
            cid: cid,
            contentsLabels: contentsLabels,
            errorMessage: errorMessage,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.state.cid) {
        
        //console.log('myid',this.state.contentsLabels)
        this.setState({ loading: true });

        this.unsubscribe= this.props.firebase
        .badge(this.state.cid)
        .onSnapshot(snapshot => {
            //console.log('data',snapshot.data())
            if(this._isMounted){
                this.setState({
                    content: snapshot.data(),
                    loading: false,
                });
            }
        });

        this.getleadershipCategoriesArray();
        this.getleadershipQualitiesArray();
        this.getBotUtterArray();
        }

    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
        this.setState({});
    }

  getleadershipCategoriesArray() {
    var myArray = [];
    var myArrayText = [];
    this.props.firebase
      .leadershipCategories()
      .orderBy('text', 'asc')
      .where('active', '==', true)
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
          if(this.context.programs.includes(doc.data().program)){
            myArray.push(doc.data().value);
            myArrayText.push(doc.data().text);
          }
        });
      });
    this.setState({
      leadershipCategories: myArray,
      leadershipCategoriesText: myArrayText
    });
  }
  getleadershipQualitiesArray()  {
    var myArray = [];
    var myArrayText = [];
    this.props.firebase
      .leadershipQualities()
      .where('active', '==', true)
      .orderBy('text', 'asc')
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
          if(this.context.programs.includes(doc.data().program)){
            myArray.push(doc.data().value);
            myArrayText.push(doc.data().text + " (" + doc.data().category + ")");
          };
        });
      });
    this.setState({
      leadershipQualities: myArray,
      leadershipQualitiesText: myArrayText
    });
     
  }
  getBotUtterArray()
  {
    var myArray = [];
    var myArrayText = [];
    this.props.firebase
      .textBotUtter()
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            myArray.push(doc.data().value);
            myArrayText.push(doc.data().text + " (" + doc.data().value + ")");
        });
      });
    this.setState({
      botUtters: myArray,
      botUttersText: myArrayText
    });
     
  }

  render() {
    const { content, loading } = this.state;
    
     
    //console.log('my context', this.context)
    return (
      <div>
        <h5 style={styles.subtitle}>Content ({this.props.match.params.id})</h5>
        {loading && 
            <LoadingProgress 
                defaultSet={this.props.defaultSet}
                type={'LoadingPageAnimated'} 
                loadingText={"many, many, data"} 
                firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                allowRefresh={true}
            />
        }

        {this.state.errorMessage !== '' && 
          <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
        }
        {content && (
          <div>
            <SettingContentActive 
              dbUpdateLink={this.props.firebase.badge(this.state.cid)}
              title={"Active Content"} 
              content={content} 
              params={['active']}
              />
            <SettingContentBase 
              dbUpdateLink={this.props.firebase.badge(this.state.cid)}
              title={"Basic Text Settings"} 
              titleFormat={true}
              content={content} 
              params={['title', 'description']}
              />
            <SettingContentSelect 
              dbUpdateLink={this.props.firebase.badge(this.state.cid)}
              title={"Original program"} 
              content={content} 
              params={['program']}
              selectOptions={this.context.programs}
              />
            <SettingContentUploadImage
              dbUpdateLink={this.props.firebase.badge(this.state.cid)}
              title={"Link to image used (URL with https)"} 
              content={content} 
              previewImageSize={true}
              params={['iconUrl']}
              uploadDatabase={this.props.firebase.storageImages('badges/'+this.state.cid)}
              />
            <SettingContentBase 
              dbUpdateLink={this.props.firebase.badge(this.state.cid)}
              title={"Parent Badge"} 
              content={content} 
              params={['parent']}
              />
            <SettingContentArray 
              dbUpdateLink={this.props.firebase.badge(this.state.cid)}
              title={"Internal author"} 
              content={content} 
              params={['author']}
              />

          </div>
        )}
      </div>
    );
  }
}

class BadgesItemBaseDialog extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        var errorMessage = '';
        var contentsLabels = ['tips, quotes'];
    
        contentsLabels = this.props.contentsLabels;
        this.state = {
            loading: false,
            content: null,
            cid:'',
            contentsLabels: contentsLabels,
            errorMessage: errorMessage,
        };
    }
  
    componentDidMount() {
        this._isMounted = true;
        this.getleadershipCategoriesArray();
        this.getleadershipQualitiesArray();
        this.getBotUtterArray();
    }
  
    componentDidUpdate() {
        //console.log('myid',this.props.contentId)
        if (this.props.contentId && this.state.cid !== this.props.contentId) {
            console.log('myidopen', this.props.contentId)
            this.setState({ loading: true, cid: this.props.contentId });
            this.unsubscribe = this.props.firebase
                .badge(this.props.contentId)
                .onSnapshot(snapshot => {
                    //console.log('data',snapshot.data())
                    if(this._isMounted ){
                        this.setState({
                            content: snapshot.data(),
                            loading: false,
                        });
                    }
                });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.setState({content:null});
        if (this.unscrubscribe !== undefined ) this.unsubscribe();
    }
  
    getleadershipCategoriesArray() {
      var myArray = [];
      var myArrayText = [];
      this.props.firebase
        .leadershipCategories()
        .orderBy('text', 'asc')
        .where('active', '==', true)
        .get()
        .then(snapshot => {
          if (snapshot.empty) {
            return;
          }
          snapshot.forEach(doc => {
            if(this.context.programs.includes(doc.data().program)){
              myArray.push(doc.data().value);
              myArrayText.push(doc.data().text);
            }
          });
        });
      this.setState({
        leadershipCategories: myArray,
        leadershipCategoriesText: myArrayText
      });
    }
    getleadershipQualitiesArray()  {
      var myArray = [];
      var myArrayText = [];
      this.props.firebase
        .leadershipQualities()
        .where('active', '==', true)
        .orderBy('text', 'asc')
        .get()
        .then(snapshot => {
          if (snapshot.empty) {
            return;
          }
          snapshot.forEach(doc => {
            if(this.context.programs.includes(doc.data().program)){
              myArray.push(doc.data().value);
              myArrayText.push(doc.data().text + " (" + doc.data().category + ")");
            };
          });
        });
      this.setState({
        leadershipQualities: myArray,
        leadershipQualitiesText: myArrayText
      });
       
    }
    getBotUtterArray()  {
      var myArray = [];
      var myArrayText = [];
      this.props.firebase
        .textBotUtter()
        .get()
        .then(snapshot => {
          if (snapshot.empty) {
            return;
          }
          snapshot.forEach(doc => {
              myArray.push(doc.data().value);
              myArrayText.push(doc.data().text + " (" + doc.data().value + ")");
          });
        });
      this.setState({
        botUtters: myArray,
        botUttersText: myArrayText
      });
       
    }
  
    render() {
        const { content, loading } = this.state;
        
         
        //console.log('my context', this.context)
        return (
            <Dialog
                open={this.props.openDialog}
                onClose={this.props.closeDialog}
                >
                <DialogTitle >
                    <IconButton onClick={this.props.closeDialog}>
                        (X)
                    </IconButton>
                    Modify Badges
                </DialogTitle>
                <DialogContent >
                    <div>
                    <h5 style={styles.subtitle}>Content ({this.props.contentId})</h5>
                    {loading && 
                        <LoadingProgress 
                          defaultSet={this.props.defaultSet}
                          type={'LoadingPageAnimated'} 
                          loadingText={"many, many, data"} 
                          firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                          allowRefresh={true}
                      />
                    }
            
                    {this.state.errorMessage !== '' && 
                        <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                    }
                    {content && (
                        <div>
                            <SettingContentActive 
                                dbUpdateLink={this.props.firebase.badge(this.state.cid)}
                                title={"Active Content"} 
                                content={content} 
                                params={['active']}
                                />
                            <SettingContentBase 
                                dbUpdateLink={this.props.firebase.badge(this.state.cid)}
                                title={"Basic Text Settings"} 
                                titleFormat={true}
                                content={content} 
                                params={['title', 'description']}
                                />
                            <SettingContentSelect 
                                dbUpdateLink={this.props.firebase.badge(this.state.cid)}
                                title={"Original program"} 
                                content={content} 
                                params={['program']}
                                selectOptions={this.context.programs}
                                />
                            <SettingContentUploadImage
                                dbUpdateLink={this.props.firebase.badge(this.state.cid)}
                                title={"Link to image used (URL with https)"} 
                                content={content} 
                                previewImageSize={true}
                                params={['iconUrl']}
                                uploadDatabase={this.props.firebase.storageImages('badges/'+this.state.cid)}
                                />
                            <SettingContentBase 
                                dbUpdateLink={this.props.firebase.badge(this.state.cid)}
                                title={"Parent Badge & Family/group"} 
                                content={content} 
                                params={['parent','family']}
                                />
                            <SettingContentArray 
                                dbUpdateLink={this.props.firebase.badge(this.state.cid)}
                                title={"Internal author"} 
                                content={content} 
                                params={['author']}
                                />
                            
                            <TextField
                                name="internal_id"
                                type="text"
                                label="Label"
                                placeholder="Label_"
                                value={this.state.duplicateID || ""}
                                onChange={(e) => this.setState({duplicateID: e.target.value}) }
                                style={ {fontSize: '16px'} }
                            />
                            <TextField
                                name="target_no"
                                type="text"
                                label="Target"
                                placeholder="Enter Target Number"
                                value={this.state.duplicateTarget || ""}
                                onChange={(e) => this.setState({duplicateTarget: e.target.value}) }
                                style={ {fontSize: '16px'} }
                            />
                            <Button onClick={() => { this.handleDuplicateContent( content )  }}>
                                Duplicate Badge
                            </Button>
                            {content.active === false &&
                                <Button onClick={() => { this.handleDeleteContent( this.state.cid )  }}>
                                    Delete Badge
                                </Button>
                            }
                        </div>
                    )}
                    </div>
                </DialogContent>
            </Dialog> 
        );
    }

    handleDuplicateContent = (content) => {
        var target = 1;
        target = parseInt(this.state.duplicateTarget);
        if(content!== undefined && this.state.duplicateID?.length > 4 && target > 0){
            var newContent = {...content};
            newContent['active'] = false;
            var name = this.state.duplicateID;
            if(name.slice(-1) !== '_'){
                name = name+'_' 
            }
            name = name+''+target;
            newContent['name'] = name;
            newContent['target'] = target;
            newContent['requirements'][0]['value'] = target;
            console.log(newContent, this.state.duplicateID)
            this.props.firebase.badge(name).set(newContent);
            this.setState({errorMessage : 'Copied badge inactive'});
            if(this.props.closeDialog!==undefined) this.props.closeDialog();
        }
        else{
            this.setState({errorMessage : 'Original data missing for a copy'})
        }
    }
    handleDeleteContent = (id) => {
        if(id!== undefined){
            this.props.firebase.badge(id).delete();
            this.setState({errorMessage : 'Deleted Badge'})
            if(this.props.closeDialog!==undefined) this.props.closeDialog();
        }
        else{
            this.setState({errorMessage : 'Original data missing for a copy'})
        }
    }
}

AdminBadgesPage.propTypes = {
    classes: PropTypes.object.isRequired
}

const BadgesList = withFirebase(BadgesListBase);
const BadgesItem = withFirebase(BadgesItemBase);
const BadgesItemDialog = withFirebase(BadgesItemBaseDialog);

const condition = authUser =>
    authUser && authUser.roles?.includes(ROLES.ADMIN);

export default compose(
    withStyles(styles),
    // withEmailVerification,
    withAuthorization(condition),
)(AdminBadgesPage);