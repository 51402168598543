import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import PropTypes from 'prop-types';

import MapRender from '../Days/MapRender';
import GoalOverview from '../Goals/GoalOverview';

import Card from '@material-ui/core/Card';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});



class MapGoals extends Component {
    static contextType = AuthUserContext;
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            activeStep : 0,
            loading: false,
            loadingText: "loading journey"
        };
    }

    componentDidMount() {
    }
    shouldComponentUpdate(nextProps, nextState) {  
        if (nextProps.rerender === false) {  
            //console.log('no rerender')
            return false;  
        }  
        //console.log(' rerender')
        return true;  
    }

    render() {
        const {theme} = this.props;
        var index = 1;
        return (

            <div >
                <div style={{textAlign:"center", width:"100%", marginBottom:20}}>
                    <Card style={theme.CardGoals}  >
                        <GoalOverview key={index}
                            user={this.context}
                            defaultSet={this.props.defaultSet || undefined}
                            features={['goal']}
                            type='quick'
                            closeDialog={() => {
                                this.setState({openGoal: false})
                            }}
                            openDialogue={ this.state.openGoal ? true : false}
                            leadershipCategories={this.props.leadershipCategories}
                            leadershipQualities={this.props.leadershipQualities}
                        />
                    </Card> 
                </div>
                <MapRender 
                    user={this.props.user} 
                    leadershipCategories={this.props.leadershipCategories}
                    leadershipQualities={this.props.leadershipQualities}
                    renderMapType={'Goals'}//one word
                    filterTrueCoachUtter={['goal',  'progress',  'expectation']}//string search
                    filterTrueIntentCoach={['goal', 'progress',   'expectation']}//string search
                    filterTrueEntityCoach={['goal']}//need exact match
                    filterTrueIntentUser={['goal']}//string search
                    filterTrueEntityUser={['goal']}//need exact match
                    filterFalseIntentUser={['activ','mindset', 'task', 'prio', 'skill', 'qualit']}//string search
                />

                <div  style={{marginTop: 30 }}>
                    {/***
                    <Accordion style={{boxShadow:'none'}}
                            onChange={(e,expanded) => {this.setState({additional_note_expanded: expanded})}}>
                        <AccordionSummary
                            expandIcon={this.state.additional_note_expanded? <CancelIcon /> : <AddCircleOutlineIcon />}
                            aria-label="About-Expand"
                            aria-controls="About-content"
                            id="About-header"
                            >
                            Add additional note
                        </AccordionSummary>
                        <AccordionDetails  style={{padding:0}} >        
                            <DiaryManual />
                        </AccordionDetails>
                    </Accordion>
                    */}
                </div>
            </div>
        );
    }
  
}

MapGoals.propTypes = {
    classes: PropTypes.object.isRequired,
};
  

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(MapGoals);
