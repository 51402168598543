import MuiAccordion from './MuiAccordion';
import MuiButton from './MuiButton';
import MuiCard from './MuiCard';
import MuiCssBaseline from './MuiCssBaseline';
import MuiCheckbox from './MuiCheckbox';
import MuiChip from './MuiChip';
import MuiDialogTitle from './MuiDialogTitle';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiIconButton from './MuiIconButton';
import MuiInputBase from './MuiInputBase';
import MuiLink from './MuiLink';
import MuiMobileStepper from './MuiMobileStepper';
import MuiMenuItem from './MuiMenuItem';
import MuiTextField from './MuiTextField';
import MuiPaper from './MuiPaper';
import MuiSlider from './MuiSlider';
import MuiSelect from './MuiSelect';
import MuiStepIcon from './MuiStepIcon';
import MuiStepLabel from './MuiStepLabel';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';
import MuiTabs from './MuiTabs';
import MuiTab from './MuiTab';
import MuiBadge from './MuiBadge';
import MuiInputLabel from './MuiInputLabel';
import MuiRadio from './MuiRadio';
function muiStyle (palette) {
    return {
        MuiAccordion: MuiAccordion(palette),
        MuiButton: MuiButton(palette),
        MuiCheckbox: MuiCheckbox(palette),
        MuiChip: MuiChip(palette),
        MuiCard: MuiCard(palette),
        MuiCssBaseline: MuiCssBaseline(palette),
        MuiDialogTitle: MuiDialogTitle(palette),
        MuiFormControlLabel: MuiFormControlLabel(palette),
        MuiLink: MuiLink(palette),
        MuiMenuItem: MuiMenuItem(palette),
        MuiMobileStepper: MuiMobileStepper(palette),
        MuiIconButton: MuiIconButton(palette),
        MuiInputBase: MuiInputBase(palette),
        MuiTextField: MuiTextField(palette),
        MuiPaper: MuiPaper(palette),
        MuiSelect: MuiSelect(palette),
        MuiSlider: MuiSlider(palette),
        MuiStepIcon: MuiStepIcon(palette),
        MuiStepLabel: MuiStepLabel(palette),
        MuiTableCell: MuiTableCell(palette),
        MuiTableHead: MuiTableHead(palette),
        MuiTypography: MuiTypography(palette),
        MuiTabs: MuiTabs(palette),
        MuiTab: MuiTab(palette),
        MuiBadge: MuiBadge(palette),
        MuiInputLabel: MuiInputLabel(palette),
        MuiRadio: MuiRadio(palette),
    }
};

export default muiStyle;