function muiStyle (palette) { return {
    root: {
        backgroundColor: 'transparent',
        color: palette.secondary.main + " !important",
        '&:link': {
            color: palette.secondary.main + " !important",
        },
        '&:hover': { // Target hover state for visual feedback
            backgroundColor: palette.secondary.light + " !important",
            color: palette.white + " !important",
            textDecoration : 'underline'
        },
        '&:hover span': { // Target hover state for visual feedback
            backgroundColor: "gold" + " !important",
            color: palette.white + " !important",
            textDecoration : 'underline'
        },
        '&:hover Link': { // Target hover state for visual feedback
            backgroundColor: "gold" + " !important",
            color: palette.white + " !important",
            textDecoration : 'underline'
        },
        '&:active': { // Target hover state for visual feedback
            backgroundColor: palette.secondary.main + " !important",
        },
        '&:visited': { // Target hover state for visual feedback
            backgroundColor: palette.secondary.main + " !important",
        },
    }
}}

export default muiStyle;