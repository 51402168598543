import React, { Component } from 'react';
//import { Link } from "react-router-dom";
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import LoadingProgress from '../Modules/LoadingProgress';

import BubbleChart from '../Charts/BubbleChart';

import { FindTextValueInDict, QualityID } from '../Modules/LoadCategoriesAndQualities';
import SnackbarInfo from '../Modules/SnackbarInfo';


const styles = theme => ({
	desktopPadding: {
	 	minHeight: 40,
	},
	buttonCenter: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
		marginBottom: 15,
    },
});

class CardTotalStatsTopics extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.refBubble = React.createRef();
        this.state = {
            open: false,
            challenges: 4,
        };
    } 

    componentDidMount() {
        this._isMounted = true;
        var engagementStats = this.props.engagementStats?.leadership_quality || undefined;
        if(engagementStats){
            this.loadTopEngagements(this.props.engagementStats);
        }
        if(this.props.loadStats){
            this.props.firebase.insight(this.props.user.uid)
            .get().then(snapshot => {
                if(this._isMounted ){
                    var engagementSet = snapshot.data();
                    //console.log('userstats', engagementSet)
                    if(engagementSet!== undefined && engagementSet.engagements !== undefined){
                        if(engagementSet.engagements.leadership_quality !== undefined){
                            engagementSet['leadership_quality'] = QualityID(engagementSet.engagements.leadership_quality);
                            this.loadTopEngagements(engagementSet);
                            //console.log('en', engagementSet)
                        }
                    }
                }
            });
        }
        //console.log(this.props)
    }
    componentWillUnmount(){
        this._isMounted = false;
    }
    componentDidUpdate(prevProps)  {
        if(this._isMounted && this.props.engagementStats !== undefined){
            if(prevProps.engagementStats === undefined){
                this.loadTopEngagements(this.props.engagementStats);
            }
            else if(this.props.engagementStats?.total?.points?.points !== prevProps.engagementStats?.total?.points?.points){
                this.loadTopEngagements(this.props.engagementStats);
            }
        }
        return true
    }
    shouldComponentUpdate(nextProps, nextState) {  
        if (nextProps.rerender === false) {  
            //console.log('no rerender')
            return false;  
        }  
        //console.log(' rerender')
        return true;  
    }

    loadTopEngagements(engagementStats){
        if(engagementStats?.leadership_quality){
            var engagementDict = engagementStats?.leadership_quality;
            var key = 'points';
            var sortedList = Object.keys( engagementDict ).map( key => ({ key, value: engagementDict[key] }) ).sort( (a, b) => a.value[key] - b.value[key] );
            //console.log('sorted', sortedList)
            var prioList = sortedList.slice(-40);
            prioList = prioList.reverse()
            var totalPoints = 0;
            var benchmarkPoints = 400;
            if(this.props.engagementBenchmarkQuality > 200){
                benchmarkPoints = this.props.engagementBenchmarkQuality;
            }
            prioList.forEach((item, index) => {
                if(prioList.length >= index && prioList[index]?.value?.value !== undefined){
                    //console.log('index', index, prioList[index], prioList[index]?.value?.value )
                    prioList[index]['icon'] = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, prioList[index]?.value?.value, 'icon');
                    prioList[index]['quality'] = prioList[index]?.value?.value;
                    prioList[index]['text'] = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, prioList[index]?.value?.value, 'text');
                    prioList[index]['label'] = prioList[index]['text'];
                    prioList[index]['points'] = prioList[index]?.value?.points;
                    prioList[index]['percentage'] = Math.round(prioList[index]['points']/benchmarkPoints*100);
                    if(prioList[index]['percentage'] > 100) prioList[index]['percentage'] = 100;
                    totalPoints += prioList[index]['percentage'];
                    prioList[index]['bgColor'] = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, prioList[index]?.value?.value, 'backgroundColor');
                    if(prioList[index]['bgColor']?.length>0){
                        prioList[index]['color'] = prioList[index]['bgColor'];
                    }
                    prioList[index]['value'] = prioList[index]['points'];
                }
            })
            this.setState({growthPathList: prioList, totalPoints});
            //console.log('prioList', prioList)
        }
    }


    render(){
        const {theme} = this.props;
        //console.log('state', this.state, this.refBubble)
        return(
            <div id={'bubbleChartDiv'} 
                style={{
                    'textAlign': 'center', 
                    width: '100%', 
                    height: '200vh'
                    }} 
                ref={this.refBubble}
            >
                {this.state.growthPathList?.length > 0
                    ?
                        <>
                        <SnackbarInfo 
                            alertText = {this.state.alertText || ''}
                            openOnClick = {true}
                            alertOpenEvent = {this.state.openAlert || false}
                            closeHandler = {() => {this.setState({openAlert: false})}}
                        />
                        <BubbleChart
                            graph= {{
                                zoom: 1.0,
                                offsetX: -0.0,
                                offsetY: -0.0,
                            }}
                            width={this.refBubble?.current?.offsetWidth || 300}
                            height={this.refBubble?.current?.offsetHeight || 300}
                            padding={0} // optional value, number that set the padding between bubbles
                            showLegend={false} // optional value, pass false to disable the legend.
                            legendPercentage={0} // number that represent the % of with that legend going to use.
                            legendFont={{
                                family: 'Arial',
                                size: 12,
                                color: '#000',
                                weight: 'bold',
                                }}
                            valueFont={{
                                family: 'Arial',
                                size: 12,
                                color: theme.palette.secondary.light,
                                weight: 'bold',
                                }}
                            labelFont={{
                                family: 'Arial',
                                size: 16,
                                color: theme.palette.secondary.light,
                                weight: 'bold',
                                }}
                            //overflow={true}
                            //Custom bubble/legend click functions such as searching using the label, redirecting to other page
                            bubbleClickFun={this.alertExtraInfo}
                            data={this.state.growthPathList || []}
                        />
                        </>
                :
                    <LoadingProgress 
                        defaultSet={this.props.defaultSet}
                        type={'LoadingPageAnimated'} 
                        loadingText={"many, many, data"} 
                        firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                        allowRefresh={true}
                    />
                }
            </div>
        )
    }

    alertExtraInfo = (info) => {
        //console.log('alertmessage', info)
        if(info.label){
            this.setState({ openAlert: false},
            () => setTimeout( () => {this.setState({alertText: info.label + ' - ' + info.value + ' XP', openAlert: true})}, 300)
        )}
    }
    
}

export default compose(
    withStyles(styles, { withTheme: true}),
)(CardTotalStatsTopics);
