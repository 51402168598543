import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import { AuthUserContext } from '../../Session';
import { withFirebase } from '../../Firebase';


import Switch from "react-switch";


const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});


class PublishingSwitch extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { content, params, title, labels } = this.props;
        const { theme, classes } = this.props;
        //console.log('updated props', this.props)

        return (
            <div 
                onClick={(event) => {
                    event.stopPropagation();
                }}
                style={{
                    position: 'absolute', 
                    right: 0, 
                    paddingLeft: 4,
                    paddingTop: 4,
                    paddingRight: 4,
                    top: 0, 
                    backgroundColor: theme.palette.white + "AA",
                    borderBottomLeftRadius: theme.borderRadius,
                    borderTopRightRadius: theme.borderRadius
                }}
            >
                <Switch
                    onChange={this.props.onChange !== undefined
                        ?   (c, event, id) => {
                                this.props.onChange(c, event, id)
                            }
                        :   () => void(0)
                    }
                    checked={this.props.checked || false}
                    onColor={this.props.onColor || theme.palette.themeAlertGreen}
                    disabled={this.props.disabled || false}
                />
            </div>
        );
    }
}

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(PublishingSwitch);
