//import palette from "../palette";

function muiStyle (palette) { return {
    /***
    rootOld: {
        maxWidth: "80%",
        minWidth: 80,
        position: "relative",
        display: "block",
        minHeight: "auto",
        borderRadius: 20,
        textTransform: "none",
        textAlign: "center",
        transition: "all .5s",
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
        color: palette.white,
        backgroundColor: palette.secondary.light,
        opacity: '1.0',
        //height: "auto",
        marginTop: 5,
        overflow: "visible",
        marginBottom: 0,
        marginRight: 0,
        marginLeft: 10,
        float: "none",
        "& + button": {
            //marginLeft: 10,
            color:  palette.white,
            opacity: '1.0',
        },
        "&$selected": {
            "&, &:hover": {
                color:  palette.white,
                backgroundColor: palette.secondary.dark,
                boxShadow: "0 7px 10px -5px rgba(76, 175, 80, 0.4)",
                opacity: '1.0',
            },
        },
        "&:hover": {
            color:  palette.black,
            backgroundColor: palette.themeAltGold,
            opacity: '1.0',
        },
    },
    */
    root: {
        maxWidth: 200,
        minWidth: 80,
        whiteSpace: "nowrap",
        overflow: "hidden",
        //overflow: "visible",
        position: "relative",
        display: "block",
        minHeight: 20,
        fontWeight: "bold",
        //borderRadius: 20,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        textTransform: "none",
        textAlign: "center",
        transition: "all .5s",
        paddingTop: 5,
        paddingBottom: 0,
        paddingLeft: 8,
        paddingRight: 8,
        color: palette.themeBlue,
        backgroundColor: palette.themeAltLightGrey, 
        opacity: '1.0',
        //height: "auto",
        marginTop: 5,
        marginBottom: 0,
        marginRight: 0,
        marginLeft: 0,
        float: "none",
        height: '100%',
        "& + button": {
            //marginLeft: 10,
            color: palette.secondary.main,
            //color:  palette.white,
            opacity: '1.0',
        },
        "&$selected": {
            "&, &:hover": {
                color: palette.white,
                backgroundColor: palette.secondary.light,
                boxShadow: "0 7px 10px -5px rgba(76, 175, 80, 0.4)",
                opacity: '1.0',
            },
        },
        "&:hover": {
            color: palette.black,
            backgroundColor: palette.themeAltGold,
            opacity: '1.0',
        },
    },
    textColorInherit: {
        color: palette.secondary.main,
        opacity: '1.0',
    }
}}

export default muiStyle;