//import palette from '../palette';

function muiStyle (palette) { return {
    root: {
        //backgroundColor: 'transparent',
        //backgroundColor: palette.darkthemeLightGrey,
        backgroundColor: palette.backPaper,
        color: palette.themeText
    },
    elevation1: {
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
    }
}}

export default muiStyle;