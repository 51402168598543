
import {StoreAppError} from './components/Modules/StoreAppError';
/**
 * @description Formats the HTTP request
 * @param {String} endpointType
 *  Possible values: 'firebase' OR 'rocky'
 * @param {*} options
 */


async function fetchWithTimeout(resource, options = {}) {
    const { timeout = 36000 } = options;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    try{
        const response = await fetch(resource, {
            ...options,
            signal: controller.signal  
        });
        clearTimeout(id);
        return response;
    }
    catch(error){
        //console.log('error root', error)
        return undefined;
    }
}

const localMode = false;
function requestLocalSession(endpointType = 'rest', options) {
    console.log('Sent to bot:', options)
    /// requires rasa run -v --enable-api --endpoints endpoints.yml --debug --cors "*"
    //return fetchWithTimeout(`http://localhost:5005/webhooks/rest/webhook`, {
    var fetcher = fetchWithTimeout(`https://50b1-31-191-136-87.ngrok-free.app/webhooks/rest/webhook`, {
        mode: 'cors',
        headers: {
            'Origin': window.location.origin,
            "ngrok-skip-browser-warning": true,
            'Access-Control-Allow-Origin': "*",
            'Access-Control-Allow-Headers': "*",
            'Content-Type': 'application/json',
        },
        ...options
    });
    return fetcher
}
function requestLanguageAgent(options) {
    if(localMode){
        console.log('Sent to translation bot:', options)
    }
    var fetcher = fetchWithTimeout(`https://us-central1-rocky-beta-dev.cloudfunctions.net/translation_agent`, {
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        ...options
    });
    return fetcher
}


function requestPrimingSession(endpointType = 'rest', options, url) {
    //console.log('requestPrimingSession', options)
    return fetchWithTimeout('https://priming-agent.'+url+'/webhooks/rest/webhook', {
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': "*",
            'Content-Type': 'application/json',
        },
        ...options
    });
}

function requestReflectionSession(endpointType = 'rest', options, url) {
    //console.log('requesrequestReflectionSessiontPrimingSession', options)
    return fetchWithTimeout('https://reflection-agent.'+url+'/webhooks/rest/webhook', {
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': "*",
            'Content-Type': 'application/json',
        },
        ...options
    });
}

function requestNLU(endpointType = 'rest', options, url) {
    //console.log(options)
    return fetch('https://nlu-agent.'+url+'/model/parse', {
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': "*",
            'Content-Type': 'application/json',
        },
        ...options
    });
}

function requestGenAI(options) {
    console.log('requestGenAI', options)
    return fetch(`https://us-central1-rocky-beta-dev.cloudfunctions.net/get_genai_open_message`, {
        mode: 'cors',
        headers: {
            //"Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Headers": "*",
            "Content-Type": "application/json",
        },
        ...options
    });
}

function requestTranslationAI(options) {
    console.log('requestTranslationAI', options)
    return fetch(`https://us-central1-rocky-beta-dev.cloudfunctions.net/generate_translation_sentence`, {
        mode: 'cors',
        headers: {
            //"Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Headers": "*",
            "Content-Type": "application/json",
        },
        ...options
    });
}



/**
 * @description Send message
 * @param {Json} content
 *  Assumes the format:
 *  {
 *    sender: <uid>,
 *    message: '....'
 *  }
 */

async function agentSelection (content, agentType, url) {
    let response = {};
    if (content?.language?.length > 1 && content?.language !== 'en-US'){
        content['language'] = content.language
        if (agentType ==='primingSession'){
            content['agent'] = 'priming'
        }
        if(localMode){
            console.log('content language packages', content)
        }
        response = await requestLanguageAgent({
                method: 'POST',
                body: JSON.stringify(content)
            }
        );
        if(localMode){
            console.log('Received from translatiob bot:', response)
        }
    }
    else if(localMode && (['primingSession', 'reflectionSession'].includes(agentType))){
        response = await requestLocalSession(
            'rest', 
            {
                method: 'POST',
                body: JSON.stringify(content)
            },
            url
        );
        //console.log('Received from bot:', response)
    }
    else{
        switch (agentType) {
            case 'primingSession':
                response = await requestPrimingSession(
                    'rest', 
                    {
                        method: 'POST',
                        body: JSON.stringify(content)
                    },
                    url
                );
                break;
            case 'reflectionSession':
                response = await requestReflectionSession(
                    'rest', 
                    {
                        method: 'POST',
                        body: JSON.stringify(content)
                    },
                    url
                );
                break;
            case 'nluAnalysis':
                response = await requestNLU(
                    'rest', 
                    {
                        method: 'POST',
                        //mode: 'no-cors',
                        body: JSON.stringify(content)
                    },
                    url
                );
                break;
            case 'questionGenAI':
                response = await requestGenAI({
                        method: 'POST',
                        //mode: 'no-cors',
                        body: JSON.stringify(content)
                    }
                );
                break;
            case 'translationAI':
                response = await requestTranslationAI({
                        method: 'POST',
                        //mode: 'no-cors',
                        body: JSON.stringify(content)
                    }
                );
                break;
            default:
                response = await requestPrimingSession(
                    'rest', {
                        method: 'POST',
                        body: JSON.stringify(content)
                    },
                    url
                );
                break;
        }
    }
    return response;
}
function delay(milliseconds){
    return new Promise(resolve => {
        setTimeout(resolve, milliseconds);
    });
}

export async function sendMessage(content, agentType = 'primingSession', db=undefined) {
    var urlDefault = 'rocky.ai';
    var urlSecond = 'rockyrobots.com';
    //var urlSecond = 'rocky.ai';
    //console.log('sendmessage',content);
    let response = {};
    const controller2 = new AbortController();
    try {
        response = await agentSelection(content, agentType, urlDefault);
        //let result = await response.json();
        //console.log('status first', response.status)
        if (response.status >= 200 && response.status <= 299) {
            const result = await response.json();
            if(localMode){
                console.log('chatagent result:',result)
            }
            return result;
        }
        else if (response.status === 422) {
            //console.log('error first', response.status, response)
            return [
                {recipient_id: content.sender, text: "The stage is yours.", issue: 'inactive'},
            ]
        }
        else{
            //console.log('error first', response.status, response)
            throw new Error('Failed first try')
            /***
            controller2.abort();
            return [
                {recipient_id: content.sender, text: "Oh no!", issue: 'policy'},
                {recipient_id: content.sender, text: "Your device policy does not allow me to access my cloud servers.", issue: 'policy'},
                {recipient_id: content.sender, text: "Please ensure to have access to the URL *.rocky.ai", issue: 'policy'}
            ]
             */
        }
    } 
    catch (err) {
        //console.log('chatagent err', err);
        if(db !== undefined){
            var newError = "Error: " + err + ".";
            StoreAppError(db, newError, 'chatAgent', 'sendMessage', JSON.stringify(content))
        }
        if(agentType !== 'questionGenAI'){
            try{
                //console.log('await')
                await delay(2000);
                //console.log('await 2sec')
                response = await agentSelection(content, agentType, urlSecond);
                //let result = await response.json();
                //console.log('status', response)
                if (response === undefined || response?.status === undefined ) {
                    //console.log('error unde', response)
                    controller2.abort();
                    return [
                        {recipient_id: content.sender, text: "Sorry, I cannot connect to my servers...", issue: 'connection'},
                        {recipient_id: content.sender, text: "Probably my servers are updated right now, please try again in a few minutes.", issue: 'connection'}
                    ]
                }
                else if (response?.status >= 200 && response?.status <= 299) {
                    const result = await response.json();
                    //console.log('chatagent extra',result)
                    return result;
                }
                else if(response?.status !== undefined){
                    //console.log('error 2', response)
                    controller2.abort();
                    return [
                        {recipient_id: content.sender, text: "Oh no!", issue: 'policy'},
                        {recipient_id: content.sender, text: "Your device policy does not allow me to access my cloud servers.", issue: 'policy'},
                        {recipient_id: content.sender, text: "Please ensure to have access to the URL *.rocky.ai", issue: 'policy'}
                    ]
                }
                else{
                    //console.log('error 55', response)
                    controller2.abort();
                    return [
                        {recipient_id: content.sender, text: "Sorry, I cannot connect to my servers...", issue: 'connection'},
                        {recipient_id: content.sender, text: "Probably my servers are updated right now, please try again in a few minutes.", issue: 'connection'}
                    ]
                }
            }
            catch (errExtra) {
                //console.log('error 2x', response)
                controller2.abort();
                return [
                    {recipient_id: content.sender, text: "Oh no!", issue: 'unavailable'},
                    {recipient_id: content.sender, text: "I have problems to connect with my memory.", issue: 'unavailable'},
                    //{recipient_id: content.sender, text: "Probably my servers are updated right now, please try again in 30 minutes."}
                    {recipient_id: content.sender, text: "Probably my servers are upgraded right now and I am learning something new, please try again in 10 minutes.", issue: 'unavailable'}
                ]
            }
        }
        else if (response.status === 422) {
            return [
                {recipient_id: content.sender, text: "The stage is yours.", issue: 'inactive'},
            ]
        }
        else{
            //console.log('error finally ', response)
            controller2.abort();
            return [
                {recipient_id: content.sender, text: "Oh no!", issue: 'unavailable'},
                {recipient_id: content.sender, text: "I have problems to connect with my memory.", issue: 'unavailable'},
                //{recipient_id: content.sender, text: "Probably my servers are updated right now, please try again in 30 minutes."}
                {recipient_id: content.sender, text: "Probably my servers are upgraded right now and I am learning something new, please try again in 10 minutes.", issue: 'unavailable'}
            ]
        }
    }
}
