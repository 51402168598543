//automatic image creator
//https://stackoverflow.com/questions/29468269/canvas-to-use-liniear-gradient-background-set-with-an-angle
export function createGradientImage(bgColor, bgColor2, logo){
    // create an offscreen canvas
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");

    var angle = 45 * Math.PI / 180;
    var x2 = 300 * Math.cos(angle);
    var y2 = 300 * Math.sin(angle);
    var gr = ctx.createLinearGradient(0, 0, x2, y2);

    gr.addColorStop(0, bgColor);
    gr.addColorStop(1, bgColor2);

    ctx.fillStyle = gr;
    var imgWith =  ctx.canvas.width;
    var imHeight =  ctx.canvas.height;
    //if(ctx.canvas.width > 300) imHeight=300;
    //if(ctx.canvas.width > 300) imHeight=300;
    ctx.fillRect(0, 0, imgWith, imHeight);

    // create a new img object
    var image = new Image();
    // set the img.src to the canvas data url
    image.src = canvas.toDataURL();
    // append the new img object to the page
    return image.src;
}
