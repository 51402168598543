import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import LoadingProgress from '../Modules/LoadingProgress';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import SubscriptionCard from '../Profile/SubscriptionCard';

import Button from '@material-ui/core/Button';
//import Card from '@material-ui/core/Card';
//import {IsMobileDevice} from '../Modules/IsMobileDevice';
import Grid from '@material-ui/core/Grid';
//import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingAbout from './SettingAbout';

// style elements
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';


const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});

const debugFlag = false;


class SettingSubscriptionMobile extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            plan: 'Content and Web App',
            error: '',
            productForPayment : null,
            loadedStore: false,
            approved: false,
            verified: false,
            owned: false,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({flagowned: false, flagverified: false})
        if (window.CdvPurchase !== undefined){
            document.addEventListener("deviceready", () => this.deviceReadyStartMobileShop(), false);
        };
    }
    componentWillUnmount() {
        this.setState({flagowned: false, flagverified: false})
        this._isMounted = false;
    }

    deviceReadyStartMobileShop = () => {
        //console.log('device ready flag')
        var buyer = 'enduser';
        var storeId = "Fovea";
        /***
        if(this.props.coach && this.context.roles !== undefined && (this.context.roles.includes('COACHADMIN') || this.context.roles.includes('REMOTECOACH') )){
            buyer = 'coach';
        }
         */
        this.setState({
            storeId: storeId, 
            buyer
        })
        var production = true;
        var products = {};
        var groupIdTitles = [];
        var groupOfArticle = {};
        var storeRegisterProducts = []
        this.props.firebase.purchaseArticles()
        .where('active', '==', true)
        .where('store', '==', 'Mobile')
        .where('production', '==', production)
        .where('category', '==', buyer)
        .orderBy('position', 'asc')
        .get()
        .then(articles => {
            if(this._isMounted){
                articles.forEach(doc => {
                    var article = doc.data();
                    ///// OVERWRITE DEFAUSLT PRUCHASE OPTION
                    article['canPurchase'] = false;
                    if(this.props.premiumOnly === true && !article.premium){
                        // do nothing
                        if(article.id!==undefined && article.id!==""){
                            storeRegisterProducts.push({
                                ...article,
                                id: article.id,
                                article: article
                            });
                        }
                    }
                    else{
                        var groupId = doc.id;
                        if (article.groupId !== undefined && article.groupId !== ''){
                            groupId = article.groupId;
                        }
                        if (groupIdTitles.includes(groupId)){
                            if(article.position === 1){
                                products[groupId]['items'].unshift(article);
                                //console.log('number 1', products)
                            }
                            else if(article.position > 1){
                                //console.log('number pos', article.position, products[groupId]['items'].length, products)
                                if(article.position <= products[groupId]['items'].length){
                                    products[groupId]['items'].splice((article.position - 1), 0, article);
                                }
                                else{
                                    products[groupId]['items'].push(article);
                                }
                            }
                            else{
                                products[groupId]['items'].push(article);
                            }
                        }
                        else{
                            groupIdTitles.push(groupId)
                            article['notAvailable'] = true;
                            products[groupId] = article;
                            products[groupId]['items'] = [];
                            products[groupId]['items'].push(article);
                        }
                        if(article.id!==undefined && article.id!==""){
                            groupOfArticle[article.id] = groupId;
                            storeRegisterProducts.push({
                                ...article,
                                id: article.id,
                                article: article
                            });
                        }
                    }
                });
                if( groupIdTitles.length>0 && this._isMounted ){
                    this.setState({
                        products,
                        groupOfArticle,
                        groupIdTitles
                    });
                    this.loadMobileStoreData(storeRegisterProducts);
                    window.CdvPurchase?.store?.ready( () => {
                        //console.log('store is flag ready')
                        if(this._isMounted) {
                            this.setState({loadedStore: true});
                            this.loadStoreEvents();
                        }
                        //window.CdvPurchase?.store?.refresh();
                    })
                }
                //console.log(products, groupIdTitles)
            }
        })//end firestore then
    }

    getProductBasics (product, destinationProduct = undefined){
        var updatedProductGroup = {};
        if (destinationProduct){
            updatedProductGroup = destinationProduct;
        }
        updatedProductGroup['title'] = product.title || "n/a";
        updatedProductGroup['description'] = product.description || "n/a";
        /////// this is the shortcut to offers[0].pricingPhases[0]
        updatedProductGroup['price'] = product?.pricing?.price || "check store";
        try{
            //console.log('found groupid', product.offers)
            var finalOffer = product?.offers?.[product?.offers?.length - 1];
            updatedProductGroup['price'] = finalOffer?.pricingPhases?.[finalOffer?.pricingPhases?.length - 1]?.price || updatedProductGroup['price'];
            //console.log('found groupid', updatedProductGroup['price'])
        } catch  (error) {console.log('error pricingphases', product)}
        updatedProductGroup['offer'] = product?.offers?.[0];
        updatedProductGroup['canPurchase'] = product.canPurchase;
        return updatedProductGroup;
    }

    loadMobileStoreData (storeRegisterProducts){
        //console.error('store info', this.state.loadedStore, window.CdvPurchase)
        if( window.CdvPurchase?.store !== undefined && !this.state.loadedStore){
            try{
                // Setup the receipt validator service.
                window.CdvPurchase.store.validator = 'https://validator.iaptic.com/v1/validate?appName=ai.rocky.app&apiKey=eccab7ca-bf8a-4df5-8359-80089d07a891';
                window.CdvPurchase.store.applicationUsername = this.context.uid;
                window.CdvPurchase.store.developerPayload = this.context.uid;
                //console.error('initiate store empty')
                // We should first register all our products or we cannot use them in the app.
                var articles = [];
                storeRegisterProducts.map( (article) => {
                    var purchaseType = window.CdvPurchase?.store?.PAID_SUBSCRIPTION;
                    if(article.type === 'PURCHASE' || article.type === 'SEATS'){
                        purchaseType = window.CdvPurchase?.store?.CONSUMABLE;
                    }
                    articles.push({
                        id:    article.id,
                        type:  purchaseType,
                        applicationUsername : this.context.uid,
                        platform: (window.device.platform === 'Android' ? "android-playstore" : "ios-appstore") 
                    })
                    return true;
                })
                window.CdvPurchase.store.register(articles);
                // Load informations about products and purchases
                window.CdvPurchase.store.initialize([(window.device.platform === 'Android' ? "android-playstore" : "ios-appstore")]);
                // set flag that everything was initiated already
                if(this._isMounted) {
                    this.setState({loadedStore:true});
                }
            }
            catch (error) {
                console.log('error with subscription plugin', error)
            }
        }
        else if (this.state.loadedStore){
            //console.log('reopeneing new shop statet')
            window.CdvPurchase.store.applicationUsername = this.context.uid;
            window.CdvPurchase.store.developerPayload = this.context.uid;
            var reloadArticles = [];
            storeRegisterProducts.map( (article) => {
                var product = window.CdvPurchase?.store?.get(article.id);
                var purchaseType = window.CdvPurchase?.store?.PAID_SUBSCRIPTION;
                if(article.type === 'PURCHASE' || article.type === 'SEATS'){
                    purchaseType = window.CdvPurchase?.store?.CONSUMABLE;
                }
                //console.log('loaded get', product)
                reloadArticles.push({
                    id:    article.id,
                    type:  purchaseType,
                    applicationUsername : this.context.uid
                })
                if( this._isMounted && product!== undefined && product!==null && product.valid) {
                    //console.error('store got item', product)
                    if(this.state.groupOfArticle!==undefined && this.state.groupOfArticle[product.id] !==undefined ){
                        var groupId = this.state.groupOfArticle[product.id];
                        //console.log('found groupid', groupId, product.id)
                        var currentProducts = this.state.products;
                        var updatedProductGroup = {};
                        //console.log('step 2 current products', currentProducts)
                        if(currentProducts[groupId] !== undefined)
                            updatedProductGroup = {...currentProducts[groupId]};
                        updatedProductGroup = this.getProductBasics(product);
                        //console.log('step 3 updatedGroup', updatedProductGroup)

                        //console.log('state before loaded', this.state)
                        if(updatedProductGroup['items'] !== undefined){
                            var tempItems = updatedProductGroup['items'];
                            tempItems.map( (cachedItem, index) => {
                                //console.log('items', cachedItem, product, index)
                                if (cachedItem.id === product.id) {
                                    //console.log('items same', cachedItem, product, index,  product.description)
                                    updatedProductGroup['items'][index] = this.getProductBasics(product, updatedProductGroup['items'][index]);
                                    //updatedProductGroup['items'][index]['overwriteahrry'] = true;
                                    //console.log('items overrwite', updatedProductGroup['items'], updatedProductGroup['items'][index], product)
                                    this.setState({
                                        products : {
                                            ...currentProducts,
                                            [groupId] : updatedProductGroup,
                                            //['testeharyry'] : true,
                                        }
                                        //products : currentProducts
                                    })
                                }
                                return true;
                            })
                            //console.log('step 4 updatedGroup', updatedProductGroup)
                        }
                        else{
                            //console.log('push a new one into it', product)
                            updatedProductGroup['items'] = [];
                            updatedProductGroup['items'].push(product);
                            currentProducts[groupId] = updatedProductGroup;
                            this.setState({
                                products : {
                                    ...currentProducts,
                                    [groupId] : updatedProductGroup
                                }
                                //products : currentProducts
                            })
                        }
                        //console.log('state after loaded', this.state)
                    }
                }
                return true;
            })
            this.loadStoreEvents(storeRegisterProducts);
            
            //window.CdvPurchase?.store?.register(reloadArticles);
            //console.log('articles already ready')
            //window.CdvPurchase?.store?.refresh();
        }
    }

    syncAppProducts(){
        window.CdvPurchase?.store?.products?.map( (product) =>  {
            //console.log("isloaded main", product.id, product, product?.title)
            if(this._isMounted && product?.title?.length > 0 ) {
                //console.error('store loaded and valid', product)
                if(this.state.groupOfArticle!==undefined && this.state.groupOfArticle[product.id] !== undefined ){
                    var groupId = this.state.groupOfArticle[product.id];
                    //console.log('found groupid', groupId, product.id)
                    var currentProducts = this.state.products;
                    var updatedProductGroup = {};
                    if(currentProducts[groupId] !== undefined)
                        updatedProductGroup = {...currentProducts[groupId]};
                        updatedProductGroup = this.getProductBasics(product, updatedProductGroup);
                    //console.log('updatedProductGroup', updatedProductGroup)
                    if(updatedProductGroup['items'] !== undefined){
                        var tempItems = updatedProductGroup['items'];
                        tempItems.map( (cachedItem, index) => {
                            if (cachedItem.id === product.id) {
                                updatedProductGroup['items'][index] = this.getProductBasics(product, updatedProductGroup['items'][index]);
                            }
                            return true;
                        })
                    }
                    else{
                        updatedProductGroup['items'] = [];
                        updatedProductGroup['items'].push(product);
                    }
                    this.setState({
                        products : {
                            ...this.state.products,
                            [groupId] :updatedProductGroup
                        }
                    })
                    //console.log('products after loaded', this.state.products)
                }
            }
            return true;
        });
    }

    loadStoreEvents (storeRegisterProducts) {
        window.CdvPurchase.store.applicationUsername = this.context.uid;
        window.CdvPurchase.store.developerPayload = this.context.uid;
        /// load store products
        this.syncAppProducts();
        //// reactions on store
        window.CdvPurchase?.store?.when()
        .approved((transaction) => {
            if(this.state.flagapproved!==true){
                if(this._isMounted || this.state.flagapproved!==true ) {
                    this.setState({flagapproved: true, error: 'Processing purchase of: ' + transaction.transactionId + '...'});
                    transaction['stateFovea'] = 'approved';
                    if(debugFlag) console.error('now its processed', transaction)
                    //this.appStoreUpdateTransactionStatus(transaction);
                    transaction['stateFovea'] = 'approved';
                    transaction['orderId'] = transaction?.transactionId || "";
                    transaction.verify()
                }
            }
        })
        .verified((receipt) => {
            if(this.state.flagverified!==true){
                if(this._isMounted || this.state.flagverified!==true) {
                    receipt['stateFovea'] = 'verified';
                    if(debugFlag) console.error('now its verified', receipt)
                    window.CdvPurchase.store.applicationUsername = this.context.uid;
                    window.CdvPurchase.store.developerPayload = this.context.uid;
                    this.setState({flagverified: true, error: 'Verifying purchase ...'});
                    receipt?.collection?.forEach(product => {
                        if (window.CdvPurchase?.store?.owned(product)) {
                            if(this._isMounted) {
                                this.setState({flagowned: true, error: 'You own: '+receipt.orderId + ' !', loadingStoreDialogue : false});
                                receipt['stateFovea'] = 'owned';
                                if(debugFlag) console.error('now its owned', receipt)
                            }
                        }
                    });
                    receipt['stateFovea'] !== 'owned' && receipt?.transactions?.forEach(transaction => {
                        transaction?.products?.forEach(product => {
                            if (window.CdvPurchase?.store?.owned(product)) {
                                if(this._isMounted) {
                                    this.setState({flagowned: true, error: 'You own: '+receipt.orderId + ' !', loadingStoreDialogue : false});
                                    receipt['stateFovea'] = 'owned';
                                    if(debugFlag) console.error('now its owned', receipt)
                                }
                            }
                        });
                    });
                    this.appStoreUpdateTransactionStatus(receipt);
                    receipt.finish()
                }
            }
        })
        .receiptsVerified( (receipt) =>  {
            if(debugFlag) console.log('updagted', receipt)
            if(receipt){
                var owned = false;
                var expired = false;
                var latestProduct = {}
                var productId = "";
                receipt?.transactions?.forEach(transaction => {
                    if(transaction?.purchaseDate !== undefined){
                        transaction.products?.forEach(product => {
                            latestProduct = product;
                            if (window.CdvPurchase?.store?.owned(product)) {
                                latestProduct['owned'] = true;
                                productId = product.id;
                                if(productId.includes('premium')){
                                    latestProduct['premium'] = true;
                                }
                                owned = true;
                            }
                        });
                    }
                });
                this.setState({loadingStoreDialogue : false});
                if(productId.length > 3){
                    var productInfo = window.CdvPurchase?.store?.get(
                        productId, 
                        (window.device.platform === 'Android' ? "android-playstore" : "ios-appstore")
                    )
                    if(this.state.flagexpired !==true && expired){
                        if(this._isMounted || this.state.flagexpired!==true ) {
                            this.setState({flagexpired: true, error: 'Oh no: Your '+ productInfo.title + ' subscription expired!'});
                            receipt['stateFovea'] = 'expired';
                            //allways ignore owned
                            //receipt['owned'] = false;
                            receipt['status'] = 'expired';
                            receipt['id'] = productId;
                            //console.error('now its expired', receipt)
                            this.appStoreUpdateTransactionStatus(receipt);
                        }
                    }
                    else if(owned) {
                        this.setState({flagowned: true, error: 'You own: '+productInfo.title + ' !', loadingStoreDialogue : false});
                    }
                    else {
                        this.setState({flagowned: true, error: 'The '+productInfo.title + ' was loaded!', loadingStoreDialogue : false});
                    }
                }
                else{
                    this.setState({flagexpired: true, error: 'Your store is up-to-date!'});
                }
            }
        });
        // Show errors for 10 seconds.
        window.CdvPurchase?.store?.error( (errorStore) => {
            if(this._isMounted ) {
                this.setState({ loadingStoreDialogue: false, error: errorStore.message });
                console.log('store error', errorStore);
            }
        });
    }

    appStoreUpdateTransactionStatus = (receipt) => {
        try{
            if(debugFlag) console.log('appstoreT', receipt)
            var checkPurchases = {};
            if ('purchasedProduct' in this.context){
                checkPurchases = this.context.purchasedProduct;
            }
            var productId = receipt.id;
            var updateProduct = {}
            if(receipt?.collection?.[0]?.id?.length > 2){
                productId = receipt?.collection?.[0]?.id;
                updateProduct = receipt.collection[0];
            }
            var product = window.CdvPurchase?.store?.get(
                    productId, 
                    (window.device.platform === 'Android' ? "android-playstore" : "ios-appstore")
                )
            if(debugFlag) console.log('product', product)
            if(product){
                updateProduct = this.getProductBasics(product, updateProduct);
                updateProduct["id"] = productId;
                updateProduct["state"] = receipt?.stateFovea || 'n/a';
                if(window.CdvPurchase?.store?.owned(product) ){
                    updateProduct["owned"] = true;
                }
                updateProduct["orderId"] = receipt.sourceReceipt?.orderId || 'n/a';
                updateProduct["offers"] = {};
                updateProduct['paymentSource'] = 'Fovea';
                if(this._isMounted){
                    this.setState({showOfferId: productId})
                }
                if(debugFlag) console.log('update produc load', receipt, updateProduct, product)
                if(updateProduct.isExpired){
                    updateProduct['state'] = 'expired'
                }
                //updateProduct = Object.assign(checkPurchases, updateProduct);
                //updateProduct = checkPurchases.update(updateProduct)
                updateProduct = Object.assign({}, checkPurchases, updateProduct);
                if('expiryDate' in product){
                    updateProduct['expiryDate'] = product.expiryDate;
                    //updateProduct = Object.assign(updateProduct, {expiryDate: product.expiryDate});
                }
                var transaction = {}
                if('nativeTransactions' in receipt){
                    transaction = receipt.nativeTransactions[0];
                    if(debugFlag) console.log('nativeTransactions', transaction)
                    var startTimeMillis = 0;
                    var expiryTimeMillis = 0;
                    if('purchase_date_ms' in transaction && 'transaction_id' in transaction){
                        // apple new
                        updateProduct['type'] = transaction.type || '';
                        updateProduct['orderId'] = transaction.transaction_id || '';
                        console.log('transaction', transaction)
                        //if('is_trial_period' in transaction){
                        if(transaction?.is_trial_period !== undefined){
                            updateProduct['introPricePaymentMode'] = transaction.is_trial_period;
                        }
                        startTimeMillis = parseInt(transaction.purchase_date_ms) || 0;
                        expiryTimeMillis = parseInt(transaction.expires_date_ms) || 0;
                    }
                    else if('in_app' in transaction && Array.isArray(transaction.in_app) && typeof transaction.in_app[0] === 'object'){
                        // apple reload - BUT only if that format works and is correct
                        transaction = transaction.in_app[0];
                        if(debugFlag) console.log('in_app', transaction)
                        updateProduct['type'] = product.type || '';
                        updateProduct['orderId'] = transaction.transaction_id || '';
                        //if('is_trial_period' in transaction){
                        if(transaction?.is_trial_period !== undefined){
                            updateProduct['introPricePaymentMode'] = transaction.is_trial_period;
                        }
                        startTimeMillis = parseInt(transaction.purchase_date_ms) || 0;
                        expiryTimeMillis = parseInt(transaction.expires_date_ms) || 0;
                    }
                    else if(transaction?.startTimeMillis > 1000){
                        // google
                        updateProduct['type'] = transaction.type || '';
                        startTimeMillis = (parseInt(transaction.startTimeMillis) ) || 0;
                        expiryTimeMillis = (parseInt(transaction.expiryTimeMillis) ) || 0;
                        if('introPricePaymentMode' in product){
                            updateProduct['introPricePaymentMode'] = product.introPricePaymentMode;
                        }
                    }
                    /// add elements
                    if (startTimeMillis>0){
                        var dateS = new Date(startTimeMillis);
                        transaction['purchaseDate'] = dateS;
                        updateProduct['purchaseDate'] = dateS;
                        if(debugFlag) console.log('timesets start', startTimeMillis, transaction['purchaseDate'] )
                    }
                    if (expiryTimeMillis>0){
                        var dateE = new Date(expiryTimeMillis);
                        transaction['expiryDate'] = dateE;
                        updateProduct['expiryDate'] = dateE;
                        if(debugFlag) console.log('timesets expiry', expiryTimeMillis, transaction['expiryDate'] )
                    }
                    if(debugFlag) console.log('total info', updateProduct,  transaction)
                    //updateProduct = updateProduct.update(transaction)
                    //updateProduct = Object.assign(updateProduct, {transaction});
                    Object.keys(transaction).forEach(key => transaction[key] === undefined && delete transaction[key])
                }
                try{
                    if(debugFlag) console.log('before',updateProduct)
                    delete updateProduct['offer']
                    if(debugFlag) console.log('after', updateProduct)
                }
                catch (error){}
                //// in the case ensure to set owned true but not overwrite if already owned
                if(this.context.purchasedProduct === undefined || this.context.purchasedProduct.owned !== true){
                    //// safely add the product
                    if(updateProduct?.['id']?.includes('premium')){
                        updateProduct['premium'] = true;
                    }
                    //// safely add the items
                    if(product.owned){
                        updateProduct['owned'] = true;
                        this.props.firebase.user(this.context.uid).update({ purchasedProduct: updateProduct});
                    }
                    else if (product?.['stateFovea'] === 'verified'){
                        updateProduct['owned'] = true;
                        this.props.firebase.user(this.context.uid).update({ purchasedProduct: updateProduct});
                    }
                    else{
                        if(this.context?.purchasedProduct?.owned === true){
                            //overwrite owner status if it is already owned in the system
                            updateProduct['owned'] = true;
                        }
                        this.props.firebase.user(this.context.uid).update({ purchasedProduct: updateProduct});
                    }
                }
                updateProduct = Object.assign({}, updateProduct, {transaction});
                updateProduct = Object.assign({}, product, updateProduct);
                updateProduct['createdAt'] = this.props.firebase.fieldValue.serverTimestamp();
                Object.keys(updateProduct).forEach(key => updateProduct[key] === undefined && delete updateProduct[key])
                if(debugFlag) console.log('update produc after', updateProduct)
                /// use the orderID to save the itme
                if(updateProduct?.orderId?.length > 5){
                    this.props.firebase.purchases(this.context.uid).doc(updateProduct.orderId).set(updateProduct, {merge: true});
                }
                else{
                    this.props.firebase.purchases(this.context.uid).add(updateProduct);
                }
            }
        } catch (error){
            this.setState({error: 'Sorry. Something went wrong with the purchase!'});
            console.log(error)
        }
    };

    render() {
        const { user } = this.props;
        const { theme, classes } = this.props;
        
        //console.log(process.env, 'version', process.env.npm_package_version)
        return (
            user
            ?
                <div className={classes.grow}>
                    <Dialog
                        open={this.state.loadingStoreDialogue || false}
                        onClose={()=>{this.setState({loadingStoreDialogue:false})}}
                        PaperProps={{style:{borderRadius: theme.borderRadius}}}
                        >
                        <DialogContent>
                            <div className={classes.form}>
                                {
                                    <LoadingProgress 
                                        defaultSet={this.props.defaultSet}
                                        type={'LoadingPageAnimated'} loadingText={"loading store"} />
                                }
                                {this.state.error && <p className={classes.error}>{this.state.error}</p>}
                                {!this.state.loadedStore && <p className={classes.error}>Store is loading...</p>}
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button 
                                onClick = {()=>{this.setState({loadingStoreDialogue:false})}}
                                color="primary"
                                >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {this.state.error !== undefined && this.state.error !== "" &&
                        <p style={theme.textSettings}>
                            <span>Info: </span>
                            <span style={theme.textErrorCenterPadding}>{this.state.error}</span>
                        </p>
                    }
                    {(this.props.showOffers!==false &&
                        //(this.context.purchasedProduct === undefined  || (this.context.purchasedProduct !== undefined && this.context.purchasedProduct.owned!==true) 
                        (true) 
                    ) 
                        &&
                        this.showSubscriptionOffers()
                    }
                    {window.CdvPurchase !== undefined && this.state.loadedStore
                        ?
                            <div>
                                <div style={{textAlign:'center', marginTop:10}}>
                                    <Button onClick={() => this.storeRefresh()}>
                                    Refresh Mobile Purchases</Button>
                                </div>
                                <div style={{textAlign:'center', marginTop:10}}>
                                    <Button onClick={() => this.storeManageSubscriptions()}>
                                    Manage Mobile Subscriptions</Button>
                                </div>
                            </div>
                        :
                            <div></div>
                    }
                    {this.props.showTerms &&
                        <Accordion style={{marginTop:30}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="About-Expand"
                                aria-controls="About-content"
                                id="About-header"
                                >
                                <FormControlLabel
                                    aria-label="About"
                                    control={<InfoIcon style={theme.cardSettings.iconDesign} />}
                                    label="About, Terms & Privacy"
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                <SettingAbout user={user} defaultSet={this.props.defaultSet}/>
                            </AccordionDetails>
                        </Accordion>
                    }
                </div>
            :
                "Items loading"
        );
    }

    showSubscriptionOffers(){
        //console.log("product", this.state.products, "ids", this.state.groupIdTitles)
        if(this.state.products!== undefined && this.state.groupIdTitles!==undefined){
            return(
                <div style={{overflowX: 'hidden', width: '100%', maxWidth: 600}}>
                    <Grid container direction={"row"}>
                        {
                            this.state.groupIdTitles.length>0
                            ?
                                this.state.groupIdTitles.map((productGroup, index) => {
                                    //console.log(this.state.products[productGroup])
                                    return(
                                        <Grid item key={'item_'+index}>
                                            <SubscriptionCard 
                                                item={this.state.products[productGroup]}
                                                user={this.context}
                                                changeOffer={this.state.showOfferId || undefined}
                                                changeSelection={(pid) => {this.setState({showOfferId: pid})}}
                                                storeManageSubscriptions={() => this.storeManageSubscriptions()}
                                                cardClickAction={(selectedProduct) => {
                                                    if(this.state.loadedStore){
                                                        //console.log('calling for a ordering by purchase button', selectedProduct)
                                                        this.setState({loadingStoreDialogue: true, error: 'Loading shop for: '+selectedProduct.title + '...'})
                                                        this.setState({flagowned: false, flagverified: false, flagexpired: false, flagapproved: false})
                                                        //window.CdvPurchase?.store?.order(selectedProduct.id, {applicationUsername: this.context.uid})
                                                        const offer = window.CdvPurchase?.store?.get(
                                                            selectedProduct.id, 
                                                            (window.device.platform === 'Android' ? "android-playstore" : "ios-appstore")
                                                        )?.getOffer(selectedProduct?.offer?.id);
                                                        //console.log('calling for offer', offer)
                                                        window.CdvPurchase?.store?.order(offer, {applicationUsername: this.context.uid})
                                                    }
                                                    else {
                                                        this.setState({error: "Mobile store not ready yet!"})
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    )
                                })
                            :
                                <Grid item>No offers available</Grid>
                        }
                    </Grid>
                </div>
            )
        }
        else{
            return("")
        }
    }

    storeRefresh(){
        try{
            this.setState({
                //loadingStoreDialogue: true, 
                error: 'Updating shop ...',
                flagowned: false, flagverified: false, flagexpired: false, flagapproved:false
            })
            window.CdvPurchase?.store?.restorePurchases();
        } catch (error){
            console.log(error)
        }
    }

    storeManageSubscriptions(){
        try{
            this.setState({flagowned: false, flagverified: false, flagexpired: false, flagapproved: false})
            window.CdvPurchase?.store?.manageSubscriptions();
        } catch (error){
            console.log(error)
        }
    }
}

SettingSubscriptionMobile.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(SettingSubscriptionMobile);