
export default function InternetConnectionQuality() {
    var speed = 'unknown';
    var event = undefined;
    try{
        speed = _connectionDetails();
        if(navigator.onLine){
            window.addEventListener("offline", () => {
                //console.log('reported offline')
                speed = _connectionDetails();
                speed = 'low'
                event = new CustomEvent("internet-connection-qulity", {detail: {speed: speed}} );
                document.dispatchEvent(event);
            });
        }
        else{
            window.addEventListener("online", () => {
                //console.log('reported online');
                speed = _connectionDetails();
                event = new CustomEvent("internet-connection-qulity", {detail: {speed: speed}} );
                document.dispatchEvent(event);
            });
        }
        event = new CustomEvent("internet-connection-qulity", {detail : {speed: speed}} );
        document.dispatchEvent(event);
        return speed;
    }
    catch(error){
        console.log('Internet Check error', error)
        return speed
    }
}

function _connectionDetails(){
    var connection = undefined;
    //var type = 'none';
    var speed = 'unknown';
    var downlink = 0;
    try {
        connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
        if(navigator.onLine){
            speed = 'online'
        }
        else{
            speed = 'low'
        }
    }
    catch(e){
        console.log('connection info error', e)
    }
    //console.log('connection Info', connection)        
    if (!!connection) {
        // Get the connection type
        //type = connection.type;
        //console.log('type', type)
        // Get the connection speed in megabits per second (Mbps)
        if(connection.downlink !== undefined && connection.downlink >= 0){
            downlink = connection.downlink;
            if (downlink < 2){ speed='low' }
            else {speed = 'online'}
            //console.log('downlink', downlink)
        }
        else if(connection.downlinkMax !== undefined && connection.downlinkMax >= 0){
            downlink = connection.downlinkMax;
            if (downlink < 2){ speed='low' }
            else {speed = 'online'}
            //console.log('downlinkMax', downlink)
        }
        else if(connection.bandwidth !== undefined && connection.bandwidth >= 0){
            downlink = connection.bandwidth;
            if (downlink < 2){ speed='low' }
            else {speed = 'online'}
            //console.log('bandwidth', downlink)
        }
        //console.log('speed', speed)
    }
    return speed
}