import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import LoadingProgress from '../Modules/LoadingProgress';

//import * as nodeCCAvenue from 'node-ccavenue';
import PaytmChecksum from 'paytmchecksum';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import SubscriptionCard from '../Profile/SubscriptionCard';

import Button from '@material-ui/core/Button';
//import Card from '@material-ui/core/Card';
import {IsMobileDevice} from '../Modules/IsMobileDevice';
import Grid from '@material-ui/core/Grid';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingAbout from './SettingAbout';

// style elements
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';

//popup and dialogu features
import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Slide from '@material-ui/core/Slide';
import { CropDinSharp } from '@material-ui/icons';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});



class SettingSubscriptionPaytm extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.staging = false
        var midTest = "eEZBQC27270168889878"
        this.mid = "mulLUM16179941294595"
        this.key = "uCaIRCEX@6ib7L!1"
        var keyTest = "wJ5r2KPW0QwcpyB8"
        if (this.staging){
            this.key = keyTest
            this.mid = midTest;
        }

        this.state = {
            open: false,
            plan: 'Content and Web App',
            error: '',
            productForPayment : null,
            status1: 'Loading...',
            status2: 'Loading...',
            loadedStore: false,
            approved: false,
            verified: false,
            owned: false,
        };
    }

    createObjectId () {
        return this.createHex(Date.now() / 1000) +
            ' '.repeat(16).replace(/./g, () => this.createHex(Math.random() * 16))
    }
    createHex (value) {
        return Math.floor(value).toString(16)
    }

    initiatePaytmToken = async (product) => { 
        var orderId = undefined;
        if(product?.merchantId?.length>2){
            this.mid = product.merchantId;
        }
        if(product?.merchantKey?.length>2){
            this.key = product.merchantKey;
        }
        if(this._isMounted ){
            //console.log('initiate paytm token', product) 
            orderId = ""+String(this.createObjectId());
            //console.log('orderId', orderId)
            this.setState({orderId: orderId})
            var paytmParams = {};
            paytmParams.body = {
                "requestType"   : "Payment",
                //"requestType"   : "NATIVE_SUBSCRIPTION",
                "mid"           : this.mid,
                "websiteName"   : (this.staging? "WEBSTAGING" : "DEFAULT"),
                "orderId"       : orderId,
                "subscriptionAmountType": "FIX",
                "subscriptionFrequencyUnit" : "MONTH",
                //"subscriptionStartDate" : "2024-01-03",
                //"subscriptionExpiryDate": "2025-12-12",
                //"subscriptionGraceDays" : "3",
                "subscriptionEnableRetry" : "1",
                //"callbackUrl"   : "https://<callback URL to be used by merchant>",
                "txnAmount"     : {
                    "value"     : product?.price?.replace(/[^0-9]/g, '') || "1990.00",
                    "currency"  : "INR",
                },
                "userInfo"      : {
                    "custId"    : this.context.uid,
                    "custEmail" : this.context.email,
                    "coachcode" : product.groupId?.replace("#",""),       
                    "productId" : product.storeId,
                    "utm_source" : (IsMobileDevice() ? (window?.device?.platform === 'Android'? "Android" : window?.device?.platform === 'iOS' ? "iOS" : "mobile") : "web"),
                },
            };
            /*
            * Generate checksum by parameters we have in body
            * Find your Merchant Key in your Paytm Dashboard at https://dashboard.paytm.com/next/apikeys 
            */
            PaytmChecksum.generateSignature(JSON.stringify(paytmParams.body), this.key).then(async (checksum) => {
                if(this._isMounted ){
                    paytmParams.head = {
                        "signature"    : checksum
                    };
                    //console.log('checksum', checksum)
                    var post_data = JSON.stringify(paytmParams);

                    /* for Production */
                    var hostname= 'securegw.paytm.in';
                    if (this.staging){
                        /* for Staging */
                        hostname = 'securegw-stage.paytm.in';
                    }
                    var path = '/theia/api/v1/initiateTransaction?mid='+this.mid+'&orderId='+orderId
                    //path = '/subscription/create?mid='+mid+'&orderId='+orderId
                    var options = {
                        //hostname: hostname,
                        //port: 443,
                        //path: path,
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Content-Length': post_data.length
                        }
                    };
                    var url = 'https://'+hostname+path;

                    //var response = "";
                    var response = await this.requestAPI(url, post_data, options)
                    let result = await response.json();
                    //console.log('results',  result)
                    var txnToken = result?.body?.txnToken || undefined;
                    this.setState({txnToken})
                }
            });
            /**
            var url = "https://securegw-stage.paytm.in/theia/api/v1/initiateTransaction?mid={mid}&orderId="+this.context.uid
            fetch("https://domain.provider.com/mas/api/v1/mail/transaction", {
                body: "{\"id\":\"3970a1b0-6e27-448a-adfc-0083db15b2fb\", \"tokens\":{\"design_token1\":\"Hi\",\"design_token2\":\"Hello\",\"design_token3\":\"World\",\"subject_token1\":\"XYZ\"}, \"recipient\":\"james@sample.com\"}",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Token: "sfg999666t673t7t82"
                },
                method: "POST"
            })
            */
        }
        return orderId
    }

    requestAPI = async (url, data, options) => {
        //console.log('options', options, data)
        var dataset = {
            body: data,
            ...options
        }
        //console.log('dataset', dataset)
        return fetch(url, dataset);
    }

    loadPaytmScript(article){
        //console.log('confic article', article)
        this.setState({open: true, loadingShop: true, error: "Please wait, the checkout will be opened in a second ..."})
        var config = {
            "root": "#checkout-hook",
            "flow": "DEFAULT",
            "data": {
                "orderId": this.state.orderId || "", /* update order id */
                "token": this.state.txnToken || "", /* update token value */
                "tokenType": "TXN_TOKEN",
                "amount": "2233.00" ,/* update amount */
            },
            "merchant": {
                "redirect": false
            },
            "handler": {
                "notifyMerchant": (eventName, data) => {
                    console.log("notifyMerchant handler function called");
                    //console.log("eventName => ",eventName);
                    //console.log("data => ",data);
                },
                "transactionStatus": (data) => {
                    //console.log("payment status ", data);  
                    /****
                        BANKNAME  :  "OTHERS"
                        BANKTXNID  :  "777001085202831"
                        CHECKSUMHASH :  "X6kVfPOR0aq3WMhvYNmlBwKRGkCJ451p12CNLlvOAgAZJuOPFKspBZspdUxTF5d/pn/Z7AMRbxVmZBgeKdBp6YQsThpeR0WSDK7kjaI4QE8="
                        CURRENCY   :   "INR"
                        GATEWAYNAME   :  "HDFC"
                        MID  :  "eEZBQC27270168889878"
                        ORDERID   :  "65a6d8aeb0c5e99e8f91ee02"
                        PAYMENTMODE   :  "DC"
                        RESPCODE :   "01"
                        RESPMSG  :  "Txn Success"
                        STATUS   :  "TXN_SUCCESS"
                        TXNAMOUNT :  "2300.00"
                        TXNDATE  :   "2024-01-17 00:57:43.0"
                        TXNID  :   "20240117011640000954181775310147325"
                     */
                    if(data.STATUS === "TXN_SUCCESS"){
                        this.setState({loadingShop: true, error: data.RESPMSG || "Success!"});
                        var product = {...article}
                        this.paytmUpdateTransactionStatus(product, data);
                        this.setState({open: false })
                    }
                    else{
                        this.setState({open: false, error: data.RESPMSG || "Validation Failed!" })
                    }
                } 
            }
        }
        ///// load paytm script
        /**
         */
        const script = document.createElement("script");
        script.async = true;
        script.type = "application/javascript"
        script.crossorigin = "anonymous"
        if (this.staging){
            /* for Staging */
            script.src = "https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/"+this.mid+".js";
        }
        else{
            script.src = "https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/"+this.mid+".js";
        }
        script.onload = () => {
            if(window.Paytm && window.Paytm.CheckoutJS){
                //window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
                window.Paytm.CheckoutJS.onLoad(() => {
                // initialze configuration using init method
                    //console.log('config', config);
                    window.Paytm.CheckoutJS.init(config).then(() => {
                        // after successfully updating configuration, invoke JS Checkout
                        this.setState({loadingShop: false})
                        //console.log('loading shop finished')
                        window.Paytm.CheckoutJS.invoke();
                    }).catch((error) => {
                        this.setState({loadingShop: false, open: false, error: error.message || "Error"})
                        console.log("error => ", error);
                    });
                });
            }
        };
        script.onerror = error => {
            console.error(error + 'script load fail!');
        }
        document.body.appendChild(script);
        //script.onload = () => this.scriptLoaded();
    }

    paytmUpdateTransactionStatus = (product, receipt) => {
        var debugFlag = false;
        try{
            if(debugFlag) console.log('appstoreT', product, receipt)
            var checkPurchases = {};
            if ('purchasedProduct' in this.context){
                checkPurchases = this.context.purchasedProduct;
            }
            receipt['email'] = this.context.email || "";
            var productId = product.id;
            var updateProduct = {...product};
            if(debugFlag) console.log('product', product)
            if(receipt.STATUS === "TXN_SUCCESS"){
                updateProduct["id"] = productId;
                updateProduct["state"] = 'active' || 'n/a';
                updateProduct["owned"] = true;
                updateProduct["orderId"] = receipt.ORDERID || 'n/a';
                updateProduct["STATUS"] = receipt.STATUS || 'n/a';
                updateProduct["CURRENCY"] = receipt.CURRENCY || 'n/a';
                updateProduct["TXNAMOUNT"] = receipt.TXNAMOUNT || 'n/a';
                updateProduct['email'] = receipt['email']
                updateProduct['paymentSource'] = 'Paytm';
                if(this._isMounted){
                    this.setState({showOfferId: productId})
                }
                updateProduct = {...checkPurchases, ...updateProduct};
                if('expiryDate' in product){
                    updateProduct['expiryDate'] = product.expiryDate;
                    //updateProduct = Object.assign(updateProduct, {expiryDate: product.expiryDate});
                }
                var transaction = {}
                if('nativeTransactions' in receipt){
                    transaction = receipt.nativeTransactions[0];
                    if(debugFlag) console.log('nativeTransactions', transaction)
                    var startTimeMillis = 0;
                    var expiryTimeMillis = 0;
                    if('purchase_date_ms' in transaction && 'transaction_id' in transaction){
                        // apple new
                        updateProduct['paymentSource'] = 'Fovea';
                        updateProduct['type'] = transaction.type || '';
                        updateProduct['orderId'] = transaction.transaction_id || '';
                        if('is_trial_period' in transaction){
                            updateProduct['introPricePaymentMode'] = transaction.is_trial_period;
                        }
                        startTimeMillis = parseInt(transaction.purchase_date_ms) || 0;
                        expiryTimeMillis = parseInt(transaction.expires_date_ms) || 0;
                    }
                    else if('in_app' in transaction){
                        // apple reload
                        updateProduct['paymentSource'] = 'Fovea';
                        transaction = transaction.in_app[0];
                        if(debugFlag) console.log('in_app', transaction)
                        updateProduct['type'] = product.type || '';
                        updateProduct['orderId'] = transaction.transaction_id || '';
                        if('is_trial_period' in transaction){
                            updateProduct['introPricePaymentMode'] = transaction.is_trial_period;
                        }
                        startTimeMillis = parseInt(transaction.purchase_date_ms) || 0;
                        expiryTimeMillis = parseInt(transaction.expires_date_ms) || 0;
                    }
                    else if(transaction?.startTimeMillis > 1000){
                        // google
                        updateProduct['paymentSource'] = 'Fovea';
                        updateProduct['type'] = transaction.type || '';
                        startTimeMillis = (parseInt(transaction.startTimeMillis) ) || 0;
                        expiryTimeMillis = (parseInt(transaction.expiryTimeMillis) ) || 0;
                        if('introPricePaymentMode' in product){
                            updateProduct['introPricePaymentMode'] = product.introPricePaymentMode;
                        }
                    }
                    /// add elements
                    if (startTimeMillis>0){
                        var dateS = new Date(startTimeMillis);
                        transaction['purchaseDate'] = dateS;
                        updateProduct['purchaseDate'] = dateS;
                        if(debugFlag) console.log('timesets', startTimeMillis,  transaction['purchaseDate'] )
                    }
                    if (expiryTimeMillis>0){
                        var dateE = new Date(expiryTimeMillis);
                        transaction['expiryDate'] = dateE;
                        updateProduct['expiryDate'] = dateE;
                        if(debugFlag) console.log('timesets', expiryTimeMillis,  transaction['expiryDate'] )
                    }
                    if(debugFlag) console.log('timesets', updateProduct,  transaction)
                    //updateProduct = updateProduct.update(transaction)
                    //updateProduct = Object.assign(updateProduct, {transaction});
                    Object.keys(transaction).forEach(key => transaction[key] === undefined && delete transaction[key])
                }
                //console.log('anything', updateProduct)
                Object.keys(updateProduct).forEach(key => updateProduct[key] === undefined && delete updateProduct[key])
                try{
                    delete updateProduct['items'];
                    delete updateProduct['author'];
                    delete updateProduct['imageURL'];
                }
                catch(error){console.log('error del', error)}
                //// in the case ensure to set owned true but not overwrite if already owned
                if(this.context.purchasedProduct === undefined || this.context.purchasedProduct.owned !== true){
                    if(product.owned){
                        this.props.firebase.user(this.context.uid).update({ purchasedProduct: updateProduct});
                    }
                    else if (product['stateFovea'] === 'verified'){
                        updateProduct['owned'] = true;
                        this.props.firebase.user(this.context.uid).update({ purchasedProduct: updateProduct});
                    }
                    else{
                        if(this.context?.purchasedProduct?.owned === true){
                            //overwrite owner status if it is already owned in the system
                            updateProduct['owned'] = true;
                        }
                        this.props.firebase.user(this.context.uid).update({ purchasedProduct: updateProduct});
                    }
                }
                
                updateProduct['transaction'] = receipt;
                updateProduct['createdAt'] = this.props.firebase.fieldValue.serverTimestamp();
                
                if(debugFlag) console.log('update produc after', updateProduct)
                /// use the orderID to save the itme
                if(updateProduct?.orderId?.length > 5){
                    this.props.firebase.purchases(this.context.uid).doc(updateProduct.orderId).set(updateProduct, {merge: true});
                }
                else{
                    this.props.firebase.purchases(this.context.uid).add(updateProduct);
                }
            }
        } catch (error){
            this.setState({error: 'Sorry. Something went wrong with the purchase!'});
            console.log(error)
        }
    };

    componentDidMount() {
        this._isMounted = true;
        // For body
        //////
        var buyer = 'enduser';
        var groupId = 'ROCKY';
        if(this.props.coach || (this.context.roles?.includes('COACHADMIN') || this.context.roles?.includes('REMOTECOACH') )){
            buyer = 'coach';
            groupId = 'REMOTECOACH';
        }
        if(this.props.coachCode) {
            groupId = this.props.coachCode;
        }
        var production = true;
        if(this.props.sandbox) {
            production = false;
        }
        var products = {};
        var groupIdTitles = [];
        groupId = groupId.replace("#","");
        this.props.firebase.purchaseArticles()
        .where('active', '==', true)
        .where('store', '==', 'Paytm')
        .where('production', '==', production)
        .where('groupId', '==', groupId)
        .where('category', '==', buyer)
        .orderBy('position', 'asc')
        .get()
        .then(articles => {
            if(this._isMounted){
                var startArticle = undefined;
                articles.forEach(doc => {
                    var article = doc.data();
                    if(this.props.premiumOnly === true && !article.premium){
                        // do nothing
                    }
                    else{
                        var groupId = doc.id;
                        if (article.groupId !== undefined && article.groupId !== ''){
                            groupId = article.groupId;
                        }
                        if (groupIdTitles.includes(groupId)){
                            if(article.position === 1){
                                products[groupId]['items'].unshift(article);
                                startArticle = article;
                                //console.log('number 1', products)
                            }
                            else if(article.position > 1){
                                //console.log('number pos', article.position, products[groupId]['items'].length, products)
                                if(article.position <= products[groupId]['items'].length){
                                    products[groupId]['items'].splice((article.position - 1), 0, article);
                                }
                                else{
                                    products[groupId]['items'].push(article);
                                }
                            }
                            else{
                                products[groupId]['items'].push(article);
                            }
                        }
                        else{
                            groupIdTitles.push(groupId)
                            products[groupId] = article;
                            products[groupId]['items'] = [];
                            products[groupId]['items'].push(article);
                        }
                        if(startArticle === undefined){
                            startArticle = article;
                        }
                    }
                });
                if(this._isMounted ){
                    if(startArticle !== undefined){
                        this.initiatePaytmToken(startArticle);
                    }
                    //console.log(products, groupIdTitles)
                    this.setState({
                        products,
                        groupIdTitles
                    });
                }
                //console.log(products, groupIdTitles)
            }
        })
        
        this.setState({
            groupId: groupId, 
            buyer
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { user } = this.props;
        const { theme, classes } = this.props;
        
        //console.log(process.env, 'version', process.env.npm_package_version)
        return (
            user
            ?
                <div className={classes.grow}>
                    {this.props.showHeading && 
                        <h1><ShoppingCartIcon/>&nbsp; My offers</h1>
                    }
                    {this.state.error !== undefined && this.state.error !== "" &&
                        <p style={theme.textSettings}>
                            <span>Info: <br/> </span>
                            <span style={theme.textErrorCenterPadding}>{this.state.error}</span>
                        </p>
                    }
                    {
                        this.showSubscriptionOffers()
                    }
                    {
                        this.showPaymentDialogue()
                    }

                    {this.props.showTerms &&
                        <Accordion style={{marginTop:30}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="About-Expand"
                                aria-controls="About-content"
                                id="About-header"
                                >
                                <FormControlLabel
                                    aria-label="About"
                                    control={<InfoIcon style={theme.cardSettings.iconDesign} />}
                                    label="About, Terms & Privacy"
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                <SettingAbout user={user} defaultSet={this.props.defaultSet}/>
                            </AccordionDetails>
                        </Accordion>
                    }
                </div>
            :
                "User loading"
        );
    }

    showSubscriptionOffers(){
        const {theme} = this.props;
        //console.log("p", this.state.products, "ids", this.state.groupIdTitles)
        if(this.state.products!== undefined && this.state.groupIdTitles!==undefined){
            return(
                <div style={{overflowX: 'hidden', width: '100%', maxWidth: 600}}>
                    <Grid container direction={"row"}>
                        {
                            this.state.groupIdTitles.length>0
                            ?
                                this.state.groupIdTitles.map((productGroup, index) => {
                                    //console.log(this.state.products[productGroup])
                                    return(
                                        <Grid item key={'item_'+index}>
                                            <SubscriptionCard 
                                                user={this.context}
                                                item={this.state.products[productGroup]}
                                                changeArticle={ (selectedProduct) => {this.initiatePaytmToken(selectedProduct)}}
                                                cardClickAction={ (selectedProduct) => {this.loadPaytmScript(selectedProduct)}}
                                                //cardClickAction={ (selectedProduct) => {this.openPaymentDialogue(selectedProduct)}}
                                            />
                                        </Grid>
                                    )
                                })
                            :   this.props.fallbackURL?.includes('http')
                                ?
                                    <Grid item style={{width: '100%'}}>
                                        <p style={theme.textSupport}>Get your subscription</p>
                                        <br/><br/>
                                        <Button onClick={() => {window.open(this.props.fallbackURL.replace("{{uid}}", this.context.uid).replace("{{email}}", this.context.email), "_blank")}}>
                                            Open Store
                                        </Button>
                                    </Grid>
                                :
                                    <Grid item>No offers available</Grid>
                        }
                    </Grid>
                </div>
            )
        }
        else{
            return("")
        }
    }

    /***
    URL: https://secure.ccavenue.com/txn/shopcart/access_code,product_id,product_currency,return_url


    product_id: 60699
     */

    openPaymentDialogue = (product, openIframe=false) => {
        /***
        const ccav = new nodeCCAvenue.Configure({
            working_key: "99243B2AE83D60B2998261BB5B5B4B49",
            merchant_id: "3111297",
        });
        const orderParams = {
            billing_name: "Name of the customer",
            currency: "INR",
            product_id: "60699"
        };
        const encryptedOrderData = ccav.getEncryptedOrder(orderParams);
        console.log('encryptedOrderData', encryptedOrderData)
         */
        //paytmURL = paytmURL + '&encRequest=' + encryptedOrderData;
        //paytmURL = paytmURL + '&product_id=60699&access_code=AVJI41KL72BL18IJLB&product_currency=INR';

        this.setState({productForPayment: product});
        var paytmURL = 'https://paytm.business/link/'+product.id+'';
        //paytmURL = 'https://secure.ccavenue.com/txn/shopcart'
        if(this.props.sandbox) {
            //paytmURL = "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction";
        }
        /////// FIRST HAS ?
        paytmURL = paytmURL + '?order_id=' + this.context.uid;
        //paytmURL = paytmURL + 'testMode=N&paymentcc=Y';utm_content
        paytmURL = paytmURL + '&prefilled_promo_code=' + product.storeId;
        paytmURL = paytmURL + '&utm_source=' + (IsMobileDevice() ? (window?.device?.platform === 'Android'? "Android" : window?.device?.platform === 'iOS' ? "iOS" : "mobile") : "web");
        paytmURL = paytmURL + '&utm_content=' + product.groupId?.replace("#","");
        paytmURL = paytmURL + '&description=' + "Best APP ever";
        paytmURL = paytmURL + '&prefilled_email=' + this.props.user.email;
        //paytmURL = paytmURL + '&encRequest=' + encryptedOrderData;
        //paytmURL = paytmURL + '&product_id=60699&access_code=AVJI41KL72BL18IJLB&product_currency=INR';
        
        //paytmURL = paytmURL + '&sealmcafeevisible=Y';
        //paytmURL = paytmURL + '&sealverisignvisible=Y';
        if(this.props.sandbox) {
            console.log(paytmURL);
        }
        if(openIframe){
            this.setState({open: true, productForPayment:product, paytmURL });
        }
        else{
            this.setState({error: "The checkout will be opened in a new browser window and use our payment partner paytm!"})
            paytmURL = paytmURL + '&browsertitle=Checkout+AI+Coach';
            var win = window.open(paytmURL, '_blank');
            win.focus();
        }
    }

    closePaymentDialogue = () => {
        this.setState({open: false, productForPayment:null, paytmURL:''})
    }

    showPaymentDialogue = () => {
        const {theme} = this.props;
        return(
            <RemoveScroll enabled={this.state.open || false}>
                <Dialog
                    open={this.state.open}
                    onClose={this.closePaymentDialogue}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                >
                    <DialogTitle style={theme.dialogTitle}  >
                        <IconButton onClick={this.closePaymentDialogue}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span style={theme.dialogTitle.titleText}>
                            {this.props.sandbox? "Sandbox " : "" } Shop
                        </span>
                    </DialogTitle>
                    <DialogContent style={{
                        margin:0, 
                        padding:0 , 
                        overflow: 'hidden'
                        }
                    }>
                        <div id="checkout-hook">
                            {this.state.loadingShop &&
                                <LoadingProgress
                                    defaultSet={this.props.defaultSet}
                                    type={'LoadingPageAnimated'} loadingText={"Shop loading"} 
                                />
                            }
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closePaymentDialogue}>
                            Close Shop
                        </Button>
                    </DialogActions>
                </Dialog>
            </RemoveScroll> 
        )
    }

}

SettingSubscriptionPaytm.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(SettingSubscriptionPaytm);



        /**
        let productsEnduser = [
            {
                store: 'Bluesnap',
                id: '2611841',
                title: 'Daily Coaching Questions',
                state: 'loading',
                description: 'Unlimited access to the reflection journaling',
                price: 'USD 3.99',
                owned: false,
                canPurchase: true,
                type: 'MONTHLY',
                billing: 'monthly', 
                button: 'Subscribe monthly'
            },
            {
                store: 'Bluesnap',
                id: '2611863',
                title: 'Daily Coaching Questions',
                state: 'loading',
                description: 'Unlimited access to the reflection journaling',
                price: 'USD 31.99',
                promotion: 'Save 30%',
                owned: false,
                canPurchase: true,
                type: 'YEARLY',
                billing: 'annualy',
                button: 'Subscribe annually'
            }
        ]
        if(this.props.sandbox) {
            productsEnduser = [
                {
                    store: 'Bluesnap',
                    id: '2611841',
                    title: 'Daily Coaching Questions',
                    state: 'loading',
                    description: 'Unlimited access to the reflection journaling',
                    price: 'USD 3.99',
                    owned: false,
                    canPurchase: true,
                    type: 'MONTHLY',
                    billing: 'monthly',
                    button: 'Subscribe monthly'
                },
                {
                    store: 'Bluesnap',
                    id: '2611863',
                    title: 'Daily Coaching Questions',
                    state: 'loading',
                    description: 'Unlimited access to the reflection journaling',
                    price: 'USD 31.99',
                    promotion: 'Save 30%',
                    owned: false,
                    canPurchase: true,
                    type: 'YEARLY',
                    billing: 'annualy',
                    button: 'Subscribe annually'
                }
            ]
        }
        let productsCoach = [
            {
                store: 'Bluesnap',
                id: '3874270',
                title: 'Monthly Coachee Seats',
                state: 'loading',
                description: 'Premium account for Rocky.ai with remote coach access',
                price: 'USD 10.00',
                owned: false,
                canPurchase: true,
                type: 'SEATS',
                billing: "once",
                button: 'Buy Seats'
            }
        ]
        if(this.props.sandbox) {
            productsCoach = [
                {
                    store: 'Bluesnap',
                    id: '2621585',
                    title: 'Monthly Coachee Seats',
                    state: 'loading',
                    description: 'Premium account for Rocky.ai with remote coach access',
                    price: 'USD 10.00',
                    owned: false,
                    canPurchase: true,
                    type: 'SEATS',
                    billing: "once",
                    button: 'Buy Seats'
                }
            ]
        }
         */
