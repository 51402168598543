import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import {DataCacheRead, DataCacheWrite} from "../Modules/DataCache";

import FileCopyIcon from '@material-ui/icons/FileCopy';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
//import { BugReportTwoTone } from '@material-ui/icons';
function Alert(props) {
    return <MuiAlert elevation={10} variant="filled" {...props} />;
}

export function CopyTextAlert(that, text){
    return(
        <>
            <IconButton>
                <FileCopyIcon 
                    onClick={() => {
                        navigator?.clipboard?.writeText( text )
                        that.setState({openAlert: true, alertText: "Copied: " + text})
                    }}
                    fontSize="small" 
                />
            </IconButton>
            <SnackbarInfo 
                alertText = {"Copied: " + text}
                openOnClick = {true}
                alertOpenEvent = {that.state?.openAlert || false}
                closeHandler = {() => {that.setState({openAlert: false})}}
            />
        </>
    )
}

class SnackbarInfo extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        var alertText = 'Your digital AI coaching chatbot that will ask you self-coaching questions.';
        if (this.props.alertText !== undefined && this.props.alertText !== ''){
            alertText = this.props.alertText
        }
        var alertName = 'general';
        if (this.props.alertName){
            alertName = this.props.alertName
        }
        var userId = 'allusers';
        if (this.props.userId){
            userId = this.props.userId
        }
        this.state = {
            autoHideDuration : 6000,
            severity: 'info', 
            //severity: 'warning',
            //marginBottom: 100,
            alertText: alertText,
            alertName: alertName,
            openAlert: false,
            shownAlertCounter: 0,
            clickedAwayCounter: 0,
            maxAlertCounts: 6,
            maxAlertClickAways: 2,
            userId: userId
        }
    }
    componentDidMount() {
        this._isMounted = true;
        //// principally deactivated
        if(this.props.openAuto && this.props.openOnClick !== true){
            var cacheStoreUser = DataCacheRead('infoalert_'+this.state.alertName+this.state.userId)
            if(cacheStoreUser.cacheAvailable && cacheStoreUser.cacheData !== undefined
                && cacheStoreUser.cacheData.shownAlertCounter !== undefined
                ) {
                var alertData = cacheStoreUser.cacheData;
                var clickedAwayCounter = this.state.clickedAwayCounter;
                if(cacheStoreUser.cacheData.clickedAwayCounter !== undefined){
                    clickedAwayCounter = cacheStoreUser.cacheData.clickedAwayCounter ;
                }
                if(alertData.shownAlertCounter < this.state.maxAlertCounts 
                    && clickedAwayCounter < this.state.maxAlertClickAways ){
                    this.setState({
                        openAlert : true,
                        shownAlertCounter : cacheStoreUser.cacheData.shownAlertCounter + 1,
                        clickedAwayCounter: clickedAwayCounter
                    })
                }
            }
            else{
                this.setState({
                    openAlert : true,
                    shownAlertCounter :  1
                })
            }
            //console.log(this.state.alertName, this.state)
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps) {
        //console.log('props', prevProps, this.props)
        if(this._isMounted && prevProps!==undefined && prevProps.alertText !== this.props.alertText ){
            this.setState({alertText : this.props.alertText  })
        }
        if(this._isMounted && prevProps!==undefined && prevProps.alertOpenEvent !== this.props.alertOpenEvent ){
            this.setState({openAlert : this.props.alertOpenEvent  })
        }
    }

    render() {
        //console.log('alert', this.props)
        return(
            <Snackbar 
                //anchorOrigin={{ vertical:'top', horizontal:'right' }}
                //key={`top,right`}
                //open={true}
                key={this.state.alertName}
                open={(this.state.openAlert || this.props.alertOpenEvent)|| false} 
                autoHideDuration={this.state.autoHideDuration} 
                onClose={(event, reason) => {this.autoCloseAlert(reason)}}
                onClick={() => {this.autoCloseAlert()}}
                style={
                    this.props.swipeIndex!==undefined && this.props.swipeIndex>0
                    ?   {bottom:100, width:'100%', left: this.props.swipeIndex!==undefined ? (String(this.props.swipeIndex*100)+'%') : '50%', transform:'none'}
                    :   {bottom:100, maxWidth:400, marginLeft: 'auto', marginRight: 'auto'}
                }
            >
                    {/*** */}
                    <Alert 
                        key={this.state.alertName}
                        variant="filled" 
                        onClose={() => this.handleCloseAlert()} 
                        severity={this.state.severity}
                        style={{
                            marginBottom:this.state.marginBottom, 
                            backgroundColor: '#FCD730',
                            color: 'black',
                            marginLeft: 10,
                            marginRight: 10,
                            //fontWeight: 'bold',
                            opacity: 0.94,
                            fontSize: 14,
                            borderRadius: 12,
                        }}
                        >
                        <span>
                            {this.state.alertText}
                        </span>
                        {this.props.buttonText?.length > 0 &&
                            <div style={{width: '100%', textAlign: 'center', marginTop: 10}}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={ () => {
                                        this.props.history.push(this.props.buttonLink);
                                    }}
                                >
                                   {this.props.buttonText}
                                </Button>
                            </div>
                        }
                    </Alert>
            </Snackbar>
        )
    }

    autoCloseAlert = (reason=undefined) => {
        if(this._isMounted && reason !== 'clickaway'){
            this.setState({openAlert: false});
            var alertData = {
                shownAlertCounter : this.state.shownAlertCounter,
                clickedAwayCounter: this.state.clickedAwayCounter
            }
            //console.log('auto closed handler', reason, this.props)
            if(this.props.openOnClick !== true){
                DataCacheWrite('infoalert_'+this.state.alertName+this.state.userId, alertData, 30);
            }
            if(this.props.closeHandler !== undefined){
                this.props.closeHandler()
            }
        }
    }

    handleCloseAlert = () => {
        if(this._isMounted){
            this.setState({openAlert: false});
            var alertData = {
                clickedAwayCounter : this.state.clickedAwayCounter + 1,
                shownAlertCounter : this.state.shownAlertCounter
            }
            //console.log('closed handler')
            if(this.props.openOnClick !== true){
                DataCacheWrite('infoalert_'+this.state.alertName+this.state.userId, alertData, 30);
            }
            if(this.props.closeHandler !== undefined){
                this.props.closeHandler()
            }
        }
    }
    
}

export default compose(
    withRouter
)(SnackbarInfo);