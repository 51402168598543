import React, { Component } from 'react';
//import { Link } from "react-router-dom";
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import withStyles from '@material-ui/core/styles/withStyles';
import HomeCardContentItems from '../Content/HomeCardContentItems';
import {CheckXPLevel} from '../Charts/XPLevels';
import { QualityID } from '../Modules/LoadCategoriesAndQualities';

const styles = theme => ({
	desktopPadding: {
	 	minHeight: 40,
    },
});

class ContentHighlights extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = { 
            loading: true,
            xpLevels: {}
        };
    } 

    componentDidMount() {
        this._isMounted = true;
        this.getHighlights();
        const xpLevels = {
            content: CheckXPLevel(this.props.user, 'content'),
        }
        if(this._isMounted){
            this.setState({ loading: true, xpLevels });
        }
    }
    componentWillUnmount (){
        this._isMounted = false;
    }

    getHighlights () {
        const weekdays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
        //console.log('start searching')
        var qualityFilter = [];
        var title = "Daily Highlights";
        if(this.props.user !== undefined && this.props.user.currentLeadershipQuality !== undefined){
            const today = new Date();
            var selectionDay = weekdays[today.getDay()];
            //console.log('', this.props.user.currentLeadershipQuality , selectionDay)
            this.props.firebase.contents()
            .where('active', '==', true)
            .where('category', '==', 'tips')
            .where('leadership_quality', 'array-contains' , QualityID(this.props.user.currentLeadershipQuality) )
            .where('promotion.weekday', '==', selectionDay)
            //.orderBy('contentVotes', 'desc')
            .limit(2)
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    //console.log('doc', doc.id)
                    qualityFilter.unshift(doc.id);
                });
                var unbookMarkOption = false;
                if((qualityFilter === undefined || qualityFilter.length === 0) && this.props.user.contentBookmarked !== undefined && this.props.user.contentBookmarked.length > 0){
                    unbookMarkOption = true;
                    qualityFilter = this.props.user.contentBookmarked || [];
                    /// get all existing bookmarks and shuffle them
                    qualityFilter = qualityFilter.sort(() => Math.random() - 0.5);
                    /// take the last two
                    if(qualityFilter !== undefined && qualityFilter.length > 4){
                        qualityFilter = qualityFilter.slice(1).slice(-4)
                    }
                    title = "Bookmarks";
                }
                if(!(qualityFilter?.length > 1)){
                    qualityFilter = qualityFilter.concat((this.props.user.contentRecommendations || []));
                    /// get all existing bookmarks and shuffle them
                    qualityFilter = qualityFilter.sort(() => Math.random() - 0.5);
                    /// take the last two
                    if(qualityFilter !== undefined && qualityFilter.length > 4){
                        qualityFilter = qualityFilter.slice(1).slice(-4)
                    }
                    title = "Daily Tips";
                }
                if(!(qualityFilter?.length > 1)){
                    qualityFilter = qualityFilter.concat((this.props.user.contentLiked || []));
                    qualityFilter = qualityFilter.sort(() => Math.random() - 0.5);
                    /// take the last two
                    if(qualityFilter !== undefined && qualityFilter.length > 4){
                        qualityFilter = qualityFilter.slice(1).slice(-4)
                    }
                }
                if(this.props.user.contentLastNotification !== undefined && this.props.user.contentLastNotification !== ''){
                    ///// add the recent notification element into the highlights
                    if(!qualityFilter.includes(this.props.user.contentLastNotification)){
                        qualityFilter.push(this.props.user.contentLastNotification);
                        title = "Daily Tips";
                    }
                    //console.log('this.props.usercontentLastNotification', this.props.user.contentLastNotification)
                }
                if(this._isMounted){
                    this.setState({qualityFilter, title, unbookMarkOption});
                    if(this.props.selectionHandler !== undefined){
                        this.props.selectionHandler(qualityFilter);
                    }
                }
            });
        }
    }

    render() {
        //console.log(this.state)
        const {theme} = this.props;
        const {qualityFilter} = this.state;
        if(qualityFilter === undefined || qualityFilter === null || qualityFilter.length === 0 ){
            return (
                <div style={{marginLeft:20, marginRight: 20, paddingTop:0}}>
                    <HomeCardContentItems 
                        user={this.props.user}
                        key={'loading'}
                        defaultSet={this.props.defaultSet || undefined}
                        visualLimit={5}
                        direction={'column'}
                        titleOfGroup={this.state.title || "Highlights"}
                        allowUpdate={false}
                        raised={true}
                        swipe={true}
                        linkToContent={this.props.linkToContent !== undefined ? this.props.linkToContent : this.state.xpLevels?.content?.valid? true: false}
                        //qualityFilter = {[this.state.dialogue.contentSelection[0].contentId]}
                        //qualityFilter = {[this.state.dialogue.contentSelection[0].contentId, this.state.dialogue.contentSelection[1].contentId]}
                        leadershipCategories={this.props.leadershipCategories}
                        leadershipQualities={this.props.leadershipQualities}
                        allQualitiesLoaded = { [] }
                        //setParentAllQualitiesLoaded={ v => this.setState({allQualitiesLoaded :[...this.state.allQualitiesLoaded, v] }) }
                        tutorialOpen = {this.props.tutorialOpen || undefined}
                    />
                </div>
            )
        }
        else{
            return (
                <div style={{marginLeft:20, marginRight: 20, paddingTop:0}}>
                    <HomeCardContentItems
                        key={'content'}
                        user={this.props.user}
                        defaultSet={this.props.defaultSet || undefined}
                        visualLimit={5}
                        specialQuery={'contentByIds'}
                        direction={'column'}
                        unbookMarkOption={this.state.unbookMarkOption || false}
                        titleOfGroup={this.state.title || "Highlights and Bookmarks"}
                        allowUpdate={false}
                        raised={true}
                        qualityFilter = {qualityFilter}
                        swipe={true}
                        removeItemFromList={ (removeContentId) => {
                                var qualityFilter = this.state.qualityFilter || [];
                                console.log('qf', qualityFilter)
                                const index = qualityFilter.indexOf(removeContentId);
                                if (index > -1) { // only splice array when item is found
                                    qualityFilter.splice(index, 1); // 2nd parameter means remove one item only
                                }
                                console.log('removed', removeContentId, qualityFilter)
                                this.setState({qualityFilter});
                            }
                        }
                        linkToContent={this.props.linkToContent !== undefined ? this.props.linkToContent : this.state.xpLevels?.content?.valid? true: false}
                        //qualityFilter = {[this.state.dialogue.contentSelection[0].contentId]}
                        //qualityFilter = {[this.state.dialogue.contentSelection[0].contentId, this.state.dialogue.contentSelection[1].contentId]}
                        leadershipCategories={this.props.leadershipCategories}
                        leadershipQualities={this.props.leadershipQualities}
                        allQualitiesLoaded = { [] }
                        //setParentAllQualitiesLoaded={ v => this.setState({allQualitiesLoaded :[...this.state.allQualitiesLoaded, v] }) }
                        tutorialOpen = {this.props.tutorialOpen || undefined}
                    />
                    <p style={theme.textSupportCenter}> 
                        Selected articles &bull; Actionable tips
                    </p>
                </div>
            )
        }
    }

}

export default compose(
    withStyles(styles, { withTheme: true}),
    withFirebase,
)(ContentHighlights);
