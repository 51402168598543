import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

import {IsMobileDevice} from '../Modules/IsMobileDevice';

/// Tutorials
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DialogTutorialPage from '../Onboarding/DialogTutorialPage';

import AssessmentPage from '../Assessments/AssessmentPage';
//import AssessmentEggup from '../Assessments/AssessmentEggup';
import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
//import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Cancel';
import Slide from '@material-ui/core/Slide';
//import { Link } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        backgroundColor: 'transparent',
    },
});

class DialogAssessment extends Component {

    _isMounted = false;
    constructor(props) {
        super(props);
        var handleBadgeFamily = 'total';
        if(this.props.handleBadgeFamily !== undefined && this.props.handleBadgeFamily !== '' ){
            handleBadgeFamily = this.props.handleBadgeFamily ;
        }
        this.state = {
            loading: true,
            handleBadgeFamily,
            questionnaireDetails: this.props.questionnaireDetails || undefined
        };
    }

    componentDidMount() {
        //console.log('users props', this.props.user)
        this._isMounted = true;
    }
    componentWillUnmount(){
        this._isMounted = false;
    }
    componentDidUpdate(prevProps){
        if(this._isMounted && this.props.assessmentId !== undefined && this.props.handleOpen && this.props.assessmentId !== prevProps.assessmentId){
            if(this.props.questionnaireDetails?.title?.length > 1){
                this.setState({questionnaireDetails: this.props.questionnaireDetails});
                //console.log('load local data set')
            }
            else{
                this.props.firebase.questionnaire(this.props.assessmentId)
                .get()
                .then(doc => {
                    if (doc.exists && this._isMounted) {
                        this.setState({
                            questionnaireDetails: doc.data(),
                            loading: false
                        })
                        //console.log(doc.data(), 'qweefvd')
                    }
                })
            }
        }
    }
    
    render(){
        //console.log('dialoge assess this.props', this.props)
        const { theme } = this.props;
        return(
            <RemoveScroll enabled={this.props.handleOpen === undefined ? false : this.props.handleOpen}>
                <DialogTutorialPage 
                    defaultSet={this.props.defaultSet}
                    handleOpen={this.state.handleOpenTutorial || false}
                    handleBadgeFamily = {'assessments'}
                    handleClose={() => {this.setState({handleOpenTutorial: false})}}
                />
                <Dialog
                    open={this.props.handleOpen === undefined ? false : this.props.handleOpen}
                    onClose={this.props.handleClose}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                    >
                    <DialogTitle style={theme.dialogTitle}>
                        <Grid container spacing={0} direction="row"
                            justifyContent="space-between"
                            alignItems="center">
                            <Grid item >
                                <IconButton onClick={this.props.handleClose}>
                                    <CancelIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <span style={theme.dialogTitle.titleText}>
                                    Questionnaire & Assessment
                                </span>
                            </Grid>
                            <Grid item>
                                <IconButton style={{marginRight: 2}} onClick={() => {this.setState({handleOpenTutorial: true})}}>
                                    <HelpOutlineIcon />
                                </IconButton>
                                {/***
                                {this.state.buttonNextActive
                                    ? 
                                        <span style={theme.dialogTitle.titleLink} onClick={this.props.handleClose}>
                                             Next
                                        </span>
                                    :
                                        <span style={theme.dialogTitle.titleLink} onClick={this.props.handleClose}>
                                            Quit
                                        </span>
                                }
                                 */}
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    {this.props.handleOpen &&
                        <DialogContent 
                            style={theme.dialogContent }
                            ref={el => this.dialogRef = el}
                        >
                            <h5>{this.props.text}</h5>
                            {this.state.questionnaireDetails !== undefined && this.state.questionnaireDetails.category === 'EGGUP'  
                                ?
                                    <AssessmentPage 
                                        user={this.props.user}
                                        isCoach={this.props.isCoach || false}
                                        defaultSet={this.props.defaultSet || undefined}
                                        eggupAPI={true}
                                        assessmentId = {this.props.assessmentId}
                                        questionnaireDetails = {this.state.questionnaireDetails || undefined}
                                        handleClose={this.props.handleClose}
                                        handleScrollTop={() => { if(this.dialogRef!==undefined  && this.dialogRef!==null &&  this.dialogRef.scrollTop!==undefined ) this.dialogRef.scrollTop = 0}}
                                        receiveResults={(results) => this.receiveResults(results)}
                                        handleResults={this.props.handleResults !== undefined && this.state.questionnaireDetails?.personalityTraitsTarget?.pP !== undefined
                                            ?   () => {
                                                    this.props.handleResults({userAssessment: this.state.questionnaireDetails.personalityTraitsTarget});
                                                    this.props.handleClose();
                                                }
                                            :   undefined
                                        }
                                    />
                                :
                                    <AssessmentPage 
                                        defaultSet={this.props.defaultSet || undefined}
                                        user={this.props.user}
                                        isCoach={this.props.isCoach || false}
                                        assessmentId = {this.props.assessmentId}
                                        questionnaireDetails = {this.state.questionnaireDetails || undefined}
                                        handleClose={this.props.handleClose}
                                        handleScrollTop={() => { if(this.dialogRef!==undefined  && this.dialogRef!==null &&  this.dialogRef.scrollTop!==undefined ) this.dialogRef.scrollTop = 0}}
                                        receiveResults={(results) => this.receiveResults(results)}
                                        ignoreDaysToWait={this.props.ignoreDaysToWait || false}
                                        handleResults={this.props.handleResults !== undefined
                                            ?   this.props.handleResults
                                            :   undefined
                                        }
                                    />
                            }
                        </DialogContent>
                    }
                    {this.state.questionnaireDetails !== undefined && this.state.questionnaireDetails.url !== undefined && this.state.questionnaireDetails.url !== ''
                        &&
                        <DialogActions 
                            style={{
                                backgroundImage: `url(${this.state.questionnaireDetails.url})`,
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                            }}>
                                &nbsp;
                        </DialogActions>
                    }
                </Dialog>
            </RemoveScroll>
        )// end return
    }//end render

    receiveResults(results){
        if(this.props.receiveResults !== undefined){
            if(results !== undefined && results.assessmentId !== undefined && results.assessmentId.length > 1){
                //console.log('recieve results', results)
                this.setState({buttonNextActive: true});
            }
            this.props.receiveResults(results);
        }
    }
};//end class


export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(DialogAssessment);
