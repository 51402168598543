import { getAnalytics, logEvent } from "firebase/analytics";
export function AddAnalytics(type, action, info='default'){
    try {
        var eventName = ''
        var paramPackage = {}
        if(type === 'screen_view'){
            eventName = type;
            var screenName = action;
            var screenClass = info;
            if(info !== 'default'){
                paramPackage = {
                    firebase_screen: screenName, 
                    firebase_screen_class: screenClass
                }
            }
            else{
                paramPackage = {
                    firebase_screen: screenName
                }
            }
        }
        else if(type === 'sign_up'){
            eventName = type;
            var method = action;
            var userProfile = 'unknown';
            if(info.includes('Executive') || info.includes('executive') || info.includes('Leader')){
                userProfile = 'Executive'
            }
            else if(info.includes('Professional') || info.includes('professional')){
                userProfile = 'Professional'
            }
            else if(info.includes('Available') || info.includes('seeker')){
                userProfile = 'Available'
            }
            else if(info.includes('Student') || info.includes('student')){
                userProfile = 'Student'
            }
            else if(info.includes('Teenager') || info.includes('teenager')){
                userProfile = 'Teenager'
            } 
            if(userProfile === 'Executive' || userProfile === 'Professional' || userProfile === 'Available' ){
                eventName = 'sign_up_target';
            }
            paramPackage = {
                method: method,
                user_profile: userProfile
            }
            //console.log('signup', userProfile)
        }
        else if(type === 'goal_completion'){
            eventName = type;
            paramPackage = { 
                name: action
            };
        }
        else if(type === 'returning'){
            eventName = type;
            paramPackage = { 
                name: action
            };
        }
        if(eventName !== ''){
            if(process?.env !== undefined && process.env.REACT_APP_DEVICE_PLATFORM!==undefined && process.env.REACT_APP_DEVICE_PLATFORM==="BROWSER"){
                const analytics = getAnalytics();
                logEvent(analytics, eventName, paramPackage);
                if(eventName === 'sign_up_target'){
                    logEvent(analytics, 'sign_up', paramPackage);
                }
                //console.log('log sceen view', eventName, paramPackage)
            }
            else{
                window.cordova.plugins.firebase.analytics.logEvent(eventName, paramPackage);
                if(eventName === 'sign_up_target'){
                    window.cordova.plugins.firebase.analytics.logEvent('sign_up', paramPackage);
                }
                //console.log('app sceen view', eventName, paramPackage)
            }
        }
    }
    catch(err){
        //console.log('noplugin')
        return false;
    } 
};
