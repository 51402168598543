
//import backgroundImage from "../../images/login-background.jpg";
//import backgroundImage from "../../images/login-background.png";
//import backgroundImageApp from "../../images/login-background.gif";
//import backgroundImageSignupCoach from "../../images/signup_coach.png";
import backgroundImageSignup from "../../images/bluerunningtrack3.jpg";
import backgroundImageSignupCoach from "../../images/backgroundlogin_rocky3.jpg";
import * as ROUTES from "../../constants/routes";


const stylesSignin = theme => ({
    rootFrame: {
        minHeight:'100vh',
        //marginTop: 'calc(-1*env(safe-area-inset-top))'
    },
    root: {
        paddingTop: 'calc( 20px + env(safe-area-inset-top))',
        paddingBottom: 'calc( 20px + env(safe-area-inset-bottom))',
        backgroundImage: (theme?.palette?.themeAltBGImage?.includes("http") ? `url(${theme.palette?.themeAltBGImage})` : theme.palette.themeBlue === "#20A5E6" ? `url(${backgroundImageSignup})` : `linear-gradient(-20deg, ${theme.palette.themeBlue} 0%, ${theme.palette.themeLightGrey} 100%)`),
        //backgroundImage: `url(${backgroundImageSignup})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh"
    },
    rootAnimated: {
        paddingTop: 'calc( 20px + env(safe-area-inset-top))',
        paddingBottom: 'calc( 20px + env(safe-area-inset-bottom))',
        //backgroundImage: `url(${backgroundImageApp})`,
        //backgroundPosition: "center 30px",
        //backgroundSize: "cover",
        //backgroundRepeat: "no-repeat",
        minHeight: "100vh"
    },
    rootSignup: {
        paddingTop: 'calc( 20px + env(safe-area-inset-top))',
        paddingBottom: 'calc( 20px + env(safe-area-inset-bottom))',
        backgroundImage: (theme?.palette?.themeAltBGImage?.includes("http") ? `url(${theme.palette?.themeAltBGImage})` : theme.palette.themeBlue === "#20A5E6" ? `url(${backgroundImageSignup})` : `linear-gradient(-20deg, ${theme.palette.themeBlue} 0%, ${theme.palette.themeLightGrey} 100%)`),
        //backgroundImage: (theme.palette.themeBlue === "#20A5E6" ? `url(${backgroundImageSignup})` : `linear-gradient(-20deg, ${theme.palette.themeBlue} 0%, ${theme.palette.themeLightGrey} 100%)`),
        //backgroundImage: `url(${backgroundImageSignup})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
    },
    rootSignupApp: {
        paddingTop: 'calc( 20px + env(safe-area-inset-top))',
        paddingBottom: 'calc( 20px + env(safe-area-inset-bottom))',
        //backgroundImage: `url(${backgroundImageSignup})`,
        //backgroundPosition: "center",
        //backgroundSize: "cover",
        //backgroundRepeat: "no-repeat",
        minHeight: "100vh",
    },
    rootSignupCoach: {
        paddingTop: 'calc( 20px + env(safe-area-inset-top))',
        paddingBottom: 'calc( 20px + env(safe-area-inset-bottom))',
        backgroundImage: `url(${backgroundImageSignupCoach})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
    },
    socialLogins: {
        display: "flex",
        flexDirection: "row",
        //paddingBottom: 15,
        justifyContent: "space-around",
        alignItems: "center",
    },
    button: {
        marginLeft: 8,
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 30,
        paddingLeft: 30
    },
    buttonBottom: {
        margin: 15,
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 30,
        paddingLeft: 30
    },
    buttonApple: {
        boxShadow: 'none',
        backgroundColor: "transparent",
        //margin: 15,
        //radius: 12,
        height: 60,
        '&:hover': {
            backgroundColor: theme.palette.themeAltGold,
        },
    },
    buttonGoogle: {
        boxShadow: 'none',
        backgroundColor: "transparent",
        //margin: 15,
        //radius: 12,
        height: 60,
        '&:hover': {
            backgroundColor: theme.palette.themeAltGold,
        },
    },
    buttonFacebook: {
        boxShadow: 'none',
        backgroundColor: "transparent",
        //margin: 15,
        //radius: 12,
        height: 60,
        '&:hover': {
            backgroundColor: theme.palette.themeAltGold,
        },
    },
    buttonTwitter: {
        boxShadow: 'none',
        backgroundColor: "transparent",
        margin: 15,
        radius: 12,
        maxHeight: 50,
        '&:hover': {
            backgroundColor: theme.palette.themeAltGold,
        },
    },
    error: {
        color: theme.palette.themeRed,
        marginTop: 20,
    },
    footer: {
        marginTop: 20,
        textAlign: "center",
        marginBottom: 0,
    },
    form: {
        textAlign: "center",
        minWidth: 200
    },
    link: {
        padding: 10,
        color: "white"
    },
    linksContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        color: "white"
    },
    login: {
        backgroundColor: theme.palette.primary.main,
        opacity: "0.95",
        marginTop: 20,
        marginLeft: 10,
        marginRight: 10,
        padding: 20,
        borderRadius: "10px"
    },
    logo: {
        width: "20%",
        marginTop: 10,
        marginBottom: 10,
        borderRadius: theme.borderRadius
    },
    logoCaption: {
        margin: 10,
        width: "40%"
    },
    logoContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    socialLoginPrompt: {
        display: "block",
        textAlign: "center",
        paddingTop: 0,
        marginTop: 0,
        marginBottom: 0,
        color: theme.palette.secondary.light
    },
    socialLoginRemark: {
        textAlign: "center",
        fontSize: 10
    },
    socialLogo: {
        borderRadius: 41,
        maxHeight: 50, 
    },
    textField: {
        marginTop: 30,
        fontSize: '16px',
    },
    innerHTML : theme.innerHTML
});
export { stylesSignin };

export const pageText = {
    linkResetPW : "Or change password.",
    linkResetNEW : "Or create a new account.",
    linkTerms: "Terms",
    linkPrivacy: "Privacy",
    linkHelp: "Support",
    linkAbout: "About",
    linkBlog : "Blog",
    linkForgot : 'Forgot your password?',
    linkLogin : 'Log In',
    linkSignup : 'Sign Up',
    ERROR_CODE_ACCOUNT_EXISTS: "auth/account-exists-with-different-credential",
    ERROR_MSG_ACCOUNT_EXISTS : "Seems you already have a login on our platform. Please try logging in with your email address.",
    ERROR_CODE_USER_NOT_FOUND : "auth/user-not-found",
    ERROR_MSG_USER_NOT_FOUND: "Sorry, we couldn't find an account with that email address. Please enter a different email address.",
    ERROR_CODE_WRONG_PASSWORD: "auth/wrong-password",
    ERROR_MSG_WRONG_PASSWORD: "Sorry, the password isn't right. Please try again or use the link below to reset your password.",
    ERROR_MSG_SOCIAL_APPLE: "Previously, you signed in with Apple on this email, so please use the Apple sign-in again.",
    ERROR_MSG_SOCIAL_GOOGLE: "Previously, you signed in with Google on this email, so please use the Google sign-in again.",
    ERROR_MSG_SOCIAL_FACEBOOK: "Previously, you signed in with Facebook on this email, so please use the Facebook sign-in again.",
    ERROR_MSG_SOCIAL_MICROSOFT: "Previously, you signed in with Microsoft on this email, so please use the Microsoft sign-in again.",
    ERROR_CODE_INVALID_EMAIL:  "auth/invalid-email",
    ERROR_MSG_INVALID_EMAIL : "Please enter your email address in the format yourname@example.com",
    ERROR_CODE_INVALID_CREDENTIAL : "auth/invalid-credential",
    ERROR_MSG_INVALID_CREDENTIAL: "We are not able to process your social login at this moment, please sign in by your Email",
    ERROR_MSG_SOCIAL_CANCELLED: "Social login cancelled",
    ERROR_MSG_APPLE_VERSION: "Apple ID login requires an iOS version above 13. Please log in with Google, Facebook or Email instead :)",
    ERROR_MSG_SOCIAL_FAILED: 'Your sign up did not provide a valid email',
    ERROR_MSG_GETDEFAULTSETTING_FAILED: "We had a problem with the internet connection",
    ERROR_MSG_INVALID_CODE : "This code is not available or not valid anymore",
    ERROR_MSG_ADD_INFO  : "Please add your sign up information",
    form_email: 'Email address',
    form_password : "Password",
    form_username : "Nick name",
    form_code : "Add coach-code ",
    label_code : "The code, provided by an organization, partner, or coach",
    label_code_info : "(You can add a coach-code in your profile settings later as well)",
    label_social_signup : 'sign up with:',
}

export function linksInfo(defaultSet = {}) {
    /****
     * OVERWRITE WINDOW.OPEN FUNCTION to system behaviour so that it works on ios/android same function in STOREMOBILEDEVICEINFO
     */
    document.addEventListener("deviceready", () => {
        try{
            window.open = function(URL, type, specs){
                if(type==='_blank'){
                    type="_system";
                }
                window.cordova.InAppBrowser.open(URL, type, specs);
            }
        } catch (err){
            console.log('noplugin inappbrowser', err)
        }  
    }, false);
    //console.log('defaultSet', defaultSet)
    return(
        <div style={stylesSignin.linksContainer}>
            <span 
                onClick={ () => window.open(
                    ROUTES.LinkCompanyURL({props: {defaultSet}})
                    , "_blank")}>
                {pageText.linkAbout}
            </span>
            &nbsp;&middot;&nbsp;
            <span 
                onClick={ () => window.open(
                    ROUTES.LinkSupportURL({props: {defaultSet}})
                    , "_blank")}>
                {pageText.linkHelp}
            </span>
            &nbsp;&middot;&nbsp;
            <span 
                onClick={ () => window.open(
                    ROUTES.LinkPrivacyURL({props: {defaultSet}})
                    , "_blank")}>
                {pageText.linkPrivacy}
            </span>
            &nbsp;&middot;&nbsp;
            <span 
                onClick={ () => window.open(
                    ROUTES.LinkTermsURL({props: {defaultSet}})
                    , "_blank")}>
                {pageText.linkTerms}
            </span>
        </div>
    )
}

export function PushForwardAfterSigning(that, userDataSet=undefined){
    //console.log('pushforward', that, userDataSet)
    //console.log('check', userDataSet !== undefined,  !(userDataSet?.onboardingDone === true), !(userDataSet?.roles?.includes('REMOTECOACH') || userDataSet?.roles?.includes('COACHADMIN')))
    var iframeLogin = false;
    if(process?.env?.REACT_APP_DEVICE_PLATFORM==="BROWSER"){
        try{
            if(window.parent !== window.self){
                //console.log('iframe 1x', window.parent, window.self)
                iframeLogin = true;
                window.parent.postMessage({ loggedIn: true });
                //console.log('iframe 2x', window.parent, window.self)
                window.parent.postMessage('loggedIn: true');
                //console.log('iframe 3x', window.parent, window.self)
                //window.top.location.href = '#/home'
            }
        } catch (e){
            console.log('e', e)
        }
    }
    if(that?.props.loginType === "alexaLinking"){
        //that.props.history.push(ROUTES.ALEXA_SIGN_IN);
        historyPush(that, ROUTES.ALEXA_SIGN_IN, iframeLogin);
    }
    else if(userDataSet?.onboardingRequired === false || userDataSet?.roles?.includes('REMOTECOACH') || userDataSet?.roles?.includes('COACHADMIN')){
        //console.log('user ac')
        historyReplace(that, ROUTES.PROFILE, iframeLogin);
        //that.props.history.push(ROUTES.HOME);
        historyPush(that, ROUTES.HOME, iframeLogin);
    }
    else if(userDataSet !== undefined && !(userDataSet?.onboardingDone === true) && !(userDataSet?.roles?.includes('REMOTECOACH') || userDataSet?.roles?.includes('COACHADMIN'))){
        //console.log('user account to onboarding', that.props, userDataSet)
        historyReplace(that, ROUTES.PROFILE, iframeLogin);
        const showdetails = (window?.sessionStorage?.getItem('showdetails') || true);
        //console.log('showdetails', showdetails)
        if((showdetails !== "false" && showdetails !== false) && userDataSet?.features?.onboardFutureSelf === true && userDataSet?.programs?.includes('default') && userDataSet?.programs?.length === 1){
            historyPush(that, ROUTES.ONBOARDING, iframeLogin);
        }
        else{
            historyPush(that, ROUTES.ONBOARDQUICK, iframeLogin);
        }
    }
    else{
        //console.log('user home')
        //that.props.history.replace(ROUTES.STATS);
        historyReplace(that, ROUTES.PROFILE, iframeLogin);
        //that.props.history.push(ROUTES.HOME);
        historyPush(that, ROUTES.HOME, iframeLogin);
    }
}
function historyReplace(that, page, iframe=false){
    if(iframe){
        try{
            window.top.location.href = '#'+page; 
        } catch (e){
            that.props.history.replace(page);
        }
    }
    else{
        that.props.history.replace(page);
    }
}
function historyPush(that, page, iframe=false){
    if(iframe){
        try{
            window.top.location.href = '#'+page; 
        } catch (e){
            that.props.history.push(page);
        }
    }
    else{
        that.props.history.push(page);
    }
}