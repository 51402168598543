import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AddAnalytics } from '../Modules/AddAnalytics';

import withStyles from "@material-ui/core/styles/withStyles";
// style used same as from singin module
import {stylesSignin} from '../SignIn';
import {linksInfo} from '../SignIn';
//import {pageText} from '../SignIn';
import {SignUpFormSimple} from '../SignUp';

import PropTypes from "prop-types";
import * as ROUTES from "../../constants/routes";
import logo from "../../images/rockyai-logo-image-only.png";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { MyInstallationOfflineCoachDataSet } from "../Modules/DefaultCode";


class SignUpTeamPage extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            ...INITIAL_STATE
        };
        //this.state = { };
    }
    
    componentDidMount() {
        AddAnalytics('screen_view', 'PageSignUpTeams', 'SignUpTeams');
    }

    render () {
        const { classes, theme, defaultSet } = this.props;
        return (
            <div className={classes.rootFrame}>
                <div className={classes.rootSignupCoach}>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} style={{ maxWidth: 500 }}>
                            <div className={classes.login}>
                            <div className={classes.logoContainer}>
                                <img src={logo} className={classes.logo} alt="AI Coaching Logo Chatbot Journaling" />
                                
                                <h4>Welcome to ROCKY for TEAMS</h4>
                                <p style={theme.divCenter}>
                                Sign up here if you are a <strong>Coach</strong> or if you have received a <strong>Coach-Code</strong> for your organization.
                                <br /> <br /> 
                                Or click <Link to={ROUTES.SIGN_UP} onClick={() => {window.open('https://app.rocky.ai', '_self'); window.name = 'Rocky.ai APP';}}>here</Link> if you want to use Rocky.ai only for yourself!
                                </p>
                            </div>
                            <SignUpFormSimple 
                                coach={true}
                                //defaultSignUpCode={"#ROCKYFORTEAMS"}
                                myInstallationOfflineDataSet = {MyInstallationOfflineCoachDataSet}
                            />
                            <div>
                                <hr />
                                <p className={classes.socialLoginPrompt}>Already signed up?</p>
                                <div className={classes.socialLogins}>
                                    <Link to={ROUTES.SIGN_IN}>
                                        <Button  className={classes.buttonBottom}>
                                        Log In
                                        </Button>
                                    </Link>
                                    <br/>
                                    <Button 
                                        onClick={ () => window.open("https://www.rocky.ai/customersupport", "_blank")}
                                    >
                                        Request a Coach Account
                                    </Button>
                                </div>
                            </div>
                            </div>
                            <div className={classes.footer}>
                                {linksInfo(defaultSet)}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        ); //end return
    }; //end redner
}; //end class

const INITIAL_STATE = {
    username: "",
    email: "",
    passwordOne: "",
    code: "",
    error: null,
    openCode: false,
};


SignUpTeamPage.propTypes = {
    classes: PropTypes.object.isRequired
};


export default withStyles(stylesSignin, { withTheme: true })(SignUpTeamPage);