
export function DeleteUser(db, uid) {
  if(uid!== undefined && uid!== '' && uid.length>4){
    db.messages()
      .where('userId', '==', uid)
      .onSnapshot(snapshot => {
        snapshot.forEach(doc => {
          doc.ref.delete();
        });
      });
    db.dialogues()
      .where('userId', '==', uid) 
      .onSnapshot(snapshot => {
        snapshot.forEach(doc => {
          doc.ref.delete();
        });
      });
    db.user(uid).collection('badges')
      .onSnapshot(snapshot => {
      snapshot.forEach(doc => {
        doc.ref.delete();
      });
    });
    db.user(uid).collection('notificationsScheduled')
      .onSnapshot(snapshot => {
      snapshot.forEach(doc => {
        doc.ref.delete();
      });
    });
    db.user(uid).collection('notificationsSent')
      .onSnapshot(snapshot => {
      snapshot.forEach(doc => {
        doc.ref.delete();
      });
    });
    db.user(uid).delete();  
  }
}