import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import BadgeCard from '../Modules/BadgeCard';

import SnackbarInfo from '../Modules/SnackbarInfo';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

//import Grid from '@material-ui/core/Grid';
import logo from "../../images/rocky-logo-square.png";

//import IconButton from '@material-ui/core/IconButton';
//import EditIcon from '@material-ui/icons/Edit';
import LinkIcon from '@material-ui/icons/Link';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});


class SettingAccountLinking extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            openSetting: false,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        var buyer = 'enduser';
        var production = false;
        var products = {};
        var groupIdTitles = [];
        this.props.firebase.purchaseArticles()
        .where('active', '==', true)
        .where('store', '==', 'accountLinking')
        .where('production', '==', production)
        .where('category', '==', buyer)
        .orderBy('price', 'asc')
        .get()
        .then(articles => {
            if(this._isMounted){
                articles.forEach(doc => {
                    var article = doc.data();
                    var groupId = doc.id;
                    if (article.groupId !== undefined && article.groupId !== ''){
                        groupId = article.groupId;
                    }
                    if (groupIdTitles.includes(groupId)){
                        products[groupId].push(article);
                    }
                    else{
                        groupIdTitles.push(groupId)
                        products[groupId] = [];
                        products[groupId].push(article);
                    }
                });
                if( groupIdTitles.length>0 && this._isMounted ){
                    //console.log(products, groupIdTitles)
                    this.setState({
                        products,
                        groupIdTitles
                    });
                }
                //console.log(products, groupIdTitles)
            }
        })
        
        this.setState({
            loaded:true
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    render() {
        const { user } = this.props;
        const { theme, classes } = this.props;
        return (
        user
            ?
            <div className={classes.grow}>
                <Card style={theme.cardSettings}>
                    {this.state.groupIdTitles!== undefined  && this.state.groupIdTitles.map( (group) => {
                        if (group==="alexaSkills"){
                            return(
                                <div key="alexaSkill">
                                    Connect to Amazon Alexa
                                    {this.state.products !== undefined && this.state.products[group] !== undefined && this.state.products[group].map( (item, index) => {
                                        var referenceLinkStatus = null;
                                        if (this.context.linkedAccounts!==undefined && this.context.linkedAccounts.alexa!==undefined){
                                            var linkedAccounts = []
                                            linkedAccounts = this.context.linkedAccounts.alexa;
                                            referenceLinkStatus = linkedAccounts.find(o => o.skillId === item.id)
                                        }
                                        return(
                                            <div
                                                key= {group+"_"+index}
                                                style={{
                                                    //marginLeft: -12, 
                                                    marginRight: -10
                                                }}
                                                >
                                                <BadgeCard
                                                    backgroundColor ={"transparent"}
                                                    image={
                                                        item.imageUrl!== undefined && item.imageUrl!==""
                                                            ? item.imageUrl
                                                            : logo
                                                    } 
                                                    avatarImage = {undefined}
                                                    title = { undefined }
                                                    subtitle = {item.description}
                                                    strongSubtitle = {false}
                                                    onClick={ undefined }
                                                    clickText= {undefined}
                                                    buttonClick = {
                                                        referenceLinkStatus!==undefined && referenceLinkStatus!==null && referenceLinkStatus.status ==="LINKED"
                                                            ?
                                                                this.state[group+"_"+index] 
                                                                    ?
                                                                        () => this.handleAlexaOpen(item)
                                                                    :
                                                                        () => this.setState({
                                                                            alertText : "Alexa skill can be unlinked with your Amazon Alexa app!",
                                                                            openAlert : true,
                                                                            [group+"_"+index]:true
                                                                        })
                                                            :
                                                                () => this.handleAlexaOpen(item)
                                                    }
                                                    button = {
                                                        referenceLinkStatus!==undefined && referenceLinkStatus!==null && referenceLinkStatus.status ==="LINKED"
                                                            ?
                                                                this.state[group+"_"+index] 
                                                                    ?   'Reconnect'
                                                                    :
                                                                        <span style={theme.textSupport} >
                                                                            linked&nbsp;<LinkIcon />
                                                                        </span>
                                                                
                                                            :
                                                                <span style={{inlineSize:'max-content', fontSize:12}} >
                                                                    {item.button}
                                                                </span>
                                                    }
                                                />
                                                {/****
                                            <Grid container 
                                                spacing ={2}
                                                key={group+"_"+index}
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                >
                                                <Grid item xs={2}>
                                                    <img src={
                                                        item.imageUrl!== undefined && item.imageUrl!==""
                                                            ? item.imageUrl
                                                            : logo
                                                        } 
                                                        style={{maxWidth:50, 
                                                            maxHeight: 50, 
                                                            height: 'auto', 
                                                            verticalAlign: 'middle',
                                                            borderRadius: '0 '+theme.borderRadius+'px '+theme.borderRadius+'px 0',
                                                        }} 
                                                        alt={item.description} />
                                                </Grid>

                                                <Grid item xs={7}>
                                                    <span><strong>
                                                        {
                                                            item.description
                                                        } 
                                                    </strong></span>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    { referenceLinkStatus!==undefined && referenceLinkStatus!==null && referenceLinkStatus.status ==="LINKED"
                                                        ?
                                                            <div style={{inlineSize:'max-content'}}>
                                                                {this.state[group+"_"+index] 
                                                                    ?
                                                                        <Button onClick={() => this.handleAlexaOpen(item)}>
                                                                            Reconnect
                                                                        </Button>
                                                                    :
                                                                        <span style={theme.textSupport}
                                                                            onClick={() => this.setState({
                                                                                alertText : "Alexa skill can be unlinked with your Amazon Alexa app!",
                                                                                openAlert : true,
                                                                                [group+"_"+index]:true
                                                                            })}>
                                                                            linked&nbsp;<LinkIcon />
                                                                        </span>
                                                                }
                                                            </div>
                                                        :
                                                            <Button onClick={() => this.handleAlexaOpen(item)}>
                                                                <span style={{inlineSize:'max-content'}}>
                                                                    {item.button}
                                                                </span>
                                                            </Button>
                                                    }
                                                </Grid>
                                            </Grid>
                                             */}

                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        }
                        else {
                            return ""
                        }
                    })}
                </Card>
                <SnackbarInfo 
                    alertText = {this.state.alertText || "You can connect your AI Coach with different other services."}
                    openOnClick = {true}
                    alertOpenEvent = {this.state.openAlert || false}
                    closeHandler = {() => {this.setState({openAlert: false})}}
                />
                {this.dialogueAlexaSetup()}
            </div>
        :   <div> </div>
                
        );
    }

    // Username Handling
    handleAlexaOpen = (item={}) => {
        //console.log("imte", item)
        if(item.groupId === 'alexaSkills'){
            var requestConnection={ "category" : "alexa"}
            //result = /?code=ANbFWxvinZYBIuIYpzLr&scope=alexa%3A%3Askills%3Aaccount_linking&state=HISlvAC7yOO28nMPgjaNLW46tD82#/
            var alexaClientId = "amzn1.application-oa2-client.3f6417826f49402f84e2ddabac87cc98"
            if(item.storeId!== undefined && item.storeId!== ""){
                alexaClientId = item.storeId
            }
            if(item.storePwd!== undefined && item.storePwd !== ""){
                requestConnection["storePwd"] = item.storePwd;
            }
            //alexaClientId = "amzn1.application-oa2-client.16e21ed0422a4c249818798e26e91ef9"
            requestConnection["storeId"] = alexaClientId;
            var openAlexaAccountLink = "https://www.amazon.com/ap/oa?client_id="+alexaClientId 
            openAlexaAccountLink = openAlexaAccountLink + "&scope=alexa::skills:account_linking"
            openAlexaAccountLink = openAlexaAccountLink + "&response_type=code"
            //openAlexaAccountLink = openAlexaAccountLink + "&scope=alexa%3A%3Askills%3Aaccount_linking&response_type=code"
            //openAlexaAccountLink = openAlexaAccountLink + "&redirect_uri=https://app.rocky.ai/#/profile"
            //openAlexaAccountLink = openAlexaAccountLink + "&redirect_uri=https%3A%2F%2Fapp.rocky.ai%2F%23%2Fprofile"
            openAlexaAccountLink = openAlexaAccountLink + "&redirect_uri=https%3A%2F%2Fapp.rocky.ai"
            openAlexaAccountLink = openAlexaAccountLink + "&state=" + this.props.user.uid
            requestConnection["userId"] = this.props.user.uid;
            if(item.id!== undefined && item.id!== undefined){
                openAlexaAccountLink = openAlexaAccountLink + "&skill_id=" + item.id
                requestConnection["skillId"] = item.id;
            }
            if(item.production){
                openAlexaAccountLink = openAlexaAccountLink + "&stage=live"
                requestConnection["stage"] = "live";
            }
            else{
                openAlexaAccountLink = openAlexaAccountLink + "&stage=development"
                requestConnection["stage"] = "development";
            }
            requestConnection["status"] = "REQUEST";
            requestConnection["createdAt"] = this.props.firebase.fieldValue.serverTimestamp();
            this.props.firebase.user(this.props.user.uid).collection("accounts").doc("alexa").set(requestConnection, {merge:true })
            //this.props.firebase.user(this.props.user.uid).collection("accounts").add(requestConnection)

            //console.log("url", openAlexaAccountLink, requestConnection)
            this.setState({
                openAlexaSetup: false,
                openAlexaAccountLink
            });
            if(process?.env !== undefined && process.env.REACT_APP_DEVICE_PLATFORM!==undefined && process.env.REACT_APP_DEVICE_PLATFORM==="BROWSER"){
                //window.open(openAlexaAccountLink, "_self")
                window.open(openAlexaAccountLink, "_blank")
            }
            else{
                window.open(openAlexaAccountLink, "_blank")
            }
        }
    } 
    handleAlexaClose = () => {
        this.setState({
            openAlexaSetup: false,
        });
    }

    dialogueAlexaSetup() {
        return(
            <Dialog
                open={this.state.openAlexaSetup || false}
                onClose={this.handleAlexaClose}
                fullWidth
                maxWidth="sm">
                <DialogTitle>First name</DialogTitle>
                {this.state.openAlexaSetup &&
                    <DialogContent>
                        <iframe 
                            title="Rocky.ai Shop"
                            //Warning: React does not recognize the `allowPaymentRequest` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `allowpaymentrequest` instead. If you accidentally passed it from a parent component, remove it from the DOM element
                            //Warning: Received `true` for a non-boolean attribute `allowpaymentrequest`. If you want to write it to the DOM, pass a string instead: allowpaymentrequest="true"
                            //allowPaymentRequest={true}
                            
                            style={{
                                width:'100%',
                                height:'100%',  
                                border: 'none'
                            }}
                            src={this.state.openAlexaAccountLink}
                            >
                                Loading Alexa Sign-up
                        </iframe>
                    </DialogContent>
                }
                <DialogActions>
                    <Button onClick={this.handleSettingClose}>
                        Cancel
                    </Button>
                    <Button onClick={this.handleSettingSubmit}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

SettingAccountLinking.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(SettingAccountLinking);
