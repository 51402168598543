import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function RockyIcon(props) {
    return (
        <SvgIcon {...props}> 
            <path d="M 5.0625 2.742188 C 4.128906 2.863281 3.601562 3.074219 3.136719 3.507812 C 2.816406 3.796875 2.632812 4.101562 2.46875 4.59375 C 2.160156 5.53125 2.15625 5.679688 2.15625 11.867188 C 2.15625 16.386719 2.171875 17.464844 2.226562 17.929688 C 2.460938 19.890625 3.160156 20.664062 4.917969 20.929688 C 5.621094 21.03125 18.46875 21.039062 19.167969 20.929688 C 20.574219 20.71875 21.242188 20.226562 21.609375 19.132812 C 21.921875 18.195312 21.914062 18.371094 21.914062 11.859375 C 21.914062 6.484375 21.910156 5.933594 21.835938 5.507812 C 21.648438 4.449219 21.46875 4.027344 21 3.5625 C 20.507812 3.066406 19.921875 2.835938 18.867188 2.71875 C 18.207031 2.648438 5.628906 2.667969 5.0625 2.742188 Z M 19.640625 11.648438 L 19.640625 18.609375 L 14.273438 18.609375 L 8.179688 12.515625 L 9.585938 12.515625 C 11.078125 12.515625 11.25 12.496094 11.703125 12.277344 C 11.996094 12.140625 12.46875 11.667969 12.605469 11.375 C 12.839844 10.894531 12.84375 10.820312 12.84375 8.320312 C 12.84375 5.835938 12.835938 5.742188 12.613281 5.28125 C 12.558594 5.171875 12.441406 4.992188 12.347656 4.882812 L 12.179688 4.6875 L 19.640625 4.6875 Z M 19.640625 11.648438 "/>
            <path d="M 15.511719 6.609375 C 14.9375 6.910156 14.546875 7.832031 14.5 8.980469 C 14.453125 10.117188 14.691406 11.046875 15.171875 11.582031 C 15.894531 12.378906 16.796875 11.84375 17.183594 10.382812 C 17.3125 9.914062 17.3125 8.558594 17.183594 8.085938 C 16.867188 6.882812 16.167969 6.261719 15.511719 6.609375 Z M 15.511719 6.609375 "/>
        </SvgIcon>
    );
}

// <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />

//<path d="M 2.738281 0.964844 C 1.738281 1.109375 0.824219 1.757812 0.367188 2.644531 C 0.226562 2.914062 0.15625 3.109375 0.0742188 3.421875 L 0.0117188 3.667969 L 0.0117188 20.332031 L 0.0742188 20.578125 C 0.15625 20.890625 0.226562 21.085938 0.367188 21.355469 C 0.820312 22.234375 1.703125 22.867188 2.707031 23.027344 C 3.027344 23.078125 20.972656 23.078125 21.292969 23.027344 C 21.625 22.976562 21.898438 22.886719 22.207031 22.738281 C 23.148438 22.277344 23.796875 21.398438 23.964844 20.355469 C 24.015625 20.035156 24.015625 3.964844 23.964844 3.644531 C 23.914062 3.3125 23.824219 3.039062 23.675781 2.730469 C 23.25 1.859375 22.519531 1.277344 21.53125 1.011719 L 21.292969 0.949219 L 12.082031 0.945312 C 7.015625 0.945312 2.8125 0.953125 2.738281 0.964844 Z M 20.320312 3.589844 C 20.710938 3.675781 21.074219 4.039062 21.160156 4.429688 C 21.195312 4.578125 21.195312 19.421875 21.160156 19.570312 C 21.074219 19.960938 20.710938 20.324219 20.320312 20.410156 C 20.1875 20.441406 14.09375 20.445312 14.082031 20.417969 C 14.078125 20.40625 12.566406 18.816406 10.722656 16.886719 C 8.878906 14.957031 7.367188 13.367188 7.363281 13.359375 C 7.359375 13.347656 8.039062 13.332031 8.875 13.328125 C 10.5625 13.320312 10.539062 13.320312 11.027344 13.148438 C 11.988281 12.804688 12.730469 12.011719 13.003906 11.039062 C 13.121094 10.617188 13.125 10.566406 13.125 7.933594 C 13.125 5.300781 13.121094 5.25 13.003906 4.828125 C 12.898438 4.449219 12.683594 4.027344 12.433594 3.71875 L 12.304688 3.5625 L 16.261719 3.5625 C 18.90625 3.5625 20.25 3.570312 20.320312 3.589844 Z M 20.320312 3.589844 "/>
//<path d="M 16.949219 5.425781 C 16.792969 5.46875 16.730469 5.503906 16.550781 5.636719 C 16.132812 5.949219 15.753906 6.6875 15.585938 7.511719 C 15.464844 8.089844 15.433594 8.960938 15.503906 9.566406 C 15.675781 11.042969 16.320312 12.109375 17.09375 12.203125 C 17.5625 12.257812 18.089844 11.835938 18.425781 11.140625 C 19.09375 9.734375 19.035156 7.46875 18.289062 6.210938 C 18.089844 5.867188 17.761719 5.558594 17.488281 5.453125 C 17.347656 5.398438 17.101562 5.382812 16.949219 5.425781 Z M 16.949219 5.425781 "/>
