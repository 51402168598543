import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';

import IconButton from '@material-ui/core/IconButton';

import { IsMobileDevice } from '../Modules/IsMobileDevice';
import MobileStepper from '@material-ui/core/MobileStepper';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';


const styles = theme => ({
    grow: {
      flexGrow: 1,
    },
});

export function customWidthStyle(widthMobile='300px', widthBrowser='300px', items = 1){
    let factor = 1;
    if(items>1){
        factor = 1/(items-1);
        factor = factor.toFixed(2);
    }
    var width = widthBrowser;
    if(IsMobileDevice()){
        width = '100%';
        if(items>1){
            width = 'calc(' + widthMobile + ' - (' + factor + ' * (100% - ' + widthMobile + ' + 10px)))';
        }
    }
    else if(items>1){
        width = 'calc(' + widthBrowser + ' - (' + factor + ' * (100% - ' + widthBrowser + ' + 10px)))';
    }
    return {
        width: width,
        maxWidth: widthBrowser,
        paddingLeft: 0,
        overflow: 'visible',
    }
}
export function customWidthSlideStyle(widthMobile='300px', widthBrowser='300px', items = 1){
    //console.log('IsMobileDevice()', IsMobileDevice())
    var width = widthBrowser;
    var paddingRight = 0;
    if(items>1){
        paddingRight = 10;
    }
    if(IsMobileDevice()){
        width = '100%';
        if(items>1){
            width = widthMobile;
        }
    }
    return {
        width: width,
        maxWidth: widthBrowser,
        paddingRight: paddingRight,
        paddingBottom: 10,
        flex: 'none',
    }
}

class SwipeStepper extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            activeStep : 0
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    moveForward = () => {
        if(this._isMounted ){
            var activeStep = this.props.activeStep;
            if(this.props.steps!==undefined && this.props.steps>0){
                if(activeStep >= this.props.steps-1) activeStep = 0;
                else if(activeStep===undefined || activeStep===null) activeStep = 1;
                else activeStep +=1;
            }
            else{
                activeStep = 0;
            }
            //this.setState({activeStep})
            if(this.props.handlerSteps!==undefined) this.props.handlerSteps(activeStep)
        }
    }
    moveBack = () => {
        if(this._isMounted ){
            var activeStep = this.props.activeStep;
            if(this.props.steps!==undefined && this.props.steps>0){
                if(activeStep < 1) activeStep = this.props.steps - 1;
                else if(activeStep===undefined || activeStep===null) activeStep = this.props.steps - 1;
                else activeStep -=1;
            }
            else{
                activeStep = this.props.steps - 1;
            }
            //this.setState({activeStep})
            if(this.props.handlerSteps!==undefined) this.props.handlerSteps(activeStep)
        }
    }

    render() {
        if(this.props.steps!==undefined && this.props.steps>2){
            return(
                <MobileStepper
                    style={{
                        backgroundColor: "transparent",
                    }}
                    onClick= {IsMobileDevice()? this.moveForward :  this.props.showBackButton ? () => {} : this.moveForward}
                    variant={this.props.steps > 20 ? 'text': "dots"}
                    steps={this.props.steps|| 0}
                    position="static"
                    //classes={ {positionStatic: {margin:"auto", width:"100%"}, dotActive:{backgroundColor:"green"}} }
                    //style={  {'& positionStatic': {margin:"auto", width:"100%"}}} 
                    activeStep={this.props.activeStep > this.props.steps - 1 ? 0 : this.props.activeStep}
                    nextButton={<IconButton onClick={this.props.showBackButton ? this.moveForward : () => {}} size="small" variant="text" > {IsMobileDevice()? "" : <ArrowForwardIosIcon  fontSize="small"  /> } </IconButton> }
                    backButton={<IconButton onClick={this.props.showBackButton ? this.moveBack : () => {}} size="small" variant="text" > {IsMobileDevice()? "" : this.props.showBackButton ? <ArrowBackIosIcon fontSize="small"  /> : "" } </IconButton>}
                    />
            )
        }
        else return ""
    }


    
}

export default compose(
    withStyles(styles, { withTheme: true }),
)(SwipeStepper);