import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../../Firebase';
import LoadingProgress from '../../Modules/LoadingProgress';

import Accordion from '@material-ui/core/Accordion';
//import AccordionDetails from '@material-ui/core/AccordionActions';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import DialogIntents from '../Modules/DialogIntents';


const styles = {
    expansionPanel: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    expansionPanelSummary: {
        paddingLeft: 5,
        paddingRight: 15,
    },
    expansionPanelSummaryCoach: {
        backgroundColor: '#f2f2f2',
        paddingLeft: 5,
        paddingRight: 15,
    },
    label: {
        fontSize: '0.8em',
        fontWeight: 700,
        paddingTop: 3,
        paddingRight: 10,
        minWidth: 60,
    },
    right : {
        position: 'absolute',
        right: 5
    },
    left : {
        position: 'absolute',
        left: 5
    },
    messages: {
        padding: 0,
        maxHeight: 320,
        overflow: 'scroll',
    },
    iconVote: {
        color: '#20a5e6',
    },
    iconNoVote: {
        color: '#f2f2f2',
    },
};

class DialogMessages extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            selectedType: "all",
            selectedIntent: "",
            loading: true,
            messages: [],
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ loading: true });
        //this.unsubscribe = 
        this.props.firebase
        .messages()
        .orderBy('createdAt', 'asc')
        .where('dialogueId', '==', this.props.dialogue.uid)
        .get().then(snapshot => {
        //.onSnapshot(snapshot => {
            if (snapshot.size) {
                if(this._isMounted){
                    let messages = [];
                    snapshot.forEach(doc => messages.push({ ...doc.data(), uid: doc.id }),);
                    this.setState({
                        messages: messages,
                        loading: false,
                    });
                }//end if mounted
            } 
            else if(this._isMounted) {
                this.setState({ messages: null, loading: false });
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
        if(this.unsubscribe!==undefined) this.unsubscribe();
    }


    render() {
        const { loading, messages } = this.state;
        console.log('dialog Props', this.props)
        return (
            <div>
                {loading && 
                    <LoadingProgress 
                        defaultSet={this.props.defaultSet}
                        type={'LoadingPageAnimated'} 
                        loadingText={"many, many, data"} 
                        firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                        allowRefresh={true}
                    />
                }

                {messages && (
                    messages.map(message => {
                        var messagesList = [].concat(message.text);
                        //console.log(messagesList)
                        return(
                            <Accordion key={message.uid} style={styles.expansionPanel}   TransitionProps={{ mountOnEnter: true }}>
                                <div 
                                    style={message.isCoach
                                            ?   styles.expansionPanelSummaryCoach
                                            :   styles.expansionPanelSummary
                                        }
                                    >
                                    {message.isCoach
                                        ?   <>
                                                <span style={styles.left}>
                                                    <ReceiptIcon  
                                                        onClick={() => {this.setState({
                                                            selectedText: message.text, 
                                                            openDialogueUtterance: true, 
                                                            openDialogueIntents: false,
                                                            nluIntent: message.intentCoach,
                                                        })}}
                                                    />
                                                    <br/>
                                                    <CastForEducationIcon  
                                                        onClick={() => {
                                                            if( message.intentCoach!== undefined && message.intentCoach.sentiment!== undefined && message.intentCoach.intent!== undefined ){
                                                                this.setState({
                                                                    selectedText: message.text, 
                                                                    openDialogueUtterance: false, 
                                                                    openDialogueIntents: true,
                                                                    nluIntent: message.intentCoach,
                                                                    selectedSentiment: message.intentCoach.sentiment,
                                                                    selectedIntent: message.intentCoach.intent,
                                                                })
                                                            }
                                                            else{
                                                                this.setState({
                                                                    selectedText: message.text, 
                                                                    openDialogueUtterance: false, 
                                                                    openDialogueIntents: true,
                                                                    nluIntent: message.intentCoach,
                                                                })
                                                            }
                                                        }}
                                                    />
                                                    <br/>
                                                    {message.buttons?.length>0
                                                        && 
                                                        <RadioButtonCheckedIcon style={{color: 'orange'}}/>
                                                    }
                                                </span>
                                            </>
                                        :   <>
                                                <span style={styles.left}>
                                                <CastForEducationIcon  
                                                    onClick={() => {
                                                        if( message.intentUser!== undefined && message.intentUser.sentiment!== undefined && message.intentUser.intent!== undefined ){
                                                            this.setState({
                                                                selectedText: message.text, 
                                                                openDialogueUtterance: false, 
                                                                openDialogueIntents: true,
                                                                nluIntent: message.intentUser,
                                                                selectedSentiment: message.intentUser.sentiment,
                                                                selectedIntent: message.intentUser.intent,
                                                            })
                                                        }
                                                        else{
                                                            this.setState({
                                                                selectedText: message.text, 
                                                                openDialogueUtterance: false, 
                                                                openDialogueIntents: true,
                                                                nluIntent: message.intentUser,
                                                            })
                                                        }
                                                    }}
                                                />
                                            </span>
                                            </>
                                    }
                                    <span style={{paddingLeft: 30, display: 'inline-block'}}>
                                        <span style={styles.label}>{message.isCoach? 'AI Coach:' : 'User:'} </span>
                                        <br/>
                                        {
                                            messagesList?.join("\r\n")
                                            //messagesList
                                        }
                                    </span>
                                    <span style={{position: 'absolute', top: 4, right: 20, fontSize: 'x-small', textAlign:'right', color:'grey'}}>
                                        {message?.messageFilter === 'bot' ? "Bot" : "App"}
                                    </span>
                                    <br/>
                                    <span style={{paddingLeft: 30, width: '100%', display: 'inline-block', fontSize: 'x-small', textAlign:'right', color:'grey'}}>
                                        {message?.intentUser?.accuracy ? Math.round(message.intentUser.accuracy*100)+"% " : message?.intentCoach?.accuracy ? Math.round(message.intentCoach.accuracy*100)+"% ": ''}
                                        {message.intentClass}
                                        <strong>{message.intentQuality?.length > 0 ? " | "+message.intentQuality : message.leadership_quality?.[0] ? " | "+message.leadership_quality[0] : ''}</strong>
                                    </span>
                                </div>
                            </Accordion>
                        )
                    })
                )}
                <DialogIntents
                    openDialogueUtterance = {this.state.openDialogueUtterance || false}
                    openDialogueIntents = {this.state.openDialogueIntents || false}
                    selectedText = {this.state.selectedText || ""}
                    nluIntent = {this.state.nluIntent || undefined}
                    handlerRemoveItem = {(item, replaceId) => {this.removeItemFromList(item)}}
                    closeDialogue = {() => {this.setState({openDialogueUtterance: false, openDialogueIntents:false})}}
                    leadershipCategories={this.props.leadershipCategories || []}
                    leadershipQualities={this.props.leadershipQualities || []}
                    cleanItem = {this.props.cleanItem || undefined}
                    />
                {
                    //this.dialogueUtterance()
                }
                {
                    //this.dialogueIntents()
                }
            </div>
        );
    }

    removeItemFromList(item) {
        if(item !== undefined && item!== ""){
            var id = item.replace(/[^A-Za-z]/g,'');
            id = id.replace(/ /g,'');
            if(id.length > 75){
                id = id.substring(0, 75);
            }
            //console.log('DEL id text', id)
            if(id !== undefined && id !== ""){
                this.props.firebase.trainingDataItem(id).delete();
            }
        }
    }
}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(DialogMessages);
