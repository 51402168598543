import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withAuthorization } from '../../Session';
import { AuthUserContext } from '../../Session';
import * as ROLES from '../../../constants/roles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DialogMessages from '../Users/Dialog';
import DialogTaskFollowUps from './DialogTaskFollowUp';

import {SetStateLoadCategoriesAndQualities} from '../../Modules/LoadCategoriesAndQualities';

const styles = {
  dialogContent: {
    marginTop: 20,
  },
  dialogTitle: {
    backgroundColor: '#fcd750',
    fontSize: 13,
  },
  loading: {
    marginTop: 50,
    textAlign: 'center',
  },
  showUser: {
    cursor: 'pointer',
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    marginTop: 10,
    marginLeft: 15,
  }
};

function convertTimestampToDate(ts) {
  let date = new Date(ts * 1000);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours();
  let minute = date.getMinutes();

  month = (month < 10 ? "0" : "") + month;
  day = (day < 10 ? "0" : "") + day;
  hour = (hour < 10 ? "0" : "") + hour;
  minute = (minute < 10 ? "0" : "") + minute;

  return year + "-" + month + "-" + day + " [" + hour + ":" + minute + "]";
}

class AdminTasksPage extends Component {
    _isMounted = false;
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);

        this.state = {
        loading: false,
        tasks: [],
        open: false,
        username: "",
        email: "",
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ loading: true });
        this.unsubscribe = this.props.firebase
            .dialogues()
            .where('openTask', '==', true)
            .orderBy('createdAt', 'desc')
            .limit(30)
            .onSnapshot(snapshot => {
            //.get()
            //.then(snapshot => {
                if(this._isMounted ){
                    let tasks = [];
                    snapshot.forEach(doc =>
                        tasks.push({ ...doc.data(), uid: doc.id })
                    );
                    tasks.sort((a,b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0));
                    tasks.reverse();
                    this.setState({
                        tasks,
                        loading: false,
                    });
                }//end ifmounted
            });
        
        SetStateLoadCategoriesAndQualities(this, this.props.firebase);
    }

    componentWillUnmount(){
        this._isMounted = false;
        this.unsubscribe();
    }


    getUser = (userId) => {
        this.props.firebase
        .user(userId)
        .get()
        .then(doc => {
            if (!doc.exists) {
                console.log('no data');
            } else {
            this.setState({
                username: doc.data().username,
                email: doc.data().email,
                openUser: doc.data()
            });
            }
        })
        .catch(err => {
            console.log('error getting document', err);
        });
    }

    handleOpen = (userId, dialogue) => async () => {
        //await this.getUser(userId);
        this.setState({
            dialogTaskOpen: true,
            openDialogue: dialogue,
            openUserId: userId,
        });
    }

    handleClose = () => {
        this.setState({
            open: false,
        });
    }


    render() {
        const { loading, tasks } = this.state;

        return (
            <div style={{backgroundColor: "#DDDDDD"}}>
                <h1 style={styles.title}>Open Tasks</h1>
                {loading &&
                    <div style={styles.loading}>
                    <CircularProgress color="secondary"/>
                    </div>
                }
                {tasks && 
                <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>User (click ID to see user info)</TableCell>
                                <TableCell>Help</TableCell>
                                <TableCell>Reflection Module</TableCell>
                                <TableCell>Feedback</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tasks.map(t => (
                                <TableRow key={t.uid}>
                                    <TableCell>
                                    {convertTimestampToDate(t.createdAt?.seconds)}
                                    </TableCell>
                                    <TableCell
                                        onClick={this.handleOpen(t.userId, t)}
                                        style={styles.showUser}
                                    >
                                    {t.userId}
                                    </TableCell>
                                    <TableCell>
                                    {t.askHelp}
                                    </TableCell>
                                    <TableCell>
                                    {t.askReflectionTopic}
                                    </TableCell>
                                    <TableCell>
                                        {t.askFeedback}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                }
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                >
                    <DialogTitle
                        disableTypography={true}
                        style={styles.dialogTitle}
                        >
                            User Info
                    </DialogTitle>
                    <DialogContent  style={styles.dialogContent} >
                        <p>Username: <strong>{this.state.username}</strong></p>
                        <p>Email: <strong>{this.state.email}</strong></p>
                        {this.state.open && this.state.openUser !== undefined &&
                            <div>
                                <p>Device: <strong>{this.state.openUser.mobileDevice !== undefined ? this.state.openUser.mobileDevice : this.state.openUser.mobileDeviceToken !== undefined? "Mobile": "Browser"}</strong></p>
                            </div>
                        }

                        {this.state.open && 
                            <DialogMessages id={`messages-${this.state.openDialogue.uid}`} dialogue={this.state.openDialogue} />
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                {this.state.dialogTaskOpen && this.state.openUserId !== undefined &&
                    <DialogTaskFollowUps
                        handleOpen={this.state.dialogTaskOpen || false}
                        handleClose={() => {this.setState({dialogTaskOpen: false})}}
                        userId= {this.state.openUserId}
                        showFailureReport={true}
                        openDialogue = {this.state.openDialogue}
                        leadershipCategories={this.state.leadershipCategories}
                        leadershipQualities={this.state.leadershipQualities}
                    />
                }
            </div>
        )
    }
}

const condition = authUser =>
    authUser && authUser.roles?.includes(ROLES.ADMIN);

export default compose(
    withFirebase,
    withAuthorization(condition),
)(AdminTasksPage);
