import React, { Component} from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';


import { QualityID } from '../Modules/LoadCategoriesAndQualities';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
//import {IsMobileDevice} from '../Modules/IsMobileDevice'
import {ExtendDialogueMessage} from '../Routine/DiaryManual';
// used for the ThumbUps
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
//import EditIcon from '@material-ui/icons/Edit';
//import AddCommentIcon from '@material-ui/icons/AddComment';
//import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
//import VisibilityIcon from '@material-ui/icons/Visibility';
//import PeopleIcon from '@material-ui/icons/People';
import SchoolIcon from '@material-ui/icons/School';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CloseIcon from '@material-ui/icons/Close';

import Divider from '@material-ui/core/Divider';
import CancelIcon from '@material-ui/icons/Cancel';
//import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
//import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';

const styles = theme => ({
    iconSelected: {
        color: theme.palette.secondary.light,
    },
    iconNotSelected: {
        color: theme.palette.themeGrey,
    },
    iconSelectedSmaller: {
        color: theme.palette.secondary.light,
        fontSize: 'smaller'
    },
    iconNotSelectedSmaller: {
        color: theme.palette.themeGrey,
        fontSize: 'smaller'
    },
    iconSelectedSmallerFollowUp: {
        color: theme.palette.themeAltGold,
        fontSize: 'smaller'
    },
    iconRedSmaller: {
        color: theme.palette.themeRed,
        fontSize: 'smaller'
    },
    fabRow: {
        position: 'fixed',
        //bottom: theme.spacing(2),
        bottom: 'calc(8% + env(safe-area-inset-bottom))', //ios
        //boom: '50vh', //ios
        //top: 'calc(8% + env(safe-area-inset-top))', //ios
        //right: theme.spacing(2),
        marginLeft: -30,
        //marginLeft: 'auto',
        //marginRight: 'auto',
        //maxWidth: 460,
        //width: '90%',
        zIndex: 100,
        transform: 'translate3d(0,0,0)',
        //overflowX: 'hidden',
        //overflowY: 'scroll',
        //WebkitOverflowScrolling: 'touch',
    },
    fabHide: {
        display: 'none',
    },
    fabActive: {
        size: 'small',
        color: 'primary',
    },
    fabInactive: {
        size: 'small',
        color: 'primary',
    }
});

class BookMarkVote extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        //console.log('initia;', this.props)
        var myVote = '';
        var myBookmark = false;
        var myComment = this.props.userEngagement !== undefined ? this.props.userEngagement.comment : "";
        var editComment = true;
        var editCommentVisible = false;
        var contentId = this.props.contentId;
        if( contentId === undefined  ||  contentId === "" ){
            contentId = "demo";
        }
        //console.log('props', this.props)
        this.state = {
            commentHide : "none",
            editComment : editComment,
            commentVisible : editCommentVisible,
            contentId: contentId,
            bookmark: myBookmark,
            vote:  myVote,
            comment: myComment,
            oldComment: myComment,
            otherComments:[],
            userVotedFlag: [],
            userId: this.props.userId,
            userEngagement:{}
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.setState({isMounted : true});
        this.getBookMarks(); 
    }
    componentWillUnmount() {
        this._isMounted = false;
        if(typeof this.unsubscribeBookmark === "function" ) this.unsubscribeBookmark();
        this.setState({isMounted : false, commentInWork:''});
    }

    hideFab = (e) => {
        if(!this.state.hideFab===true)
        {
            this.setState({hideFab:true});
            //console.log(this.state)

            //console.log('timeout content fabs')
            setTimeout(() => {
                this.setState({hideFab:false});
             }, 1500);
        }
    }

    upvoteContent = () => {
        //this.state.vote = 'up'
        const timestamp = this.props.firebase.fieldValue.serverTimestamp();
        this.props.firebase.contentBookmark( this.state.contentId, this.context.uid).set({
          createdAt: timestamp,
          vote: 'up',
          type: 'vote',
          origin: 'resources',
          contentId: this.state.contentId,
          userId: this.context.uid
        }, {merge:true }); 
        this.setState({ vote : 'up'});
        // add points to the content itself
        this.props.firebase.content( this.state.contentId).set({
            contentVotes: this.props.firebase.fieldValue.increment(1)
        }, {merge:true }); 
        //console.log('voted up &', this.state.vote);
        this.props.firebase.user(this.context.uid).update({
            contentLiked : this.props.firebase.fieldValue.arrayUnion( this.state.contentId),
            contentUnliked : this.props.firebase.fieldValue.arrayRemove( this.state.contentId)
        });
        // add points fo the personal gamification
        ExtendDialogueMessage(this.props.firebase, this.context, 'I am interested in ' + this.props.contentData.title, "What are your thoughts on: "+this.props.contentData.title, 'utter_ask_awareness', '', 'content');
        this.addEngagementPoints(1,'content');
    }
    devoteContent = () => {
        this.setState({ vote : ''});
        //this.state.vote = ''
        this.props.firebase.contentBookmark( this.state.contentId, this.context.uid).set({
            vote: '',
            type: 'vote',
            origin: 'resources',
            contentId: this.state.contentId,
            userId: this.context.uid
          }, {merge:true }); 
        this.props.firebase.user(this.context.uid).update({
            contentLiked : this.props.firebase.fieldValue.arrayRemove( this.state.contentId),
            contentUnliked : this.props.firebase.fieldValue.arrayRemove( this.state.contentId)
        });
    }
    downvoteContent = () => {
        this.setState({ vote : 'down'});
        //this.state.vote = 'down'
        //console.log('devote first', this.props)
        this.props.firebase.contentBookmark( this.state.contentId, this.context.uid).set({
            vote: 'down',
            type: 'vote',
            origin: 'resources',
            contentId: this.state.contentId,
            userId: this.context.uid
          }, {merge:true }); 
        this.props.firebase.user(this.context.uid).update({
            contentUnliked : this.props.firebase.fieldValue.arrayUnion(this.state.contentId ),
            contentLiked : this.props.firebase.fieldValue.arrayRemove( this.state.contentId),
        });
        this.addEngagementPoints(1,'content');
        this.props.firebase.content( this.state.contentId).set({
            contentVotes: this.props.firebase.fieldValue.increment(-1)
        }, {merge:true }); 
    }
    bookmarkContent = ( ) => {
        const timestamp = this.props.firebase.fieldValue.serverTimestamp();
        //console.log('state', this.state, this.context.uid, this.props)
        this.props.firebase.contentBookmark( this.state.contentId, this.context.uid).set({
            origin: 'resources',
            createdAt: timestamp,
            bookmark: true,
            contentId: this.state.contentId,
            userId: this.context.uid
        }, {merge:true });
        this.setState({bookmark : true});
        this.props.firebase.content( this.state.contentId).set({
            contentVotes: this.props.firebase.fieldValue.increment(3)
        }, {merge:true }); 
        this.props.firebase.user(this.context.uid).update({
            ctaText : this.props.contentData.title,
            contentBookmarked : this.props.firebase.fieldValue.arrayUnion( this.state.contentId)
        });
        ExtendDialogueMessage(this.props.firebase, this.context, 'I want to improve in ' + this.props.contentData.title , "What are your thoughts on: "+this.props.contentData.title, 'utter_ask_awareness', '', 'content');
        this.addEngagementPoints(1,'content');
        
    }
    unbookmarkContent = () => {
        if(this._isMounted){
            this.setState({bookmark : false});
        }
        //console.log('state', this.state)
        this.props.firebase.contentBookmark( this.state.contentId, this.context.uid).set({
            origin: 'resources',
            bookmark: false,
            contentId: this.state.contentId,
            userId: this.context.uid
          }, {merge:true }); 
        this.props.firebase.user(this.context.uid).update({
            contentBookmarked : this.props.firebase.fieldValue.arrayRemove( this.state.contentId)
        });
    }
    removeContentLastNotification = () => {
        this.props.firebase.user(this.context.uid).update({
            contentLastNotification : this.props.firebase.fieldValue.delete(),
            contentBookmarked : this.props.firebase.fieldValue.arrayRemove(this.state.contentId)
        });
        if(this.props.removeItemFromList !== undefined){
            this.props.removeItemFromList(this.state.contentId);
        }
        if(this.props.handleClose !== undefined){
            this.props.handleClose();
        }
    }
    removeContentHighlights = () => {
        this.unbookmarkContent();
        if(this.props.removeItemFromList !== undefined){
            this.props.removeItemFromList(this.state.contentId);
        }
        if(this.props.handleClose !== undefined){
            this.props.handleClose();
            //console.log('closeing')
        }
    }
    showComment = () => {
        //console.log("received id", this.state.contentId)
        if(this.props.handleOpenComment !== undefined){
            this.props.handleOpenComment(this.state.contentId)
        }
        else{
            this.showContent();
        }
    }
    showCommitments = () => {
        //console.log("received id", this.state.contentId)
        this.setState({commitmentsShown : true})
        if(this.props.handleOpenCommitments !== undefined){
            this.props.handleOpenCommitments(this.state.contentId)
        }
        else{
            this.showContent();
        }
    }
    showRead = () => {
        if(this.props.handleOpenRead !== undefined){
            this.props.handleOpenRead(this.state.contentId)
        }
        else{
            this.showContent();
        }
    }
    showContent = () => {
        if(this.props.handleOpen !== undefined){
            this.props.handleOpen(this.state.contentId)
        }
    }
    hideComment = ()  => {
        //console.log('hide')
        this.setState({commentHide : "none", open:false, editComment : false, comment: this.state.oldComment});
    }
    
    addEngagementPoints = (points, type) => {
        //console.log('data', this.props.contentData)
        const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const timestamp = this.props.firebase.fieldValue.serverTimestamp();
        var d = new Date();
        var myDay = weekdays[d.getDay()]
        var message = '';
        var qualitySearchTerm = ''
        if(this.state.comment !== undefined ) message= this.state.comment;
        // this string check is added due to bookmark option with array search   
        if(typeof this.props.contentData.leadershipQualitySearchTerm === 'string'){
            qualitySearchTerm = this.props.contentData.leadershipQualitySearchTerm;
        }  
        //console.log('engage', timestamp)
        this.props.firebase.insightsEngagement().add({
            createdAt: timestamp, 
            day: myDay,
            message: message, 
            origin: 'resources',
            contentTitle: this.props.contentData.title,
            leadership_quality: [QualityID(qualitySearchTerm)],
            leadership_category: this.props.contentData.leadership_category,
            contentId: this.state.contentId,
            points: points,
            type: type,
            userId: this.context.uid,
        }); 
    }

    getBookMarks = () => {
        //console.log('loaded engagement:',this.state.contentId, this.context.uid);
        this.unsubscribeBookmark = this.props.firebase.contentBookmark( this.state.contentId, this.context.uid)
        .get().then(docEngagement => {
        //.onSnapshot(docEngagement => {
            if(this._isMounted ){
                if (docEngagement.exists ) {
                    var userEngagement = docEngagement.data();
                    var myVote = '';
                    var myBookmark = false;
                    var editCommentVisible = false;
                    if (userEngagement.vote) {
                        myVote = userEngagement.vote;
                    }
                    if (userEngagement.bookmark) {
                        myBookmark =  userEngagement.bookmark;
                    }
                    
                    //this.setState({ tips: shuffle(tips) });
                    //console.log('loaded engagement:', docEngagement.data());
                    if(this._isMounted){
                        this.setState({ 
                            commentVisible : editCommentVisible,
                            bookmark: myBookmark,
                            vote: myVote,
                            oldComment: userEngagement.comment,
                            comment: userEngagement.comment,
                            commentVotes: userEngagement.commentVotes,
                            userEngagement : userEngagement,
                            read : userEngagement.read,
                        });
                    }
                }
            }//end ifmounted
        });
    }

    render() {
        const { classes, theme } = this.props;
        const {contentData} = this.props;
        if(contentData !== undefined){
            //console.log('props' ,this.props)
            if (this.props.buttonStyle !== undefined && this.props.buttonStyle === 'fabs' ){
                return(
                    <div className={this.state.hideFab? classes.fabHide : classes.fabRow} >
                        <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                            >

                            {/**
                                <Grid item xs={2} style={{ textAlign: 'left' }}>
                                    {this.state.vote === 'up'
                                    ?
                                        <Fab color="primary" size='small' onClick={this.devoteContent}> 
                                            <ThumbUpIcon className={classes.iconSelected}  />
                                        </Fab>
                                        :
                                        <Fab color="primary" size='small' onClick={this.upvoteContent} > 
                                            <ThumbUpOutlinedIcon className={classes.iconNotSelected}/>
                                        </Fab>
                                    }
                                </Grid>
                                <Grid item xs={2} style={{ textAlign: 'left' }}>
                                    {this.state.vote === 'down'
                                    ?
                                        <Fab color="primary" size='small' onClick={this.devoteContent}> 
                                            <ThumbDownIcon className={classes.iconSelected} />
                                        </Fab>
                                            :
                                        <Fab color="primary" size='small' onClick={this.downvoteContent}> 
                                            <ThumbDownOutlinedIcon className={classes.iconNotSelected} />
                                        </Fab>
                                    }
                                </Grid>
                            */}
                            {this.props.hideCommentButton !== true && contentData !== undefined
                                ?
                                    <Grid item xs={1}>
                                        {this.state.comment === '' || this.state.comment === undefined  
                                        ?
                                            <Badge overlap='circular'badgeContent={contentData.commentsCount} color='error'>
                                                <Fab color="primary" size='small' onClick={this.showComment}> 
                                                    <SpeakerNotesIcon className={classes.iconNotSelected} />
                                                </Fab>
                                            </Badge>
                                        :
                                            <Badge overlap='circular'badgeContent={contentData.commentsCount} color='error'>
                                                <Fab color="primary" size='small' onClick={this.showComment}> 
                                                    <SpeakerNotesIcon className={classes.iconSelected} />
                                                </Fab>
                                            </Badge>
                                        }
                                    </Grid>
                                :
                                    <Grid item xs={1}/>
                            }
                            <Grid item xs={1}>
                                {this.state.vote === 'up'
                                ?
                                    <Fab color="primary" size='small' onClick={this.devoteContent}> 
                                        <ThumbUpIcon className={classes.iconSelected}  />
                                    </Fab>
                                    :
                                    <Fab color="primary" size='small' onClick={this.upvoteContent} > 
                                        <ThumbUpOutlinedIcon className={classes.iconNotSelected}/>
                                    </Fab>
                                }
                            </Grid>
                            <Grid item xs={1}>
                                {this.state.bookmark === true
                                ?
                                    <Fab color="primary" size='small' onClick={this.unbookmarkContent}> 
                                        <BookmarkIcon className={classes.iconSelected} />
                                    </Fab>
                                :
                                    <Fab color="primary" size='small' onClick={this.bookmarkContent} > 
                                        <BookmarkBorderOutlinedIcon className={classes.iconNotSelected}/>
                                    </Fab>
                                }
                            </Grid>
                            <Grid item xs={1}>
                                {(this.context.contentLastNotification === this.props.contentId)
                                    ?
                                        <Fab color="primary" size='small' onClick={this.removeContentLastNotification}> 
                                            <DeleteForeverIcon className={classes.iconSelected} />
                                        </Fab>
                                    :   this.props.unbookMarkOption
                                        ?
                                            <Fab color="primary" size='small' onClick={this.removeContentHighlights}> 
                                                <DeleteForeverIcon className={classes.iconSelected} />
                                            </Fab>
                                        :
                                            ''
                                }
                            </Grid>
                            <Grid item/>
                        </Grid> 
                    </div>
                )
            } // if with fabs return fab view
            else if (this.props.commentsAndActions){
                return (
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={0}
                        >
                        <Grid item xs={4} style={{ textAlign: 'left' }}>
                            {contentData.contentVotes !== undefined && contentData.contentVotes > 0
                            &&
                                <>
                                    <FavoriteIcon className={classes.iconRedSmaller} />
                                    &nbsp;{contentData.contentVotes}
                                </>
                            }
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'center' }}>
                            {this.props.contentData?.textWordCount > 90 &&
                                (""+ Math.round(this.props.contentData.textWordCount/100*0.6) + " min read")
                            }
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'right', color: theme.palette.secondary.main }} >
                            {contentData.commentsCount !== undefined && contentData.commentsCount > 0
                            &&
                                <Badge overlap='circular'
                                    onClick={this.showComment}
                                    badgeContent={ this.context.newMessages!== undefined &&  this.context.newMessages.filter(q => q.contentId === this.props.contentId).length ?  this.context.newMessages.filter(q => q.contentId === this.props.contentId).length : 0 }
                                    color="error">
                                    {//contentData.commentsCount + " comments"
                                        'View comments'
                                    }
                                </Badge>
                            }
                        </Grid>
                        {((contentData.commentsCount !== undefined && contentData.commentsCount > 0) || (contentData.contentVotes !== undefined && contentData.contentVotes > 0))
                            &&
                            <Grid item xs={12} style={{paddingTop: 12, paddingBottom: 12}}>
                                <Divider/>
                            </Grid>
                        }
                        <Grid item xs={4} style={{ textAlign: 'center' }}  
                            onClick={this.state.bookmark? this.unbookmarkContent : this.bookmarkContent } 
                            >
                            {this.state.bookmark === true
                            ?
                                <IconButton size="small"> 
                                    <BookmarkIcon className={classes.iconSelectedSmaller} />
                                </IconButton>
                            :
                                <IconButton size="small" > 
                                    <BookmarkBorderOutlinedIcon className={classes.iconNotSelectedSmaller}/>
                                </IconButton>
                            }
                            <br/>Bookmark
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'center' }}  onClick={this.showComment}>
                            {this.state.comment === '' || this.state.comment === undefined  
                            ?
                                <IconButton size="small"> 
                                    <Badge overlap='circular'
                                        //badgeContent={0} 
                                        badgeContent={this.state.commentVotes || 0} 
                                        color='error'>
                                        <SpeakerNotesIcon className={classes.iconNotSelectedSmaller} />
                                    </Badge>
                                </IconButton>
                            :
                                <IconButton size="small" > 
                                    <Badge
                                        //badgeContent={0} 
                                        badgeContent={this.state.commentVotes || 0} 
                                        color='error'>
                                        <SpeakerNotesIcon className={classes.iconSelectedSmaller} />
                                    </Badge>
                                </IconButton>
                            }
                            <br/>Comment
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'center' }}
                            onClick={this.state.read? this.showContent : this.showRead}
                            >
                            {this.state.read !== true
                            ?
                                <IconButton size="small"> 
                                    <SchoolIcon className={classes.iconNotSelectedSmaller}  />
                                </IconButton>
                                :
                                <IconButton size="small"> 
                                    <AssignmentIcon className={classes.iconSelectedSmallerFollowUp}/>
                                </IconButton>
                            }
                            <br/>{this.state.read? "Follow-up" : "Read"}
                        </Grid>
                    </Grid> 
                )
            }
            else if (this.props.commentsIconOnly){
                return (
                    this.state.comment === '' || this.state.comment === undefined  
                            ?
                                <IconButton> 
                                    <Badge overlap='circular'badgeContent={contentData.commentsCount} color='secondary'>
                                        <SpeakerNotesIcon className={classes.iconNotSelected} />
                                    </Badge>
                                </IconButton>
                            :
                                <IconButton > 
                                    <Badge overlap='circular'badgeContent={contentData.commentsCount} color='secondary'>
                                        <SpeakerNotesIcon className={classes.iconSelected} />
                                    </Badge>
                                </IconButton>
                )
            }
            else if (this.props.buttonStyle === 'DialogActions'){
                return(
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={0}
                        >
                        <Grid item xs={2} style={{ textAlign: 'left' }}>
                            {this.state.bookmark === true
                            ?
                                <IconButton onClick={() => {
                                        this.unbookmarkContent();
                                    }}> 
                                    <BookmarkIcon className={classes.iconSelected} />
                                </IconButton>
                            :
                                <IconButton onClick={ () => {
                                        this.bookmarkContent();
                                        this.upvoteContent();
                                    }} > 
                                    <BookmarkBorderOutlinedIcon className={classes.iconNotSelected}/>
                                </IconButton>
                            }
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'left' }}>
                            {this.state.vote === 'down'
                            ?
                                <IconButton onClick={this.devoteContent}> 
                                    <ThumbDownIcon className={classes.iconSelected} />
                                </IconButton>
                                    :
                                <IconButton onClick={() => {
                                        this.downvoteContent()
                                        this.removeContentHighlights()
                                        }}> 
                                    <ThumbDownOutlinedIcon className={classes.iconNotSelected} />
                                </IconButton>
                            }
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'left' }}>
                            {this.state.comment === '' || this.state.comment === undefined  
                            ?
                                <IconButton onClick={this.showComment}> 
                                    <Badge overlap='circular'badgeContent={contentData.commentsCount} color='secondary'>
                                        <SpeakerNotesIcon className={classes.iconNotSelected} />
                                    </Badge>
                                </IconButton>
                            :
                                <IconButton onClick={this.showComment}> 
                                    <Badge overlap='circular'badgeContent={contentData.commentsCount} color='secondary'>
                                        <SpeakerNotesIcon className={classes.iconSelected} />
                                    </Badge>
                                </IconButton>
                            }
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                            {this.state.commitmentsShown !== true && this.props.contentData?.validated && this.props.contentData?.actions?.length > 0
                            ?
                                <IconButton onClick={this.showCommitments}> 
                                    <AssignmentTurnedInIcon className={classes.iconNotSelected} />
                                </IconButton>
                            :   this.props.handleClose !== undefined
                                ?
                                    <IconButton onClick={this.props.handleClose}> 
                                        <CloseIcon className={classes.iconNotSelected} />
                                    </IconButton>
                                :   ''
                            }
                        </Grid>
                        {
                            //this.DialogRenderComments(contentData)
                        }
                    </Grid> 
                )
            } 
            else{
                return(
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={0}
                        >
                        <Grid item xs={2} style={{ textAlign: 'left' }}>
                            {this.state.vote === 'up'
                            ?
                                <IconButton onClick={this.devoteContent}> 
                                    <ThumbUpIcon className={classes.iconSelected}  />
                                </IconButton>
                                :
                                <IconButton onClick={this.upvoteContent} > 
                                    <ThumbUpOutlinedIcon className={classes.iconNotSelected}/>
                                </IconButton>
                            }
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'left' }}>
                            {this.state.vote === 'down'
                            ?
                                <IconButton onClick={this.devoteContent}> 
                                    <ThumbDownIcon className={classes.iconSelected} />
                                </IconButton>
                                    :
                                <IconButton onClick={this.downvoteContent}> 
                                    <ThumbDownOutlinedIcon className={classes.iconNotSelected} />
                                </IconButton>
                            }
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'left' }}>
                            {this.state.comment === '' || this.state.comment === undefined  
                            ?
                                <IconButton onClick={this.showComment}> 
                                    <Badge overlap='circular'badgeContent={contentData.commentsCount} color='secondary'>
                                        <SpeakerNotesIcon className={classes.iconNotSelected} />
                                    </Badge>
                                </IconButton>
                            :
                                <IconButton onClick={this.showComment}> 
                                    <Badge overlap='circular'badgeContent={contentData.commentsCount} color='secondary'>
                                        <SpeakerNotesIcon className={classes.iconSelected} />
                                    </Badge>
                                </IconButton>
                            }
                            
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                            {this.state.bookmark === true
                            ?
                                <IconButton  onClick={this.unbookmarkContent}> 
                                    <BookmarkIcon className={classes.iconSelected} />
                                </IconButton>
                            :
                                <IconButton onClick={this.removeContentHighlights} > 
                                    <BookmarkBorderOutlinedIcon className={classes.iconNotSelected}/>
                                </IconButton>
                            }
                        </Grid>
                        {
                            //this.DialogRenderComments(contentData)
                        }
                    </Grid> 
                )
            } //else return WITHOUT FAB
        }
        else return ""
    }

}

// HN this is the export so that the module can be loaded in another file

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
  )(BookMarkVote);

  