import React, { Component } from 'react';
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import JournalWeek from './JournalWeek';
import { withFirebase } from '../Firebase';
import LoadingProgress from '../Modules/LoadingProgress';
import DiaryManual from '../Routine/DiaryManual';


// style elements
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionActions';
//import Typography from '@material-ui/core/Typography';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

class JournalList extends Component {
    _isMounted = false;
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        //console.log('probs in JournalWeek ',this.probs);
        this.state = {
            loading: false,
            loadingText: 'loading diary',
            journalWeeks: [],
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ loading: true });
        // weekly Journals
        var query = this.props.firebase
        .insightsWordsArchive(this.context.uid)
        .where("category","==","weeklyJournal")
        if(this.props.messageWeek!== undefined && this.props.messageWeek!== ''){
            query = this.props.firebase
            .insightsWordsArchiveMessages(this.context.uid, this.props.messageWeek)
        }
        this.unsubscribe = query
        .onSnapshot(snapshot => {
            if(this._isMounted){
                if (snapshot.size) {
                    let journalWeeks = [];
                    snapshot.forEach(doc =>
                        journalWeeks.unshift({ ...doc.data(), uid: doc.id }),
                    );
                    if(this._isMounted ){
                        this.setState({
                            journalWeeks,
                            loading: false,
                        });
                    }
                    //console.log('journalweeks data',journalWeeks);
                } else {
                    if(this.props.messageWeek!== undefined && this.props.messageWeek!== '' && snapshot.exists){
                        //journalWeeks.unshift({ ...snapshot.data(), uid: snapshot.id });
                        if(this._isMounted){
                            this.setState({
                                activeStep_recent:  0, 
                                journalWeeks: [{ ...snapshot.data(), uid: snapshot.id }],
                                loading: false,
                            });
                        }
                    }
                    else{
                        if(this._isMounted){
                            this.setState({ journalWeeks: null, loading: false });
                        }
                        //console.log('no data loaded for journal week');
                    }
                }
            }
        });    
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
        this.setState({ loading: false });
    }

  render(){
    var journalWeeks = this.state.journalWeeks;
    if(this.props.historyLimit !== undefined && this.props.historyLimit > 0 && journalWeeks!==undefined && journalWeeks!==null){
      journalWeeks=journalWeeks.slice(0,this.props.historyLimit);
    }
    return(
      <div>
      {!(this.state.loading ) && this.props.allowManualAdding 
      && journalWeeks!==undefined && journalWeeks!==null && journalWeeks.length > 0
        &&
        <Accordion style={{marginTop: 40, marginBottom: 40}}>
        <AccordionSummary
          expandIcon={<AddCircleOutlineIcon />}
          aria-label="About-Expand"
          aria-controls="About-content"
          id="About-header"
        >
          Add additional Comment to Journal
        </AccordionSummary>
        <AccordionDetails  style={{padding:0}}>        
          <DiaryManual />
        </AccordionDetails>
      </Accordion>
      }
      {this.state.loading 
        ?
            <LoadingProgress 
                defaultSet={this.props.defaultSet}
                type={'LinearProgress'} loadingText={this.state.loadingText} />
        :
          journalWeeks!==undefined && journalWeeks!==null &&
            journalWeeks.map(weeks => (
            weeks.messages
              ?
                <JournalWeek 
                    key={weeks.uid}
                    date = {weeks.uid}
                    dataset = {weeks}
                />
              :
                ""
            ))
        }
      </div>
    )
  }
}


export default compose(
  withFirebase,
)(JournalList);
