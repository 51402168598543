import React, { Component } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { AuthUserContext } from '../Session';

import SignOutButton from '../SignOut';
import logo from "../../images/rocky-logo.png";
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';

import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const styles = {
  root: {
    minWidth: '100%'
  },
  button: {
    color: 'black',
    fontFamily: '"Nunito Sans", sans-serif',
    fontSize: 12,
    fontWeight: 700,
    minWidth: 78,
  },
  buttonRight: {
    color: 'white',
    float: 'right',
    fontFamily: '"Nunito Sans", sans-serif',
    // fontFamily: '"M PLUS Rounded 1c", sans-serif',
    fontSize: 12,
    fontWeight: 700,
    minWidth: 78,
  },
  divider: {
    borderColor: 'darkgrey',
    marginLeft: 15,
    marginRight: 15,
  },
  drawer: {
    minWidth: 230,
  },
  drawerLogo: {
    backgroundColor: '#fcd750',
    marginLeft: 15,
    marginTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 10,
    paddingBottom: 12,
    width: 200,
  },
  greeting: {
    color: 'lightgrey',
    fontFamily: '"M PLUS Rounded 1c", sans-serif',
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 0,
    marginLeft: 15,
    marginTop: 30,
  },
  listItemText: {
    color: '#fcd750',
    fontFamily: '"M PLUS Rounded 1c", sans-serif',
    fontSize: 13,
    fontWeight: 700,
  },
  logo: {
    width: 80,
    verticalAlign: 'top',
  },
  logoBackground: {
    backgroundColor: '#fcd750',
    marginLeft: 15,
    marginRight: 15,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddintBottom: 2,
  },
  paper: {
    backgroundColor: 'black',
  },
};


const sideListAdmin = (username) => {
    return (
        <div style={styles.drawer}>
            <Link to={ROUTES.HOME}>
                <img src={logo} style={styles.drawerLogo} alt="logo in drawer"/>
            </Link>
            <p style={styles.greeting}>Hi, {username}</p>
            <List>
                <ListItem>
                <Link to={ROUTES.HOME} style={{ textDecoration: 'none' }}>
                    <ListItemText
                    disableTypography={true}
                    disableunderline="true"
                    style={styles.listItemText}
                    >
                    Home
                    </ListItemText>
                </Link>
                </ListItem>
                <ListItem>
                <Link to={ROUTES.PROFILE} style={{ textDecoration: 'none' }}>
                    <ListItemText
                    disableTypography={true}
                    disableunderline="true"
                    style={styles.listItemText}
                    >
                    Profile
                    </ListItemText>
                </Link>
                </ListItem>
                <ListItem>
                <Link to={ROUTES.ADMIN_TASKS} style={{ textDecoration: 'none' }}>
                    <ListItemText
                    disableTypography={true}
                    disableunderline="true"
                    style={styles.listItemText}
                    >
                    Tasks
                    </ListItemText>
                </Link>
                </ListItem>
            </List>
            <Divider light={true} />
            <List>
                <ListItem>
                <Link to={ROUTES.ADMIN_USERS} style={{ textDecoration: 'none' }}>
                    <ListItemText
                    disableTypography={true}
                    disableunderline="true"
                    style={styles.listItemText}
                    >
                    Users
                    </ListItemText>
                </Link>
                </ListItem>
                <ListItem>
                    <Link to={ROUTES.ADMIN_DIALOGUES} style={{ textDecoration: 'none' }}>
                        <ListItemText
                        disableTypography={true}
                        disableunderline="true"
                        style={styles.listItemText}
                        >
                        Dialogues
                        </ListItemText>
                    </Link>
                    </ListItem>
                <ListItem>
                    <Link to={ROUTES.ADMIN_FEEDBACKS} style={{ textDecoration: 'none' }}>
                        <ListItemText
                        disableTypography={true}
                        disableunderline="true"
                        style={styles.listItemText}
                        >
                        Feedbacks
                        </ListItemText>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link to={ROUTES.ADMIN_CONTENTS} style={{ textDecoration: 'none' }}>
                        <ListItemText
                        disableTypography={true}
                        disableunderline="true"
                        style={styles.listItemText}
                        >
                        Content
                        </ListItemText>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link to={ROUTES.ADMIN_NEWS} style={{ textDecoration: 'none' }}>
                        <ListItemText
                            disableTypography={true}
                            disableunderline="true"
                            style={styles.listItemText}
                        >
                        News
                        </ListItemText>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link to={ROUTES.ADMIN_FLAGS} style={{ textDecoration: 'none' }}>
                        <ListItemText
                            disableTypography={true}
                            disableunderline="true"
                            style={styles.listItemText}
                        >
                        Content Flags
                        </ListItemText>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link to={ROUTES.ADMIN_UTTERANCES} style={{ textDecoration: 'none' }}>
                        <ListItemText
                        disableTypography={true}
                        disableunderline="true"
                        style={styles.listItemText}
                        >
                        Utterances
                        </ListItemText>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link to={ROUTES.ADMIN_INTENTS} style={{ textDecoration: 'none' }}>
                        <ListItemText
                        disableTypography={true}
                        disableunderline="true"
                        style={styles.listItemText}
                        >
                        Intents
                        </ListItemText>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link to={ROUTES.ADMIN_INTENTSTATS} style={{ textDecoration: 'none' }}>
                        <ListItemText
                        disableTypography={true}
                        disableunderline="true"
                        style={styles.listItemText}
                        >
                        Intent Stats
                        </ListItemText>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link to={ROUTES.ADMIN_TRAINING} style={{ textDecoration: 'none' }}>
                        <ListItemText
                            disableTypography={true}
                            disableunderline="true"
                            style={styles.listItemText}
                            >
                            Training Data
                        </ListItemText>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link to={ROUTES.ADMIN_BADGES} style={{ textDecoration: 'none' }}>
                        <ListItemText
                        disableTypography={true}
                        disableunderline="true"
                        style={styles.listItemText}
                        >
                        Badges
                        </ListItemText>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link to={ROUTES.ADMIN_TUTORIALS} style={{ textDecoration: 'none' }}>
                        <ListItemText
                        disableTypography={true}
                        disableunderline="true"
                        style={styles.listItemText}
                        >
                        Help Tutorials
                        </ListItemText>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link to={ROUTES.ADMIN_SHOP} style={{ textDecoration: 'none' }}>
                        <ListItemText
                        disableTypography={true}
                        disableunderline="true"
                        style={styles.listItemText}
                        >
                        Shop Items
                        </ListItemText>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link to={ROUTES.ADMIN_LEADERSHIP_PROGRAMS} style={{ textDecoration: 'none' }}>
                        <ListItemText
                        disableTypography={true}
                        disableunderline="true"
                        style={styles.listItemText}
                        >
                        Leadership Domains
                        </ListItemText>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link to={ROUTES.ADMIN_LEADERSHIP_CATEGORIES} style={{ textDecoration: 'none' }}>
                        <ListItemText
                        disableTypography={true}
                        disableunderline="true"
                        style={styles.listItemText}
                        >
                        Leadership Program
                        </ListItemText>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link to={ROUTES.ADMIN_LEADERSHIP_QUALITIES} style={{ textDecoration: 'none' }}>
                        <ListItemText
                            disableTypography={true}
                            disableunderline="true"
                            style={styles.listItemText}
                            >
                            Leadership Qualities
                        </ListItemText>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link to={ROUTES.ADMIN_QUESTIONNAIRES} style={{ textDecoration: 'none' }}>
                        <ListItemText
                            disableTypography={true}
                            disableunderline="true"
                            style={styles.listItemText}
                            >
                            Questionnaires
                        </ListItemText>
                    </Link>
                </ListItem>
            </List>
        </div>);
};



class Navigation extends Component { 

    constructor(props) {
        super(props);

        this.state = {
        open: false
        };
    }

    handleOpen = () => {
        this.setState({ open: true });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

  render() {
    const { classes } = this.props;

    return (
        <div style={styles.root}>
            <AuthUserContext.Consumer>
                {authUser =>
                    authUser 
                    ? (
                        <AppBar style={{ boxShadow: 'none' }} position="static">
                            <Toolbar className="nav-container" >
                                <Button
                                    onClick={this.handleOpen}
                                    style={styles.button}
                                    color="inherit">
                                    Menu
                                </Button>
                                &nbsp; &nbsp;
                                <Link to={ROUTES.HOME}>
                                    <Button
                                        style={styles.button}
                                        color="inherit">
                                        Go to App
                                    </Button>
                                </Link>
                                <SignOutButton />
                            </Toolbar>
                        {authUser.roles?.includes(ROLES.ADMIN)
                        ?
                            <Drawer
                                    classes={{ paper: classes.paper }}
                                open={this.state.open}
                                onClose={this.handleClose}
                                >
                                <div
                                    tabIndex={0}
                                    role="button"
                                    onClick={this.handleClose}
                                    onKeyDown={this.handleClose}
                                >
                                    {sideListAdmin(authUser.username)}
                                </div>
                            </Drawer>
                        :
                           ""
                        }
                        </AppBar>
                    ) 
                    : (
                    <AppBar style={{ boxShadow: 'none' }} position="static">
                        <Toolbar className="nav-container">
                            <div style={styles.logoBackground}>
                                <Link to={ROUTES.HOME}>
                                    <img src={logo} style={styles.logo} alt="logo in menu" />
                                </Link>
                            </div>
                            <Button style={styles.button} color="inherit" component={Link} to={ROUTES.SIGN_IN}>
                            Sign In
                            </Button>
                        </Toolbar>
                    </AppBar>
                    )
                }
            </AuthUserContext.Consumer>
        </div>
        );
    }
}

Navigation.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles)
)(Navigation);
