import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import { AuthUserContext } from '../../Session';
import { withFirebase } from '../../Firebase';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import Grid from '@material-ui/core/Grid';
//import MenuItem from '@material-ui/core/MenuItem';
//import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

const styles = theme => ({
  grow: {
    flexGrow: 1,
  },
});


class SettingContentTime extends Component {
  static contextType = AuthUserContext;

  constructor(props) {
    super(props);
    this.state = {
        defaultValue: '',
        openSetting: false,
    };
  }

  // Username Handling
  handleSettingOpen = () => {
    this.props.params.map((p) => {
        if(this.props.content[p]!== undefined){
          return this.setState({[p] : this.props.content[p]});
        }
        else{
            return this.setState({[p] : this.state.defaultValue});
        }
    })
    this.setState({
        openSetting: true,
    });
  }

  handleSettingChange = (param, event) => {
    this.setState({
      [param]: event.target.value
    });
  }

  handleSettingClose = () => {
    this.setState({
      openSetting: false,
    });
    this.props.params.map((p) => {
        return this.setState({[p] : this.state.defaultValue});
    })
  }

  handleSettingSubmit = () => {
    this.props.params.map((p) => {
        if(this.state[p] !== null && this.state[p] !== "" && this.state[p] !== undefined && this.state[p] !== this.props.content[p] ){
            this.props.dbUpdateLink.update({
                [p]: this.state[p]
            });
        }
        return '';
    })        
    this.handleSettingClose();
  }

  render() {
    const { content, params, title, labels } = this.props;
    const { theme, classes } = this.props;

    return (
      content
        ?
        <div className={classes.grow}>
          <Card style={theme.cardSettings}>
            <p style={theme.textBigTitleText}>
                {title}
            </p>
            {
                params.map((p, index) => (
                    <p style={theme.textSettings} key={"current_"+p}>
                        {content[p]!== undefined
                            ? <span style={{ float: 'right', fontWeight: 700, maxWidth:'75%' }}>{content[p]}</span>
                            : <span style={{ float: 'right'}}>{"[n/a]"}</span>
                        }
                        <span>{labels!==undefined? labels[index]: p}</span>
                    </p>
                ))
            }
            
            <p style={{ float: 'right'}} >
                Change 
                {(this.props.access === undefined || this.props.access === true)
                &&
                    <IconButton onClick={this.handleSettingOpen}> 
                        <EditIcon fontSize="small" style={{color:"red"}}/>
                    </IconButton>
                }
            </p>
          </Card>
          <Dialog
            open={this.state.openSetting}
            onClose={this.handleSettingClose}
            fullWidth
            maxWidth="sm">
            <DialogTitle>Change Setting</DialogTitle>
            <form>
            <DialogContent>
                {
                    params.map((p, index) => (
                        
                        <div style={{width: `100%`}} key={"change_"+p}> 
                            <TextField
                                margin="normal"
                                //fullWidth
                                style={ {fontSize: '16px', width: 200} }
                                key={"change_"+p}
                                id={"change_"+p}
                                label={labels!==undefined? labels[index]: p}
                                type="time"
                                //defaultValue={this.state[p]!==undefined?this.state[p]:"13:45"}
                                value={this.state[p]!==undefined && this.state[p]!==''?this.state[p]:"13:45"}
                                onChange={(e) => this.handleSettingChange(p, e)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    step: 300, // 5 min
                                }}
                            />
                        </div>
                    ))
                }
                {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                ?
                  <p style={theme.textErrorCenterPadding}>
                    {this.state.errorMessage}
                  </p>
                : <p> </p>
                }
            </DialogContent>
            </form>
            <DialogActions>
              <Button onClick={this.handleSettingClose}>
                Cancel
              </Button>
              <Button onClick={this.handleSettingSubmit}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      : <div> </div>
            
    );
  }
}

SettingContentTime.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withFirebase
)(SettingContentTime);
