import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import {AvatarCard} from '../../Modules/AvatarImage';
import { FindTextValueInDict } from '../../Modules/LoadCategoriesAndQualities';

import { AuthUserContext } from '../../Session';
import { withFirebase } from '../../Firebase';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
//import TextField from '@material-ui/core/TextField';
//import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import BorderColorTwoToneIcon from '@material-ui/icons/BorderColorTwoTone';

const styles = theme => ({
  grow: {
    flexGrow: 1,
  },
});


class SettingContentSelectCombi extends Component {
  static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        this.state = {
            defaultValue: '',
            openSetting: false,
        };
    }

    // Username Handling
    handleSettingOpen = () => {
        this.props.params.map((p) => {
            if(this.props.content[p]!== undefined){
                return this.setState({[p] : this.props.content[p]});
            }
            else{
                return this.setState({[p] : this.state.defaultValue});
            }
        })
        this.setState({
            openSetting: true,
        });
    }

    handleSettingChange = (param, event) => {
        try{
            if(param?.length>0 && event?.target?.value !== undefined){
                var motherCategory = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, event.target.value, 'category');
                if(motherCategory?.length>0){
                    this.setState({
                        [param]: event.target.value,
                        catFilter: motherCategory
                    });
                    //console.log('select',param, event.target.value, motherCategory)
                }
                else{
                    this.setState({
                        [param]: event.target.value
                    });
                }
            }
        } catch (e){ console.log('error SCSC', e)}
    }

    handleSettingClose = () => {
        this.setState({
            openSetting: false,
        });
        this.props.params.map((p) => {
            return this.setState({[p] : this.state.defaultValue});
        })
    }

    handleSettingSubmit = () => {
        //console.log('new value',this.props.params)
        this.props.params.map((p) => {
            if(this.state[p] !== null && (this.state[p]!=='' || (this.state[p]==='' && this.props.content[p] !== undefined)) && this.state[p] !== undefined && this.state[p] !== this.props.content[p] ){
                var newValue = this.state[p];
                if(newValue === ''){
                    newValue = this.props.firebase.fieldValue.delete()
                }
                var updateSet = { [p]: newValue };
                if(this.props.updateExtraChange !== undefined && typeof this.props.updateExtraChange === 'object'){
                    Object.assign(updateSet, this.props.updateExtraChange)
                }
                if(this.props.dbUpdateLink !== undefined){
                    this.props.dbUpdateLink.update(updateSet);
                }
                if(this.props.updateState !== undefined){
                    Object.assign(updateSet, {category: this.state.catFilter})
                    this.props.updateState(updateSet);
                }
                //console.log('new value',this.state[p])
            }
            return '';
        })
        this.handleSettingClose();
    }

    render() {
        const { content, params, title, labels, selectOptions, selectOptionsText, emptyOption } = this.props;
        const { theme, classes } = this.props;
        //console.log('SCSC props', this.props)
        return (
        content
            ?
                <div className={classes.grow}>
                    <Card style={theme.cardAdminSettings} onClick={this.handleSettingOpen}>
                        <Grid container>
                            <Grid item xs={9} >
                            <h6><a to="#">{title}</a>
                            {this.props.mandatory 
                                &&  <strong style={{color:theme.palette.themeRed}}>&nbsp;*</strong>
                            }
                            {this.props.mandatory===false
                                &&  <span style={{fontSize: 'small', fontWeight:'normal'}}>&nbsp;(optional)</span>
                            }
                            </h6>
                          </Grid>
                            <Grid item xs={1}>  </Grid>
                            <Grid item xs={2} style={{textAlign: 'right', marginTop: -10}}>
                                {(this.props.access === undefined || this.props.access === true)
                                &&
                                    <Tooltip title={'Edit/Change'}>
                                        <IconButton onClick={this.handleSettingOpen}> 
                                            <BorderColorTwoToneIcon fontSize="small" style={{color:"red"}}/>
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Grid>
                        </Grid>
                        {
                            params.map((p, index) => (
                                <div style={theme.textSettings} key={"current_"+p}>
                                    {content[p]?.length > 1 /// not including "" empty
                                        ?   <span style={{ float: 'right', fontWeight: 700, maxWidth:'75%' }}
                                                onClick={this.handleSettingOpen}
                                            >
                                                <AvatarCard 
                                                    key={content[p]} dict={this.props.leadershipQualities} type='quality' item={content[p]} squareSize='20' 
                                                    fallbackText={true}
                                                />
                                                {/***
                                                    selectOptions!== undefined && selectOptionsText !== undefined && selectOptions.indexOf(content[p]) >= 0
                                                    ?   selectOptionsText[ selectOptions.indexOf(content[p]) ]
                                                    :   content[p]
                                                */
                                                }
                                            </span>
                                        :   <span style={{ float: 'right', color: theme.palette.secondary.main}}
                                                onClick={this.handleSettingOpen}
                                            >
                                                {"Add"}
                                            </span>
                                    }
                                    <span>{
                                        labels!==undefined
                                            ?   labels[index]
                                            :   this.props.noLabel !== true
                                                ?   p
                                                :   ""
                                        }
                                    </span>
                                    {this.props.infoText?.[index]?.length > 2
                                        &&  <div style={{...theme.textSupport, marginTop: 20}}><br/>{this.props.infoText[index]}</div>
                                    }
                                </div>
                            ))
                        }
                    </Card>
                    <Dialog
                        open={this.state.openSetting || false}
                        onClose={this.handleSettingClose}
                        fullWidth
                        maxWidth="sm"
                    >
                        <DialogTitle>{this.props.title?.length > 2 ? this.props.title : "Change Quest"}</DialogTitle>
                        <form>
                        <DialogContent>
                            {params.map((p, index) => {
                                    var counter = 0;
                                    var motherCategory = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, content[p], 'category');
                                    //console.log('motherCategory',p,  motherCategory)
                                    return(
                                        <div style={{width: `100%`}} key={"change_"+p}>
                                            <strong>{labels!==undefined? labels[index]: p}</strong>
                                            <span style={{color:'darkgrey'}}>&nbsp;-&nbsp;
                                                {selectOptions!== undefined && selectOptionsText !== undefined && selectOptions.indexOf(content[p]) >= 0
                                                    ?   selectOptionsText[ selectOptions.indexOf(content[p]) ]
                                                    :   content[p]
                                                }
                                            </span>
                                            {////////////////// THIS ARE THE CATEGORIES
                                            this.props.selectOptionsFilter !== undefined && 
                                                <Select
                                                    style={{width:'100%', textAlign: "right"}}
                                                    value={this.state.catFilter || FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.state[p], 'category') || ''}
                                                    onChange={(event) => {
                                                        if(event?.target?.value !== undefined){
                                                            console.log('cat', event?.target?.value) 
                                                            this.setState({catFilter: (event?.target?.value), [p]: ''})
                                                        }
                                                    }}
                                                    name={'selectmaster'}
                                                >
                                                    {this.props.selectOptionsFilter?.map((entry,index) => {
                                                        return (
                                                            <MenuItem 
                                                                key={"select_"+index+entry} 
                                                                value={entry}
                                                                selected={entry === (this.state.catFilter || FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.state[p], 'category'))}
                                                            >
                                                                <AvatarCard key={entry} dict={this.props.leadershipCategories} type='category' item={entry} squareSize='20' />
                                                                {/**
                                                                    selectOptionsText !== undefined && selectOptionsText[index] !== undefined && selectOptionsText[index] !== ""
                                                                    ?   selectOptionsText[index]
                                                                    :    entry
                                                                */
                                                                }
                                                            </MenuItem>
                                                            );
                                                        })//end map
                                                    }
                                                    {content[p]?.length > 0 && motherCategory === undefined &&
                                                        <MenuItem key={"select_plys_undefined" } value={'undefined'}
                                                            selected={true} >
                                                            {" [NO ACCESS]"}
                                                        </MenuItem>
                                                    }
                                                </Select>
                                            }                     
                                            {////////////////// THIS ARE THE QUALITIES
                                            (this.state.catFilter?.length > 0 || this.state[p]?.length > 0) && p?.length > 0 && this.state?.[p] !== undefined 
                                            && 
                                                <>
                                                {!(p?.length > 0 && this.state[p]?.length > 0)
                                                    &&
                                                    <span style={{width: '100%', textAlign:'right', display: 'block'}}>
                                                        Please select
                                                    </span>
                                                }
                                                <Select
                                                    style={{width:'100%', textAlign: "right"}}
                                                    value={p?.length > 0 && this.state[p]?.length > 0 ? this.state[p] : ''}
                                                    onChange={(e) => this.handleSettingChange(p, e)}
                                                    name={p}
                                                >
                                                    {
                                                        ////// if the filter category is different to the selected item then it should be added once
                                                        (motherCategory !== undefined && index === 0 && this.state.catFilter?.length > 1 && this.state.catFilter !== motherCategory)
                                                        &&
                                                            <MenuItem 
                                                                key={"select_raw_"+content[p]} 
                                                                value={content[p] || ''}
                                                                selected={true}
                                                            >
                                                                <AvatarCard key={content[p]} dict={this.props.leadershipQualities} type='quality' item={content[p]} squareSize='20' />
                                                                {
                                                                    //this.state[p]
                                                                }
                                                            </MenuItem>
                                                    }
                                                    {selectOptions!== undefined && selectOptions?.map((entry, index) => {
                                                        if(this.state.catFilter?.length > 1 && FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, entry, 'category') !== this.state.catFilter){
                                                            //console.log('hidden', this.state.catFilter, this.props)
                                                            return ""
                                                        }
                                                        else if(this.state.catFilter === undefined && this.props.selectOptionsFilter?.length > 0
                                                            && FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, entry, 'category') !== motherCategory){
                                                            //console.log('hidden own', this.state.catFilter)
                                                            return ""
                                                        }
                                                        else{
                                                            //console.log('shown', p, this.state[p]?.length, this.state.catFilter, counter)
                                                            counter += 1;
                                                            //console.log(counter, this.state.catFilter, entry === this.state[p] , ((!(this.state[p]?.length > 0)) && counter === 1))
                                                            return (
                                                                <MenuItem 
                                                                    key={"select_"+index+entry} 
                                                                    value={entry || ''}
                                                                    selected={entry === this.state[p] || ((!(this.state[p]?.length > 0)) && counter === 1)}
                                                                >
                                                                    <AvatarCard key={entry} dict={this.props.leadershipQualities} type='quality' item={entry} squareSize='20' />
                                                                    {/**
                                                                        selectOptionsText !== undefined && selectOptionsText[index] !== undefined && selectOptionsText[index] !== ""
                                                                        ? selectOptionsText[index]
                                                                        : entry
                                                                    */
                                                                    }
                                                                </MenuItem>
                                                            );
                                                        }    
                                                    })} 
                                                    {//// quality
                                                    p?.length> 0 && selectOptions?.length > 0 && content!==undefined
                                                        ?   (content[p]!== undefined && !(selectOptions?.includes(content[p])) )
                                                            ?
                                                                <MenuItem 
                                                                    key={"select_plys_"+content[p] } 
                                                                    value={content[p] || ''}
                                                                    selected={content[p] === this.state[p]} 
                                                                >
                                                                    {content[p] + " [NO ACCESS]"}
                                                                </MenuItem>
                                                            :
                                                                emptyOption
                                                                ?
                                                                    <MenuItem key={"select_0"+p} value={''}  >
                                                                        {'---REMOVE---'}
                                                                    </MenuItem>
                                                                :
                                                                    <div></div>
                                                        :   <div></div>
                                                    }
                                                </Select>
                                                </>
                                            }
                                        </div>
                                    )
                                })
                            }
                            {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                                ?
                                    <p style={{color : 'red'}}>
                                        {this.state.errorMessage}
                                    </p>
                                :   <p> </p>
                            }
                        </DialogContent>
                        </form>
                        <DialogActions>
                            <Button onClick={this.handleSettingClose}>
                                Cancel
                            </Button>
                            {params?.[0] !== undefined
                            &&
                                <Button
                                    disabled={this.state[params[0]] !== content[params[0]] && (this.state[params[0]]?.length > 0 || this.props.emptyOption )
                                        ? false : true}
                                    onClick={this.handleSettingSubmit}>
                                    Save
                                </Button>
                            }
                        </DialogActions>
                    </Dialog>
                </div>
            :   <div> </div>
                
        );
    }
}

SettingContentSelectCombi.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(SettingContentSelectCombi);
