import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { withAuthorization } from '../Session';
import { AuthUserContext } from '../Session';
import * as ROLES from '../../constants/roles';

import Navigation from '../Navigation';

const styles = {};

class AdminPage extends Component {
  static contextType = AuthUserContext;

  render() {
    return (
      <div>
        <Navigation />
      </div>
    );
  }
}

AdminPage.propTypes = {
  classes: PropTypes.object.isRequired
}

const condition = authUser =>
      authUser && authUser.roles?.includes(ROLES.ADMIN);

export default compose(
  withStyles(styles),
  // withEmailVerification,
  withAuthorization(condition),
)(AdminPage);
