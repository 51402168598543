import {DataCacheRead} from "../Modules/DataCache";

export function LoadStateEngagementStats(that, userId, realTime = false) {
    let engagementStats = {};
    var engagementBenchmarkQuality = 200;
    var engagementBenchmarkCategory = 600;
    if(that !== undefined && userId !== undefined){
        var cacheStoreStats = DataCacheRead('engagementStats_'+userId)
        //console.log("loaded", cacheStoreStats)
        if(!realTime && cacheStoreStats.cacheAvailable) {
            engagementStats = cacheStoreStats.cacheData;
            if(that._isMounted!==false && engagementStats!==undefined && engagementStats!==null){
                if(engagementStats["1stRank"] !== undefined && engagementStats["1stRank"]['leadership_quality'] !== undefined  && engagementStats["1stRank"]['leadership_quality'].points > engagementBenchmarkQuality){
                    engagementBenchmarkQuality = engagementStats["1stRank"]['leadership_quality'].points
                }
                if(engagementStats["1stRank"] !== undefined && engagementStats["1stRank"]['leadership_category'] !== undefined && engagementStats["1stRank"]['leadership_category'].points > engagementBenchmarkCategory){
                    engagementBenchmarkCategory = engagementStats["1stRank"]['leadership_category'].points
                }
                //return [engagementStats, engagementBenchmarkQuality, engagementBenchmarkCategory]
            }
            if(that._isMounted !== false){
                //console.log('settingh state')
                that.setState({engagementStats, engagementBenchmarkQuality, engagementBenchmarkCategory})
            }
            //console.log("loaded", engagementStats,  engagementBenchmarkQuality, engagementBenchmarkCategory)
        }
        else if(that.props !== undefined && that.props.firebase !== undefined){
            that.props.firebase.insight(userId)
            .get().then(snapshot => {
                if(that._isMounted ){
                    var stats = {};
                    var engagementSet = snapshot.data();
                    //console.log('userstats', engagementSet)
                    if(engagementSet!== undefined && engagementSet.engagements !== undefined){
                        if(engagementSet.engagements.leadership_category !== undefined){
                            stats['leadership_category'] = engagementSet.engagements.leadership_category
                        }
                        if(engagementSet.engagements["1stRank"] !== undefined){
                            stats['1stRank'] = engagementSet.engagements["1stRank"]
                        }
                        if(engagementSet.engagements["type"] !== undefined){
                            stats['type'] = engagementSet.engagements["type"]
                        }
                        if(engagementSet.engagements.leadership_quality !== undefined){
                            stats['leadership_quality'] = engagementSet.engagements.leadership_quality
                        }
                        if(engagementSet.engagements.total !== undefined){
                            //console.log('labels', engagementSet.bot_utter);
                            replaceDataMeasures(stats, 'points', engagementSet.engagements.total.points, 'All points', 'all')
                            replaceDataMeasures(stats, 'engagement', engagementSet.engagements.total.points, 'All engagements', 'all')
                        }
                        if(engagementSet.sentiments !== undefined){
                            stats['sentiments'] = engagementSet.sentiments;
                        }
                    }
                    else{
                        addDataMeasures(stats, 'points', 1, 1, 'total', 'All points', 'all')
                        addDataMeasures(stats, 'engagement', 1, 1, 'total', 'All engagements', 'all')
                    }
                    engagementStats = stats;
                    if(engagementStats["1stRank"] !== undefined && engagementStats["1stRank"]['leadership_quality'] !== undefined  && engagementStats["1stRank"]['leadership_quality'].points > engagementBenchmarkQuality){
                        engagementBenchmarkQuality = engagementStats["1stRank"]['leadership_quality'].points
                    }
                    if(engagementStats["1stRank"] !== undefined && engagementStats["1stRank"]['leadership_category'] !== undefined && engagementStats["1stRank"]['leadership_category'].points > engagementBenchmarkCategory){
                        engagementBenchmarkCategory = engagementStats["1stRank"]['leadership_category'].points
                    }
                    //console.log('New stats', engagementStats, engagementBenchmarkQuality, engagementBenchmarkCategory)
                    if(that._isMounted !== false){
                        //console.log('settingh state')
                        that.setState({engagementStats, engagementBenchmarkQuality, engagementBenchmarkCategory})
                    }
                }
            });
        }
    }
    return {engagementStats, engagementBenchmarkQuality, engagementBenchmarkCategory}
}

function addDataMeasures(stats, measure, points, engagements, category, label, type){
    if(!(category in stats)){
        stats[category]={}
    }
    if(!(measure in stats[category]) && measure !== undefined && measure !== 'default'){
        stats[category][measure] = {
            points:  points,
            engagements: engagements,
            label: label,
            type: type
        }
    }
    else if (measure !== undefined && measure !== 'default'){
        stats[category][measure] = {
            points:  points + stats[category][measure].points,
            engagements: engagements + stats[category][measure].engagements,
            label: label,
            type: type
        }
    }
}
function replaceDataMeasures(stats, measure, data, label, type){
    //console.log('s', measure, label)
    if(!(data.category in stats)){
        stats[data.category ]={}
    }
    stats[data.category][measure] = data;
    stats[data.category][measure]['label'] = label;
    stats[data.category][measure]['type'] = type;
}