import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import * as ROUTES from '../../constants/routes';
import {IsMobileDevice} from '../Modules/IsMobileDevice';
import ActionFabButton from '../Home/ActionFabButton';
import badgeCongrats from "../../images/congrats.png";
import Countdown from 'react-countdown';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';

import StarsRoundedIcon from '@material-ui/icons/StarsRounded';
import AssistantPhotoIcon from '@material-ui/icons/AssistantPhoto';
//import logo from "../../images/rockyai-logo-image-only.png";

// MUI stuff
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
//import AlarmIcon from '@material-ui/icons/Alarm';
import SnackbarInfo from '../Modules/SnackbarInfo';

import RockyIcon from '../Modules/RockyIcon';
import ForumIcon from '@material-ui/icons/Forum';

// Help
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
	desktopPadding: {
	 	minHeight: 40,
	},
	buttonCenter: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
		marginBottom: 15,
    },
});
const stylesTab = {
    tabMain: {
        padding: 0,
        margin: 0,
        width: '100%',
        minWidth: 'auto',
        display: 'contents'
    },
    tabsNavi: {
        backgroundColor: 'transparent', 
        padding: 0,
        margin: 0,
        opacity: 100,
        width: 'auto',
        minWidth: 'auto',
        display: 'contents'
    }
}

class Task4Chats extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        
        this.state = {
            open: false,
            challenges: 4,
            dateReference: this.getDateReference()
        };
    } 

    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount(){
        this._isMounted = false;
    }

    getDateReference(){
        var date = Date.now();
        var dateReference = date;
        var timestamp = undefined;
        var offSetForTimer = (1 * 24 * 60 * 60 * 1000);
        if(this.props.user?.primingSessionStartedAt?.seconds > 0){
            if(this.props.user?.reflectionSessionStartedAt?.seconds > this.props.user?.primingSessionStartedAt?.seconds){
                timestamp = this.props.user.reflectionSessionStartedAt.seconds;
                dateReference = new Date(timestamp * 1000 + offSetForTimer);
            }
            else{
                timestamp = this.props.user.primingSessionStartedAt.seconds;
                dateReference = new Date(timestamp * 1000 + offSetForTimer);
            }
        }
        else if(this.props.user?.reflectionSessionStartedAt?.seconds > 0){
            timestamp = this.props.user.reflectionSessionStartedAt.seconds;
            dateReference = new Date(timestamp * 1000 + offSetForTimer);
        }
        else if(this.props.user?.journeyStartedAt?.seconds > 0){
            timestamp = this.props.user.journeyStartedAt.seconds;
            dateReference = new Date(timestamp * 1000 + offSetForTimer);
        }
        //console.log('dateReference', dateReference)
        return dateReference
    }

    render(){
        return(
            <>
                <SnackbarInfo 
                    alertText = {this.state.alertText}
                    openOnClick = {true}
                    alertOpenEvent = {this.state.openAlert || false}
                    closeHandler = {() => {this.setState({openAlert: false})}}
                />
                {this.renderButtons()}
            </>
        )
    }

    handleStartRoutine = () => {
        if(this._isMounted){
            this.props.history.push(ROUTES.ROUTINE);
            this.setState({ openAlert: false},
                () => setTimeout( () => {this.setState({alertText: 'You can complete 2 chats per day, one in the morning and one in the evening.', openAlert: true})}, 300)
            )
        }
    }

    renderButtons() {
        const { theme } = this.props;
        return (
            <div style={{margin:this.props.noMargin? 0 : 20}}>
                <div style={{position: 'relative'}}>
                    <h4 style={theme.CardContent.titleText}>
                        {//Day ① - 'Reflective Chat'
                        }
                        Coaching Agenda
                    </h4>
                    {this.props.tutorialOpen 
                    &&
                        <div style={{position: 'absolute', top: -4, right: -6}}>
                            <IconButton style={{marginRight: 0}} onClick={() => {if(this.props.tutorialOpen) this.props.tutorialOpen('mission')}}>
                                <HelpOutlineIcon />
                            </IconButton>
                        </div>
                    }
                </div>
				{(this.props.user.dialogueCounter === undefined || this.props.user.dialogueCounter < 5)
					&&
					<p style={theme.textCenter}> 
						4 reflection practices &bull; Explore personal vision
					</p>
				}
                {this.props.user?.dialogueCounter >= 4
                    ?   ''
                    :   <div style={{textAlign: 'center', color: theme.palette.themeBlue}}>Time for next AI chat:</div>
                }
                {this.props.user?.dialogueCounter >= 4
                    ?   ''
                    :
                        <h1 style={{...theme.textSupportCenter}}>
                            <Countdown 
                                daysInHours={true}
                                date={this.state.dateReference > Date.now() 
                                    ? this.state.dateReference 
                                    : (Date.now() + (30 * 1000))} 
                                onComplete={this.handleStartRoutine}
                            />
                        </h1>
                }
                {this.props.user?.dialogueCounter > 0
                    ?   this.props.user?.dialogueCounter < 4
                        ?
                            <p style={theme.textSupportCenter}> 
                                {this.props.user.dialogueCounter * 25 + 5}% of personal AI analysis completed. <br/>
                            </p>
                        :
                            <p style={theme.textSupportCenter}> 
                                {this.props.user.dialogueCounter} / 4 AI chats completed. <br/>
                            </p>
                    :
                        <p style={theme.textSupportCenter}> 
                            5% of personal AI analysis completed. <br/>
                        </p>
                }
                <div style={{padding: '10px 0px 10px 0px', margin:0, width: "100%", maxHeight: 200, height:"100%", overflow:'hidden'}}>
                    <Tabs
                        aria-label="Coaching Modules"
                        variant="scrollable"
                        scrollButtons="auto"
                        value={1}
                        style={{
                            //marginLeft:20, 
                            //marginRight:20, 
                            //paddingLeft: 10
                        }} 
                    >
                        <Tab 
                            style={{...stylesTab.tabsNavi}}
                            value={1}
                            onClick={this.handleStartRoutine}
                            label={
                            <ActionFabButton
                                text="Chat 1/4"
                                icon={(this.props.user?.dialogueCounter > 0 ? CheckCircleIcon : RockyIcon)}
                                theme={theme}
                                height = '9vH'
                                style={{marginLeft:10}}
                                //percentage = {this.props.actionUpdatesScore || 0}
                                //colorCircle = { this.props.user?.dialogueCounter > 0 ? theme.palette.themeDarkBlue : theme.palette.themeAltGold}
                                //colorButton = {this.props.actionUpdatesScore > 40? theme.palette.themeAltGold : theme.palette.themeDarkBlue }
                                percentage = {100}
                                colorCircle = {'#e1e6e6'}
                                colorButton = { this.props.user?.dialogueCounter > 0 ? theme.palette.themeAlertGreen : theme.palette.themeBlue }
                            />}
                        />
                        <Tab 
                            style={{...stylesTab.tabsNavi}}
                            value={2}
                            onClick={this.handleStartRoutine}
                            label={
                            <ActionFabButton
                                text="Chat 2/4"
                                icon={(this.props.user?.dialogueCounter > 1 ? CheckCircleIcon : this.props.user?.dialogueCounter === 1 ? RockyIcon : StarsRoundedIcon)}
                                theme={theme}
                                height = '9vH'
                                style={{marginLeft:10}}
                                //percentage = {this.props.actionUpdatesScore || 0}
                                //colorCircle = { this.props.user?.dialogueCounter > 0 ? theme.palette.themeDarkBlue : theme.palette.themeAltGold}
                                //colorButton = {this.props.actionUpdatesScore > 40? theme.palette.themeAltGold : theme.palette.themeDarkBlue }
                                percentage = {100}
                                colorCircle = {'#e1e6e6'}
                                colorButton = { this.props.user?.dialogueCounter > 1 ? theme.palette.themeAlertGreen : this.props.user?.dialogueCounter === 1 ? theme.palette.themeBlue : theme.palette.themeGrey }
                            />}
                        />
                        <Tab 
                            style={{...stylesTab.tabsNavi}}
                            value={3}
                            onClick={this.handleStartRoutine}
                            label={
                            <ActionFabButton
                                text="Chat 3/4"
                                icon={(this.props.user?.dialogueCounter > 2 ? CheckCircleIcon : this.props.user?.dialogueCounter === 2 ? RockyIcon : StarsRoundedIcon)}
                                theme={theme}
                                height = '9vH'
                                style={{marginLeft:10}}
                                //percentage = {this.props.actionUpdatesScore || 0}
                                //colorCircle = { this.props.user?.dialogueCounter > 0 ? theme.palette.themeDarkBlue : theme.palette.themeAltGold}
                                //colorButton = {this.props.actionUpdatesScore > 40? theme.palette.themeAltGold : theme.palette.themeDarkBlue }
                                percentage = {100}
                                colorCircle = {'#e1e6e6'}
                                colorButton = { this.props.user?.dialogueCounter > 2 ? theme.palette.themeAlertGreen : this.props.user?.dialogueCounter === 2 ? theme.palette.themeBlue : theme.palette.themeGrey }
                            />}
                        />
                        <Tab 
                            style={{...stylesTab.tabsNavi}}
                            value={4}
                            onClick={this.handleStartRoutine}
                            label={
                            <ActionFabButton
                                text="Chat 4/4"
                                icon={(this.props.user?.dialogueCounter > 3 ? AssistantPhotoIcon : this.props.user?.dialogueCounter === 3 ? RockyIcon : AssistantPhotoIcon)}
                                theme={theme}
                                height = '9vH'
                                style={{marginLeft:10}}
                                //percentage = {this.props.actionUpdatesScore || 0}
                                //colorCircle = { this.props.user?.dialogueCounter > 0 ? theme.palette.themeDarkBlue : theme.palette.themeAltGold}
                                //colorButton = {this.props.actionUpdatesScore > 40? theme.palette.themeAltGold : theme.palette.themeDarkBlue }
                                percentage = {100}
                                colorCircle = {'#e1e6e6'}
                                colorButton = { this.props.user?.dialogueCounter > 3 ? theme.palette.themeAlertGreen : this.props.user?.dialogueCounter === 3 ? theme.palette.themeBlue : theme.palette.themeGrey }
                            />}
                        />
                    </Tabs> 
                </div>
                {this.props.user?.dialogueCounter >=4
                    &&
                        <div style={{paddingBottom:40, paddingTop:40, textAlign:'center'}}>
                            <p style={theme.textErrorCenterPadding}>
                                <img src={badgeCongrats} 
                                    style={{maxWidth:120, width:"25%", height: 'auto', verticalAlign: 'middle'}} 
                                    alt='well done'  />
                            </p>
                            <span>You activated your personal AI analysis</span>
                            <h4 style={theme.textSupportCenter}>
                                Congratulations!
                            </h4>
                            <Button onClick={() => this.props.history.push(ROUTES.STATS)}>
                                Show AI insights
                            </Button>
                        </div>
                }
            </div>
        )
    }

    renderActionItem(key, icon, subtitle, title, actionResult, link){
        const {theme} =  this.props;
        //console.log('redner', key, title)
        var color = theme.palette.themeAlertGreen ;
        if(actionResult) color = theme.palette.themeLightGrey ;
        return(
            <Card id={key} key={key} 
                style={{...theme.CardDialogueButtonFrame, 
                    background : 'linear-gradient(to bottom, '+ color +' 0%, '+theme.palette.white+' 30%)',
                    //backgroundColor : 'linear-gradient(to bottom, '+color+' 0%, #FFFFFF 30%)',
                    margin:10, 
                    padding:20,
                    height: "90%",
                    overflow: "hidden"
                }}
            >
                        <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item xs={2}>
                                {icon}
                            </Grid>
                            <Grid item xs={10}>
                                <span style={theme.CardContentItem.TextSubTitle}> 
                                    <strong style={{wordWrap:'break-word'}}>
                                    &nbsp; {title}
                                    </strong>
                                </span>  
                            </Grid>
                            <Grid item xs={12}>
                                <span style={theme.CardContentItem.TextSubTitle}> 
                                   {subtitle}
                                </span>  
                            </Grid>
                            <Grid item xs={12}>
                                &nbsp;
                                {actionResult
                                    ?
                                        <CheckCircleIcon
                                            style={{color:"green",  float: "right"  }}
                                        />
                                    :
                                        <Link to={link}>
                                            <Button
                                                style={{float: "right", backgroundColor: theme.palette.themeRed, marginBottom:10}}
                                                size={IsMobileDevice() ? "small" : "medium"}
                                                endIcon={icon}
                                            >
                                                Start
                                            </Button>
                                        </Link>
                                }
                            </Grid>
                        </Grid>
                    
            </Card>
        )
    }
}

export default compose(
    withRouter,
    withStyles(styles, { withTheme: true})
)(Task4Chats);
