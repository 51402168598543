import React, { Component } from 'react';
//import { Link } from "react-router-dom";
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import * as ROUTES from '../../constants/routes';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import {IsMobileDevice} from '../Modules/IsMobileDevice';

import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';

import WidgetsIcon from '@material-ui/icons/Widgets';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
//import logo from "../../images/rockyai-logo-image-only.png";

// MUI stuff
import Grid from "@material-ui/core/Grid";
//import Card from '@material-ui/core/Card';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { SetAgentStatus } from '../Routine/SetAgentStatus';
import RockyIcon from '../Modules/RockyIcon';
import ForumIcon from '@material-ui/icons/Forum';

const styles = theme => ({
	desktopPadding: {
	 	minHeight: 40,
	},
	buttonCenter: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
		marginBottom: 15,
    },
});




class TaskNext extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        
        this.state = {
            open: false,
        };
    } 

    componentDidMount() {
        this._isMounted = true;
        //console.log(this.state)
        
        var targetTotalEngagements = 50;
        if(this.context.mobileNotifications === "daily" || this.context.emailNotifications === "daily"){ targetTotalEngagements = 80; }
        if(this.context.mobileNotifications === "casually" || this.context.emailNotifications === "casually"){ targetTotalEngagements = 50; }
        if(this.context.mobileNotifications === "weekly" || this.context.emailNotifications === "weekly"){ targetTotalEngagements = 20; }
        var currentTotalEngagements = 0;
        var completedTotal = 0;
        var labelTotal = currentTotalEngagements + "/" +targetTotalEngagements;
        var date = new Date();
        //var selectDayFrom = new Date(date.setDate(date.getDate() - 1));
        var selectDayFrom = new Date(date.setHours(date.getHours() - 12));
        var statsList = {};
        this.unsubscribe = this.props.firebase
        .insightsEngagement()
        .where('userId', '==', this.context.uid)
        .where('createdAt', '>=', selectDayFrom)
        .onSnapshot(snapshot => {
            if (snapshot.size) {
                if(this._isMounted){
                    this.setState({loading:true});
                }
                if (statsList === undefined ) statsList = {};

                currentTotalEngagements = 0;

                snapshot.forEach(doc => {
                    var engagementSet = doc.data();
                    if (statsList[engagementSet.type] === undefined ) statsList[engagementSet.type] =  []; 
                    statsList[engagementSet.type].push(engagementSet)
                    currentTotalEngagements += 1;
                    //console.log('data', data);
                }); // end of snapshot
                

                completedTotal = Math.ceil(currentTotalEngagements / targetTotalEngagements * 100)
                if (currentTotalEngagements>targetTotalEngagements) currentTotalEngagements = targetTotalEngagements;
                //console.log("Currenttotal", targetTotal, completedTotal)
                labelTotal = currentTotalEngagements + "/" +targetTotalEngagements;
                //console.log("1 week date")
            } 
            else{
                console.log("no data daily")
            }
            //console.log("daily syatrsy", selectDayFrom, statsList)
            //console.log("Currenttotal", targetTotal, completedTotal)
            if(this._isMounted){
                this.setState({
                    statsList, 
                    labelTotal,
                    completedTotal
                })
            }
        });

        this.setState(SetAgentStatus(this.props.user));
    }
    componentWillUnmount(){
        this._isMounted = false;
    }

    render() {
        const { theme } = this.props;
        //console.log(this.state.reflectionInTimeRange,this.state.reflectionStatus )
        return (
            <div style={{margin:20}}>
                <h4 style={theme.CardContent.titleText}>
                    Daily Engagements
                </h4>
                {/***
                <h1 style={theme.CardContent.titleText}><ControlPointIcon />&nbsp; What's next?</h1>
                 */}
                { 
                    this.renderActionItem(
                        (this.props.defaultSet?.programAvatarName?.length > 2 ? <ForumIcon/> : <RockyIcon/>)
                        , this.chooseAgentText()
                        , this.state.primingInTimeRange 
                            ? "Coaching Chat"
                            : "Reflection Chat"
                        , ((this.state.primingInTimeRange && ( this.state.primingStatus === 'DONE' ))  || (this.state.reflectionInTimeRange && ( this.state.reflectionStatus === 'DONE' )))
                        , ROUTES.ROUTINE
                    )
                }
                { 
                    this.renderActionItem(
                        <AssignmentTurnedInIcon/>
                        , "Status Updates & Goal Tracking"
                        , "Follow-up"
                        , (this.state.statsList!== undefined && this.state.statsList["followUp"] !== undefined && this.state.statsList["followUp"].length >=1)
                        , ROUTES.JOURNAL
                    )
                }
                { 
                    this.renderActionItem(
                        <WidgetsIcon/>
                        , "Personalized Tips & Inspirations"
                        , "Explore"
                        , (this.state.statsList!== undefined && this.state.statsList["content"] !== undefined && this.state.statsList["content"].length >=1)
                        , ROUTES.CONTENT
                    )
                }
                { 
                    this.renderActionItem(
                        <PeopleAltIcon/>
                        , "Share Ideas & Help the Community"
                        , "Discuss"
                        , (this.state.statsList!== undefined && this.state.statsList["social"] !== undefined && this.state.statsList["social"].length >=1)
                        , ROUTES.MESSAGES
                    )
                }
            </div>
        )
    }

    renderActionItem(icon, subtitle, title, actionResult, link){
        const {theme} =  this.props;
        return(
            <div >
                <Grid container spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={12}>
                        <span style={theme.CardContentItem.TextSubTitle}> 
                            <strong style={{wordWrap:'break-word'}}>
                                {title}
                            </strong>
                        </span>  
                    </Grid>
                    <Grid item xs={2}>
                        <div >
                            {icon}
                            &nbsp;
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                        <span style={theme.CardContentItem.TextSubTitle}> 
                            {subtitle}
                        </span>  
                    </Grid>
                    <Grid item xs={12}>
                        &nbsp;
                        {actionResult
                            ?
                                <CheckCircleIcon
                                    style={{color:"green",  float: "right"  }}
                                />
                            :
                                <Link to={link}>
                                    <Button
                                        style={{float: "right", backgroundColor: theme.palette.themeRed}}
                                        size={IsMobileDevice() ? "small" : "medium"}
                                        endIcon={icon}
                                    >
                                        Start
                                    </Button>
                                </Link>
                        }
                    </Grid>
                </Grid>
            </div>
        )
    }
    
    chooseAgentText = () => {
        if (this.state.primingInTimeRange) {
            if (this.state.primingStatus === 'START') return 'Set Goals for Today';
            else if (this.state.primingStatus === 'DONE') return 'Come back tonight for a reflective self-coaching chat';
            else if (this.state.primingStatus === 'CONTINUE') return 'Continue with AI Chat';
            else return 'Get more coaching questions';
        }
        else if (this.state.reflectionInTimeRange) {
            if (this.state.reflectionStatus === 'START') return 'Start Self-Coaching';
            else if (this.state.reflectionStatus === 'DONE') return 'Come back tomorrow for to set the goals for the day';
            else if (this.state.reflectionStatus === 'CONTINUE') return 'Continue with AI Chat';
            else return 'Get more coaching questions';
        }
        else return 'Get more coaching questions';
    }
}

export default compose(
    withStyles(styles, { withTheme: true})
)(TaskNext);
