import React, { Component } from 'react';
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import withStyles from '@material-ui/core/styles/withStyles';
import { FindTextValueInDict } from '../Modules/LoadCategoriesAndQualities';

import DialogEngagementOverview from '../Days/DialogEngagementOverview';

import DialogSettingEmail from '../Profile/DialogSettingEmail';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import Button from '@material-ui/core/Button';

import Card from '@material-ui/core/Card';
import LoadingProgress from '../Modules/LoadingProgress';
import JournalActivitiesItem from './JournalActivitiesItem';
import { IsMobileDevice } from '../Modules/IsMobileDevice';
import { DataCacheRead, DataCacheWrite} from "../Modules/DataCache";
//import DiaryManual from '../Routine/DiaryManual';
import VizSensor from 'react-visibility-sensor';
import SwipeableViews from 'react-swipeable-views';
import SwipeStepper, {customWidthStyle, customWidthSlideStyle} from '../Modules/SwipeStepper';

import InfoIcon from '@material-ui/icons/Info';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';

//import AvatarImage, {AvatarCard} from '../Modules/AvatarImage';
import AvatarImage from '../Modules/AvatarImage';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
// style elements


import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
//import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

// Help
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
///badgecard
import BadgeCard from '../Modules/BadgeCard';
import TaskNext from '../Home/TaskNext';
import { CheckMobileNotificationActivated } from '../Modules/StoreMobileDeviceInfo';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    }
})

class JournalActivities extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        //console.log('probs in JournalWeek ',this.probs);
        this.state = {
            loading: false,
            allowRefresh: true,
            emptyData: false,
            loadingText: 'loading activities',
            journeyFollowUps: [],
            journeyFollowUpsHide : [],
            journeyFollowUpsDone : [],
            journeyFollowUpsFailed : [],
            journeyFollowUpsOngoing : [],
            journeyFollowUpsRelevant : [],
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ loading: true });  
        this.loadActionSet();
    }
    shouldComponentUpdate(nextProps, nextState) {  
        if (nextProps.rerender === false) {  
            //console.log('no rerender')
            return false;  
        }  
        //console.log(' rerender')
        return true;  
    }

    loadActionSet = () => {
        if (this.unsubscribe !== undefined){
            this.unsubscribe()
        } 
        var updatedActions = {};
        var limit = 30;
        var query = this.props.firebase
        .journeyFollowUps()
        .where("userId","==",this.context.uid)
        if(this.props.specialQuery === 'contentByIds' && this.props.contentIds !== undefined && this.props.contentIds.length > 0){
            query = query.where("contentId",'in', this.props.contentIds)
        }
        else if(this.props.specialQuery === 'contentByQuality' && this.props.quality?.length > 0){
            query = query.where('leadership_quality', 'array-contains', this.props.quality)
        }
        else if(this.props.specialQuery === 'contentByIds' && (this.props.contentIds !== undefined  || (this.props.contentIds !== undefined && this.props.contentIds.length === 0))){
            var fakeContentId = "sdfnoirgbnreiobnerbneigmrpfomrg53";
            query = query.where("contentId",'in', [fakeContentId])
        }
        else if(this.props.specialQuery === 'showDoneOnly'){
            query = query.where("done",'==', true)
        }
        query = query.orderBy('createdAt', 'desc')
        if(this.props.historyLimit !== undefined && this.props.historyLimit > 0){
            limit = this.props.historyLimit
        }

        ///// write into cache parameter set and Loading ///////////////////
        let actionsCache = {};
        var cacheName = 'actionsCache_'+limit+this.props.contentIds +'_'+this.context.uid;
        var cacheStore = DataCacheRead(cacheName)
        if(cacheStore.cacheAvailable && cacheStore.cacheData !== undefined) {
            actionsCache = cacheStore.cacheData;
            this.setState(actionsCache);
        }
        //////////////////////////////////////////////////////////////////
        query = query.limit(limit*3);
        //console.log(query)
        // weekly Journals
        let journeyFollowUps = [];
        let journeyFollowUpsHide = [];
        let journeyFollowUpsDone = [];
        let journeyFollowUpsFailed = [];
        let journeyFollowUpsOngoing = [];
        let journeyFollowUpsRelevant = [];
        let intentionsLoaded = [];
        let journeyItems = {};
        let journeyIntents = {};
        var selectedCategory = this.state.selectedCategory || undefined;
        if(selectedCategory === 'default'){
            selectedCategory = undefined;
        }
        let availableCategories = {};
        this.unsubscribe = query
        .onSnapshot(snapshot => {
            var latestItemId = 'nothingthatwaslatest';
            var latestItemDate = undefined;
            if(this._isMounted){
                if (snapshot.size) {
                    var visualLimit = limit;
                    if(this.props.visualLimit>0){
                        visualLimit = this.props.visualLimit;
                    }
                    snapshot.forEach((doc) =>{
                        var item = doc.data();
                        //// ASSIGN the category
                        var leadershipCategory = 'default';
                        if(item.leadership_category?.length>1){
                            leadershipCategory = item.leadership_category;
                        }
                        //// ASSIGN the first filter category
                        if(this.props.filterMode === true && !item.hide && !item.failed && !item.done ){
                            if(selectedCategory === undefined && item.leadership_category?.length>1){
                                selectedCategory = item.leadership_category;
                                availableCategories[selectedCategory] = 1;
                            }
                            else if(availableCategories[leadershipCategory] === undefined){
                                availableCategories[leadershipCategory] = 1;
                            }
                            else{
                                availableCategories[leadershipCategory] = availableCategories[leadershipCategory] + 1;
                            }
                        }
                        //// ASSIGN all the items filter category
                        //console.log('activites reads', doc.id, item.intention, leadershipCategory, selectedCategory)
                        if(this.props.filterMode !== true || (leadershipCategory === selectedCategory) || selectedCategory === undefined){
                            var group = item.intentClass || '';
                            var topIntent = '';
                            ////// Finding the intentclass structure
                            var subs = group.split("_") || [];
                            if (subs.length >= 2){
                                /// the third is the top group
                                topIntent = subs[2];
                            }
                            //console.log('item', group, topIntent)
                            /////// Settin the prios
                            if(latestItemDate === undefined || item.createdAt > latestItemDate){
                                if(!item.done && !item.failed && !item.hide){
                                    //console.log('valid moment item', item)
                                    latestItemDate = item.createdAt;
                                    latestItemId = item.messageId;
                                }
                            }
                            //console.log(doc.id, this.state)
                            var filterIndex = -1;
                            if((journeyItems[doc.id] === undefined || item.hide)
                                //&& item.intentClass !== undefined 
                                //&& true
                                //&& (item.intentClass.includes('accompl') || item.intentClass.includes('activit') || item.intentClass.includes('reason') || item.intentClass.includes('intention') || item.intentClass.includes('concl') || item.intentClass.includes('challenge'))
                            ){
                                if((!item.hide) && this.props.groupItems && topIntent !== '' && journeyIntents[topIntent]?.length > 0 && visualLimit > 0){
                                    if(item.important){
                                        var removeOldId = journeyIntents[topIntent][0];
                                        //var removeOldId = doc.id;
                                        journeyIntents[topIntent].unshift(doc.id);
                                        ///// in the case remove the first item from other ones
                                        filterIndex = -1;
                                        filterIndex = journeyFollowUps.indexOf(removeOldId);
                                        if(filterIndex>-1) journeyFollowUps.splice(filterIndex, 1);
                                        filterIndex = journeyFollowUpsOngoing.indexOf(removeOldId);
                                        //console.log('filterindex on', removeOldId, filterIndex, journeyFollowUpsOngoing)
                                        if(filterIndex>-1) journeyFollowUpsOngoing.splice(filterIndex, 1);
                                        //console.log('filterindex ong', removeOldId, filterIndex, journeyFollowUpsOngoing)
                                        filterIndex = journeyFollowUpsRelevant.indexOf(removeOldId);
                                        //console.log('filterindex relevant', removeOldId, filterIndex, journeyFollowUpsRelevant)
                                        if(filterIndex>-1) journeyFollowUpsRelevant.splice(filterIndex, 1);
                                        //console.log('filterindex after', removeOldId, filterIndex, journeyFollowUpsRelevant)
                                        filterIndex = journeyFollowUpsDone.indexOf(removeOldId);
                                        if(filterIndex>-1) journeyFollowUpsDone.splice(filterIndex, 1);
                                        //// add as relevant

                                        if(!intentionsLoaded.includes(item.intention)){
                                            intentionsLoaded.push(item.intention);
                                            journeyFollowUpsRelevant.push(doc.id);
                                            visualLimit -= 1;
                                        }
                                        //console.log(topIntent, doc.id , removeOldId, journeyIntents[topIntent], journeyIntents, journeyFollowUpsRelevant, journeyFollowUpsOngoing)
                                    }
                                    else {
                                        if(!intentionsLoaded.includes(item.intention)){
                                            intentionsLoaded.push(item.intention);
                                            journeyIntents[topIntent].push(doc.id);
                                            visualLimit -= 1;
                                        }
                                    }
                                    //journeyItems[doc.id] = { ...item, id: doc.id, intentGroup: topIntent, group: 'Hide' };
                                }
                                //console.log('nwq blnk', item)
                                else if(item.hide) {
                                    //journeyItems[doc.id] = { ...item, id: doc.id, intentGroup: topIntent, group: 'Hide' };
                                    var replaceId = '';
                                    if(topIntent !== '' && journeyIntents[topIntent]?.length > 1 && journeyIntents[topIntent][0] === doc.id){
                                        replaceId = journeyIntents[topIntent][1];
                                        journeyIntents[topIntent].splice(0, 1);
                                        //console.log('topintent delete', doc.id, replaceId, journeyIntents[topIntent] )
                                    }
                                    filterIndex = -1;
                                    filterIndex = journeyFollowUps.indexOf(doc.id);
                                    if(filterIndex>-1) {
                                        if(replaceId!=='') {
                                            journeyFollowUps[filterIndex] = replaceId;
                                        }
                                        else {
                                            journeyFollowUps.splice(filterIndex, 1);
                                        }
                                    }
                                    filterIndex = journeyFollowUpsOngoing.indexOf(doc.id);
                                    if(filterIndex>-1) {
                                        if(replaceId!=='') {
                                            journeyFollowUpsOngoing[filterIndex] = replaceId;
                                        }
                                        else {
                                            journeyFollowUpsOngoing.splice(filterIndex, 1);
                                        }
                                    }
                                    filterIndex = journeyFollowUpsRelevant.indexOf(doc.id);
                                    if(filterIndex>-1) {
                                        if(replaceId!=='') {
                                            journeyFollowUpsRelevant[filterIndex] = replaceId;
                                        }
                                        else {
                                            journeyFollowUpsRelevant.splice(filterIndex, 1);
                                        }
                                    }
                                    filterIndex = journeyFollowUpsDone.indexOf(doc.id);
                                    if(filterIndex>-1) {
                                        if(replaceId!=='') {
                                            journeyFollowUpsDone[filterIndex] = replaceId;
                                        }
                                        else {
                                            journeyFollowUpsDone.splice(filterIndex, 1);
                                        }
                                    }
                                    journeyFollowUpsHide.push(doc.id);
                                    //console.log(' delete', doc.id, replaceId,journeyIntents[topIntent] )
                                }
                                else if(visualLimit > 0){
                                    if(item.done) {
                                        if(!this.props.showOnlyOneItem || (this.props.showOnlyOneItem && journeyFollowUpsDone.length<1) ){
                                            //journeyItems[doc.id] = { ...item, id: doc.id, intentGroup: topIntent, group: 'Done' };
                                            //console.log('push done', item)
                                            if(!intentionsLoaded.includes(item.intention)){
                                                intentionsLoaded.push(item.intention);
                                                journeyFollowUpsDone.push(doc.id);
                                            }
                                        }
                                    }
                                    else if(item.failed) {
                                        if(!this.props.showOnlyOneItem || (this.props.showOnlyOneItem && journeyFollowUpsFailed.length<1) ){
                                            //journeyItems[doc.id] = { ...item, id: doc.id, intentGroup: topIntent, group: 'Failed' };
                                            journeyFollowUpsFailed.push(doc.id);
                                        }
                                    }
                                    else if(item.urgent || item.important) {
                                        if(item.offTrack && this.props.hideOffTrack){
                                        }
                                        else{
                                            if(!this.props.showOnlyOneItem || (this.props.showOnlyOneItem && journeyFollowUpsRelevant.length<1) ){
                                                if(!intentionsLoaded.includes(item.intention)){
                                                    intentionsLoaded.push(item.intention);
                                                    journeyFollowUpsRelevant.push(doc.id);
                                                    journeyIntents[topIntent]=[doc.id];
                                                    //journeyItems[doc.id] = { ...item, id: doc.id, intentGroup: topIntent, group: 'Relevant' };
                                                    visualLimit -= 1;
                                                }
                                            }
                                        }
                                    }
                                    else if(item.onTrack || item.offTrack) {
                                        if(item.offTrack && this.props.hideOffTrack){

                                        }
                                        else{
                                            if(!this.props.showOnlyOneItem || (this.props.showOnlyOneItem && journeyFollowUpsOngoing.length<1) ){
                                                if(!intentionsLoaded.includes(item.intention)){
                                                    intentionsLoaded.push(item.intention);
                                                    journeyFollowUpsOngoing.push(doc.id);
                                                    journeyIntents[topIntent]=[doc.id];
                                                    visualLimit -= 1;
                                                }
                                            }
                                        }
                                    }
                                    else{
                                        if(item.offTrack && this.props.hideOffTrack){

                                        }
                                        else{
                                            if(!this.props.showOnlyOneItem || (this.props.showOnlyOneItem && journeyFollowUps.length<1) ){
                                                if(!intentionsLoaded.includes(item.intention)){
                                                    intentionsLoaded.push(item.intention);
                                                    journeyFollowUps.push(doc.id);
                                                    journeyIntents[topIntent]=[doc.id];
                                                    //journeyItems[doc.id] = { ...item, id: doc.id, intentGroup: topIntent, group: 'Other' };
                                                    visualLimit -= 1;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            journeyItems[doc.id] = { ...item, id: doc.id, intentGroup: topIntent};
                            //console.log('state', this.state)
                        }
                    } );
                    if(this.state.allowRefresh){
                        updatedActions = {
                            journeyItems,
                            journeyIntents,
                            journeyFollowUps,
                            journeyFollowUpsDone,
                            journeyFollowUpsFailed,
                            journeyFollowUpsOngoing,
                            journeyFollowUpsRelevant,
                            journeyFollowUpsHide,
                            journeyItemsTemp: journeyItems,
                            journeyIntentsTemp: journeyIntents,
                            journeyFollowUpsTemp: journeyFollowUps,
                            journeyFollowUpsDoneTemp: journeyFollowUpsDone,
                            journeyFollowUpsFailedTemp: journeyFollowUpsFailed,
                            journeyFollowUpsOngoingTemp: journeyFollowUpsOngoing,
                            journeyFollowUpsRelevantTemp: journeyFollowUpsRelevant,
                            journeyFollowUpsHideTemp: journeyFollowUpsHide,
                            loading: false,
                            emptyData: false,
                            latestItemId,
                            selectedCategory,
                            availableCategories
                        };
                        if(journeyFollowUpsRelevant.length > 0){
                            updatedActions['compactViewSelection'] = "journeyFollowUpsRelevant";
                        }
                        else if(journeyFollowUpsOngoing.length > 0){
                            updatedActions['compactViewSelection'] = "journeyFollowUpsOngoing";
                        }
                        else if(journeyFollowUps.length > 0){
                            updatedActions['compactViewSelection'] = "journeyFollowUps";
                        }
                        else if(journeyFollowUpsDone.length > 0){
                            updatedActions['compactViewSelection'] = "journeyFollowUpsDone";
                        }
                        if(this._isMounted){
                            this.setState(updatedActions);
                        }
                        //console.log('state', this.state)
                        ///// write into cache parameter set and Loading ///////////////////
                        DataCacheWrite('actionsCache_'+limit+this.props.contentIds+'_'+this.context.uid, updatedActions, 2)
                    }
                    else{
                        updatedActions = {
                            journeyItemsTemp: journeyItems,
                            journeyIntentsTemp: journeyIntents,
                            journeyFollowUpsTemp: journeyFollowUps,
                            journeyFollowUpsDoneTemp: journeyFollowUpsDone,
                            journeyFollowUpsFailedTemp: journeyFollowUpsFailed,
                            journeyFollowUpsOngoingTemp: journeyFollowUpsOngoing,
                            journeyFollowUpsRelevantTemp: journeyFollowUpsRelevant,
                            journeyFollowUpsHideTemp: journeyFollowUpsHide,
                            loading: false,
                            emptyData: false,
                            latestItemId,
                            selectedCategory,
                            availableCategories
                        };
                        if(this._isMounted){
                            this.setState(updatedActions);
                        }
                    }
                    //console.log('update',journeyIntents, availableCategories);
                } //end snapshot size
                else {
                    updatedActions = {
                        journeyFollowUps: null, 
                        loading: false, 
                        emptyData: true 
                    };
                    if(this._isMounted ){
                        this.setState(updatedActions);
                    }
                    //console.log('no data loaded for journal week');
                    ///// write into cache parameter set and Loading ///////////////////
                    DataCacheWrite('actionsCache_'+limit+this.props.specialQuery+'_'+this.context.uid, updatedActions, 2)
                }//end no size
            }//end ismounted
        });  
    }


    componentDidUpdate(prevProps)  {
        this._isMounted = true;
        var newContentIds = '';
        var oldContentIds = '';
        if(this.props.contentIds !== undefined && this.props.contentIds !== ""){
            newContentIds = this.props.contentIds
        }
        if(prevProps.contentIds !== undefined && prevProps.contentIds !== ""){
            oldContentIds = prevProps.contentIds
        }
        if(newContentIds !== oldContentIds ) {
            this.loadActionSet();
        }
        return true
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
        this.setState({ loading: false });
    }

    render(){
        const {theme} = this.props;
        if(!this.state.emptyData)
        {
            return(
                <div>
                    {this.props.filterMode && Object.keys(this.state.availableCategories || {}).length>1 && 
                        <Card style={{...theme.CardContentItem.CardContentSpace, ...this.props.style}}>
                            <FormControl component="fieldset" style={{width: '100%', paddingTop: 20}}>
                                {/***
                                <h2>Coaching Plan pillar:</h2>
                                */}
                                <RadioGroup row aria-label="position" name="position"
                                    style={{textAlign: 'center', display: 'block', paddingTop: 10, marginLeft: -20, marginRight: -20, whiteSpace: 'nowrap'}}
                                    onChange={(event) => { 
                                        if(this._isMounted){
                                            this.setState({selectedCategory: event.target.value}, () => this.loadActionSet());
                                        }
                                    }}
                                >
                                    {/***
                                     * (action.leadership_category?.length>1 && !['confidence', 'priority', 'people', 'clarity', 'communication', 'balance'].includes(action.leadership_category) )
                                    &&
                                        <FormControlLabel
                                            key={'_raw'}
                                            value={action.leadership_category}
                                            style={{margin: 0}} 
                                            checked={true}
                                            control={<Radio color="secondary" />}
                                            label={<AvatarImage 
                                                type='category' 
                                                item={action.leadership_category} 
                                                squareSize='4vh'
                                                dict={this.props.leadershipCategories}
                                                backgroundColor={FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, action.leadership_category, 'backgroundColor') || theme.palette.themeNaviGrey}
                                            />}
                                            labelPlacement="top"
                                        />
                                    */}
                                    {/***
                                    <FormControlLabel
                                        key={'latest'}
                                        value={'default'}
                                        style={{margin: 0}} 
                                        checked={this.state.selectedCategory === undefined || this.state.selectedCategory === 'default' }
                                        control={<Radio color="secondary" />}
                                        label={<AvatarImage 
                                            type='category' 
                                            item={'focus'} 
                                            squareSize='4vh'
                                            dict={this.props.leadershipCategories}
                                            backgroundColor={theme.palette.secondary.light}
                                        />}
                                        labelPlacement="top"
                                    />
                                    */}
                                    {//['confidence', 'priority', 'people', 'clarity', 'communication', 'balance']
                                    Object.keys(this.state.availableCategories).map(( item, index) => (
                                        <FormControlLabel
                                            key={item+'_'+index}
                                            value={item}
                                            style={{margin: 0}} 
                                            checked={this.state.selectedCategory === item}
                                            control={<Radio color="secondary" />}
                                            label={<AvatarImage 
                                                type='category' 
                                                item={item} 
                                                squareSize='4vh'
                                                dict={this.props.leadershipCategories}
                                                backgroundColor={FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, item, 'backgroundColor') || theme.palette.themeNaviGrey}
                                            />}
                                            labelPlacement="top"
                                        />
                                    ))}
                                </RadioGroup>
                                {/***
                                 */}
                                <h2 style={{textAlign: 'center'}}>
                                    {FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, (this.state.selectedCategory || 'Latest'))}&nbsp;Goals
                                </h2>
                            </FormControl>
                        </Card>
                    }
                    {false && this.props.filterMode &&
                        <>
                            <DialogEngagementOverview 
                                user={this.props.user}
                                defaultSet={this.props.defaultSet || undefined}
                                handleOpen={this.state.dialogEngagementOpen || false}
                                handleClose={() => {this.setState({dialogEngagementOpen:false})}}
                                //updateScore = { (score) => { this.updateScore(score) } }
                                leadershipCategories = {this.props.leadershipCategories}
                                leadershipQualities = {this.props.leadershipQualities}
                                //userQualityPath = {usedQualities || undefined }
                                type={'category'}
                                selectedItem={this.state.selectedCategory || 'communication'}
                                //selectedQuality={this.state.dialogEngagementQuality || undefined}
                                text = {FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, (this.state.selectedCategory || 'communication'), 'description')}
                                title = {FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, (this.state.selectedCategory || 'communication'), 'explanationShort')}
                                //nonSubscriber = {this.state.nonSubscriber || true}
                                //engagementStats = {engagementStats}
                                //engagementBenchmarkQuality = {400}
                                //handlerContinue = {this.props.handlerContinue ? this.props.handlerContinue : undefined}
                            />

                            <BadgeCard
                                //key={badgeItem.id+'_'+index}
                                backgroundColor ={"white"}
                                //image={FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, (this.state.selectedCategory || 'communication'), 'icon')}
                                avatarImage = {
                                    <AvatarImage 
                                        id={(this.state.selectedCategory || 'communication')} 
                                        type='category' 
                                        key={(this.state.selectedCategory || 'communication')} 
                                        image={FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, (this.state.selectedCategory || 'communication'), 'icon')}
                                        vertical-align="middle" 
                                        item={(this.state.selectedCategory || 'communication')} 
                                        squareSize={"auto"} 
                                        backgroundColor={FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, (this.state.selectedCategory || 'communication'), 'backgroundColor')} 
                                    />
                                }
                                //title = { badgeItem.target!== undefined ? "Next milestone:  (" + badgeItem.target + ' Points)' : 'Next milestone:' }
                                title = { 'Goals Timeline' }
                                subtitle = {FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, (this.state.selectedCategory || 'communication'))}
                                strongSubtitle = {true}
                                onClick={ () => this.setState({dialogEngagementOpen: true}) }
                                clickText= {'Show'}
                            />
                        </>

                    }
                    {!this.state.loading  
                    ?
                        this.props.compactView && this.state.compactViewSelection !== undefined && this.state.compactViewSelection !== ""
                        ?
                            <Card style={{...theme.CardContentItem.CardContentSpace, ...this.props.style}}>
                                {
                                    this.renderBlock(this.state[this.state.compactViewSelection], 'My Priorities', 'important')
                                }
                            </Card>
                        :
                            <Card style={{...theme.CardContentItem.CardContentSpace, ...this.props.style}}>
                                {this.props.hideImportant !== true &&
                                    this.renderBlock(this.state.journeyFollowUpsRelevant, 'Important' + (this.props.filterMode !== true ? ' Goals' : ''), 'urgent')
                                }
                                {this.props.hideImportant !== true &&
                                    this.renderBlock(this.state.journeyFollowUpsOngoing, 'Ongoing' + (this.props.filterMode !== true ? ' Goals' : ''), 'important')
                                }
                                {this.props.hideNew !== true &&
                                    this.renderBlock(this.state.journeyFollowUps, 'New' + (this.props.filterMode !== true ? ' Goals' : ''), 'other')
                                }
                                {this.props.hideDone !== true &&
                                    this.renderBlock(this.state.journeyFollowUpsDone, 'Victories', 'done')
                                }
                                {this.props.hideFailed !== true &&
                                    this.renderBlock(this.state.journeyFollowUpsFailed, 'Failed', 'failed')
                                }
                                {this.props.hideReminder !== true
                                    &&
                                    <div style={{width: '100%', textAlign: 'center'}}>
                                        <DialogSettingEmail 
                                            handleOpen = {this.state.openEmailSettings || false}
                                            leadershipQualities={this.props.leadershipQualities}
                                            handleClose = {() => this.setState({openEmailSettings:false})}
                                            user={this.context} 
                                        />
                                        <div style={{...theme.buttonCenter, marginBottom: 30, marginTop: 30}} 
                                            onClick={() => {this.setState({openEmailSettings:true})}}
                                        >
                                            <Button>
                                                {CheckMobileNotificationActivated(this.context)
                                                    ?
                                                        <>
                                                            Change Reminders&nbsp;<NotificationsNoneIcon fontSize="large"/>
                                                        </>
                                                    :
                                                        <>
                                                            Set Reminders&nbsp;<NotificationsOffIcon fontSize="large" style={{color: "red"}}/>
                                                        </>
                                                }
                                            </Button>
                                        </div>    
                                    </div>
                                }
                            </Card>
                    :   
                        <div>
                            <LoadingProgress 
                                defaultSet={this.props.defaultSet}
                                type={'LoadingPageAnimated'} 
                                loadingText={"loading sub-goals"} 
                            />
                        </div>
                    }
                </div>
            )
        }
        else if(this.props.preview && this.props.specialQuery!=='contentByIds'){
            return(
                <div style={{marginLeft:-20, marginRight: -20}}>
                    <TaskNext 
                        user={this.props.user!== undefined ? this.props.user : this.context} 
                        defaultSet={this.props.defaultSet}
                    />
                </div>
                
            )
        }
        else if(this.state.emptyData && this.props.showEmptyMessage){
            var errorText = 'As you interact with your AI Coach, Sub-Goals will be created. You can ask questions, articulate your thoughts, or participate in the coaching chat so that new Goals can be developed.';
            return(
                <div style={{paddingLeft: 14, paddingRight: 10}}>
                    <FormControlLabel
                        aria-label="Inspiration"
                        control={<InfoIcon style={{...theme.textSupport, marginRight: 10}}/>}
                        label= {
                            <span style={theme.textSupport}>
                                {errorText}
                            </span>
                        }
                    />
                </div>
            )
        }
        else{
            return('')
        }
    }

    renderBlock = (block, title, key) => {
        const {theme} = this.props;
        if(block!==undefined && block!==null && block.length>0){
            return(
                <VizSensor 
                    partialVisibility={true}
                    key={key}
                    onChange={(isVisible) => { 
                        if(isVisible && this.state['isVisible_'+key]===undefined) { 
                            this.setState({ ['isVisible_'+key] : true})
                            //console.log('t.leadership_category', t.leadership_category)
                        }}
                    }
                >
                            <div key={key}>
                                <Grid container spacing={2}  alignItems="center">
                                    <Grid item xs={12}>
                                        <div style={{position: 'relative'}}>
                                            {/** <AvatarCard  title={true} textBlock={title}  type='icon' image={<AssignmentTurnedInIcon />} squareSize='20' backgroundColor={'black'}/> */}
                                            <h4 style={theme.CardContent.titleText}>{title} </h4>
                                            {this.props.tutorialOpen 
                                            &&
                                                <div style={{position: 'absolute', top: -4, right: -6}}>
                                                    <IconButton style={{marginRight: 0}} onClick={() => {if(this.props.tutorialOpen) this.props.tutorialOpen('followup')}}>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </div>
                                            }
                                        </div>
                                    </Grid>
                                    {false && !IsMobileDevice() &&
                                    <Grid item xs>
                                        {this.state['activeStep_'+key]!== undefined && this.state['activeStep_'+key]>=1 
                                            &&
                                                <ArrowBackIosIcon fontSize="small" onClick={() => { this.setState({ ['activeStep_'+key]: (this.state['activeStep_'+key]-1)})}} />
                                        }
                                    </Grid>
                                    }
                                    {false && !IsMobileDevice() &&
                                    <Grid item>
                                        {this.state['activeStep_'+key]!== undefined 
                                            ?   block.length-1 > this.state['activeStep_'+key]  &&
                                                    <ArrowForwardIosIcon fontSize="small" onClick={() => { this.setState({ ['activeStep_'+key]: (this.state['activeStep_'+key]+1)})}}  />
                                            :   block.length > 1  &&
                                                    <ArrowForwardIosIcon fontSize="small" onClick={() => { this.setState({ ['activeStep_'+key]: 1})}}  />
                                                
                                        }
                                    </Grid>
                                    }
                                </Grid>
                                {this.state['isVisible_'+key]
                                    ?
                                        this.renderSingleViews(block, key, title)
                                    :
                                        <Skeleton variant="rect" width={IsMobileDevice()?'150px':'70%' } height={200} />
                                }
                            </div>
                </VizSensor>
            )
        } //ned main if check
    }

    allowRefreshAndUpdate = (keyWord = undefined) => {
        if(this._isMounted){
            //console.log('updateat content')
            this.setState({
                journeyItems: this.state.journeyItemsTemp,
                journeyIntents: this.state.journeyIntentsTemp,
                journeyFollowUps : this.state.journeyFollowUpsTemp,
                journeyFollowUpsDone : this.state.journeyFollowUpsDoneTemp,
                journeyFollowUpsFailed : this.state.journeyFollowUpsFailedTemp,
                journeyFollowUpsOngoing : this.state.journeyFollowUpsOngoingTemp,
                journeyFollowUpsRelevant : this.state.journeyFollowUpsRelevantTemp,
                journeyFollowUpsHide : this.state.journeyFollowUpsHideTemp,
            });
            if(keyWord!== undefined && keyWord!==""){
                this.setState({
                    allowRefresh: true,
                    ['activeStep_'+keyWord]  : 0
                });
            }
            else{
                this.setState({
                    allowRefresh: true,
                });
            }
        }
    }

    renderSingleViews = (block, keyWord, title=undefined) => {
        const {theme} = this.props;
        //console.log("group", block, keyWord)
        if(block!== undefined && block.length > 0){
            if(this.props.showOnlyOneItem || this.props.checkMode){
                return(
                    <div>
                        {block.map((itemId, index) => {
                            var item = this.state.journeyItems?.[itemId] || undefined;
                            //console.log('loading item'  , item)
                            if(item !== undefined){
                                var subItems = [];
                                if(item.intentGroup?.length>0 && this.state.journeyIntents?.[item.intentGroup]?.length > 1){
                                    subItems = this.state.journeyIntents[item.intentGroup];
                                    var itemStart = this.state.journeyItems?.[subItems[0]] || undefined;
                                    if(itemStart !== undefined){
                                        item = itemStart;
                                    }
                                    //console.log('SUBITEMS'  , subItems)
                                }
                                return(
                                    <div key={item.id} >
                                        <JournalActivitiesItem
                                            user={this.props.user}
                                            defaultSet={this.props.defaultSet || undefined}
                                            allowRefresh={() => {this.allowRefreshAndUpdate(keyWord)}}
                                            blockRefresh={() => {this.setState({allowRefresh:false})}}
                                            reflectionMode={this.props.reflectionMode || false}
                                            flagHighlighted={item.messageId === this.state.latestItemId}
                                            key={item.id}
                                            showTips={this.props.showTips || false}
                                            cta={item} 
                                            ctaId={item.id}
                                            checkMode={this.props.checkMode || false}
                                            specialQuery= {this.props.specialQuery}
                                            leadershipCategories={this.props.leadershipCategories}
                                            leadershipQualities={this.props.leadershipQualities}
                                            handlerMoment = {this.props.handlerMoment || undefined}
                                            handlerMessageSubmit = {this.props.handlerMessageSubmit || undefined}
                                            />
                                        {subItems?.length>0 && this.state["showAll"+item.id] && subItems.map((itemSubId, indexSub) => {
                                            var itemSub = this.state.journeyItems?.[itemSubId] || undefined;
                                            //console.log('the sub item'  , itemSub)
                                            if (itemSub && indexSub > 0 && !itemSub.hide) return(
                                                <JournalActivitiesItem 
                                                    user={this.props.user}
                                                    defaultSet={this.props.defaultSet || undefined}
                                                    key= {'sub_'+itemSubId}
                                                    subItemView = {true}
                                                    showTips={this.props.showTips || false}
                                                    allowRefresh={() => {this.allowRefreshAndUpdate(keyWord)}}
                                                    blockRefresh={() => {this.setState({allowRefresh:false})}}
                                                    reflectionMode={this.props.reflectionMode || false}
                                                    flagHighlighted={itemSub.messageId === this.state.latestItemId}
                                                    cta={itemSub} 
                                                    ctaId={itemSub.id}
                                                    checkMode={this.props.checkMode || false}
                                                    specialQuery= {this.props.specialQuery}
                                                    leadershipCategories={this.props.leadershipCategories}
                                                    leadershipQualities={this.props.leadershipQualities}
                                                    handlerMoment = {this.props.handlerMoment || undefined}
                                                    handlerMessageSubmit = {this.props.handlerMessageSubmit || undefined}
                                                    />
                                            )
                                            else return ''
                                        })}
                                        {subItems?.length>0 &&
                                            <div 
                                                style={{textAlign: 'right', marginBottom: 20}}
                                                onClick={() => this.setState({["showAll"+item.id] : (this.state["showAll"+item.id] ? false : true)})}
                                            >
                                                <span style={{color: theme.palette.secondary.main}}>
                                                    {this.state["showAll"+item.id]
                                                        ?   "Show less -"
                                                        :   "Show more +"
                                                    }
                                                </span>
                                            </div>
                                        }
                                    </div>
                                )
                            }
                            else{
                                return ''
                            }
                        }) }
                    </div>
                )
            }
            else{
                return(
                    <div>
                        <SwipeableViews
                                key={(keyWord+'reload'+block.length)}
                                axis={'x'}
                                index={this.state['activeStep_'+keyWord]}
                                onChangeIndex={(index) => {
                                    try{
                                        this.handleStepChange(index, keyWord)
                                    }
                                    catch (err) {console.log('Swipe Error')}
                                }}
                                enableMouseEvents = {this.state.allowRefresh === false? false : true}
                                disabled = {this.state.allowRefresh === false? true : false}
                                resistance = {true}
                                style={customWidthStyle('80vW', '300px', (this.props.visualLimit === undefined || (this.props.visualLimit>0 && this.props.visualLimit >= block.length )) ? block.length :  this.props.visualLimit)}
                                slideStyle={customWidthSlideStyle('80vW', '300px', (this.props.visualLimit === undefined || (this.props.visualLimit>0 && this.props.visualLimit >= block.length )) ? block.length :  this.props.visualLimit)}
                                
                            >
                            {block.flatMap((item, index) => {
                                if(this.props.visualLimit === undefined || (this.props.visualLimit>0 && this.props.visualLimit >= index+1)){
                                    return(
                                        <JournalActivitiesItem 
                                            user={this.props.user}
                                            defaultSet={this.props.defaultSet || undefined}
                                            allowRefresh={() => {this.allowRefreshAndUpdate(keyWord)}}
                                            blockRefresh={() => {this.setState({allowRefresh:false})}}
                                            flagHighlighted={item.messageId === this.state.latestItemId}
                                            key={item.id} 
                                            cta={item} 
                                            ctaId={item.id}
                                            title={title}
                                            leadershipCategories={this.props.leadershipCategories}
                                            leadershipQualities={this.props.leadershipQualities}
                                            moveNext={(removeFlag) => {
                                                //console.log(removeFlag,'length', block.length, 'index', index, 'limit',this.props.visualLimit );
                                                var itemLengths = block.length;
                                                if(this.props.visualLimit<itemLengths) itemLengths = this.props.visualLimit;
                                                if(removeFlag && index <= (itemLengths - 2)){
                                                    this.handleStepChange(index, keyWord)
                                                    //console.log('i 0')
                                                }
                                                else if(index <= (itemLengths - 2)){
                                                    this.handleStepChange(index+1, keyWord)
                                                    console.log('i i')
                                                }
                                                else if(index === (itemLengths - 2)){
                                                    this.handleStepChange(index, keyWord)
                                                    //console.log('i 2')
                                                }
                                                else if(itemLengths > 0 && index > 0 && index < (itemLengths - 1)){
                                                    this.handleStepChange(index, keyWord)
                                                    //console.log('i 3')
                                                }
                                                else{
                                                    this.handleStepChange(0, keyWord)
                                                    //console.log('i 4')
                                                }
                                            }}
                                            specialQuery= {this.props.specialQuery}
                                            handlerMoment = {this.props.handlerMoment || undefined}
                                            handlerMessageSubmit = {this.props.handlerMessageSubmit || undefined}
                                            />    
                                    ) 
                                }
                                else{
                                    return []
                                }
                            })}
                        </SwipeableViews>
                        <SwipeStepper
                            handlerSteps = {(step) => this.setState({['activeStep_'+keyWord ]:step}) }
                            steps={ block.length>0 ?  block.length>this.props.visualLimit? this.props.visualLimit : block.length : 0}
                            activeStep = {this.state['activeStep_'+keyWord] || 0}
                            showBackButton = {!IsMobileDevice()}
                        />
                    </div>
                )
            }
        }
        else{
            return ""
        }
    }

    handleStepChange = (step, keyWord) => {
        //console.log(step, keyWord, 'handler')
        if(step !== undefined && keyWord !== undefined) {
            this.setState({['activeStep_'+keyWord] : step});
        }
    };

}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(JournalActivities);