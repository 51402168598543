//import palette from '../palette';

function muiStyle (palette) { return {
    root: {
        fontSize: '16px',
        backgroundColor: 'rgba(241,240,238,.9)',
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        borderBottomLeftRadius: 15,
        //padding: 10,
        marginTop:5,
        '& .MuiOutlinedInput-root': {
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
            borderBottomLeftRadius: 15,
            '&:hover fieldset': {
                borderColor: palette.secondary.light,
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
                borderBottomLeftRadius: 15,
            },
            '&.Mui-focused fieldset': {
                borderColor: palette.black,
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
                borderBottomLeftRadius: 15,
            },
        },
    }
}}

export default muiStyle;