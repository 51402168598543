export const freeTopics = ['positive_thinking', 'focus', 'studying']

export function CheckSubscriptionStatus(userData, targetLeadershipCategory='default') {
    //console.log('userData purchasedProduct', userData.purchasedProduct)
    var nonSubscriber = true;
    var premiumSubscriber = false;
    var premiumContentAccess = false;
    var trialPeriod = false;
    if(userData!== undefined && userData!=='' ){
        premiumContentAccess = false;
        trialPeriod = false;
        var dialogueCounter = 1
        if(userData.dialogueCounter !== undefined && userData.dialogueCounter>1) {
            dialogueCounter = userData.dialogueCounter
        }
        /////////////////// Non Premium / Non Subscription
        if(userData.purchasedProduct === undefined){
            nonSubscriber = true;
        }
        else if(userData.purchasedProduct.owned !== true){
            nonSubscriber = true;
        }
        else if(userData.purchasedProduct.owned === true){
            nonSubscriber = false;
            trialPeriod = false;
            if(userData.purchasedProduct.premium === true){
                premiumSubscriber = true;
                premiumContentAccess = true;
            }
        }
        /////////////////// Free Dialogue Set, second chance for trial
        if(nonSubscriber){
            var freeDialogues = 1
            if(userData.freeDialogues !== undefined && userData.freeDialogues>1) {
                freeDialogues = userData.freeDialogues;
                trialPeriod = true;
            }
            if((dialogueCounter > freeDialogues) && dialogueCounter >= 1 ){
                nonSubscriber = true;
                trialPeriod = false;
            }
        }
        if(targetLeadershipCategory !== undefined){
            //console.log('user in Trial time')
            if(trialPeriod && userData?.programs?.includes(targetLeadershipCategory)){
                premiumContentAccess = true;
            }
        }
        ///////////// ensure access to all trial include premium
        if(dialogueCounter<3 && nonSubscriber === false){
            //nonSubscriber = false;
            trialPeriod = true;
            //// ALLOW or disallow new content 
        }
        /////// Treatment for ADMIN People //////////////////////////
        /////// Always premium content access and always trialperiod of non-subscriber
        if(userData?.roles?.includes("COACHADMIN") || userData?.roles?.includes("ADMIN") || userData?.roles?.includes("TEAM")){
            premiumContentAccess = true;
            if(nonSubscriber){
                trialPeriod = true;
            }
        }
        //console.log(nonSubscriber, premiumSubscriber, premiumContentAccess, trialPeriod)
        return {nonSubscriber, premiumSubscriber, premiumContentAccess, trialPeriod}
    }
    else{
        return {nonSubscriber, premiumSubscriber, premiumContentAccess, trialPeriod}
    }
}

export function CheckSubscriptionStateOwned(userData){
    var purchasedProduct = undefined;
    if(userData !== undefined){
        purchasedProduct = userData?.purchasedProduct || undefined;
    }
    if(userData?.roles?.includes("COACHADMIN") || userData?.roles?.includes("ADMIN") || userData?.roles?.includes("TEAM")){
        return true;
    }
    else if(purchasedProduct!==undefined && purchasedProduct.owned===true){
        return true;
    }
    else{
        return false;
    }
}