import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import AppRating from '../Modules/AppRating';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    RedditIcon,
    TwitterIcon,
    WhatsappIcon,
    } from "react-share";

const styles = theme => ({
    grow: {
      flexGrow: 1,
    },
});

class ShareSocialFeedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user,
            defaultText: 'I am happy to have discovered Rocky.ai, a wonderful AI-powered coaching experience!',
            defaultTitle: 'Check out this new app, Rocky.ai !'
        }
    }

    componentDidMount() {
    }

    render() {
        return(
            <div style={{textAlign: 'center', width: '100%'}}>
                {process !== undefined && process?.env !== undefined && process.env.REACT_APP_DEVICE_PLATFORM!==undefined && process.env.REACT_APP_DEVICE_PLATFORM==="BROWSER"
                    ?
                        <>
                            <TwitterShareButton 
                                url={"https://www.rocky.ai?share=TWB"}
                                title={this.state.defaultText+' '+this.props.feedback}
                                via='Rocky.ai'
                                hashtags={['#reflection', '#selfimprovement', '#coaching', '#mindset' ]}
                                related={['rockyaitalks']}
                                beforeOnClick={ () => {

                                }}
                            >
                                <TwitterIcon size={32} round={true} />
                            </TwitterShareButton>
                            &nbsp;
                            <LinkedinShareButton 
                                url={"https://www.rocky.ai?share=LIB"}
                                title={this.state.defaultTitle}
                                summary={this.state.defaultText+' '+this.props.feedback}
                                soure='www.rocky.ai'
                            >
                                <LinkedinIcon size={32} round={true} />
                            </LinkedinShareButton>
                            &nbsp;
                            <EmailShareButton 
                                url={"https://www.rocky.ai?share=EMB"}
                                subject={this.state.defaultTitle}
                                body={this.state.defaultText+' '+this.props.feedback}
                                soure='www.rocky.ai'
                                >
                                <EmailIcon size={32} round={true} />
                            </EmailShareButton>
                            &nbsp;
                            <FacebookShareButton 
                                url={"https://www.rocky.ai?share=FBB"}
                                quote={this.state.defaultText+' '+this.props.feedback}
                                hashtag={'#selfimprovement'}
                                soure='www.rocky.ai'
                                >
                                <FacebookIcon size={32} round={true} />
                            </FacebookShareButton>
                            &nbsp;
                            <RedditShareButton 
                                url={"https://www.rocky.ai?share=RDB"}
                                title={this.state.defaultText+' '+this.props.feedback}
                                >
                                <RedditIcon size={32} round={true} />
                            </RedditShareButton>
                            &nbsp;
                            <WhatsappShareButton 
                                url={"https://www.rocky.ai?share=WAB"}
                                title={this.state.defaultText+' '+this.props.feedback}
                                summary='Rocky.ai'
                                soure='www.rocky.ai'
                                >
                                <WhatsappIcon size={32} round={true} />
                            </WhatsappShareButton>
                        </>
                    :
                        <>
                            <AppRating 
                                buttonText="App Store"
                                simpleMode={true}
                                freeDialoguesRequest={false}  
                                user={this.props.user} 
                                db={this.props.firebase}
                                />
                            &nbsp;
                        </>
                }
            </div>
        )
    }

}

export default compose(
    withStyles(styles, { withTheme: true }),
    withRouter
)(ShareSocialFeedback);