
import moment from "moment-timezone";

export function GetTimeZone (db=undefined, user=undefined){
    var previousTimeZone = '';
    var timezone = 'UTC';
    var error = '';
    var error2 = '';
    try{
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        //var timezone1 = timezone;
        //var timezone2 = moment.tz.guess();
        ///// TAKE ALTERNATIVE
        if(timezone === undefined || timezone === null || timezone === '' || timezone === 'UTC'){
            error = 'Move to moment as first attempt failed';
            timezone = moment.tz.guess();
        }
        if(timezone === undefined || timezone === null || timezone === '' || timezone === 'UTC'){
            error2 = 'Moment failed too in first attempt';
            timezone = 'UTC';
        }
    }
    catch(err){
        console.log('TZ error', err);
        error = err;
        try{
            timezone = moment.tz.guess();
            if(timezone === undefined || timezone === null || timezone === '' || timezone === 'UTC'){
                timezone = 'UTC';
            }
        } catch (err2){
            error2 = err2;
            console.log('TZ error2', error2);
            timezone = 'UTC';
        }
    }
    if(user !== undefined && user !== null && user.uid !== undefined && db !== undefined){
        const timestamp = db.fieldValue.serverTimestamp();
        previousTimeZone = user.timezone;
        //console.log('guesszone', timezone2, timezone1, timezone)
        if(timezone !== undefined && timezone !== null && timezone !== '' && timezone !== 'UTC'){
            if(timezone !== previousTimeZone){
                db
                .user(user.uid)
                .update({
                    timezone: timezone,
                    lastEngagement: timestamp
                });
            }
        }
        if(error !== '' || error2 !== '' || timezone === 'UTC'){
            db
            .user(user.uid)
            .update({
                timezoneProblem: {timezone, error, error2},
                lastEngagement: timestamp
            });
        }
    }
    return timezone;
}