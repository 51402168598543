import React, { Component } from 'react';
//import { Link } from "react-router-dom";
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
//import { IsMobileDevice } from '../Modules/IsMobileDevice';
//import CardBigFive from '../Assessments/CardBigFive';
//import DialogCardBadges from '../Charts/DialogCardBadges';
import DialogProgramItems from '../Charts/DialogProgramItems';

//import ProgressRadar from '../Charts/ProgressRadar';
import ProgressBars from '../Charts/ProgressBars';
import {LoadStateEngagementStats} from '../Modules/LoadStateEngagementStats';
import {LoadUserCategoryAndQualities} from "../Modules/LoadUserData";
import { FindTextValueInDict } from '../Modules/LoadCategoriesAndQualities';

// Help
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';

import Grid from '@material-ui/core/Grid';

import {SetStateLoadingBatches} from '../Charts/LoadingBatches';

//import SchoolIcon from '@material-ui/icons/School';
import Card from '@material-ui/core/Card';
import DialogQuickTopicSetting from '../Goals/DialogQuickTopicSetting';
import CardSentimentGauge from '../Charts/CardSentimentGauge';
import CardLatestSentiments from '../Charts/CardLatestSentiments';
import Task4Topics from '../Home/Task4Topics';
//import CardAssessmentFactors from './CardAssessmentFactors';

const styles = theme => ({
	desktopPadding: {
	 	minHeight: 40,
    },
    listItem: {
        boxShadow: 1,
        border: '0px',
        borderRadius: theme.borderRadius,
    },
});

class CardProgramProgress extends Component {
    //static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = { 
            loading: true
        };
    } 

    componentDidMount() {
        this._isMounted = true;
        //console.log('props', this.props)
        //loaded by parent now
        //LoadStateEngagementStats(this, this.props.user.uid);
        //this.loadLatestBatch() ;
        this.loadCategoryAndQualities();
        if(this.props.user!== undefined && this.props.user.uid !== undefined){
            if(false && this.props.engagementStats !== undefined && this.props.engagementStats.total !== undefined){
                this.setState({engagementStats: this.props.engagementStats})
            }
            else{
                LoadStateEngagementStats(this, this.props.user.uid, true);
            }
            //console.log('loadstats', this.state)
            this.loadPersonalityTrait(this.props.user.uid)
        }
        else if(this.props.engagementStats?.engagements?.total !== undefined){
            //console.log('found', this.props)
            this.setState({
                mainCategory: this.props.engagementStats?.engagements?.['1stRank']?.leadership_category?.value || 'priority',
                mainQuality: [this.props.engagementStats?.engagements?.['1stRank']?.leadership_quality?.valueID] || ['discipline'],
                engagementStats: this.props.engagementStats
            })
        }
    }
    componentWillUnmount (){
        this._isMounted = false;
    }
    componentDidUpdate(prevProps)  {
        var newValues = '';
        var oldValues = '';
        if(this.props.user !== undefined && this.props.user.currentLeadershipQuality !== ""){
            newValues = this.props.user.currentLeadershipQuality 
        }
        if(prevProps.user !== undefined && prevProps.user.currentLeadershipQuality  !== ""){
            oldValues = prevProps.user.currentLeadershipQuality 
        }
        if(newValues !== oldValues && newValues!== "" && oldValues!=="") {
            this.loadCategoryAndQualities();
            //this.loadLatestBatch() ;
            //console.log('leadershipquaity changes', this.props.user.currentLeadershipQuality )
        }
        if(this.props.user?.userAssessmentUpdatedAt !== undefined && prevProps?.user?.userAssessmentUpdatedAt !== undefined && this.props.user.userAssessmentUpdatedAt !== prevProps.user.userAssessmentUpdatedAt){
            //console.log('reload personality traits')
            this.loadPersonalityTrait(this.props.user.uid)
        }
        return true
    }
    shouldComponentUpdate(nextProps, nextState) {  
        if (nextProps.rerender === false) {  
            //console.log('no rerender')
            return false;  
        }  
        //console.log(' rerender')
        return true;  
    } 

    loadCategoryAndQualities() {
        if(this._isMounted && this.props.user?.uid){
            var userGrowthPath = LoadUserCategoryAndQualities(this, this.props.user.uid, this.props.user, this.props.leadershipCategories, this.props.leadershipQualities)
            //console.log('userGrowthPath', userGrowthPath)
            if(this._isMounted && userGrowthPath !== undefined ){
                this.setState({
                    ...userGrowthPath.oldCommentList,
                    mainCategory: userGrowthPath.mainCategory, 
                    mainQuality: userGrowthPath.mainQuality, 
                    programQualities: userGrowthPath.programQualities, 
                    mainQualitiesDicts: userGrowthPath.mainQualitiesDicts , 
                    sisterQualities: userGrowthPath.sisterQualities
                });
            }
        }
    }

    loadPersonalityTrait(userId)  {
        var personalityTraits = [50,50,50,50,50];
        var permaFactors = [0,0,0,0,0,0];
        this.props.firebase
        .personalityTrait(userId)
        .get()
        .then(snapshot => {
        //.onSnapshot(snapshot => {
            if (!snapshot.empty) {
                var userData = snapshot.data();
                if(this._isMounted) {
                    var userTraits = {};
                    if(userData !== undefined && userData.userAssessment !== undefined ){
                        userTraits = userData.userAssessment;
                        if(userTraits !== undefined && userTraits["O"] !== undefined){
                            personalityTraits = [
                                userTraits['O'] || 50,
                                userTraits['C'] || 50,
                                userTraits['E'] || 50,
                                userTraits['A'] || 50,
                                //Math.abs(100- userTraits['N'] )
                                userTraits['N'] || 50
                            ]
                        }
                        if(userTraits !== undefined && userTraits["pP"] !== undefined){
                            permaFactors = [
                                userTraits['pP'] || 0,
                                userTraits['pE'] || 0,
                                userTraits['pR'] || 0,
                                userTraits['pM'] || 0,
                                userTraits['pA'] || 0,
                                userTraits['pV'] || 0
                            ]
                        }
                    }
                }
                //console.log('traits loaded', userData)
            }
            if(this._isMounted) {
                this.setState({personalityTraits, permaFactors})
            }
        });
    }  
    
    loadLatestBatch(){
        if(this._isMounted) SetStateLoadingBatches(this, this.props.firebase, this.props.user.uid,  this.props.user.currentCategory, 10)
    }

    render() {
        //console.log('state', this.state)
        const { theme } = this.props;
        const {mainCategory, mainQuality, mainQualitiesDicts, relatedQualities} = this.state;
        const {engagementStats} = this.state;
        return (
            <div>
                {mainCategory!==undefined && mainCategory!==null
                    ?
                        <div style={{position: 'relative'}}>
                            <h4>
                                Engagement
                            </h4>
                            {this.props.tutorialOpen 
                            &&
                                <div style={{position: 'absolute', top: -4, right: -6}}>
                                    <IconButton style={{marginRight: 0}} onClick={() => {if(this.props.tutorialOpen) this.props.tutorialOpen('mission')}}>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </div>
                            }
                            <p>
                                {this.state.badgesListComplete !== undefined && this.state.badgesListComplete.length > 0
                                    ? this.state.badgesListComplete[0].title 
                                    : mainCategory.explanationShort
                                }
                            </p>
                        </div>
                    : 
                        <h4 style={theme.CardContent.titleText}>
                            Progress
                        </h4>
                }
                {mainQuality!==undefined && mainQuality!==null
                    &&
                    <>
                        <p style={theme.textSupportCenter}>
                            Engagement score &bull; Trending mindset
                        </p>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12}>
                                {this.props.user === undefined && this.props.engagementStats?.engagements?.total !== undefined
                                ?   this.renderProgram(mainCategory, mainQualitiesDicts, this.props.engagementStats.engagements, 'My Program') 
                                :   this.props.user?.programs?.includes('default')
                                    ?   this.renderProgram(mainCategory, mainQualitiesDicts, engagementStats, 'My Program') 
                                    :   mainQualitiesDicts!==undefined && mainQualitiesDicts.length>0
                                        ?   this.renderQualities(mainCategory, mainQualitiesDicts, engagementStats, 'My Modules') 
                                        :   "...loading"
                                }
                            </Grid>
                            {this.props.user?.uid?.length > 2
                            &&
                                <Grid item xs={12}>
                                    <Task4Topics
                                        user={this.props.user}
                                        isCoach={this.props.isCoach || false}
                                        noMargin={true}
                                        statspage={true}
                                        engagementStats={engagementStats}
                                        engagementBenchmarkQuality= {400}
                                        leadershipCategories={this.props.leadershipCategories}
                                        leadershipQualities={this.props.leadershipQualities} 
                                    />
                                </Grid>
                            }
                            {engagementStats?.sentiments?.latest !== undefined &&
                                <Grid item xs> 
                                    <CardSentimentGauge
                                        user={this.props.user || undefined}
                                        title={'Average Mindset'}
                                        sentiments={engagementStats.sentiments.latest}
                                        tutorialOpen={
                                            this.props.tutorialOpen !== undefined
                                            ?   this.props.tutorialOpen
                                            :   undefined
                                        }
                                    />
                                </Grid>
                            }
                            {engagementStats?.sentiments?.latest !== undefined
                                &&   
                                <Grid item xs> 
                                    <CardLatestSentiments
                                        user={this.props.user || undefined}
                                        title={'Monthly Trending Topics'}
                                        sentiments={engagementStats.sentiments.latest}
                                        tutorialOpen={
                                            this.props.tutorialOpen !== undefined
                                            ?   this.props.tutorialOpen
                                            :   undefined
                                        }
                                    />
                                </Grid>
                            }
                            {false && engagementStats?.sentiments?.latest !== undefined
                                &&   
                                <Grid item xs={12}> 
                                    <CardLatestSentiments
                                        user={this.props.user || undefined}
                                        title={'Top Modules'}
                                        variant
                                        sentiments={engagementStats.sentiments}
                                        tutorialOpen={
                                            this.props.tutorialOpen !== undefined
                                            ?   this.props.tutorialOpen
                                            :   undefined
                                        }
                                    />
                                </Grid>
                            }
                        </Grid>
                    </>
                }
                
                {false && relatedQualities!==undefined && relatedQualities.length>0
                    &&
                    <Card  style={theme.CardTop} key={'oter_items'}>
                        { this.renderQualityOverview(relatedQualities, engagementStats) }
                    </Card>
                }
            </div>
            
        )
    }


    renderQualities (mainCategory, mainQualitiesDicts, engagementStats) {
        const { theme } = this.props;
        var maxPoints = 400;
        if(this.props.user?.engagementCounter <= 400){
            maxPoints = 200;
        }
        //console.log('main', mainQualitiesDicts, engagementStats)
        var engagementTypes =[0,0,0,0];
        var engagementLabels = ['', '', '', ''];
        var engagementColors = [];
        if (mainQualitiesDicts!==undefined && mainQualitiesDicts!==null && mainQualitiesDicts.length>=1){
            for (var index in mainQualitiesDicts){
                //console.log(key, index)
                if(mainQualitiesDicts[index]!== undefined && mainQualitiesDicts[index]!== null){
                    if(engagementStats!==undefined && engagementStats['leadership_quality'] !==undefined && engagementStats['leadership_quality'][mainQualitiesDicts[index].valueID] !==undefined ){
                        engagementTypes[index] = engagementStats['leadership_quality'][mainQualitiesDicts[index].valueID]["points"];
                        if(engagementTypes[index] < 1) {
                            engagementTypes[index] = 1;
                        }
                        else if(engagementTypes[index] > maxPoints) {
                            engagementTypes[index] = maxPoints;
                        }
                        //engagementLabels[index] = mainQualitiesDicts[index].text + " " + engagementTypes[index] + " XP";
                        engagementLabels[index] = mainQualitiesDicts[index].text ;
                        if(mainQualitiesDicts[index]?.backgroundColor?.length>0){
                            //console.log('mainQualitiesDicts[index].backgroundColor', mainQualitiesDicts[index])
                            engagementColors[index] = mainQualitiesDicts[index].backgroundColor+"7D";
                        }
                        else engagementColors[index] = theme.palette.secondary.light+"7D";
                    }
                    else{
                        engagementTypes[index] = 1
                        engagementLabels[index] = mainQualitiesDicts[index].text;
                        if(mainQualitiesDicts[index]?.backgroundColor?.length>0){
                            engagementColors[index] = mainQualitiesDicts[index].backgroundColor+"7D";
                        }
                        else engagementColors[index] = theme.palette.secondary.light+"7D";
                    }
                }
                //console.log('engagementColors', engagementColors)
            }
        }

        //console.log("engage", engagementTypes)
        if(mainCategory!== undefined){
            return (
                <div style={{height:"auto", maxHeight: 250 }}>
                    <Card  style={theme.CardGoals}>
                        <div style={{padding:10 }}>
                            <strong>Coaching Plan Modules</strong>
                        </div>
                        <div onClick={() => this.setState({dialogOpenProgressTopics: true})}
                            style={{paddingBottom:14, textAlign: ''}}
                        >   
                            {/**
                            <ProgressRadar
                                isCoach = {this.props.isCoach || false}
                                values={engagementTypes}
                                labels={engagementLabels}
                                //colorMain={mainCategory.backgroundColor}
                                colorMain={theme.palette.themeBlue}
                                //colorBackGround={mainCategory.backgroundColor + "44"}
                                colorBackGround={theme.palette.themeBlue+ "44"}
                            />
                             */}
                            <ProgressBars
                                type={'horizontal'}
                                values={engagementTypes}
                                labels={engagementLabels}
                                displayValues={false}
                                displayPercentage={true}
                                suggestedMax={maxPoints}
                                //colorMiddle={theme.palette.themeBlue}
                                colorMain={engagementColors}
                                //colorMain={theme.palette.themeBlue+'44'}
                                //colorBackGround={theme.palette.themeBlue+ "77"}
                            />
                        </div>
                        <p style={theme.textSupportCenter}>Overview Module Interaction</p>
                    </Card>
                    {(true || this.props.isCoach)
                        ?
                            <DialogQuickTopicSetting 
                                isCoach = {this.props.isCoach || false}
                                openCurrentAchievementGoal = {this.state.dialogOpenProgressTopics || false}
                                handleCurrentAchievementGoalClose = {
                                    () => {
                                        this.setState({dialogOpenProgressTopics: false});
                                    }
                                }
                                user={this.props.user}
                                screen={'dekstop'}
                                leadershipCategories = {this.props.leadershipCategories}
                                leadershipQualities = {this.props.leadershipQualities}
                            />
                             
                        :
                            <DialogProgramItems 
                                user={this.props.user}
                                handleOpen={this.state.dialogOpenProgressTopics || false}
                                handleClose={() => {if(this._isMounted) this.setState({dialogOpenProgressTopics:false})}}
                                leadershipCategories = {this.props.leadershipCategories}
                                leadershipQualities = {this.props.leadershipQualities}
                                selectedQualities={mainQualitiesDicts}
                                engagementBenchmarkQuality = {400} 
                                engagementStats={engagementStats}
                                title = {'My Modules'}
                            />
                    }
                </div>
            )
        }
        else {
            return ""
        }
    }

    renderProgram (mainCategory, mainQualitiesDicts, engagementStats) {
        const { theme } = this.props;
        var maxPoints = 800;
        var level = "Level ③";
        if(this.props.user?.engagementCounter <= 400){
            maxPoints = 200;
            level = "Level ①";
        }
        else if(this.props.user?.engagementCounter <= 800){
            maxPoints = 400;
            level = "Level ②";
        }
        else if(this.props.user?.engagementCounter > 2000){
            maxPoints = 1200;
            level = "Level ④";
        }
        //console.log('main', mainQualitiesDicts, engagementStats, this.props.leadershipCategories)
        var engagementTypes =[0,0,0,0];
        var engagementLabels = ['', '', '', ''];
        var engagementColors = [];
        var engagementColorBack = [];
        var permaCategories = ['confidence', 'priority', 'people', 'clarity', 'communication', 'balance'];
        var applyPermaInitValues = false;
        var permaInitValues = [];
        //console.log('initial peram', permaInitValues)
        this.state.permaFactors?.map((value, index) => {
            if(value>0){
                //// we are making hald the max score and will then half the actual value so that a complete picture comes along
                permaInitValues.push(value/100*maxPoints/2);
                applyPermaInitValues = true;
            }
            else{
                permaInitValues.push(0);
            }
            return true;
        })
        //console.log('adjusted peram', permaInitValues)
        if (this.props.user?.currentCategory && !permaCategories.includes(this.props.user?.currentCategory)){
            permaCategories = [this.props.user.currentCategory].concat(permaCategories);
            if(permaInitValues !== undefined && applyPermaInitValues){
                /// push an empty item on top
                permaInitValues.unshift(0);
            }
        }
        if (permaCategories?.length>=1){
            for (var index in permaCategories){
                var bgColor = undefined;
                //console.log('index', index, permaCategories[index])
                if(engagementStats!==undefined && engagementStats['leadership_category'] !==undefined && engagementStats['leadership_category'][permaCategories[index]] !==undefined ){
                    engagementTypes[index] = engagementStats['leadership_category'][permaCategories[index]]["points"];
                    if(engagementTypes[index] < 1) {
                        engagementTypes[index] = 1;
                    }
                    else if(engagementTypes[index] > maxPoints) {
                        engagementTypes[index] = maxPoints;
                        if(this.props.engagementDivider > 1){
                            ///// For group measures
                            engagementTypes[index] = maxPoints/this.props.engagementDivider;
                        }
                    }
                    if(applyPermaInitValues && permaInitValues[index]>0){
                        //// we are making hald the max score and will then half the actual value so that a complete picture comes along
                        engagementTypes[index] = engagementTypes[index]/2;
                    }
                    engagementLabels[index] = FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, permaCategories[index]);
                    bgColor = FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, permaCategories[index], 'backgroundColor');
                    if(bgColor?.[0] === "#"){
                        engagementColors[index] = bgColor+"7D";
                        engagementColorBack[index] = bgColor;
                    }
                    else {
                        engagementColors[index] = theme.palette.secondary.light+"7D";
                        engagementColorBack[index] = theme.palette.secondary.light;
                    }
                }
                else{
                    engagementTypes[index] = 1
                    engagementLabels[index] = FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, permaCategories[index]);
                    bgColor = FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, permaCategories[index], 'backgroundColor');
                    if(bgColor?.[0] === "#"){
                        engagementColors[index] = bgColor+"7D";
                        engagementColorBack[index] = bgColor;
                    }
                    else {
                        engagementColors[index] = theme.palette.secondary.light+"7D";
                        engagementColorBack[index] = theme.palette.secondary.light;
                    }
                }
            }
        }

        //console.log("engage", engagementTypes)
        if(mainCategory!== undefined){
            return (
                <div style={{height:"auto", maxHeight: 250 }}>
                    <Card style={theme.CardGoals}
                        onClick={() => {
                            if(this.props.handlerNext !== undefined){
                                this.props.handlerNext();
                            }
                            //this.setState({dialogOpenProgressTopics: true})
                        }}
                        >
                        <div style={{padding:10 }}>
                            <strong>Optimal Human Thriving {level}</strong>
                        </div>
                        <div 
                            style={{paddingBottom:14, textAlign: ''}}
                        >
                            <ProgressBars
                                type={'horizontal'}
                                suggestedMax={maxPoints}
                                //suggestedStart={0}
                                //itemColor={engagementColors}
                                values={permaInitValues || undefined}
                                valuesInMiddle = {engagementTypes}
                                //valuesInBack = {permaInitValues || undefined}
                                displayValues={false}
                                displayPercentage={true}
                                labels={engagementLabels}
                                //colorMiddle={theme.palette.themeBlue}
                                colorMain={engagementColorBack}
                                colorMiddle={engagementColors}
                            />
                        </div>
                        <p style={{...theme.textCenter, color: theme.palette.secondary.main}}>Assess Growth Pillars</p>
                    </Card>
                    {
                    /***
                    (true || this.props.isCoach)
                        ?
                            <DialogQuickTopicSetting 
                                isCoach = {this.props.isCoach || false}
                                openCurrentAchievementGoal = {this.state.dialogOpenProgressTopics || false}
                                handleCurrentAchievementGoalClose = {
                                    () => {
                                        this.setState({dialogOpenProgressTopics: false});
                                    }
                                }
                                user={this.props.user}
                                screen={'dekstop'}
                                leadershipCategories = {this.props.leadershipCategories}
                                leadershipQualities = {this.props.leadershipQualities}
                            />
                             
                        :
                            <DialogProgramItems 
                                user={this.props.user}
                                handleOpen={this.state.dialogOpenProgressTopics || false}
                                handleClose={() => {if(this._isMounted) this.setState({dialogOpenProgressTopics:false})}}
                                leadershipCategories = {this.props.leadershipCategories}
                                leadershipQualities = {this.props.leadershipQualities}
                                selectedQualities={mainQualitiesDicts}
                                engagementBenchmarkQuality = {600} 
                                engagementStats={engagementStats}
                                title = {'My Modules'}
                            />
                     */
                    }
                </div>
            )
        }
        else {
            return ""
        }
    }

    handlerUpdate = (userTraits) => {
        //console.log('main update', userTraits)
        //invert Neuroticm
        //userTraits["N"]  = Math.abs(100- userTraits["N"] )
        this.setState({
            openBigFive: false, 
            //personalityTraits : userTraits
        })
    }

}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true}),
)(CardProgramProgress);
