import { QualityID } from "./LoadCategoriesAndQualities";

export function AddEngagementPoints(db, engagementData) {
    if(engagementData.userId!== undefined && engagementData.userId !=='' ){
        //console.log('data', this.props.contentData)
        var storeDate = engagementData;
        const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const timestamp = db.fieldValue.serverTimestamp();
        var selectDate = new Date();
        var myDay = weekdays[selectDate.getDay()]
        var dateString = 
                selectDate.getFullYear() +"-"+
                ("0" +  (selectDate.getMonth()+1)).slice(-2) +"-"+ 
                ("0" + selectDate.getDate()).slice(-2) + " " + 
                weekdays[selectDate.getDay()] + " " + 
                ("0" + selectDate.getHours()).slice(-2) + ":" + 
                ("0" + selectDate.getMinutes()).slice(-2) + ":" + 
                ("0" + selectDate.getSeconds()).slice(-2);
        
        if(engagementData.points === undefined || engagementData.points <=0  ){
            storeDate['points'] = 1;
        }
        if(engagementData.leadership_quality !== undefined  && typeof engagementData.leadership_quality === 'string'  ){
            storeDate['leadership_quality'] = [QualityID( engagementData.leadership_quality)];
        }
        storeDate['createdAt'] =  timestamp;
        storeDate['day'] =  myDay;
        storeDate['date'] =  dateString;

        //console.log('engage', storeDate)
        db.insightsEngagement().add(storeDate);
    }
    else{
        console.log('Missing user Id for engagement score')
    }

}