import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import withStyles from '@material-ui/core/styles/withStyles';

import {IsMobileDevice} from '../Modules/IsMobileDevice';


///// Classical List
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import AvatarImage from '../Modules/AvatarImage';


import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import { QualityID } from '../Modules/LoadCategoriesAndQualities';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        backgroundColor: 'transparent',
    },
});

class DialogProgramItems extends Component { 
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.setState({
            loading: false,
        });
    }
    componentWillUnmount(){
        this._isMounted = false;
    }
    
    render(){
        const { theme } = this.props;
        return(
            <RemoveScroll enabled={this.props.handleOpen === undefined ? false : this.props.handleOpen}>
                <Dialog
                    open={this.props.handleOpen === undefined ? false : this.props.handleOpen}
                    onClose={this.props.handleClose}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                    >
                    <DialogTitle style={theme.dialogTitle}>

                        <Grid container spacing={0} direction="row"
                            justifyContent="space-between"
                            alignItems="center">
                            <Grid item >
                                <IconButton onClick={this.props.handleClose}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <span style={theme.dialogTitle.titleText}>
                                    Module Completion
                                </span>
                            </Grid>
                            <Grid item>
                                <span style={theme.dialogTitle.titleLink} onClick={this.props.handleClose}>
                                    Close
                                </span>
                            </Grid>
                        </Grid>
                        {/**
                        <IconButton onClick={this.props.handleClose}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span style={theme.dialogTitle.titleText}>My Milestones</span>
                        */}
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent}>
                        {this.props.handleOpen
                            &&
                            this.renderQualityOverview(this.props.selectedQualities, this.props.engagementStats, this.props.title)
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )// end return
    }//end render

    renderQualityOverview(selectedQualities, engagementStats, title) {
        const {theme} = this.props;
        //console.log('ready to render qualities', selectedQualities, engagementStats)
        if (selectedQualities!==undefined && selectedQualities!==null && selectedQualities.length>=1){
            return(
                <div>
                    <h4>
                        {title }
                    </h4>
                    {selectedQualities.map((entry,index) => {
                        var catText = ""
                        try{
                            catText = this.props.leadershipCategories.find(c => c.value === entry.category).text + " "
                        }
                        catch (err) {
                            console.log('issue with finding the cat name');
                        }
                        if (entry.active) {
                            return (
                                <List style={{padding:0, paddingTop: 20, margin:0}} key={"qualities_"+index}>
                                    { entry.level!==undefined && entry.level > 0
                                        && 
                                        <>  {catText} 
                                            Quest:&nbsp;{ entry.level}
                                            <span style={{fontSize: 'small', float: 'right'}}>
                                                {engagementStats?.['leadership_quality']?.[entry.valueID]?.["points"] > 0
                                                    &&
                                                    " (" + 
                                                        Math.min(Math.round(engagementStats['leadership_quality'][entry.valueID]["points"] / this.props.engagementBenchmarkQuality * 100 ), 100)
                                                        + "% completed)"
                                                                
                                                }
                                            </span>
                                        </>
                                    }
                                    {engagementStats!==undefined && engagementStats['leadership_quality'] !==undefined && engagementStats['leadership_quality'][entry.valueID] !==undefined 
                                        &&
                                        <LinearProgress 
                                            variant="determinate" 
                                            style={{
                                                width:'100%',
                                                height: 5,
                                            }}
                                            value={
                                                engagementStats?.['leadership_quality']?.[entry.valueID]?.["points"]
                                                    ?   Math.min((Math.round(engagementStats['leadership_quality'][entry.valueID]["points"] / this.props.engagementBenchmarkQuality * 100)), 100)
                                                    :   0
                                                } 
                                            color="secondary" 
                                            />
                                    }
                                    <ListItem style={{width: '100%', margin:0, paddingLeft:10}}>
                                        {entry.icon!== undefined && entry.icon!== ""
                                            ?
                                                <AvatarImage type={'imageIcon'} image={entry.icon} item={entry.valueID} squareSize='8vh' backgroundColor={entry.backgroundColor} />  
                                            :
                                                <AvatarImage type='quality' item={entry.valueID} squareSize='8vh' backgroundColor={entry.backgroundColor} />  
                                        }
                                        <ListItemText 
                                            style={{paddingLeft: 15, paddingTop: 0}}
                                            primary={entry.valueID === QualityID(this.props.user.currentLeadershipQuality) ? entry.text + ' (active)': entry.text }
                                            secondary=
                                                {<Typography style={{ color: theme.themeTextSecondary, width:'95%' }}>
                                                    {entry.explanationShort}
                                                </Typography>}
                                        />
                                    </ListItem>
                                </List>
                            );				
                        } else {
                            return "";
                        }
                    })}
                </div>
            )
        }
        else return ""
    }
};//end class


export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(DialogProgramItems);
