import React, { Component} from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import {IconPicker} from '../Modules/IconPicker';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';


import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    grow: {
      flexGrow: 1,
    },
  });

class JournalWeekDetail extends Component {
    static contextType = AuthUserContext;
    constructor(props) {
        super(props);
        //console.log('initia;', this.props)
        //var day
        this.state = {
            editEntry : false,
            messageId: props.messageSet.messageId,
            messages: props.messageSet,
            allMessages : props.allMessages,
            message: props.messageSet.message,
            editMessages: false,
            day: props.day,
            key: props.key,
            messageOld: props.messageSet.message,
          };
        //console.log('initia;', this.state)
    }

    showJournalText = () => {
        this.setState({
          editMessages: true,
        });
      }
    
    onChangeJournalText = event => {
        this.setState({ message: event.target.value });
    };

    saveChangeJournalText = () => {
        if(this.state.messageId !== ''){
            //console.log("message", this.state.messageId);    
            //console.log("text", this.state.message);

            //write update for the mssage itself
            const timestamp = this.props.firebase.fieldValue.serverTimestamp();
            this.props.firebase.message( this.state.messageId).set({
                changedAt: timestamp,
                text: [this.state.message],
                textOriginal: [this.state.messageOld],
            }, {merge:true }); 

            //get dialogue id and update dialogues
            if(this.state.messages.dialogueId !== '' && this.state.messages.dialogueId !== undefined){
                this.props.firebase.dialogue( this.state.messages.dialogueId).update({changedAt: timestamp}); 
                //console.log("dialpogue", this.state.messages.dialogueId );  
            }

            if(this.state.messages.date !== undefined){
                //console.log(updatedMessages, this.state.key, this.state.messages)
                // updating the message archive files
                var updatedMessages = this.state.allMessages;
                this.state.allMessages.forEach((item, index) => {
                    if (item.messageId === this.state.messageId){
                        var messageStruct = updatedMessages[index];
                        messageStruct['message'] = this.state.message;
                        updatedMessages[index] = messageStruct ;
                    }
                });
                var dateNextSat = new Date(this.state.messages.date.substring(0, 10));
                dateNextSat = this.getNextDayOfTheWeek("sat", false, dateNextSat );
                //console.log("next sat", dateNextSat, this.state.messages.createdAt)
                var dateNextSatString = 
                    dateNextSat.getFullYear() +"-"+
                    ("0" +  (dateNextSat.getMonth()+1)).slice(-2) +"-"+ 
                    ("0" + dateNextSat.getDate()).slice(-2);
    
                //console.log(updatedMessages, this.state.key, this.state.messages, dateNextSatString)
                //################ changed to pass the correct messages into the full array including the other days
                this.props.firebase.insightsWordsArchiveMessages(this.context.uid, dateNextSatString).set({
                    messages : updatedMessages,
                    }, {merge:true }); 
            }
            

            
            
        }
        
        this.setState({ editMessages : false});
        //console.log('voted up &', this.state.vote);
    };
    
    getNextDayOfTheWeek(dayName, excludeToday = true, refDate = new Date()) {
        const dayOfWeek = ["sun","mon","tue","wed","thu","fri","sat"]
                          .indexOf(dayName.slice(0,3).toLowerCase());
        if (dayOfWeek < 0) return;
        refDate.setHours(0,0,0,0);
        refDate.setDate(refDate.getDate() + !!excludeToday + 
                        (dayOfWeek + 7 - refDate.getDay() - !!excludeToday) % 7);
        return refDate;
    }

    render(){
        const { theme } = this.props;
        var messageId = this.state.messages.date.trim();
        return(
            <Grid container spacing={2}>
                <Grid item xs={2} style={theme.cardGridJournalDayHighlight}>
                    {
                        this.state.day === "Week"
                        ? this.state.messages.date.substring(6, 10) + " " + this.state.messages.date.substring(15, 20)
                        : this.state.messages.date.substring(15, 20)
                    }
                    
                   
                </Grid>
                
                <Grid item xs={10}>
                <b>{this.state.messages.questionTitle && !(this.state.messages?.questionTitle?.includes('_')) ? this.state.messages.questionTitle : 'Key thoughts'}  </b>                   
                </Grid>
                <Grid item xs={2} style={theme.cardGridJournalDayHighlight}
                >
                { IconPicker(this.state.messages.bot_utter)}
                </Grid>
                
                <Grid item xs={10}  container spacing={1} >
                
                    <Grid item xs={10}> 
                    {this.state.editMessages
                        ?
                        <form>
                            <TextField
                                //autoFocus
                                id={messageId}
                                //label="Your entry"
                                style={ {fontSize: '16px'} }
                                multiline
                                InputProps={{
                                    style:{fontSize: '16px'}
                                }}
                                value={this.state.message} 
                                variant='outlined'
                                onChange={this.onChangeJournalText}
                                fullWidth
                            />
                        </form> 
                        : <q>{this.state.message}</q>
                    }
                    </Grid>
                    <Grid item xs={2}> 
                    {this.state.messages.messageId 
                    ?
                        this.state.editMessages 
                        ?  
                            <IconButton fontSize="small" onClick={this.saveChangeJournalText}>
                                <SaveIcon fontSize="small"/>
                            </IconButton>
                        :  
                            <IconButton fontSize="small" onClick={this.showJournalText}>
                                <EditIcon fontSize="small" />
                            </IconButton>
                    : <div></div>
                    }
                    </Grid>
                
                    <Grid item xs={12}> 
                    <p style={theme.cardJournalDayHighlight.iconDesign}> {this.state.messages.question? this.state.messages.question: ''} </p>
                    </Grid>
                </Grid>
            </Grid>
        )// end return
    }//end render

}


export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
    )(JournalWeekDetail);
