import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';
import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";

import {CheckXPLevel} from '../Charts/XPLevels';
import SnackbarInfo from '../Modules/SnackbarInfo';
import BadgeCard from '../Modules/BadgeCard';
import {AvatarFoto} from '../Modules/AvatarImage';

import DialogEngagementOverview from '../Days/DialogEngagementOverview';

import {CheckSubscriptionStatus} from '../Modules/CheckSubscriptionStatus';

import CardTopicSquare from '../Goals/CardTopicSquare';
import SwipeableViews from 'react-swipeable-views';
import imageSettingQuality from "../../images/settingquality.png";
//import badgeStart from "../../images/Badge_XP_congrats.png";
import SwipeStepper, {customWidthStyle, customWidthSlideStyle} from '../Modules/SwipeStepper';
import { DataCacheRead, DataCacheWrite} from "../Modules/DataCache";

import LeadershipQualityPicker from '../Goals/LeadershipQualityPicker';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

import Grid from '@material-ui/core/Grid';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import ForumIcon from '@material-ui/icons/Forum';
import AssessmentIcon from '@material-ui/icons/Assessment';

import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';

// Help
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const styles = theme => ({
    cardActive: {
        backgroundColor: theme.palette.themeLightGrey,
        marginTop: 2,
        borderRadius: 15,
        marginBottom: 2,
        display: 'inline-block',
        maxWidth: '90%',
    },
    listItem: {
        listStyleType: "none",
        boxShadow: 1,
        border: '0px',
        borderRadius: theme.borderRadius,
    },
    listItemAddNew: {
        listStyleType: "none",
        boxShadow: 1,
        maxWidth: 200,
        maxHeight: 200,
        //opacity: '40%',
        //border: '0px',
        borderRadius: theme.borderRadius,
        height: '100%',
        borderStyle: 'dotted',
        borderColor: theme.palette.secondary.main,
        padding: 0
    },    
    avatarIcon : {
        color: theme.palette.secondary.main, 
        height: 'auto',
        width: 'auto',
        minHeight: '50%',
        minWidth: '50%',
        margin: '25%'
    }
})

class ContentQualitySwipe extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state={
            textLoading: "...",
            recentUpdatePoints: 0,
            xpLevels: {}
        }
    }

    componentDidMount() {
        this._isMounted = true;
        var cacheStore = DataCacheRead('updateQualities_'+this.props.user.uid )
        //console.log("read",cacheStore)
        if(cacheStore!== null && cacheStore.isCacheExpired !== true && cacheStore.cacheData > 0){
            this.setState({recentUpdatePoints: cacheStore.cacheData});
            this.updateScore(cacheStore.cacheData, 0);
        }
        const xpLevels = {
            modules: CheckXPLevel(this.props.user, 'modules'),
        }
        //console.log('xpLevels', xpLevels)
        this.setState({xpLevels});
        var subscriberStatus = CheckSubscriptionStatus(this.props.user);
        //console.log('xpLevels', subscriberStatus, xpLevels)
        if(subscriberStatus.nonSubscriber !== undefined){
            //console.log('extra XP subsc', subscriberStatus, xpLevels)
            this.setState({
                nonSubscriber:( subscriberStatus.nonSubscriber ),
                trialPeriod: (subscriberStatus.trialPeriod )
            });
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps)  {
        if(this._isMounted && this.props.open && prevProps.open !== true && this.state.open !== true){
            if(this.state.dialogEngagementQuality === undefined || this.state.dialogEngagementQuality === ''){
                if (this.props.mainQualitiesDicts!==undefined && this.props.mainQualitiesDicts.length>0){
                    var entry = this.props.mainQualitiesDicts[0];
                    this.setState({ 
                        dialogEngagementQuality: entry.value, 
                        dialogEngagementOpen : true, 
                        dialogEngagementText: entry.definition,
                        dialogEngagementTitle: entry.explanationShort,
                    });
                }
            }
            else{
                this.setState({dialogEngagementOpen:true})
            }
        }
        //console.log('openCsssatalogue', this.props)
        if(this._isMounted && this.props.openCatalogue && prevProps.openCatalogue !== true && this.state.openLeadershipQualityPicker !== true){
            //console.log('openCatalogue', this.props.openCatalogue)
            this.setState({ 
                openLeadershipQualityPicker : true, 
            });
        }
        if(this._isMounted && this.props.mainQualitiesDicts !== prevProps.mainQualitiesDicts && prevProps.mainQualitiesDicts !== undefined){
            this.setState({activeStep:1});
        }
        return true
    }
    shouldComponentUpdate(nextProps, nextState) {  
        if (nextProps.rerender === false) {  
            //console.log('no rerender')
            return false;  
        }  
        //console.log(' rerender')
        return true;  
    }

    render () {
        const {mainQualitiesDicts, engagementStats} = this.props;
        //console.log('selected qualities', mainQualitiesDicts, this.props)
        //console.log('props histor', this.props)
        if (mainQualitiesDicts!==undefined && mainQualitiesDicts.length>0){
            let usedQualities = mainQualitiesDicts;
            return (
                <div style={{margin:this.props.noMargin? 0 : 20}} id="growthpath">
                    {true //this.state.xpLevels?.modules?.valid !== true
                        &&
                        <SnackbarInfo 
                            history={this.props.history}
                            alertText = {this.state.alertText || 'Try to collect more XP to modify your personal Coaching Plan.'}
                            openOnClick = {true}
                            alertOpenEvent = {this.state.alertOpenEvent || false}
                            closeHandler = {() => this.setState({alertOpenEvent: false})}
                            buttonText={this.state.alertButton?.length>0 ? 'Unlock' : undefined}
                            buttonLink={{
                                pathname: ROUTES.PROFILE,
                                state: {showSubscriptions: true}
                            }}
                        />
                    }
                    <DialogEngagementOverview 
                        user={this.props.user}
                        defaultSet={this.props.defaultSet || undefined}
                        screenWidth={this.props.screenWidth || undefined}
                        handleOpen={this.state.dialogEngagementOpen || false}
                        handleClose={() => {this.setState({dialogEngagementOpen:false})}}
                        updateScore = { (score) => { this.updateScore(score) } }
                        leadershipCategories = {this.props.leadershipCategories}
                        leadershipQualities = {this.props.leadershipQualities}
                        userQualityPath = {usedQualities || undefined }
                        type={'quality'}
                        selectedItem={this.state.dialogEngagementQuality || undefined}
                        text = {this.state.dialogEngagementText || ""}
                        title = {this.state.dialogEngagementTitle || ""}
                        handlerPurchase={() => {
                            this.setState({nonSubscriber: false});
                        }}
                        nonSubscriber = {this.state.nonSubscriber}
                        trialPeriod = {this.state.trialPeriod}
                        engagementStats = {engagementStats}
                        engagementBenchmarkQuality = {400}
                        handlerContinue = {this.props.handlerContinue ? this.props.handlerContinue : undefined}
                    />
                    {
                        this.renderQualitySwipe(usedQualities, engagementStats)
                    }
                    <LeadershipQualityPicker
                        defaultSet={this.props.defaultSet || undefined}
                        scope={'current'}
                        user={this.props.user}
                        submitNewPath={this.props.handlerContinue || undefined}
                        openChooseTopic1={this.state.openLeadershipQualityPicker || false}
                        openChooseTopic2={this.state.openLeadershipQualityPicker2 || false}
                        leadershipCategories={this.props.leadershipCategories}
                        leadershipQualities={this.props.leadershipQualities}
                        refreshQualities = {this.props.refreshQualities || undefined}
                        refreshCategories = {this.props.refreshCategories || undefined}
                        engagementStats={this.props.engagementStats}
                        engagementBenchmarkQuality={400}
                        handler={() => {this.setState({  openLeadershipQualityPicker: false,  openLeadershipQualityPicker2: false })}}
                        handlerOpen2={(entry) =>  {this.setState({  openLeadershipQualityPicker: true,  openLeadershipQualityPicker2: true })}}
                        handlerClose2={() =>  {this.setState({  openLeadershipQualityPicker: true,  openLeadershipQualityPicker2: false })}}
                        handlerCloseAll={() => {this.setState({  openLeadershipQualityPicker: false,  openLeadershipQualityPicker2: false })}}
                        closeDialog={() => {}}
                    />
                </div>
            )
        }
        else return "" 
    }

    renderQualitySwipe(mainQualitiesDicts, engagementStats) {
        const {theme} = this.props;
        //console.log('renderQualitySwipe', this.state, this.props)
        if (mainQualitiesDicts!==undefined && mainQualitiesDicts!==null && mainQualitiesDicts.length>=1){
            return(
                <div>
                    {this.props.variant === 'today'
                        ?
                            <Grid container direction="row" justifyContent={"center"}  alignItems="center"  spacing={1}>
                                <Grid item > 
                                    <img src={imageSettingQuality} alt='My Current Focus' style={theme.cardSettings.iconDesign} />
                                </Grid>
                                <Grid item > 
                                    <strong>Today's Quest</strong>
                                </Grid>
                            </Grid>
                        :
                            <div style={{position: 'relative'}}>
                                <h4 style={theme.CardContent.titleText}>
                                    {this.props.title?.length > 2 ? this.props.title : 'Coaching Plan'}
                                </h4>
                                {this.props.linkToContent===true 
                                ?
                                    <div style={{position: 'absolute', top: -4, right: -6}}>
                                        <Link to={ROUTES.CONTENT}>
                                            See All &gt;
                                        </Link>
                                    </div>
                                :
                                    this.props.tutorialOpen 
                                    ?
                                        <div style={{position: 'absolute', top: -4, right: -6}}>
                                            <IconButton style={{marginRight: 0}} onClick={() => {if(this.props.tutorialOpen) this.props.tutorialOpen('path')}}>
                                                <HelpOutlineIcon />
                                            </IconButton>
                                        </div>
                                    :   ""
                                }
                            </div>
                    }
                    {(this.props.subscriber !== true) && this.props.unlockCounter > 0 && !(this.props.user?.dialogueCounter >= this.props.unlockCounter)
                    ?   <BadgeCard
                            avatarGreyOut={!(this.props.unlockCounter > 0 && !(this.props.user?.dialogueCounter >= this.props.unlockCounter)) ? false : true}
                            backgroundColor = {"white"}
                            title = {'Set-Up'}
                            subtitle = {'Personalization'}
                            strongSubtitle = {false}
                            onClick={false
                                //this.props.updateScore !== undefined && !(this.props.unlockCounter > 0 && !(this.props.user?.dialogueCounter >= this.props.unlockCounter))
                                ?   this.props.updateScore
                                :   () => this.setState({alertOpenEvent: true, alertButton: 'Unlock', alertText: 'You can change your personal development plan after subscribing or finishing '+ this.props.unlockCounter +' reflection practices.'})
                            }
                            clickText={undefined}
                            button={'Unlock'}
                        />
                    :   <div style={{marginBottom: 10}}>
                            {this.props.cardMode
                            ?
                                <Grid container direction="row" justifyContent={"center"}  alignItems="center"  spacing={1}>
                                    {(true || (this.props.user?.programs?.includes('default') || this.props.user?.programs?.length>1))
                                        &&
                                        <Grid item xs={6} style={{height: '100%'}}>
                                            {this.addNewTopicTile()}
                                        </Grid>
                                    }
                                    {mainQualitiesDicts.map((entry, index) => (
                                        <Grid item xs={6} key={"quali_"+index}
                                            onClick={() => {
                                                if(entry?.value){
                                                    if(this.props.clickAction !== undefined){
                                                        this.props.clickAction(entry.value);
                                                    }
                                                    else{
                                                        this.setState({ 
                                                            dialogEngagementQuality: entry.value, 
                                                            dialogEngagementOpen : true, 
                                                            dialogEngagementText: entry.definition,
                                                            dialogEngagementTitle: entry.explanationShort,
                                                        })
                                                    }
                                                }
                                            }} 
                                        >
                                            {entry?.value &&
                                                <CardTopicSquare
                                                    key= {entry.value+'_'+index} 
                                                    item= {entry}
                                                    type= {"leadership_quality"}
                                                    showShop= {true}
                                                    showCompletionLevel= {this.props.showCompletionLevel || false}
                                                    mainTopicFlag = {this.props.user.currentLeadershipQuality === entry.value? true : false}
                                                    todayTopicFlag = {this.props.user!== undefined && this.props.user.todaysLeadershipQuality === entry.value ? true : false}
                                                    engagementStats = {engagementStats}
                                                    engagementBenchmarkCategory = {this.state.engagementBenchmarkCategory || 400}
                                                    submitValue = {(value) => {
                                                        //console.log('valuie', value)
                                                        if(this.props.handlerContinue){
                                                            this.props.handlerContinue();
                                                        }
                                                    }}
                                                    //submitValue = {this.props.handlerContinue ? (value) => this.props.handlerContinue : undefined}
                                                    //handlerContinue = {this.props.handlerContinue ? this.props.handlerContinue : undefined}
                                                />
                                            }
                                        </Grid>
                                    ))}
                                    {false && !(this.props.user?.programs?.includes('default') || this.props.user?.programs?.length>1)
                                        &&
                                        <Grid item xs={6} style={{height: '100%'}}>
                                            {this.addNewTopicTile()}
                                        </Grid>
                                    }
                                    {mainQualitiesDicts.length > 1 && !(mainQualitiesDicts.length % 2) &&
                                        <Grid item xs={6} style={{height: '100%'}}>
                                            {(this.props.user?.roles?.includes("COACHEE") || this.props.user?.roles?.includes("OBSERVE")) 
                                                ?
                                                    this.contactCoach()
                                                :
                                                    this.helpContent()
                                            }
                                        </Grid>
                                    }
                                </Grid>
                            :
                                <>
                                    <SwipeableViews
                                        key={('reload'+(Object.keys(mainQualitiesDicts).length+1))}
                                        axis={'x'}
                                        index={this.state['activeStep']}
                                        onChangeIndex={(step) => {
                                            try{
                                                this.setState({activeStep : step});
                                            }
                                            catch (err) {console.log('Swipe Error')}
                                        }}
                                        enableMouseEvents = {this.state.allowRefresh === false? false : true}
                                        disabled = {this.state.allowRefresh === false? true : false}
                                        resistance = {true}
                                        style={customWidthStyle('40vw', '200px', (Object.keys(mainQualitiesDicts).length+2))}
                                        slideStyle={customWidthSlideStyle('40vW', '200px', (Object.keys(mainQualitiesDicts).length+2))}
                                    >
                                        {(true || (this.props.user?.programs?.includes('default') || this.props.user?.programs?.length>1))
                                            ?
                                                this.addNewTopicTile()
                                            :
                                                (this.props.user?.roles?.includes("COACHEE") || this.props.user?.roles?.includes("OBSERVE")) 
                                                    ?
                                                        this.contactCoach()
                                                    :
                                                        this.helpContent()
                                        }
                                        {mainQualitiesDicts.map((entry, index) => (
                                            <div key={"quali_"+index}
                                                onClick={() => {
                                                    if(entry?.value){
                                                        if(this.props.clickAction !== undefined){
                                                            this.props.clickAction(entry.value);
                                                        }
                                                        else{
                                                            this.setState({ 
                                                                dialogEngagementQuality: entry.value, 
                                                                dialogEngagementOpen : true, 
                                                                dialogEngagementText: entry.definition,
                                                                dialogEngagementTitle: entry.explanationShort,
                                                            })
                                                        }
                                                    }
                                                }} 
                                            >
                                                {entry?.value &&
                                                    <CardTopicSquare
                                                        key= {entry.value+'_'+index} 
                                                        item= {entry}
                                                        type= {"leadership_quality"}
                                                        showShop= {true}
                                                        showCompletionLevel= {this.props.showCompletionLevel || false}
                                                        mainTopicFlag = {this.props.user.currentLeadershipQuality === entry.value? true : false}
                                                        todayTopicFlag = {this.props.user!== undefined && this.props.user.todaysLeadershipQuality === entry.value ? true : false}
                                                        engagementStats = {engagementStats}
                                                        engagementBenchmarkCategory = {this.state.engagementBenchmarkCategory || 400}
                                                        submitValue = {(value) => {
                                                            //console.log('valuie', value)
                                                            if(this.props.handlerContinue){
                                                                this.props.handlerContinue();
                                                            }
                                                        }}
                                                        //submitValue = {this.props.handlerContinue ? (value) => this.props.handlerContinue : undefined}
                                                        //handlerContinue = {this.props.handlerContinue ? this.props.handlerContinue : undefined}
                                                    />
                                                }
                                            </div>
                                        ))
                                        }
                                        {(true || this.props.user?.programs?.includes('default') || this.props.user?.programs?.length>1)
                                            ?
                                                (this.props.user?.roles?.includes("COACHEE") || this.props.user?.roles?.includes("OBSERVE")) 
                                                    ?
                                                        this.contactCoach()
                                                    :
                                                        this.helpContent()
                                                
                                            :
                                                this.addNewTopicTile()
                                        }
                                    </SwipeableViews>
                                    <SwipeStepper
                                        handlerSteps = {(step) => this.setState({activeStep:step}) }
                                        steps={(mainQualitiesDicts.length+2) || 0}
                                        activeStep = {this.state.activeStep || 0}
                                    />
                                </>
                            }
                        </div>
                    }
                    {this.props.defaultSet?.programBrand?.length > 10 &&
                        <div style={{ alignItems:"center", verticalAlign:'middle', textAlign: 'center'}}>
                            <img
                                alt={this.props.defaultSet.programTitle}
                                style={{maxWidth: 150, maxHeight: 120}}
                                src={ this.props.defaultSet?.programBrand}
                                >
                            </img>
                        </div>
                    }
                    {(this.props.variant !== 'today' && (this.props.user.dialogueCounter === undefined || this.props.user.dialogueCounter < 5))
                        ?
                            <p style={theme.textSupportCenter}> 
                                Measurements &bull; Personalized Tips
                            </p>
                        :
                            <p style={theme.textSupportCenter}> 
                                Personal development plan &bull; Coaching quests 
                            </p>
                    }
                </div>
            )
        }
        else return ''
    }

    updateScore(newPoints, oldPoints = undefined){
        //console.log( 'd new', newPoints)
        if(newPoints !== undefined && newPoints > 0){
            if (oldPoints === undefined){
                oldPoints = this.state.recentUpdatePoints || 0 ;
            }
            var totalScore = newPoints + oldPoints;
            if (this.props.updateScore !== undefined){ 
                this.props.updateScore(totalScore) ;
                //console.log('update', totalScore);
            }
            if (totalScore !== oldPoints){
                //console.log(totalScore, oldPoints, newPoints)
                this.setState({recentUpdatePoints : totalScore});
                DataCacheWrite('updateQualities_'+this.props.user.uid , totalScore, 600)
            }
        }
    }

    addNewTopicTile(){
        const {classes, theme} = this.props;
        return(
            <div key={'add_new_topic_card'} style={{height: '100%', textAlign: 'left'}}>
                <ImageListItem key={'add_new_topic_card'} 
                    className={classes.listItemAddNew}
                    style={{
                        height: '100%',
                        backgroundColor : this.state['hoveradd'] ? theme.palette.themeAltGold : 'transparent',
                    }}
                    onMouseEnter={(this.state['hoveradd'] !== true) ? () => {this.setState({hoveradd: true})} : void(0)}
                    onMouseLeave={(this.state['hoveradd'] === true) ? () => {this.setState({hoveradd: false})} : void(0)}
                    onClick={ 
                        true
                        //this.state.xpLevels?.modules?.valid === true
                            ?
                                () => { this.setState({openLeadershipQualityPicker: true})}
                            :
                                () => this.setState({ alertOpenEvent: true, alertText: this.state.xpLevels?.modules?.text})
                    } 
                >
                    <AvatarFoto key={'find'} type='icon' image={<AddShoppingCartIcon fontSize='large' className={classes.avatarIcon}/>} item={'ddd'} squareSize='100%' backgroundColor={'transparent'} />            
                    <ImageListItemBar
                        title={(this.props.user?.programs?.includes('default') || this.props.user?.programs?.length>1) ? 'Show Programs' : 'Show Program'}
                        subtitle={<span>{this.props.user?.programs?.includes('default') ? "Add Quest" : "My Quest"}</span>}
                        style={{ borderRadius: '0 0 '+theme.borderRadius+'px '+theme.borderRadius+'px', maxHeight:"35%"}}
                        actionIcon={
                            <IconButton aria-label={`Add Quest`} >
                                <ArrowForwardIosIcon/>
                            </IconButton>
                        }
                    />
                </ImageListItem>
            </div>
        )
    }
    contactCoach(){
        const {classes, theme} = this.props;
        /***
        return(
            <div key={'contact_coach'} className={classes.listItemAddNew}>

                <div key={'contact_coach_box'} 
                    style={{height: '100%'}}
                    >
                    <Link to={ROUTES.SUPPORT}>
                    <div style={{ 
                        color: theme.palette.secondary.light,
                        height: '64%',
                        padding: 15,
                        }}> 
                        <ForumIcon fontSize='large' />
                    </div> 

                    <div style={{ 
                        color: theme.palette.secondary.light,
                        height: '36%',
                        paddingLeft: 8
                        }}> 

                        <Grid container 
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-end">
                            <Grid item xs={8} >
                                <span>Contact Coach</span>
                            </Grid>
                            <Grid item xs={4} >
                                <IconButton aria-label={'Add New Module'} >
                                    <ArrowForwardIosIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div> 
                    </Link>
                </div>
            </div>
        )
         */
        return(
            <ImageListItem key={'contact_coach'} className={classes.listItemAddNew}
                onClick={() => this.props.history.push(ROUTES.SUPPORT)}
            >
                <AvatarFoto key={'find'} type='icon' image={<ForumIcon fontSize='large' className={classes.avatarIcon}/>} item={'ddd'} squareSize='100%' backgroundColor={'transparent'} /> 
                <ImageListItemBar
                    title={'Contact Coach'}
                    subtitle={<span>Support/Help</span>}
                    style={{ borderRadius: '0 0 '+theme.borderRadius+'px '+theme.borderRadius+'px', maxHeight:"35%", maxWidth: 200}}
                    actionIcon={
                        <IconButton aria-label={`Choose assess`} >
                            <ArrowForwardIosIcon />
                        </IconButton>
                    }
                />
            </ImageListItem>
        )
    }
    helpContent(){
        const {classes, theme} = this.props;
        if(this.props.buttonClickAssessment !== undefined){
            return(
                <ImageListItem key={'contact_find'} className={classes.listItemAddNew}
                    onClick={() => { this.props.buttonClickAssessment() }}
                >
                    <AvatarFoto key={'find'} type='icon' image={<AssessmentIcon fontSize='large' className={classes.avatarIcon}/>} item={'ddd'} squareSize='100%' backgroundColor={'transparent'} /> 
                    {/***
                    <div style={{height: '100%'}}>
                        <Link to={ROUTES.SUPPORT}>
                        <div style={{ 
                            color: theme.palette.secondary.light,
                            height: '64%',
                            padding: 15,
                            }}> 
                            <ForumIcon fontSize='large' />
                        </div> 
                        </Link>
                    </div>
                    */}
                    <ImageListItemBar
                        title={'Do a Test'}
                        subtitle={<span>Self-Assessment</span>}
                        style={{ borderRadius: '0 0 '+theme.borderRadius+'px '+theme.borderRadius+'px', maxHeight:"35%", maxWidth: 200}}
                        actionIcon={
                            <IconButton aria-label={`Choose assess`} >
                                <ArrowForwardIosIcon />
                            </IconButton>
                        }
                    />
                </ImageListItem>
            )
        }
    }

    freeContent(engagementStats){
        //console.log('free', this.state, engagementStats)
        //if(this.state.nonSubscriber && this.state.trialPeriod !== true){
        if(true){
            return(
                <Grid item xs={6} key={"quali_xxxxx"}
                    onClick={() => {
                        this.setState({ 
                            dialogEngagementQuality: 'discipline', 
                            dialogEngagementOpen : true, 
                            dialogEngagementText: 'Accountability',
                            dialogEngagementTitle: 'Discipline',
                        })
                    }} 
                >
                    <CardTopicSquare
                        key= {'discipline_xxxxx'} 
                        item= {'discipline'}
                        type= {"leadership_quality"}
                        showShop= {true}
                        showCompletionLevel= {this.props.showCompletionLevel || false}
                        mainTopicFlag = {false}
                        todayTopicFlag = {false}
                        engagementStats = {engagementStats}
                        engagementBenchmarkCategory = {this.state.engagementBenchmarkCategory || 400}
                        submitValue = {(value) => {
                            //console.log('valuie', value)
                            if(this.props.handlerContinue){
                                this.props.handlerContinue();
                            }
                        }}
                    />
                </Grid>
            )
        }
    }
}

export default compose(
    withStyles(styles, { withTheme: true}),
    withFirebase,
    withRouter,
)(ContentQualitySwipe);
