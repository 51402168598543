import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';

import {StoreAppError} from '../Modules/StoreAppError';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import TextField from "@material-ui/core/TextField";

import SnackbarInfo from '../Modules/SnackbarInfo';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import FlagTwoToneIcon from '@material-ui/icons/FlagTwoTone';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});


class DialogFlagError extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            openSetting: false,
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    render() {
        return (
            <div style={{height: this.props.position==="absolute" ? 0: 'inherit'}}>
                <Tooltip title="Report Issue"
                    style={{
                        position: 'relative',
                        zIndex: 100,
                        left: 'calc(100% - 40px)',
                        color: 'transparent'
                    }}
                >
                    <IconButton 
                        size='small'
                        onClick={this.handleOpen}>
                        <FlagTwoToneIcon style={{color: 'grey'}} />
                    </IconButton>
                </Tooltip>
                <SnackbarInfo
                    key={'report'}
                    alertText = {"Thank you for reporting this issue/person!"}
                    openOnClick = {true}
                    alertOpenEvent = {this.state.openAlert || false}
                    closeHandler = {() => {this.setState({openAlert: false})}}
                />
                {this.dialogFlagMessage()}
            </div>
        );
    }

    // Username Handling
    handleOpen = () => {
        this.setState({
            open: true,
        });
    } 
    handleClose = () => {
        this.setState({
            open: false,
        });
    }
    handleSubmit = () => {
        StoreAppError(
            this.props.firebase, 
            (this.props.issue || 'unknown'), 
            (this.props.item || 'unknown'), 
            (this.state.comment || ''), 
            JSON.stringify(this.props.dict || {})
        )
        this.setState({
            open: false,
            openAlert: true
        });
    }

    handleBlockUser = (userId) => {
        this.props.firebase.user(this.context.uid).update({
            contentUsersBlocked: this.props.firebase.fieldValue.arrayUnion(userId)
        })
        this.setState({
            open: false,
            openAlert: true
        });
    }

    dialogFlagMessage() {
        const { theme } = this.props;
        //console.log('dialogFlagMessage', this.props)
        return(
            <Dialog
                open={this.state.open || false}
                onClose={this.handleClose}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>{this.props.title || 'Report Issue'}</DialogTitle>
                {this.state.open &&
                    <DialogContent>

                        {this.props.dict?.userId?.length > 2
                        &&
                            <Card style={theme.cardSettings}>
                                <p>About User: {this.props.dict?.userName?.split(" ")[0]}</p>
                                <Button onClick={() => this.handleBlockUser(this.props.dict?.userId)} style={{margin: 8}}>
                                    Block
                                </Button>
                                <Button onClick={() => {
                                        this.setState({ comment : "Report user: " + this.props.dict?.userName},
                                            () => {this.handleSubmit()}
                                        )
                                    }} 
                                    style={{margin: 8}}>
                                    Report
                                </Button>
                            </Card>
                        }
                        <Card style={theme.cardSettings}>
                            <p>Please let us know if something is wrong by clicking on submit.</p>
                            <TextField
                                name="title"
                                style={ {fontSize: '16px'} }
                                //label="Optional Comment"
                                type="text"
                                variant="outlined"
                                placeholder="Please describe the issue..."
                                fullWidth
                                multiline
                                InputProps={{
                                    style:{fontSize: '16px'}
                                }}
                                value={this.state.comment || ''}
                                onChange={(e) => {this.setState({ comment : e.target.value})}}
                            />
                        </Card>
                    </DialogContent>
                }
                <DialogActions>
                    <Button onClick={this.handleClose} style={{margin: 8}}>
                        Cancel
                    </Button>
                    <Button onClick={this.handleSubmit} style={{margin: 8}}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(DialogFlagError);
