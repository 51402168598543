import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import LoadingProgress from '../../Modules/LoadingProgress';

import { withFirebase } from '../../Firebase';
import { withAuthorization,
	 // withEmailVerification
       } from '../../Session';
import { AuthUserContext } from '../../Session';
import * as ROLES from '../../../constants/roles';
import ManageQuestions from '../../Coach/ManageQuestions';
import ManageContent from '../../Coach/ManageContent';
import AdminTrainingPage from '../../Admin/Training/AdminTrainingPage';

//import {ConvertTimestampToDateString} from '../../Modules/ConvertTimestampToDateString';

// MUI stuff
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import Table from '@material-ui/core/Table';
//import TableCell from '@material-ui/core/TableCell';
//import TableHead from '@material-ui/core/TableHead';
//import TableRow from '@material-ui/core/TableRow';
//import TableBody from '@material-ui/core/TableBody';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
//import ButtonSwitch from '@material-ui/core/Switch';
import {Column, Table, SortDirection} from 'react-virtualized';
import {  AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css'
import _ from "lodash";

//import MenuItem from '@material-ui/core/MenuItem';
//import Select from '@material-ui/core/Select';
// Icons
//import AddIcon from '@material-ui/icons/Add';

// Modules for parameter change
import IntentArrayManagement from '../Modules/IntentArrayManagement';

const styles = {
    table: {
        minWidth: 100,
    },
    subtitle: {
        marginTop: 20,
        fontWeight: 700,
    },
    addButton: {
        marginTop: 20,
    },
};


const descriptionSentiment = {
    inform_positive_short: 'A positive response or sentiment that should lead to a follow-up question.',
    inform_positive_long: 'Information about an accomplishment or intention with description. NO follow-up.',
    inform_neutral_short: 'An indication about a topic or goal is provided and requires a follow-up question',
    inform_neutral_long: 'A conclusion about a topic is provided. NO follow-up.',
    inform_negative_short: 'A negative response or sentiment that requires a follow-up question.',
    inform_negative_long: 'A failure reason is provided. NO follow-up.',
    chat: 'A question was asked.',
}

const responseIntentOptions=['utter_inform_advice', 'utter_compliment_accomplishments', 'utter_compliment_short', 'utter_affirm_challenge', 'utter_affirm_disappointment' , 'utter_affirm_indicator', 'utter_affirm_rapport_extended', 'utter_affirm_activity', 'utter_affirm_intention', 'utter_affirm_assessment']
//const responseIntentOptionsText=['Answer Question/Tips', 'Congratulate on achievements', 'Compliment on positivity', 'Understand challenge', 'Empahty on failure/dissapointemnt' , 'Short indicator', 'Provide extra conclusion', 'Understand activity', 'Understand goal/intention', 'utter_affirm_assessment']

class AdminIntentStatsPage extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        const sortBy = 'active';
        const sortDirection = SortDirection.ASC;

        this.state = {
            loading: false,
            contents: [],
            contentsTab: [],
            contentsLabels: [],
            title: '',
            author: '',
            program: '',
            intent: '',
            open: false,
            sortBy,
            sortDirection,
            descriptionSentiment,
        };
        //this.sortState = createMultiSort(this.sort);
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            loading: true,
            open: false, 
            title: '',
            author: '',
            program: '',
            showTab: '',
            errorMessage : ''
        });

        var sentiment = '';

        //this.unsubscribe = 
        this.props.firebase.messages()
            .orderBy('createdAt', 'desc')
            .limit(10000)
            .get().then(snapshot => {
        //.onSnapshot(snapshot => {
            let contentsTab = {};
            let contentsTabList = {};
            let contentsLabels = [];
            let intentsArchive = {};

            var content = {};

            snapshot.forEach(doc =>{
                if((this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM') ) && this._isMounted){
                    content = doc.data();
                    if(content.intentUser !== undefined){
                        var intentUser = content.intentUser;
                        sentiment = intentUser.sentiment || undefined;
                        var intent = intentUser.intent || undefined;
                        if(sentiment === undefined){
                            sentiment = '---undefined---'
                        }
                        if(intent === undefined){
                            intent = '---undefined---'
                        }
                        if(!(sentiment in contentsTab)){
                            contentsTab[sentiment] = [];
                            contentsTabList[sentiment]= [];
                            contentsLabels.push(sentiment );
                        }
                        var userMessage = content.message;
                        userMessage = content.text[0];
                        if(!(intent in intentsArchive)){
                            intentsArchive[intent] = {intent, sentiment, counter : 1, examples: [userMessage] };
                        }
                        else{
                            intentsArchive[intent]['counter'] = intentsArchive[intent]['counter']+=1;
                            //console.log('intentsArchive[intent]', intentsArchive[intent])
                            if(userMessage.trim() !== '' && !(intentsArchive[intent]['examples'].includes(userMessage))){
                                intentsArchive[intent]['examples'] = intentsArchive[intent]['examples'].concat( [userMessage] );
                            }
                        }
                    } // end if true
                } // end of if ADMIN
                else if(this.context.roles?.includes('COACHADMIN') && this._isMounted ){
                } // end of if ADMIN
            });//end of snapshot

            Object.keys(intentsArchive).forEach( key => {
                var sumIntent = intentsArchive[key];
                var itemSentiment = sumIntent['sentiment'];
                //console.log('intentsArchive[item]',sumIntent)
                contentsTab[itemSentiment].push(sumIntent);
                contentsTabList[itemSentiment].push({
                    active: sumIntent.active? 'Yes': 'No',
                    description : sumIntent.intent || '-',
                    intent : sumIntent.intent || '-',
                    sentiment : sumIntent.sentiment || '-',
                    qty: sumIntent.counter || 0,
                    examplesQty: sumIntent.examples?.length || 0,
                    examples: sumIntent.examples || [],
                });

            })

            //console.log('contents', contentsTabList)
            if(this.state.showTab === undefined || this.state.showTab === ''){
                this.setState({showTab: sentiment})
            }
            if(this._isMounted){
                this.setState({
                    contentsTab: contentsTab,
                    contentsTabList,
                    contentsLabels,
                    loading: false,
                });
            }
            //console.log('total',contentsTabList['total'][0])
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
        if(this.unsubscribe !== undefined) this.unsubscribe();
    }

    _sortList = ({sortBy, sortDirection}) => {
        const list = this.state.contentsTabList[this.state.showTab];
        //console.log('sortingbefore', sortBy ,sortDirection)
        let newList = _.sortBy(list, [sortBy]);
        if (sortDirection === SortDirection.DESC) {
            newList.reverse();
        }
        //console.log('sorting',newList, this.state.showTab )
        return newList;
    }

    _compareFilter = (a,b) => {
        if ( a.class.includes(this.state.classFilter) ){
            //console.log('filtering', a, b)
            return -1;
        }
        else{
            return 1;
        }
    }
    _sortListByFilter = ( () => {
        console.log('filter')
        const list = this.state.contentsTabList[this.state.showTab];
        let newList = list.sort(this._compareFilter);
        return newList;
    })

    _sort = ({sortBy, sortDirection}) => {
        const sortedList = this.state.contentsTabList;
        if(this.state.classFilter !== undefined && this.state.classFilter !== ''){
            sortedList[this.state.showTab] = this._sortListByFilter();
        }
        else{
            sortedList[this.state.showTab] = this._sortList({sortBy, sortDirection});
        }
        this.setState({sortBy, sortDirection, contentsTabList: sortedList});
    }

    handleOpen = () => {
        //console.log('handleOpen');
        this.setState({ open: true });
    };

    handleClose = () => {
        //console.log('handleClose');
        this.setState({ open: false });
    };

    handleChange = (event) => {
        //console.log([event.target.name] + ": " + event.target.value);
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleTabChange = (event, newValue) => {
        //console.log('new tab', newValue)
        this.setState({
        showTab: newValue
        });
    }

    render() {
        const { showTab, contentsTab, contentsLabels, loading } = this.state;
        //console.log('votes', maxVotes)
        return (
            <div style={{height: '100vh'}}>
                {loading && 
                    <LoadingProgress 
                        defaultSet={this.props.defaultSet}
                        type={'LoadingPageAnimated'} 
                        loadingText={"many, many, data"} 
                        firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                        allowRefresh={true}
                    />
                }
                <TextField
                    name="classFilter"
                    style={ {fontSize: '16px',  position: 'fixed', right:40} }
                    type="text"
                    label="classFilter"
                    placeholder="balance"
                    value={this.state.classFilter || ''}
                    onChange={(event) => this.setState({ classFilter : event.target.value})}
                />
                {this.state.errorMessage !== '' && 
                    <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                }
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={this.handleTabChange}
                    initialselectedindex={0}
                    value={showTab}
                >
                    {contentsLabels.length>0 && 
                        contentsLabels.map( (key) => (
                        <Tab 
                            value={key} 
                            key={key}
                            label={key + " ("+contentsTab[key].length+")"}/>
                        ))
                    }
                </Tabs>
                {this.state.descriptionSentiment !== undefined && showTab!==undefined && showTab!=='' && this.state.descriptionSentiment[showTab] !== undefined
                    &&
                    <h4 style={{color:'green'}}>
                        {this.state.descriptionSentiment[showTab] }
                    </h4>
                }
                {contentsLabels.length>0
                 &&  contentsLabels.map( (key) => (
                    
                    <div 
                    value={key} 
                    key={key} 
                    hidden={showTab !==key}>
                        <AutoSizer>
                        {({ height, width }) => (
                            <Table
                                width={width}
                                height={700}
                                headerHeight={20}
                                rowHeight={30}
                                rowCount={this.state.contentsTabList[key].length}
                                sort={this._sort}
                                sortBy={this.state.sortBy}
                                sortDirection={this.state.sortDirection}
                                rowGetter={({index}) => { 
                                    if(this.state.classFilter === undefined || this.state.classFilter === ''){
                                        return this.state.contentsTabList[key][index];
                                    }
                                    else{
                                        if(this.state.contentsTabList[key][index].class !== undefined &&
                                            this.state.contentsTabList[key][index].class.includes(this.state.classFilter )){
                                            //console.log(this.state.contentsTabList[key][index])
                                            return this.state.contentsTabList[key][index]
                                        }
                                        else return false
                                    }
                                }}
                                onRowClick={({ event, index, rowData })  => {this.setState({
                                    content: this.state.contentsTabList[key][index],
                                    dialogOpen:true})}}
                            >
                                <Column label="Active" dataKey="active" width={80} disableSort={false}  />
                                <Column label="Description" dataKey="description" width={350} disableSort={false} />
                                <Column label="# Number" dataKey="qty" width={80} disableSort={false} />
                                <Column label="Examples" dataKey="examplesQty" width={80} disableSort={false} />
                            </Table>
                        )}
                        </AutoSizer>
                    </div>
                ))
                }
                    <QuestionIntentsItemDialog 
                        content={this.state.content}
                        sentiment={this.state.content?.sentiment || undefined }
                        intent={this.state.content?.intent || undefined}
                        openDialog={this.state.dialogOpen|| false}
                        closeDialog={(e) => {this.setState({dialogOpen : false })}}
                    />
                </div>
        );
    }
}


class QuestionIntentsItemBaseDialog extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        var errorMessage = '';
        var contentsLabels = ['total'];
        this.state = {
            loading: false,
            content: null,
            cid:'',
            contentsLabels: contentsLabels,
            errorMessage: errorMessage,
            descriptionSentiment
        };
    }
  
    componentDidMount() {
        this._isMounted = true;
        //    this.getleadershipCategoriesArray();
        //    this.getleadershipQualitiesArray();
        //    this.getBotUtterArray();
    }

    componentWillUnmount() {
        this.setState({content:null});
    }
  
  
    render() {
        const { loading } = this.state;
        const { content } = this.props;
        //console.log('my context', this.context)
        return (
            <div>
                <Dialog
                    open={this.props.openDialog}
                    onClose={this.props.closeDialog}
                    >
                    <DialogTitle >
                        <IconButton onClick={this.props.closeDialog}>
                            (X)
                        </IconButton>
                        Check Intent
                    </DialogTitle>
                    <DialogContent >
                        <div>
                        <h5 style={styles.subtitle}>Intent Response Class ({this.props.contentId})</h5>
                        {loading && 
                            <LoadingProgress 
                                defaultSet={this.props.defaultSet}
                                type={'LoadingPageAnimated'} 
                                loadingText={"many, many, data"} 
                                firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                                allowRefresh={true}
                            />
                        }
                
                        {this.state.errorMessage !== '' && 
                            <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                        }
                        {content && (
                            <div
                                key={this.state.cid+''}
                            >
                                <p>
                                    Training data examples are the responses given by users of the chatbot. The do not need correct grammar.
                                    Based on traning data the AI is able to improve language understanding.
                                </p>
                                <IntentArrayManagement
                                    key={this.state.cid}
                                    //dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)}
                                    title={"Training data examples: "  + (content.accuracy !== undefined ? (content.accuracy.examples *100 + '%') : '')} 
                                    content={content} 
                                    params={['examples']}
                                    addNewButton={true}
                                    sorting={true}
                                    showShort={true}
                                    addOnly={false}
                                    noLabel={true}
                                    searchIntents={true}
                                    //intentId = {this.state.cid}
                                    intentUser= {{intent: content.intent, sentiment:content.sentiment}}
                                    classFilter={this.props.classFilter}
                                    //updateAccuracy = { (accuracy) => {this.props.firebase.agentIntent(this.state.cid).update({"accuracy.examples" : accuracy, "accuracy.updatedAt" : this.props.firebase.fieldValue.serverTimestamp()})}}
                                    //updateExampleResults = { (exampleResults) => {this.setState({['exampleResults_'+this.state.cid]: exampleResults}) }}
                                    //exampleResults = {this.state['exampleResults_'+this.state.cid] || undefined }
                                />

                                &nbsp;
                                <Button style={{margin: 20}} onClick={ () => this.setState({openUtterances : true})}>
                                    Add follow-up questions
                                </Button>
                                &nbsp;
                                <Button style={{margin: 20}} onClick={ () => this.setState({openTrainingData : true})}>
                                    Check training data
                                </Button>
                            </div>
                        )}
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.openUtterances || this.state.openContent || this.state.openResponses || this.state.openTrainingData || false}
                    onClose={ () =>  this.setState({openUtterances : false, openContent : false, openResponses: false, openTrainingData: false})}
                    fullScreen={true}
                    >
                    <DialogTitle >
                        <IconButton onClick={() =>  this.setState({openUtterances : false, openContent: false, openResponses: false, openTrainingData: false})}>
                            (X)
                        </IconButton>
                        Add follow up questions / content
                        {content !== undefined && content !== null 
                            ?
                                ' > ' + content.intent + ' > ' + (content.responses!== undefined ? content.responses[0] : '')
                            :
                                ''
                        }
                        {content?.examples?.length > 0 &&
                            <span style={{color: 'red'}}>
                                <br/>
                                {content.examples.toString().substring(0, 200)} ...
                            </span>
                        }
                    </DialogTitle>
                    <DialogContent >
                        {this.state.openUtterances && content !== undefined  && content !== null 
                            &&
                            <ManageQuestions 
                                searchByIntent = {content.intent}
                                intentSamples = {content.examples}
                                simpleView = {this.props.simpleView || false}
                                leadership = {content.qualities || undefined}
                                category = {content.category || undefined}
                                leadershipCategories={this.props.leadershipCategories || []}
                                leadershipQualities={this.props.leadershipQualities || []}
                            />
                        }
                        {this.state.openTrainingData && content !== undefined  && content !== null 
                            &&
                            <AdminTrainingPage 
                                moveIntent = {content.intent}
                                moveSentiment = {content.sentiment}
                                leadership = {content.qualities || undefined}
                                category = {content.category || undefined}
                                leadershipCategories={this.props.leadershipCategories || []}
                                leadershipQualities={this.props.leadershipQualities || []}
                            />
                        }
                        {this.state.openContent && content !== undefined  && content !== null 
                            &&
                            <ManageContent
                                searchByIntent = {content.intent}
                                simpleView = {this.props.simpleView || false}
                                leadershipQualities={this.props.leadershipQualities || []}
                            />
                        }
                        {this.state.openResponses && content !== undefined  && content !== null 
                            &&
                            <ManageQuestions
                                searchUtterancesFilter = {responseIntentOptions}
                                searchByIntent = {content.intent}
                                simpleView = {this.props.simpleView || false}
                                leadership = {content.qualities || undefined}
                                category = {content.category || undefined}
                                leadershipCategories={this.props.leadershipCategories || []}
                                leadershipQualities={this.props.leadershipQualities || []}
                            />
                        }
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

AdminIntentStatsPage.propTypes = {
    classes: PropTypes.object.isRequired
}

export const QuestionIntentsItemDialog = withFirebase(QuestionIntentsItemBaseDialog);

const condition = authUser =>
    authUser && authUser.roles && (authUser.roles?.includes(ROLES.ADMIN) || authUser.roles?.includes(ROLES.TEAM) || authUser.adminIntents !== undefined);

export default compose(
    withStyles(styles),
    // withEmailVerification,
    withAuthorization(condition),
)(AdminIntentStatsPage);

