import React, { Component } from 'react';
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import JournalWeek from './JournalWeek';
import { withFirebase } from '../Firebase';
import withStyles from '@material-ui/core/styles/withStyles';
import { IsMobileDevice } from '../Modules/IsMobileDevice';
import {AvatarCard} from '../Modules/AvatarImage';
import { DataCacheRead, DataCacheWrite} from "../Modules/DataCache";
import LoadingProgress from '../Modules/LoadingProgress';
import VizSensor from 'react-visibility-sensor';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
// style elements
//import Accordion from '@material-ui/core/Accordion';
//import AccordionSummary from '@material-ui/core/AccordionSummary'
//import AccordionDetails from '@material-ui/core/AccordionActions';
//import Typography from '@material-ui/core/Typography';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import {customWidthStyle, customWidthSlideStyle} from '../Modules/SwipeStepper';
import SwipeableViews from 'react-swipeable-views';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});

class JournalListSwipe extends Component {
    _isMounted = false;
    static contextType = AuthUserContext;
    constructor(props) {
        super(props);
        //console.log('probs in JournalWeek ',this.probs);
        this.state = {
            loading: false,
            loadingText: 'loading diary',
            journalWeeks: [],
            journalMonths: [],
            journalMonthsLabels: [],
            activeStep : 0,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        if(this.props.historyLimit === 1){
            this.setState({showLastDayOnly : true})
        }

        this.setState({ loading: true });
        // weekly Journals
        var query = this.props.firebase
            .insightsWordsArchive(this.context.uid)
            .where("category","==","weeklyJournal")
        if(this.props.messageWeek!== undefined && this.props.messageWeek!== ''){
            var messageToWeekSaturday = this.props.messageWeek;
            try{
                var d = new Date(this.props.messageWeek);
                d.setDate(d.getDate() + ((7-d.getDay())%7+6) % 7);
                messageToWeekSaturday = d.toISOString().slice(0,10);
                //console.log('Now', this.props.messageWeek)
                //console.log('next sa', d)
                //console.log('to week sa', messageToWeekSaturday)
            }
            catch(e){

            }
            query = this.props.firebase
            .insightsWordsArchiveMessages(this.context.uid, messageToWeekSaturday)
        }

        ///// write into cache parameter set and Loading ///////////////////
        let messageArchive = {};
        var cacheStore = DataCacheRead('messageArchive_'+this.props.messageWeek+'_'+this.context.uid);
        if(cacheStore.cacheAvailable && cacheStore.cacheData !== undefined) {
            messageArchive = cacheStore.cacheData;
            this.setState(messageArchive);
            this.setState({loading: false});
        }
        //////////////////////////////////////////////////////////////////

        //if(this.props.historyLimit !== undefined && this.props.historyLimit > 0){
        //    query = query.limit(this.props.historyLimit);
        //}
        this.unsubscribe = query
        //.get().then(snapshot => {
        .onSnapshot(snapshot => {
            if(this._isMounted){
                if (snapshot.size) {
                    let journalWeeks = [];
                    let journalMonths = {};
                    let journalMonthsLabels = [];
                    if(this.props.monthlyView){
                        snapshot.forEach(doc => {
                            var monthlySet = doc.id.slice(0,7)
                            if(monthlySet === undefined){
                                monthlySet = 'Undefined'
                            }
                            if(!(monthlySet in journalMonths)){
                                //console.log(monthlySet)
                                //contentsTab[doc.data().category]={name:doc.data().category, contents: []};
                                journalMonths[monthlySet] = []
                                journalMonthsLabels.unshift(monthlySet);
                                this.setState({['activeStep_'+monthlySet]: 0})
                            }
                            journalMonths[monthlySet].unshift({ ...doc.data(), uid: doc.id });
                            //if(journalMonths[monthlySet].length>1){
                            //    journalWeeks.sort((a,b) => (a.uid > b.uid) ? 1 : ((b.uid > a.uid) ? -1 : 0)); 
                            //}
                        } );
                        if(this._isMounted){
                            this.setState({
                                journalMonths,
                                journalMonthsLabels,
                                loading: false,
                            });
                        }
                        messageArchive['journalMonths'] = journalMonths;
                        messageArchive['journalMonthsLabels'] = journalMonthsLabels;
                        DataCacheWrite('messageArchive_'+this.props.messageWeek+'_'+this.context.uid, messageArchive, 360)
                        //console.log(journalMonths, journalMonthsLabels)
                    }
                    else{
                        snapshot.forEach(doc =>
                            journalWeeks.unshift({ ...doc.data(), uid: doc.id })
                        );
                        //journalWeeks.sort((a,b) => (a.uid > b.uid) ? 1 : ((b.uid > a.uid) ? -1 : 0)); 
                        if(this._isMounted){
                            this.setState({
                                activeStep_recent:  0, 
                                journalWeeks,
                                loading: false,
                            });
                        }
                        messageArchive['journalWeeks'] = journalWeeks;
                        messageArchive['activeStep_recent'] = 0;
                        DataCacheWrite('messageArchive_'+this.props.messageWeek+'_'+this.context.uid, messageArchive, 360)
                    }
                    //console.log('journalweeks data',journalWeeks);
                } 
                else {
                    if(this.props.messageWeek!== undefined && this.props.messageWeek!== '' && snapshot.exists){
                        //journalWeeks.unshift({ ...snapshot.data(), uid: snapshot.id });
                        if(this._isMounted){
                            this.setState({
                                activeStep_recent:  0, 
                                journalWeeks: [{ ...snapshot.data(), uid: snapshot.id }],
                                loading: false,
                            });
                        }
                        messageArchive['journalWeeks'] = [{ ...snapshot.data(), uid: snapshot.id }];
                        messageArchive['activeStep_recent'] = 0;
                        DataCacheWrite('messageArchive_'+this.props.messageWeek+'_'+this.context.uid, messageArchive, 360)
                    }
                    else{
                        if(this._isMounted){
                            this.setState({ journalWeeks: null, loading: false });
                        }
                        //console.log('no data loaded for journal week');
                    }
                }
            }
        });  
        
    }

    componentWillUnmount() {
        this._isMounted = false;
        if(this.unsubscribe !== undefined) this.unsubscribe();
        //this.setState({ loading: false });
    }
    shouldComponentUpdate(nextProps, nextState) {  
        if (nextProps.rerender === false) {  
            //console.log('no rerender')
            return false;  
        }  
        //console.log(' rerender')
        return true;  
    }

    render(){
        var journalWeeks = this.state.journalWeeks;
        const { theme } = this.props;
        const { journalMonthsLabels, journalMonths} = this.state;
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

        if(this.props.historyLimit !== undefined && this.props.historyLimit > 0 && journalWeeks!==undefined && journalWeeks!==null){
            if(journalWeeks.length > this.props.historyLimit ){
                journalWeeks = journalWeeks.slice(0,this.props.historyLimit);
            }
        }
    
        return(
            <div>
                {this.state.loading 
                ?
                    //<AvatarCard textBlock={'Latest diary'}  title={true} type='icon' image={<AssignmentIcon />} squareSize='20' backgroundColor={'black'}/>
                    //<Skeleton variant="rect" width={IsMobileDevice()?'60vw':'70%' } height={200} />
                    <Card style={theme.CardContentItem.root}>
                        <LoadingProgress 
                            defaultSet={this.props.defaultSet}
                            type={'LoadingPageAnimated'} loadingText={"loading diary"} />
                    </Card>
                :
                    this.props.monthlyView
                        ?
                            journalMonthsLabels!==undefined && journalMonthsLabels.length>0 && 
                                journalMonthsLabels.map((label, index) => (
                                    <VizSensor 
                                        partialVisibility={true}
                                        key={label+'_'+index}
                                        onChange={(isVisible) => { 
                                            if(isVisible && this.state['isVisible_'+label]===undefined  && this._isMounted) { 
                                                this.setState({ ['isVisible_'+label] : true})
                                                //console.log('t.leadership_category', t.leadership_category)
                                            }}
                                        }
                                    >
                                        <Card key={label+'_'+index} 
                                            style={theme.CardContentItem.CardContentSpace}
                                            onClick={() => {this.setState({activeMonth:'showMonth_'+label})}}
                                        >
                                            <Grid container spacing={2} alignItems="center" style={{paddingTop: 20}}>
                                                <Grid item>
                                                    <AvatarCard  title={true} textBlock={"Diary "+label.substr(0,4)+" "+ months[parseInt(label.substr(-2))-1]}  
                                                    type='icon' image={<AssignmentIcon />} squareSize='20' backgroundColor={'black'}
                                                    />
                                                </Grid>
                                                {!IsMobileDevice() &&
                                                    <Grid item xs>
                                                        {this.state['activeStep_'+label]!== undefined && this.state['activeStep_'+label]>=1
                                                            &&
                                                                <ArrowBackIosIcon fontSize="small" onClick={() => { this.setState({ ['activeStep_'+label]: (this.state['activeStep_'+label]-1)})}} />
                                                        }
                                                    </Grid>
                                                }
                                                {!IsMobileDevice() &&
                                                    <Grid item>
                                                        {this.state['activeStep_'+label]!== undefined 
                                                            ?   (journalMonths[label].length-1) > this.state['activeStep_'+label]  &&
                                                                    <ArrowForwardIosIcon fontSize="small" onClick={() => { this.setState({ ['activeStep_'+label]: (this.state['activeStep_'+label]+1)})}}  />
                                                            :   journalMonths[label].length > 1  &&
                                                                    <ArrowForwardIosIcon fontSize="small" onClick={() => { this.setState({ ['activeStep_'+label]: 1})}}  />
                                                                
                                                        }
                                                    </Grid>
                                                }
                                            </Grid>
                                            {this.state.activeMonth === 'showMonth_'+label
                                            &&
                                                this.state['isVisible_'+label]
                                                    ?
                                                        this.renderViews(journalMonths[label], label)
                                                    :
                                                        //<Skeleton variant="rect" width={IsMobileDevice()?'60vw':'70%' } height={200} />
                                                        <div style={{marginBottom:10, color: theme.palette.secondary.main}}>Show reflections</div>
                                                
                                            }
                                        </Card>
                                    </VizSensor>
                                ))
                            
                        :
                            journalWeeks!==undefined && journalWeeks!==null
                            ?
                                <Card style={theme.CardContentItem.root}> 
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item>
                                            <AvatarCard textBlock={this.props.messageWeek?.length > 0 ? 'Chat Diary' : 'Latest diary'}  title={true} type='icon' image={<AssignmentIcon />} squareSize='20' backgroundColor={'black'}/>
                                        </Grid>
                                        {!IsMobileDevice() &&
                                        <Grid item xs>
                                            {this.state['activeStep_recent']!== undefined && this.state['activeStep_recent']>=1
                                                &&
                                                    <ArrowBackIosIcon fontSize="small" onClick={() => { this.setState({ activeStep_recent: (this.state['activeStep_recent']-1)})}} />
                                            }
                                        </Grid>
                                        }
                                        {!IsMobileDevice() &&
                                        <Grid item>
                                            {this.state['activeStep_recent']!== undefined 
                                                ?   (journalWeeks.length-1) > this.state['activeStep_recent']  &&
                                                        <ArrowForwardIosIcon fontSize="small" onClick={() => { this.setState({ activeStep_recent: (this.state['activeStep_recent']+1)})}}  />
                                                :   journalWeeks.length > 1  &&
                                                        <ArrowForwardIosIcon fontSize="small" onClick={() => { this.setState({ activeStep_recent: 1})}}  />
                                                        
                                            }
                                        </Grid>
                                        }
                                        <Grid item xs={12}>
                                            {this.renderViews(journalWeeks, 'recent')}
                                        </Grid>
                                    </Grid>
                                </Card>
                            :
                                <Card style={theme.CardContentItem.root}>
                                    No chat available at this date
                                </Card>
                
                    
                }
            </div>
        )
    }


  renderViews = (journalWeeks, keyWord) => {
    //console.log("swipe", this.state)
    return(
        <SwipeableViews
            key={keyWord}
            axis={'x'}
            index={this.state['activeStep_'+keyWord]}
            onChangeIndex={(index) => {
                try{
                    this.handleStepChange(index, keyWord)
                }
                catch (err) {console.log('Swipe Error')}
            }}
            enableMouseEvents = {this.state.open === true? false : true}
            disabled = {this.state.open === true? true : false}
            resistance = {true}
            style={customWidthStyle('80vw', this.props.messageWeek?.length > 1 ? '100%' : '300px', (journalWeeks.length))}
            slideStyle={customWidthSlideStyle('80vW', this.props.messageWeek?.length > 1 ? '100%' : '300px', (journalWeeks.length))}
        >
            {journalWeeks.map((weeks, index) => (
                weeks.messages
                    &&
                    <VizSensor 
                        partialVisibility={true}
                        key={keyWord+'_'+index}
                        onChange={(isVisible) => { 
                            if(isVisible && this.state['isVisibleItem_'+keyWord+'_'+index]===undefined && this._isMounted) { 
                                this.setState({ ['isVisibleItem_'+keyWord+'_'+index] : true})
                                //console.log('t.leadership_category', t.leadership_category)
                            }}
                        }
                    >
                        {
                            this.state['isVisibleItem_'+keyWord+'_'+index]
                            ?
                                <JournalWeek 
                                    key={keyWord+'_'+index}
                                    date = {weeks.uid}
                                    dataset = {weeks}
                                    showLastDayOnly = {this.state.showLastDayOnly? true : false}
                                    dialogOpenStatus = {(dialogOpenStatus) => {this.setState({open: dialogOpenStatus})} }
                                />
                            :
                                <Skeleton variant="rect" width={'400' } height={200} />
                        }
                        
                    </VizSensor>
            ))}
        </SwipeableViews>
    )
  }

    handleStepChange = (step, keyWord) => {
        //console.log(step, keyWord, 'handler')
        if(step !== undefined && keyWord !== undefined && this._isMounted) {
            this.setState({['activeStep_'+keyWord] : step});
        }
    };
}


export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(JournalListSwipe);
