import palette from './palette';

const muiStyle = {
    fontFamily: [
      'Proxima Nova Regular',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      color: palette.themeText,
    },
    h2: {
      color: palette.themeText,
    },
    h3: {
      color: palette.themeText,
    },
    h4: {
      color: palette.themeText,
    },
    h5: {
      color: palette.themeText,
    },
    h6: {
      color: palette.themeText,
    },
    p: {
      color: palette.themeText,
    },
    subtitle1: {
      color: palette.themeText,
      fontSize: '16px',
      letterSpacing: '-0.05px',
      lineHeight: '25px'
    },
    subtitle2: {
      color: palette.text.secondary,
      fontWeight: 400,
      fontSize: '14px',
      letterSpacing: '-0.05px',
      lineHeight: '21px'
    },
    body1: {
      color: palette.themeText,
      fontSize: '14px',
      letterSpacing: '-0.05px',
      lineHeight: '21px'
    },
    body2: {
      color: palette.themeAltGold,
      fontSize: '12px',
      letterSpacing: '-0.04px',
      lineHeight: '18px'
    },
    button: {
      color: palette.text.primary,
      fontSize: '14px',
    },
    caption: {
      color: palette.text.secondary,
      fontSize: '11px',
      letterSpacing: '0.33px',
      lineHeight: '13px'
    },
    overline: {
      color: palette.text.secondary,
      fontSize: '11px',
      fontWeight: 500,
      letterSpacing: '0.33px',
      lineHeight: '13px',
      textTransform: 'uppercase'
    }
};

export default muiStyle;