import React, { Component } from 'react';
//import { Link } from "react-router-dom";
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';

const styles = theme => ({
	desktopPadding: {
	 	minHeight: 40,
    },
    listItem: {
        boxShadow: 1,
        border: '0px',
        borderRadius: theme.borderRadius,
    },
});

class CardLatestSentiments extends Component {
    //static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = { 
            loading: true
        };
    } 

    componentDidMount() {
        this._isMounted = true;
        var sentiments = {};
        if(this.props.sentiments !== undefined){
            sentiments = this.props.sentiments;
        }
        var valuesOverview = [];
        var sentimentPositive = (sentiments?.total?.positive?.engagements || 0) + 0;
        //console.log('sentiments].positive.value', sentiments['1stRank'].positive.value)
        if(sentiments?.['1stRank']?.positive?.value){
            valuesOverview.push(sentiments['1stRank'].positive.value)
        }
        var sentimentIntention = (sentiments?.total?.intention?.engagements || 0 )+ 0;
        if(sentiments?.['1stRank']?.intention?.value){
            if(!valuesOverview.includes(sentiments['1stRank'].intention.value))
                valuesOverview.push(sentiments['1stRank'].intention.value)
            else
                sentiments['1stRank'].intention = {}
        }
        var sentimentNeutral = (sentiments?.total?.neutral?.engagements || 0) + 0;
        if(sentiments?.['1stRank']?.neutral?.value){
            if(!valuesOverview.includes(sentiments['1stRank'].neutral.value))
                valuesOverview.push(sentiments['1stRank'].neutral.value)
            else
                sentiments['1stRank'].neutral = {}
        }
        var sentimentNegative = (sentiments?.total?.negative?.engagements || 0) + 0;
        if(sentiments?.['1stRank']?.negative?.value){
            if(!valuesOverview.includes(sentiments['1stRank'].negative.value))
                valuesOverview.push(sentiments['1stRank'].negative.value)
            else
                sentiments['1stRank'].negative = {}
        }
        //console.log('valuesOverview', valuesOverview)
        var averagePoints = (0.1*sentimentNegative +0.4*sentimentNeutral + 0.6*sentimentIntention +1.4*sentimentPositive)/(sentimentNegative +sentimentNeutral +sentimentIntention +sentimentPositive);
        sentiments['averageScore'] = averagePoints;
        this.setState({sentiments});
    }
    componentWillUnmount (){
        this._isMounted = false;
    }

    render() {
        const { theme } = this.props;
        const { sentiments } = this.state;
        //console.log('sentiments', sentiments)
        return(
            <div style={{maxheight: 250, height: 'auto', minWidth: 200}}>
                <Card style={{...theme.CardGoals, height: '100%'}}
                    onClick={ () =>
                        {if(this.props.tutorialOpen !== undefined) this.props.tutorialOpen('insights'); }
                    }>
                    <div style={{padding: 10}}>
                        <strong>{this.props.title || 'Top Responses'}</strong>
                    </div>
                    <div style={{paddingLeft:10, paddingTop: 10, textTransform: 'capitalize'}}>
                        {sentiments?.['1stRank']?.positive?.value 
                            &&
                            <p>🚀&nbsp;
                                <span style={{color: theme.palette.themeAlertGreen, fontSize: sentiments?.averageScore>=0.75 ? 'larger' : 'smaller'}}>
                                    Winning:&nbsp;
                                    <strong>
                                    {sentiments?.['1stRank']?.positive?.title?.length>1
                                        ?   sentiments?.['1stRank'].positive.title
                                        :   sentiments?.['1stRank']?.positive?.value?.includes('_')
                                            ?   sentiments?.['1stRank']?.positive?.value.substring(sentiments?.['1stRank']?.positive?.value.lastIndexOf('_')+1)
                                            :   sentiments?.['1stRank']?.positive?.value
                                    }
                                    </strong>
                                </span>
                            </p>
                        }
                        {sentiments?.['1stRank']?.intention?.value && sentiments?.['1stRank']?.intention?.value !== sentiments?.['1stRank']?.positive?.value
                            && 
                            <p>🎯&nbsp;
                                <span style={{color: theme.palette.themeAlertGreen, fontSize: (sentiments?.averageScore>=0.5 && sentiments?.averageScore<0.75) ? 'larger' : 'smaller'}}>
                                    Acting:&nbsp;
                                    <strong>
                                    {sentiments?.['1stRank']?.intention?.title?.length>1
                                        ?   sentiments?.['1stRank'].intention.title
                                        :   sentiments?.['1stRank']?.intention?.value?.includes('_')
                                            ?   sentiments?.['1stRank']?.intention?.value.substring(sentiments?.['1stRank']?.intention?.value.lastIndexOf('_')+1)
                                            :   sentiments?.['1stRank']?.intention?.value
                                    }
                                    </strong>
                                </span>
                            </p>
                        }
                        {sentiments?.['1stRank']?.neutral?.value && sentiments?.['1stRank']?.positive?.value !== sentiments?.['1stRank']?.neutral?.value
                            &&
                            <p>🧭&nbsp;
                                <span style={{color: theme.palette.themeAlertOrange, fontSize: (sentiments?.averageScore>=0.2 && sentiments?.averageScore<0.5) ? 'larger' : 'smaller'}}>
                                    Thinking:&nbsp;
                                    <strong>
                                    {sentiments?.['1stRank']?.neutral?.title?.length>1
                                        ?   sentiments?.['1stRank'].neutral.title
                                        :   sentiments?.['1stRank']?.neutral?.value?.includes('_')
                                            ?   sentiments?.['1stRank']?.neutral?.value.substring(sentiments?.['1stRank']?.neutral?.value.lastIndexOf('_')+1)
                                            :   sentiments?.['1stRank']?.neutral?.value
                                    }
                                    </strong>
                                </span>
                            </p>
                        }
                        {sentiments?.['1stRank']?.negative?.value && sentiments?.['1stRank']?.negative?.value !== sentiments?.['1stRank']?.positive?.value
                            &&
                            <p>🗯️&nbsp;
                                <span style={{color: theme.palette.themeAlertRed, fontSize: sentiments?.averageScore<0.2 ? 'larger' : 'smaller'}}>
                                    Feeling:&nbsp;
                                    <strong>
                                    {sentiments?.['1stRank']?.negative?.title?.length>1
                                        ?   sentiments?.['1stRank'].negative.title
                                        :   sentiments?.['1stRank']?.negative?.value?.includes('_')
                                            ?   sentiments?.['1stRank']?.negative?.value.substring(sentiments?.['1stRank']?.negative?.value.lastIndexOf('_')+1)
                                            :   sentiments?.['1stRank']?.negative?.value
                                    }
                                    </strong>
                                </span>
                            </p>
                        }
                    </div>
                </Card>
            </div>
        )
    }
}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true}),
)(CardLatestSentiments);
