//import palette from '../palette';

function muiStyle (palette) { return {
    root: {
        colorPrimary:  palette.primary.dark,
        colorSecondary: palette.secondary.dark,
    },
    colorPrimary:  palette.primary.dark,
    colorSecondary: palette.secondary.dark,
}}

export default muiStyle;