//import palette from '../palette';

function muiStyle (palette) { return {
    root: {
        backgroundColor: palette.backPrimary
    },
    dotActive: {
        backgroundColor: palette.themeAltGold
    }
}}

export default muiStyle;