import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { AddCodeToExistingAccountSet } from "../Modules/DefaultCode";

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
//import MenuItem from '@material-ui/core/MenuItem';
//import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Switch from "react-switch";

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import GroupIcon from '@material-ui/icons/Group';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});


class SettingCode extends Component {
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);

        this.state = {
            code: '',
            openSetting: false,
        };
    }

    // Username Handling
    handleSettingOpen = (team=undefined) => {
        if(team!==undefined){
            this.setState({
                openSetting: true,
                code: team,
                removeCode: true,
                errorMessage: ''
            });
        }
        else{
            this.setState({
                openSetting: true,
                code: "",
                removeCode: false,
                errorMessage: ''
            });
        }
    }

    handleSettingChange = event => {
        var newCode = event.target.value.trim()
        if(newCode.length > 0) { 
            newCode = newCode.replace(/[^a-z0-9-]/gi, '');
            newCode = newCode.toUpperCase();
            if(newCode.charAt(0) !== '#'){
                newCode = '#'+newCode;
            }
        }
        this.setState({
            code: newCode
        });
    }

    handleSettingClose = () => {
        this.setState({
            openSetting: false,
            code: '',
            errorMessage: ''
        });
    }

    handleSettingSubmit = () => {
      if (this.state.code !== "" && this.state.code.length >=3 ){
        this.props.firebase.signupCodeByName(this.state.code).get()
        .then(doc => {
          if (doc.exists) {
            var newSet = doc.data();
            var userDataSet = this.props.user;
            userDataSet = AddCodeToExistingAccountSet(userDataSet, newSet);
            userDataSet['code'] = this.state.code;
            this.props.firebase.user(this.props.user.uid).update(userDataSet);
            //his.props.setParent(obj);
            //console.log('my array', obj);
            this.setState({errorMessage: ''});
            this.handleSettingClose();       
          } else {
            this.setState({errorMessage: 'This code does not exist or is not valid anymore'});  
          }
        });//end of fb then
      }//end of if code
      else {
        this.setState({errorMessage: 'This code is invalid'});  
      }
    }

    render() {
        const { user } = this.props;
        const { theme, classes } = this.props;

        return (
            user?.uid?.length > 4
                ?
                    <div className={classes.grow}>
                        <Card style={theme.cardSettings}>
                            <p style={theme.textSettings} onClick={() => this.handleSettingOpen()}>
                                {user.code?.length > 0
                                    ?   
                                        <span style={theme.textSettings.settingsTextValue}>
                                            {user.code}
                                        </span>
                                    :   
                                        <span style={theme.textSettings.settingsFixedTextValue}>
                                            <IconButton > 
                                                <EditIcon />
                                            </IconButton>
                                        </span>
                                } 
                                <span style={theme.textSettings.settingsTextDescription}>Add Code:</span>
                            </p>
                            <div style={{paddingTop: 30}}>
                                {(user.roles?.includes('COACHEE') || user.roles?.includes('OBSERVE'))
                                    ?   user.groups!==undefined && user.groups.length > 0
                                        ?
                                            <div>
                                                <p style={theme.textSupport}>
                                                    You are assigned to following teams in which a coach has access to your profile and personal Coaching Plan!
                                                </p>
                                                <Grid container direction='row' justifyContent="space-between" style={{width: '100%'}}>
                                                {user.groups.map((team, indexGroup) => (
                                                  <React.Fragment key={"team_"+indexGroup}>
                                                      <Grid item xs={10}>
                                                            <GroupIcon style={{...theme.inlineIconRight,color: "green"}}/>&nbsp;
                                                            {this.props.user.coachAccess &&
                                                                <RemoveRedEyeIcon style={{...theme.inlineIconRight,color: "red"}}/>
                                                            }
                                                            &nbsp;{team}
                                                      </Grid>
                                                      <Grid item xs={2}>
                                                          <div style={{fontSize:"small", color:'grey', textAlign:'center', marginRight:10}} 
                                                          >
                                                              <IconButton color="secondary" style={{padding: 2}}
                                                                onClick={() => this.handleSettingOpen(team)}>
                                                                  <HighlightOffIcon fontSize="small" style={{color: "red"}}/>
                                                              </IconButton>
                                                          </div>
                                                      </Grid>
                                                  </React.Fragment>
                                                ))}
                                                </Grid>
                                                <br/><br/>
                                                <p style={theme.textSupport}>
                                                    You can provide full chat transparency to your coach or team leader.
                                                </p>
                                                <div>
                                                    <span style={{ float: 'right'}}>
                                                        <Switch
                                                            checked={this.props.user.coachAccess ? this.props.user.coachAccess : false}
                                                            onColor={theme.palette.themeAlertRed}
                                                            onChange={(c, event, id) => {
                                                                this.props.firebase.user(this.props.user.uid).update({coachAccess: c})
                                                            }}
                                                        />
                                                    </span>
                                                    <span style={theme.textSettings.settingsTextDescription}>Share AI Chats:</span>
                                                </div>
                                            </div>
                                        :
                                            <div>
                                                Remove the coach-code and activate the standard programs.
                                                &nbsp;
                                                <p style={theme.textSettings.settingsFixedTextValue}>
                                                    <IconButton color="secondary" style={{padding: 2}}
                                                      onClick={() => this.handleSettingOpen('#ROCKYAICONTENT')}>
                                                        <HighlightOffIcon fontSize="small" style={{color: "red"}}/>
                                                    </IconButton>
                                                </p>
                                            </div>
                                    :   ((user.programs === undefined || !user.programs.includes('default')) && user.hidePublicContent !== true)
                                        ?
                                            <div>
                                                Activate access to the 'Essentials' programs.
                                                &nbsp;
                                                <p style={theme.textSettings.settingsFixedTextValue}>
                                                    <IconButton color="secondary" style={{padding: 2}}
                                                        onClick={() => this.handleSettingOpen('#ROCKYAICONTENT')}>
                                                        <HighlightOffIcon fontSize="small" style={{color: "red"}}/>
                                                    </IconButton>
                                                </p>
                                            </div>
                                        :   ''
                                }
                            </div>
                        </Card>
                        <Dialog
                            open={this.state.openSetting}
                            onClose={this.handleSettingClose}
                            fullWidth
                            maxWidth="sm">
                            <DialogTitle>My Coach-Code</DialogTitle>
                            {this.state.openSetting &&
                            <DialogContent>
                                <p style={theme.textSettings}>
                                    {this.state.code !== '' && this.state.removeCode
                                        ?   <span style={theme.textSettings.settingsTextDescription}>Remove Coach-Code: {this.state.code}</span>
                                        :   <span style={theme.textSettings.settingsTextDescription}>Have you been invited by someone and/or do you have a Coach-Code?</span>
                                    }
                                </p>
                                <form>
                                <div>
                                    {this.state.code !== '' && this.state.removeCode
                                        ?
                                            <p>
                                                Please confirm to remove you from the team: <strong>{this.state.code}</strong>
                                            </p>
                                        : 
                                            <TextField
                                                fullWidth
                                                id="code"
                                                //label="New Coach-Code"
                                                type="text"
                                                variant="outlined"
                                                style={ {fontSize: '16px'} }
                                                value={this.state.code}
                                                onChange={this.handleSettingChange}
                                            />
                                    }
                                    {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                                        ?
                                            <p style={theme.textErrorCenterPadding}>
                                                {this.state.errorMessage}
                                            </p>
                                        :   <p> </p>
                                    }
                                </div>
                                </form>
                            </DialogContent>
                            }
                            <DialogActions>
                                <Button onClick={this.handleSettingClose}>
                                  Cancel
                                </Button>
                                {this.state.code !== '' && this.state.removeCode
                                    ?
                                        <Button onClick={() => this.removeTeam(this.state.code)}>
                                            Remove
                                        </Button>
                                    :
                                        <Button onClick={this.handleSettingSubmit}>
                                            Save
                                        </Button>
                                }
                            </DialogActions>
                        </Dialog>
                    </div>
                :   <div> </div> 
        );
    }

    removeTeam(team){   
        var updateRoles = [];
        var updatePrograms = [];
        this.setState({
            errorMessage: ("Removing you from the team: " + team + " ...")
        })
        if(this.props.user.groups !== undefined && this.props.user.groups.length>1){
            this.props.firebase.user(this.props.user.uid).update({
                groups : this.props.firebase.fieldValue.arrayRemove(team)
            })
            .then(() =>
                this.handleSettingClose()
            )
        }
        else if(this.props.user.groups !== undefined && this.props.user.groups.length===1){
            updateRoles = updateRoles.concat(this.props.user.roles || []);
            updatePrograms = updatePrograms.concat(this.props.user.programs || []);
            ////// CLEAN ROLES
            if(!updateRoles.includes('default')){
                updateRoles.push('default')
            }
            var index = updateRoles.indexOf('COACHEE');
            if (index !== -1) {
                updateRoles.splice(index, 1);
            }
            var index2 = updateRoles.indexOf('OBSERVE');
            if (index2 !== -1) {
                updateRoles.splice(index2, 1);
            }
            ////// CLEAN PROGRAMS
            if(!updatePrograms.includes('default')){
                updatePrograms.push('default')
            }
            if(updateRoles.includes('REMOTECOACH') || updateRoles.includes('COACHADMIN')){
                this.props.firebase.user(this.props.user.uid).update({
                    code: '',
                    groups : this.props.firebase.fieldValue.arrayRemove(team),
                    roles: updateRoles,
                    groupCompetitionId: this.props.firebase.fieldValue.delete(),
                    programs: updatePrograms
                })
                .then(() =>
                    this.handleSettingClose()
                )
            }
            else{
                this.props.firebase.user(this.props.user.uid).update({
                    roles: updateRoles,
                    code: '',
                    groupCompetitionId: this.props.firebase.fieldValue.delete(),
                    programs: updatePrograms
                })
                .then(() =>
                    this.handleSettingClose()
                )
            }
        }
        else{
            updateRoles = updateRoles.concat(this.props.user.roles || []);
            updatePrograms = updatePrograms.concat(this.props.user.programs || []);
            ////// CLEAN ROLES
            if(!updateRoles.includes('default')){
                updateRoles.push('default')
            }
            var index3 = updateRoles.indexOf('COACHEE');
            if (index3 !== -1) {
                updateRoles.splice(index3, 1);
            }
            var index4 = updateRoles.indexOf('OBSERVE');
            if (index4 !== -1) {
                updateRoles.splice(index4, 1);
            }
            ////// CLEAN PROGRAMS
            if(!updatePrograms.includes('default')){
                updatePrograms.push('default')
            }
            this.props.firebase.user(this.props.user.uid).update({
                roles: updateRoles,
                code: '',
                groupCompetitionId: this.props.firebase.fieldValue.delete(),
                programs: updatePrograms
            })
            .then(() =>
                this.handleSettingClose()
            )
        }
    }
}

SettingCode.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(SettingCode);
