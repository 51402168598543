
//import palette from '../palette';
import typography from '../typography';

function muiStyle (palette) { return {
  root: {
    ...typography.body1,
    borderBottom: `1px solid ${palette.divider}`
  },
  head: {
    color: 'white'
  }
}}

export default muiStyle;