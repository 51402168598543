export function StoreAppError(db, errorMessage, source, data1, data2) {
    //console.log(userInfo)
    if (errorMessage === undefined) errorMessage = "undefined error"
    var userId = 'unknown'
    try{
        var userInfo = JSON.parse(localStorage.getItem('authUser'));
        userId = userInfo.uid;
    }
    catch(error){
        if(userInfo === undefined || userInfo === null) userId = 'not available';
    }
    try{
        const timestamp = db.fieldValue.serverTimestamp();
        var errorDict = {
            userId: userId,
            createdAt: timestamp
        }
        if(source !== undefined) errorDict['source'] = source;
        if(data1 !== undefined) errorDict['dataPoint1'] = data1;
        if(data2 !== undefined) errorDict['dataPoint2'] = data2;
        if(errorMessage !== undefined) errorDict['errorMessage'] = errorMessage;
        db.appErrors().add(errorDict);
    }
    catch(errorFB){
        console.log(errorFB)
    }
};