//import palette from '../palette';

function muiStyle (palette) { return {
    root: {
        color: palette.secondary.main,
    },
    gutters: {
        backgroundColor: palette.backPaper,
    }
}}

export default muiStyle;