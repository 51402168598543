import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import {RenderCoachTabs} from '../Coach/HomeCoach';
import { StoreMobileDeviceToken } from '../Modules/StoreMobileDeviceInfo';
import AppInstallLinks from '../Profile/AppInstallLinks';

import TutorialPage from '../Onboarding/TutorialPage';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import * as ROUTES from '../../constants/routes';
import {CheckXPLevel} from '../Charts/XPLevels';
import {CheckSubscriptionStateOwned} from '../Modules/CheckSubscriptionStatus';
import { AddAnalytics } from '../Modules/AddAnalytics';

import VizSensor from 'react-visibility-sensor';
import pixel from "../../images/pixel.png";

import TodayProgram from '../Home/TodayProgram';
import TodayAdd from './TodayAdd';
import SnackbarInfo from '../Modules/SnackbarInfo';
import Grid from '@material-ui/core/Grid';
import CardAssessmentShop from '../Assessments/CardAssessmentShop';
import Button from '@material-ui/core/Button';

///Badge Hotline
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import logoHotline from "../../images/Badge_hotline.png";

import LoadingProgress from '../Modules/LoadingProgress';
import Card from '@material-ui/core/Card';
import TaskNext3D from './TaskNext3D';
import Task2Assessments from './Task2Assessments';
import Task4Chats from './Task4Chats';
import Task4Topics from './Task4Topics';
import ContentQualitySwipe from '../Content/ContentQualitySwipe';
import ContentHighlights from '../Content/ContentHighlights';
import {LoadUserCategoryAndQualities} from "../Modules/LoadUserData";
import {LoadStateEngagementStats} from '../Modules/LoadStateEngagementStats';
import BadgeCard from '../Modules/BadgeCard';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import InfoIcon from '@material-ui/icons/Info';
//// tutoiral button
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DialogTutorialPage from '../Onboarding/DialogTutorialPage';

import JournalIntentionCheck from '../Days/JournalIntentionCheck';
import CardGroupRanking from '../Charts/CardGroupRanking';
import TodayCoach from './TodayCoach';

const styles = theme => ({
    desktopPadding: {
        minHeight: 40,
    },
    fabRow: {
        position: 'fixed',
        //bottom: theme.spacing(2),
        right: theme.spacing(2),
        //marginLeft: 'auto',
        //marginRight: 'auto',
        //maxWidth: 460,
        //width: '80%',
        zIndex: 100,
        transform: 'translate3d(0,0,0)',
    },
});



class HomePageAdaptive extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.dialogRef = React.createRef();
        this.actionRefUpdates = React.createRef();
        this.actionRefTopics = React.createRef();
        this.actionRefAssessment = React.createRef();
        this.actionRefStats = React.createRef();
        this.actionRefChallenges = React.createRef();
        this.state = {
            activeTab: 0,
            users: null,
            user: null,
            loading: false,
            botUtterance: '',
            dialogueStatus: '',
            checkDialogue: true,
            left: false,
            newHomeStatus: '',
            routineStatus: '',
            leadershipCategories: [],
            leadershipQualities: [],
            insights: [],
            xpLevels: {}
        };
    }

    debounce(func, wait, immediate) {
        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };

    componentDidMount() {
        this._isMounted = true;
        this.getXPLevels();
        try{
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            if(this.dialogRef !== undefined ) this.dialogRef.current.scrollIntoView({behaviour: 'smooth'});
        } catch  (err) {
            console.log('No scroll to top');
        }
        this.setState({ loading: true });
        //window.addEventListener("resize", this.updateWindowDimensionsEfficientFn);
        
        this.loadCategoriesAndQualities();
        this.setState({
            user: this.props.user,
            loading: false,
        });
        LoadStateEngagementStats(this, this.context.uid);
        AddAnalytics('screen_view', 'PageHome');
        if(!(this.context?.dialogueCounter >= 3)){
            StoreMobileDeviceToken(this.props.firebase.user(this.context.uid), true);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        //window.removeEventListener("resize", this.updateWindowDimensionsEfficientFn);
        //this.unsubscribeUser();
    }

    componentDidUpdate(prevProps)  {
        if(this._isMounted && this.props.user !== undefined  && prevProps.user !== undefined){
            if(this.props.user.currentLeadershipQuality !== prevProps.user.currentLeadershipQuality){
                this.loadCategoriesAndQualities();
            }
            else if(this.props.user.todaysLeadershipQuality !== prevProps.user.todaysLeadershipQuality){
                this.loadCategoriesAndQualities();
            }
            else if(this.props.user.additionalLeadershipQuality2 !== prevProps.user.additionalLeadershipQuality2){
                this.loadCategoriesAndQualities();
            }
            else if(this.props.user.additionalLeadershipQuality3 !== prevProps.user.additionalLeadershipQuality3){
                this.loadCategoriesAndQualities();
            }
            else if(this.props.user.additionalLeadershipQuality4 !== prevProps.user.additionalLeadershipQuality4){
                this.loadCategoriesAndQualities();
            }
            else if(this.props.user.additionalLeadershipQuality5 !== prevProps.user.additionalLeadershipQuality5){
                this.loadCategoriesAndQualities();
            }
            else if(this.props.user.additionalLeadershipQuality6 !== prevProps.user.additionalLeadershipQuality6){
                this.loadCategoriesAndQualities();
            }
            if(prevProps.user !== undefined && this.props.user.engagementCounter !== prevProps.user.engagementCounter){
                this.getXPLevels();
            }
        }
        return true
    }

    getXPLevels(){
        const xpLevels = {
            modules: CheckXPLevel(this.context, 'modules'),
            followUp: CheckXPLevel(this.context, 'followUp'),
            content: CheckXPLevel(this.context, 'content'),
            journal: CheckXPLevel(this.context, 'journal'),
            social: CheckXPLevel(this.context, 'social'),
        }
        const subscriber = CheckSubscriptionStateOwned(this.context);
        this.setState({xpLevels: xpLevels, subscriber});
        //console.log('state', subscriber, this.state)
    }

    loadCategoriesAndQualities() {
        //console.log("props", this.props);
        if(this._isMounted){
            this.setState({
                leadershipCategories: this.props.leadershipCategories,
                leadershipQualities: this.props.leadershipQualities,
            })
        }
        //console.log("props", this.props, this.state);
        var userGrowthPath = LoadUserCategoryAndQualities(this, this.context.uid, this.context, this.props.leadershipCategories, this.props.leadershipQualities)
        //console.log('userGrowthPath', userGrowthPath)
        if(this._isMounted && userGrowthPath !== undefined ){
            this.setState({
                ...userGrowthPath.oldCommentList,
                mainCategory: userGrowthPath.mainCategory, 
                mainQuality: userGrowthPath.mainQuality, 
                programQualities: userGrowthPath.programQualities, 
                mainQualitiesDicts: userGrowthPath.mainQualitiesDicts , 
                sisterQualities: userGrowthPath.sisterQualities
            });
        }
    }

    loadNews(){
        var loadedNews = [];
        //console.log('load news, ', this.context)
        this.props.firebase
        .collectionByName('newsArticles')
        .where('active', '==', true)
        .where('roles', 'array-contains-any', (this.context.roles?.length > 0 ? this.context.roles : ['default']))
        .orderBy('createdAt', 'desc')
        .limit(3)
        .get()
        .then(snapshot => {
            if (!snapshot.empty) {
                snapshot.forEach(doc => {
                    if(doc.data().position <= 2 ){
                        loadedNews.unshift({...doc.data()});
                    }
                    else{
                        loadedNews.push({...doc.data()});
                    }
                });
            }
            this.setState({ loadedNews });
        });
    }

    handleScrollTo =  (section) => {
        if(this._isMounted){
            console.log('scroll to', section)
            this.setState({['open_'+section] : true},
                () => this.setState({['open_'+section] : false}))
            let el = null;
            if(section === "Journey") {
                el = this.actionRefUpdates.current;
                this.setState({'open_Updates': true}, () => this.setState({'open_Updates': false}))
            }
            else if(section === "Modules") {
                el = this.actionRefTopics.current;
                this.setState({'open_Catalogue': true}, () => this.setState({'open_Catalogue': false}))
            }
            else if(section === "Mission") el = this.actionRefChallenges.current;
            else if(section === "Assessment") el = this.actionRefAssessment.current;
            else if(section === "Stats") el = this.actionRefStats.current;
            //window.scrollTo({behaviour: 'smooth', left: 0, top: el.offsetTop});
            if(el !== null && el !== undefined){
                if(section === "Mission"){
                    el.scrollIntoView({behaviour: 'smooth', block:'start'});
                }
                else{
                    el.scrollIntoView({behaviour: 'smooth', block:'center'});
                }
            }
        }
    }

    render() {
        const { theme } = this.props;
        //const { classes } = this.props;
        const { leadershipCategories, leadershipQualities, user, screen } = this.props;
        const { loading } = this.state;
        //console.log('screen', this.props, this.state)
        return (
            <Grid key="splitsidenavi" container direction="row" ref={this.dialogRef} id='pageroot'>
                <Grid item xs={screen==="desktop" ? 2 : false} >
                    {this._isMounted
                        ? 
                            screen==="desktop" && !(window?.location?.hash?.includes('/home'))
                                ?
                                    this.context.roles!==undefined && (this.context.roles?.includes("COACHADMIN") || this.context.roles?.includes("REMOTECOACH"))
                                        ? 
                                            <Card style={theme.CardDesktopLeft}>
                                                <Card style={theme.CardNaviLeft}>
                                                    {RenderCoachTabs(this, "vertical")}
                                                </Card>
                                            </Card>
                                        :
                                            ""
                                :
                                    ""
                        : ''
                    }
                </Grid>
                <Grid item xs={screen==="desktop" ? 8 : 12} >
                    <div style={theme.HomeContentRoot}>
                        <DialogTutorialPage 
                            defaultSet={this.props.defaultSet}
                            handleOpen={this.state.handleOpenTutorial || false}
                            handleBadgeFamily = {this.state.tutorialFamily?.length>1 ? this.state.tutorialFamily : 'total'}
                            handleClose={() => {this.setState({handleOpenTutorial: false, tutorialFamily : 'total'})}}
                        />
                        {loading &&
                            <div>
                                <LoadingProgress 
                                    defaultSet={this.props.defaultSet}
                                    type={'LoadingPageAnimated'} loadingText={"loading Coaching Plan"} />
                            </div>
                        }
                        {user && this._isMounted
                        ?
                            <div>
                            {screen === "desktop"
                                ?
                                    this.state.hideCoachView !== true && this.context.roles!==undefined 
                                    && (this.context.roles?.includes("COACHADMIN") || this.context.roles?.includes("REMOTECOACH"))
                                    && !(window.location?.hash?.includes(ROUTES.PROGRAM))
                                    ?
                                        <Card style={theme.CardDesktopMain}>
                                            <TodayCoach
                                                user={user}
                                                defaultSet={this.props.defaultSet || undefined}
                                                leadershipCategories={this.props.leadershipCategories}
                                                leadershipQualities={this.props.leadershipQualities}
                                            />
                                        </Card>
                                    :
                                        <Card style={theme.CardDesktopMain}>
                                            <TodayProgram
                                                user={user}
                                                defaultSet={this.props.defaultSet || undefined}
                                                screen={screen}
                                                leadershipCategories={leadershipCategories}
                                                leadershipQualities={leadershipQualities}
                                                refreshQualities = {this.props.refreshQualities || undefined}
                                                refreshCategories = {this.props.refreshCategories || undefined}
                                                engagementStats={this.state.engagementStats || undefined}
                                                engagementBenchmarkQuality= {400}
                                                engagementBenchmarkCategory={ this.state.engagementBenchmarkCategory || undefined}
                                                showBadge={false}
                                                showTopic={false}
                                                showProgress={false}
                                                //showButtons={user?.dialogueCounter > 4 ? true: false}
                                                actionUpdatesScore={this.state.actionUpdatesScore || 0}
                                                actionTopicsScore={this.state.actionTopicsScore || 0}
                                                actionChallengesScore={this.state.actionChallengesScore || 0}
                                                buttonClickPriorities={() => { this.handleScrollTo('Journey'); }}
                                                buttonClickTopics={() => { this.handleScrollTo('Modules') }}
                                                buttonClickMission={() => { this.handleScrollTo('Mission') }}
                                                buttonClickStats={() => { this.handleScrollTo('Stats') }}
                                                buttonClickAssessment={() => { this.handleScrollTo('Assessment') }}
                                            />
                                            {false && (this.context.roles?.includes("COACHADMIN") || this.context.roles?.includes("REMOTECOACH"))
                                            &&
                                                <Card style={theme.CardContentItem.rootFeed}>
                                                    <div style={{margin: 20, textAlign: 'center', color: theme.palette.themeAlertRed}}>
                                                        <strong>
                                                            <InfoIcon style={theme.inlineIconLeft} /> This is the end users' app view. Please switch to the Coach Panel or Creator Tool.
                                                        </strong>
                                                    </div>
                                                </Card>
                                            }
                                            <Card style={theme.CardContentItem.rootFeed}>
                                                <TodayAdd
                                                    user={user}
                                                    screen={screen || undefined}
                                                    chatPreview={true}
                                                    defaultSet={this.props.defaultSet || undefined}
                                                    leadershipCategories={leadershipCategories}
                                                    leadershipQualities={leadershipQualities}
                                                    refreshQualities = {this.props.refreshQualities || undefined}
                                                    refreshCategories = {this.props.refreshCategories || undefined}
                                                    tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                />
                                            </Card>
                                            {(this.context?.features?.showChallenges!==true || user?.dialogueCounter > 4)
                                                ?   ''
                                                :   user?.userAssessmentUpdatedAt !== undefined
                                                    ?
                                                        <Card style={theme.CardContentItem.rootFeed}>
                                                            <Task4Chats                                         
                                                                screen={screen}
                                                                user={user}
                                                                open={this.state['open_Challenges'] || false}
                                                                updateScore={(score) => { if(this._isMounted) this.setState({actionChallengesScore: score})}}
                                                                leadershipCategories={leadershipCategories}
                                                                leadershipQualities={leadershipQualities}
                                                                refreshQualities = {this.props.refreshQualities || undefined}
                                                                refreshCategories = {this.props.refreshCategories || undefined}
                                                                tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                            />
                                                        </Card>
                                                    :
                                                        <Card style={theme.CardContentItem.rootFeed}>
                                                            <Task2Assessments                                      
                                                                screen={screen}
                                                                subscriber={this.state.subscriber || false}
                                                                user={user}
                                                                unlockCounter={0}
                                                                open={this.state['open_Challenges'] || false}
                                                                updateScore={() => { 
                                                                    //console.log('sdsdsdsd')
                                                                    this.setState({openAssessments: true})
                                                                }}
                                                                updateOcean={() => { 
                                                                    //console.log('sdsdsdsd')
                                                                    this.setState({openOcean: true})
                                                                }}
                                                                leadershipCategories={leadershipCategories}
                                                                leadershipQualities={leadershipQualities}
                                                                refreshQualities = {this.props.refreshQualities || undefined}
                                                                refreshCategories = {this.props.refreshCategories || undefined}
                                                                tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                            />
                                                        </Card>
                                            }
                                            <div  ref={this.actionRefUpdates}  >  </div>
                                            {user?.dialogueCounter > 0 &&
                                                <Card style={theme.CardContentItem.rootFeed}>
                                                    <div style={{marginLeft: 20, marginRight: 20, marginBttom: 20}}>
                                                        <JournalIntentionCheck 
                                                            user={user}
                                                            screen={screen}
                                                            subscriber={this.state.subscriber || false}
                                                            open={this.state['open_Updates'] || false}
                                                            updateScore={(score) => { if(this._isMounted) this.setState({actionUpdatesScore: score})}}
                                                            leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities} 
                                                            refreshQualities = {this.props.refreshQualities || undefined}
                                                            refreshCategories = {this.props.refreshCategories || undefined}
                                                            historyLimit={20}
                                                            visualLimit={6}
                                                        />
                                                    </div>
                                                </Card>
                                            }
                                            <div  ref={this.actionRefChallenges}  >  </div>
                                            {(true || this.context?.features?.showChallenges )&&
                                                <Card style={theme.CardContentItem.rootFeed}>
                                                    <TaskNext3D 
                                                        screen={screen}
                                                        defaultSet={this.props.defaultSet}
                                                        user={user}
                                                        open={this.state['open_Challenges'] || false}
                                                        updateScore={(score) => { if(this._isMounted) this.setState({actionChallengesScore: score})}}
                                                        leadershipCategories={leadershipCategories}
                                                        leadershipQualities={leadershipQualities} 
                                                        updateOcean={() => { 
                                                            //console.log('sdsdsdsd')
                                                            this.setState({openOcean: true})
                                                        }}
                                                        refreshQualities = {this.props.refreshQualities || undefined}
                                                        refreshCategories = {this.props.refreshCategories || undefined}
                                                        tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                    />
                                                </Card>
                                            }
                                            {(this.context.contentBookmarked?.length > 0 || this.context.contentLiked?.length > 0 || this.context.contentLastNotification?.length > 0 || this.context.contentRecommendations?.length > 0)
                                            &&  
                                                <Card style={theme.CardContentItem.rootFeed}>
                                                    <VizSensor
                                                            partialVisibility={true}
                                                            onChange={(isVisible) => { 
                                                                //console.log('isivi', isVisible, this.state)
                                                                if(isVisible && this.state['isVisible_ContentHighlights'] !== true && this._isMounted) { 
                                                                    this.setState({['isVisible_ContentHighlights'] : true});
                                                                }
                                                            }}
                                                        >
                                                        <div style={{height: (this.state['isVisible_ContentHighlights'] === true ? 0 : 475)}}>  <img src={pixel} alt=''/></div>
                                                    </VizSensor>
                                                    {this.state['isVisible_ContentHighlights'] &&
                                                        <ContentHighlights 
                                                            defaultSet={this.props.defaultSet || undefined}
                                                            user={user}
                                                            linkToContent={true}
                                                            screen={screen}
                                                            leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities} 
                                                            refreshQualities = {this.props.refreshQualities || undefined}
                                                            refreshCategories = {this.props.refreshCategories || undefined}
                                                            tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                        />
                                                    }
                                                </Card>
                                            }
                                            {true && user.programs !== undefined
                                                &&
                                                <Card style={theme.CardContentItem.rootFeed}>
                                                    <div style={{paddingTop:0, margin:20}}>
                                                        <div ref={this.actionRefAssessment}> </div>
                                                        <VizSensor
                                                            partialVisibility={true}
                                                            onChange={(isVisible) => { 
                                                                if(isVisible && this.state['isVisible_CardAssessmentShop'] !== true && this._isMounted) { 
                                                                    this.setState({['isVisible_CardAssessmentShop'] : true});
                                                                }
                                                            }}
                                                        >
                                                            <div style={{height: (this.state['isVisible_CardAssessmentShop'] === true ? 0 : 440)}}>  <img src={pixel} alt=''/></div>
                                                        </VizSensor>
                                                        {(this.state.openAssessments || this.state.openOcean || this.state['isVisible_CardAssessmentShop']) 
                                                        &&
                                                            <CardAssessmentShop 
                                                                defaultSet={this.props.defaultSet || undefined}
                                                                showSummary = {true}
                                                                allowDialog = {true}
                                                                leadershipCategories={leadershipCategories}
                                                                leadershipQualities={leadershipQualities}
                                                                refreshQualities = {this.props.refreshQualities || undefined}
                                                                refreshCategories = {this.props.refreshCategories || undefined}
                                                                openDefaultAssessment = {this.state.openAssessments || false}
                                                                openOCEANAssessment = {this.state.openOcean || false}
                                                                handlerClose= {() => this.setState({openAssessments : false, openOcean: false})}
                                                                user={user}
                                                                screen={screen}
                                                                tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                            />
                                                        }
                                                    </div>
                                                </Card>
                                            }
                                            <div ref={this.actionRefTopics}> </div>
                                            {true &&
                                                <Card style={theme.CardContentItem.rootFeed}>
                                                    <VizSensor 
                                                        partialVisibility={true}
                                                        onChange={(isVisible) => { 
                                                            if(isVisible && this.state['isVisible_ContentQualitySwipe'] !== true && this._isMounted) { 
                                                                this.setState({['isVisible_ContentQualitySwipe'] : true});
                                                            }
                                                        }}
                                                    >
                                                        <div style={{height: (this.state['isVisible_ContentQualitySwipe'] === true ? 0 : 400)}}>  <img src={pixel} alt=''/></div>
                                                    </VizSensor>
                                                    <div style={{margin:0, paddingTop:0}}>
                                                        {this.state['isVisible_ContentQualitySwipe'] === true &&
                                                            <ContentQualitySwipe 
                                                                user={user}
                                                                defaultSet={this.props.defaultSet || undefined}
                                                                mainQualitiesDicts={this.state.mainQualitiesDicts}
                                                                subscriber={this.state.subscriber || false}
                                                                engagementStats={this.state.engagementStats}
                                                                screenWidth={this.props.screenWidth || undefined}
                                                                openCatalogue={this.state['open_Catalogue'] || false}
                                                                linkToContent={true}
                                                                //open={this.state['open_Topics'] || false}
                                                                updateScore={(score) => { if(this._isMounted) {this.setState({actionTopicsScore: score})}}}
                                                                leadershipCategories={leadershipCategories}
                                                                leadershipQualities={leadershipQualities} 
                                                                refreshQualities = {this.props.refreshQualities || undefined}
                                                                refreshCategories = {this.props.refreshCategories || undefined}
                                                                buttonClickAssessment={() => { this.handleScrollTo('Assessment') }}
                                                                tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                            />
                                                        }
                                                    </div>
                                                </Card>
                                            }
                                            <div ref={this.actionRefStats}> </div>
                                            {false && this.props.showWeekly !== false && this.context.dialogueCounter >= 2 &&
                                                <Card style={theme.CardContentItem.rootFeed}>
                                                    <div style={{marginLeft: 20, marginRight: 20, marginBttom: 20}}>
                                                        <VizSensor
                                                            partialVisibility={true}
                                                            onChange={(isVisible) => { 
                                                                if(isVisible && this.state['isVisible_CardGroupRanking'] !== true && this._isMounted) { 
                                                                    this.setState({['isVisible_CardGroupRanking'] : true});
                                                                }
                                                            }}
                                                        >
                                                            <div style={{height: (this.state['isVisible_CardGroupRanking'] === true ? 0 : 100)}}>  <img src={pixel} alt=''/></div>
                                                        </VizSensor>
                                                        {this.state['isVisible_CardGroupRanking']  &&
                                                            <CardGroupRanking                                         
                                                                screen={screen}
                                                                user={user}
                                                                compactView={true}
                                                                showLinks={true}
                                                                badgeTotal={this.state.badgeTotal || undefined}
                                                                leadershipCategories={leadershipCategories}
                                                                leadershipQualities={leadershipQualities} 
                                                                refreshQualities = {this.props.refreshQualities || undefined}
                                                                refreshCategories = {this.props.refreshCategories || undefined}
                                                                tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                            />
                                                        }
                                                    </div>
                                                </Card>
                                            }
                                            <VizSensor
                                                partialVisibility={true}
                                                onChange={(isVisible) => { 
                                                    if(isVisible && this.state['isVisible_News'] !== true && this._isMounted) { 
                                                        this.setState({['isVisible_News'] : true});
                                                        this.loadNews();
                                                    }
                                                }}
                                            >
                                                <div style={{height: (this.state['isVisible_News'] === true ? 0 : 100)}}>  <img src={pixel} alt=''/></div>
                                            </VizSensor>
                                            {this.state['isVisible_News']  &&
                                                <>
                                                    {
                                                        this.renderService(screen)
                                                    }
                                                    {
                                                        this.renderNews(screen)
                                                    }
                                                </>
                                            }
                                            <Button 
                                                onClick={() => {
                                                    if (this.props?.defaultSet?.programSupportURL?.length>10){ 
                                                        window.open(this.props.defaultSet?.programSupportURL , "_blank")
                                                    }
                                                    else{
                                                        window.open("https://help.rocky.ai", "_blank")
                                                    }
                                                }}
                                            >
                                                Support & Help
                                            </Button>
                                            {false &&
                                            <Button
                                                variant="outlined" color="secondary" style={{marginBottom: 20}}
                                                onClick={() => {this.setState({showTutorial : !(this.state.showTutorial) })}}
                                            >
                                                <span>
                                                    <HelpOutlineIcon style={theme.inlineIconLeft}/>
                                                    {this.state.showTutorial ? "Hide Tutorial" : "Show Tutorial"}
                                                </span>
                                            </Button>
                                            }
                                            {this.state.showTutorial
                                            &&
                                                    <Card style={theme.CardContentItem.rootFeed}>
                                                        <div style={{padding: 20}}>
                                                            <TutorialPage 
                                                                defaultSet={this.props.defaultSet}
                                                                showDesktop={true}
                                                                addDefaults={true}
                                                                handleBadgeFamily={'total'}
                                                                showStepper={false}
                                                                handleClose={undefined}
                                                                handleScrollTop={() => { if(this.dialogRef!==undefined  && this.dialogRef!==null &&  this.dialogRef.scrollTop!==undefined ) this.dialogRef.scrollTop = 0}}
                                                            />
                                                        </div>
                                                    </Card>
                                            }
                                            {this.renderMobileApp(screen)}
                                            <div style={{paddingBottom: 100}}>
                                                &nbsp;
                                            </div>
                                        </Card>
                                :
                                    ""
                                
                            }
                            {screen !== "desktop"
                                ?
                                    <Card style={theme.CardAppMain}>
                                        <TodayProgram
                                            user={user}
                                            defaultSet={this.props.defaultSet || undefined}
                                            leadershipCategories={leadershipCategories}
                                            leadershipQualities={leadershipQualities}
                                            refreshQualities = {this.props.refreshQualities || undefined}
                                            refreshCategories = {this.props.refreshCategories || undefined}
                                            showBadge={false}
                                            showTopic={false}
                                            showProgress={false}
                                            showGoal={false}
                                            //showButtons={user?.dialogueCounter > 4 ? true: false}
                                            engagementStats={this.state.engagementStats || undefined}
                                            engagementBenchmarkQuality= {400}
                                            engagementBenchmarkCategory={ this.state.engagementBenchmarkCategory || undefined}
                                            actionUpdatesScore={this.state.actionUpdatesScore || 0}
                                            actionTopicsScore={this.state.actionTopicsScore || 0}
                                            actionChallengesScore={this.state.actionChallengesScore || 0}
                                            buttonClickPriorities={() => { this.handleScrollTo('Journey') }}
                                            buttonClickTopics={() => { this.handleScrollTo('Modules') }}
                                            buttonClickMission={() => { this.handleScrollTo('Mission') }}
                                            buttonClickStats={() => { this.handleScrollTo('Stats') }}
                                            buttonClickAssessment={() => { this.handleScrollTo('Assessment') }}
                                        />
                                        {/***
                                            <div style={{margin:20}}>
                                                <Grid container spacing={0} direction='row' justifyContent="space-between" >
                                                    <Grid item > </Grid>
                                                    <Grid item > </Grid>
                                                    <Grid item>
                                                        <span  onClick={() => {this.setState({handleTutorialOpen: true})}}>
                                                            <AvatarCard textBlock={'Tutorial'} 
                                                            type='icon' image={<HelpOutlineIcon /> } squareSize='20' backgroundColor={theme.palette.secondary.light}/>
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                                <DialogTutorialPage 
                                                    defaultSet={this.props.defaultSet}
                                                    handleOpen={this.state.handleTutorialOpen || false}
                                                    handleBadgeFamily = {'total'}
                                                    handleClose={() => { if(this._isMounted) this.setState({handleTutorialOpen: false})}}
                                                />  
                                            </div>
                                         */}
                                        {true //user?.dialogueCounter > 2
                                            ?
                                                <Card style={theme.CardContentItem.rootFeed}>
                                                    <TodayAdd
                                                        user={user}
                                                        defaultSet={this.props.defaultSet || undefined}
                                                        chatPreview={true}
                                                        leadershipCategories={leadershipCategories}
                                                        leadershipQualities={leadershipQualities}
                                                        refreshQualities = {this.props.refreshQualities || undefined}
                                                        refreshCategories = {this.props.refreshCategories || undefined}
                                                        tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                    />
                                                </Card>
                                            :   ''
                                        }
                                        {true && (this.context.roles?.includes("COACHADMIN") || this.context.roles?.includes("REMOTECOACH"))
                                        &&
                                            <Card style={{...theme.CardContentItem.rootFeed, backgroundColor: theme.palette.themeAlertOrange+"45"}}>
                                                <div style={{margin: 20, textAlign: 'center', color: theme.palette.secondary.light}}>
                                                    <strong>
                                                        <InfoIcon style={theme.inlineIconLeft} /> 
                                                        This is the view for app users. 
                                                        <br/><br/>However, since you have a Coach account, please access the Coach Panel or Creator Mode using a desktop browser. 
                                                        <br/>For accessing the admin tools, make sure your screen is at least 1200 pixels wide.
                                                    </strong>
                                                </div>
                                            </Card>
                                        }
                                        {user?.dialogueCounter <=4 && user?.userAssessmentUpdatedAt === undefined
                                            &&
                                                <Card style={theme.CardContentItem.rootFeed}>
                                                    <div ref={this.actionRefAssessment}> </div>
                                                    <div style={{paddingBottom:20, margin:0}}>
                                                        <Task2Assessments                                      
                                                            screen={screen}
                                                            subscriber={this.state.subscriber || false}
                                                            unlockCounter={0}
                                                            user={user}
                                                            open={this.state['open_Challenges'] || false}
                                                            updateScore={() => { 
                                                                //console.log('sdsdsdsd')
                                                                this.setState({openAssessments: true})
                                                            }}
                                                            updateOcean={() => { 
                                                                //console.log('sdsdsdsd')
                                                                this.setState({openOcean: true})
                                                            }}
                                                            leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities}
                                                            refreshQualities = {this.props.refreshQualities || undefined}
                                                            refreshCategories = {this.props.refreshCategories || undefined}
                                                            tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                        />
                                                    </div>
                                                </Card>
                                        }
                                        {(this.context?.features?.showChallenges !== true || user?.dialogueCounter > 2)
                                            ?   '' 
                                            :   
                                                <Card style={theme.CardContentItem.rootFeed}>
                                                    <Task4Chats                                         
                                                        screen={screen}
                                                        user={user}
                                                        open={this.state['open_Challenges'] || false}
                                                        updateScore={(score) => { if(this._isMounted) this.setState({actionChallengesScore: score})}}
                                                        leadershipCategories={leadershipCategories}
                                                        leadershipQualities={leadershipQualities}
                                                        refreshQualities = {this.props.refreshQualities || undefined}
                                                        refreshCategories = {this.props.refreshCategories || undefined}
                                                        tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                    />
                                                </Card>
                                        }
                                        <div ref={this.actionRefUpdates}  > </div>
                                        <Card style={theme.CardContentItem.rootFeed}>
                                            <div style={{
                                                //display: (user?.dialogueCounter > 6 ? 'block' : 'none'), 
                                                marginLeft: 20,
                                                marginRight: 20
                                                }}
                                            >
                                                <VizSensor
                                                    partialVisibility={true}
                                                    onChange={(isVisible) => {
                                                        if(isVisible && this.state['isVisible_JournalIntentionCheck'] !== true && this._isMounted) { 
                                                            this.setState({['isVisible_JournalIntentionCheck'] : true});
                                                        }
                                                    }}
                                                >
                                                    <div style={{height: (this.state['isVisible_JournalIntentionCheck'] === true ? 0 : 100)}}>  <img src={pixel} alt=''/></div>
                                                </VizSensor>
                                                {this.state['isVisible_JournalIntentionCheck'] &&
                                                    <JournalIntentionCheck 
                                                        user={user}
                                                        history={this.props.history}
                                                        //title={user?.dialogueCounter > 4 ? undefined : 'Day ④ - Vision & Goals'}
                                                        title={user?.dialogueCounter > 4 ? undefined : 'Vision & Goals'}
                                                        avatarGreyOut={(this.state.subscriber || user?.dialogueCounter > 3) ? false : true}
                                                        subscriber={this.state.subscriber || false}
                                                        showSlider={true}
                                                        screen={screen}
                                                        open={this.state['open_Updates'] || false}
                                                        updateScore={(score) => { if(this._isMounted) this.setState({actionUpdatesScore: score})}}
                                                        leadershipCategories={leadershipCategories}
                                                        leadershipQualities={leadershipQualities} 
                                                        refreshQualities = {this.props.refreshQualities || undefined}
                                                        refreshCategories = {this.props.refreshCategories || undefined}
                                                        historyLimit={20}
                                                        visualLimit={6}
                                                    />
                                                }
                                            </div>
                                        </Card>
                                        <div style={{paddingBottom:0}} ref={this.actionRefChallenges}> </div>
                                        {true //this.context?.features?.showChallenges && user?.dialogueCounter > 5
                                        &&
                                            <Card style={theme.CardContentItem.rootFeed}>
                                                <TaskNext3D                                         
                                                    screen={screen}
                                                    defaultSet={this.props.defaultSet}
                                                    user={user}
                                                    open={this.state['open_Challenges'] || false}
                                                    updateScore={(score) => { if(this._isMounted) this.setState({actionChallengesScore: score})}}
                                                    leadershipCategories={leadershipCategories}
                                                    leadershipQualities={leadershipQualities}
                                                    updateOcean={() => { 
                                                        //console.log('sdsdsdsd')
                                                        this.setState({openOcean: true})
                                                    }}
                                                    refreshQualities = {this.props.refreshQualities || undefined}
                                                    refreshCategories = {this.props.refreshCategories || undefined}
                                                    tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                />
                                            </Card>
                                        }
                                        <div ref={this.actionRefStats}> </div>
                                        {this.context?.features?.showChallenges && user?.dialogueCounter >=4
                                        &&
                                            <Card style={theme.CardContentItem.rootFeed}>
                                                <Task4Topics                                        
                                                    screen={screen}
                                                    user={user}
                                                    engagementBenchmarkQuality= {400}
                                                    engagementStats={this.state.engagementStats || undefined}
                                                    leadershipCategories={leadershipCategories}
                                                    leadershipQualities={leadershipQualities} 
                                                    refreshQualities = {this.props.refreshQualities || undefined}
                                                    refreshCategories = {this.props.refreshCategories || undefined}
                                                    tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                />
                                            </Card>
                                        }
                                        {true && ((user?.dialogueCounter <=4 || user?.dialogueCounter === undefined) || (user?.dialogueCounter > 4 ))
                                            &&
                                            <Card style={theme.CardContentItem.rootFeed}>
                                                <div style={{marginLeft:20, marginRight:20, display: (user?.dialogueCounter > 2 ? 'block' : 'block')}}>
                                                    <div ref={this.actionRefAssessment}> </div>
                                                    <VizSensor
                                                        partialVisibility={true}
                                                        onChange={(isVisible) => { 
                                                            if(isVisible && this.state['isVisible_CardAssessmentShop'] !== true && this._isMounted) { 
                                                                this.setState({['isVisible_CardAssessmentShop'] : true});
                                                            }
                                                        }}
                                                    >
                                                        <div style={{height: (this.state['isVisible_CardAssessmentShop'] === true ? 0 : 440)}}>  <img src={pixel} alt=''/></div>
                                                    </VizSensor>
                                                    {(this.state.openAssessments || this.state.openOcean || this.state['isVisible_CardAssessmentShop']) 
                                                    &&
                                                        <CardAssessmentShop 
                                                            defaultSet={this.props.defaultSet || undefined}
                                                            showSummary = {true}
                                                            allowDialog = {true}
                                                            leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities} 
                                                            refreshQualities = {this.props.refreshQualities || undefined}
                                                            refreshCategories = {this.props.refreshCategories || undefined}
                                                            user={user}
                                                            screen={screen}
                                                            openDefaultAssessment = {this.state.openAssessments || false}
                                                            openOCEANAssessment = {this.state.openOcean || false}
                                                            handlerClose= {() => this.setState({openAssessments : false, openOcean: false})}
                                                            tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                        />
                                                    }
                                                </div>
                                            </Card>
                                        }
                                        <div ref={this.actionRefTopics}> </div>
                                        {true && 
                                            <Card style={theme.CardContentItem.rootFeed}>
                                                <VizSensor
                                                    partialVisibility={true}
                                                    onChange={(isVisible) => {  
                                                        //console.log('isVisible_ContentQualitySwipe', isVisible, this.state)
                                                        if(isVisible && this.state['isVisible_ContentQualitySwipe'] !== true && this._isMounted) { 
                                                            this.setState({['isVisible_ContentQualitySwipe'] : true});
                                                        }
                                                    }}
                                                >
                                                    <div style={{height: (this.state['isVisible_ContentQualitySwipe'] === true ? 0 : 400)}}>  <img src={pixel} alt=''/></div>
                                                </VizSensor>
                                                {this.state['isVisible_ContentQualitySwipe'] &&
                                                    <ContentQualitySwipe 
                                                        user={user}
                                                        defaultSet={this.props.defaultSet || undefined}
                                                        //title={'Day ③ - Coaching Plan'}
                                                        title={'Coaching Plan'}
                                                        cardMode={this.props.screenWidth < 450 ? true : false}
                                                        history={this.props.history}
                                                        subscriber={this.state.subscriber || false}
                                                        //unlockCounter={this.state.subscriber ? 0 : 2}
                                                        unlockCounter={0}
                                                        mainQualitiesDicts={this.state.mainQualitiesDicts}
                                                        engagementStats={this.state.engagementStats}
                                                        screen={screen}
                                                        linkToContent={true}
                                                        openCatalogue={this.state['open_Catalogue'] || false}
                                                        //open={this.state['open_Topics'] || false}
                                                        //updateScore={(score) => { if(this._isMounted) {console.log('newupdate modules', score); this.setState({actionTopicsScore: score})}}}
                                                        updateScore={(score) => { if(this._isMounted) this.setState({actionTopicsScore: score})}}
                                                        leadershipCategories={leadershipCategories}
                                                        leadershipQualities={leadershipQualities} 
                                                        refreshQualities = {this.props.refreshQualities || undefined}
                                                        refreshCategories = {this.props.refreshCategories || undefined}
                                                        buttonClickAssessment={() => { this.handleScrollTo('Assessment') }}
                                                        tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                    />
                                                }
                                            </Card>
                                        }
                                        <Card style={theme.CardContentItem.rootFeed}>
                                            <VizSensor
                                                partialVisibility={true}
                                                onChange={(isVisible) => { 
                                                    if(isVisible && this.state['isVisible_ContentHighlights'] !== true && this._isMounted) { 
                                                        this.setState({['isVisible_ContentHighlights'] : true});
                                                    }
                                                }}
                                            >
                                                <div style={{height: (this.state['isVisible_ContentHighlights'] === true ? 0 : 475)}}>  <img src={pixel} alt=''/></div>
                                            </VizSensor>
                                            {this.state['isVisible_ContentHighlights'] &&
                                                <ContentHighlights 
                                                    defaultSet={this.props.defaultSet || undefined}
                                                    user={user}
                                                    screen={screen}
                                                    linkToContent={true}
                                                    leadershipCategories={leadershipCategories}
                                                    leadershipQualities={leadershipQualities} 
                                                    refreshQualities = {this.props.refreshQualities || undefined}
                                                    refreshCategories = {this.props.refreshCategories || undefined}
                                                    tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                />
                                            }
                                        </Card>
                                        {false && this.props.showWeekly !== false && user?.dialogueCounter >2 &&
                                            <Card style={theme.CardContentItem.rootFeed}>
                                                <div style={{marginLeft: 20, marginRight: 20}}>
                                                <VizSensor
                                                    partialVisibility={true}
                                                    onChange={(isVisible) => { 
                                                        if(isVisible && this.state['isVisible_CardGroupRanking'] !== true && this._isMounted) { 
                                                            this.setState({['isVisible_CardGroupRanking'] : true});
                                                        }
                                                    }}
                                                >
                                                    <div style={{height: (this.state['isVisible_CardGroupRanking'] === true ? 0 : 100)}}>  <img src={pixel} alt=''/></div>
                                                </VizSensor>
                                                {this.state['isVisible_CardGroupRanking'] &&
                                                    <CardGroupRanking                                         
                                                        screen={screen}
                                                        noShadow={true}
                                                        compactView={true}
                                                        user={user}
                                                        showLinks={true}
                                                        badgeTotal={this.state.badgeTotal || undefined}
                                                        leadershipCategories={leadershipCategories}
                                                        leadershipQualities={leadershipQualities} 
                                                        refreshQualities = {this.props.refreshQualities || undefined}
                                                        refreshCategories = {this.props.refreshCategories || undefined}
                                                        tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                    />
                                                }
                                                </div>
                                            </Card>
                                        }
                                        <Card style={theme.CardContentItem.rootFeed}>
                                            <div style={{marginLeft:20, marginRight:20, marginBottom: 40}}>
                                                <h4>Account</h4>
                                                <BadgeCard
                                                        backgroundColor ={"white"}
                                                        image={undefined} 
                                                        avatarImage = { 
                                                            <AccountCircleIcon style={{ color: theme.palette.primary.dark, fontSize: 60, marginTop:20, marginBottom:20, marginLeft:10 }} />  }
                                                        title = {"Profile Settings"}
                                                        subtitle = {"Modify name, notifications, modules or email address."}
                                                        strongSubtitle = {false}
                                                        onClick={ () => {
                                                            this.props.history.push(ROUTES.PROFILE) }
                                                        } 
                                                        clickText = "Change"
                                                    />
                                                <VizSensor
                                                    partialVisibility={true}
                                                    onChange={(isVisible) => { 
                                                        if(isVisible && this.state['isVisible_News'] !== true && this._isMounted) { 
                                                            this.setState({['isVisible_News'] : true});
                                                            this.loadNews();
                                                        }
                                                    }}
                                                >
                                                    <div style={{height: (this.state['isVisible_News'] === true ? 0 : 100)}}>  <img src={pixel} alt=''/></div>
                                                </VizSensor>
                                                {this.state['isVisible_News']  &&
                                                    <>
                                                        {this.renderService(screen)}
                                                        {this.renderNews(screen)}
                                                        {this.renderMobileApp(screen)}
                                                    </>
                                                }
                                            </div>
                                        </Card>
                                        <div style={{paddingBottom: 100}}>
                                            &nbsp;
                                        </div>
                                    </Card>
                                :
                                    ""
                                
                            }

                            <SnackbarInfo 
                                alertText = 'From the home screen you can start the AI chat, change your current topic or view selected tips for you.'
                                alertName = 'homeintro'
                                userId = {user.uid}
                                />
                            </div>
                        :
                            <p></p>
                        }
                    </div>
                </Grid> 
                <Grid item xs={screen==="desktop" ? 2 : false} >
                </Grid>
            </Grid>  
        );
    }

    renderService(screen = 'mobile'){
        const {theme} = this.props;
        return(
            <>
                {this.context.onboardingResponses?.includes('Executive') && this.context.programs?.includes('default')
                && !this.context.roles?.includes("REMOTECOACH") && !this.context.roles?.includes("COACHADMIN") 
                && !this.context.roles?.includes("COACHEE") && !this.context.roles?.includes("OBSERVE") 
                && this.props.defaultSet === undefined
                &&
                    <Card style={theme.CardContentItem.rootFeed}>
                    <div style={{padding: screen === 'desktop' ? 20 :0}}>
                        <BadgeCard
                            backgroundColor ={"white"}
                            image={logoHotline} 
                            avatarImage = {undefined}
                            title = {'Get your custom AI coach!'}
                            subtitle = {'Book a call to get access'}
                            strongSubtitle = {true}
                            onClick={ () => window.open(ROUTES.LinkBookMeeting(this), "_blank") }
                            button={<>Book&nbsp;<HeadsetMicIcon /></>}
                            /> 
                        <p style={theme.textSupportCenter}>
                            Rocky for Teams &bull; Activate Coach Account
                        </p>
                        </div>
                    </Card>
                }
                {false && (this.context.roles?.includes("REMOTECOACH") || this.context.roles?.includes("COACHADMIN")) 
                &&
                    <Card style={theme.CardContentItem.rootFeed}>
                    <div style={{padding: screen === 'desktop' ? 20 :0}}>
                        <BadgeCard
                            backgroundColor ={"white"}
                            image={logoHotline} 
                            avatarImage = {undefined}
                            title = {'Administrator Support'}
                            subtitle = {'Book a call to get assistance'}
                            strongSubtitle = {true}
                            onClick={ () => window.open(ROUTES.LinkBookMeeting(this), "_blank") }
                            button={<>Book&nbsp;<HeadsetMicIcon /></>}
                        /> 
                        <p style={theme.textSupportCenter}>
                            Rocky for Teams &bull; Coach Panel Support
                        </p>
                        </div>
                    </Card>
                }
                {((this.context.roles?.includes("COACHEE") || this.context.roles?.includes("OBSERVE")) && ROUTES.LinkBookMeeting(this)?.includes("http"))
                &&
                    <Card style={theme.CardContentItem.rootFeed}>
                    <div style={{padding: screen === 'desktop' ? 20 :0}}>
                        <BadgeCard
                            backgroundColor ={"white"}
                            image={logoHotline} 
                            avatarImage = {undefined}
                            title = {'Support and Hotline'}
                            subtitle = {'Book a call - Technical Support'}
                            strongSubtitle = {true}
                            onClick={ () => window.open(ROUTES.LinkBookMeeting(this), "_blank") }
                            button={<>Book&nbsp;<HeadsetMicIcon /></>}
                            /> 
                            <p style={theme.textSupportCenter}>
                                Special support &bull; 1-on-1 assistance
                            </p>
                        </div>
                    </Card>
                }
            </>
        )
    }

    renderNews(screen){
        const { theme } = this.props;
        if(this.context.news?.length> 2 || this.state.loadedNews?.length> 0 ){
            return(
                <Card style={theme.CardContentItem.rootFeed}>
                    <div style={{padding: screen === 'desktop' ? 20 :0}}> 
                        {this.state.loadedNews?.length> 0 &&
                            <div>
                                <h4>News</h4>
                                {this.state.loadedNews.map((item, index) => (
                                    <div key={'news_'+index} style={{marginBottom: 20}}>
                                        <strong>{item.title}</strong>
                                        <br/>{item.description}
                                        {item.button?.length>2 && item.url?.length>2 &&
                                            <div style={{width: '100%', textAlign: 'right', paddingTop: 10}}>
                                                <Button
                                                    onClick={() => { window.open(item.url, "_blank")  }}
                                                    >
                                                    {item.button}
                                                </Button>
                                            </div>
                                        }
                                        {item.text?.length> 2 &&
                                            <div 
                                                style={{...theme.innerHTML, marginTop: 10, width:'100%', textAlign: 'left'}}
                                                onClick = {(event) => {
                                                    try{
                                                        const link = event.target.getAttribute('href');
                                                        if(link !== undefined && link !== null && link !== ""){
                                                            event.preventDefault();
                                                            window.open(link, "_blank")
                                                        }
                                                    } catch (e){
                                                        console.log('No Link', e)
                                                    }
                                                }}
                                                dangerouslySetInnerHTML={{ __html: item.text}} 
                                            />
                                        }
                                    </div>
                                ))}
                            </div>
                        }
                        {this.context.news?.length> 2 &&
                            <div 
                                style={{...theme.innerHTML, marginTop: 100, width:'100%', textAlign: 'left'}}
                                onClick = {(event) => {
                                    try{
                                        const link = event.target.getAttribute('href');
                                        if(link !== undefined && link !== null && link !== ""){
                                            event.preventDefault();
                                            window.open(link, "_blank")
                                        }
                                    }
                                    catch(e){
                                        console.log('No Link', e)
                                    }
                                }}
                                dangerouslySetInnerHTML={{ __html: this.context.news}} 
                            />
                        }
                    </div>
                </Card>
            )
        }
    }

    renderMobileApp(screen){
        return(
            <AppInstallLinks 
                screen={screen}
                user={this.props.user}
                defaultSet={this.props.defaultSet}
            />
        )
    }
}

HomePageAdaptive.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withRouter,
    withStyles(styles, { withTheme: true}),
    withFirebase
)(HomePageAdaptive);
