import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
// MODULES
import SettingUser from './SettingUser';
import SettingCode from './SettingCode';
import SettingEmail from './SettingEmail';
import SettingSubscriptionWebBlue from './SettingSubscriptionWebBlue';
import SettingAbout from './SettingAbout';

import SnackbarInfo from '../Modules/SnackbarInfo';

import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
//import Grid from '@material-ui/core/Grid';

//style icons
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import FaceIcon from '@material-ui/icons/Face';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import InfoIcon from '@material-ui/icons/Info';


// style elements
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionActions';
//import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {IsMobileDevice} from '../Modules/IsMobileDevice';

const styles = theme => ({
    root: {
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: theme.maxWidthDialog,
        backgroundColor: theme.palette.themeLightGrey,
        height: '100%'
    },
});



class HomeProducts extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            user: null,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ loading: true });
        this.unsubscribe = this.props.firebase
        .user(this.context.uid)
        .onSnapshot(snapshot => {
            if(this._isMounted){
                this.setState({
                    user: snapshot.data(),
                    loading: false,
                });
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
    }


  render() {
    const { user, loading } = this.state;
    const { theme, classes } = this.props;

    return (
        <div className={classes.root}>

            {user !== undefined && user !== null &&
                <SnackbarInfo 
                    alertText = 'This page shows an overview of your payments and susbcriptions.'
                    alertName = 'paymentintro'
                    userId = {this.context.uid}
                    />
            }
            <Card style={IsMobileDevice()
                    ? theme.CardTopCompactMobileNavi
                    : theme.CardTop
                }>
                    <h2 style={theme.textMonthCenter}>My Subscriptions</h2>
            </Card>

            <div style={{paddingTop: 100}}>
                &nbsp;
            </div>
      
            <div style={theme.textMonthCenter}>
                <AccountCircleIcon style={{ color: theme.palette.primary.dark, fontSize: 60  }} />
                {user!== undefined && user!==null 
                        ? <h2 style={theme.textMonthCenter}>{user.username}</h2>
                        : <h2 style={theme.textMonthCenter}>Me</h2>
                }
            </div>

            {loading &&
                <div style={theme.divCenterPadding}>
                    <CircularProgress color="secondary"/>
                </div>
            }
            <div>&nbsp;</div>
            {user!== undefined && user!==null && 
                <div style={{ maxWidth: IsMobileDevice() ? '100%' :  theme.maxWidthDialog }}> 
                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-label="NotificationExpand"
                        aria-controls="Notifications-content"
                        id="Notifications-header"
                        >
                        <FormControlLabel
                            aria-label="Notifications"
                            control={<NotificationsNoneIcon  style={theme.cardSettings.iconDesign} />}
                            label="Notification Preference"
                        />
                        </AccordionSummary>
                        <AccordionDetails>
                        <SettingEmail user={user}/>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-label="Account-Expand"
                        aria-controls="Account-content"
                        id="Account-header"
                        >
                            <FormControlLabel
                                aria-label="Account"
                                control={<PermIdentityIcon style={theme.cardSettings.iconDesign} />}
                                label="Account"
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                        <SettingUser user={user}/>
                        </AccordionDetails>
                    </Accordion>          
                    
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-label="Code-Expand"
                            aria-controls="Code-content"
                            id="Code-header"
                            >
                            <FormControlLabel
                                aria-label="Code"
                                control={<FaceIcon style={theme.cardSettings.iconDesign} />}
                                label="Coach Code"
                            />
                            </AccordionSummary>
                            <AccordionDetails>
                            <SettingCode user={user}/>
                            </AccordionDetails>
                    </Accordion>
                    
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-label="Subscription-Expand"
                            aria-controls="Subscription-content"
                            id="Subscription-header"
                            >
                            <FormControlLabel
                                aria-label="Subscription"
                                control={<StarBorderIcon style={theme.cardSettings.iconDesign} />}
                                label="Subscription Plan"
                            />
                            </AccordionSummary>
                            <AccordionDetails>
                                <SettingSubscriptionWebBlue user={user}/>
                            </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-label="About-Expand"
                            aria-controls="About-content"
                            id="About-header"
                            >
                            <FormControlLabel
                                aria-label="About"
                                control={<InfoIcon style={theme.cardSettings.iconDesign} />}
                                label="About, Terms & Privacy"
                            />
                            </AccordionSummary>
                            <AccordionDetails>
                            <SettingAbout user={user} defaultSet={this.props.defaultSet}/>
                            </AccordionDetails>
                        </Accordion>
                    </div>
            }

        </div>
    );
  }
}

HomeProducts.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(HomeProducts);
