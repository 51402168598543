import React, { Component } from 'react';
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../Firebase';
import SettingPlan from '../Profile/SettingPlan';

import DialogSettingPlan from '../Profile/DialogSettingPlan';

// General Modules
import LoadingProgress from '../Modules/LoadingProgress';
import TransactionHistory from '../Profile/TransactionHistory';

//UI
import Grid from '@material-ui/core/Grid';

//Panels
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Icons
import Button from '@material-ui/core/Button';



const styles = theme => ({
    grow: {
        flexGrow: 1, 
    },
})

class CoachSubscriptions extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        //console.log('probs in JournalWeek ',this.probs);
        this.state = {
            loading: false,
            loadingText: 'loading purchases',
            coachees: null,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ loading: false });
        this.unsubscribe = this.props.firebase
        .purchases(this.context.uid)
        .onSnapshot(snapshot => {
            let purchases = [];
            if(this._isMounted ){
                snapshot.forEach(doc => {
                    purchases.push(doc.data())
                })
            }
            if(purchases.length>0 && this._isMounted) {
                this.setState({ purchases });
            }
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
        if(this.unsubscribe!==undefined){this.unsubscribe();}
        this.setState({ loading: false});
    }

    render(){
        const {theme} = this.props;
        if(this.state.loading ){
            return(
                <div>
                    <LoadingProgress 
                        defaultSet={this.props.defaultSet}
                        type={'LinearProgress'} loadingText={this.state.loadingText} />
                </div>
            )
        }
        else{
            return(
                <div>
                    <h4>My Purchases</h4>
                    <Accordion key="groups_details1"  id={`groups-key1`}
                        TransitionProps={{ mountOnEnter: true }}
                        expanded={this.state.isExpanded === undefined ? true : this.state.isExpanded}
                        onChange={(event, isExpanded) => this.setState({isExpanded})}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="groups-content"
                            id="groups"
                            >
                            <div style={theme.divFullWidth}>
                                <strong style={theme.textSupport}>Purchase Seats</strong>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={0} direction='column' >
                                <Grid item >
                                    <div style={{paddingTop: 20}}>
                                        <SettingPlan 
                                            user={this.context}
                                            coach={true}
                                        />
                                    </div>
                                </Grid>
                                <Grid item >
                                    &nbsp;
                                </Grid>
                                <Grid item >
                                    {false &&
                                        <p>
                                            <Button onClick={() => this.handleOpenPurchase()}>
                                                Purchase New Seats
                                            </Button>
                                        </p>
                                    }
                                    <p style={theme.textSettings.settingsTextDescription}>
                                        Each seat grants the access to one Coachee/User for a Rocky Premium Subscription for 1 month.
                                    </p>
                                    <p style={theme.textSettings.settingsTextDescription}>
                                        You can purchase a number of seats and then assign them to the individual users in your 'Manage Users' panel.
                                    </p>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion key="clients_transactions"  id={`clients-key1`}   TransitionProps={{ mountOnEnter: true }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="clients_transactions"
                            id="clients_transactions"
                            >
                            <div style={theme.divFullWidth}>
                                <strong style={theme.textSupport}>Coachee Seat Transactions</strong>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={0} direction='column' >
                                <Grid item >
                                    <TransactionHistory 
                                        userId={this.context.uid}
                                        paymentSource={'SeatTransferHistory'}    
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion key="clients_details1"  id={`clients-key1`}  TransitionProps={{ mountOnEnter: true }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="clients-content"
                            id="clients"
                            >
                            <div style={theme.divFullWidth}>
                                <strong style={theme.textSupport}>Purchase History</strong>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={0} direction='column' >
                                <Grid item >
                                    <TransactionHistory 
                                        userId={this.context.uid}
                                        paymentSource={'Bluesnap'}    
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    {
                        this.dialoguePurchase()
                    }
                </div> 
            )
        }
       
    }
    

    handleClose = () => {
        this.setState({DialogSettingPlanOpen: false, open:false} )
    }
    handleOpenPurchase = () => {
        this.setState({selectedId : this.context.uid, selectedUser: this.context, DialogSettingPlanOpen: true, open:true} )
    }


    dialoguePurchase = () => {
        return(
            <DialogSettingPlan 
                defaultSet={this.props.defaultSet || undefined}
                user={this.context}
                coach={true}
                handleOpen={this.state.DialogSettingPlanOpen}
                premiumOnly={true}
                purchaseSuccessFlag={() => {
                    this.setState({nonSubscriber: false, premiumSubscriber: true})
                }}
                purchaseSuccessHandler={() => {
                    this.setState({DialogSettingPlanOpen: false, nonSubscriber: false, premiumSubscriber: true})
                }}
                handleClose={() => this.setState({DialogSettingPlanOpen : false})}
            />
        )
    }


}//end class


export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(CoachSubscriptions);
