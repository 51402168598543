function muiStyle (palette) { return {
    color: palette.black,
    root: {
        "&.MuiStepIcon-active": {
            color: palette.secondary.main
        },
        "&.MuiStepIcon-completed": {
            color: palette.themeAlertGreen
        },
    },
    text: {
        fill: palette.white,
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
        color: palette.themeGrey
    }
}}

export default muiStyle;