import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import SignInPage from '../SignIn/SignInPage';
//import SignUpPage from '../SignUp/SignUpPage';

const withAuthorization = condition => Component => {
    class WithAuthorization extends React.Component {
        _isMounted = false;
        static contextType = AuthUserContext;

        componentDidMount() {
            this._isMounted = true;
        }

        componentWillUnmount() {
            this._isMounted = false;
        }

        render() {
            //console.log('auth render', this.context, condition, Component, this.props)
            return (
                <AuthUserContext.Consumer>
                    {authUser =>
                        condition(authUser) 
                            ?   <Component {...this.props} /> 
                            :   <SignInPage 
                                    {...this.props}
                                    errorMessage={this.props.advancedCondition === true ? "Your account does not have the necessary access rights to view this page. Please try using a different account." : undefined}
                                />
                    }
                </AuthUserContext.Consumer>
            );
        }
    }

    return compose(
        withRouter,
        withFirebase,
    )(WithAuthorization);
};

export default withAuthorization;
