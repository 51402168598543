import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';


import { withFirebase } from '../Firebase';

import TextField from '@material-ui/core/TextField';
import AddAlertIcon from '@material-ui/icons/AddAlert';
//import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import EventIcon from '@material-ui/icons/Event';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { CreateAlert } from '../Modules/CreateAlert';
/// Calendar Links
import { google, outlook, office365, yahoo, ics } from "calendar-link";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AppleIcon from '@material-ui/icons/Apple';
import RockyIcon from '../Modules/RockyIcon';
//import ForumIcon from '@material-ui/icons/Forum';

const styles = theme => ({
    grow: {
        flexGrow: 1, 
    },
})

class CardAlert extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        var message = "I want to ...";
        if(this.props.preMessage!==undefined && this.props.preMessage!==""){
            message= this.props.preMessage;
        }
        var context = "Follow-up";
        if(this.props.preContext!==undefined && this.props.preContext!==""){
            context= this.props.preContext;
        }

        var dateNow = new Date(); // Creating a new date object with the current date and time
        var dateTomorrow = new Date(dateNow.getTime() + (60 * 60 * 24 * 1000)); 
        // get hours
        var hour = dateNow.getHours(); 
        if(hour < 22){
            hour = hour + 1;
        }
        else{
            hour = 9;
            dateNow = dateTomorrow;
        }
        hour = hour.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
            ? `0${hour}`
            : ''+hour;
        //month
        var monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
        monthWithOffset = monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
            ? `0${monthWithOffset}`
            : ''+monthWithOffset;
        // day
        var monthTomorrowWithOffset = dateTomorrow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
        monthTomorrowWithOffset = monthTomorrowWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
            ? `0${monthTomorrowWithOffset}`
            : ''+monthTomorrowWithOffset;
        // day
        var dateDay =
        dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
            ? `0${dateNow.getUTCDate()}`
            : '' + dateNow.getUTCDate();
        var dateTomorrowDay =
        dateTomorrow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
            ? `0${dateTomorrow.getUTCDate()}`
            : '' + dateTomorrow.getUTCDate();
        // year
        const year = dateNow.getFullYear(); // Getting current year from the created Date object
        const yearTomorrow = dateTomorrow.getFullYear();
        const materialDateInput = `${year}-${monthWithOffset}-${dateDay}`;
        const materialDateTomorrowInput = `${yearTomorrow}-${monthTomorrowWithOffset}-${dateTomorrowDay}`;
        this.state = {
            user: this.props.user,
            dateAlert: materialDateInput,
            dateTomorrowAlert: materialDateTomorrowInput,
            timeAlert: hour+':45',
            message: message,
            context: context,
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount(){
        this._isMounted = false;
    }

    render(){
        //console.log('state', this.state)
        const { theme } = this.props;
        const today = new Date();
        var nowHours = today.getHours();
        if(this.props.userId !== undefined && this.props.user!==undefined){
            return(
                <div style={theme.divFullWidth}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        //justifyContent="center"
                        justifyContent="flex-end"
                        >
                        <Grid item xs={12}>
                            <p> Create date and message for personal reminder </p>
                        </Grid>
                        <Grid item xs={12} style={{ minHeight: 20 }}>
                            <TextField
                                fullWidth 
                                variant="outlined"
                                id="message"
                                style={ {fontSize: '16px'} }
                                //label="Reminder message"
                                type="text"
                                defaultValue={this.state.message}
                                onChange={(event) => this.setState({ message: event.target.value }) }
                            />
                        </Grid>
                        {/***
                        <Grid item xs={12} style={{ minHeight: 20 }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="context"
                                style={ {fontSize: '16px'} }
                                label="Context of reminder"
                                type="text"
                                defaultValue={this.state.context}
                                onChange={(event) => this.setState({ context: event.target.value }) }
                            />
                        </Grid>
                        */}
                        {this.props.urgent
                            ?
                                <Grid item xs={12} style={{textAlign: 'center'}}>
                                    <FormControl component="fieldset">
                                        <RadioGroup row aria-label="position" name="position" 
                                            onChange={(event) => { 
                                                if(this._isMounted){
                                                    var timeAlert = this.state.timeAlert;
                                                    var dateAlert = this.state.dateAlert;
                                                    var targetDayTime = event.target.value;
                                                    if(targetDayTime === '1hour') timeAlert = "" + timeAlert;
                                                    else if(targetDayTime === 'morning') timeAlert = '09:45';
                                                    else if(targetDayTime === 'afternoon') timeAlert = '13:45';
                                                    else if(targetDayTime === 'evening') timeAlert = '17:45';
                                                    else if(targetDayTime === 'nextmorning') {
                                                        timeAlert = '09:45';
                                                        dateAlert = this.state.dateTomorrowAlert;
                                                    }
                                                    else if(targetDayTime === 'nextafternoon') {
                                                        dateAlert = this.state.dateTomorrowAlert;
                                                        timeAlert = '13:45';
                                                    }
                                                    else if(targetDayTime === 'nextevening') {
                                                        dateAlert = this.state.dateTomorrowAlert;
                                                        timeAlert = '17:45';
                                                    }
                                                    this.setState({targetDayTime, timeAlert, dateAlert});
                                                }
                                            }}
                                        >
                                            {nowHours < 22
                                                &&
                                                <FormControlLabel
                                                    key={'1hour'}
                                                    value={'1hour'}
                                                    style={{margin: 0}} 
                                                    checked={this.state.targetDayTime==='1hour'}
                                                    control={<Radio color="secondary" />}
                                                    label={<span style={{paddingRight:8}}>In 1 hour</span>}
                                                    labelPlacement="bottom"
                                                />
                                            }
                                            {nowHours < 9
                                                &&
                                                <FormControlLabel
                                                    key={'morning'}
                                                    value={'morning'}
                                                    style={{margin: 0}} 
                                                    checked={this.state.targetDayTime==='morning'}
                                                    control={<Radio color="secondary" />}
                                                    label={<span style={{paddingRight:8}}>Morning</span>}
                                                    labelPlacement="bottom"
                                                />
                                            }
                                            {nowHours < 14
                                                &&
                                                <FormControlLabel
                                                    key={'thisafternoon'}
                                                    value={'afternoon'}
                                                    style={{margin: 0}} 
                                                    checked={this.state.targetDayTime==='afternoon'}
                                                    control={<Radio color="secondary" />}
                                                    label={<span style={{paddingRight:8}}>Afternoon</span>}
                                                    labelPlacement="bottom"
                                                />
                                            }
                                            {nowHours < 17
                                                &&
                                                <>
                                                    <FormControlLabel
                                                        key={'thisevening'}
                                                        value={'evening'}
                                                        style={{margin: 0}} 
                                                        checked={this.state.targetDayTime==='evening'}
                                                        control={<Radio color="secondary" />}
                                                        label={<span style={{paddingRight:8}}>Evening</span>}
                                                        labelPlacement="bottom"
                                                    />
                                                    {nowHours > 12
                                                        &&
                                                        <FormControlLabel
                                                            key={'nextmorning'}
                                                            value={'nextmorning'}
                                                            style={{margin: 0}} 
                                                            checked={this.state.targetDayTime==='nextmorning'}
                                                            control={<Radio color="secondary" />}
                                                            label={<span style={{paddingRight:8}}>Morning</span>}
                                                            labelPlacement="bottom"
                                                        />
                                                    }
                                                </>
                                            }
                                            {nowHours >= 17
                                                &&
                                                <>
                                                    <FormControlLabel
                                                        key={'nextmorning'}
                                                        value={'nextmorning'}
                                                        style={{margin: 0}} 
                                                        checked={this.state.targetDayTime==='nextmorning'}
                                                        control={<Radio color="secondary" />}
                                                        label={<span style={{paddingRight:8}}>Morning</span>}
                                                        labelPlacement="bottom"
                                                    />
                                                    <FormControlLabel
                                                        key={'afternoon'}
                                                        value={'nextafternoon'}
                                                        style={{margin: 0}} 
                                                        checked={this.state.targetDayTime==='nextafternoon'}
                                                        control={<Radio color="secondary" />}
                                                        label={<span style={{paddingRight:8}}>Afternoon</span>}
                                                        labelPlacement="bottom"
                                                    />
                                                </>
                                            }
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            :
                                <>
                                    <Grid item xs={12} style={{ minHeight: 20, textAlign: 'right' }}>
                                        <TextField
                                            id="datetime-local"
                                            variant="outlined"
                                            style={ {fontSize: '16px'} }
                                            //label="Next reminder"
                                            type="date"
                                            defaultValue={this.state.dateAlert}
                                            onChange={(event) => this.setState({ dateAlert: event.target.value }) }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                min: this.state.dateAlert
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ minHeight: 20, textAlign: 'right'}}>
                                        <TextField
                                            id="time-local"
                                            variant="outlined"
                                            style={ {fontSize: '16px'} }
                                            //label="Time"
                                            type="time"
                                            defaultValue={this.state.timeAlert || "11:30"}
                                            onChange={(event) => this.setState({ timeAlert: event.target.value }) }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                step: 900, // 5 min
                                            }}
                                        />
                                    </Grid>
                                </>
                        }
                        {this.props.addCalendar
                            ?
                                <>
                                    <Grid item xs={6}>
                                        <span style={theme.textSettings.settingsTextDescription}>Personal Reminder:</span>
                                    </Grid>
                                    <Grid item xs={6}
                                        style={{textAlign: 'right', pointerEvents: (this.state.openSelect !== true? true : false) }}
                                        onClick={() => {if(this.state.openSelect !== true ) this.setState({openSelect: true})}}
                                    >
                                        <FormControl>
                                            <Select
                                                labelId="calendar"
                                                id="calendarr"
                                                value={this.state.calendarType || 'reminder'}
                                                disableUnderline
                                                onChange={(event) => {
                                                    var datum = new Date(this.state.dateAlert+'T'+this.state.timeAlert);
                                                    //var currentDateTimeCentralTimeZone = new Date(datum.toLocaleString('en-US', { timeZone: this.state.timezone }));
                                                    //var timeSchedule = new Date(currentDateTimeCentralTimeZone.getTime() + 1000);
                                                    //console.log('datum', datum)
                                                    var description = 'AI Coach - ' + this.state.context + ' - ' + this.state.message;
                                                    var title = this.state.message;
                                                    const calendarItem={
                                                        title: title,
                                                        description: description,
                                                        start: datum,
                                                        duration: [1, "hour"],
                                                        busy: true
                                                    }
                                                    var link = '';
                                                    var attachment = undefined;
                                                    if(event.target.value === 'google'){
                                                        link = google(calendarItem);
                                                        //console.log('added to googe', link);
                                                        window.open(link, "_blank");
                                                        this.saveAlert()
                                                        this.setState({error: "Google calendar link opened in new window!", calendarType: event.target.value});
                                                    }
                                                    else if(event.target.value === 'yahoo'){
                                                        link = yahoo(calendarItem);
                                                        window.open(link, "_blank");
                                                        this.saveAlert()
                                                        this.setState({error: "Yahoo calendar link opened in new window!", calendarType: event.target.value});
                                                    }
                                                    else if(event.target.value === 'outlook'){
                                                        link = outlook(calendarItem);
                                                        window.open(link, "_blank");
                                                        this.saveAlert()
                                                        this.setState({error: "Outlook calendar link opened in new window!", calendarType: event.target.value});
                                                    }
                                                    else if(event.target.value === 'office365'){
                                                        link = office365(calendarItem);
                                                        window.open(link, "_blank");
                                                        this.saveAlert()
                                                        this.setState({error: "Office365 calendar link opened in new window!", calendarType: event.target.value});
                                                    }
                                                    else if(event.target.value === 'apple'){
                                                        attachment = ics(calendarItem);
                                                        //console.log('attachment', attachment);
                                                        //link = 'mailto:'+this.context.email;
                                                        //link = link + '?subject='+this.state.message
                                                        //link = link + '&body='+this.state.context + ' - ' + this.state.message
                                                        window.open(attachment, "_blank");
                                                        //x.close();
                                                        this.saveAlert()
                                                        this.setState({error: "Calendar file downloaded!", calendarType: event.target.value});
                                                    }
                                                    else if(event.target.value === 'ics'){
                                                        attachment = ics(calendarItem);
                                                        //console.log('attachment', attachment);
                                                        //link = 'mailto:'+(this.props.user?.email || this.props.user?.username);
                                                        //link = link + '?subject='+title
                                                        //link = link + '&body='+description
                                                        window.open(attachment, "_blank");
                                                        this.saveAlert()
                                                        this.setState({error: "Email calendar link opened in new window!", calendarType: event.target.value});
                                                    }
                                                    else{
                                                        this.saveAlert()
                                                        this.setState({calendarType: event.target.value});
                                                    }
                                                    this.setState({openSelect: false});
                                                }}
                                                open={this.state.openSelect || false}
                                                onClose={() => this.setState({openSelect: false})}
                                                onOpen={() => this.setState({openSelect: true})}
                                            >
                                                <MenuItem
                                                    key={'reminder'}
                                                    value={'reminder'}
                                                    selected={this.state.calendarType===undefined || this.state.calendarType==='reminder' || false}
                                                    style={{marginBottom: -10}}
                                                >
                                                    Add to Calendar:
                                                </MenuItem>
                                                <MenuItem
                                                    key={'google'}
                                                    value={'google'}
                                                    selected={this.state.calendarType==='google'}
                                                    style={{marginBottom: -10}}
                                                >
                                                    <AddAlertIcon fontSize="small"/>&nbsp;Google Calendar
                                                </MenuItem>
                                                <MenuItem
                                                    key={'yahoo'}
                                                    value={'yahoo'}
                                                    selected={this.state.calendarType==='yahoo'}
                                                    style={{marginBottom: -10}}
                                                >
                                                    <AddAlertIcon fontSize="small"/>&nbsp;Yahoo Calendar
                                                </MenuItem>
                                                <MenuItem
                                                    key={'office365'}
                                                    value={'office365'}
                                                    selected={this.state.calendarType==='office365'}
                                                    style={{marginBottom: -10}}
                                                >
                                                    <AddAlertIcon fontSize="small"/>&nbsp;Office 365
                                                </MenuItem>
                                                <MenuItem
                                                    key={'outlook'}
                                                    value={'outlook'}
                                                    selected={this.state.calendarType==='outlook'}
                                                    style={{marginBottom: -10}}
                                                >
                                                    <AddAlertIcon fontSize="small"/>&nbsp;Outlook.com
                                                </MenuItem>
                                                {process?.env?.REACT_APP_DEVICE_PLATFORM === "BROWSER"
                                                    &&
                                                    <MenuItem
                                                        key={'apple'}
                                                        value={'apple'}
                                                        selected={this.state.calendarType==='apple'}
                                                        style={{marginBottom: -10}}
                                                    >
                                                        <AppleIcon fontSize="small"/>&nbsp;Apple Calendar
                                                    </MenuItem>
                                                }
                                                {process?.env?.REACT_APP_DEVICE_PLATFORM === "BROWSER"
                                                    &&
                                                    <MenuItem
                                                        key={'ics'}
                                                        value={'ics'}
                                                        selected={this.state.calendarType==='ics'}
                                                        style={{marginBottom: -10}}
                                                    >
                                                        <EventIcon fontSize="small"/>&nbsp;Download .ics file
                                                    </MenuItem>
                                                }
                                                <MenuItem
                                                    key={'rocky'}
                                                    value={'rocky'}
                                                    selected={this.state.calendarType==='rocky'}
                                                    style={{marginBottom: -10}}
                                                >
                                                    <RockyIcon fontSize="small"/>&nbsp;App Reminder
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                        {/***
                                        <Button onClick={() => {this.saveAlert()}} style={theme.buttonRight}>
                                            Add to Calendar&nbsp;<EventIcon fontSize="small"/>
                                        </Button>
                                        */}
                                    </Grid>
                                </>
                            :
                                <Grid item xs={12} style={{textAlign: 'right'}}>
                                    <Button onClick={() => {this.saveAlert()}} style={theme.buttonRight}>
                                        Save Alert&nbsp;<AddAlertIcon fontSize="small"/>
                                    </Button>
                                </Grid>
                        }
                        <Grid item xs={12}>
                            <p style={theme.textErrorCenterPadding}>
                                {this.state.error} &nbsp;
                            </p>
                        </Grid>
                        {/***
                         */}
                    </Grid>
                </div>
            )
        }
        else{
            return ""
        }
    }

    saveAlert(){
        if(this.state.message !== undefined && this.state.message.length>4 && this.state.context!== undefined && this.state.context.length>4){
            if(this.state.dateAlert !== undefined && this.state.dateAlert!== ''
                &&  this.state.timeAlert !== undefined && this.state.timeAlert!== ''){
                var datum = new Date(this.state.dateAlert+'T'+this.state.timeAlert);
                var currentDateTimeCentralTimeZone = new Date(datum.toLocaleString('en-US', { timeZone: this.state.timezone }));
                var timeSchedule = new Date(currentDateTimeCentralTimeZone.getTime() + 1000);
                var result = CreateAlert(this.props.firebase, this.state.user, this.state.message, this.state.context, this.props.senderName, timeSchedule)
                if(result !== "none"){
                    this.setState({error: "..." + result + " alert added!"});
                    if(this.props.alertSet!== undefined ) this.props.alertSet(true);
                }
                else{
                    this.setState({error: "All notification channels are deactivated!"})
                }
            }
            else{
                this.setState({error: "Please choose a valid date!"})
            }
        }
        else{
            this.setState({error: "Please provide a longer message and context!"})
        }
    }

}//end class


export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(CardAlert);
