import React, { Component } from 'react';

import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';

//import ActionFabButton from '../Home/ActionFabButton';
import {freeTopics} from '../Modules/CheckSubscriptionStatus';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import {AvatarFoto} from '../Modules/AvatarImage';

import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import StarsIcon from '@material-ui/icons/Stars';
import Filter2Icon from '@material-ui/icons/Filter2';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
    grow: {
      flexGrow: 1,
    },
    listItem: {
        listStyleType: "none",
        boxShadow: 1,
        border: '0px',
        borderRadius: theme.borderRadius,
        maxWidth: 200,
        maxHeight: 200,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    listItemInactive: {
        listStyleType: "none",
        boxShadow: 1,
        opacity: '40%',
        border: '0px',
        borderRadius: theme.borderRadius,
        maxWidth: 200,
        maxHeight: 200,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
});

class CardTopicSquare extends Component {
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        var itemType = "leadership_category"
        if(this.props.type!==undefined && this.props.type!==""){
            itemType = this.props.type;
        }
        this.state = {
            itemType
        }
    }

    render() {
        const { theme, classes } = this.props;
        //console.log('stats eng', this.props)
        const { item, engagementStats, engagementBenchmarkCategory} = this.props;
        var completion = 0;
        if ( engagementStats!==undefined && engagementStats[this.state.itemType] !== undefined 
            && engagementStats[this.state.itemType][item.value] !==undefined && engagementBenchmarkCategory !== undefined ){
                completion = Math.round(engagementStats[this.state.itemType][item.value]["points"] / engagementBenchmarkCategory * 100);
        }
        if(item !==undefined && item!==null && item.value!== undefined ){
            return(
                <ImageListItem key={item.value} 
                    onClick={() => {this.handleSubmitValue(item.value)}}
                    className={classes.listItem}
                    style={{
                        backgroundColor: this.state['hover'+item.value]
                            ? theme.palette.themeAltGold 
                            : item.backgroundColor!== undefined && item.backgroundColor.length > 3
                                ?   item.backgroundColor
                                :   theme.palette.themeAlertBlue
                                ,
                        backgroundImage:
                            item.url !== undefined && item.url !== '' 
                                ?  'linear-gradient( to right, '+
                                    (this.state['hover'+item.value]
                                        ? theme.palette.themeAltGold 
                                        : item.backgroundColor!== undefined && item.backgroundColor.length > 3
                                            ?   item.backgroundColor
                                            :   theme.palette.themeAlertBlue
                                    )
                                    +' 30%, transparent ), url("'+item.url+'")'
                                :   '',
                        backgroundPosition: '30% 40%',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        opacity : this.state['hover'+item.value] ? 0.7 : 1,
                        listStyleType: 'none'
                            //(this.props.mainTopicFlag || this.props.todayTopicFlag || this.props.mainTopicFlag === undefined) ? 1 : 0.4
                    }}
                    onMouseEnter={(this.state['hover'+item.value ] !== true) ? () => {this.setState({['hover'+item.value ]: true})} : void(0)}
                    onMouseLeave={(this.state['hover'+item.value] === true) ? () => {this.setState({['hover'+item.value ]: false})} : void(0)}
                >
                    {/***
                     <ActionFabButton
                        text={(this.state.growthPathList?.[0]?.percentage || 0) + "%"}
                        image={(item.icon)}
                        theme={theme}
                        height = '9vH'
                        style={{marginLeft:10}}
                        percentage = {75}
                        colorCircle = { theme.palette.themeAlertGreen }
                        colorButton = { item.backgroundColor || theme.palette.themeAltGold}
                    /> 
                     
                     */}
                    {item.icon!==undefined && item.icon!==''
                        ?
                            <AvatarFoto key={item.value} type='imageIcon' image={item.icon} item={item.value} squareSize='100%' backgroundColor={item.url?.includes("http") ? 'transparent' : item.backgroundColor} />   
                        :
                            <AvatarFoto key={item.value} type={this.props.type!==undefined && this.props.type!=="" ? this.props.type : 'category'} item={item.value} squareSize='100%' backgroundColor={item.url?.includes("http") ? 'transparent' : item.backgroundColor} />   
                    }
                    {this.props.showCompletionLevel && completion > 0 &&
                        <div style={{
                            position:"absolute", 
                            bottom: "35%",
                            fontSize: 12,
                            zIndex: 3, 
                            left: 16, 
                            color: theme.palette.themeAltGold, 
                            //textShadow: " 0.07em 0 black,  0 0.07em black, -0.07em 0 black, 0 -0.07em black",
                            fontWeight: 400
                        }}> 
                            {completion > 99
                                ?   'Advanced Level'
                                :   (completion + "% completed")
                            }
                        </div>
                    }
                    {this.props.mainTopicFlag === true
                        &&
                        <div style={{position:"absolute", top:10, right:10, color:"black"}}> 
                            <StarsIcon/>
                        </div>
                    }
                    {this.props.mainTopicFlag !== true && this.props.todayTopicFlag === true
                        &&
                        <div style={{position:"absolute", top:10, right:10, color:"black"}}> 
                            <Filter2Icon/>
                        </div>
                    }
                    <ImageListItemBar
                        title={
                            <span>
                                {this.props.showShop && !(freeTopics.includes(item.value)) &&
                                    <ShoppingBasketIcon style={{ ...theme.inlineIconLeft, color: item.program === 'default' ? theme.palette.themeBlue : theme.palette.themeAltGold}} />
                                }
                                {item.text}
                            </span>
                            }
                        subtitle={
                            <span>
                                {this.props.subtitle?.length > 2 ? this.props.subtitle : item.explanationShort}
                            </span>}
                        style={{ borderRadius: '0 0 '+theme.borderRadius+'px '+theme.borderRadius+'px', maxHeight:"35%", maxWidth: 200}}
                        actionIcon={this.props.subtitle === undefined
                            ?
                                <IconButton aria-label={`Choose ${item.text}`} >
                                    <ArrowForwardIosIcon />
                                </IconButton>
                            :   undefined
                        }
                    />
                </ImageListItem>
            )
        }
        else return ""
    }

    handleSubmitValue = (category) => {
        if(this.props.submitValue!==undefined){
            this.props.submitValue(category);
        }
    }
}

export default compose(
    withStyles(styles, { withTheme: true })
)(CardTopicSquare);