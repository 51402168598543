import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import * as ROUTES from "../../constants/routes";
import { withRouter } from "react-router-dom";

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import Grid from '@material-ui/core/Grid';
//import MenuItem from '@material-ui/core/MenuItem';
//import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import googleIcon from "../../images/google_button.png";
import facebookIcon from "../../images/facebook_button.png";
import appleIcon from "../../images/appleid_button.png";
import twitterIcon from "../../images/twitter.jpg";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    socialLogo: {
        borderRadius: 41,
        maxHeight: 30, 
    },
});


class SettingUser extends Component {
    static contextType = AuthUserContext;
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            openSetting: false,
        };
    }

    componentDidMount() {
        if(this.context.anonymousSignup && this.context.anonymousPassword !== undefined && this.context.anonymousPassword !== "" ){
            var oldPassword = this.context.anonymousPassword;
            this.setState({ oldPassword });
        }
        this.getAuthProviders();
    }

    getAuthProviders() {
        var authProviders = this.props.firebase.checkAuthProvider();
        if( authProviders=== undefined || authProviders.length === 0 ){
            //console.log('SettingUser authProviders', authProviders)
            authProviders = this.props.firebase.checkAuthProvider();
        }
        this.setState({authProviders });
    }

    render() {
        const { user } = this.props;
        const { theme, classes } = this.props;
        return (
        user
            ?
            <div className={classes.grow}>
                <Card style={theme.cardSettings}>
                    <p style={theme.textSettings} onClick={this.handleSettingOpen}>
                        <span style={theme.textSettings.settingsTextValue}>{user.username}</span>
                        <span style={theme.textSettings.settingsTextDescription}>Username:</span>
                    </p>
                    {(
                        (this.state.authProviders !== undefined && this.state.authProviders.includes("password")) ||
                        (this.context.anonymousSignup === true && this.context.anonymousPassword !== undefined && this.context.anonymousPassword !== "")
                     )
                        &&
                        <p style={theme.textSettings} onClick={this.handlePasswordOpen}>
                            <span style={theme.textSettings.settingsTextValue}>{"*****"}</span>
                            <span style={theme.textSettings.settingsTextDescription}>Password:</span>
                        </p>
                    }
                    {(
                        (this.state.authProviders !== undefined && this.state.authProviders.includes("password")) ||
                        (this.context.anonymousSignup === true && this.context.anonymousPassword !== undefined && this.context.anonymousPassword !== "")
                     )
                        ?
                            <p style={theme.textSettings} onClick={this.handleEmailOpen}>
                                <span style={theme.textSettings.settingsTextValue}>{user.email}</span>
                                <span style={theme.textSettings.settingsTextDescription}>Email:</span>
                            </p>
                        :
                            <p style={theme.textSettings}>
                                <span style={theme.textSettings.settingsTextDescription}>{user.email}</span>
                            </p>
                    }
                    {this.state.authProviders !== undefined && this.state.authProviders.includes("google.com")
                        &&
                        <>
                            <p style={theme.textSettings}>
                                <span style={theme.textSettings.settingsFixedTextValue}>
                                    <img src={googleIcon} className={classes.socialLogo} alt="Sign in with Google" />
                                    &nbsp;
                                    <IconButton onClick={ () => this.setState({unlinkMethod: "google.com", openUnlink: true})}> 
                                        <EditIcon />
                                    </IconButton>
                                </span>
                                <span style={theme.textSettings.settingsTextDescription}>Delete Account, Data & Google Sign-in:</span>
                            </p>
                        </>
                    }
                    {this.state.authProviders !== undefined && this.state.authProviders.includes("apple.com")
                        &&
                        <>
                            <p style={theme.textSettings}>
                                <span style={theme.textSettings.settingsFixedTextValue}>
                                    <img src={appleIcon} className={classes.socialLogo} alt="Sign in with Apple" />
                                    &nbsp;
                                    <IconButton onClick={ () => this.setState({unlinkMethod: "apple.com", openUnlink: true})}> 
                                        <EditIcon />
                                    </IconButton>
                                </span>
                                <span style={theme.textSettings.settingsTextDescription}>Delete Account, Data & Apple Sign-in:</span>
                            </p>
                        </>
                    }
                    {this.state.authProviders !== undefined && this.state.authProviders.includes("facebook.com")
                        &&
                        <>
                            <p style={theme.textSettings}>
                                <span style={theme.textSettings.settingsFixedTextValue}>
                                    <img src={facebookIcon} className={classes.socialLogo} alt="Sign in with Facebook" />
                                    &nbsp;
                                    <IconButton onClick={ () => this.setState({unlinkMethod: "facebook.com", openUnlink: true})}> 
                                        <EditIcon />
                                    </IconButton>
                                </span>
                                <span style={theme.textSettings.settingsTextDescription}>Delete Account, Data & Facebook Sign-in:</span>
                            </p>
                        </>
                    }
                    {this.state.authProviders !== undefined && this.state.authProviders.includes("twitter.com")
                        &&
                        <>
                            <p style={theme.textSettings}>
                                <span style={theme.textSettings.settingsFixedTextValue}>
                                    <img src={twitterIcon} className={classes.socialLogo} alt="Sign in with Twitter" />
                                    &nbsp;
                                    <IconButton onClick={ () => this.setState({unlinkMethod: "twitter.com", openUnlink: true})}> 
                                        <EditIcon />
                                    </IconButton>
                                </span>
                                <span style={theme.textSettings.settingsTextDescription}>Delete Account, Data & Twitter Sign-in:</span>
                            </p>
                        </>
                    }
                    {user.purchasedProduct !== undefined && user.purchasedProduct.owned===true 
                        ?   ""
                        :   ""
                    }
                    {this.state.authProviders !== undefined && this.state.authProviders.length === 1 && this.state.authProviders.includes("password")
                    &&
                        <p style={theme.textSettings}>
                            <br/><br/>
                            <span style={theme.textSettings.settingsTextDescription} >
                                <span style={{textDecoration:'underline'}} onClick={this.handleDeleteOpen}>Delete Account & Data</span>
                            </span>
                        </p>
                    }
                </Card>
                <Dialog
                    open={this.state.openSetting}
                    onClose={this.handleSettingClose}
                    fullWidth
                    maxWidth="sm">
                    <DialogTitle>Username</DialogTitle>
                    <form>
                    <DialogContent>
                        First Name / Nickname
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="username"
                            style={ {fontSize: '16px'} }
                            //label="Username"
                            value={this.state.username}
                            onChange={this.handleSettingChange}
                        />
                        {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                            ?   <p style={theme.textErrorCenterPadding}> {this.state.errorMessage}  </p>
                            :   <p> </p>
                        }
                    </DialogContent>
                    </form>
                    <DialogActions>
                    <Button onClick={this.handleSettingClose}>
                        Cancel
                    </Button>
                    <Button onClick={this.handleSettingSubmit}>
                        Save
                    </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openEmail || false}
                    onClose={this.handleEmailClose}
                    fullWidth
                    maxWidth="sm">
                    <DialogTitle>Change Email Address</DialogTitle>
                    <form>
                    <DialogContent>
                        <p>
                            Current Email: <br/><strong>{this.props.user.email}</strong>
                        </p>
                        New Email
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="email"
                            style={ {fontSize: '16px'} }
                            //label="New email"
                            autoComplete="username"
                            value={this.state.email || ''}
                            onChange={this.handleEmailChange}
                        />
                        Password
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="password"
                            type={this.state.showPassword ? "text" : "password"}
                            style={ {fontSize: '16px'} }
                            //"Password required"
                            autoComplete="current-password"
                            value={this.state.password || ""}
                            onChange={(event) => this.setState({password:event.target.value})}
                            InputProps={{ // <-- This is where the toggle button is added.
                                endAdornment: (
                                <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => this.setState({showPassword: !this.state.showPassword})}
                                        >
                                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                </InputAdornment>
                                )
                            }}
                        />
                        {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                            ?   <p style={theme.textErrorCenterPadding}> {this.state.errorMessage}  </p>
                            :   <p> </p>
                        }
                    </DialogContent>
                    </form>
                    <DialogActions>
                    <Button onClick={this.handleEmailClose}>
                        Cancel
                    </Button>
                    <Button onClick={this.handleEmailSubmit}>
                        Save
                    </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.openPassword || false}
                    onClose={this.handlePasswordClose}
                    fullWidth
                    maxWidth="sm">
                    <DialogTitle>Change password</DialogTitle>
                    <form>
                    <DialogContent>
                        Old Password
                        {this.context.anonymousSignup && this.context.anonymousPassword !== undefined && this.context.anonymousPassword !== "" 
                            ? 
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="oldPassword"
                                    disabled={this.context.anonymousPassword?.length> 0 ? true : false}
                                    type="text"
                                    style={ {fontSize: '16px'} }
                                    //label="Old password"
                                    autoComplete="current-password"
                                    value={this.context.anonymousPassword || ""}
                                    //onChange={(event) => this.setState({oldPassword:event.target.value})}
                                />
                            :
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="newPassword"
                                    type="password"
                                    style={ {fontSize: '16px'} }
                                    //label="Old password"
                                    autoComplete="current-password"
                                    value={this.state.oldPassword || ""}
                                    onChange={(event) => this.setState({oldPassword:event.target.value})}
                                />
                        }
                        New Password
                        <TextField
                            fullWidth
                            id="newPassword"
                            variant="outlined"
                            style={ {fontSize: '16px'} }
                            //label="New password"
                            autoComplete="new-password"
                            type={this.state.showPassword ? "text" : "password"}
                            value={this.state.newPassword || ""}
                            onChange={(event) => this.setState({newPassword:event.target.value})}
                            InputProps={{ // <-- This is where the toggle button is added.
                                endAdornment: (
                                <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => this.setState({showPassword: !this.state.showPassword})}
                                        >
                                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                </InputAdornment>
                                )
                            }}
                        />
                        {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                            ?   <p style={theme.textErrorCenterPadding}> {this.state.errorMessage}  </p>
                            :   <p> </p>
                        }

                        {(this.context.anonymousSignup || (this.context.anonymousPassword !== undefined && this.context.anonymousPassword !== "")  || (this.props.user.email.includes('anonymous') ))
                            ?   ""
                            :
                                <span style={theme.textSupportRight}>Forgot password?&nbsp;
                                    <span style={{textDecoration :"underline"}} onClick= { () =>  this.handlePasswordEmail(this.props.user.email)}>Send recovery email</span>
                                </span> 
                        }
                        
                    </DialogContent>
                    </form>
                    <DialogActions>
                        <Button onClick={this.handlePasswordClose}>
                            Cancel
                        </Button>
                        <Button onClick={this.handlePasswordSubmit}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.openDelete || false}
                    onClose={this.handleDeleteClose}
                    fullWidth
                    maxWidth="sm">
                    <DialogTitle>Delete Account</DialogTitle>
                    <form>
                    <DialogContent>
                        {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                            ?   <p style={theme.textErrorCenterPadding}> {this.state.errorMessage}  </p>
                            :   <p> </p>
                        }
                        {this.state.areyousuretodelete 
                            && 
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="passwordreset"
                                    type="password"
                                    style={ {fontSize: '16px'} }
                                    //label="Password required"
                                    autoComplete="current-password"
                                    value={this.state.password || ""}
                                    onChange={(event) => this.setState({password:event.target.value})}
                                />
                        }
                    </DialogContent>
                    </form>
                    <DialogActions>
                        <Button onClick={this.state.areyousuretodelete ? this.absolutelyDeleteAccount : this.handleDeleteAgree}>
                            Yes! Delete Account and All Data
                        </Button>
                        <Button onClick={this.handleDeleteClose}>
                            NO! DO NOT DELETE
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.openUnlink || false}
                    onClose={this.handleUnlinkClose}
                    fullWidth
                    maxWidth="sm">
                    <DialogTitle>Sign-in Method</DialogTitle>
                    <form>
                    <DialogContent>
                        {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                            ?   <p style={theme.textErrorCenterPadding}> {this.state.errorMessage}  </p>
                            :   <p> </p>
                        }
                        {(this.state.unlinkMethod !== undefined && this.state.authProviders !== undefined)
                            ?
                                this.state.authProviders.length === 1
                                ?
                                    <strong>Unlink and delete account from: {this.state.unlinkMethod}</strong>
                                :
                                    <strong>Unlink account from: {this.state.unlinkMethod}</strong>
                            :   ""
                        }
                        {this.state.areyousuretodelete && this.state.authProviders.length === 1
                            && 
                            <p style={theme.textErrorCenterPadding}> Your account will be converted to an anonymous account </p>
                        }
                    </DialogContent>
                    </form>
                    <DialogActions>
                        {(this.state.authProviders === undefined || this.state.unlinkMethod === undefined || this.state.unlinkDone)
                            ?
                                <Button onClick={this.handleUnlinkClose}>
                                    Close
                                </Button>
                            :
                                this.state.authProviders.length === 1
                                ?
                                    <>
                                        <Button 
                                            onClick={this.state.areyousuretodelete ? () => {this.handleUnlinkSingle(true)} : this.handleUnlinkDeleteAgree}
                                            //onClick={ this.handleUnlinkDeleteAgree}
                                        >
                                            Yes! DELETE Account
                                        </Button>
                                        <Button onClick={this.handleUnlinkClose}>
                                            NO! DO NOT DELETE ACCOUNT!
                                        </Button>
                                    </>
                                :
                                    <>
                                        <Button 
                                            onClick={this.handleUnlinkSingle }
                                            //onClick={ this.handleUnlinkDeleteAgree}
                                        >
                                            Yes! UNLINK Account
                                        </Button>
                                        <Button onClick={this.handleUnlinkClose}>
                                            NO! DO NOT UNLINK ACCOUNT!
                                        </Button>
                                    </>
                        }
                    </DialogActions>
                </Dialog>


            </div>
        :   <div> </div>
                
        );
    }

    // Username Handling
    handleSettingOpen = () => {
        this.setState({
            openSetting: true,
            username: this.props.user.username ,
        });
    } 
    handleSettingChange = event => {
        this.setState({
            username: event.target.value
        });
    }
    handleSettingClose = () => {
        this.setState({
            openSetting: false,
            username: '',
        });
    }
    handleSettingSubmit = () => {
        if(this.state.username!==undefined && this.state.username.length>1)
        {
            this.props.firebase.user(this.context.uid).update({
                username: this.state.username
            });      
            this.handleSettingClose();
        }
        else{
            this.setState({
                errorMessage: 'Username too short!'
            });
        }
    }
    // Email Handling
    handleEmailOpen = () => {
        this.setState({
            openEmail: true,
            //email: this.props.user.email ,
            email: '' ,
        });
    } 
    handleEmailChange = event => {
        this.setState({
            email: event.target.value
        });
    }
    handleEmailClose = () => {
        this.setState({
            openEmail: false,
            email: '',
        });
    }
    handleEmailSubmit = () => {
        if(this.state.password!==undefined && this.state.password.length>1 && this.state.email!==undefined && this.state.email.length>5 )
        {
            if(this.state.email!==this.props.user.email){
                var email = this.state.email.toLowerCase();
                this.props.firebase.doEmailChange(this.props.user.email, this.state.password,  email)
                .then((result) => {
                    //console.log("email change", result)
                    if(result.success){
                        this.props.firebase.user(this.context.uid).update({
                            email: email,
                            anonymousSignup: false
                        });
                        this.setState({
                            errorMessage: result.errorMessage
                        });
                        this.getAuthProviders();
                        this.handleEmailClose();
                    }
                    else{
                        this.setState({
                            errorMessage: result.errorMessage
                        });
                    }
                })
            }
            else{
                this.setState({
                    errorMessage: 'Old and new email are the same!'
                });
            }
        }
        else{
            this.setState({
                errorMessage: 'Please add your password and email!'
            });
        }
    }
    // Password Handling
    handlePasswordEmail = (email) => {
        //console.log("email", email)
        this.props.firebase
        .doPasswordReset(email)
        .then(() => {
            this.setState({
                errorMessage: 'An email will be send to: ' +email
            });
        })
        .catch(error => {
            this.setState({
                errorMessage: error
            });
        });
    }
    handlePasswordOpen = () => {
        this.setState({
            openPassword: true,
        });
    }    
    handleNewPasswordChange = event => {
        this.setState({
            newPassword: event.target.value
        });
    }
    handleOldPasswordChange = event => {
        this.setState({
            oldPassword: event.target.value
        });
    }
    handlePasswordClose = () => {
        this.setState({
            openPassword: false,
            newPassword: '',
            oldPassword: '',
        });
    }
    handlePasswordSubmit = () => {
        var oldPwd = this.state.oldPassword || '';
        if(oldPwd === "") {
            oldPwd = this.context.anonymousPassword?.length> 0 || "";
        }
        if(oldPwd?.length>1 && this.state.newPassword!==undefined && this.state.newPassword.length>3 )
        {
            if(oldPwd !== this.state.newPassword){
                this.props.firebase.doPasswordChange(this.props.user.email, oldPwd, this.state.newPassword)
                .then((result) => {
                    //console.log("password change", result)
                    if(result.success){
                        this.props.firebase.user(this.context.uid).update({
                            anonymousPassword: this.props.firebase.fieldValue.delete()
                        });
                        this.setState({
                            errorMessage: result.errorMessage
                        });
                        this.getAuthProviders();
                        this.handlePasswordClose();
                    }
                    else{
                        this.setState({
                            errorMessage: result.errorMessage
                        });
                    }
                })
            }
            else{
                this.setState({
                    errorMessage: 'Both passwords are the same!'
                });
            }
        }
        else{
            this.setState({
                errorMessage: 'Please add your old and new password!'
            });
        }
    } 
    handleDeleteOpen = () => {
        this.setState({
            openDelete: true,
            errorMessage: 'Are you sure that you want to delete this account with all the history for now and forever????'
        });
    } 
    handleDeleteClose = () => {
        this.setState({
            openDelete: false,
            areyousuretodelete: false,
            errorMessage: ''
        });
    }
    handleUnlinkClose = () => {
        this.setState({
            openUnlink: false,
            unlinkMethod: undefined,
            errorMessage: ''
        });
    }
    handleDeleteAgree = () => {
        this.setState({
            areyousuretodelete: true,
            errorMessage: 'Okay! Please add your password and press delete account'
        });
    } 
    handleUnlinkDeleteAgree = () => {
        this.setState({
            areyousuretodelete: true,
            errorMessage: 'Okay! You can unlink and delete your account now'
        });
    }
    handleUnlinkSingle = async (forceDelete = false) => {
        var providerId = this.state.unlinkMethod;
        //providerId = "password";
        var makeAnonymous = false;
        if(!forceDelete && this.state.authProviders !== undefined && this.state.authProviders.length === 1 && !this.state.authProviders.includes("password")){
            makeAnonymous = true;
        }
        await this.props.firebase.doUnlinkAuthProvider(providerId, makeAnonymous, this.context.uid, "", "", forceDelete)
        .then( async (status) => {
            if (!status){
                //console.log('received error', status)
                this.getAuthProviders();
                this.setState({
                    areyousuretodelete: false,
                    errorMessage: 'Sorry, something went wrong, please contact the support'
                });
            }
            else {
                //console.log('received', status)
                this.setState({
                    openUnlink : true,
                    unlinkDone : true,
                    areyousuretodelete: false,
                    errorMessage: 'Sign-in method unlinked!'
                });
                this.getAuthProviders();
            }
            if(forceDelete){
                //console.log('signout', status)
                this.props.firebase.doSignOut();
            }

        })
    }

    absolutelyDeleteAccount = async () => {
        if(this.state.password!==undefined && this.state.password.length>1  )
        {
            if(this.state.areyousuretodelete){
                this.props.firebase.doDeleteAccount(this.props.user.email, this.state.password,  this.context.uid)
                .then((result) => {
                    //console.log("email change", result)
                    if(result.success){
                        this.props.history.push(ROUTES.SIGN_IN);
                        this.setState({
                            errorMessage: result.errorMessage
                        });
                        this.getAuthProviders();
                        this.handleDeleteClose();
                    }
                    else{
                        this.setState({
                            errorMessage: result.errorMessage
                        });
                    }
                })
            }
            else{
                this.setState({
                    errorMessage: 'Did not work!'
                });
            }
        }
        else{
            this.setState({
                errorMessage: 'Please add your password to delete the account!'
            });
        }
    }
}

SettingUser.propTypes = {
  classes: PropTypes.object.isRequired
};

//const condition = authUser => !!authUser;

export default compose(
    withRouter,
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(SettingUser);
