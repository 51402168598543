import React, { Component } from 'react';
import { Chart, Doughnut } from 'react-chartjs-2';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    grow: {
      flexGrow: 1,
    },
});

class ProgressDoughnut extends Component {
    _isMounted = true;

    constructor(props) {
        super(props);
        var [data, options] = this.loadChartData();
        //console.log('initiated doughnut',  data, options)
        this.state = { data, options};
    }

    componentDidMount() {
        this._isMounted = true;
        Chart.pluginService.register({
            beforeDraw: function (chart) {
                if (chart.config.options.elements.center) {
                    //Get ctx from string
                    var ctx = chart.chart.ctx;

                    //Get options from the center object in options
                    var centerConfig = chart.config.options.elements.center;
                    var fontStyle = "'Proxima Nova Regular'";
                    var txt = centerConfig.text;
                    var color = centerConfig.color || '#000';
                    var sidePadding = centerConfig.sidePadding || 20;
                    var sidePaddingCalculated = (sidePadding/100) * (chart.innerRadius * 3);
                    //Start with a base font of 30px
                    ctx.font = "20px " + fontStyle ;

                    //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                    var stringWidth = ctx.measureText(txt).width;
                    var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
                    //chart.chart.canvas.parentNode.style.height = chart.chart.canvas.width;
                    //console.log('chart', chart, "sidePaddingCalculateds", sidePaddingCalculated, "elementWidth", elementWidth)

                    // Find out how much the font can grow in width.
                    var widthRatio = elementWidth / stringWidth;
                    var newFontSize = Math.floor(30 * widthRatio);
                    var elementHeight = (chart.innerRadius * 2);
                    

                    // Pick a new font size so it will not be larger than the height of label.
                    var fontSizeToUse = Math.min(newFontSize, elementHeight);

                    //Set font settings to draw it correctly.
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
                    var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
                    ctx.font = fontSizeToUse+"px bold " + fontStyle;
                    ctx.fillStyle = color;

                    //Draw text in center
                    ctx.fillText(txt, centerX, centerY);
                }
            }
        });
    }

    componentDidUpdate(prevProps)  {
        if(this._isMounted){
            var newValues = '';
            var oldValues = '';
            if(this.props.percentage !== undefined && this.props.percentage >= 0){
                newValues = this.props.percentage
            }
            if(prevProps.percentage !== undefined && prevProps.percentage >= 0 ){
                oldValues = prevProps.percentage
            }
            if(newValues !== '' && newValues !== oldValues ) {
                var [data, options] = this.loadChartData();
                this.setState({ data, options});
                //console.log('updated doughnut',  data, options)
            }
        }
        return true
    }

    loadChartData() {
        //console.log('chart', this.props)
        var percentage = 0;
        if(this.props.percentage !== undefined &&  this.props.percentage !== null && this.props.percentage > 0){
            if (this.props.percentage > 100){
                percentage = 100;
            }
            else{
                percentage = this.props.percentage;
            }
        }
        var colorText = this.props.theme.palette.secondary.light;
        if(this.props.colorText !== undefined && this.props.colorText !== null && this.props.colorText[0] === "#" ){
            colorText = this.props.colorText;
        }
        var colorMain = this.props.theme.palette.secondary.main;
        if(this.props.colorMain !== undefined && this.props.colorMain !== null && this.props.colorMain[0] === "#" ){
            colorMain = this.props.colorMain;
        }
        var colorBackGround = this.props.theme.palette.themeLightGrey+'88';
        if(this.props.colorBackGround !== undefined && this.props.colorBackGround !== null && this.props.colorBackGround[0] === "#" ){
            colorBackGround = this.props.colorBackGround;
        }

        const data = {
            datasets: [{
                data: [percentage, 100 - percentage],
                backgroundColor: [
                    colorMain,
                    colorBackGround
                ],
                borderColor: [
                    colorMain,
                    colorBackGround
                ],
            }],
        };
        const options = {
            elements: {
                center: {
                    text: this.props.overWriteText!== undefined && this.props.overWriteText !== "" ? this.props.overWriteText : ((percentage).toFixed(0).toString() + "%"),
                    //text: this.props.textExtra,
                    color: colorText, //Default black
                    backgroundColor: 'red',
                    fontSize: 13,
                    fontStyle: "'Proxima Nova Regular'", //Default Arial
                    fontWeight: 700,
                    sidePadding: 16 //Default 20 (as a percentage)
                }
            },
            cutoutPercentage: this.props.cutoutPercentage!== undefined && this.props.cutoutPercentage > 10 ? this.props.cutoutPercentage : 70,
            events: [],
            responsive : true,
            legend: {display: false},
            maintainAspectRatio : false,
            layout: {
                padding: 1
            },
            aspectRatio: 1,
        };
        return [data, options]
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return(
            <Doughnut
                data={this.state.data}
                options={this.state.options}
            />
        )
    }
}


export default compose(
    withStyles(styles, { withTheme: true })
)(ProgressDoughnut);
