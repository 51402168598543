//import palette from '../palette';

function muiStyle (palette) { return {
    root: {
        color: palette.themeGrey,
        '&.Mui-focused': {
            color:  palette.themeBlue,
        },
    }
}}

export default muiStyle;