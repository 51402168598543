import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import { AuthUserContext } from '../../Session';
import { withFirebase } from '../../Firebase';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';
import IconButton from '@material-ui/core/IconButton';
//import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import BorderColorTwoToneIcon from '@material-ui/icons/BorderColorTwoTone';

import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});


class SettingContentArray extends Component {
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        this.state = {
            defaultValue: [],
            openSetting: false,
        };
    }

    // Username Handling
    handleSettingOpen = () => {
        this.props.params.map((p) => {
            if(this.props.content[p]!== undefined){
                return this.setState({[p] : this.props.content[p], [(p+'Change')]: this.props.content[p]});
            }
            else{
                return this.setState({[p] : this.state.defaultValue, [(p+'Change')]:this.state.defaultValue});
            }
        })
        if(this.props.addMoveIntent !== undefined){
            console.log('addMoveIntent', this.props.addMoveIntent )
            var addMoveSubIntent = undefined;
            var addMoveSentiment = undefined;
            if(this.props.addMoveIntent.includes('inform_accomplishment')){
                addMoveSubIntent = 'inform_accomplishment' ;
                addMoveSentiment = this.props.addMoveIntent.replace('inform_accomplishment', '');
            }
            else if(this.props.addMoveIntent.includes('inform_sentiment')){
                addMoveSubIntent = 'inform_sentiment' ;
                addMoveSentiment = this.props.addMoveIntent.replace('inform_sentiment', '');
            }
            else if(this.props.addMoveIntent.includes('inform_intention')){
                addMoveSubIntent = 'inform_intention' ;
                addMoveSentiment = this.props.addMoveIntent.replace('inform_intention', '');
            }
            else if(this.props.addMoveIntent.includes('inform_activity')){
                addMoveSubIntent = 'inform_activity' ;
                addMoveSentiment = this.props.addMoveIntent.replace('inform_activity', '');
            }
            else if(this.props.addMoveIntent.includes('inform_conclusions')){
                addMoveSubIntent = 'inform_conclusions' ;
                addMoveSentiment = this.props.addMoveIntent.replace('inform_conclusions', '');
            }
            else if(this.props.addMoveIntent.includes('inform_indicator')){
                addMoveSubIntent = 'inform_indicator' ;
                addMoveSentiment = this.props.addMoveIntent.replace('inform_indicator', '');
            }
            else if(this.props.addMoveIntent.includes('inform_failurereason')){
                addMoveSubIntent = 'inform_failurereason' ;
                addMoveSentiment = this.props.addMoveIntent.replace('inform_failurereason', '');
            }
            else if(this.props.addMoveIntent.includes('inform_challenge')){
                addMoveSubIntent = 'inform_challenge' ;
                addMoveSentiment = this.props.addMoveIntent.replace('inform_challenge', '');
            }
            else if(this.props.addMoveIntent.includes('question_conclusions')){
                addMoveSubIntent = 'question_conclusions' ;
                addMoveSentiment = this.props.addMoveIntent.replace('question_conclusions', '');
            }
            this.setState({addMoveSubIntent: addMoveSubIntent, addMoveSentiment: addMoveSentiment})
        }
        this.setState({
            openSetting: true,
        });
    }

    handleSettingChange = (param, index, event) => {
        //console.log(event.target.value, index)
        var newValue = '';
        var activateUpdate = true;
        if(event.target.value === undefined){
            activateUpdate = false;
        }
        else{
            newValue  = event.target.value;
        }
        if(this.props.max!==undefined && this.props.max>0 ){
            if(event.target.value.length > this.props.max){
                activateUpdate = false;
                this.setState({
                    errorMessage: 'Text is too long - max ' + this.props.max + ' characters'
                });
            }
        } 
        if(index === 0 && this.state[(param+'Change')].length === 1 && newValue === '' && this.props.emptyOption !== true){
            activateUpdate = false;
        }
        if(activateUpdate){
            var myArray = [...this.state[(param+'Change')]];
            if(index > (this.state[(param+'Change')].length+1) && newValue !== ''){
                myArray.push(newValue)
                this.setState({
                    [(param+'Change')]: myArray
                });
            }
            else if(event.target.value !== undefined ){
                myArray[index] = newValue;
            }
            this.setState({
                [(param+'Change')]: myArray,
                errorMessage: ''
            });
        }
    }

    handleSettingClose = () => {
        this.setState({
            openSetting: false,
        });
        this.props.params.map((p) => {
            return this.setState({[p] : this.state.defaultValue, [p+'Change']:this.state.defaultValue});
        })
    }

    handleSettingSubmit = () => {
        this.props.params.map((p) => {
            if(this.state[(p+'Change')] !== null && this.state[(p+'Change')].length>0 && this.state[(p+'Change')] !== undefined && this.state[(p+'Change')] !== this.props.content[p] ){
                var newArray = this.state[(p+'Change')].filter(function(v){return (v !== undefined && v !== '')});
                newArray = newArray.map(v => { return v.trim(); });
                newArray = newArray.filter(function(v){return (v !== undefined && v !== '')});
                //var newArray = this.state[(p+'Change')].filter(v => { if(v !== undefined && v.trim()!=='') return v.trim() ; else return false; });
                //var newArray = newArray.map(v => { return v.trim(); });
                //console.log('newArray', newArray)
                if(this.props.sorting){
                    newArray.sort();
                }
                var updateSet = { [p]: newArray };
                if(this.props.updateExtraChange !== undefined && typeof this.props.updateExtraChange === 'object'){
                    Object.assign(updateSet, this.props.updateExtraChange)
                }
                if(this.props.dbUpdateLink !== undefined){
                    this.props.dbUpdateLink.update(updateSet);
                }
                if(this.props.updateState !== undefined){
                    this.props.updateState(updateSet);
                }
                //console.log('new setting', newArray)
            }
            return '';
        })        
        this.handleSettingClose();
    }

    handleMoveResponse = () => {
        var newIntentTarget = this.state.moveResponseSentiment + this.state.addMoveSentiment;
        if(this.state.moveResponse !== '' && newIntentTarget !== ''){
            //console.log('newIntentTarget', newIntentTarget, this.state);
            this.setState({errorMessage : 'Searching for: '+newIntentTarget+ ''});
            this.props.firebase
            .agentIntentClasses()
            .where('intent', '==', newIntentTarget)
            .get().then(snapshot => {
                if(snapshot.empty){
                    //console.log('no examples')
                    this.setState({errorMessage : 'Not intent with class: '+newIntentTarget+ ''});
                    return false;
                }
                else{
                    snapshot.forEach((doc) =>{ 
                        //console.log(doc.id);
                        var updateSet = {};
                        updateSet["responses"] = this.props.firebase.fieldValue.arrayUnion(this.state.moveResponse.trim());
                        this.props.firebase.agentIntent(doc.id).update(updateSet);
                        //console.log('updated', newIntentTarget, doc.id)
                    })
                    var updateArray = Array.from(this.state.responsesChange);
                    const index = updateArray.indexOf(this.state.moveResponse);
                    if (index > -1) {
                        updateArray[index] = '';
                    }
                    this.setState({responsesChange: updateArray, moveResponse: undefined, errorMessage: undefined});
                    return true;
                }
            })
        }
        else{
            this.setState({errorMessage : 'Failure with Selection'});
        }
    }

    render() {
        const { content, params, title } = this.props;
        const { theme, classes } = this.props;
        //console.log('this.state', this.state)
        return (
            content
            ?
            <div className={classes.grow}>
                <Card style={theme.cardAdminSettings} onClick={this.handleSettingOpen}>
                <Grid container>
                    <Grid item xs={9} >
                            <h6><a to="#">{title}</a>
                            {this.props.mandatory 
                                &&  <strong style={{color:theme.palette.themeRed}}>&nbsp;*</strong>
                            }
                            {this.props.mandatory===false
                                &&  <span style={{fontSize: 'small', fontWeight:'normal'}}>&nbsp;(optional)</span>
                            }
                            </h6>
                          </Grid>
                    <Grid item xs={1}>  </Grid>
                    <Grid item xs={2} style={{textAlign: 'right', marginTop: -10}}>
                        {(this.props.access === undefined || this.props.access === true)  &&
                            <Tooltip title={'Edit/Change'}>
                                <IconButton onClick={this.handleSettingOpen}> 
                                    <BorderColorTwoToneIcon fontSize="small" style={{color:"red"}}/>
                                </IconButton>
                            </Tooltip>
                        }
                    </Grid>
                </Grid>
                    {
                        params.map((p) => (
                            <p style={theme.textSettings} key={"current_"+p}>
                                {content[p]!== undefined && content[p] instanceof Array
                                    ? 
                                        <span style={{ float: 'right', fontWeight: 700, maxWidth:'75%' }}>
                                            {this.props.showShort
                                                ?
                                                    content[p].map( (item, index) => (
                                                       index<4
                                                        ? <li key={"sort_"+index}>{item}</li>
                                                        : index === 4
                                                            ? <li key={"sort_"+index}>... more</li>
                                                            : ""
                                                    ))
                                                :
                                                    content[p].map( (item, index) => (
                                                        <li key={"sort_"+index}>{item}</li>
                                                    ))
                                            }                                        
                                        </span>
                                    :   <span style={{ float: 'right'}}>{"[n/a]"}</span>
                                }
                                {this.props.noLabel !== true &&
                                    <span>{p}</span>
                                }
                            </p>
                        ))
                    }
                </Card>
                <Dialog
                    open={this.state.openSetting}
                    onClose={this.handleSettingClose}
                    fullWidth
                    maxWidth="sm">
                    <DialogTitle>Change Setting</DialogTitle>
                    <form>
                    <DialogContent>
                        {params.map((p) => (
                            <div style={{width: `100%`}} key={"change_"+p}>
                                {(this.props.addNewButton === true)
                                    ?
                                        this.state.fordbidNewSentences
                                        ?
                                            <SyncDisabledIcon fontSize="small" style={{color: 'red'}}/>
                                        :
                                            <IconButton disabled={this.state.loadingNewSentences || false} onClick={() => this.getNewSentences()}> 
                                                <PlaylistAddIcon fontSize="small" style={{color:this.state.loadingNewSentences? 'grey' : "blue"}}/>
                                            </IconButton>
                                    :   ''
                                }
                                Array for: <strong>{p}</strong> (Erase, modify or add)
                                {this.state[p] !== undefined && Array.isArray(this.state[p]) 
                                    &&
                                    this.state[p].map((x, index) => (
                                    <p key={"org_"+index}> 
                                        {this.props.addMoveResponses && this.state.addMoveSubIntent !== undefined
                                            &&
                                            <Checkbox
                                                onClick={(event) => this.setState({moveResponse: (event.target.checked? x : undefined)})}
                                                checked = {
                                                    this.state.moveResponse === x 
                                                        ? true
                                                        : false
                                                }
                                            />
                                        }
                                        {this.props.noLabel
                                            ?
                                                ""
                                            :
                                                <span style={{ paddingTop: (8+16*index)}}>
                                                    {
                                                    this.state[p][index]?.length>14 ? (this.state[p][index].substring(0,14)+'...') : this.state[p][index]
                                                    }
                                                </span>
                                        }
                                        <TextField
                                            style={{ float: 'right', width: this.props.noLabel? '100%':'60%', fontSize: '16px'}}
                                            fullWidth
                                            key={"change_"+index}
                                            id={"change_"+index}
                                            multiline={this.props.multiline || undefined}
                                            defaultValue={x}
                                            value={this.state[(p+'Change')][index] || ''}
                                            label={(this.props.noLabel? (index+". ") : "") + ((this.props.max > 0 ? (" (" + this.state[(p+'Change')]?.[index]?.length+ "/"+ this.props.max+ " character)") : this.props.noLabel? "" : x))}
                                            onChange={(e) => this.handleSettingChange(p, index, e)}
                                        />
                                    </p>
                                ))}
                                <TextField
                                    style={{ float: 'right', width:'60%', fontSize: '16px'}}
                                    fullWidth
                                    key={"change_new"}
                                    id={"change_new"}
                                    label={((this.props.max > 0 ? (" (" + this.state[(p+'Change')]?.[this.state[p].length+1]?.length + "/"+ this.props.max+ " character)") : "Add New"))}
                                    onChange={(e) => this.handleSettingChange(p, (this.state[p].length+1), e)}
                                />
                            </div>
                        ))  }
                        {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                        ?
                        <p style={theme.textErrorCenterPadding}>
                            {this.state.errorMessage}
                        </p>
                        : <p> </p>
                        }
                    </DialogContent>
                    </form>
                    <DialogActions>
                        <Button onClick={this.handleSettingClose}>
                            Cancel
                        </Button>
                        {this.state.moveResponse !== undefined && this.state.moveResponse !== '' && this.state.addMoveSubIntent !== undefined
                            ?
                                <>
                                    <Button 
                                        disabled={this.state.moveResponseSentiment === undefined || this.state.moveResponseSentiment === 'none' }
                                        onClick={this.handleMoveResponse}
                                    >
                                        Move Response to:
                                    </Button>
                                    <Select
                                        value={this.state.moveResponseSentiment || 'none'}
                                        onChange={(event) => this.setState({moveResponseSentiment: event.target.value !== this.state.addMoveSubIntent ? event.target.value : 'none'})}
                                        name='sentiment'
                                        >
                                            <MenuItem value={'inform_accomplishment'}>
                                                Accomplishment / Positive Long
                                            </MenuItem>
                                            <MenuItem value={'inform_sentiment'}>
                                                Positive Short
                                            </MenuItem>
                                            <MenuItem value={'inform_intention'}>
                                                Goal / Intention Long
                                            </MenuItem>
                                            <MenuItem value={'inform_activity'}>
                                                Activity / Intention Short
                                            </MenuItem>
                                            <MenuItem value={'inform_conclusions'}>
                                                Conclusions / Neutral Long
                                            </MenuItem>
                                            <MenuItem value={'inform_indicator'}>
                                                Indicator / Neutral Short
                                            </MenuItem>
                                            <MenuItem value={'inform_failurereason'}>
                                                Failure / Negative Long
                                            </MenuItem>
                                            <MenuItem value={'inform_challenge'}>
                                                Challenge / Negative Short
                                            </MenuItem>
                                            <MenuItem value={'question_conclusions'}>
                                                Question
                                            </MenuItem>
                                            <MenuItem value={'none'}>
                                                None
                                            </MenuItem>
                                    </Select>
                                </>
                            :   this.state.errorMessage=== undefined || this.state.errorMessage===''
                                ?
                                    <Button onClick={this.handleSettingSubmit}>
                                        Save
                                    </Button>
                                :
                                    <Button disabled>
                                        Save
                                    </Button>
                        }
                    </DialogActions>
                </Dialog>
            </div>
        :   <div> </div>
                
        );
    }

    getNewSentences(){
        this.setState({loadingNewSentences : true})
        this.getNewSentencesRockyAI();
    }
    getNewSentencesRockyAI = async () => {
        var url = 'https://us-central1-rocky-beta-dev.cloudfunctions.net/generate_sentences'
        let data = {
            'input': 'your text here',
            'rewrite_num' : 10,
            'uniqueness': 3
        }
        let inputTexts = [];
        this.props.params.map((p) => {
            inputTexts = inputTexts.concat(this.props.content[p]);
            return true;
        })
        var inputTextChain = ''
        var counter = 0;
        inputTexts.map( item => {
            if( inputTextChain === ''){
                inputTextChain = item
            }
            else{
                inputTextChain = inputTextChain + '\n' + item
            }
            counter += 1;
            return true;
        })
        if(counter > 30) data['rewrite_num'] = 1;
        else if(counter > 20) data['rewrite_num'] = 3;
        if(counter > 0 && counter < 60){
            data['input'] = inputTextChain;
            let formData = {
                method: 'post',
                body: JSON.stringify(data)
            }
            //console.log('input', formData)
            var response = await requestAPI(url, formData);
            let result = await response.json();
            console.log('result', result)
            if (result !== undefined && result.length > 0){
                //var update = {};
                this.props.params.map((p) => {
                    //update[p] = this.props.firebase.fieldValue.arrayUnion(...result);
                    var myArrayOld = [...this.state[(p)]];
                    var myArrayNew = [...this.state[(p+'Change')]];
                    myArrayOld = myArrayOld.concat(...result)
                    myArrayNew = myArrayNew.concat(...result)
                    //console.log('myArray update', myArrayNew, myArrayOld)
                    this.setState({
                        [(p+'Change')]: myArrayNew,
                        [(p)]: myArrayOld
                    });
                    return true;
                })
                //console.log('update', update)
                //this.props.dbUpdateLink.update(update);
            }
            this.setState({loadingNewSentences : false})
            return response;
        }
        else{
            this.setState({fordbidNewSentences : true})
        }
        return false
    }

}

function requestAPI(url, options) {
    //console.log('options', options)
    //console.log(options)
    return fetch(url, {
        mode: 'cors',
        headers: {
            //'Access-Control-Allow-Origin': '*',
            //'Access-Control-Allow-Headers': "*",
            'Content-Type': 'application/json',
        },
        ...options
    });
}


SettingContentArray.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(SettingContentArray);
