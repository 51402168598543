function muiStyle (palette) { return {
    color: palette.black,
    root: {
        "&.Mui-disabled .MuiStepLabel-label": {
            color: palette.themeGrey
        },
    },
    label: {
        color: palette.black,
        "&.MuiStepLabel-active": {
            color: palette.secondary.main
        },
        "&.MuiStepLabel-completed": {
            color: palette.themeAlertGreen
        },
        "&.MuiStepLabel-alternativeLabel": {
            fontSize: 16
        }
    },
}}

export default muiStyle;