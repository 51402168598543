import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { UserStageRequirements, UserSessionLevel } from '../../Admin/QuestionUtterances';

import { AuthUserContext } from '../../Session';
import { withFirebase } from '../../Firebase';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';


import TextField from '@material-ui/core/TextField';

import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import DialogIntentSummary from '../Modules/DialogIntentSummary';

// radio
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

//expansion
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import IconButton from '@material-ui/core/IconButton';
//import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import BorderColorTwoToneIcon from '@material-ui/icons/BorderColorTwoTone';

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LockIcon from '@material-ui/icons/Lock';

import { indexOf } from 'lodash';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});

class SettingContentArrayCheckbox extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            defaultValue: [],
            openSetting: false,
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    // Username Handling
    handleSettingOpen = () => {
        this.props.params.map((p) => {
            if(this.props.content[p]!== undefined){
                return this.setState({[p] : this.props.content[p], [(p+'Change')]: this.props.content[p]});
            }
            else{
                return this.setState({[p] : this.state.defaultValue, [(p+'Change')]:this.state.defaultValue});
            }
        })
        this.setState({
            openSetting: true,
            searchTag: this.props.searchTag
        });
    }

    handleChangeCheckbox = (param, value, event) => {
        if(value !== undefined){
            var myArray = [...this.state[(param+'Change')]];
            var checked = event.target.checked;
            var newArray = [value];
            if(checked){
                var arrayIndex = myArray.indexOf(value);
                if(this.props.selectMaxOne){
                    this.setState({
                        [(param+'Change')]: newArray
                    });
                }
                else if( arrayIndex < 0){
                    myArray = [...myArray, ...newArray];
                    this.setState({
                        [(param+'Change')]: myArray
                    });
                }
            }
            else{
                //console.log(myArray, newArray)
                var arrayIndex2 = myArray.indexOf(value);
                if( arrayIndex2 > -1){
                    myArray[arrayIndex2] = "";
                    this.setState({
                        [(param+'Change')]: myArray
                    });
                }
                //myArray = myArray.filter(x=> !newArray.includes(x));
                //console.log('after',myArray)
                //this.setState({  [(param+'Change')]: myArray  });
            }
        }
        else{
            console.log('checkbox undefined value')
        }
    };

    handleSettingChange = (param, index, event) => {
        var myArray = [...this.state[(param+'Change')]];
        var newValue = event.target.value;
        if(newValue !== undefined){
            if(index === undefined || index === null){
                //used for the admin extra field
                this.setState({
                    [(param+'Extra')]: newValue
                });
            }
            else{
                if(index > (this.state[(param+'Change')].length) && newValue !== ''){
                    myArray.push(newValue)
                    this.setState({
                        [(param+'Change')]: myArray
                    });
                } 
                else if(newValue !== undefined){
                    myArray[index] = newValue;
                }
                this.setState({
                    [(param+'Change')]: myArray
                });
            }
        }
        else{
            console.log('Error new value is undefined')
        }
    }

    handleSettingClose = () => {
        this.setState({
            openSetting: false,
        });
        this.props.params.map((p) => {
            return this.setState({[p] : this.state.defaultValue, [p+'Change']:this.state.defaultValue});
        })
    }

    handleSettingSubmit = () => {
        var closeReady = true;
        this.props.params.map((p) => {
            var addExtra = false;
            if(Object.keys(this.state).includes(p+'Extra')){
                if(this.state[(p+'Extra')] !== ""){
                    addExtra = true;
                }
            }
            if(addExtra ||
                    (this.state[(p+'Change')] !== null && this.state[(p+'Change')].length>0 && this.state[(p+'Change')] !== undefined && this.state[(p+'Change')] !== this.props.content[p])
                ){
                var newArray = this.state[(p+'Change')].filter(function(v){return (v!=='' && v!==undefined)});
                if(addExtra){
                    var extraValue = this.state[(p+'Extra')];
                    //console.log("extra", extraValue);
                    newArray.push(extraValue);
                }
                if(this.props.sorting){
                    newArray.sort();
                }
                if(!this.props.selectAtLeastOne || (newArray!== undefined && newArray.length>=1)){
                    //console.log('at least one')
                    var updateSet = { [p]: newArray };
                    if(this.props.updateExtraChange !== undefined && typeof this.props.updateExtraChange === 'object'){
                        Object.assign(updateSet, this.props.updateExtraChange)
                    }
                    if(this.props.updateState !== undefined){
                        this.props.updateState(updateSet);
                    }
                    if( this.props.dbUpdateLink !== undefined){
                        this.props.dbUpdateLink.update(updateSet);
                    }
                }
                else{
                    closeReady = false;
                    this.setState({errorMessage: 'Need at least one item selected.'})
                }
                //console.log('new setting', newArray)
            }
            return '';
        })
        if (closeReady) this.handleSettingClose();
    }

    updateLifeStageButtons = (event, requirements) => {
        event.stopPropagation();
        if(requirements){
            //console.log('after', requirements)
            if(this.props.updateState !== undefined){
                this.props.updateState({requirements: requirements});
            }
            if( this.props.dbUpdateLink !== undefined){
                this.props.dbUpdateLink.update({requirements: requirements});
            }
        }
    }

    updateSessionButtons = (event, session) => {
        event.stopPropagation();
        if(session){
            //console.log('after', session)
            if(this.props.updateState !== undefined){
                this.props.updateState({session: session});
            }
            if( this.props.dbUpdateLink !== undefined){
                this.props.dbUpdateLink.update({session: session});
            }
        }
    }

    render() {
        const { content, params, title, selectOptions, selectOptionsText, selectOptionsGroups, selectOptionsGroupsText, labels, groupFilter } = this.props;
        const { theme, classes } = this.props;
        //console.log('CHECKBOX props', this.props, this.state)
        return (
        content
            ?
            <div className={classes.grow}>
                <Card 
                    style={{
                        ...theme.cardAdminSettings, 
                        backgroundColor: ((this.props.access === false) ? 'transparent': theme.cardAdminSettings.backgroundColor), 
                        cursor: ((this.props.access === false) ? 'none' : 'pointer')
                    }} 
                    onClick={(this.props.access === undefined || this.props.access === true) ? this.handleSettingOpen : void(0)}
                >
                    <Grid container>
                        <Grid item xs={9} >
                            <h6><a to="#">{title}</a>
                            {this.props.mandatory 
                                &&  <strong style={{color:theme.palette.themeRed}}>&nbsp;*</strong>
                            }
                            {this.props.mandatory===false
                                &&  <span style={{fontSize: 'small', fontWeight:'normal'}}>&nbsp;(optional)</span>
                            }
                            </h6>
                          </Grid>
                        <Grid item xs={1}>  </Grid>
                        <Grid item xs={2} style={{textAlign: 'right', marginTop: -10}}>
                            {(this.props.access === undefined || this.props.access === true)
                            ?
                                <Tooltip title={'Edit/Change'}>
                                    <IconButton onClick={this.handleSettingOpen}> 
                                        <BorderColorTwoToneIcon fontSize="small" style={{color:"red"}}/>
                                    </IconButton>
                                </Tooltip>
                            :   <span style={{fontSize: 'small', fontWeight:'normal'}}>Read-only</span>
                            }
                        </Grid>
                        {params.map((p, index) => (
                            <React.Fragment key={"list_"+index}>
                                <Grid item xs={12} key={"current_"+p}>
                                    <p style={theme.textSettings} key={"current_"+p}>
                                        {content[p]!== undefined && content[p] instanceof Array
                                            ? 
                                                <span style={{ float: 'right', fontWeight: 700, maxWidth:'75%' }}>
                                                    {content[p].map( (item, index) => {
                                                        if( this.props.hideUnavailable && selectOptions !== undefined && selectOptions.indexOf(item) >= 0){
                                                            return(
                                                                <li key={"sort_"+index}>
                                                                    {  item   }
                                                                </li>
                                                            )
                                                        }
                                                        else if(this.props.hideUnavailable !== true){
                                                            return(
                                                                <li key={"sort_"+index}>
                                                                    {
                                                                        selectOptions!== undefined && selectOptions.indexOf(item) >= 0
                                                                        ?   selectOptionsText !== undefined 
                                                                            ?   <span>{selectOptionsText[ selectOptions.indexOf(item) ]} </span>
                                                                            :   item
                                                                        :   <span style={{color: "orange"}}>{item?.substring(0,5)}... - No change rights</span>
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                        else{
                                                            return ""
                                                        }
                                                    })}
                                                    {/**
                                                     
                                                    [{content[p].join(', ')}]
                                                    */} 
                                                </span>
                                            :   <span style={{ float: 'right'}}>{this.props.emptyStage || "[n/a]"}</span>
                                        }
                                        {this.props.noLabel !== true &&
                                            <span>{labels!==undefined? labels[index]: p}</span>
                                        }
                                    </p>
                                </Grid>
                                <Grid item xs={12} key={"currentIcons_"+p}>
                                    {this.props.showLifeStageButtons
                                    &&
                                        <div style={{textAlign: 'right', marginTop: 20}}>
                                            {UserStageRequirements(this, content[p] || undefined)}
                                        </div>
                                    }
                                    {this.props.showSessionLevelButtons
                                    &&
                                        <div style={{textAlign: 'right', marginTop: 20}}>
                                            {UserSessionLevel(this, content[p] || undefined)}
                                        </div>
                                    }
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                </Card>
                <Dialog
                    open={this.state.openSetting}
                    onClose={this.handleSettingClose}
                    fullWidth
                    //maxWidth="sm"
                    >
                    <DialogTitle>Change Setting</DialogTitle>
                    <form>
                    <DialogContent>
                        {groupFilter !== undefined && groupFilter.length>0 &&
                            <FormControl component="fieldset">
                                <p>Filter Selection</p>
                                <RadioGroup row aria-label="Type" name="type"
                                    value={this.state.filterByGroup? this.state.filterByGroup : ''}
                                    onChange={(e) => {this.setState({filterByGroup: e.target.value}) } }
                                    >
                                    <FormControlLabel value={''} key={'all'} control={<Radio color='secondary' />} label={'All'} />
                                    {groupFilter.map((group, index) => (
                                        <FormControlLabel value={group} key={group+'_'+index} control={<Radio color='secondary' />} label={group} />
                                    ))}
                                </RadioGroup>
                                <p></p>
                            </FormControl>
                        }
                        {params !== undefined && params.map((p, index) => (
                                <div style={{width: `100%`}} key={"change_"+p+index}> 
                                    {this.props.noLabel !== true
                                        && <strong>{labels!==undefined? labels[index]: p}</strong>
                                    }
                                    <br/><br/>
                                    {this.props.selectMaxOne
                                        ?   'Select one item only'
                                        :   'Multiple selection possible'
                                    }
                                    &nbsp;
                                    {(this.context.roles?.includes("ADMIN") || this.context.roles?.includes("TEAM"))
                                        &&
                                            <TextField
                                                style={{float: 'right',   width:100, fontSize: '16px'}}
                                                value={this.state.searchTagTemp || ''}
                                                label={'filtervalue'}
                                                onChange={(e) => {
                                                    var newSearchValue = e.target.value;
                                                    this.setState({searchTagTemp: newSearchValue});
                                                    setTimeout(() => {
                                                        if(this._isMounted && this.state.searchTag !== newSearchValue && this.state.searchTagTemp === newSearchValue){
                                                            this.setState({searchTag: newSearchValue});
                                                            console.log('searcg', newSearchValue)
                                                        }
                                                    }, 1000, newSearchValue);
                                                }}
                                            />
                                    }
                                    <br/>
                                    <FormControl component="fieldset">
                                        {selectOptionsGroups !== undefined && Object.keys(selectOptionsGroups).length > 0
                                        ?
                                            Object.keys(selectOptionsGroups).map( (group, index) => (
                                                <Accordion 
                                                    key={"accord_"+group+'_'+index}
                                                    expanded={(this.props.selectPreOpen && selectOptionsGroups[group].some(entry => this.state[p]?.includes(entry)) === true) ? true : undefined}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-label={"Account-Expand_"+group}
                                                        aria-controls={"Account-content_"+group}
                                                        id={"Account-header_"+group}
                                                    >
                                                        Group: {group}
                                                    </AccordionSummary>
                                                    <AccordionDetails> 
                                                        <FormGroup  key={"option_"+group}>
                                                            <Grid container>
                                                                {selectOptionsGroups[group] !== undefined && selectOptionsGroups[group].map((entry, indexDeep) =>  (
                                                                    <React.Fragment key={'form_'+group+entry+"_"+indexDeep}>
                                                                        {(!(entry?.length > 0) 
                                                                            || 
                                                                            (this.state?.searchTag?.length > 1 && !(entry.includes(this.state.searchTag)) && 
                                                                            this.state[p] !== undefined && this.state[p] !== null && !(this.state[p].includes(entry) ))
                                                                            )
                                                                        ?   ""
                                                                        :
                                                                            this.state.filterByGroup !== undefined && this.state.filterByGroup.length > 1 && !entry.includes(this.state.filterByGroup)
                                                                            ? ""
                                                                            : 
                                                                            <React.Fragment key={"select_"+entry+indexDeep}>
                                                                                <Grid item xs={11}>
                                                                                    <FormControlLabel
                                                                                        key={"select_"+entry+indexDeep}
                                                                                        control={
                                                                                            (this.props.selectOptionsStickyFilters?.includes(entry) && this.state[p+'Change']?.includes(entry))
                                                                                            ?
                                                                                                <LockIcon style={{color: 'red', marginLeft: 14, marginRight: 15}} />
                                                                                            :
                                                                                                <Checkbox 
                                                                                                    checked={this.state[p+'Change'] !== undefined && this.state[p+'Change'] !== null && this.state[p+'Change']?.includes(entry) ? true : false}
                                                                                                    onChange={(e) => this.handleChangeCheckbox(p, entry, e)} 
                                                                                                    name={entry} 
                                                                                                    style={{color:'green'}}
                                                                                                    disabled = {(this.props.selectOptionsStickyFilters?.includes(entry) && this.state[p+'Change']?.includes(entry)) ? true : false}
                                                                                                />
                                                                                        }
                                                                                        label={
                                                                                            <span style={{backgroundColor: this.state[p] !== undefined && this.state[p] !== null && this.state[p].includes(entry) ? "yellow" : 'white'}}>
                                                                                                {selectOptionsGroupsText!== undefined && selectOptionsGroupsText[group] !== undefined && selectOptionsGroupsText[group][indexDeep]?.length > 0
                                                                                                    ? 
                                                                                                        <strong>
                                                                                                            {
                                                                                                                selectOptionsGroupsText[group][indexDeep] > 30 
                                                                                                                    ? selectOptionsGroupsText[group][indexDeep].substring(0,30) + "..."
                                                                                                                    : selectOptionsGroupsText[group][indexDeep]
                                                                                                            }
                                                                                                            <br/>
                                                                                                        </strong>
                                                                                                    :
                                                                                                        (entry?.length > 0)
                                                                                                            ?   entry
                                                                                                            :   "n/a"
                                                                                                }
                                                                                            </span>
                                                                                            }
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    {this.props.showIntentDetails === true &&
                                                                                        <ContactSupportIcon onClick={() => this.setState({openDialogueIntents: true, intentClassName: entry}) }/>
                                                                                    }
                                                                                </Grid>
                                                                            </React.Fragment>
                                                                        }
                                                                    </React.Fragment>
                                                                ) )}
                                                            </Grid>
                                                        </FormGroup>
                                                    </AccordionDetails>
                                                </Accordion> 
                                            ))
                                        :
                                            <FormGroup>
                                                <Grid container>
                                                    {selectOptions !== undefined && selectOptions.map((entry, indexDeep) =>  (
                                                        (   !(entry?.length > 0) 
                                                            || 
                                                            ((this.state?.searchTag?.length > 1 && !(entry?.includes(this.state.searchTag)) && !(selectOptionsText[indexDeep]?.includes(this.state.searchTag))) 
                                                                || (this.state.filterByGroup !== undefined && this.state.filterByGroup.length > 1 && !entry.includes(this.state.filterByGroup)))
                                                        )
                                                        ?    ""
                                                        :
                                                            <React.Fragment key={"select_"+entry+'_'+indexDeep}>
                                                                {this.props.showIntentDetails === true &&
                                                                    <React.Fragment key={"select_all"+indexDeep}>
                                                                        <Grid item xs={11}>
                                                                        </Grid>
                                                                        <Grid item xs={1}> 
                                                                            <ContactSupportIcon onClick={() => this.setState({openDialogueIntents: true, intentClassName: entry}) }/>
                                                                        </Grid>
                                                                    </React.Fragment>
                                                                }
                                                                <Grid item xs={12}>
                                                                    <FormControlLabel
                                                                        key={"select_"+entry}
                                                                        control={
                                                                            (this.props.selectOptionsStickyFilters?.includes(entry) && this.state[p+'Change']?.includes(entry))
                                                                            ?
                                                                                <LockIcon style={{color: 'red', marginLeft: 14, marginRight: 15}} />
                                                                            :
                                                                                <Checkbox 
                                                                                    checked ={this.state[p] !== undefined && this.state[p+'Change']!== undefined && this.state[p+'Change'] !== null && this.state[p+'Change'].includes(entry) ? true : false}
                                                                                    onChange ={(e) => this.handleChangeCheckbox(p, entry, e)} 
                                                                                    name={entry} 
                                                                                    style={{color:'green'}}
                                                                                    disabled = {(this.props.selectOptionsStickyFilters?.includes(entry) && this.state[p+'Change']?.includes(entry)) ? true : false}
                                                                                />
                                                                        }
                                                                        label={
                                                                            <span style={{backgroundColor: this.state[p] !== undefined && this.state[p] !== null && this.state[p].includes(entry) ? "yellow" : 'white'}}>
                                                                                { selectOptionsText !== undefined && selectOptionsText[indexDeep] !== undefined && selectOptionsText[indexDeep]  !== ""
                                                                                    ? 
                                                                                        <strong>
                                                                                            {
                                                                                                selectOptionsText[indexDeep] > 30 
                                                                                                    ?   selectOptionsText[indexDeep].substring(0,30) + "..."
                                                                                                    :   selectOptionsText[indexDeep]
                                                                                            }
                                                                                            <br/>
                                                                                        </strong>
                                                                                    :   entry
                                                                                }
                                                                            </span>}
                                                                    />
                                                                </Grid>
                                                            </React.Fragment>
                                                    ) )}
                                                </Grid>
                                            </FormGroup>
                                        }
                                    </FormControl>
                                    {(this.props.addManually || this.context.roles?.includes("ADMIN") || this.context.roles?.includes("TEAM"))
                                        &&
                                        <Accordion key={"accord_quick"}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-label={"Account-quick"}
                                                aria-controls={"Account-quick"}
                                                id={"Account-quick"}
                                                >
                                                Add Item Manually
                                            </AccordionSummary>
                                            <AccordionDetails> 
                                                {params.map((p) => (
                                                    <div style={{width: `100%`}} key={"change_"+p}> 
                                                        Array for: <strong>{p}</strong> (Erase, modify or add)
                                                        {this.state[p+'Change'] !== undefined && Array.isArray(this.state[p+'Change']) 
                                                        && this.state[p+'Change'].map((x, index) => (
                                                            <div key={"org_"+index}> 
                                                                {this.props.noLabel
                                                                    ?
                                                                        "" + index
                                                                    :
                                                                        <strong>{this.state[p]!== undefined && this.state[p].length > index ? this.state[p][index] : indexOf}</strong>
                                                                }
                                                                <TextField
                                                                    style={{ float: 'right', width: this.props.noLabel?'90%':'60%', fontSize: '16px'}}
                                                                    fullWidth
                                                                    key={"change_"+index}
                                                                    id={"change_"+index}
                                                                    defaultValue={x}
                                                                    label={""}
                                                                    onChange={(e) => this.handleSettingChange(p, index, e)}
                                                                />
                                                            </div>
                                                        ))}
                                                        <TextField
                                                            style={{ float: 'right', width:'60%', fontSize: '16px'}}
                                                            fullWidth
                                                            key={"change_new"}
                                                            id={"change_new"}
                                                            label={'add new'}
                                                            onChange={(e) => this.handleSettingChange(p, undefined, e)}
                                                        />
                                                    </div>
                                                ))  }
                                            </AccordionDetails>
                                        </Accordion> 
                                }
                                </div>
                            ))
                        }

                        {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                            ?
                                <p style={theme.textErrorCenterPadding}>
                                    {this.state.errorMessage}
                                </p>
                            :   <p> </p>
                        }
                    </DialogContent>
                    </form>
                    <DialogActions>
                        <Button onClick={this.handleSettingClose}>
                            Cancel
                        </Button>
                        <Button onClick={this.handleSettingSubmit}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                
                <DialogIntentSummary
                    openDialogueUtterance = {this.state.openDialogueUtterance || false}
                    openDialogueIntents = {this.state.openDialogueIntents || false}
                    //selectedText = {this.state.selectedText || ""}
                    intentClassName = {this.state.intentClassName || undefined}
                    //intentUser = {this.props.intentUser || undefined}
                    //handlerRemoveItem = {(item, replaceId) => {this.removeItemFromList(item, replaceId)}}
                    closeDialogue = {() => {this.setState({openDialogueUtterance: false, openDialogueIntents:false})}}
                    />
            </div>
        : <div> </div>
                
        );
    }
}

SettingContentArrayCheckbox.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(SettingContentArrayCheckbox);
