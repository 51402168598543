import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import PropTypes from 'prop-types';

import MapRender from '../Days/MapRender';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});



class MapQualities extends Component {
    static contextType = AuthUserContext;
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            activeStep : 0,
            loading: false,
            loadingText: "loading journey"
        };
    }

    componentDidMount() {
    }
    shouldComponentUpdate(nextProps, nextState) {  
        if (nextProps.rerender === false) {  
            //console.log('no rerender')
            return false;  
        }  
        //console.log(' rerender')
        return true;  
    }

    render() {
        //const {theme} = this.props;
        //var index = 1;

        return (
            <div>
                <MapRender 
                    user={this.context} 
                    leadershipCategories={this.props.leadershipCategories}
                    leadershipQualities={this.props.leadershipQualities}
                    renderMapType={'Qualities'}//one word
                    filterTrueCoachUtter={['qualit', 'awareness', 'skill', 'topic', 'improvement','challenge', 'expectation']}//string search
                    filterTrueIntentCoach={['qualit', 'awareness', 'skill', 'topic','improvement', 'challenge', 'expectation']}//string search
                    filterTrueEntityCoach={['skill']}//need exact match
                    filterTrueIntentUser={['conclusion', 'skill', 'quality','mindset']}//string search
                    filterTrueEntityUser={['skill']}//need exact match
                    filterFalseIntentUser={['prio', 'task', 'activi','goal']}//string search
                />

                <div  style={{marginTop: 30 }}>
                    {/***
                    <Accordion style={{boxShadow:'none'}}
                            onChange={(e,expanded) => {this.setState({additional_note_expanded: expanded})}}>
                        <AccordionSummary
                            expandIcon={this.state.additional_note_expanded? <CancelIcon /> : <AddCircleOutlineIcon />}
                            aria-label="About-Expand"
                            aria-controls="About-content"
                            id="About-header"
                            >
                            Add additional note
                        </AccordionSummary>
                        <AccordionDetails  style={{padding:0}} >        
                            <DiaryManual />
                        </AccordionDetails>
                    </Accordion>
                    */}
                </div>
            </div>
        );
    }

  
}

MapQualities.propTypes = {
    classes: PropTypes.object.isRequired,
};
  

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(MapQualities);
