//import palette from '../palette';

function muiStyle (palette) { return {
    root: {
        color: palette.secondary.main,
        colorPrimary: {
            '&$checked': {
                color: palette.themeText
            },
            color: palette.themeText
        }
    }
}}

export default muiStyle;