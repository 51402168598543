import React, { Component } from 'react';
import { compose } from 'recompose';
//import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../Firebase';
import DayItem from '../Days/DayItem';
import { DataCacheWrite} from "../Modules/DataCache";

import Grid from '@material-ui/core/Grid';
//import Paper from '@material-ui/core/Paper';

import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';

const styles = {
    date: {
        color: 'black',
        fontSize: '0.8em',
        marginBottom: 0,
        paddingTop: 10,
        paddingBottom: 0,
    },
    paper: {
        backgroundColor: 'milk',
        borderRadius: 0,
        marginLeft: 20,
        paddingBottom: 10,
        paddingRight: 10,
        textAlign: 'center',
    },
    priming1: {
        background: 'linear-gradient(90deg, transparent 80%, rgba(192,233,252,1) 80%)',
        paddingRight: 10,
        textAlign: 'right',
        cursor: 'pointer',
    },
    reflection1: {
        background: 'linear-gradient(90deg, #7394C6 20%, transparent 20%)',
        paddingLeft: 10,
        textAlign: 'left',
        cursor: 'pointer',
    },
}


class DayList extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.last7Reflections = [3];
        this.last7Primings = [3];
        this.state = {
            loading: true,
            days: [],
            lastXXDays: [],
            uid: this.props.userId
        };
    }

    componentDidMount() {
        this._isMounted = true;
        //console.log("home stats props", this.props)
        this.getLatestDialogues(this.state.uid);
    }

    componentDidUpdate(prevProps)  {
        if(this.props.userId !== undefined && this.props.userId !== "" && this.props.userId !== prevProps.userId && this.props.userId !== this.state.userId){
            //console.log('update dielg')
            this.setState({uid: this.props.userId})
            this.getLatestDialogues(this.props.userId);
        }
        return true
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.setState({ loading: true, lastXXDays: [] });
        //window.removeEventListener('scroll', this.handleScroll);
    }

    getLatestDialogues = (uid) =>  {
        ////// 180 days in the past max
        let startDate = new Date(Date.now() - (180 * 24 * 60 * 60 * 1000));
        let endDate = new Date(Date.now() + (1 * 24 * 60 * 60 * 1000)); 
        ///// write into cache parameter set and Loading ///////////////////
        let endedDialogues = {};
        //////////////////////////////////////////////////////////////////
        this.unsubscribeDialogues = this.props.firebase
        .dialogues()
        .orderBy('createdAt', 'desc')
        .where('userId', '==', uid)
        //.where('dialogueEnded', '==', true)
        .limit(60)
        .get().then(snapshot => {
            if (snapshot.size) {
                let days = [];
                snapshot.forEach(doc =>{
                    var singleDialogue = doc.data();
                    if(singleDialogue.measurement===undefined || singleDialogue.measurement<1 || singleDialogue.measurement>5){
                        singleDialogue.measurement = 3;
                    }
                    days.push({ ...singleDialogue, uid: doc.id })
                    //console.log('singleDialogue', singleDialogue)
                });
                let lastXXDays = this.getDateArray(startDate, endDate, days);
                if(this._isMounted ){
                    this.setState({
                        days,
                        loading: false,
                        lastXXDays
                    });
                }
                if(uid === this.context.uid){
                    DataCacheWrite('latestDialogues',days, 1800)
                }
                ///// write into cache parameter set and Loading ///////////////////
                //endedDialogues['lastXXDays'] = lastXXDays;
                endedDialogues['days'] = days;
                //endedDialogues['loading'] = false;
                //console.log('days data',lastXXDays);
            } 
            else if(this._isMounted ){
                this.setState({ days: null, loading: false, lastXXDays: [] });
            }
        });
    }
    getDateArray = (start, end, days) => {
        let arr = [];
        let dt = new Date(start);
        if (days) {
            while (dt <= end) {
                //console.log(days)
                var primingValue = this.getPrimingSession(dt, days);
                var reflectionValue = this.getReflectionSession(dt, days);
                if(primingValue!== undefined && primingValue.measurement > 0 ) this.last7Primings.push(primingValue.measurement);
                if(reflectionValue!== undefined && reflectionValue.measurement > 0 ) this.last7Reflections.push(reflectionValue.measurement);
                var dialogueEnded = false;
                if(primingValue!== undefined && primingValue.phase === 'session_ended') dialogueEnded = true;
                if(reflectionValue!== undefined && reflectionValue.phase === 'session_ended') dialogueEnded = true;
                if(this.last7Primings.length>7) this.last7Primings.shift();
                if(this.last7Reflections.length>7) this.last7Reflections.shift();
                //console.log(this.last7Primings, primingValue)
                dt.setDate(dt.getDate() + 1);
                arr.push([new Date(dt), primingValue , reflectionValue, this.last7Primings.reduce((a,v,i)=>(a*i+v)/(i+1)), this.last7Reflections.reduce((a,v,i)=>(a*i+v)/(i+1)), dialogueEnded]);
            }
        } 
        else {
            return arr;
        }
        return arr.reverse();
    }
    getPrimingSession = (day, days) => {
        let obj =
            days.find(
            d =>
                new Date(d.createdAt.seconds * 1000).getDate() === day.getDate() &&
                new Date(d.createdAt.seconds * 1000).getMonth() === day.getMonth() &&
                d.dialogueType === "priming");
        //console.log(obj)
        return obj;
    }
    getReflectionSession = (day, days) => {
        let obj =
            days.find(
            d =>
                new Date(d.createdAt.seconds * 1000).getDate() === day.getDate() &&
                new Date(d.createdAt.seconds * 1000).getMonth() === day.getMonth() &&
                d.dialogueType === "reflection");
        return obj;
    }

    render() {
        //console.log('Days', this.state)
        const {lastXXDays} = this.state;
        return(
            <div style={{paddingTop: 40, paddingBottom: 20}}>
                <div>
                    {!(lastXXDays?.length>0)
                    ?   "No chats recoreded yet"
                    :
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ width: '100%', backgroundColor: 'transparent' }}
                        >
                            <Grid item xs={6}>
                                <p style={{textAlign:"center"}}>Morning Energy </p>
                            </Grid>
                            <Grid item xs={6}>
                                <p style={{textAlign:"center"}}>Evening Satisfaction </p>
                            </Grid>
                            <Grid item xs={1} style={{textAlign:"left", color:"#FCD730"}} >
                                < SentimentVerySatisfiedIcon />
                            </Grid>
                            <Grid item style={{textAlign:"center", color:"#FCD730"}} >
                                < SentimentSatisfiedAltIcon />
                            </Grid>
                            <Grid item xs={3} style={{textAlign:"center", color:"#FCD730"}} >
                                < SentimentVeryDissatisfiedIcon />
                            </Grid>
                            <Grid item style={{textAlign:"center", color:"#FCD730"}}  >
                                < SentimentSatisfiedAltIcon />
                            </Grid>
                            <Grid item xs={1} style={{textAlign:"right", color:"#FCD730"}}  >
                                < SentimentVerySatisfiedIcon />
                            </Grid>
                        </Grid>
                    }
                </div>
                {lastXXDays.map(day => (
                    (day[1] || day[2])
                        ?
                            <DayItem
                                isCoach = {this.props.isCoach}
                                user = {this.props.user || undefined}
                                key={day[0].toString()}
                                dayDate={day[0]}
                                priming={day[1]}
                                ended={day[5] || false}
                                reflection={day[2]}
                                averagePriming={day[3]}
                                averageReflection={day[4]}
                            />
                        :   ""
                ))}
            </div>
        )
    }// end of render
}// end of class

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(DayList);
