import React, { Component } from "react";
import { AddAnalytics } from '../Modules/AddAnalytics';
//import { withRouter } from "react-router-dom";

import { AuthUserContext } from '../Session';
import { compose } from "recompose";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
/// Tabs
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
//import PropTypes from "prop-types";
// auto push after reload with social
//// List lements
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import SendIcon from '@material-ui/icons/Send';

import {RenderMediaYoutube} from  '../Content/RenderMedia';

//import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

//stepper and views
import MobileStepper from '@material-ui/core/MobileStepper';
import { autoPlay } from 'react-swipeable-views-utils';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';

import LoadingProgress from '../Modules/LoadingProgress';
import Card from '@material-ui/core/Card';

//import backgroundImageWelcome from "../../images/login-background.jpg";
import logo from "../../images/rockyai-logo-image-only.png";
import logoCaption from "../../images/rockyai-logo-caption.png";

// MUI stuff
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
//import VpnKeyIcon from '@material-ui/icons/VpnKey';

import {IsMobileDevice} from '../Modules/IsMobileDevice';

import SnackbarInfo from '../Modules/SnackbarInfo';

const styles = theme => ({
    root: {
        //backgroundImage: `url(${backgroundImageWelcome})`,
        //backgroundPosition: "center",
       // backgroundSize: "cover",
        //backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        height: "100vh",
        //overflowY: 'auto',// for scroll top
    },
    rootDesktopView: {
        //backgroundImage: `url(${backgroundImageWelcome})`,
        //backgroundPosition: "center",
       // backgroundSize: "cover",
        //backgroundRepeat: "no-repeat",
        minHeight: "100%",
        height: "100%",
        //overflowY: 'auto',// for scroll top
    },
    rootMobile: {
        minHeight: "100vh",
        height: "100vh",
        //overflowY: 'auto',// for scroll top
    },
});


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

class TutorialPage extends Component {
    _isMounted = false;
    static contextType = AuthUserContext;
    constructor(props) {
        super(props);
        this.state = {
            activeStep : 0,
            username: '',
            engagementCounterLevel: 0,
            badgeFamily: 'total'
        };
        //console.log(this.props.badgeFamily, 'family')
    }

    componentDidMount() {
        this._isMounted = true;
        var username= this.context.username;
        var engagementCounterLevel= this.context.engagementCounter;
        if (engagementCounterLevel === undefined){
            engagementCounterLevel = 0;
        }
        if (username === undefined){
            username = '';
        }
        var badgeFamily = "total";
        if(this.props.badgeFamily?.length>2) {
            badgeFamily = this.props.badgeFamily;
        }
        this.setState({username, engagementCounterLevel, badgeFamily})
        //console.log('origin props', this.props)
        this.loadWelcomeData(username,engagementCounterLevel);
        AddAnalytics('screen_view', 'PageTutorial');
    }

    componentWillUnmount(){
        this._isMounted = false;
    }
        
    loadWelcomeData(username, engagementCounterLevel) {
        //console.log('origin props', this.state, this.context, engagementCounterLevel)
        /// Tabs and Content
        var tutorialPages = {default: []};
        var tutorialCategoryNames = undefined;
        var tutorialCategories = [];
        var tutorialPrograms = ['default'];
        //var badgeTutorialPages = [];
        var badgeFamily = "total";
        var coachUser = false;
        if(this.props.badgeFamily?.length>2) {
            badgeFamily = this.props.badgeFamily;
            if(badgeFamily === 'teams'){
                coachUser = true;
            }
        }
        if(this.context.roles?.includes('REMOTECOACH') || this.context.roles?.includes('COACHADMIN') ){
            coachUser = true;
        }
        /////// Add the tutorial programs
        if(this.context.programs?.includes('default') && this.context?.hidePublicContent !== true ){
            tutorialPrograms.push('rocky');
        }
        this.context.programs?.map(item => {
            if(item !== 'default'){
                tutorialPrograms.push(item);
            }
            return true;
        })
        var badgeFamilies = [badgeFamily]
        if(this.props.addTotal === true){
            badgeFamilies = [badgeFamily, 'total']
        }
        //console.log('tutorialPrograms', tutorialPrograms, coachUser)
        this.unsubscribe = this.props.firebase
        .onboardingContents()
        .where('category', '==', 'tutorial')
        .where('active', '==', true)
        .where('programs', 'array-contains-any', tutorialPrograms)
        if(this.props.addDefaults === true){
            console.log('addDefaults')
        }
        else{
            console.log('n defaults')
            this.unsubscribe = this.unsubscribe 
            .where('badgeFamily', 'in', badgeFamilies)
            //.where('engagementCounter', '<=', engagementCounterLevel)
            .limit(20)
        }
        this.unsubscribe = this.unsubscribe 
        //.orderBy('engagementCounter', 'desc')
        .orderBy('engagementCounter', 'asc')
        .get()
        .then(snapshot => {
            if (snapshot.empty) {
                tutorialPages['total'].push({ text: "Tutorial Information", pid: "default" });
                return;
            }
            snapshot.forEach(doc => {
                var dataSet = doc.data();
                //var dataBadgeFamily = dataSet.badgeFamily || 'total';
                var dataBadgeFamily = dataSet.badgeFamily || 'total';
                var dataBadgeSorting = dataSet.h1 || dataBadgeFamily;
                if(this.state.badgeFamily === dataSet.badgeFamily && this.state.badgeFamily !== dataBadgeSorting){
                    this.setState({badgeFamily: dataBadgeSorting})
                }
                //console.log('dataBadgeFamily', dataBadgeFamily)
                if(dataSet?.h1?.length>2 && this.props.addDefaults !== false){
                    if(dataBadgeFamily === 'teams' && !coachUser){
                        //console.log('skip for coach')
                    }
                    else{
                        if(tutorialCategoryNames === undefined){
                            tutorialCategoryNames = {};
                        }
                        tutorialCategoryNames[dataBadgeSorting] = dataSet.h1;
                        if(!tutorialCategories.includes(dataBadgeSorting)){
                            tutorialCategories.push(dataBadgeSorting);
                        }
                    }
                    //console.log('tutorialCategories', tutorialCategories)
                }
                if(tutorialPages[dataBadgeSorting] === undefined){
                    tutorialPages[dataBadgeSorting] = [];
                }
                if(dataBadgeFamily === badgeFamily){
                    tutorialPages[dataBadgeSorting].push({ ...doc.data(), pid: doc.id });
                    //badgeTutorialPages.unshift({ ...doc.data(), pid: doc.id });
                }
                else if(this.props.addDefaults){
                    if(dataBadgeFamily.length > 1){
                        tutorialPages[dataBadgeSorting].push({ ...doc.data(), pid: doc.id });
                    }
                    else{
                        tutorialPages['total'].push({ ...doc.data(), pid: doc.id });
                    }
                    //tutorialPages.unshift({ ...doc.data(), pid: doc.id });
                }
            });
            var selectedPages = tutorialPages[this.state.badgeFamily] || tutorialPages['total'];
            //console.log(tutorialPages,badgeTutorialPages )
            //tutorialPages = badgeTutorialPages.concat(tutorialPages);
            if(this._isMounted){
                this.setState({ 
                    tutorialPages,
                    selectedPages,
                    tutorialCategories,
                    tutorialCategoryNames
                });
            }
            //console.log('this.state.selectedPages', this.state.tutorialPages)
        });
    }    

    render() {
        const { classes, theme } = this.props;
        console.log('tutpiral page this.props', this.props)
        return (
            <div className={IsMobileDevice() ? classes.rootMobile : this.props.showDesktop === true ? classes.rootDesktopView : classes.root}
                ref={el => this.dialogRef = el} >
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {this.props.showStepper !== false && this.state.selectedPages !== undefined 
                        ?
                            <div style={{
                                    ...theme.MobileStepperAppBarMobile,
                                    position: 'absolute' ,
                                    zIndex: 100 ,
                                    opacity: '0.94',
                                    borderRadius: theme.borderRadius ,
                                }}>
                                    <MobileStepper
                                        variant="dots"
                                        steps={this.state.selectedPages.length}
                                        position="static"
                                        style={{
                                            backgroundColor: theme.palette.secondary.main,
                                            borderRadius: theme.borderRadius ,
                                        }}
                                        activeStep={this.state.activeStep || 0}
                                        nextButton={
                                            this.state.activeStep === this.state.selectedPages.length - 1 
                                            ?
                                                <Button onClick={() => 
                                                    {
                                                        var catIndex = 0;
                                                        catIndex = this.state.tutorialCategories?.indexOf(this.state.badgeFamily) || 0;
                                                        var key = 'total';
                                                        //console.log("next", this.state)
                                                        if(this.state.tutorialCategories?.length <= 1){
                                                            if(this.props.handleClose!== undefined) this.props.handleClose()
                                                        }
                                                        else if(catIndex < 0 || catIndex === this.state.tutorialCategories.length){
                                                            key = this.state.tutorialCategories[0];
                                                            this.setState({
                                                                activeStep: 0, 
                                                                badgeFamily: key, 
                                                                selectedPages: (this.state.tutorialPages?.[key] || [])
                                                            })
                                                        }
                                                        else{
                                                            key = this.state.tutorialCategories[catIndex+1];
                                                            this.setState({
                                                                activeStep: 0, 
                                                                badgeFamily: key, 
                                                                selectedPages: (this.state.tutorialPages?.[key] || this.state.tutorialPages?.['total'] || [])
                                                            })
                                                        }
                                                    }} 
                                                    variant='outlined'
                                                >
                                                    Next
                                                    <KeyboardArrowRight />
                                                {/***
                                                <Button onClick={() => {
                                                        //this.setState({openAlert: true})
                                                        if(this.props.handleClose!== undefined) this.props.handleClose()
                                                    }} 
                                                    variant='outlined'>
                                                    Close
                                                    <KeyboardArrowRight />
                                                </Button>
                                                 */}
                                                </Button>
                                            :
                                                <Button onClick={this.handleNext} variant="contained">
                                                    Next
                                                    <KeyboardArrowRight />
                                                </Button>
                                        }
                                        backButton={
                                            <Button onClick={this.handleBack} disabled={this.state.activeStep === 0} variant='outlined'>
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                            </div>
                        : ''
                    }
                    {this.state.tutorialCategoryNames !== undefined && this.state.tutorialCategories?.length>0
                    &&
                        <Grid item xs={12} >
                            <h1 style={{...theme.textCenterPadding, marginTop: -6}}>
                                {this.state.tutorialCategories?.[(this.state.badgeFamily || 'total')]?.replace('{{username}}', this.state.username)}
                            </h1>
                            <Tabs
                                value={(this.state.badgeFamily || 'total')}
                                //value={this.state.tutorialCategories?.[this.state.badgeFamily] ? (this.state.badgeFamily || 'total') : (this.state.tutorialCategories?.[0] || 'total')}
                                //onChange={this.handleChange}
                                aria-label="Learning Content"
                                variant="scrollable"
                                scrollButtons={"auto"}
                                initialselectedindex={'Explore'}
                                ref={this.containerLine}
                                style={{
                                    marginTop: 20,
                                    marginBottom: 0,
                                    marginLeft: -20,
                                    width: 'calc(100% + 40px)'
                                }}
                            >
                                {this.state.tutorialCategories.map((key, index) => (
                                    <Tab 
                                        value={key} 
                                        key={key+'_'+index}
                                        label={this.state.tutorialCategoryNames[key]}
                                        onClick={() => this.setState({
                                            activeStep: 0, 
                                            badgeFamily: key, 
                                            selectedPages: (this.state.tutorialPages?.[key] || this.state.tutorialPages?.['total'] || [])
                                        })}
                                    />
                                ))}
                            </Tabs>
                            <div
                                style={{
                                    borderRadius: '0 0 '+theme.borderRadius+'px '+theme.borderRadius+'px',
                                    marginRight: -20,
                                    marginLeft: -20,
                                    paddingBottom: 8,
                                    marginBottom: 0,
                                    marginTop: 0,
                                    background : theme.palette.secondary.light
                                }}
                            ></div>
                        </Grid>
                    }
                    <div style={(IsMobileDevice() && this.props.showDesktop !== true) ? theme.OnboardMobile  :( this.props.showDesktop === true ? {} : theme.OnboardDesktop)}>
                        <Grid item xs={12} >
                            {this.state.selectedPages === undefined 
                                ?
                                    <div style={{paddingTop:50}}>
                                        {false &&
                                            <Link to={ROUTES.HOME}>
                                                <div style={theme.logoContainer}>
                                                <img src={logo} style={theme.logo} alt="Mentoring AI Logo Chatbot Journaling" />
                                                <img
                                                    src={logoCaption}
                                                    style={theme.logoCaption}
                                                    alt="AI Logo Leadership Coach"
                                                />
                                                </div>
                                            </Link>
                                        }
                                        Tutorials
                                        <hr />
                                    </div>
                                :   ''
                            }
                        </Grid>

                        <SnackbarInfo 
                            alertText = {"You need to gain more XP to unlock new features first!"}
                            openOnClick = {true}
                            alertOpenEvent = {this.state.openAlert || false}
                            closeHandler = {() => {this.setState({openAlert: false})}}
                        />
                        <Grid item xs={12} style={{width: "100%"}}> 
                            <Grid
                                container
                                spacing={0}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                style={{width: "100%", display: "-webkit-box"}}
                            >
                                {this.props.showDesktop === true &&
                                    <Grid item xs={4} 
                                        style={{maxWidth: '30%', paddingRight: 20, textAlign: 'left'}}
                                    > 
                                        {
                                            this.renderPagesNavi(this.state.activeStep )
                                        }
                                    </Grid>
                                }
                                <Grid item xs={(this.props.showDesktop === true) ? 8 : 12} 
                                    style={{
                                        maxWidth: (this.props.showDesktop === true ? '36vw' : '100%'), 
                                        textAlign: 'center',
                                        //borderStyle: 'dashed',
                                        //overflow: 'auto'
                                    }}
                                > 
                                {this.state.selectedPages !== undefined 
                                ?
                                    <SwipeableViews
                                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                        index={this.state.activeStep || 0}
                                        onChangeIndex={this.handleStepChange}
                                        enableMouseEvents
                                    >
                                        {this.state.selectedPages.map((step, index) => (
                                            <div key={index} >
                                            {Math.abs(this.state.activeStep - index) <= 2 
                                            ? (
                                                <div style={theme.divCenter}> 
                                                    {this.state.tutorialCategories === undefined && step.h1 !== undefined &&
                                                        <h1 style={{...theme.textCenterPadding, marginTop: -6}}>
                                                        {step.h1.replace('{{username}}', this.state.username)}</h1>
                                                    }
                                                    {step.h2 !== undefined &&
                                                        <h2 style={theme.textCenterPadding}>
                                                        {step.h2.replace('{{username}}', this.state.username)}</h2>
                                                    }
                                                    {step.h3 !== undefined &&
                                                        <h5 style={theme.textSupport}>
                                                        {step.h3.replace('{{username}}', this.state.username)}</h5>
                                                    }
                                                    {step.h4 !== undefined &&
                                                        <h6>
                                                        {step.h4.replace('{{username}}', this.state.username)}</h6>
                                                    }
                                                    {step.youtubeId !== undefined && step.youtubeId !== '' &&
                                                        <div style={{
                                                            marginRight: IsMobileDevice() ? 0 : 0,
                                                            marginLeft: IsMobileDevice() ? 0 : 0,
                                                            marginTop: 10,
                                                            width: '100%',
                                                            padding: 4
                                                        }}>
                                                            {
                                                                <RenderMediaYoutube
                                                                    contentData = {{
                                                                        youtubeId: step.youtubeId, 
                                                                        title: 'AI Coaching Tutorials',
                                                                        url: (step.imageMain !== undefined && step.imageMain !== ''  ? step.imageMain : undefined)
                                                                    }}
                                                                    enabled = {true}
                                                                />
                                                            }
                                                        </div>
                                                    }
                                                    {step.h5 !== undefined &&
                                                        <h5 style={theme.textSupport}>
                                                        {step.h5.replace('{{username}}', this.state.username)}</h5>
                                                    }
                                                    {step.imageMain !== undefined && step.imageMain !== '' 
                                                        ? 
                                                            <div style={{
                                                                marginRight: 2,
                                                                marginLeft: 2,
                                                                paddingRight: 0,
                                                                paddingLeft: 0,
                                                            }}>
                                                                <img style={theme.dialogImage} src={step.imageMain} alt={'Tutorial'} />
                                                            </div>
                                                        : step.images !== undefined && step.images !== ''
                                                        ?
                                                            <Card style={{...theme.CardGoals, margin:20}}>
                                                                <AutoPlaySwipeableViews >
                                                                {
                                                                step.images.map((image, index) => (
                                                                    <div key={index}  style={{
                                                                        marginRight: 2,
                                                                        marginLeft: 2,
                                                                        paddingRight: 0,
                                                                        paddingLeft: 0,
                                                                    }}>
                                                                        <img style={{width:'90%'}} src={image} alt={'Tutorial '+index} />
                                                                    </div>
                                                                )) 
                                                                }
                                                                </AutoPlaySwipeableViews>
                                                            </Card>
                                                        : ''
                                                    }
                                                    {step.h6 !== undefined &&
                                                        <h6 >
                                                        {step.h6.replace('{{username}}', this.state.username)}</h6>
                                                    }
                                                    {(step.buttonLandingPage !== undefined && step.buttonLandingPage !== '')
                                                        ?
                                                            <div style={theme.divCenter}> 
                                                                <Link to={ROUTES[step.buttonLandingPage]} onClick={ () => {this.props.handleClose()}}>
                                                                    <Button  >
                                                                        {step.button}
                                                                    </Button>
                                                                </Link>
                                                                <br/>
                                                                <br/>
                                                            </div>
                                                        :   !(ROUTES.LinkSupportURL(this)?.includes('rocky.ai'))
                                                            ?
                                                                <div style={theme.divCenter}> 
                                                                    <Button 
                                                                        onClick={() => {
                                                                            window.open(ROUTES.LinkSupportURL(this), "_blank")
                                                                        }}
                                                                    >
                                                                        Get Support
                                                                    </Button>
                                                                    <br/>
                                                                    <br/>
                                                                </div>
                                                            :
                                                                step.buttonExternalPage?.includes('http')
                                                                ?
                                                                    <div style={theme.divCenter}> 
                                                                        <Button 
                                                                            onClick={() => {
                                                                                window.open(step.buttonExternalPage, "_blank")
                                                                            }}
                                                                        >
                                                                            Detailed Help
                                                                        </Button>
                                                                        <br/>
                                                                        <br/>
                                                                    </div>
                                                                :
                                                                    <div style={theme.divCenter}> 
                                                                        {ROUTES.IsWhiteLabel(this)
                                                                            ?   this.props?.defaultSet?.programSupportURL?.includes('http')
                                                                                ?
                                                                                    <Button 
                                                                                        onClick={() => {
                                                                                            window.open(this.props.defaultSet?.programSupportURL , "_blank")
                                                                                        }}
                                                                                    >
                                                                                        More Help
                                                                                    </Button>
                                                                                :   ""
                                                                            :   
                                                                                <Button 
                                                                                    onClick={() => {
                                                                                        window.open("https://help.rocky.ai", "_blank")
                                                                                    }}
                                                                                >
                                                                                    More Help
                                                                                </Button>
                                                                        }
                                                                        <br/>
                                                                        <br/>
                                                                    </div>
                                                    }
                                                    {this.props.showDesktop !== true && this.state.selectedPages?.length>1 &&
                                                        <>
                                                            <Divider />
                                                            {this.renderPagesNavi(index)}
                                                        </>
                                                    }
                                                    <div style={{ marginBottom: 100}}>&nbsp;</div>
                                                </div>
                                            ) 
                                            :   ''}
                                            </div>
                                        ))}
                                    </SwipeableViews>
                                :
                                    <div style={theme.loadingCenterPadding}>
                                        <LoadingProgress 
                                            defaultSet={this.props.defaultSet}
                                            type={'CircularProgress'} loadingText={"loading"} />
                                    </div>
                                }

                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </div>
        );
    }

    renderPagesNavi(index) {
        const {theme} = this.props;
        return(
            <List style={{width: "100%", textAlign: "left"}}>
                {this.state.selectedPages?.length > 1
                ?
                    this.state.selectedPages.map((sub, subIndex) => {
                        return(
                            <ListItem key={'expl_'+subIndex} 
                                style={
                                    this.state['hover'+index] === true
                                    ?   {paddingLeft:0, paddingRight:0, backgroundColor: theme.palette.themeAltGold, borderRadius: theme.borderRadius}
                                    :   {paddingLeft:0, paddingRight:0, backgroundColor: 'transparent' }
                                }
                                onClick={() => this.handleStepChange(subIndex)}
                                onMouseEnter={(this.props.handlerMessageSubmit !== undefined) ? () => {this.setState({['hover'+index]: true})} : void(0)}
                                onMouseLeave={(this.props.handlerMessageSubmit !== undefined) ? () => {this.setState({['hover'+index]: false})} : void(0)}
                            >
                                <ListItemIcon style={{marginRight:10, minWidth:'auto',  color:"green"}}>
                                    <SendIcon fontSize='small' style={{color: this.state.activeStep === subIndex ? theme.palette.black : theme.palette.themeRed}}  />
                                </ListItemIcon>
                                <ListItemText style={{paddingLeft:8, paddingRight: 8}}>
                                    <span style={{color: this.state.activeStep === subIndex ? theme.palette.black : theme.palette.secondary.main}}>{sub.h2}</span>
                                </ListItemText>
                            </ListItem>
                        )                    
                    })
                :
                    <ListItem key={'expl_tittlemain'} 
                        style={
                            {paddingLeft:0, paddingRight:0, backgroundColor: 'transparent' }
                        }
                        //onClick={() => this.handleStepChange(subIndex)}
                        //onMouseEnter={(this.props.handlerMessageSubmit !== undefined) ? () => {this.setState({['hover'+index]: true})} : void(0)}
                        //onMouseLeave={(this.props.handlerMessageSubmit !== undefined) ? () => {this.setState({['hover'+index]: false})} : void(0)}
                    >
                        <ListItemIcon style={{marginRight:10, minWidth:'auto',  color:"green"}}>
                            <SendIcon fontSize='small' style={{color: theme.palette.black }}  />
                        </ListItemIcon>
                        <ListItemText style={{paddingLeft:8, paddingRight: 8}}>
                            <span style={{color: theme.palette.black }}>{this.state.tutorialCategoryNames?.[(this.state.badgeFamily || 'total')] || "Info"}</span>
                        </ListItemText>
                    </ListItem>
            }
            </List>
        )
    }

    handleStepChange = step => {
        this.setState({activeStep : step}, 
            () => this.handleScrollTop());
    };
    handleScrollTop = () => {
        if(this.props.handleScrollTop!== undefined ) this.props.handleScrollTop()
        //if(this.dialogRef !== undefined && this.dialogRef.scrollTop !== undefined ) this.dialogRef.scrollTop  = 0;
    }
    handleNext = () => {
        this.setState({activeStep : this.state.activeStep + 1}, 
            () => this.handleScrollTop());
    };
    handleBack = () => {
        this.setState({activeStep : this.state.activeStep - 1}, 
            () => this.handleScrollTop());
    };

}

export default compose(
  withStyles(styles, { withTheme: true }),
  withFirebase
)(TutorialPage);

