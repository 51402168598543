//core
import React, { Component } from 'react';
import { compose } from 'recompose';
//design
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
//import Grid from '@material-ui/core/Grid';
//functions
import TagCloud from 'react-tag-cloud';
import {IsMobileDevice} from '../Modules/IsMobileDevice';

const styles = theme => ({
    grow: {
      flexGrow: 1,
    },
    app_outer: {
        alignItems: "center",
        bottom: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        left: 0,
        position:'relative',
        padding: 0,
        right: 0,
        top: 0,
    },
    app_inner: {
        display: "flex",
        flexDirection: "column",
        height:   IsMobileDevice()?"90vw":300,
        maxWidth: IsMobileDevice()?"90vw":600,
        maxHeight: IsMobileDevice()?"90vw":300,
        width:  IsMobileDevice()?"90vw":'50vw',
      },
    tag_cloud: {
        flex: "1"
    },
    tag_cloud___div: {
        transition: "1.4s"
    },
    tag_item_wrapper: {
        cursor: "pointer"
    },
    tag_item_wrapper_hover: {
        zIndex: "100"
    },
    tag_item_wrapper_hover__tag_item_tooltip: {
        display: "block"
    },
  });



class WordCloudItem extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            title: props.title,
            tagsPrimary: props.tagsPrimary,
            tagsSecondary: props.tagsSecondary,
            tagsThird: props.tagsThird
        };
    }

    
    render() {
        const { classes, theme } = this.props;
        const {title, tagsPrimary, tagsSecondary, tagsThird} = this.state;
        return (
            <div > 
                <h2 >{title} </h2>                
                <Card style={theme.cardWordCloud}>
                    <div className={classes.app_outer}>                    
                        <div className={classes.app_inner}>
                            <TagCloud 
                                key={title}
                                style={{
                                    flex: "1",
                                    fontFamily: 'Proxima Nova Regular',
                                    //fontSize: () => Math.round(Math.random() * 50) + 16,
                                    fontSize: theme.cardWordCloud.tagCloud.fontSize,
                                    color: "black",
                                    padding: 5,
                                }}>
                                
                                {tagsPrimary!== undefined && tagsPrimary.map((tag, index) => (
                                    <div 
                                        style={{
                                            color: theme.cardWordCloud.tagCloud.tagsPrimaryColor, 
                                            fontSize: (theme.cardWordCloud.tagCloud.fontSize*2-index*5)}}
                                        key={"1"+tag}
                                        >
                                        {tag.charAt(0).toUpperCase() + tag.substring(1)}
                                    </div>
                                ))}
                                {tagsSecondary!== undefined && tagsSecondary.map((tag, index) => (
                                    <div key={"2"+tag}
                                        style={{
                                            color: theme.cardWordCloud.tagCloud.tagsSecondaryColor, 
                                            fontSize: (theme.cardWordCloud.tagCloud.fontSize-index*2)}}>
                                    {tag}</div>
                                ))}
                                {tagsThird!== undefined && tagsThird.map((tag, index) => (
                                    <div key={"3"+tag}
                                        style={{
                                            color: theme.cardWordCloud.tagCloud.tagsThirdColor, 
                                            fontSize: (theme.cardWordCloud.tagCloud.fontSize-index*4)}}
                                        rotate={(index===2 ) ?90 : (90*index)}
                                    >
                                    {tag}</div>
                                ))}
                            </TagCloud>
                        </div>
                    </div>
                </Card>
            </div>                 
        )
    }
}


export default compose(
    withStyles(styles, { withTheme: true })
)(WordCloudItem);

