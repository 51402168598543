import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types';
import { compose } from 'recompose';

// mini guide from http://www.uxshaper.com/different-layouts-with-react-router/
// reference materiual ui premium package https://github.com/devias-io/react-material-dashboard/blob/master/src/components/RouteWithLayout/RouteWithLayout.js
 
const RouteLayout = (props) => {
    const { layout: Layout, component: Component, defaultSet: defaultSet, ...rest } = props;
    //console.log(";RL props", props)
    //todo: logic for validate user
    return (
        <Route {...rest} render={matchProps => (
            <Layout
                defaultSet={defaultSet || {}}
            >
                <Component {...matchProps} defaultSet={defaultSet || {}} refreshDefaultSet={props.refreshDefaultSet || undefined}/>
            </Layout>
        )} />
    )
};

RouteLayout.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string
};


export default compose(

)(RouteLayout);