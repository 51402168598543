import React, { Component } from 'react';
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import withStyles from '@material-ui/core/styles/withStyles';

//import Grid from '@material-ui/core/Grid';
//import Card from '@material-ui/core/Card';
import LoadingProgress from '../Modules/LoadingProgress';

import WordCloudItem from './WordCloudItem';
const styles = theme => ({
    grow: {
      flexGrow: 1,
    },
    
  });

class WordCloudList extends Component {
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingText : 'loading word clouds',
            data:[],
            historyLimit :this.props.historyLimit,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        // monthly word clouds
        let data = [];
        this.unsubscribe = this.props.firebase
        .insightsWordsArchive(this.context.uid)
        .where("category","==","wordCloud")
        .get()
        .then(snapshot => {
          if (snapshot.size) {
            
            snapshot.forEach(doc =>
                data.unshift({ ...doc.data(), id: doc.id }),
            );
            if(data.length>0 && this.state.historyLimit!==undefined && this.state.historyLimit>0){
              //console.log("limit", this.state.historyLimit)
              data=data.slice(0,this.state.historyLimit)
            }   
            this.setState({data, loading: false });
            //console.log('journalweeks data',journalWeeks);
          }  
          else {
            this.setState({ data: [], loading: false });
            //console.log('no data loaded for journal week');
          }
        });//ned firebase
    }
    shouldComponentUpdate(nextProps, nextState) {  
        if (nextProps.rerender === false) {  
            //console.log('no rerender')
            return false;  
        }  
        //console.log(' rerender')
        return true;  
    }
  
  /*
  componentWillUnmount() {
    this.unsubscribe();
  }
  */

  showDataItems = () => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    return(
        <div>
            {this.state.data.map( (d, index) =>(
                <WordCloudItem 
                    key={index}
                    title= { months[parseInt(d.id.substring(5,7))-1] + " " + d.id.substring(0,4)}
                    tagsPrimary={d.wordCollection6TopKeys.words}
                    tagsSecondary={d.wordCollection6TopActions.words}
                    tagsThird={d.wordCollection6TopAssess.words}
                />
            ))}

            {/**
             <Grid container spacing={2}  >
                {this.state.data.map( (d) => {
                    //console.log('datacloud', d.id.substring(5,7))
                    return(
                    <Grid
                        item
                        key={d.id}
                        sm={this.state.data.length===1 ? 12 : 6}
                        //xs={this.state.historyLimit===1 ? 12 : 6}
                    > 
                        <WordCloudItem 
                            title= { months[parseInt(d.id.substring(5,7))-1] + " " + d.id.substring(0,4)}
                            tagsPrimary={d.wordCollection6TopKeys.words}
                            tagsSecondary={d.wordCollection6TopActions.words}
                            tagsThird={d.wordCollection6TopAssess.words}
                            />
                    </Grid>
                    )
                
                })}
            </Grid>
       */}
      </div> 
    )
  }
  

    render() {
        const { data } = this.state;
        const {  loading } = this.state;
        //console.log("sdasd",this.state.data)
        return(
            <div  style={{height:'150vh', paddingBottom:300}}>
                {loading 
                ?
                    <LoadingProgress 
                        defaultSet={this.props.defaultSet}
                        type={'LinearProgress'} loadingText={this.state.loadingText} />
                : 
                  
                    data.length > 0
                        ? this.showDataItems()
                        : <div>Finish a journal entry to see your word cloud</div>      
                }
            </div>
        );
    }
}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
  )(WordCloudList);
