import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import ImageListItem from '@material-ui/core/ImageListItem';

import Card from '@material-ui/core/Card';
import badgeStart from "../../images/Badge_XP_congrats.png";
import {Textfit} from 'react-textfit';
import Button from "@material-ui/core/Button";

const styles = theme => ({
    grow: {
      flexGrow: 1,
    },
});

class ModuleCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            readyTitle: false,
            readySubtitle: false
        }
    }

    render() {
        const {theme} = this.props;
        const {clickAction, title, subtitle} = this.props;
        return(
            <ImageListItem
                onClick={() => {
                    if(clickAction !== undefined){
                        clickAction()
                    }
                }}
                style={{margin: 5}}
            >
                <div 
                    onMouseEnter={(clickAction!==undefined) ? () => {this.setState({hover: true})} : void(0)}
                    onMouseLeave={(clickAction!==undefined) ? () => {this.setState({hover: false})} : void(0)}
                    style={{
                        backgroundColor: this.state.hover ? theme.palette.themeAltGold : this.props.backgroundColor === 'transparent' ? theme.palette.backPrimary : this.props.backgroundColor,
                        height: 'calc(100% - 10px)', 
                        minHeight: (this.props.height || 160), 
                        minWidth: (this.props.width || 200),
                        maxWidth: (this.props.width || 400), 
                        borderStyle: 'dotted', 
                        borderWidth: 1, 
                        borderCollapse: 'black', 
                        borderRadius: theme.borderRadius, 
                        padding: 10
                    }}
                >
                    <Button
                        onClick={() => {void(0)}}
                    >
                        {this.props.buttonText}
                    </Button>
                </div>
                {title?.length > 2
                &&
                    <ImageListItemBar
                        title={
                            <span>
                                {title}
                            </span>
                            }
                        subtitle={<span>{subtitle}</span>}
                        style={{ borderRadius: '0 0 '+theme.borderRadius+'px '+theme.borderRadius+'px', maxHeight:"35%"}}
                        actionIcon={<></>}
                    />
                }
            </ImageListItem>
        )
    }

    
}

export default compose(
    withStyles(styles, { withTheme: true })
)(ModuleCard);