export const DigitalTwinsList = {
    buddha : {
        title : "Buddha - AI Coaching",
        code : "#LEGENDSBUDDHA",
        key : "buddha",
        url : "buddha.my-ai.coach"
    },
    cexh: {
        title : "AI Coaching and Execution Hub",
        code : "#SOCIALCAPITALBUILDERS",
        key : "cexh",
        url : "cexh.my-ai.coach"
    },
    chance : {
        title : "Coach Chance - Your Personal AI Coach",
        code : "#MYFUTURE",
        key : "chance",
        url : "chance.my-ai.coach"
    },
    liyt : {
        title : "Live In Your Truth - AI Coaching",
        code : "#LIVEINYOURTRUTH",
        key : "liyt",
        url : "liyt.my-ai.coach"
    },
    lyric : {
        title : "Lyric - Your Personal AI Coach",
        code : "#CHANGEYOURSTORY",
        key : "lyric",
        url : "lyric.my-ai.coach"
    },
    peaceify : {
        title : "Peaceify - Conflict Management AI Coach",
        code : "#PEACEBUILDING",
        key : "peaceify",
        url : "peaceify.my-ai.coach"
    },
    ppllc : {
        title : "Chew On This - Your Personal AI Coach",
        code : "#POLARPERMACULTURE",
        key : "ppllc",
        url : "ppllc.my-ai.coach"
    },
    pmc : {
        title : "PMC AI Coach - Personalized Management Coaching",
        code : "#ACCESSPMCPROGRAM",
        key : "pmc",
        url : "pmc.my-ai.coach"
    },
    sales : {
        title : "AI Sales Coach",
        code : "#SALESCOACHING",
        key : "sales",
        url : "sales.my-ai.coach"
    },
    selfimprovement : {
        title : "Self-Improvement AI Coaching",
        code : "#SELFIMPROVEMENT",
        key : "self-improvement",
        url : "self-improvement.my-ai.coach"
    }
}
