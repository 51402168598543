import React from 'react';

// MUI stuff
//import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';

import { withFirebase } from '../Firebase';

const SignOutButton = ({firebase }) => (
  <IconButton onClick={firebase.doSignOut}
    >
      <ExitToAppIcon />
  </IconButton>
);

export default withFirebase(SignOutButton);
