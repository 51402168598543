import React, { Component } from 'react';
import { compose } from 'recompose';
import * as chatAgent from '../chatAgent';
import { saveAs } from 'file-saver';
import { openDB } from 'idb';

// language
import {franc} from 'franc-min'

import anyAscii from 'any-ascii';
import { DataCacheRead, DataCacheWrite } from '../components/Modules/DataCache';
 /*
 * Generate a short ID from text with length-based strategies
 * @param {string} text - Input text to generate ID from
 * @returns {string} Shortened, lowercase ID
 */

function saveJsonFile(data, filename) {
    const blob = new Blob(
        [JSON.stringify(data, null, 2)], 
        { type: 'application/json' }
    );
    saveAs(blob, filename);
}

export function generateIDShortString(text) {
    // Convert to ASCII Roman characters
    text = anyAscii("" + text);   
    const filtered_message = text.replace(/[^.,!?a-zA-Z0-9 -]/g, ''); 
    // Split into words
    const words = filtered_message.split(/\s+/);
    let short_id;
    // Short text handling
    if (text.length < 44) {
        // Keep only alphanumeric characters and convert to lowercase
        short_id = filtered_message.replace(/[^0-9a-zA-Z]/g, '').toLowerCase();
    }
    else if (text.length < 88) {
        // Take first three characters of each word
        short_id = words.map(word => word.slice(0, 3).toLowerCase()).join('');
    } else {
        // Take first two characters of each word
        short_id = words.map(word => word.slice(0, 2).toLowerCase()).join('');
    }
    return short_id;
}

export const LanguageSets = {
    "en-US" : {
        short: 'en',
        mid: 'eng',
        title: "America English",
        timezones: [
            'Pacific/Honolulu',
            'America/Chicago',
            'America/Denver',
            'America/Detroit',
            'America/Los_Angeles',
            'America/New_York',
            'America/New_York',
            'America/New_York',
            'America/Indianapolis',
            'America/Edmonton',
            'America/Phoenix',
            'America/Toronto',
            'America/Vancouver',
            'America/Winnipeg',
            'America/Regina',
            'America/Halifax',
            'America/Whitehorse',
            'America/Anchorage',
        ],
    },
    "en-GB" : {
        short: 'en',
        mid: 'eng',
        title: "British English",
        timezones: [
            'Europe/London',
            'Europe/Dublin',
            'Australia/Adelaide',
            'Australia/Perth',
            'Australia/Sydney',
            'Australia/Brisbane',
            'Australia/Melbourne',
            'Australia/Darwin',
            'Pacific/Auckland',
            'Asia/Singapore'
        ],
    },
    "es-ES": {
        short: 'es',
        mid: 'spa',
        title: "Spanish",
        timezones: [
            "America/Argentina",
            "America/Bogota",  // Colombia
            "America/Chihuahua",  // Mexico
            "America/Costa_Rica",
            "America/Dominica",
            "America/El_Salvador",
            "America/Guatemala",
            "America/Guayaquil",  // Ecuador
            "America/Havana",  // Cuba
            "America/La_Paz",  // Bolivia
            "America/Lima",  // Peru
            "America/Los_Angeles",  // Some Spanish speakers in US
            "America/Managua",  // Nicaragua
            "America/Mexico_City",
            "America/Montevideo",  // Uruguay
            "America/Panama",
            "America/Paraguay",
            "America/Puerto_Rico",
            "America/Santiago",  // Chile
            "America/Santo_Domingo",  // Dominican Republic
            "Atlantic/Canary",  // Canary Islands (Spain)
            "Europe/Madrid",  // Spain
            "Pacific/Easter"  // Easter Island (Chile)
        ],
    },
    "de-DE": {
        short: 'de',
        mid: 'deu',
        title: "German",
        timezones: [
            "Europe/Berlin",
            "Europe/Vienna",
            "Europe/Zurich",
            "Europe/Busingen",
            "Europe/Vaduz",
            "Europe/Luxembourg",     // Luxembourg
        ],
    },
    "pt-PT": {
        short: 'pt',
        mid: 'por',
        title: "Portuguese",
        timezones: [
            // Portugal
            "Europe/Lisbon",
            "Atlantic/Madeira",
            "Atlantic/Azores",
            // Brazil
            "America/Sao_Paulo",
            "America/Rio_Branco",
            "America/Manaus",
            "America/Cuiaba",
            "America/Fortaleza",
            "America/Belem",
            // Cape Verde
            "Atlantic/Cape_Verde",
            // Other Portuguese-speaking regions in Africa (use standard African time zones)
            "Africa/Luanda", // Angola
            "Africa/Maputo"  // Mozambique
        ],
    },
    "hi-IN": {
        mid: 'hi',
        short: 'hin',
        title: "Hindi",
        timezones: [
            // India
            "Asia/Kolkata",
            // Mauritius (Hindi is spoken by a significant population)
            "Indian/Mauritius"
        ],
    },
    "ru-RU": {
        short: 'ru',
        mid: 'rus',
        title: "Russian",
        timezones: [
             // Russia (various time zones across the country)
            "Europe/Moscow",
            "Europe/Kaliningrad",
            "Europe/Samara",
            "Asia/Yekaterinburg",
            "Asia/Omsk",
            "Asia/Novosibirsk",
            "Asia/Krasnoyarsk",
            "Asia/Irkutsk",
            "Asia/Yakutsk",
            "Asia/Vladivostok",
            "Asia/Magadan",
            "Asia/Sakhalin",
            "Asia/Kamchatka",
            "Asia/Anadyr",
            // Other countries with significant Russian-speaking populations
            "Europe/Minsk",    // Belarus
            "Europe/Simferopol", // Crimea (disputed)
            "Asia/Almaty",     // Kazakhstan
            "Asia/Bishkek",    // Kyrgyzstan
            "Asia/Tashkent",   // Uzbekistan
            "Asia/Ashgabat"    // Turkmenistan
        ],
        //"Europe/Kiev",     // Ukraine (now split into "Europe/Kyiv" for post-2014)  
    },
    "ar-EG": {
        short: 'ar',
        mid: 'arb',
        title: "Arabic", 
        timezones: [
            // Middle East
            "Asia/Riyadh",       // Saudi Arabia
            "Asia/Dubai",        // United Arab Emirates
            "Asia/Kuwait",       // Kuwait
            "Asia/Qatar",        // Qatar
            "Asia/Bahrain",      // Bahrain
            "Asia/Baghdad",      // Iraq
            "Asia/Amman",        // Jordan
            "Asia/Damascus",     // Syria
            "Asia/Beirut",       // Lebanon
            // North Africa
            "Africa/Cairo",      // Egypt
            "Africa/Tripoli",    // Libya
            "Africa/Tunis",      // Tunisia
            "Africa/Algiers",    // Algeria
            "Africa/Casablanca", // Morocco
            "Africa/El_Aaiun",   // Western Sahara (disputed)
            // Sudan and neighboring regions
            "Africa/Khartoum",   // Sudan
            "Africa/Juba",       // South Sudan
            // Other Arabic-speaking countries
            "Africa/Mogadishu",  // Somalia
            "Asia/Aden",         // Yemen
            "Asia/Gaza",         // Palestine
            "Asia/Hebron"        // Palestine (Hebron area)
        ],
    },
    "it-IT": {
        short: 'it',
        mid: 'ita',
        title: "Italian",
        timezones: [
            "Europe/Rome",        // Italy
            "Europe/Vatican",     // Vatican City
            "Europe/San_Marino",  // San Marino
        ],
    },
    "nl-NL": {
        short: 'nl',
        mid: 'nld',
        title: "Dutch",
        timezones: [
            // Netherlands and Belgium
            "Europe/Amsterdam",   // Netherlands
            "Europe/Brussels",    // Belgium
            // Dutch Caribbean
            "America/Aruba",      // Aruba
            "America/Curacao",    // Curaçao
            "America/Kralendijk", // Bonaire
            "America/Paramaribo", // Suriname
        ],
    },
    "fr-FR": {
        short: 'fr',
        mid: 'fra',
        title: "French",
        timezones: [
            // France and its territories
            "Europe/Paris",          // France
            "Indian/Reunion",        // Réunion
            "Indian/Mayotte",        // Mayotte
            "Pacific/Noumea",        // New Caledonia
            "Pacific/Tahiti",        // French Polynesia
            "Pacific/Wallis",        // Wallis and Futuna
            "America/Cayenne",       // French Guiana
            "America/Miquelon",      // Saint Pierre and Miquelon
            "America/Guadeloupe",    // Guadeloupe
            "America/Martinique",    // Martinique
            // Belgium, Switzerland, Luxembourg
            "Europe/Brussels",       // Belgium
            "Europe/Monaco",       // Monaco
        ],
    },
    "ko-KR": {
        short: 'kr',
        mid: 'kor',
        title: "Korean",
        timezones: [
            "Asia/Seoul",   // South Korea
            "Asia/Pyongyang" // North Korea
        ],
    },
    "tr-TR": {
        short: 'tr',
        mid: 'tur',
        title: "Turkish",
        timezones: [
            "Europe/Istanbul",
        ],
    },
    "bn-BD": {
        short: 'bn',
        mid: 'ben',
        title: "Bengali",
        timezones: [
            "Asia/Dhaka",   // Bangladesh
            "Asia/Kolkata"  // West Bengal and Tripura (India)
        ],
    },
    "zh-CN": {
        short: 'zn',
        mid: 'cmn',
        title: "Mandarin Chinese",
        timezones: [
            // China
            "Asia/Shanghai",   // Mainland China (China Standard Time)
            // Taiwan
            "Asia/Taipei",     // Taiwan
            // Hong Kong
            "Asia/Hong_Kong",  // Hong Kong
            // Macau
            "Asia/Macau"       // Macau
        ],
    },
    "ja-JP": {
        short: 'jp',
        mid: 'jap',
        title: "Japanese",
        timezones: [
            "Asia/Tokyo"
        ],
    },
    "hr-HR": {
        short: 'hr',
        mid: 'hrv',
        title: "Croatian",
        timezones: [
            "Europe/Zagreb",
            "Europe/Sarajevo"
        ],
    },
     "el-GR": {
        short: 'el',
        mid: 'ell',
        title: "Greek",
        timezones: [
            "Europe/Athens",   // Greece
            "Asia/Nicosia"     // Cyprus
        ],
    },
    "id-ID": {
        short: 'id',
        mid: 'ind',
        title: "Indonesian",
        timezones: [
            "Asia/Jakarta",    // Java and Sumatra (Indonesia)
            "Asia/Pontianak",  // Kalimantan (Indonesia)
            "Asia/Makassar",   // Sulawesi (Indonesia)
            "Asia/Balikpapan", // Kalimantan (Indonesia)
            "Asia/Mataram"     // Lombok (Indonesia)
        ],
    },
}
export const ZonesEnglish = [
    ...LanguageSets["en-US"]['timezones'],
    ...LanguageSets["en-GB"]['timezones']
];
export const ZonesLowCost = [
    "Asia/Calcutta",
    "Asia/Pakistan",
    "Asia/Bangkok",
    "Asia/Beirut",
    "Asia/Dhaka",
    "Asia/Jakarta",
    "Asia/Karachi",
    "Asia/Kuala_Lumpur",
    "Asia/Manila",
    "Asia/Rangood",
    "Asia/Riyadh",
    "Asia/Saigon",
    "Asia/Shanghai",
    "Asia/Tehran",
    "Asia/Tehran",
    "Asia/Tehran",
    "Europe/Moscow",
    "Europe/Istanbul",
    "Europe/Kiev",
    "Africa/Accra",
    "Africa/Cairo",
    "Africa/Johannesburg",
    "Africa/Lagos"
]

export function BrowserLanguage(timezone){
    //console.log('languageCode', timezone)
    var languageCode = undefined
    if(timezone?.length > 2){
        for (let langKey of Object.keys(LanguageSets)) {
            if (LanguageSets[langKey].timezones?.includes(timezone)) {
                languageCode = langKey;
                break;
            }
        }
    }
    //console.log('languageCode', languageCode)
    return languageCode 
}

export function CheckLanguage(text, preferredLanguageCode, browserLanguageCode){
    //// preferredLanguageCode is stored in user parameters
    //// browserLanguageCode based on timezone
    var languageCodeFranc = franc(text, {
        minLength: 3,
        only: ['eng', 'spa', 'fra', 'deu', 'ita', 'por', 'rus', 'cmn', 'arb', 'ben', 'hin', 'ind', 'nld', 'ell', 'hrv']
    });
    console.log('CheckLanguage', languageCodeFranc, preferredLanguageCode, browserLanguageCode)
    if(browserLanguageCode?.includes('en') && languageCodeFranc === 'eng'){
        if((preferredLanguageCode === 'en-GB')){
            return 'en-GB'
        }
        else {
            return 'en-US'
        }
    }
    else if((preferredLanguageCode === undefined || preferredLanguageCode === '') && languageCodeFranc === 'eng'){
        return 'en-US'
    }
    else if(preferredLanguageCode?.length > 2 && preferredLanguageCode === browserLanguageCode){
        if(languageCodeFranc === 'und'){
            console.log('languageCode und', preferredLanguageCode)
            return preferredLanguageCode
        }
        else if(LanguageSets?.[preferredLanguageCode]?.mid === languageCodeFranc){
            console.log('languageCode mid match', preferredLanguageCode)
            return preferredLanguageCode
        }
        else{
            console.log('languageCode ????', languageCodeFranc, preferredLanguageCode)
            return preferredLanguageCode
        }
    }
    else if (preferredLanguageCode?.length > 2){
        if(languageCodeFranc === 'und'){
            console.log('languageCode und langsewtting', preferredLanguageCode)
            return preferredLanguageCode
        }
        else if(LanguageSets?.[preferredLanguageCode]?.mid === languageCodeFranc){
            console.log('languageCode mid match', preferredLanguageCode)
            return preferredLanguageCode
        }
        else{
            return 'und'
        }
    }
    else{
        return 'und'
    }
}

export function LanguageVariant(text, language){

}
// Open or create the database
const initDB = async () => {
    return await openDB(DB_NAME, 1, {
        upgrade(db) {
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true });
            }
        },
    });
};
  
// Initialize IndexedDB
const DB_NAME = 'languageDB';
const STORE_NAME = 'translations';
// Check if a translation exists
const getTranslation = async (shortId) => {
    const db = await initDB();
    return await db.get(STORE_NAME, `${shortId}`);
};
/// Save or update a translation (extend without overwriting)
const saveTranslation = async (shortId, language, text) => {
    const db = await initDB();
    // Fetch existing data for this ID
    const existingData = await getTranslation(shortId);
    // Extend the existing data with the new translation
    const updatedData = {
      ...existingData, // Keep existing translations
      id: shortId,     // Ensure the ID remains intact
      [language]: text, // Add or update the language translation
    };
    // Save back to IndexedDB
    await db.put(STORE_NAME, updatedData);
    console.log('Translation saved:', updatedData);
};

// Export JSON File
const exportJsonFile = async () => {
    const db = await initDB();
    const allTranslations = await db.getAll(STORE_NAME);
  
    const blob = new Blob([JSON.stringify(allTranslations, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
  
    const link = document.createElement('a');
    link.href = url;
    link.download = 'language.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
  

class LanguageManager extends Component {
    _isMounted = true;
    constructor(props) {
        super(props);
        var text = undefined
        var sourceText = props.text?.trim();
        var extensionText = "";
        var language = 'en-US';
        var textPackage = {
            language
        };
        if(props?.language?.length > 0){
            language = props.language;
        }
        if(language === 'en-US'){
            textPackage['text'] = sourceText;
        }
        else{
            this.getLanguageText(sourceText, language)
        }
        this.state = textPackage
    }
    componentDidMount() {
        this._isMounted = true;
        //console.log('state', this.state)
    }
    componentWillUnmount() {
        this._isMounted = false;
        if(this.unsubscribe!==undefined) this.unsubscribe();
    }
    componentDidUpdate(prevProps){
        if(this.props.language !== undefined && this.props.language !== prevProps.language){
            console.log('changed languages ', this.props.language, prevProps.language)
            this.getLanguageText(this.props.text, this.props.language)
        }
    }
    async getLanguageText(text, language){
        var type_text = (this.props.type || 'text')
        var text = text.trim();
        var extensionText = "";
        if (text.endsWith(":")) {
            text = text.substring(0, text.length - 1);
            extensionText = ":";
        }
        else if (text.endsWith(" ...")) {
            text = text.substring(0, text.length - 4);
            extensionText = "...";
        }
        else if (text.endsWith("...")) {
            text = text.substring(0, text.length - 3);
            extensionText = "...";
        }
        var shortId = generateIDShortString(text)
        if(this._isMounted){
            const existingTranslation = await getTranslation(shortId, language);
            //console.log('existingTranslation',existingTranslation)
            if (existingTranslation?.[language]?.length > 0) {
                //console.log('Local translation available', shortId, existingTranslation[language]);
                this.setState({ text: existingTranslation[language] });
            }
            else{
                //console.log('localDefaultSet need to search', shortId)
                var packageTranslation = {
                    'texts': [text], 
                    'source_language': 'en-US', 
                    'target_language': language,
                    'type_text' : type_text
                }
                chatAgent.sendMessage(packageTranslation, 'translationAI').then(extractionResults => {
                    console.log('extractionResults', extractionResults)
                    if(this._isMounted){
                        if(extractionResults?.translations?.length > 0){
                            const translatedText = extractionResults.translations[0];
                            this.setState({ text: translatedText, extensionText });
                            // Save translation to IndexedDB
                            saveTranslation(shortId, language, translatedText);
                            // Optionally export the data as a JSON file
                            //exportJsonFile();
                        }
                    }
                })
            }
        }
    }
    render() {
        return (
            <>
                {this.state.text || this.props.text || ""}{this.state.extensionText || ""}
            </>
        )
    }
}
export default compose(
)(LanguageManager);