function muiStyle (palette) { return {
    root: {
        width: "100%",
        maxHeight: "100%",
        paddingBottom: 0,
        minHeight: 20,
        marginTop: 0,
        "& div.MuiTabs-scroller": {
            paddingLeft: 0,
        }
    },
    indicator: {
        display: "none"
    },
    centered: {
        alignItems: "center",
        justifyContent: "center"
    }
    
}}

export default muiStyle;