const minTime = 8;
const maxTime = 18;

export function BackgroundMode(styleProps){
    if(styleProps === undefined){
        styleProps={}
    }
    var d = new Date(); // for now
    var hours = d.getHours(); // => 9
    var typeScreen = 'rgba(63,66,87,.9)';
    if(hours > minTime && hours < maxTime){
        typeScreen = 'rgba(241,240,238,.7)';
        styleProps['buttonTextDark'] = true;
        if('backgroundColor' in styleProps) delete styleProps.backgroundColor;
        styleProps['background'] = typeScreen;
    }
    else{
        styleProps['color'] = '#FEFFEE';
        styleProps['buttonTextDark'] = false;
    }
    if('opacity' in styleProps) delete styleProps.opacity;
    //console.log('props', hours,  styleProps)
    return styleProps
}
export function BackgroundTextSupportColor(styleProps){
    if(styleProps === undefined){
        styleProps={}
    }
    var d = new Date(); // for now
    var hours = d.getHours(); // => 9
    if(hours > minTime && hours < maxTime){
        /// keep color
    }
    else{
        //styleProps['color'] = '#39cccc';
    }
    return styleProps
}