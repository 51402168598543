import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../Firebase';

import ContentRecommendationsPreview from '../Content/ContentRecommendationsPreview';

//import IconButton from '@material-ui/core/IconButton';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Grid from '@material-ui/core/Grid';


const styles = theme => ({
    botActive: {
        //color: theme.palette.secondary.dark,
        //color: '#38415c',
        color: 'black',
        display: 'inline-block',
        fontSize: 18,
        overflow: 'auto',
        //fontWeight: 'bold'
    },
    cardActive: {
        backgroundColor: theme.palette.themeLightGrey,
        marginTop: 2,
        borderRadius: 15,
        marginBottom: 2,
        display: 'inline-block',
        maxWidth: '90%',
    },
    bot: {
        //color: theme.palette.secondary.dark,
        color: 'black',
        //color: theme.palette.themeGrey,
        display: 'inline-block',
        fontSize: 18,
        overflow: 'auto',
    },
    user: {
        //color: 'white',
        color: theme.palette.white,
        display: 'inline-block',
        fontSize: 18,
        overflow: 'auto',
    },
    cardBot: {
        backgroundColor: theme.palette.themeLightGrey,
        maxWidth: '90%',
        marginTop: 2,
        borderRadius: 15,
        marginBottom: 2,
        display: 'inline-block',
    },
    cardUser: {
        //backgroundColor: theme.palette.secondary.light,
        backgroundColor: theme.palette.themeDarkBlue,
        marginTop: 2,
        borderRadius: 15,
        marginBottom: 2,
        display: 'inline-block',
        textAlign: 'left',
        marginRight : 0,
        maxWidth: '90%',
    },
    cardPadding: {
        paddingTop: 10,
        paddingBottom: 10,
        "&:last-child": {
          paddingBottom: 10
        }
    },
    expansionPanel : {
        background: 'transparent',
        border: 'none',
        margin: 0,
        padding: 0,
        boxShadow: 'none',
    }
})



class ContentSuggestCarousel extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state={
            textLoading: "..."
        }
    }

    componentDidMount() {
        this._isMounted = true;
        //this.loadMessages()
    }
    componentWillUnmount() {
        this._isMounted = false;
        //if(this.unsubscribeMessages!==undefined) this.unsubscribeMessages();
    }

    render () {
        const {theme} = this.props;
        const {user, contentSelection} = this.props;
        //console.log('contentSelection', contentSelection)
        if( user !== undefined 
            && contentSelection !== undefined  && contentSelection.length > 0 
            ){
            return (         
                <Grid container 
                    direction='row' 
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item xs={1} style={{marginTop: 'auto', marginBottom: 'auto'}}>
                        {contentSelection !== undefined && contentSelection.length > 1
                            &&
                                <ArrowLeftIcon 
                                    style={{color: theme.palette.secondary.main}}
                                    onClick={() => this.setState({
                                        contentPreviewItem : this.state.contentPreviewItem === undefined 
                                            ?   contentSelection.length - 1
                                            :   this.state.contentPreviewItem >= 1
                                                ?   this.state.contentPreviewItem - 1
                                                :   contentSelection.length - 1
                                        })}
                                />
                        }
                    </Grid>
                    <Grid item xs={10} style={{width:"70%", height: '100%'}}>
                        <div 
                            //className={classes.cardPadding}
                        >
                        {contentSelection !== undefined && contentSelection.length > 0 &&
                            <ContentRecommendationsPreview 
                                user={this.props.user}
                                contentSelection={contentSelection}
                                leadershipCategories={this.props.leadershipCategories}
                                leadershipQualities={this.props.leadershipQualities}
                                previewHeight={50}
                                visualLimit={3}
                                handleOpen = {this.props.handleOpen || undefined}
                                handlerShowPosition={this.state.contentPreviewItem}
                                handlerResetPosition={ () => {
                                    this.setState({contentPreviewItem: undefined})
                                }}
                                handlerMessageSubmit = {
                                    this.props.handlerMessageSubmit === undefined
                                        ?  undefined
                                        :  (text) => {this.submitInspiration(text)}
                                    }
                                handlerContentOpened = {
                                    this.props.handlerContentOpened === undefined
                                        ?  undefined
                                        :  (id) => {this.props.handlerContentOpened(id)}
                                    }
                                closeAfterActionAdded = {this.props.closeAfterActionAdded || false}
                            />
                        }
                        </div>
                    </Grid>
                    <Grid item xs={1} style={{marginTop: 'auto', marginBottom: 'auto', textAlign: 'right'}}>
                        {contentSelection !== undefined && contentSelection.length > 1
                            &&
                            <ArrowRightIcon 
                                style={{color: theme.palette.secondary.main}}
                                onClick={() => this.setState({
                                    contentPreviewItem : this.state.contentPreviewItem === undefined 
                                        ?   1 
                                        :   this.state.contentPreviewItem >= contentSelection.length - 1
                                            ?   0
                                            :   this.state.contentPreviewItem + 1
                                    })}
                            />
                        }
                    </Grid>
                </Grid>
            )
        }
        else{
            return ""
        }
    }

    submitInspiration = (text) => {
        if(this.props.handlerMessageSubmit!== undefined){
            this.props.handlerMessageSubmit(text)
        }
    }
}

export default compose(
    withStyles(styles, { withTheme: true}),
    withFirebase,
)(ContentSuggestCarousel);
