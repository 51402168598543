const codeLevels = {
    quotes: 3,
    modules : 4,
    content: 5,
    followUp: 6,
    discover: 7,
    social: 5,
    journey: 8,
    journal: 9,
    extraContent: 10,
}
/***
const levelRank = {
    0: 'Starter',
    1: 'Newbie',
    2: 'Rookie',
    3: 'Student',
    4: 'Engager',
    5: 'Insider',
    6: 'Graduate',
    7: 'High-Performer',
    8: 'Expert',
    9: 'Pro',
    10: 'Leader',
    11: 'Teacher',
    12: 'Elite',
    13: 'Master',
    14: 'Champion',
    15: 'Hero',
    16: 'Legend',
    17: 'Megastar',
    18: 'Royalty',
    19: 'Superhuman',
    20: 'Yoda'
}

const levelShortXPMap = {
    0: 0,
    1: 1,
    2: 10,
    3: 25,
    4: 40,
    5: 60,
    6: 90,
    7: 130,
    8: 180,
    9: 240,
    10: 310,
    11: 390,
    12: 480,
    13: 580,
    14: 690,
    15: 810,
    16: 940,
    17: 1100,
    18: 1300,
    19: 1600,
    20: 2000
}
 */

const levelXPMap = {
    0: 0,
    1: 3,
    2: 5,
    3: 15,
    4: 25,
    5: 45,
    6: 70,
    7: 100,
    8: 130,
    9: 165,
    10: 200,
    11: 240,
    12: 300,
    13: 380,
    14: 480,
    15: 600,
    16: 740,
    17: 900,
    18: 1100,
    19: 1350,
    20: 1700,
    21: 2200,
    22: 2800,
    23: 3600,
    24: 4600,
    25: 5800,
    26: 7400,
    27: 10000,
    28: 13000,
    29: 16000,
    30: 20000
}

export function CurrentXPLevel(propsUser){
    var xpCurrent = 0;
    var xpLevel = 0;
    if(propsUser!==undefined && propsUser.engagementCounter !== undefined && propsUser.engagementCounter > 0){
        xpCurrent = propsUser.engagementCounter;
        for(let level in levelXPMap){
            //console.log(level, levelXPMap[level], xpCurrent )
            if(levelXPMap[level] >= xpCurrent){
                //console.log('levels done', xpLevel, levelXPMap[level], xpCurrent )
                break;
            }
            xpLevel = parseInt(level);
        }
    }
    return xpLevel;
}

export function CheckXPLevel(propsUser, code='', level=3) {
    var xpCurrent = 0;
    var valid = false;
    var xpRequired = 1;
    var xpMissing = 1;
    var chatsRequired = 4;
    var chatsMissing = 4;
    var chatsCurrent = 0;
    //// OPTION 1 Check the CHATS
    if(propsUser.dialogueCounter !== undefined && propsUser.dialogueCounter > 0){
        chatsCurrent = propsUser.dialogueCounter;
        if(chatsCurrent<chatsMissing) chatsMissing -= chatsCurrent;
        else chatsMissing = 0;
    }
    var text = 'You need to gain more XP!';
    //// OPTION 2 Check the XP
    if(propsUser!==undefined && propsUser.engagementCounter !== undefined && propsUser.engagementCounter > 0){
        xpCurrent = propsUser.engagementCounter;
    }
    if(code!=='' && codeLevels[code] !== undefined && codeLevels[code] > 0){
        xpRequired = levelXPMap[codeLevels[code]];
    }
    else if(level > 0){
        xpRequired = level;
    }
    //console.log(code, codeLevels[code] , levelXPMap[codeLevels[code]])
    if(xpCurrent >= xpRequired || chatsCurrent >= chatsRequired){
        valid = true;
        xpMissing = 0; 
        text = 'You are ready to go!';
    }
    else{
        xpMissing = xpRequired - xpCurrent;
        if(xpMissing < 100 || chatsCurrent >= 1) text = 'You need only ' + xpMissing + ' XP more, complete ' + chatsMissing + ' more chats or subscribe to a plan to access this feature!';
        else text = 'You need to collect ' + xpRequired + ' XP, complete ' + chatsRequired + ' chats or subscribe to a plan to access this feature!';
    }
    //console.log({code:code, valid: valid, required: xpRequired, missing: xpMissing, text})
    return {valid: valid, required: xpRequired, missing: xpMissing, text}
}