import React, { Component } from 'react';
//import { Link } from "react-router-dom";
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
//import { IsMobileDevice } from '../Modules/IsMobileDevice';
//import CardBigFive from '../Assessments/CardBigFive';

import ProgressRadar from '../Charts/ProgressRadar';


//import SchoolIcon from '@material-ui/icons/School';
import Card from '@material-ui/core/Card';

const styles = theme => ({
	desktopPadding: {
	 	minHeight: 40,
    },
    listItem: {
        boxShadow: 1,
        border: '0px',
        borderRadius: theme.borderRadius,
    },
});

class CardAssessmentFactors extends Component {
    //static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = { 
            loading: true
        };
    } 

    componentDidMount() {
        this._isMounted = true;
        if(this.props.user!== undefined && this.props.user.uid !== undefined && this.props.personalityTraits === undefined){
            this.loadPersonalityTrait(this.props.user)
        }
    }
    componentWillUnmount (){
        this._isMounted = false;
    }

    loadPersonalityTrait(user){
        var personalityTraits = [50,50,50,50,50];
        //var personalityTraits = {};
        var userTraits = personalityTraits;
        //console.log('loca', user)
        if(this.props.showPERMA && user?.userAssessment?.['pP'] !== undefined){
            userTraits = user.userAssessment;
            personalityTraits = [
                userTraits['pP'] || 50,
                userTraits['pE'] || 50,
                userTraits['pR'] || 50,
                userTraits['pM'] || 50,
                userTraits['pA'] || 50,
                userTraits['pV'] || 50
            ]
            //console.log('traits pre loaded', personalityTraits)
            if(this._isMounted) this.setState({personalityTraits})
        }
        else if(this.props.showPERMA !== true && user?.userAssessment?.['0'] !== undefined){
            userTraits = user.userAssessment;
            personalityTraits = [
                userTraits['O'] || 50,
                userTraits['C'] || 50,
                userTraits['E'] || 50,
                userTraits['A'] || 50,
                userTraits['N'] || 50
            ]
            //console.log('traits pre loaded', personalityTraits)
            if(this._isMounted) this.setState({personalityTraits})
        }
        else{
            this.props.firebase
            .personalityTrait(user.uid)
            .get()
            .then(snapshot => {
            //.onSnapshot(snapshot => {
                if (!snapshot.empty) {
                    var userData = snapshot.data();
                    if(this._isMounted) {
                        if(userData !== undefined && userData.userAssessment !== undefined ){
                            if(this.props.showPERMA && userData?.userAssessment?.['pP'] !== undefined){
                                userTraits = userData.userAssessment;
                                personalityTraits = [
                                    userTraits['pP'] || 50,
                                    userTraits['pE'] || 50,
                                    userTraits['pR'] || 50,
                                    userTraits['pM'] || 50,
                                    userTraits['pA'] || 50,
                                    userTraits['pV'] || 50
                                ]
                                //console.log('traits pre loaded', personalityTraits)
                                if(this._isMounted) this.setState({personalityTraits})
                            }
                            else if(this.props.showPERMA !== true && userData.userAssessment['O'] !== undefined){
                                userTraits = userData.userAssessment;
                                personalityTraits = [
                                    userTraits['O'] || 50,
                                    userTraits['C'] || 50,
                                    userTraits['E'] || 50,
                                    userTraits['A'] || 50,
                                    //Math.abs(100- userTraits['N'] )
                                    userTraits['N'] || 50
                                ]
                            }
                        }
                    }
                    //console.log('traits assessment loaded', personalityTraits, userData)
                }
                if(this._isMounted) this.setState({personalityTraits})
            });
        }
    }  

    render() {
        //console.log('state', this.state)
        const {mainCategory, mainQualitiesDicts, engagementStats} = this.props;
        return (
            <div>
                {this.props.user !== undefined
                    ?
                        this.props.showCustom
                        ?   this.renderCUSTOM(mainCategory, mainQualitiesDicts, engagementStats) 
                        :
                            this.props.showPERMA
                            ?   this.renderPERMA(mainCategory, mainQualitiesDicts, engagementStats) 
                            :   this.renderBigFive(mainCategory, mainQualitiesDicts, engagementStats) 
                    :   "...loading"
                }
            </div>
            
        )
    }


    renderBigFive (mainCategory, mainQualitiesDicts, engagementStats) {
        const { theme } = this.props;
        //console.log(mainQualitiesDicts, engagementStats)
        var percentageProgram = 0;
        if(mainCategory?.value?.length>1 && engagementStats!==undefined && engagementStats['leadership_category'] !==undefined && engagementStats['leadership_category'][mainCategory.value] !==undefined ){
            percentageProgram = Math.round(engagementStats['leadership_category'][mainCategory.value]["points"] / this.state.engagementBenchmarkCategory * 100)
        }
        var engagementTypes =[0,0,0,0];
        var engagementLabels = ['', '', '', ''];
        var engagementColors = [];
        var index = 0;
        if (mainQualitiesDicts!==undefined && mainQualitiesDicts!==null && mainQualitiesDicts.length>=1){
            for (index in mainQualitiesDicts){
                //console.log(key, index)
                if(mainQualitiesDicts[index]!== undefined && mainQualitiesDicts[index]!== null){
                    if(engagementStats!==undefined && engagementStats['leadership_quality'] !==undefined && engagementStats['leadership_quality'][mainQualitiesDicts[index].valueID] !==undefined ){
                        engagementTypes[index] = engagementStats['leadership_quality'][mainQualitiesDicts[index].valueID]["points"];
                        engagementLabels[index] = mainQualitiesDicts[index].text;
                        if(mainQualitiesDicts[index].backgroundColor!==undefined && mainQualitiesDicts[index].backgroundColor !== ""){
                            engagementColors[index] = mainQualitiesDicts[index].backgroundColor+"7D";
                        }
                        else engagementColors[index] = theme.palette.secondary.light+"7D";
                    }
                }
            }
        }
        var personalityTraits = [50,50,50,50,50];
        if(this.state.personalityTraits!==undefined && this.state.personalityTraits[0]>0){
            //console.log('change from state', this.state.personalityTraits)
            personalityTraits = [...this.state.personalityTraits];
            personalityTraits[4]  = Math.abs(100- this.state.personalityTraits[4] )
        }
        var personalityDelta = [...personalityTraits.slice()];
        var personalityTarget = [...personalityTraits.slice()];
        var personalityPlusDelta = [0,0,0,0,0];
        var personalityPlusTarget = [0,0,0,0,0];
        if (mainCategory!==undefined && mainCategory!==null && mainCategory.personalityTraitsTarget!==undefined){
            index = 0;
            for (var key of ["O", "C", "E", "A", "N"]){
                //console.log('got they keys', key, index, mainCategory.personalityTraitsTarget[key])
                if(mainCategory.personalityTraitsTarget[key] !==undefined ){
                    //personalityTarget[index] = personalityTarget[index] * ( 1 + ( mainCategory.personalityTraitsTarget[key] ) / 100);
                    personalityTarget[index] = personalityTraits[index] + ( ( (100-personalityTraits[index])/4 + mainCategory.personalityTraitsTarget[key] )/4 );
                    //console.log('result', index, personalityTraits[index], personalityTarget[index], ( (100-personalityTraits[index]) + mainCategory.personalityTraitsTarget[key] )/2 )
                    /***
                    if(personalityTraits[index] > mainCategory.personalityTraitsTarget[key]){
                        personalityTarget[index] = personalityTarget[index] * ( 1 + ( mainCategory.personalityTraitsTarget[key] ) / 100);
                        //personalityTarget[index] = personalityTraits[index] + 3 +  ( 3 * mainCategory.personalityTraitsTarget[key] ) / 100
                    }
                    else{
                        personalityTarget[index] = (personalityTraits[index] + mainCategory.personalityTraitsTarget[key]) / 2;
                    }
                     */
                    
                    //if(personalityTarget[index] > 95) personalityTarget[index] = 95
                    personalityDelta[index] = (personalityTarget[index] + personalityTraits[index] ) / 2;
                    if(percentageProgram > 0 ){
                        personalityDelta[index] =  personalityTraits[index] + (personalityTarget[index] - personalityTraits[index] ) * percentageProgram / 100;
                    }
                    personalityPlusDelta[index] = personalityDelta[index] - personalityTraits[index];
                    personalityPlusTarget[index] = personalityTarget[index] - personalityPlusDelta[index] - personalityTraits[index];
                }
                index +=1;
            }
            //console.log("OCEAN", personalityTraits, "targets", personalityTarget, 'delta', personalityDelta, 'from category', mainCategory.personalityTraitsTarget)
        }
        ////// Final Rendering of Graph
        //console.log('personalityTraits', personalityTraits)
        if (this.props.user !==undefined){
            return (
                <div style={{height: "auto", maxHeight: 250}}>
                    {/*** 
                    <CardBigFive 
                        userTraitHandler = {(userTraits) => { this.handlerUpdate(userTraits)  }}
                        handleClose = {() => this.setState({ openBigFive: false})}
                        showSummary = {false}
                        showButton = {false}
                        category = {'OCEAN'}
                        openHandler = {this.state.openBigFive || false}
                        allowDialog = {true}
                        isCoach = {this.props.isCoach || false}
                        user = {this.props.user || undefined}
                        />
                    */}
                    <Card style={theme.CardGoals} 
                        onClick={ () => {
                                if(this.props.handlerNext !== undefined){
                                    this.props.handlerNext();
                                }
                                //console.log('print from push button')
                                //this.setState({openBigFive: true})
                            }}
                    >
                        <div style={{padding:10}}>
                            <strong>Personality Factors</strong>
                        </div>
                        <div style={{paddingBottom:14}}>
                            {personalityTraits?.length > 0
                            &&
                                <ProgressRadar
                                    suggestedMax={70}
                                    isCoach = {this.props.isCoach || false}
                                    values={personalityTraits}
                                    //labels={["Open","Organized","Outgoing","Warm","Calm"]}
                                    labels={["Openness","Conscious","Extroversion","Agreeableness","Neuroticism"]}
                                    colorMain={theme.palette.themeBlue}
                                    colorBackGround={theme.palette.themeBlue+ "44"}
                                />
                            }
                        </div>
                        {this.props.handlerNext!==undefined &&
                            <p style={theme.textSupportCenter}>
                                <span style={{color: theme.palette.secondary.main}}>
                                    {this.props.user?.currentAssessment === undefined
                                        ?   "Assess your personality"
                                        :   "Show personality assessments"
                                    }
                                </span>
                            </p>
                        }
                    </Card>
                </div>
            )
        }
        else {
            return ""
        }
    }

    renderPERMA (mainCategory, mainQualitiesDicts, engagementStats) {
        const { theme } = this.props;
        var personalityTraits = [50,50,50,50,50,50];
        if(this.state.personalityTraits!==undefined && this.state.personalityTraits[0]>0){
            //console.log('change from state', this.state.personalityTraits)
            personalityTraits = [...this.state.personalityTraits];
        }
        ////// Final Rendering of Graph
        //console.log('personalityTraits', personalityTraits)
        if (this.props.user !==undefined){
            return (
                <div style={{height: "auto", maxHeight: 250}}>
                    <Card style={theme.CardGoals} 
                        onClick={ () => {
                                if(this.props.handlerNext !== undefined){
                                    this.props.handlerNext();
                                }
                                //console.log('print from push button')
                                //this.setState({openBigFive: true})
                            }}
                    >
                        <div style={{padding:10}}>
                            <strong>Thriving Factors</strong>
                        </div>
                        <div style={{paddingBottom:14}}>
                            {personalityTraits?.length > 0
                            &&
                                <ProgressRadar
                                    suggestedMax={70}
                                    isCoach = {this.props.isCoach || false}
                                    values={personalityTraits}
                                    labels={["Positivity","Engagement","Relationships","Meaning","Accomplishment","Life-Balance"]}
                                    colorMain={theme.palette.themeBlue}
                                    colorBackGround={theme.palette.themeBlue+ "44"}
                                />
                            }
                        </div>
                        {this.props.handlerNext!==undefined &&
                            <p style={theme.textSupportCenter}>
                                <span style={{color: theme.palette.secondary.main}}>
                                    {this.props.user?.currentAssessment === undefined
                                        ?   "Assess your personality"
                                        :   "Show personality assessments"
                                    }
                                </span>
                            </p>
                        }
                    </Card>
                </div>
            )
        }
        else {
            return ""
        }
    }

    renderCUSTOM (mainCategory, mainQualitiesDicts, engagementStats) {
        const { theme } = this.props;
        var flagActive = false;
        if (this.props.user !== undefined){
            var questionnaireKeys = this.props.questionnaireDetails?.resultTexts || {};
            var newKeys = Object.keys(questionnaireKeys);
            var labels = [];
            var personalityTraits = [];
            newKeys.map((item) => {
                //console.log('item', item)
                if(this.props.user?.userAssessment?.[item] !== undefined){
                    flagActive = true;
                    var text = questionnaireKeys?.[item]?.text || "";
                    //console.log('infoso', item, text, questionnaireKeys, questionnaireKeys?.[item])
                    if(text.length > 0){
                        if(text.length > 12){
                            text = text.substring(0,11) + "..."
                        }
                        labels.push(text);
                        personalityTraits.push(this.props.user?.userAssessment?.[item] || 0);
                    }
                }
                return true;
            })
            if(labels.length < 1){
                labels = ['Result'];
            }
            ////// Final Rendering of Graph
            //console.log('personalityTraits', this.props, newKeys, labels, questionnaireKeys)
            if (flagActive){
                return (
                    <div style={{height: "auto", maxHeight: 250}}>
                        <Card style={theme.CardGoals} 
                            onClick={ () => {
                                    if(this.props.handlerNext !== undefined){
                                        this.props.handlerNext();
                                    }
                                    //console.log('print from push button')
                                    //this.setState({openBigFive: true})
                                }}
                        >
                            <div style={{padding:10}}>
                                <strong>{this.props.questionnaireDetails?.title || "Assessment"}</strong>
                            </div>
                            <div style={{paddingBottom:14}}>
                                {personalityTraits?.length > 0
                                &&
                                    <ProgressRadar
                                        suggestedMax={70}
                                        isCoach = {this.props.isCoach || false}
                                        values={personalityTraits}
                                        labels={labels}
                                        colorMain={theme.palette.themeBlue}
                                        colorBackGround={theme.palette.themeBlue+ "44"}
                                    />
                                }
                            </div>
                            {this.props.handlerNext!==undefined &&
                                <p style={theme.textSupportCenter}>
                                    <span style={{color: theme.palette.secondary.main}}>
                                        {this.props.user?.currentAssessment === undefined
                                            ?   "Assess your personality"
                                            :   "Show personality assessments"
                                        }
                                    </span>
                                </p>
                            }
                        </Card>
                    </div>
                )
            }
        }
        else {
            return ""
        }
    }

    handlerUpdate = (userTraits) => {
        //console.log('main update', userTraits)
        //invert Neuroticm
        //userTraits["N"]  = Math.abs(100- userTraits["N"] )
        this.setState({
            openBigFive: false, 
            personalityTraits : userTraits
        })
    }

}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true}),
)(CardAssessmentFactors);
