export function ConvertTimestampToDateString(timestamp, pretty=false)  {
    //console.log('time', timestamp)
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    if(timestamp!== undefined && timestamp !== null){
        let date = new Date();
        if (timestamp instanceof Date) {
            date = timestamp;
        }
        else{
            let ts = timestamp.seconds;
            date = new Date(ts * 1000);
        }
        //console.log('date', date)     
        let year = date.getFullYear();
        let monthVar = date.getMonth() + 1;
        let day = date.getDate();
        let hour = date.getHours();
        let minute = date.getMinutes();

        let month = (monthVar < 10 ? "0" : "") + monthVar;
        day = (day < 10 ? "0" : "") + day;
        hour = (hour < 10 ? "0" : "") + hour;
        minute = (minute < 10 ? "0" : "") + minute;

        if(pretty){
            //console.log('date', year, day)   
            return year + "-" + months[monthVar-1]  + "-" + day;
        }
        else{
            //console.log('normal', year, day)
            return year + "-" + month + "-" + day + " [" + hour + ":" + minute + "]";
        }
    }
    else {
        if(pretty){
            return ""
        }
        else return "2000-01-01 [00:00]";
    }
}
export function ConvertTimeZoneStampToPresentingString(timestamp, timezone, format='default')  {
    //console.log('time', timestamp)
    if(timezone !== undefined && timezone !== '' ){
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        if(timestamp!== undefined && timestamp !== null){
            let date = new Date();
            //console.log('date sys', timezone, date)     
            if (timestamp instanceof Date) {
                date = timestamp;
            }
            else{
                let ts = timestamp.seconds;
                date = new Date(ts * 1000);
            }
            //console.log('date before', timezone, date)    
            date = new Date(date.toLocaleString('en-US', { timeZone: timezone }));
            //console.log('date local', date, timezone)     
            let year = date.getFullYear();
            let monthVar = date.getMonth() + 1;
            let weekday = date.getDay();
            let day = date.getDate();
            let hour = date.getHours();
            let minute = date.getMinutes();

            //let month = (monthVar < 10 ? "0" : "") + monthVar;
            day = (day < 10 ? "0" : "") + day;
            hour = (hour < 10 ? "0" : "") + hour;
            minute = (minute < 10 ? "0" : "") + minute;

            if(format === 'weekday'){
                //console.log('date', year, day)   
                return (year + "-" + months[monthVar-1] + "-" + day + " [" + hour + ":" + minute + "] " + weekdays[weekday] );
            }
            else{
                //console.log('normal', year, day)
                return (year + "-" + months[monthVar-1] + "-" + day + " [" + hour + ":" + minute + "]" );
            }
        }
        else {
            return "2000-01-01 [00:00]";
        }
    }
    else{
        return ConvertTimestampToDateString(timestamp, false);
    }
}