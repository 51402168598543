import queryString from 'query-string';

export function FormatCoachCode(urlCoachCode){
    if(urlCoachCode.startsWith("#/")){
        urlCoachCode = urlCoachCode.replace("#/", "");
    }
    if(urlCoachCode.startsWith("#")){
        urlCoachCode = urlCoachCode.replace("#", "");
    }
    // Find the index of the special character
    const index = urlCoachCode.indexOf("#");
    if (index !== -1) {
        urlCoachCode = urlCoachCode.substring(0, index);
    }
    //console.log('Temp coachcode', index, urlCoachCode)
    urlCoachCode = "#" + urlCoachCode.toUpperCase();
    return urlCoachCode
}

export function CheckCoachCode(){
    //console.log('get coach code function')
    try{
        var urlSearch = window.location.href.slice(window.location.href.indexOf('?'));
        urlSearch = urlSearch?.replace("&amp;state=#/signin?", "&")
        var query = queryString.parse(urlSearch);
        var urlCoachCode = "";
        if(query?.coachcode?.length > 3){
            console.log('We have a coachcode', query)
            //console.log('query?.coachcode', query?.coachcode)
            urlCoachCode = query?.coachcode;
            ///// a few tricks to remove eventual coonestallatioon with hastag for navigation versus coachcode with hashtag versus
            urlCoachCode = FormatCoachCode(urlCoachCode)
            window?.sessionStorage?.setItem('urlCoachCode', urlCoachCode);
            //console.log('Detected coachcode', urlCoachCode)
        }
        return urlCoachCode
    }
    catch(error){
        return ""
    }
}

function requestAPI(url, options) {
    //console.log('options', options)
    //console.log(options)
    return fetch(url, {
        mode: 'cors',
        headers: {
            //'Access-Control-Allow-Origin': '*',
            //'Access-Control-Allow-Headers': "*",
            'Content-Type': 'application/json',
        },
        ...options
    });
}

export async function CheckCodeAndTokenAppSumo(that){
    //console.log('get coach code function')
    try{
        var urlSearch = window.location.href.slice(window.location.href.indexOf('?'));
        var query = queryString.parse(urlSearch);
        var urlCoachCode = "";
        if(query?.code?.length > 3){
            console.log('query APP Sumo', query.code)
            const code = query?.code;
            const content = {
                client_id: '079734314742',
                client_secret: 'a6975d42c844ede5ead981d0ae8fdbe545364df486010b50d1bf3795',
                code: code,
                //// has to be exactly like in the protal defined
                redirect_uri: 'https://app.rocky.ai/#/signin?coachcode=appsumo',
                grant_type: 'authorization_code'
                //refresh_token: code,
                //grant_type: 'refresh_token'
            }
            //console.log('full content', content)
            var formData = {
                method: 'post',
                body: JSON.stringify(content)
            }
            var response_token = await requestAPI( 'https://appsumo.com/openid/token/', formData);
            var result_token = await response_token.json();
            console.log('result_token', result_token)
            if(!(result_token?.access_token?.length > 2)){
                let content_refresh = {
                    client_id: '079734314742',
                    client_secret: 'a6975d42c844ede5ead981d0ae8fdbe545364df486010b50d1bf3795',
                    refresh_token: code,
                    grant_type: 'refresh_token'
                }
                formData = {
                    method: 'post',
                    body: JSON.stringify(content_refresh)
                }
                response_token = await requestAPI( 'https://appsumo.com/openid/token/', formData);
                result_token = await response_token.json();
                console.log('refresh_token', result_token)
            }
            if(result_token?.access_token?.length > 2){
                let response_key = await fetch('https://appsumo.com/openid/license_key/?access_token=' + result_token.access_token, {
                    method: 'GET'
                });
                let result_key = await response_key.json();
                console.log('result_key', result_key)
                if(result_key?.license_key?.length > 2){
                    urlCoachCode = result_key.license_key;
                    urlCoachCode = FormatCoachCode(urlCoachCode);
                    window?.sessionStorage?.setItem('urlCoachCode', urlCoachCode);
                    window?.sessionStorage?.setItem('urlPrioCoachCode', urlCoachCode);
                }
            }
        }
        return urlCoachCode
    }
    catch(error){
        console.log('Error Appsumo API', error)
        return error
    }
}