import React, { Component } from 'react';
import {Chart, HorizontalBar, Bar} from 'react-chartjs-2';

import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    grow: {
      flexGrow: 1,
    },
});


class ProgressBars extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        var [data, options] = this.loadChartData();
        this.state = { data, options};
    }

    componentDidMount() {
        this._isMounted = true;
        Chart.pluginService.register({
            afterDraw: function (chart) {
                if (chart.config.data.labels) {
                    //Get ctx from string
                    /****
                    var ctx = chart.chart.ctx;
                    ctx.save();
                    var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
                    var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
                    console.log(chart.config.data, centerX, centerY)
                    chart.config.data.labels.forEach((value, index) => {
                        if(typeof chart.config.data.labels[index] === 'object'){
                            let image = new Image();
                            image.src = value;
                            console.log(" image.src",  image.src)
                            ctx.drawImage(image, centerX, centerY)
                        }
                    })
                    ctx.restore();
                     */
                }
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps)  {
        //console.log('updated values',  prevProps)
        if(this._isMounted){
            var newValues = '';
            var oldValues = '';
            if(this.props.values !== undefined && this.props.values !== ""){
                newValues = this.props.values
            }
            if(prevProps.values !== undefined && prevProps.values !== ""){
                oldValues = prevProps.values
            }
            if(newValues === oldValues){
                if(this.props.valuesInMiddle !== undefined && this.props.valuesInMiddle !== ""){
                    newValues = this.props.valuesInMiddle
                }
                if(prevProps.valuesInMiddle !== undefined && prevProps.valuesInMiddle !== ""){
                    oldValues = prevProps.valuesInMiddle
                }
            }
            if(newValues !== oldValues && newValues!== "" && oldValues!=="") {
                var [data, options] = this.loadChartData();
                this.setState({ data, options});
                //console.log('updated values',  data, options)
            }
        }
        return true
    }

    loadChartData() {
        const {theme} = this.props;
        var values = [0,0,0];
        var labels = ['', '', ''];
        if(this.props.values !== undefined &&  this.props.values !== null && this.props.values.length > 0){
            values = this.props.values;
        }
        if(this.props.labels !== undefined &&  this.props.labels !== null && this.props.labels.length > 0){
            labels = this.props.labels;
        }
        //var colorText = this.props.theme.palette.secondary.dark;
        //if(this.props.colorText !== undefined && this.props.colorText !== null && this.props.colorText[0] === "#" ){
        //    colorText = this.props.colorText;
        //}
        var colorMain = this.props.theme.palette.secondary.light;
        if(this.props.colorMain !== undefined && this.props.colorMain !== null && (this.props.colorMain[0] === "#" || this.props.colorMain[0]?.[0] === "#")){
            colorMain = this.props.colorMain;
        }
        var colorBackGround = this.props.theme.palette.themeNaviGrey;
        if(this.props.colorBackGround !== undefined && this.props.colorBackGround !== null && (this.props.colorBackGround[0] === "#" || this.props.colorBackGround[0]?.[0] === "#")){
            colorBackGround = this.props.colorBackGround;
        }
        var colorMiddle = this.props.theme.palette.primary.dark;
        if(this.props.colorMiddle!==undefined && this.props.colorMiddle!==null && (this.props.colorMiddle[0] === "#" || this.props.colorMiddle[0]?.[0] === "#") ){
            colorMiddle = this.props.colorMiddle;
        }
        if(this.props.itemColor!==undefined && this.props.itemColor!==null && this.props.itemColor.length > 0){
            //console.log('got the colors', this.props.itemColor)
            colorMiddle = this.props.itemColor;
        }
        var valuesInBack = [];
        if(this.props.valuesInBack !== undefined && this.props.valuesInBack !== null && this.props.valuesInBack.length > 0 ){
            valuesInBack = this.props.valuesInBack;
        }
        var valuesInMiddle = [];
        if(this.props.valuesInMiddle !== undefined && this.props.valuesInMiddle !== null && this.props.valuesInMiddle.length > 0 ){
            valuesInMiddle = this.props.valuesInMiddle;
        }
        let data = {
            labels: labels,
            datasets: [
                {
                    label: 'Initial',
                    backgroundColor: colorMain,
                    borderColor: colorMain,
                    borderWidth: 1,
                    data: values
                },
                {
                    label: 'Current', // removed in options
                    backgroundColor: colorMiddle,
                    borderColor: colorMiddle,
                    hoverBackgroundColor: '#fff',
                    hoverBorderColor: '#fff',
                    highlightFill: this.props.theme.palette.primary.dark,
                    borderWidth: 1,
                    data: valuesInMiddle
                },
                {
                    label: 'Total',
                    backgroundColor: colorBackGround,
                    borderColor: colorMiddle,
                    borderWidth: 1,
                    data: valuesInBack
                },
            ],
        };

        let options = {
            events: [],
            responsive : true,
            legend: {display: false},
            maintainAspectRatio : false,
            aspectRatio: 1,
            scales: {
                yAxes: [
                    {
                        stacked: true,
                        ticks: {
                            beginAtZero: false,
                            fontColor: theme.palette.themeText
                        },
                    },
                ],
                xAxes: [
                    {
                        stacked: true,
                        ticks: {
                            fontColor: theme.palette.themeText,
                            beginAtZero: false,
                            display: (this.props.displayValues || this.props.displayPercentage) ? true : false,
                            callback: this.props.displayPercentage && this.props.suggestedMax
                                ?   (value) => { 
                                        if(value){
                                            return (Math.round(value/this.props.suggestedMax * 100) + '%')
                                        }
                                        else{
                                            return ''
                                        }
                                    }
                                :   () => {return ''}
                        },
                    },
                ],
            },
        };
        //console.log('props', this.props)
        if (this.props.suggestedMax!==undefined && this.props.suggestedMax>1){
            options['scales']['yAxes'][0]['ticks']['suggestedMax'] = this.props.suggestedMax;
            options['scales']['xAxes'][0]['ticks']['suggestedMax'] = this.props.suggestedMax;
        }
        if (this.props.suggestedStart!==undefined && this.props.suggestedStart===0){
            options['scales']['yAxes'][0]['ticks']['beginAtZero'] = true;
            options['scales']['xAxes'][0]['ticks']['beginAtZero'] = true;
        }
        return [data, options]
    }

    render() {
        if(this.props.type === 'horizontal'){
            return(
                <HorizontalBar
                    options={this.state.options}
                    data={this.state.data}
                />
            )
        }
        else{
            return(
                <Bar
                    options={this.state.options}
                    data={this.state.data}
                />
            )
        }
    }
}

export default compose(
    withStyles(styles, { withTheme: true })
)(ProgressBars);
