import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import * as ROUTES from "../../constants/routes";

import Button from '@material-ui/core/Button';


const styles = theme => ({
    grow: {
      flexGrow: 1,
    },
});

class AppRate extends Component {
    constructor(props) {
        super(props);
        var buttonText = 'Recommend this app';
        if (this.props.buttonText !== undefined && this.props.buttonText !== ''){
            buttonText = this.props.buttonText
        }
        var freeDialoguesRequest = false;
        if (this.props.freeDialoguesRequest){
            freeDialoguesRequest = this.props.freeDialoguesRequest
        }
        this.state = {
            buttonText : buttonText,
            user: this.props.user,
            freeDialoguesRequest
        }
    }

    setUpConnections = () => {
        if(window.AppRate !== undefined){
            try{
                window.AppRate.setPreferences({
                    displayAppName: 'Rocky.ai',
                    usesUntilPrompt: 2,
                    promptAgainForEachNewVersion: false,
                    useLanguage: 'en',
                    inAppReview: true,
                    simpleMode: (this.props.simpleMode ? true: false),
                    showPromptForInAppReview: (this.props.simpleMode? false : true),
                    reviewType: {
                        android: 'InAppBrowser',
                        //android: 'InAppReview',
                        ios: 'InAppReview'
                        //ios: 'AppStoreReview'
                    },
                    storeAppURL: {
                        ios: '1492680249',
                        android: 'market://details?id=ai.rocky.app'
                    },
                    customLocale: {
                        title: "Would you mind recommending %@?",
                        message: "It won't take more than a minute and helps to give other people access. Thanks for your support!",
                        cancelButtonLabel: "No, Thanks",
                        rateButtonLabel: "Recommend Now",
                        laterButtonLabel: "Later",
                        yesButtonLabel: "Yes!",
                        noButtonLabel: "Not really",
                        appRatePromptTitle: 'Do you like using %@',
                        feedbackPromptTitle: 'Mind giving us some feedback and get more free chats?'
                    },
                    openUrl: (url) => {
                        window.open(url, '_blank', 'location=yes');
                        //window.open('mailto:hello@rocky.ai','_system');
                        if(this.props.freeDialoguesRequest ){
                            this.addFreeDialogues();
                        }
                    },
                    callbacks : {
                        handleNegativeFeedback:  () => {
                            if(this.props.dialogueId !== ''){
                                try{
                                    var feedbackText = 'I give your app a low rating.';
                                    if (this.props.freeDialoguesRequest ){
                                        feedbackText = feedbackText + ' But thanks for the free chats.';
                                    }
                                    this.props.db.dialogue(this.props.dialogueId).update({
                                        feedback: feedbackText,
                                        vote: 'down',
                                    });
                                    if(this.props.user?.uid?.length>3){
                                        this.props.db.user(this.props.user?.uid).update({
                                            ratedApp: true,
                                            ratedAppDate: this.props.db.fieldValue.serverTimestamp()
                                        });
                                    }
                                    if(this.props.feedbackEvent !== undefined){
                                        this.props.feedbackEvent();
                                    }
                                }
                                catch (e){
                                    console.log('feedback error', e)
                                }
                            }
                            //window.open('mailto:hello@rocky.ai','_system');
                            if(this.props.freeDialoguesRequest ){
                                this.addFreeDialogues(2);
                            }
                        },
                        onRateDialogShow: (callback) => {
                            //console.log('Showing rate dialogue', callback);
                            callback(1) // cause immediate click on 'Rate Now' button
                            if(this.props.afterRating !== undefined){
                                this.props.afterRating();
                            }
                        },
                        onButtonClicked: (buttonIndex) => {
                            console.log("onButtonClicked -> " + buttonIndex);
                            if(buttonIndex===3 && this.props.freeDialoguesRequest ){
                                this.addFreeDialogues();
                            }
                            if(this.props.afterRating !== undefined){
                                this.props.afterRating();
                            }
                        }
                    }
                });

                if(false && this.props.freeDialoguesRequest){
                    window.AppRate.setPreferences = ({
                        customLocale: {
                            message: "Get more free chats for recommending us!"
                        }
                    });
                }
                this.setState({pluginReady: true})
            } 
            catch(error){
                console.log('cannot setup app urls', error)
                this.setState({pluginReady: false})
            }
        }
        else{
            console.log('no appRate')
            this.setState({pluginReady: false})
        }
    }

    componentDidMount() {
        this.setUpConnections();
    }

    addFreeDialogues = async (extraCount = 4) => {
        try { 
            //console.log('data', this.state, this.props)
            if(this.state.pluginReady && this.props.freeDialoguesRequest  && this.props.user !== undefined && this.props.db !== undefined ){
                var user = this.props.user;
                if(user.uid !== undefined){
                    var currentDialogues = 0;
                    var currentFreeDialogues = 0;
                    if (user.dialogueCounter !== undefined){
                        currentDialogues = user.dialogueCounter;
                    }
                    if (user.freeDialogues !== undefined){
                        currentFreeDialogues = user.freeDialogues;
                    }
                    if(currentDialogues > currentFreeDialogues){
                        extraCount = extraCount + currentDialogues;
                    } else{
                        extraCount = extraCount + currentFreeDialogues;
                    }
                    /*** */
                    if(user.uid !== undefined){
                        await this.props.db.user(user.uid).update({
                            freeDialogues: extraCount,
                            ratedApp: true,
                            ratedAppDate: this.props.db.fieldValue.serverTimestamp()
                        })
                        //console.log(extraCount)
                        if(this.props.purchaseSuccessHandler !== undefined){
                            this.props.purchaseSuccessHandler();
                        }
                        else{
                            this.props.history.push(ROUTES.HOME);
                        }
                    }
                }
            }
            else{
                console.log('no props data', this.state, this.props)
            }
        } catch(err){
            console.log('updating freedialogues error', err)
        } 
    }

    

    render() {
        if(this.state.pluginReady){
            if(this.props.className !== undefined){
                return(
                    <Button 
                        className={this.props.className}
                        onClick={() => this.pluginCallHander()} variant='contained' >
                        {this.props.buttonText}
                    </Button>
                )
            }
            else{
                return(
                    <Button onClick={() => this.pluginCallHander()} variant='contained' >
                        {this.props.buttonText}
                    </Button>
                )
            }
        }
        else return ''
    }

    pluginCallHander = () => {
        try { 
            window.AppRate.promptForRating(true);
        } catch(err){
            console.log('noplugin')
            if(this.props.afterRating !== undefined){
               this.props.afterRating();
            }
        } 
    }

    
}

export default compose(
    withStyles(styles, { withTheme: true }),
    withRouter
)(AppRate);