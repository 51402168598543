function muiStyle (palette) { return {
    a: {
      backgroundColor: 'transparent !important',
      color: `${palette.themeAlertRed} !important`,
      '&:hover': {
        backgroundColor: `${palette.secondary.light} !important`,
        color: `${palette.white} !important`,
        textDecoration: 'underline !important',
      },
      '&:active': {
        backgroundColor: `${palette.themeAlertRed} !important`,
      },
      '&:visited': {
        backgroundColor: `${palette.themeAlertRed} !important`,
      },
    },
    'body a': { // Higher specificity selector
      backgroundColor: 'transparent',
      color: `${palette.themeAlertRed}`,
      '&:hover': {
        backgroundColor: `${palette.secondary.light}`,
        color: `${palette.white}`,
        textDecoration: 'underline',
      },
    },
    root: {
        a: {
          backgroundColor: 'transparent !important',
          color: `${palette.themeAlertRed} !important`,
          '&:hover': {
            backgroundColor: `${palette.secondary.light} !important`,
            color: `${palette.white} !important`,
            textDecoration: 'underline !important',
          },
          '&:active': {
            backgroundColor: `${palette.themeAlertRed} !important`,
          },
          '&:visited': {
            backgroundColor: `${palette.themeAlertRed} !important`,
          },
        },
        color: palette.themeText
    },
    body1: {
        color: palette.themeText
    },
    gutterBottom: {
        marginBottom: 8
    },
}}

export default muiStyle;