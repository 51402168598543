import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
//import {  Link } from 'react-router-dom';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import LoadingProgress from '../../Modules/LoadingProgress';

import { withFirebase } from '../../Firebase';
import { withAuthorization,
	 // withEmailVerification
       } from '../../Session';
import { AuthUserContext } from '../../Session';
import * as ROLES from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';

import DialogQuestionnaireItem from '../Questionnaires/DialogQuestionnaireItem';

// MUI stuff
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import Table from '@material-ui/core/Table';
//import TableCell from '@material-ui/core/TableCell';
//import TableHead from '@material-ui/core/TableHead';
//import TableRow from '@material-ui/core/TableRow';
//import TableBody from '@material-ui/core/TableBody';
import {Column, Table, SortDirection} from 'react-virtualized';
import {  AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css'
import _ from "lodash";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
//import ButtonSwitch from '@material-ui/core/Switch';

//expansion
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// Icons
import AddIcon from '@material-ui/icons/Add';

// Modules for parameter change
import SettingContentBase from '../Modules/SettingContentBase';
import SettingContentActive from '../Modules/SettingContentActive';
import SettingColorPicker from '../Modules/SettingColorPicker';
import SettingContentArray from '../Modules/SettingContentArray';
import SettingContentMapList from '../Modules/SettingContentMapList';
import SettingContentUploadImage from '../Modules/SettingContentUploadImage';
import SettingContentRichText from '../Modules/SettingContentRichText';
import SettingContentArraySelect from '../Modules/SettingContentArraySelect';
import SettingContentArrayCheckbox from '../Modules/SettingContentArrayCheckbox';
import SettingContentSelect from '../Modules/SettingContentSelect';
import SettingContentMapSelect from '../Modules/SettingContentMapSelect';

import {
    CellMeasurer,
    CellMeasurerCache,
    createMasonryCellPositioner,
  } from 'react-virtualized';

//// for keywords
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

//popup
import {RemoveScroll} from 'react-remove-scroll';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const styles = {
    table: {
        minWidth: 100,
    },
    subtitle: {
        marginTop: 20,
        fontWeight: 700,
    },
    addButton: {
        marginTop: 20,
    },
};

class AdminQuestionnairesPage extends Component {
    render() {
        return (
            <div style={{backgroundColor: "#DDDDDD"}}>
                <Switch>
                    <Route exact path={ROUTES.ADMIN} component={QuestionnairesList} />
                    <Route exact path={ROUTES.ADMIN_QUESTIONNAIRES} component={QuestionnairesList} />
                </Switch>
            </div>
        );
    }
}

class QuestionnairesListBase extends Component {
    _isMounted = false;
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        const sortBy = 'active';
        const sortDirection = SortDirection.ASC;
        this.state = {
            loading: false,
            contents: [],
            contentsTab: [],
            contentsLabels: [],
            title: '',
            author: '',
            program: '',
            open: false,
            sortBy,
            sortDirection,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            loading: true,
            open: false, 
            title: '',
            author: '',
            program: '',
            showTab: '',
            errorMessage : ''
        });

        var name = '';

        this.unsubscribe = this.props.firebase
        .questionnaires()
        .orderBy('title', 'asc')
        .onSnapshot(snapshot => {
            if(this._isMounted ){
                let contentsTab = [];
                let contentsTabList = [];
                let contentsLabels = [];
                var content={};

                var keySorting = 'leadership_category'
                
                snapshot.forEach(doc => {
                    if(this.context.roles?.includes('ADMIN')){
                        if(this.context.programs.includes(doc.data().program) || doc.data().program===undefined){
                            content = doc.data();
                            name = content[keySorting];
                            //console.log("content", content)
                            if(name === undefined){
                                name = '---undefined---'
                            }
                            if(!(name in contentsTab)){
                                //contentsTab[doc.data().category]={name:doc.data().category, contents: []};
                                contentsTab[name] = [];
                                contentsTabList[name]= [];
                                contentsLabels.push(name );
                            }
                            contentsTab[name].push({ ...doc.data(), uid: doc.id });
                            contentsTabList[name].push({
                                active: content.active? 'Yes': 'No',
                                category: content.category || '-',
                                actions: content.actions!==undefined ? content.actions.length : '-',
                                text:  content.subtitle ? content.text!=='' ? 'Yes' : '-' : '-',
                                source:  content.source || '-',
                                title:  content.title.length>30 ? (content.title.substring(0,29)+'...'): content.title,
                                subtitle:  content.subtitle ? content.subtitle.length>12 ? (content.subtitle.substring(0,12)+'...'): content.subtitle : '-',
                                program: content.program || '-',
                                id: doc.id,
                                author: content.author || '-',
                                resultTexts: content.resultTexts || undefined,
                            });
                        } // end if true
                    } // end of if ADMIN
                });//end of snapshot


                //console.log('contents', contentsTab)
                if(this.state.showTab === undefined || this.state.showTab === ''){
                    this.setState({showTab: name})
                }
                this.setState({
                    contentsTab: contentsTab,
                    contentsTabList,
                    contentsLabels,
                    loading: false,
                });
            }//end if mounted
        });
    }

    _sortList = ({sortBy, sortDirection}) => {
        const list = this.state.contentsTabList[this.state.showTab];
        //console.log('sortingbefore', sortBy ,sortDirection)
        let newList = _.sortBy(list, [sortBy]);
        if (sortDirection === SortDirection.DESC) {
            newList.reverse();
        }
        //console.log('sorting',newList, this.state.showTab )
        return newList;
    }
    _sort = ({sortBy, sortDirection}) => {
        var sortedList = this.state.contentsTabList;
        sortedList[this.state.showTab] = this._sortList({sortBy, sortDirection});
        this.setState({sortBy, sortDirection, contentsTabList: sortedList});
    }

    _sortQuestionsList = ({sortBy, sortDirection}) => {
        const list = this.state.questionSetList;
        //console.log('sortingbefore', sortBy ,sortDirection)
        let newList = _.sortBy(list, [sortBy]);
        if (sortDirection === SortDirection.DESC) {
            newList.reverse();
        }
        //console.log('sorting',newList, this.state.showTab )
        return newList;
    }
    _sortQuestions = ({sortBy, sortDirection}) => {
        var sortedList = this.state.questionSetList;
        sortedList = this._sortQuestionsList({sortBy, sortDirection});
        this.setState({sortBy, sortDirection, questionSetList: sortedList});
    }

    loadContentDetails(key, index){
        var lastContentId =  this.state.currentContentId;
        var currentContentId = this.state.contentsTabList[key][index].id
        this.setState({
            currentContentId, 
            dialogOpen:true,
            currentContent: this.state.contentsTabList[key][index],
        })
        //console.log('contentchange', lastContentId,  currentContentId)
        if(lastContentId !== currentContentId){
            this.loadQuestionsSet(currentContentId)
        }
    }

    loadQuestionsSet(lastContentId) {
        if( this.unsubscribeQuestions!== undefined ) this.unsubscribeQuestions();

        this.unsubscribeQuestions = this.props.firebase
        .questionnaireSet(lastContentId)
        .onSnapshot(snapshot => {
            if(this._isMounted ){
                let questionSet = [];
                let questionSetList = [];
                //console.log('loading', lastContentId)
                snapshot.forEach(doc => {
                    if(this.context.roles?.includes('ADMIN')){
                            var content = doc.data();
                            questionSet.push({ ...content, id: doc.id });
                            questionSetList.push({
                                active: content.active? 'Yes': 'No',
                                scale: (content.pointsScaleLikert !== undefined || content.pointsResponse0 !== undefined )? 'Yes': 'No',
                                category: content.category || '-',
                                type: content.type || '-',
                                variant: content.variant || '-',
                                position:  content.position || '-',
                                source:  content.source || '-',
                                question:  (content.question !== undefined && content.question.length>30 )? (content.question.substring(0,29)+'...'): content.question,
                                program: content.program || '-',
                                id: doc.id,
                                author: content.author || '-',
                            });
                    } // end of if ADMIN
                });//end of snapshot

                this.setState({
                    questionSet,
                    questionSetList,
                    loading: false,
                });
            }//end if mounted
        });
    }

    handleOpen = () => {
        //console.log('handleOpen');
        this.setState({ open: true });
    };

    handleClose = () => {
        //console.log('handleClose');
        this.setState({ open: false });
    };

    handleChange = (event) => {
        //console.log([event.target.name] + ": " + event.target.value);
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = () => {
        if(this.state.title !== '' && this.state.author !== '' && this.state.category !== '') {
            const timestamp = this.props.firebase.fieldValue.serverTimestamp();
            this.props.firebase.questionnaires().add({
                title: this.state.title,
                author: [this.state.author],
                program: this.state.program,
                active: false,
                createdAt: timestamp
            });
            this.setState({errorMessage : 'Added content (category undefined)'})
        }
        else{
            this.setState({errorMessage : 'All three values have to be defined to add a content'})
        }
        
        this.setState({
            title: '',
            author: '',
            program: '',
        });
        this.handleClose();
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
        if( this.unsubscribeQuestions!== undefined ) this.unsubscribeQuestions();
    }

    handleTabChange = (event, newValue) => {
        //console.log('new tab', newValue)
        this.setState({
            showTab: newValue
        });
    }

    render() {
        //console.log("state", this.state)
        const { showTab, contentsTab, contentsLabels, questionSetList, loading } = this.state;
        return (
            <div style={{height: '100vh'}}>
                {loading && 
                    <LoadingProgress 
                        defaultSet={this.props.defaultSet}
                        type={'LoadingPageAnimated'} 
                        loadingText={"many, many, data"} 
                        firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                        allowRefresh={true}
                    />
                }
                <div>
                    <IconButton style={styles.addButton} onClick={this.handleOpen}>
                        <AddIcon />
                    </IconButton>
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        fullWidth
                        maxWidth="sm">
                        <DialogContent>
                            <DialogTitle>Add a new content set</DialogTitle>
                            <form>
                                <TextField
                                name="title"
                                type="text"
                                label="1. Title"
                                placeholder="Enter title"
                                value={this.state.title}
                                onChange={this.handleChange}
                                fullWidth
                                style={ {fontSize: '16px'} }
                                />
                                <TextField
                                name="author"
                                type="text"
                                label="2. Author"
                                placeholder="Enter content author name"
                                value={this.state.author}
                                onChange={this.handleChange}
                                fullWidth
                                style={ {fontSize: '16px'} }
                                />
                                <p>3. Select program</p>
                                <Select
                                value={this.state.program}
                                onChange={this.handleChange}
                                name='program'
                                >
                                {this.context.programs.map(entry => {
                                        return (
                                            <MenuItem key={"add_"+entry} value={entry}
                                            >
                                            {entry}
                                            </MenuItem>
                                        );
                                        })
                                    
                                }
                                    
                                </Select>
                                
                                </form>   
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose}>
                            Cancel
                            </Button>
                            <Button onClick={this.handleSubmit}>
                            Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                {this.state.errorMessage !== '' && 
                    <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                }
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={this.handleTabChange}
                    initialselectedindex={0}
                    value={showTab}
                    >
                    {contentsLabels.length>0 && 
                        contentsLabels.map( (key) => (
                        <Tab 
                            value={key} 
                            key={key}
                            label={key + " ("+contentsTab[key].length+")"}/>
                        ))
                    }
                </Tabs>
            
                {contentsLabels.length>0 &&  contentsLabels.map( (key) => (
                    <div 
                        value={key} 
                        key={key}
                        style = {{minHeight : 300 }}
                        hidden={showTab !==key}>

                        <AutoSizer>
                        {({ height, width }) => (
                            <Table
                                width={width}
                                height={300}
                                headerHeight={20}
                                rowHeight={30}
                                rowCount={this.state.contentsTabList[key].length}
                                sort={this._sort}
                                sortBy={this.state.sortBy}
                                sortDirection={this.state.sortDirection}
                                rowGetter={({index}) => this.state.contentsTabList[key][index]}
                                onRowClick={({ event, index, rowData })  => {
                                    this.loadContentDetails(key, index)
                                }}
                                    >
                                <Column label="Active" dataKey="active" width={100} disableSort={false}  />
                                <Column label="Program" dataKey="category" width={130} disableSort={false} />
                                <Column label="Title" dataKey="title" width={200} disableSort={false} />
                                <Column label="Subtitle" dataKey="subtitle" width={200} disableSort={false} />
                                <Column label="Domain" dataKey="program" width={130} disableSort={false} />
                                <Column label="Action" dataKey="actions" width={100} disableSort={false} />
                                <Column label="Text" dataKey="text" width={100} disableSort={false} />
                                <Column label="Source" dataKey="source" width={130} disableSort={false} />
                                <Column label="Author" dataKey="author" width={130} disableSort={false}  />
                                
                            </Table>
                        )}
                        </AutoSizer>
                    </div>
                ))  }
                <QuestionnairesItemDialog 
                    contentId={this.state.currentContentId}
                    contentsLabels={contentsLabels}
                    openDialog={this.state.dialogOpen|| false}
                    closeDialog={(e) => {this.setState({dialogOpen: false })}}
                />
                <DialogQuestionnaireItem 
                    questionId={this.state.currentQuestionId}
                    questionnaireId={this.state.currentContentId}
                    questionnaireSettings = {this.state.currentContent || undefined}
                    openDialog={this.state.dialogQuestionOpen|| false}
                    closeDialog={(e) => {this.setState({dialogQuestionOpen: false })}}
                />
                {questionSetList!== undefined && this.state.currentContentId !== undefined &&
                    <div 
                        value={"questions_"+this.state.currentContentId } 
                        key={"questions_"+this.state.currentContentId } 
                        >
                        <Button onClick={() => this.addQuestionToSet(this.state.currentContentId)}>
                            Add Question
                        </Button>
                        {questionSetList.length>0 
                        &&
                            <AutoSizer>
                            {({ height, width }) => (
                                <Table
                                    width={width}
                                    height={700}
                                    headerHeight={20}
                                    rowHeight={30}
                                    rowCount={questionSetList.length}
                                    sort={this._sortQuestions}
                                    sortBy={this.state.sortBy}
                                    sortDirection={this.state.sortDirection}
                                    rowGetter={({index}) => questionSetList[index]}
                                    onRowClick={({ event, index, rowData })  => {
                                        this.setState({
                                            dialogQuestionOpen : true, currentQuestionId : questionSetList[index].id
                                        })
                                    }}
                                        >
                                    <Column label="Pos." dataKey="position" width={50} disableSort={false} />
                                    <Column label="Active" dataKey="active" width={100} disableSort={false}  />
                                    <Column label="Program" dataKey="category" width={130} disableSort={false} />
                                    <Column label="Question" dataKey="question" width={200} disableSort={false} />
                                    <Column label="Type" dataKey="type" width={130} disableSort={false} />
                                    <Column label="Variant" dataKey="variant" width={130} disableSort={false} />
                                    <Column label="Scale" dataKey="scale" width={130} disableSort={false}  />
                                    
                                </Table>
                            )}
                            </AutoSizer>
                        }
                    </div>
                }
            </div>
        );
    }

    addQuestionToSet(currentContentId){
        if(currentContentId!==undefined && currentContentId!==""){
            const timestamp = this.props.firebase.fieldValue.serverTimestamp();
            this.props.firebase.questionnaireQuestions().add({
                question: "NEW QUESTION",
                author: [this.state.author],
                questionnaires: [currentContentId],
                active: false,
                createdAt: timestamp
            })
            .then( (docRef) => {
                if(this._isMounted){
                    this.setState({
                        currentQuestionId : docRef.id,
                        dialogQuestionOpen : true
                    })
                }
            })
        }
    }
}


class QuestionnairesItemBaseDialog extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        var id = this.props.contentId;
        var errorMessage = '';
        var contentsLabels = ['tips, quotes'];
        var maxVote = 30;

        // delivered from db section pool
    
        contentsLabels = this.props.contentsLabels;
        maxVote = this.props.maxVote;
        
        //console.log('location',id)
        this.state = {
            loading: false,
            content: null,
            id: id,
            contentsLabels: contentsLabels,
            errorMessage: errorMessage,
            maxVote : maxVote,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.getleadershipCategoriesArray();
        this.getleadershipQualitiesArray();
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.unscrubscribe !== undefined ) this.unsubscribe();
    }

    componentDidUpdate() {
        //console.log('init', this.props.contentId)
        if (this.props.contentId && this.state.id !== this.props.contentId) {
            //console.log('myidopen', this.props.contentId)
            this.setState({ loading: true, id: this.props.contentId });
            this.unsubscribe = this.props.firebase
                .questionnaire(this.props.contentId)
                .onSnapshot(snapshot => {
                    if(this._isMounted ){
                        //console.log('data',snapshot.data())
                        this.setState({
                            content: snapshot.data(),
                            maxVote:  this.props.maxVote,
                            loading: false,
                        });
                    }//end ifmounted
                });
        }
    }

    getleadershipCategoriesArray()
    {
        var myArray = [];
        var myArrayText = [];
        this.props.firebase
        .leadershipCategories()
        .orderBy('text', 'asc')
        .where('active', '==', true)
        .get()
        .then(snapshot => {
            if (snapshot.empty) {
            return;
            }
            snapshot.forEach(doc => {
            if(this.context.programs.includes(doc.data().program)){
                myArray.push(doc.data().value);
                myArrayText.push(doc.data().text);
            }
            });
        });
        if(this._isMounted){
            this.setState({
                leadershipCategories: myArray,
                leadershipCategoriesText: myArrayText
            });
        }
    }
    getleadershipQualitiesArray()
    {
        var myArray = [];
        var myArrayText = [];
        this.props.firebase
        .leadershipQualities()
        .where('active', '==', true)
        .orderBy('category', 'asc')
        .orderBy('text', 'asc')
        .get()
        .then(snapshot => {
            if (snapshot.empty) {
            return;
            }
            snapshot.forEach(doc => {
            if(this.context.programs.includes(doc.data().program)){
                myArray.push(doc.data().value);
                myArrayText.push( "(" + doc.data().category + ") " + doc.data().text);
            };
            });
        });
        if(this._isMounted){
            this.setState({
                leadershipQualities: myArray,
                leadershipQualitiesText: myArrayText
            });
        }
         
    }

    render() {
        const { content, loading } = this.state;
        
         
        //console.log('my context', this.context)
        return (
            <Dialog
            open={this.props.openDialog}
            onClose={this.props.closeDialog}
            >
                <DialogTitle >
                    <IconButton onClick={this.props.closeDialog}>
                        (X)
                    </IconButton>
                    Modify Assessment 
                </DialogTitle>
                <DialogContent >
                    <div>

                    <Button onClick={() => { this.handleDuplicateContent( content )  }}>
                        Duplicate
                    </Button>
                    <h5 style={styles.subtitle}>Content ({this.props.contentId})</h5>
                    {loading && 
                        <LoadingProgress 
                            defaultSet={this.props.defaultSet}
                            type={'LoadingPageAnimated'} 
                            loadingText={"many, many, data"} 
                            firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                            allowRefresh={true}
                        />
                    }

                    {this.state.errorMessage !== '' && 
                        <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                    }
                    {content && (
                        <div>
                            <SettingContentActive 
                                dbUpdateLink={this.props.firebase.questionnaire(this.state.id)}
                                title={"Active and public Content"} 
                                content={content} 
                                labels={['Published in the app', 'Can be found by people outside the program', 'Requires premium subscription']}
                                params={['active', 'public', 'premium']}
                                />
                            <SettingContentBase 
                                dbUpdateLink={this.props.firebase.questionnaire(this.state.id)}
                                title={"Basic Text Settings"} 
                                titleFormat={true}
                                content={content}  
                                max={60}
                                params={['title', 'subtitle']}
                                />
                            <SettingContentSelect 
                                dbUpdateLink={this.props.firebase.questionnaire(this.state.id)}
                                title={"Content category (tips/quotes/...)"} 
                                content={content} 
                                params={['category']}
                                selectOptions={['OCEAN', 'PERMA-V', 'DISC', 'EGGUP', 'MBTI', 'CUSTOM']}
                                />
                            {content.category === 'OCEAN'
                                &&
                                <>
                                <p>Assigns internal scores for O. C. E. A. N.</p>
                                    {['O', 'C', 'E', 'A', 'N'].map(item => (
                                        <SettingContentMapList
                                            key={"PERMA_key_"+item}
                                            dbUpdateLink={this.props.firebase.questionnaire(this.state.id)}
                                            title={"Result Text "+item} 
                                            content={content} 
                                            objectSet={true}
                                            max = {90}
                                            labels={["Description PERMA-V"]}
                                            params={['resultTexts.'+item]}
                                            mapOptions={['text', 'description', 'lowText', 'highText','75','55', '50', '45', '25']}
                                            mapOptionsText={['a. Title', 'b. Description', 'c. Low Indicator', 'd. High Indicator',  'e. Top Insights','f. High Insights', 'g. Average Insights', 'h. Mediocre Insight', 'i. Low Insight']}
                                            />
                                        ))
                                    }
                                </>
                            }
                            {content.category === 'PERMA-V'
                                &&
                                <>
                                    <p>Assigns internal scores for pP. pE. pR. pM. pA. pV</p>
                                    {['pP', 'pE', 'pR', 'pM', 'pA', 'pV'].map(item => (
                                        <SettingContentMapList
                                            key={"PERMA_key_"+item}
                                            dbUpdateLink={this.props.firebase.questionnaire(this.state.id)}
                                            title={"Result Text "+item} 
                                            content={content} 
                                            objectSet={true}
                                            max = {220}
                                            labels={["Description PERMA-V"]}
                                            params={['resultTexts.'+item]}
                                            mapOptions={['text', 'description', 'lowText', 'highText','75','55', '50', '45', '25']}
                                            mapOptionsText={['a. Title', 'b. Description', 'c. Low Indicator', 'd. High Indicator',  'e. Top Insights','f. High Insights', 'g. Average Insights', 'h. Mediocre Insight', 'i. Low Insight']}
                                            />
                                        ))
                                    }
                                </>
                            }
                            {content.category === 'CUSTOM'
                                &&
                                <>
                                    <SettingContentMapList
                                        key={"CUSTOM_key_TEMPLATE"}
                                        dbUpdateLink={this.props.firebase.questionnaire(this.state.id)}
                                        title={"Custom keys for program: "+content.program} 
                                        content={content} 
                                        objectSet={true}
                                        max = {3}
                                        templateParam = {'code'}
                                        labels={["Add 3 character codes per key"]}
                                        params={['custom.'+content.program+'_TEMPLATE']}
                                        mapOptions={['code']}
                                        mapOptionsText={['3 Character code']}
                                        />
                                    <p>Assigns internal scores for Custom keys</p>
                                    {content.custom !== undefined && Object.keys(content.custom).map(item => (
                                        <SettingContentMapList
                                            key={"CUSTOM_key_"+item}
                                            dbUpdateLink={this.props.firebase.questionnaire(this.state.id)}
                                            title={"Result Text "+item} 
                                            content={content} 
                                            objectSet={true}
                                            max = {220}
                                            labels={["Description Assessment Key: " + item]}
                                            params={['resultTexts.'+item]}
                                            mapOptions={['text', 'description', 'lowText', 'highText','75','55', '50', '45', '25']}
                                            mapOptionsText={['a. Title', 'b. Description', 'c. Low Indicator', 'd. High Indicator',  'e. Top Insights','f. High Insights', 'g. Average Insights', 'h. Mediocre Insight', 'i. Low Insight']}
                                            />
                                        ))
                                    }
                                </>
                            }
                            <SettingContentSelect 
                                dbUpdateLink={this.props.firebase.questionnaire(this.state.id)}
                                title={"Leadership program (priority/balance/...)"} 
                                content={content} 
                                params={['leadership_category']}
                                selectOptions={['default'].concat(this.state.leadershipCategories || [])}
                                selectOptionsText={['Any Program'].concat(this.state.leadershipCategoriesText || [])}
                                />
                            <SettingContentArraySelect
                                dbUpdateLink={this.props.firebase.questionnaire(this.state.id)}
                                title={"Assigned dev. areas / skills / qualities"} 
                                content={content} 
                                params={['leadership_quality']}
                                selectOptions={this.state.leadershipQualities}
                                selectOptionsText={this.state.leadershipQualitiesText}
                                />
                            <SettingContentSelect 
                                dbUpdateLink={this.props.firebase.questionnaire(this.state.id)}
                                title={"Original program"} 
                                content={content} 
                                params={['program']}
                                selectOptions={this.context.programs}
                                />
                            <SettingContentMapSelect
                                dbUpdateLink={this.props.firebase.questionnaire(this.state.id)}
                                title={"PERMA traits outcome."} 
                                content={content} 
                                labels={["PERMA-V"]}
                                params={['personalityTraitsTarget']}
                                mapOptions={['O','C', 'E', 'A', 'N', 'pP', 'pE', 'pR', 'pM', 'pA', 'pV']}
                                mapOptionsText={['Openness','Consciousness', 'Extroversion', 'Agreeableness','Neuroticism',
                                    'Positive Emotions', 'Engage', 'Relations.', 'Meaning', 'Accompl.', 'Life-Balance' ]}
                                selectOptions={[10, 25, 50, 75, 90]}
                                selectOptionsText={['none', 'some','medium', 'high', 'perfect']}
                                />
                            <hr/>
                            Design
                            <SettingContentUploadImage
                                dbUpdateLink={this.props.firebase.questionnaire(this.state.id)}
                                title={"Icon for Assessment"} 
                                previewImageSize={true}
                                content={content} 
                                params={['icon']}
                                uploadDatabase={this.props.firebase.storageImages('content/'+this.state.id)}
                                />
                            <SettingContentUploadImage
                                dbUpdateLink={this.props.firebase.questionnaire(this.state.id)}
                                title={"Background Image"} 
                                previewImageSize={true}
                                content={content} 
                                params={['url']}
                                uploadDatabase={this.props.firebase.storageImages('content/'+this.state.id)}
                                />
                            <SettingColorPicker
                                dbUpdateLink={this.props.firebase.questionnaire(this.state.cid)}
                                title={"Color Theme"} 
                                content={content} 
                                noLabel={true}
                                hexColorFormat={true}
                                params={['backgroundColor']}
                            />
                            <hr/>
                            <SettingContentRichText
                                dbUpdateLink={this.props.firebase.questionnaire(this.state.id)}
                                title={"Edit Richt Text (external tool)"} 
                                content={content} 
                                params={['text']}
                                />
                            <SettingContentBase 
                                dbUpdateLink={this.props.firebase.questionnaire(this.state.id)}
                                title={"Sources and external link"} 
                                content={content} 
                                params={['source', 'sourceUrl']}
                                />
                            {content.utteranceDescriptions !== undefined && content.utteranceDescriptions.utter !== undefined && content.utteranceDescriptions.utter.length > 1
                                ?
                                    <SettingContentArrayCheckbox
                                        dbUpdateLink={this.props.firebase.questionnaire(this.state.id)}
                                        title={"Add AI labels"} 
                                        content={content}
                                        noLabel={true}
                                        params={['utteranceClass']}
                                        groupFilter={['balance', 'self', 'mindset', 'learning', 'productivity', 'work', 'career', 'success', 'business', 'people', 'communication']}
                                        selectOptions={content.utteranceDescriptions.utter}
                                        selectOptionsText={content.utteranceDescriptions.response}
                                        />
                                :
                                    <SettingContentArray
                                        dbUpdateLink={this.props.firebase.questionnaire(this.state.id)}
                                        title={"Manage AI labels"} 
                                        content={content}
                                        noLabel={true}
                                        params={['utteranceClass']}
                                        />
                            }
                            <hr/>
                            <SettingContentArray 
                                dbUpdateLink={this.props.firebase.questionnaire(this.state.id)}
                                title={"Internal author"} 
                                content={content} 
                                params={['author']}
                                />

                        </div>
                    )}
                    </div>
                </DialogContent>
                {
                    /***

                                
                            <Button onClick={() => this.handleOpenDialogueKeyWords()}>
                            Manage Keywords
                            </Button>
                            <Button onClick={() => this.handleOpenDialogueQuestions()}>
                            Manage Questions
                            </Button>
                    this.dialogueKeyWords()
                }
                {
                    this.dialogueQuestions()

                     */
                }
            </Dialog> 
        );
    }


    handleCloseDialogueKeyWords = () => {
        this.setState({openDialogueKeyWords: false} )
    }
    handleOpenDialogueKeyWords = () => {
        this.props.firebase.questionnaire(this.state.id + "/postprocess/searchKeyWords" ).get()
        .then((doc) => {
            var postprocessData = { triggerStartSearch : false}
            if(doc){
                if(doc.data() !== undefined && doc.data().triggerStartSearch !== undefined){
                    postprocessData = doc.data();
                }
            }
            //console.log(('processdata'), postprocessData)
            if(this._isMounted){
                this.setState({postprocessData} );
            }
        })
        this.props.firebase.questionnaire(this.state.id + "/keyWords/tags" ).get()
        .then((doc) => {
            var identifiedTags = []
            if(doc){
                if(doc.data() !== undefined && doc.data().tags !== undefined){
                    identifiedTags = doc.data().tags;
                }
            }
            if(this._isMounted){
                this.setState({identifiedTags} );
            }
        })

        var activeTags = [];
        var activeTagsObjects = {};
        if(this.state.content !== undefined && this.state.content !== null && this.state.content.tags !== undefined ){
            activeTags = [...this.state.content.tags];
            //activeTagsObject = Object.assign(...activeTags.map(k => ({ ['tags_'+k.replace(/ /g,'')] : true })));
            activeTagsObjects =  Object.assign.apply(null, this.state.content.tags.map(k =>({['tags_'+k.replace(/ /g,'')]:true})));
        }
        if(this._isMounted){
            this.setState({openDialogueKeyWords: true, activeTags, activeTagsObjects: activeTagsObjects} )
        }

        if(this.state.content !== undefined && this.state.content.leadership_quality !== undefined ){
            this.props.firebase.questionnaires()
            .where('leadership_quality', 'array-contains-any', (this.state.content.leadership_quality || []))
            .get()
            .then((snapshot) => {
                if(snapshot){
                    var otherTags = [];
                    snapshot.forEach(doc => {
                        var tempTags = doc.data().tags;
                        //console.log("tempTags", tempTags)
                        if( tempTags !== undefined && tempTags.length>1 ){

                            //console.log("other tags", otherTags)
                            otherTags = _.union(otherTags, tempTags)
                        }
                    })
                    if(this._isMounted){
                        //console.log("other tags", otherTags)
                        this.setState({otherTags} );
                    }
                }
            })
        }
        //console.log('activtags', this.state)
    }
    handleCloseDialogueQuestions = () => {
        this.unsubscribeQuestions()
        this.setState({openDialogueQuestions: false} )
    }
    handleOpenDialogueQuestions = () => {
        var questionsGenerated = [{questions:[], questionsFinal:[]}]
        this.unsubscribeQuestions = this.props.firebase.questionnaire(this.state.id + "/keyWords/questions" )
        .onSnapshot(doc => {
        //.get().then((doc) => {
            if(doc){
                questionsGenerated = doc.data()
            }
            if(this._isMounted){
                this.setState({openDialogueQuestions: true, questionsGenerated} );
            }
        })
    }

    handleDuplicateContent = (content) => {
        if(content!== undefined ){
            var newContent = {...content};
            newContent['status'] = 'inactive';
            newContent['title'] = 'COPY: ' + content.title;
            newContent['tags'] = [];
            this.props.firebase.questionnaires().add(newContent);
            this.setState({errorMessage : 'Copied content without tags and status inactive'})
        }
        else{
            this.setState({errorMessage : 'Original data missing for a copy'})
        }
    }

    dialogueKeyWords = () => {
        return( 
            <RemoveScroll enabled={this.state.openDialogueKeyWords || false}>
                <Dialog
                    open={this.state.openDialogueKeyWords || false}
                    onClose={this.handleCloseDialogueKeyWords}
                    fullWidth={true}
                >
                    <DialogTitle  >
                        <IconButton onClick={this.handleCloseDialogueKeyWords}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span>
                            Key Word Management
                        </span>
                    </DialogTitle>
                    <DialogContent>
                        {this.state.openDialogueKeyWords
                            ?
                                <div>
                                    {this.state.activeTags!==undefined && Array.isArray(this.state.activeTags) && 
                                        <Button onClick={() => this.saveUpdatedKeyWords()}>
                                            Save Updated Keywords
                                        </Button>
                                    }
                                    {this.state.postprocessData!==undefined && 
                                        <SettingContentActive 
                                            setUpdate = {true}
                                            dbUpdateLink={this.props.firebase.questionnaire(this.state.id + "/postprocess/searchKeyWords" )}
                                            title={"Trigger Key Word generator "} 
                                            content={this.state.postprocessData} 
                                            labels={['Change from Off -> On']}
                                            params={['triggerStartSearch']}
                                            />
                                    }
                                    {this.state.content !== undefined && this.state.content !== null && this.state.content.tags !== undefined &&
                                        <Accordion TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-label="Account-Expand"
                                                aria-controls="Account-content"
                                                id="Account-header"
                                                >
                                                Current Active
                                            </AccordionSummary>
                                            <AccordionDetails> 
                                                <div style={{border:1}}>
                                                    <p>Active in Content</p>
                                                    {this.state.content.tags.map ( (currentTag, index) => (
                                                        <FormControlLabel
                                                            key={"currentTags_"+index}
                                                            id={"currentTags_"+index}
                                                            control={<Checkbox
                                                                onClick={(event) => this.updateActiveTags(currentTag, event.target.checked) }
                                                                checked = {
                                                                    this.state.activeTagsObjects !== undefined &&  this.state.activeTagsObjects['tags_'+currentTag.replace(/ /g,'')] 
                                                                        ? true
                                                                        : false
                                                                }
                                                                //checked={ 
                                                                //    this.state.activeTags!==undefined && Array.isArray(this.state.activeTags) && this.state.activeTags.includes(currentTag)
                                                                //        ? true
                                                                //        : false
                                                                //} 
                                                                name={"currentTags_"+index} />}
                                                            label={currentTag}
                                                        />
                                                    ))}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion> 
                                    }
                                    {this.state.identifiedTags !== undefined && this.state.identifiedTags !== null &&
                                        <Accordion TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-label="Account-Expand"
                                                aria-controls="Account-content"
                                                id="Account-header"
                                                >
                                                Found tags in text
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div style={{border:1}}>
                                                    <p>Found tags in text</p>
                                                    {this.state.identifiedTags.map ( (possibleTag, index) => (
                                                        <FormControlLabel
                                                            key={"possibleTag_"+index}
                                                            id={"possibleTag_"+index}
                                                            control={<Checkbox
                                                                onClick={(event) => this.updateActiveTags(possibleTag, event.target.checked) }
                                                                checked = {
                                                                    this.state.activeTagsObjects !== undefined &&  this.state.activeTagsObjects['tags_'+possibleTag.replace(/ /g,'')] 
                                                                        ? true
                                                                        : false
                                                                }
                                                                //checked={ 
                                                                //    this.state.activeTags!==undefined && Array.isArray(this.state.activeTags) && this.state.activeTags.includes(possibleTag)
                                                                //        ? true
                                                                //        : false
                                                                //} 
                                                                name={"possibleTag_"+index} />}
                                                            label={possibleTag}
                                                        />
                                                    ))}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>  
                                    }
                                    {this.state.otherTags !== undefined && this.state.otherTags !== null &&
                                        <Accordion TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-label="otherqualities"
                                                aria-controls="otherqualities"
                                                id="otherqualities"
                                                >
                                                From other qualities
                                            </AccordionSummary>
                                            <AccordionDetails>  
                                                <div style={{border:1}}>
                                                    <p>Found in other modules</p>
                                                    {this.state.otherTags.map ( (alternativeTag, index) => (
                                                        <FormControlLabel
                                                            key={"alternativeTag_"+index}
                                                            id={"alternativeTag_"+index}
                                                            control={<Checkbox
                                                                checked = {
                                                                    this.state.activeTagsObjects !== undefined &&  this.state.activeTagsObjects['tags_'+alternativeTag.replace(/ /g,'')] 
                                                                        ? true
                                                                        : false
                                                                }
                                                                onClick={(event) => this.updateActiveTags(alternativeTag, true) }
                                                                name={"alternativeTag_"+index} />}
                                                            label={alternativeTag}
                                                        />
                                                    ))}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    }
                                </div>
                            :
                                ""
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }
    dialogueQuestions = () => {
        return(
            <RemoveScroll enabled={this.state.openDialogueQuestions || false}>
                <Dialog
                    open={this.state.openDialogueQuestions || false}
                    onClose={this.handleCloseDialogueQuestions}
                    fullScreen={true}
                    style={{height:'100%'}}
                >
                    <DialogTitle  >
                        <IconButton onClick={this.handleCloseDialogueQuestions}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span>
                            Question Management
                        </span>
                    </DialogTitle>
                    <DialogContent>
                        {this.state.openDialogueQuestions
                            ?
                                <div style={{height:'100%'}}>
                                    <SettingContentArray
                                        dbUpdateLink={this.props.firebase.questionnaire(this.state.id + "/keyWords/questions" )}
                                        title={"Created Questions"} 
                                        content={this.state.questionsGenerated} 
                                        params={['questionsFinal']}
                                        noLabel={true}
                                        />
                                    {this.state.questionsGenerated !== undefined && this.state.questionsGenerated.questions !== undefined &&
                                        <Accordion TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-label="autoquestions"
                                                aria-controls="autoquestions"
                                                id="autoquestions"
                                                >
                                                Auto generated questions
                                            </AccordionSummary>
                                            <AccordionDetails>  
                                                <div style={{border:1}}>
                                                    {this.state.questionsGenerated.questions.map ( (q, index) => (
                                                        <span
                                                            key={"alternativeTag_"+index}
                                                            id={"alternativeTag_"+index}
                                                        >
                                                            {q}
                                                            &nbsp;
                                                            <Button onClick={() => {
                                                                var qs = this.state.questionsGenerated.questionsFinal;
                                                                qs.push(q);
                                                                this.setState({questionsGenerated: {
                                                                    questionsFinal: qs,
                                                                    questions: this.state.questionsGenerated.questions}
                                                                }) }} >
                                                            +</Button><br/>
                                                        </span>
                                                    ))}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    }
                                    {/***
                                    <SettingContentArray
                                        dbUpdateLink={this.props.firebase.questionnaire(this.state.id + "/keyWords/questions" )}
                                        title={"Auto questions"} 
                                        content={this.state.questionsGenerated} 
                                        params={['questions']}
                                        noLabel={true}
                                        />
                                         */}
                                </div>
                            :
                                ""
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }

        // Default sizes help Masonry decide how many images to batch-measure
    cache = new CellMeasurerCache({
        defaultHeight: 250,
        defaultWidth: 200,
        fixedWidth: true,
    });
    
    // Our masonry layout will use 3 columns with a 10px gutter between
    cellPositioner = createMasonryCellPositioner({
        cellMeasurerCache: this.cache,
        columnCount: 3,
        columnWidth: 200,
        spacer: 10,
    });

    renderRowCheckbox = ({ index, key, parent, style}) => {
        var alternativeTag = this.state.otherTags[index]
        return(
            <CellMeasurer cache={this.cache} index={index} key={key} parent={parent}>
            <FormControlLabel
                key={key+"_"+index}
                id={key+"_"+index}
                control={<Checkbox
                    checked = {
                        this.state.activeTagsObjects !== undefined &&  this.state.activeTagsObjects['tags_'+alternativeTag.replace(/ /g,'')] 
                            ? true
                            : false
                    }
                    onClick={(event) => this.updateActiveTags(alternativeTag, true) }
                    name={key+"_"+index} />}
                label={alternativeTag}
            />
            </CellMeasurer>
        )
    }

    updateActiveTags = (tag, activate) => {
        if (this._isMounted && this.state.activeTags!==undefined && Array.isArray(this.state.activeTags)) {
            //console.log('before',activate,  tag,  this.state.activeTags)
            var tagUpdate = this.state.activeTags
            if (activate){
                //tagUpdate.push(tag)
                tagUpdate = _.union(tagUpdate, [tag])
                this.setState({ activeTagsObjects : {...this.state.activeTagsObjects, ['tags_'+tag.replace(/ /g,'')] : true} })
            }
            else{

                this.setState({ activeTagsObjects : {...this.state.activeTagsObjects, ['tags_'+tag.replace(/ /g,'')] : false} })
                var index = tagUpdate.indexOf(tag);
                tagUpdate.splice(index, 1);
                tagUpdate = tagUpdate.filter((_, i) => i !== tag)
            }
            //console.log('after', tag,  this.state.activeTags)
            this.setState({activeTags : tagUpdate})
            //console.log('activtagsafter update', this.state)
        }
    }

    saveUpdatedKeyWords = () => {
        if (this.state.activeTags!==undefined && Array.isArray(this.state.activeTags) && this.state.activeTags.length>1) {
            this.props.firebase.questionnaire(this.state.id ).update({tags : this.state.activeTags })
            .then( () => {
                this.handleCloseDialogueKeyWords()
            })
        }
    }
}

AdminQuestionnairesPage.propTypes = {
    classes: PropTypes.object.isRequired
}

const QuestionnairesList = withFirebase(QuestionnairesListBase);
export const QuestionnairesItemDialog = withFirebase(QuestionnairesItemBaseDialog);

const condition = authUser =>
    authUser && authUser.roles?.includes(ROLES.ADMIN);

export default compose(
    withStyles(styles),
    // withEmailVerification,
    withAuthorization(condition),
)(AdminQuestionnairesPage);


