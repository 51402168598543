import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import PropTypes from 'prop-types';


import MapRender from '../Days/MapRender';


const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});



class MapTasks extends Component {
    static contextType = AuthUserContext;
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            activeStep : 0,
            loading: false,
            loadingText: "loading journey"
        };
    }

    componentDidMount() {

    }
    shouldComponentUpdate(nextProps, nextState) {  
        if (nextProps.rerender === false) {  
            //console.log('no rerender')
            return false;  
        }  
        //console.log(' rerender')
        return true;  
    }

  
    render() {
        return (
            <div style={{width:"100%", marginBottom:30}}>
                <MapRender 
                    user={this.props.user} 
                    leadershipCategories={this.props.leadershipCategories}
                    leadershipQualities={this.props.leadershipQualities}
                    renderMapType={'Tasks'}//one word
                    filterTrueCoachUtter={['prio','activit', 'task', 'improvement', 'progress', 'accomplis', 'help', 'preparation']}//string search
                    filterTrueIntentCoach={['prio','activit', 'task', 'improvement', 'progress', 'accomplis', 'help', 'preparation']}//string search
                    filterTrueIntentUser={['prio','activit', 'task', 'improvement', 'progress', 'accomplis', 'preparation']}//string search
                    filterFalseIntentUser={['mindset','goal', 'sentiment', 'skill', 'qualit']}//string search
                    filterTrueEntityCoach={['activity','task']}//need exact match
                    filterTrueEntityUser={['activity','task', 'help', 'remind']}//need exact match
                />

                
                <div  style={{marginTop: 30 }}>
                    {/***
                    <Accordion style={{boxShadow:'none'}}
                            onChange={(e,expanded) => {this.setState({additional_note_expanded: expanded})}}>
                        <AccordionSummary
                            expandIcon={this.state.additional_note_expanded? <CancelIcon /> : <AddCircleOutlineIcon />}
                            aria-label="About-Expand"
                            aria-controls="About-content"
                            id="About-header"
                            >
                            Add additional note
                        </AccordionSummary>
                        <AccordionDetails  style={{padding:0}} >        
                            <DiaryManual />
                        </AccordionDetails>
                    </Accordion>
                    */}
                </div>
            </div> 
        );
    }
  
}

MapTasks.propTypes = {
    classes: PropTypes.object.isRequired,
};
  

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(MapTasks);
