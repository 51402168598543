//import palette from '../palette';

function muiStyle (palette) { return {
    root: {
        color: palette.secondary.main,
        padding: 6,
        marginLeft: 6,
        marginRight: 6,
        '&:hover': {
            backgroundColor: palette.secondary.light + " !important",
            color: palette.white + " !important",
        }
    }
}}

export default muiStyle;