import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import LoadingProgress from '../../Modules/LoadingProgress';

import { withFirebase } from '../../Firebase';
import { withAuthorization,
	 // withEmailVerification
       } from '../../Session';
import { AuthUserContext } from '../../Session';
import * as ROLES from '../../../constants/roles';
import ManageQuestions from '../../Coach/ManageQuestions';
import ManageContent from '../../Coach/ManageContent';
import AdminTrainingPage from '../../Admin/Training/AdminTrainingPage';
import {DescriptionSentiment} from '../../Admin/Training/TrainingDataManagement';


import {ConvertTimestampToDateString} from '../../Modules/ConvertTimestampToDateString';

// MUI stuff
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import Table from '@material-ui/core/Table';
//import TableCell from '@material-ui/core/TableCell';
//import TableHead from '@material-ui/core/TableHead';
//import TableRow from '@material-ui/core/TableRow';
//import TableBody from '@material-ui/core/TableBody';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
//import ButtonSwitch from '@material-ui/core/Switch';
import {Column, Table, SortDirection} from 'react-virtualized';
import {  AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css'
import _ from "lodash";

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// Icons
import AddIcon from '@material-ui/icons/Add';

// Modules for parameter change
import SettingContentBase from '../Modules/SettingContentBase';
import SettingContentActive from '../Modules/SettingContentActive';
//import SettingContentNumber from '../Modules/SettingContentNumber';
import SettingContentArray from '../Modules/SettingContentArray';
//import SettingContentImage from '../Modules/SettingContentImage';
//import SettingContentUploadImage from '../Modules/SettingContentUploadImage';
//import SettingContentRichText from '../Modules/SettingContentRichText';
import SettingContentArraySelect from '../Modules/SettingContentArraySelect';
import SettingContentSelect from '../Modules/SettingContentSelect';
import IntentArrayManagement from '../Modules/IntentArrayManagement';
import SettingContentArrayCheckbox from '../Modules/SettingContentArrayCheckbox';

const styles = {
    table: {
        minWidth: 100,
    },
    subtitle: {
        marginTop: 20,
        fontWeight: 700,
    },
    addButton: {
        marginTop: 20,
    },
};


const responseIntentOptions=['utter_inform_advice', 'utter_compliment_accomplishments', 'utter_compliment_short', 'utter_affirm_challenge', 'utter_affirm_disappointment' , 'utter_affirm_indicator', 'utter_affirm_rapport_extended', 'utter_affirm_activity', 'utter_affirm_intention', 'utter_affirm_assessment']
const responseIntentOptionsText=['Answer Question/Tips', 'Congratulate on achievements', 'Compliment on positivity', 'Understand challenge', 'Empahty on failure/dissapointemnt' , 'Short indicator', 'Provide extra conclusion', 'Understand activity', 'Understand goal/intention', 'utter_affirm_assessment']

class AdminQuestionIntentsPage extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        const sortBy = 'active';
        const sortDirection = SortDirection.ASC;

        this.state = {
            loading: false,
            contents: [],
            contentsTab: [],
            contentsLabels: [],
            title: '',
            author: '',
            program: '',
            intent: '',
            open: false,
            sortBy,
            sortDirection,
            descriptionSentiment : DescriptionSentiment,
            runningAccTestIndex: 0
        };
        //this.sortState = createMultiSort(this.sort);
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            loading: true,
            open: false, 
            title: '',
            author: '',
            program: '',
            showTab: '',
            errorMessage : '',
            moveInaccurateItems: true
        });

        var name = '';

        //this.unsubscribe = 
        this.props.firebase.agentIntentClasses()
            .orderBy('description', 'asc')
            .get().then(snapshot => {
        //.onSnapshot(snapshot => {
            let contentsTab = [];
            let contentsTabList = [];
            let contentsLabels = [];

            var keySorting = 'sentiment'
            var content = {}
            
            var accuracyString = '';
            var accuracyUpdated = '';
            snapshot.forEach(doc =>{
                if((this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM') ) && this._isMounted){
                    if(this.context.programs?.includes(doc.data().program) || doc.data().program===undefined){
                        name = doc.data()[keySorting];
                        content = doc.data();
                        if(name === undefined){
                            name = '---undefined---'
                        }
                        if(!(name in contentsTab)){
                            //contentsTab[doc.data().category]={name:doc.data().category, contents: []};
                            contentsTab[name] = [];
                            contentsTabList[name]= [];
                            contentsLabels.push(name );
                        }
                        var examplePresent = content.examples!==undefined? content.examples.length : 0;
                        var examplePast = content.examplesPast!==undefined? content.examplesPast.length : 0 ;
                        var exampleFuture = content.examplesFuture!==undefined? content.examplesFuture.length : 0;
                        accuracyString = '';
                        accuracyUpdated = '';
                        if(content.accuracy !== undefined){
                            if(content.accuracy.examples !== undefined && !isNaN(content.accuracy.examples)){
                                accuracyString = content.accuracy.examples*100;
                            }
                            if(content.accuracy.examplesPast !== undefined && !isNaN(content.accuracy.examplesPast)){
                                accuracyString = accuracyString + '/' + content.accuracy.examplesPast*100;
                            }
                            if(content.accuracy.examplesFuture !== undefined && !isNaN(content.accuracy.examplesFuture)){
                                accuracyString = accuracyString + '/' + content.accuracy.examplesFuture*100;
                            }
                            if(content.accuracy.updatedAt !== undefined){
                                accuracyUpdated = ConvertTimestampToDateString(content.accuracy.updatedAt);
                            }
                        }
                        contentsTab[name].push({ ...doc.data(), uid: doc.id });
                        contentsTabList[name].push({
                            active: content.active? 'Yes': 'No',
                            description : content.description || '-',
                            responses:  content.responses!==undefined?  content.responses[0] : '-',
                            examples:  examplePresent + '/' + examplePast+  '/' +exampleFuture,
                            clean: content.clean ? 'Yes' : '-',
                            lowItems: ((content.examplesLow?.length || 0)) > 0 ? ((content.examplesLow?.length || 0)) : '',
                            badItems: ((content.examplesFalse?.length || 0)) > 0 ? ((content.examplesFalse?.length || 0)) : '',
                            autoItems: ((content.examplesAuto?.length || 0)) > 0 ? ((content.examplesAuto?.length || 0)) : '',
                            accuracy: content.accuracy !== undefined ? accuracyString : '-',
                            accuracyDate: content.accuracy?.updatedAt?.seconds ? content.accuracy.updatedAt : undefined,
                            accuracyUpdated: content.accuracy !== undefined ? accuracyUpdated : '-',
                            intentText: content.intentText?.length>1 ? 'Y' : '-',
                            qualities: content.qualities?.length>0 ? content.qualities : "n/a",
                            tenses:  content.tenses!==undefined?  content.tenses.join(',') : '-',
                            class: content.class || '-',
                            lookup: (content.lookup  )? content.entity : '-',
                            synonym: (content.synonymActive )? content.synonym : '-',
                            uid: doc.id ,
                        });
                    } // end if true
                } // end of if ADMIN
                else if(this.context.roles?.includes('COACHADMIN') && this._isMounted ){
                    //console.log('coachadmin')
                    content = doc.data();
                    var checkFlag = false;
                    name = doc.data()[keySorting];
                    if(content.intentTags !== undefined && this.props.filterIntents!== undefined && this.props.filterIntents.length>0){
                        this.props.filterIntents.map(tag => {
                            if(content.intentTags[tag]){
                                checkFlag = true;
                            }
                            return true;
                        })
                    }
                    if(checkFlag){
                        name = content[keySorting];
                        if(name === undefined){
                            name = '---undefined---'
                        }
                        if(!(name in contentsTab)){
                            //contentsTab[doc.data().category]={name:doc.data().category, contents: []};
                            contentsTab[name] = [];
                            contentsTabList[name] = [];
                            contentsLabels.push(name );
                        }
                        contentsTab[name].push({ ...doc.data(), uid: doc.id });
                        accuracyString = '';
                        accuracyUpdated = '';
                        if(content.accuracy !== undefined){
                            if(content.accuracy.examples !== undefined && !isNaN(content.accuracy.examples)){
                                accuracyString = content.accuracy.examples*100;
                            }
                            if(content.accuracy.examplesPast !== undefined && !isNaN(content.accuracy.examplesPast)){
                                accuracyString = accuracyString + '/' + content.accuracy.examplesPast*100;
                            }
                            if(content.accuracy.examplesFuture !== undefined && !isNaN(content.accuracy.examplesFuture)){
                                accuracyString = accuracyString + '/' + content.accuracy.examplesFuture*100;
                            }
                            if(content.accuracy.updatedAt !== undefined){
                                accuracyUpdated = ConvertTimestampToDateString(content.accuracy.updatedAt);
                            }
                        }
                        contentsTabList[name].push({
                            active: content.active? 'Yes': 'No',
                            description : content.description || '-',
                            responses:  content.responses!==undefined?  content.responses[0] : '-',
                            examples:  content.examples!==undefined? content.examples.length : 0,
                            accuracy: content.accuracy !== undefined ? accuracyString : '-',
                            accuracyUpdated: content.accuracy !== undefined ? accuracyUpdated : '-',
                            intentText: content.intentText?.length>1 ? 'Y' : '-',
                            qualities: content.qualities?.length>0 ? content.qualities : "n/a",
                            tenses:  content.tenses!==undefined?  content.tenses.join(',') : '-',
                            class: content.class || '-',
                            lookup: (content.lookup )? content.entity : '-',
                            synonym: (content.synonymActive )? content.synonym : '-',
                            uid: doc.id ,
                        });
                    }//end if program / no default
                } // end of if ADMIN
            });//end of snapshot


            //console.log('contents', contentsTabList)
            if(this.state.showTab === undefined || this.state.showTab === ''){
                this.setState({showTab: name})
            }
            if(this._isMounted){
                this.setState({
                    contentsTab: contentsTab,
                    contentsTabList,
                    contentsLabels,
                    loading: false,
                });
            }
            //console.log('total',contentsTabList['total'][0])
        });
        this.getleadershipQualitiesArray();
        this.getleadershipCategoriesArray();
    }

    componentWillUnmount() {
        this._isMounted = false;
        if(this.unsubscribe !== undefined) this.unsubscribe();
    }

    getleadershipCategoriesArray() {
        var myArray = [];
        var myArrayText = [];
        this.props.firebase
        .leadershipCategories()
        .orderBy('text', 'asc')
        .where('active', '==', true)
        .get()
        .then(snapshot => {
            if (snapshot.empty) {
            return;
            }
            snapshot.forEach(doc => {
            if(this.context.programs.includes(doc.data().program)){
                myArray.push(doc.data().value);
                myArrayText.push(doc.data().text);
            }
            });
        });
        if(this._isMounted){
            //console.log(myArray, myArrayText)
            this.setState({
                leadershipCategoriesValues: myArray,
                leadershipCategoriesText: myArrayText
            });
        }
    }
    getleadershipQualitiesArray(){
        var myArray = [];
        var myArrayText = [];
        this.props.firebase
        .leadershipQualities()
        .where('active', '==', true)
        .orderBy('category', 'asc')
        .orderBy('text', 'asc')
        .get()
        .then(snapshot => {
            if (snapshot.empty) {
            return;
            }
            snapshot.forEach(doc => {
            if(this.context.programs.includes(doc.data().program)){
                myArray.push(doc.data().value);
                myArrayText.push( "(" + doc.data().category + ") " + doc.data().text);
            };
            });
        });
        if(this._isMounted){
            this.setState({
                leadershipQualitiesValues: myArray,
                leadershipQualitiesText: myArrayText
            });
        }
         
    }

    _sortList = ({sortBy, sortDirection}) => {
        const list = this.state.contentsTabList[this.state.showTab];
        //console.log('sortingbefore', sortBy ,sortDirection)
        let newList = _.sortBy(list, [sortBy]);
        if (sortDirection === SortDirection.DESC) {
            newList.reverse();
        }
        //console.log('sorting',newList, this.state.showTab )
        return newList;
    }

    _compareFilter = (a,b) => {
        if ( a.class.includes(this.state.classFilter) ){
            //console.log('filtering', a, b)
            return -1;
        }
        else{
            return 1;
        }
    }
    _sortListByFilter = ( () => {
        console.log('filter')
        const list = this.state.contentsTabList[this.state.showTab];
        let newList = list.sort(this._compareFilter);
        return newList;
    })

    _sort = ({sortBy, sortDirection}) => {
        const sortedList = this.state.contentsTabList;
        if(this.state.classFilter !== undefined && this.state.classFilter !== ''){
            sortedList[this.state.showTab] = this._sortListByFilter();
        }
        else{
            sortedList[this.state.showTab] = this._sortList({sortBy, sortDirection});
        }
        this.setState({sortBy, sortDirection, contentsTabList: sortedList});
    }

    handleOpen = () => {
        //console.log('handleOpen');
        this.setState({ open: true });
    };

    handleClose = () => {
        //console.log('handleClose');
        if(this.props.handelStopAccuracyTest !== undefined){
            this.props.handelStopAccuracyTest();
        }
        this.setState({ open: false });
    };

    handleChange = (event) => {
        //console.log([event.target.name] + ": " + event.target.value);
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = () => {
        if(this.state.title !== '' && this.state.author !== '' && this.state.program !== '' && this.state.intent !== '')
        {
            const timestamp = this.props.firebase.fieldValue.serverTimestamp();
            this.props.firebase.agentIntentClasses().add({
                description: this.state.title,
                author: [this.state.author],
                sentiment: this.state.program,
                intent: this.state.intent,
                class: this.state.intent,
                active: false,
                tenses: ["default", "future", "past"],
                createdAt: timestamp
            });
            this.setState({errorMessage : 'Added content (sentiment ' + this.state.program + ')'})
        }
        else{
            this.setState({errorMessage : 'All  values have to be defined to add a content'})
        }
        this.setState({
            title: '',
            author: '',
            program: '',
        });
        this.handleClose();
    }

    handleTabChange = (event, newValue) => {
        //console.log('new tab', newValue)
        this.setState({
            showTab: newValue,
            runningAccTestIndex: 0
        });
    }

    render() {
        const { showTab, contentsTab, contentsLabels, loading } = this.state;
        //console.log('votes', maxVotes)
        return (
            <div style={{height: '100vh', backgroundColor: '#DDDDDD'}}>
                {loading && 
                    <LoadingProgress 
                        defaultSet={this.props.defaultSet}
                        type={'LoadingPageAnimated'} 
                        loadingText={"many, many, data"} 
                        firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                        allowRefresh={true}
                    />
                }
                <TextField
                    name="classFilter"
                    style={ {fontSize: '16px',  position: 'fixed', right:40} }
                    type="text"
                    label="classFilter"
                    placeholder="balance"
                    value={this.state.classFilter || ''}
                    onChange={(event) => this.setState({ classFilter : event.target.value})}
                />
                {this.context.roles?.includes("ADMIN") && this.state.contentsLabels!==undefined && this.state.contentsLabels.length>0 &&
                    <div>
                        <IconButton style={styles.addButton} onClick={this.handleOpen}>
                            <AddIcon />
                        </IconButton>
                        <Dialog
                            open={this.state.open}
                            onClose={this.handleClose}
                            fullWidth
                            maxWidth="sm">
                            <DialogContent>
                                <DialogTitle>Add a new intent set</DialogTitle>
                                <form>
                                    <TextField
                                        name="title"
                                        style={ {fontSize: '16px'} }
                                        type="text"
                                        label="1. Title"
                                        placeholder="Enter title"
                                        value={this.state.title}
                                        onChange={this.handleChange}
                                        fullWidth
                                    />
                                    <TextField
                                        name="author"
                                        style={ {fontSize: '16px'} }
                                        type="text"
                                        label="2. Author"
                                        placeholder="Enter content author name"
                                        value={this.state.author}
                                        onChange={this.handleChange}
                                        fullWidth
                                    />
                                    <TextField
                                        name="intent"
                                        style={ {fontSize: '16px'} }
                                        type="text"
                                        label="intent_name"
                                        placeholder="inform_XXX"
                                        value={this.state.intent}
                                        onChange={this.handleChange}
                                        fullWidth
                                    />
                                    <p>3. Select sentiment</p>
                                    <Select
                                        value={this.state.program}
                                        onChange={this.handleChange}
                                        name='program'
                                        >
                                        {this.state.contentsLabels.map(entry => {
                                            return (
                                                <MenuItem key={"add_"+entry} value={entry} >
                                                {entry}
                                                </MenuItem>
                                            );
                                        }) }
                                    </Select>
                                    
                                    </form>
                                <DialogActions>
                                    <Button onClick={this.handleClose}>
                                        Cancel
                                    </Button>
                                    <Button onClick={this.handleSubmit}>
                                        Save
                                    </Button>
                                </DialogActions>
                            </DialogContent>
                        </Dialog>
                    </div>
                }
                {this.state.errorMessage !== '' && 
                    <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                }
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={this.handleTabChange}
                    initialselectedindex={0}
                    value={showTab}
                >
                    {contentsLabels.length>0 && 
                        contentsLabels.map( (key) => (
                        <Tab 
                            value={key} 
                            key={key}
                            label={key + " ("+contentsTab[key].length+")"}/>
                        ))
                    }
                </Tabs>
                {this.state.descriptionSentiment !== undefined && showTab!==undefined && showTab!=='' && this.state.descriptionSentiment[showTab] !== undefined
                    &&
                    <h4 style={{color:'green'}}>
                        {this.state.descriptionSentiment[showTab] }
                        {this.context?.roles?.includes('ADMIN') &&
                            <>
                                &nbsp;&nbsp;
                                <Button 
                                    disabled={false}
                                    onClick={() => {
                                        if(this.state.runningAccuracyTest){
                                            this.setState({runningAccuracyTest: false})
                                        }
                                        else{
                                            this.setState({runningAccuracyTest: true}, () => {
                                                this.updateAIAccuracy(this.state.runningAccTestIndex + 1, !this.state.runningAccuracyTest)
                                            })
                                        }
                                    }}
                                >
                                    {this.state.runningAccuracyTest
                                        ?   'Stop Testing'
                                        :   'Check Data Accuracy'
                                    }
                                </Button>
                                &nbsp;&nbsp;
                                <Button 
                                    onClick={() => {
                                        this.setState({moveInaccurateItems: this.state.moveInaccurateItems === true ? false : true});
                                    }}
                                >
                                    {this.state.moveInaccurateItems
                                        ?   'Disable Auto-Move'
                                        :   'Auto-Move Training Data'
                                    }
                                </Button>
                            </>
                        }
                    </h4>
                }
                {contentsLabels.length>0 && contentsLabels.map( (key) => (
                    <div 
                        value={key} 
                        key={key} 
                        hidden={showTab !==key}
                    >
                        <AutoSizer>
                        {({ height, width }) => (
                            <Table
                                width={width}
                                height={700}
                                headerHeight={20}
                                rowHeight={30}
                                rowCount={this.state.contentsTabList[key].length}
                                sort={this._sort}
                                sortBy={this.state.sortBy}
                                sortDirection={this.state.sortDirection}
                                rowGetter={({index}) => { 
                                    if(this.state.classFilter === undefined || this.state.classFilter === ''){
                                        return this.state.contentsTabList[key][index];
                                    }
                                    else{
                                        if(this.state.contentsTabList[key][index].class !== undefined &&
                                            this.state.contentsTabList[key][index].class.includes(this.state.classFilter )){
                                            //console.log(this.state.contentsTabList[key][index])
                                            return this.state.contentsTabList[key][index]
                                        }
                                        else return false
                                    }
                                }}
                                onRowClick={({ event, index, rowData })  => {
                                    this.setState({
                                        currentContentId: this.state.contentsTabList[key][index].uid, 
                                        currentClass: this.state.contentsTabList[key][index].class, 
                                        dialogOpen:true
                                    })}}
                            >
                                <Column label="Active" dataKey="active" width={80} disableSort={false}  />
                                <Column label="Description" dataKey="description" width={350} disableSort={false} />
                                <Column label="# Data" dataKey="examples" width={80} disableSort={false} />
                                <Column label="Clean" dataKey="clean" width={80} disableSort={false} />
                                <Column label="LOW" dataKey="lowItems" width={80} disableSort={false} />
                                <Column label="FAIL" dataKey="badItems" width={80} disableSort={false} />
                                <Column label="AUTO" dataKey="autoItems" width={80} disableSort={false} />
                                {this.props.simpleView!==true
                                    &&
                                    <Column label="# Acc" dataKey="accuracy" width={80} disableSort={false} />
                                }
                                {this.props.simpleView!==true
                                    &&
                                    <Column label="# Date" dataKey="accuracyUpdated" width={140} disableSort={false} />
                                }
                                <Column label="Response" dataKey="responses" width={350} disableSort={false} />
                                {this.props.simpleView!==true
                                    &&
                                    <Column label="Short" dataKey="intentText" width={70} disableSort={false} />
                                }
                                {this.props.simpleView!==true
                                &&
                                    <Column label="Quals" dataKey="qualities" width={120} disableSort={false} />
                                }
                                {this.props.simpleView!==true
                                    &&
                                    <Column label="Class" dataKey="class" width={300} disableSort={false} />
                                }
                                {this.props.simpleView!==true
                                    &&
                                    <Column label="Lookup" dataKey="lookup" width={100} disableSort={false} />
                                }
                                {this.props.simpleView!==true
                                    &&
                                    <Column label="Synonym" dataKey="synonym" width={110} disableSort={false} />
                                }
                            </Table>
                        )}
                        </AutoSizer>
                    </div>
                ))}
                    <QuestionIntentsItemDialog 
                        contentId={this.state.currentContentId}
                        sentiments={this.state.contentsLabels}
                        intent={this.state.currentClass}
                        openDialogQIntents={this.state.dialogOpen|| false}
                        simpleView = {this.props.simpleView || false}
                        moveInaccurateItems = {this.state.moveInaccurateItems || false}
                        closeDialog={(e) => {this.setState({dialogOpen : false})}}
                        runningAccuracyTest={(this.state.runningAccuracyTest && this.state.contentsLabels) ? this.state.runningAccuracyTest : false}
                        handleRunNextAccuracyTest={() => this.updateAIAccuracy(this.state.runningAccTestIndex + 1)}
                        handelStopAccuracyTest = {() => this.setState({runningAccuracyTest: false})}
                        contentsLabels={contentsLabels}
                        classFilter={this.state.classFilter}
                        leadershipCategoriesValues={this.state.leadershipCategoriesValues || []}
                        leadershipCategoriesText={this.state.leadershipCategoriesText || []}
                        leadershipQualitiesValues={this.state.leadershipQualitiesValues || []}
                        leadershipQualitiesText={this.state.leadershipQualitiesText || []}
                    />
                </div>
        );
    }

    confirmDialog(msg) {
        try{
            /// for test mode activate below
            /**
            return new Promise( (resolve, reject) => {
                let confirmed = window.confirm(msg);
                console.log('confirmed', confirmed)
                if(!confirmed){
                    this.setState({runningAccuracyTest: false, dialogOpen: false});
                }
                return (confirmed) ? resolve(true) : reject(false);
            });
             */
            return setTimeout(() => { if(this._isMounted){return true}}, 800)
        } catch (error){
            this.setState({runningAccuracyTest: false, dialogOpen: false});
            return false;
        }
    }

    updateAIAccuracy = async (index=0, start=false) => {
        if(this.state.runningAccuracyTest){
            this.setState({runningAccTestIndex: index}, async () => {
                const { showTab, contentsTabList, runningAccuracyTest } = this.state;
                var nowTestDate = new Date();
                var lastTestDate = new Date();
                //console.log('acc', index, this.state)
                var checkNext = false;
                if(index === 0 && this.state.runningAccuracyTest){
                    this.setState({runningAccuracyTest: false})
                    //console.log('Stop Testing')
                }
                else if(index === 0 || start){
                    this.setState({runningAccuracyTest: true})
                    console.log('Start Testing')
                    checkNext = true;
                }
                else if(runningAccuracyTest !== false){
                    checkNext = true;
                }
                if(checkNext){
                    console.log('checknext', checkNext, index)
                    var intentsList = contentsTabList[showTab]
                    var length = intentsList.length;
                    if (index < length) {
                        var itemData = intentsList[index];
                        //console.log('intentsList', index, length, itemData);
                        if(itemData?.accuracyDate?.seconds > 0){
                            lastTestDate = new Date(itemData.accuracyDate.seconds * 1000 + 10 * 24*60*60*1000);
                            console.log('date made', itemData, lastTestDate);
                        }
                        if(nowTestDate >= lastTestDate){
                            if(itemData.clean === 'YES'){
                                console.log('already clean')
                                this.updateAIAccuracy(index+1);
                            }
                            else if(itemData.active === 'Yes' && itemData.clean !== 'YES'){
                                console.log('itemData unclean', itemData, lastTestDate, 'Next intent: '+itemData.class + ' - ' + itemData.description);
                                const confirmation = await this.confirmDialog('Next intent: '+itemData.class + ' - ' + itemData.description);
                                if(confirmation){
                                    this.setState({currentContentId: itemData.uid, dialogOpen: true}, () => this.setState({runningAccuracyTest: true}));
                                }
                                else{
                                    console.log('stop everything');
                                    this.setState({runningAccuracyTest: false, dialogOpen: false});
                                }

                            }
                            else {
                                console.log('skip inactive')
                                this.updateAIAccuracy(index+1);
                            }
                        }
                        else {
                            console.log('date passed')
                            this.updateAIAccuracy(index+1);
                        }
                    }
                    else{
                        console.log('index too much', index, length)
                        this.setState({runningAccuracyTest: false})
                    }
                }
            });
        }
    }
}


class QuestionIntentsItemBaseDialog extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        var errorMessage = '';
        var contentsLabels = ['total'];
        this.state = {
            loading: false,
            content: undefined,
            cid:'',
            contentsLabels: contentsLabels,
            errorMessage: errorMessage,
            descriptionSentiment: DescriptionSentiment
        };
    }
  
    componentDidMount() {
        this._isMounted = true;
        //    this.getleadershipCategoriesArray();
        //    this.getleadershipQualitiesArray();
        //    this.getBotUtterArray();
    }
  
    componentDidUpdate(prevProps) {
        //console.log('myid',this.props)
        if (this.props.contentId && this.state.cid !== this.props.contentId) {
            console.log('myidopen', this.props.contentId)
            if(this.unsubscribe !== undefined) this.unsubscribe();
            this.setState({ loading: true, cid: this.props.contentId });
            this.unsubscribe = this.props.firebase
                .agentIntent(this.props.contentId)
                .onSnapshot(snapshot => {
                    //console.log('data',snapshot.data())
                    if(snapshot && this._isMounted){
                        this.setState({
                            content: {...snapshot.data(), id: this.props.contentId},
                            loading: false,
                        });
                        console.log('updated loading new data', snapshot.data().description)
                    }
                });
            this.loadTrainingData(this.props.intent);
        }
        else if (this.props.searchClass && this.state.searchClass !== this.props.searchClass) {
            console.log('myidopen 2', this.props.searchClass,  this.props.searchSentiment)
            if(this.unsubscribe !== undefined) this.unsubscribe();
            this.setState({ loading: true, cid: undefined, searchClass : this.props.searchClass, searchSentiment: this.props.searchSentiment });
            var intent = this.props.searchClass;
            var tense = 'default'
            if(intent?.includes("_past")){
                intent = intent?.replace("_past", "") || "";
                tense = "past";
            }
            else if(intent?.includes("_future")){
                intent = intent?.replace("_future", "") || "";
                tense = "future";
            }
            this.unsubscribe = this.props.firebase
                .agentIntentClasses()
                .where('sentiment', '==',  this.props.searchSentiment)
                .where('intent', '==', intent)
                .where('tenses', 'array-contains', tense)
                .onSnapshot(snapshot => {
                    var content = undefined;
                    var intentId = undefined;
                    snapshot.forEach((doc) =>{
                        content = doc.data();
                        intentId = doc.id;
                    })
                    //console.log('data',snapshot.data())
                    if(snapshot && this._isMounted){
                        this.setState({
                            cid : intentId,
                            content: content,
                            loading: false,
                        });
                    }
                });
            this.loadTrainingData(intent);
        }
        //console.log('prep', this.props, this.state)
        if(this.props.runningAccuracyTest && this.state['processingAccuracyTest_'+this.state.cid] !== true && this.state.cid === this.props.contentId && this.props.openDialogQIntents){
            console.log('handler for openung accuracy Test')
            this.startAccuracyCheck();
            //console.log('handler for openung accuracy ', this.state)
        }
        else if(this.props.runningAccuracyTest && this.state['processingAccuracyTest_'+this.state.cid] === true && this.state.cid === this.props.contentId && !this.props.openDialogQIntents){
            console.log('opened already')
            setTimeout(() => {
                if(this._isMounted){
                    //this.startAccuracyCheck('finish');
                }
            }, 1400);
        }
    }

    loadTrainingData(intent) {
        var trainingDataItems = [];
        console.log('loadtrainidata', intent)
        if(intent !== undefined){
            this.props.firebase
            .trainingData()
            .where('intent', '==', intent)
            .orderBy('accuracy', 'desc')
            .get().then(snapshot => {
                trainingDataItems = [];
                snapshot.forEach(doc => {
                    trainingDataItems.push(doc.data().text)
                })
                this.setState({trainingDataItems})
                //console.log('data', this.props.intent, trainingDataItems)
            });
        }
        else{
            this.setState({trainingDataItems});
        }
    }

    startAccuracyCheck(stage='start'){
        if(this.props.runningAccuracyTest){
            var content = this.state.content;
            console.log('request test', this.state.cid, content)
            if(content !== undefined && content !== null && content?.id === this.state.cid){
                if(stage === 'start'){
                    stage = 'examples';
                }
                else if(stage === 'examples'){
                    //this.setState({['externalOpen_'+stage+this.state.cid]: false});
                    stage = 'examplesPast';
                }
                else if(stage === 'examplesPast'){
                    //this.setState({['externalOpen_'+stage+this.state.cid]: false});
                    stage = 'examplesFuture';
                }
                else if(stage === 'examplesFuture'){
                    stage = 'finish';
                    //console.log('starting finish round' + stage)
                }
                else{
                    stage = 'finish';
                    //console.log('starting finish round' + stage)
                }
                //// start the new one
                if(stage === 'finish'){
                    if(this.props.closeDialog !== undefined){
                        this.props.closeDialog();
                    }
                    if(this.props.handleRunNextAccuracyTest !== undefined){
                        setTimeout(() => {
                            if(this._isMounted){
                                this.props.handleRunNextAccuracyTest();
                            }
                        }, 1400);
                        console.log('handle next')
                    }
                }
                else if(content?.[stage]?.length > 0){
                    if(this.state.cid !== undefined){
                        console.log('starting extra ' + stage, content)
                        this.setState({['externalOpen_'+stage+this.state.cid] : true, ['processingAccuracyTest_'+this.state.cid]: true});
                    }
                }
                else if(stage !== 'finish'){
                    console.log('finished the item')
                    this.startAccuracyCheck(stage);
                }
            }
            else{
                console.log('loading content')
                setTimeout(() => {
                    if(this._isMounted){
                        this.startAccuracyCheck(stage);
                    }
                }, 1400);
            }
        }
    }

    createPrompt(content) {
        var example = content.intentTitle || "";
        var prompt = undefined;
        if(example?.length > 2){
            var addressI = false
            //var addressYou = false
            prompt = "Give me 20 examples that";
            if(content?.sentiment?.includes('inform_negative_long')){
                prompt = prompt + " describe a failure using the words "
                addressI=true
            }
            else if (content?.sentiment?.includes('inform_neutral_long')){
                prompt = prompt + " give a definition of a"
            }
            else if (content?.sentiment?.includes('inform_intention_long')){
                prompt = prompt + " give a commitment using the word"
                addressI=true
            }
            else if (content?.sentiment?.includes('inform_positive_long')){
                prompt = prompt + " articulate and accomplishment using the word"
                addressI=true
            }
            else if (content?.sentiment?.includes('question')){
                prompt = prompt + " ask a question about the word"
            }
            else{
                prompt = prompt + " include"
            }
            prompt = prompt + " '"+ content.intentTitle + " or " + (content?.intentText?.replace(".","") || "") + "'"
            prompt = prompt + " and keep them more general. Do not connect sentences to each other. Do not use numeration and start each line with a #."
            if(addressI){
                prompt = prompt + " Address me as I."
            }
            prompt = prompt + " And then make 20 more but replace the words" + (" '") + content.intentTitle + " or " + (content?.intentText?.replace(".","") || "") + "'"
        }
        return prompt
    }

    componentWillUnmount() {
        this.setState({content: undefined});
        if (this.unscrubscribe !== undefined ) this.unsubscribe();
    }
  
    render() {
        const { content, loading } = this.state;
        //console.log('Questionintents my context', this.state, this.props)
        return (
            <div>
                <Dialog
                    open={this.props.openDialogQIntents}
                    onClose={this.props.closeDialog}
                    fullWidth={true}
                    >
                    <DialogTitle >
                        <IconButton onClick={this.props.closeDialog}>
                            (X)
                        </IconButton>
                        {content!==undefined && content!==null
                            ? "Modify Intent Data " +content.description
                            : "Modify Intent Data"
                        }
                        {this.state.runningAccuracyTest &&
                            <IconButton style={{color: 'red'}} onClick={() => this.props.handelStopAccuracyTest()}>
                                (Cancel)
                            </IconButton>
                        }
                    </DialogTitle>
                    <DialogContent >
                        <div>
                        <h5 style={styles.subtitle}>Intent Response Class ({this.props.contentId})</h5>
                        {loading && 
                            <LoadingProgress 
                                defaultSet={this.props.defaultSet}
                                type={'LoadingPageAnimated'} 
                                loadingText={"many, many, data"} 
                                firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                                allowRefresh={true}
                            />
                        }
                
                        {this.state.errorMessage !== '' && 
                            <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                        }
                        {content && (
                            <div
                                key={this.state.cid+''}
                            >
                                <SettingContentActive 
                                    dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)}
                                    title={"Active"} 
                                    content={content}
                                    access = {this.props.simpleView? false: true}
                                    params={['active', 'clean']}
                                />
                                {this.state.descriptionSentiment !== undefined && content.sentiment!==undefined && this.state.descriptionSentiment[content.sentiment] !== undefined
                                    &&
                                    <p style={{color:'red'}}>
                                        {this.state.descriptionSentiment[content.sentiment] }
                                    </p>
                                    
                                }
                                <SettingContentBase 
                                    dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)}
                                    title={"Description (make unique) and help guide (tips and rules)"} 
                                    content={content}
                                    access = {this.props.simpleView? false: true}
                                    params={['description','guide']}
                                />
                                <p>
                                    The responses are the direct replies that confirm what was understood or add additional information or explanation to what was understood.
                                    This are NOT questions. Multiple replies can be added to increase variety.
                                </p>
                                <SettingContentArray
                                    dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)}
                                    title={"Responses of bot (Min. 1 default response)"} 
                                    addNewButton={true}
                                    addMoveResponses={true}
                                    addMoveIntent={content.intent || undefined}
                                    content={content} 
                                    params={['responses']}
                                />
                                {(this.context.roles?.includes("ADMIN") || this.context.roles?.includes("TEAM")) &&
                                    <> 
                                    <SettingContentArrayCheckbox
                                        dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)}
                                        title={"NEW! Response utterances from databases"}
                                        content={content} 
                                        params={['responseUtterances']}
                                        selectOptions={responseIntentOptions}
                                        selectOptionsText={responseIntentOptionsText}
                                    />
                                    {content?.responseUtterances !== undefined && content?.responseUtterances?.length > 0 &&
                                        <Button style={{margin: 20}} onClick={ () => this.setState({openResponses : true})}>
                                            Add dynamic responses
                                        </Button>
                                    }
                                    </>
                                }
                                {/**
                                <SettingContentArray
                                    dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)}
                                    title={"Responses past"} 
                                    content={content} 
                                    params={['responsesPast']}
                                />
                                <SettingContentArray
                                    dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)}
                                    title={"Responses future"} 
                                    content={content} 
                                    params={['responsesFuture']}
                                />
                                 */}
                                <p>
                                    Training data examples are the responses given by users of the chatbot. The do not need correct grammar.
                                    Based on traning data the AI is able to improve language understanding.
                                </p>
                                <IntentArrayManagement
                                    key={this.state.cid}
                                    dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)}
                                    title={"Training data examples: "  + (content.accuracy !== undefined ? (content.accuracy.examples *100 + '%') : '')} 
                                    content={content} 
                                    params={['examples']}
                                    addNewButton={true}
                                    sorting={true}
                                    showShort={true}
                                    addOnly={this.props.simpleView || false}
                                    noLabel={true}
                                    searchIntents={true}
                                    externalOpen={this.state['externalOpen_examples'+this.state.cid] || false}
                                    externalCloseHandler={() => this.setState({['externalOpen_examples'+this.state.cid]: false})}
                                    intentId = {this.state.cid}
                                    intentUser= {{intent: content.intent, sentiment:content.sentiment}}
                                    classFilter={this.props.classFilter}
                                    exampleResults = {this.state['exampleResults_'+this.state.cid] || undefined }
                                    updateAccuracy = { (accuracy) => {
                                        var clean = false;
                                        if(accuracy > 0.7 && (content.examplesFalse===undefined || content.examplesFalse.length===0) && (content.examplesBad===undefined || content.examplesBad.length===0)){
                                            clean = true;
                                        }
                                        this.startAccuracyCheck('examples');
                                        this.props.firebase.agentIntent(this.state.cid).update({"accuracy.examples" : accuracy, "accuracy.updatedAt" : this.props.firebase.fieldValue.serverTimestamp(), clean: clean})}
                                    }
                                    updateExampleResults = { (exampleResults) => {this.setState({['exampleResults_'+this.state.cid]: exampleResults}) }}
                                    updateMoveFalseData = { (exampleFalse, intentDate) => {
                                        //console.log('false try', this.state)
                                        if(this.props.moveInaccurateItems){
                                            //console.log('false move')
                                            this.props.firebase.agentIntent(this.state.cid).update({examples: this.props.firebase.fieldValue.arrayRemove(exampleFalse), examplesLow: this.props.firebase.fieldValue.arrayUnion(exampleFalse), clean: false})
                                            var tempResults = {};
                                            if(this.state['exampleResultsFalse_'+this.state.cid] !== undefined){
                                                tempResults = this.state['exampleResultsFalse_'+this.state.cid];
                                            }
                                            tempResults[exampleFalse] = intentDate;
                                            this.setState({['exampleResultsFalse_'+this.state.cid]: tempResults})
                                        }
                                    }}
                                    updateMoveLowAccuracy = { (exampleLow, intentDate) => {
                                        if(this.props.moveInaccurateItems){
                                            this.props.firebase.agentIntent(this.state.cid).update({examples: this.props.firebase.fieldValue.arrayRemove(exampleLow), examplesLow: this.props.firebase.fieldValue.arrayUnion(exampleLow), clean: false});
                                            var tempResults = {};
                                            if(this.state['exampleResultsLow_'+this.state.cid] !== undefined){
                                                tempResults = this.state['exampleResultsLow_'+this.state.cid];
                                            }
                                            tempResults[exampleLow] = intentDate;
                                            this.setState({['exampleResultsLow_'+this.state.cid]: tempResults})
                                        }
                                    }}
                                    promptSupport={ this.createPrompt(content) }
                                />
                                <IntentArrayManagement
                                    key={this.state.cid+'past'}
                                    dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)}
                                    title={"Past tense training: " + (content.accuracy !== undefined ? (content.accuracy.examplesPast *100 + '%') : '')} 
                                    content={content} 
                                    params={['examplesPast']}
                                    addNewButton={true}
                                    sorting={true}
                                    showShort={true}
                                    addOnly={this.props.simpleView || false}
                                    noLabel={true}
                                    externalOpen={this.state['externalOpen_examplesPast'+this.state.cid] || false}
                                    externalCloseHandler={() => this.setState({['externalOpen_examplesPast'+this.state.cid]: false})}
                                    searchIntents={true}
                                    intentId = {this.state.cid}
                                    intentUser= {{intent: content.intent, sentiment:content.sentiment}}
                                    classFilter={this.props.classFilter}
                                    updateAccuracy = { (accuracy) => {
                                        var clean = false;
                                        if(accuracy > 0.7 && (content.examplesFalse===undefined || content.examplesFalse.length===0) && (content.examplesBad===undefined || content.examplesBad.length===0)){
                                            clean = true;
                                        }
                                        this.startAccuracyCheck('examplesPast');
                                        this.props.firebase.agentIntent(this.state.cid).update({"accuracy.examples" : accuracy, "accuracy.updatedAt" : this.props.firebase.fieldValue.serverTimestamp(), clean: clean})}
                                    }
                                    exampleResults = {this.state['exampleResultsPast_'+this.state.cid] || undefined }
                                    updateExampleResults = { (exampleResults) => {this.setState({['exampleResultsPast_'+this.state.cid]: exampleResults}) }}
                                    updateMoveFalseData = { (exampleFalse, intentDate) => {
                                        if(this.props.moveInaccurateItems){
                                            this.props.firebase.agentIntent(this.state.cid).update({examplesPast: this.props.firebase.fieldValue.arrayRemove(exampleFalse), examplesLow: this.props.firebase.fieldValue.arrayUnion(exampleFalse), clean: false})
                                            var tempResults = {};
                                            if(this.state['exampleResultsFalse_'+this.state.cid] !== undefined){
                                                tempResults = this.state['exampleResultsFalse_'+this.state.cid];
                                            }
                                            tempResults[exampleFalse] = intentDate;
                                            this.setState({['exampleResultsFalse_'+this.state.cid]: tempResults})
                                        }
                                    }}
                                    updateMoveLowAccuracy = { (exampleLow, intentDate) => {
                                        if(this.props.moveInaccurateItems){
                                            this.props.firebase.agentIntent(this.state.cid).update({examplesPast: this.props.firebase.fieldValue.arrayRemove(exampleLow), examplesLow: this.props.firebase.fieldValue.arrayUnion(exampleLow), clean: false});
                                            var tempResults = {};
                                            if(this.state['exampleResultsLow_'+this.state.cid] !== undefined){
                                                tempResults = this.state['exampleResultsLow_'+this.state.cid];
                                            }
                                            tempResults[exampleLow] = intentDate;
                                            this.setState({['exampleResultsLow_'+this.state.cid]: tempResults})
                                        }
                                    }}
                                />
                                <IntentArrayManagement
                                    key={this.state.cid+'future'}
                                    dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)} 
                                    title={"Future tense training: " + (content.accuracy !== undefined ? (content.accuracy.examplesFuture *100 + '%') : '')} 
                                    content={content} 
                                    params={['examplesFuture']}
                                    addNewButton={true}
                                    sorting={true}
                                    addOnly={this.props.simpleView || false}
                                    showShort={true}
                                    noLabel={true}
                                    externalOpen={this.state['externalOpen_examplesFuture'+this.state.cid] || false}
                                    externalCloseHandler={() => this.setState({['externalOpen_examplesFuture'+this.state.cid]: false})}
                                    searchIntents={true}
                                    intentId = {this.state.cid}
                                    intentUser= {{intent: content.intent, sentiment:content.sentiment}}
                                    classFilter={this.props.classFilter}
                                    updateAccuracy = { (accuracy) => {
                                        var clean = false;
                                        if(accuracy > 0.7 && (content.examplesFalse===undefined || content.examplesFalse.length===0) && (content.examplesBad===undefined || content.examplesBad.length===0)){
                                            clean = true;
                                        }
                                        this.startAccuracyCheck('examplesFuture');
                                        this.props.firebase.agentIntent(this.state.cid).update({"accuracy.examples" : accuracy, "accuracy.updatedAt" : this.props.firebase.fieldValue.serverTimestamp(), clean: clean})}
                                    }
                                    updateExampleResults = { (exampleResults) => {this.setState({['exampleResultsFuture_'+this.state.cid]: exampleResults}) }}
                                    exampleResults = {this.state['exampleResultsFuture_'+this.state.cid] || undefined }
                                    updateMoveFalseData = { (exampleFalse, intentDate) => {
                                        if(this.props.moveInaccurateItems){
                                            this.props.firebase.agentIntent(this.state.cid).update({examplesFuture: this.props.firebase.fieldValue.arrayRemove(exampleFalse), examplesLow: this.props.firebase.fieldValue.arrayUnion(exampleFalse), clean: false})
                                            var tempResults = {};
                                            if(this.state['exampleResultsFalse_'+this.state.cid] !== undefined){
                                                tempResults = this.state['exampleResultsFalse_'+this.state.cid];
                                            }
                                            tempResults[exampleFalse] = intentDate;
                                            this.setState({['exampleResultsFalse_'+this.state.cid]: tempResults})
                                        }
                                    }}
                                    updateMoveLowAccuracy = { (exampleLow, intentDate) => {
                                        if(this.props.moveInaccurateItems){
                                            this.props.firebase.agentIntent(this.state.cid).update({examplesFuture: this.props.firebase.fieldValue.arrayRemove(exampleLow), examplesLow: this.props.firebase.fieldValue.arrayUnion(exampleLow), clean: false});
                                            var tempResults = {};
                                            if(this.state['exampleResultsLow_'+this.state.cid] !== undefined){
                                                tempResults = this.state['exampleResultsLow_'+this.state.cid];
                                            }
                                            tempResults[exampleLow] = intentDate;
                                            this.setState({['exampleResultsLow_'+this.state.cid]: tempResults})
                                        }
                                    }}
                                />
                                {content.examplesAuto?.length>0
                                &&
                                    <IntentArrayManagement
                                        key={this.state.cid+'examplesAuto'}
                                        dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)} 
                                        title={"Auto training examples:"} 
                                        content={content} 
                                        params={['examplesAuto']}
                                        addNewButton={true}
                                        sorting={true}
                                        addOnly={false}
                                        showShort={true}
                                        noLabel={true}
                                        searchIntents={true}
                                        intentId = {this.state.cid}
                                        moveItems = {(items) => this.props.firebase.agentIntent(this.state.cid).update({examples: this.props.firebase.fieldValue.arrayUnion(...items), examplesLow: []})}
                                        exampleResults = {this.state['exampleResultsAuto_'+this.state.cid] || undefined }
                                        updateExampleResults = { (exampleResults) => {this.setState({['exampleResultsAuto_'+this.state.cid]: exampleResults}) }}
                                        intentUser= {{intent: content.intent, sentiment:content.sentiment}}
                                        classFilter={this.props.classFilter}
                                        updateMoveFalseData = { (exampleFalse, intentDate) => {
                                            if(this.props.moveInaccurateItems){
                                                this.props.firebase.agentIntent(this.state.cid).update({examplesAuto: this.props.firebase.fieldValue.arrayRemove(exampleFalse), examplesLow: this.props.firebase.fieldValue.arrayUnion(exampleFalse), clean: false})
                                                var tempResults = {};
                                                if(this.state['exampleResultsFalse_'+this.state.cid] !== undefined){
                                                    tempResults = this.state['exampleResultsFalse_'+this.state.cid];
                                                }
                                                tempResults[exampleFalse] = intentDate;
                                                this.setState({['exampleResultsFalse_'+this.state.cid]: tempResults}) 
                                            }
                                        }}
                                    />
                                }
                                {content.examplesLow?.length>0
                                &&
                                    <IntentArrayManagement
                                        key={this.state.cid+'low'}
                                        dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)} 
                                        title={"Low accuracy training examples:"} 
                                        content={content} 
                                        params={['examplesLow']}
                                        addNewButton={true}
                                        sorting={true}
                                        addOnly={false}
                                        showShort={true}
                                        noLabel={true}
                                        searchIntents={true}
                                        intentId = {this.state.cid}
                                        moveItems = {(items) => this.props.firebase.agentIntent(this.state.cid).update({examples: this.props.firebase.fieldValue.arrayUnion(...items), examplesLow: []})}
                                        exampleResults = {this.state['exampleResultsLow_'+this.state.cid] || undefined }
                                        updateExampleResults = { (exampleResults) => {this.setState({['exampleResultsLow_'+this.state.cid]: exampleResults}) }}
                                        intentUser= {{intent: content.intent, sentiment:content.sentiment}}
                                        classFilter={this.props.classFilter}
                                        updateMoveFalseData = { (exampleFalse, intentDate) => {
                                            if(false && this.props.moveInaccurateItems){
                                                this.props.firebase.agentIntent(this.state.cid).update({examplesLow: this.props.firebase.fieldValue.arrayRemove(exampleFalse), examplesFalse: this.props.firebase.fieldValue.arrayUnion(exampleFalse), clean: false})
                                                var tempResults = {};
                                                if(this.state['exampleResultsFalse_'+this.state.cid] !== undefined){
                                                    tempResults = this.state['exampleResultsFalse_'+this.state.cid];
                                                }
                                                tempResults[exampleFalse] = intentDate;
                                                this.setState({['exampleResultsFalse_'+this.state.cid]: tempResults}) 
                                            }
                                        }}
                                    />
                                }
                                {content.examplesFalse?.length>0
                                &&
                                    <IntentArrayManagement
                                        key={this.state.cid+'false'}
                                        dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)} 
                                        title={"False training examples:"} 
                                        content={content} 
                                        params={['examplesFalse']}
                                        addNewButton={true}
                                        sorting={true}
                                        addOnly={false}
                                        showShort={true}
                                        noLabel={true}
                                        searchIntents={true}
                                        intentId = {this.state.cid}
                                        moveItems = {(items) => this.props.firebase.agentIntent(this.state.cid).update({examples: this.props.firebase.fieldValue.arrayUnion(...items), examplesFalse: []})}
                                        exampleResults = {this.state['exampleResultsFalse_'+this.state.cid] || undefined }
                                        updateExampleResults = { (exampleResults) => {this.setState({['exampleResultsFalse_'+this.state.cid]: exampleResults}) }}
                                        intentUser= {{intent: content.intent, sentiment:content.sentiment}}
                                        classFilter={this.props.classFilter}
                                    />
                                }
                                {(this.props.intent !== undefined || this.props.searchClass !== undefined) && this.state.trainingDataItems?.length > 0
                                &&
                                    <IntentArrayManagement
                                        key={this.state.cid+'training'}
                                        //dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)} 
                                        title={"Open Training Data examples:"} 
                                        content={{trainingDataItems: this.state.trainingDataItems}} 
                                        params={['trainingDataItems']}
                                        addNewButton={false}
                                        sorting={true}
                                        addOnly={false}
                                        showShort={true}
                                        noLabel={true}
                                        searchIntents={true}
                                        intentId = {this.state.cid}
                                        cleanItem = {(text) => {
                                            var tempListItems = this.state.trainingDataItems || [];
                                            var newarr = tempListItems.filter(a => a !== text)
                                            console.log('remove texts from list', text, newarr)
                                            this.setState({trainingDataItems: newarr})
                                        }}
                                        exampleResults = {this.state['exampleResultsTrain_'+this.state.cid] || undefined }
                                        updateExampleResults = { (exampleResults) => {this.setState({['exampleResultsTrain_'+this.state.cid]: exampleResults}) }}
                                        intentUser= {{intent: content.intent, sentiment:content.sentiment}}
                                        classFilter={this.props.classFilter}
                                    />

                                }
                                
                                {this.props.simpleView!==true
                                &&
                                    <>
                                        <SettingContentArraySelect
                                            dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)}
                                            title={"general, past, future tense"} 
                                            content={content} 
                                            params={['tenses']}
                                            selectOptions={['default', 'past', 'future']}
                                            selectOptionsText={['default', 'past', 'future']}
                                            />
                                        <SettingContentBase 
                                            dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)}
                                            title={"Intent without tense"} 
                                            content={content} 
                                            params={['intent']}
                                            updateCopyTo={'class'}
                                        />
                                        <SettingContentSelect 
                                            dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)}
                                            title={"Sentiment, Response Selector"} 
                                            content={content} 
                                            params={['sentiment']}
                                            selectOptions={this.props.sentiments}
                                            />
                                        <SettingContentBase 
                                            dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)}
                                            title={"Father Moment Text and one-word Title"} 
                                            content={content} 
                                            params={['intentText', 'intentTitle']}
                                            />
                                        <SettingContentSelect 
                                            dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)}
                                            title={"Mother Program"} 
                                            content={content}
                                            emptyOption={true}
                                            params={['category']}
                                            selectOptions={this.props.leadershipCategoriesValues || []}
                                            selectOptionsText={this.props.leadershipCategoriesText || []}
                                            />
                                        <SettingContentArraySelect 
                                            dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)}
                                            title={"Sister Modules"} 
                                            content={content} 
                                            params={['qualities']}
                                            selectOptions={this.props.leadershipQualitiesValues || []}
                                            selectOptionsText={this.props.leadershipQualitiesText || []}
                                            />
                                        {/***
                                        <SettingContentSelect 
                                            dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)}
                                            title={"Class - intent+tense"} 
                                            content={content} 
                                            params={['class']}
                                            selectOptions={[content.intent, content.intent+'_past', content.intent+"_future"]}
                                        />
                                         */}
                                    </>
                                }
                                {false && this.props.simpleView!==true
                                &&
                                    <Button onClick={ () => this.setState({openContent : true})}>
                                        Assign content
                                    </Button>
                                }

                                &nbsp;
                                <Button style={{margin: 20}} onClick={ () => this.setState({openUtterances : true})}>
                                    Add follow-up questions
                                </Button>
                                &nbsp;
                                <Button style={{margin: 20}} onClick={ () => this.setState({openTrainingData : true})}>
                                    Check training data
                                </Button>
                                &nbsp;
                                <p style={{color: 'red'}}>
                                    {this.state.errorMessage || ""}
                                </p>
                                {content.intentText?.length>1 && content.intentTitle?.length>1
                                    &&
                                    <>
                                        <Button style={{margin: 20}} onClick={ () => this.copyIntentText()}>
                                            Copy IntentText Blank
                                        </Button>
                                         &nbsp;
                                        <Button style={{margin: 20}} onClick={ () => this.copyIntentText(true)}>
                                            Copy IntentText Overwrite
                                        </Button>
                                    </>
                                }
                                {this.props.simpleView!==true
                                &&
                                    <>
                                        <SettingContentActive 
                                            dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)}
                                            title={"Activate lookup, synonym (just 1)"} 
                                            content={content} 
                                            params={['lookup', 'synonymActive']}
                                        />
                                        <SettingContentBase 
                                            dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)}
                                            title={"1 word entity name"} 
                                            content={content} 
                                            params={['entity', 'synonym']}
                                        />
                                        <SettingContentArray
                                            dbUpdateLink={this.props.firebase.agentIntent(this.state.cid)}
                                            title={"author"} 
                                            content={content} 
                                            params={['author']}
                                        />
                                    </>
                                }
                            </div>
                        )}
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.openUtterances || this.state.openContent || this.state.openResponses || this.state.openTrainingData || false}
                    onClose={ () =>  this.setState({openUtterances : false, openContent : false, openResponses: false, openTrainingData: false})}
                    fullScreen={true}
                    >
                    <DialogTitle >
                        <IconButton onClick={() =>  this.setState({openUtterances : false, openContent: false, openResponses: false, openTrainingData: false})}>
                            (X)
                        </IconButton>
                        Add follow up questions / content
                        {content !== undefined && content !== null 
                            ?
                                ' > ' + content.intent + ' > ' + (content.responses!== undefined ? content.responses[0] : '')
                            :
                                ''
                        }
                        {content?.examples?.length > 0 &&
                            <span style={{color: 'red'}}>
                                <br/>
                                {content.examples.toString().substring(0, 200)} ...
                            </span>
                        }
                    </DialogTitle>
                    <DialogContent >
                        {this.state.openUtterances && content !== undefined  && content !== null 
                            &&
                            <ManageQuestions 
                                searchByIntent = {content.intent}
                                intentSamples = {content.examples}
                                simpleView = {this.props.simpleView || false}
                                leadership = {content.qualities || undefined}
                                category = {content.category || undefined}
                                leadershipCategories={this.props.leadershipCategories || []}
                                leadershipQualities={this.props.leadershipQualities || []}
                            />
                        }
                        {this.state.openTrainingData && content !== undefined  && content !== null 
                            &&
                            <AdminTrainingPage 
                                moveIntent = {content.intent}
                                moveSentiment = {content.sentiment}
                                leadership = {content.qualities || undefined}
                                category = {content.category || undefined}
                                leadershipCategories={this.props.leadershipCategories || []}
                                leadershipQualities={this.props.leadershipQualities || []}
                            />
                        }
                        {this.state.openContent && content !== undefined  && content !== null 
                            &&
                            <ManageContent
                                searchByIntent = {content.intent}
                                simpleView = {this.props.simpleView || false}
                                leadershipQualities={this.props.leadershipQualities || []}
                            />
                        }
                        {this.state.openResponses && content !== undefined  && content !== null 
                            &&
                            <ManageQuestions
                                searchUtterancesFilter = {responseIntentOptions}
                                searchByIntent = {content.intent}
                                simpleView = {this.props.simpleView || false}
                                leadership = {content.qualities || undefined}
                                category = {content.category || undefined}
                                leadershipCategories={this.props.leadershipCategories || []}
                                leadershipQualities={this.props.leadershipQualities || []}
                            />
                        }
                    </DialogContent>
                </Dialog>
            </div>
        );
    }

    copyIntentText(overwrite = false) {
        const { content } = this.state;
        this.setState({errorMessage: 'Start copying Intent Text and Program...'})
        const intentText = content.intentText;
        const intentTitle = content.intentTitle;
        const category = content.category || this.props.firebase.fieldValue.delete();
        const qualities = content.qualities || this.props.firebase.fieldValue.delete();
        var intentTopic = ''+content.intent;
        var spaces = intentTopic.split('_');
        if(spaces.length >= 2){
            intentTopic = '' + (intentTopic?.replace('inform_', '') || "");
            intentTopic = '' + (intentTopic.replace('question_', '') || "");
            intentTopic = intentTopic.substring(intentTopic.indexOf('_') + 1);
            var array = [
                'inform_sentiment_'+intentTopic,
                'inform_accomplishment_'+intentTopic,
                'inform_failurereason_'+intentTopic,
                'question_conclusions_'+intentTopic,
                'inform_conclusions_'+intentTopic,
                'inform_indicator_'+intentTopic,
                'inform_intention_'+intentTopic,
                'inform_activity_'+intentTopic,
                'inform_challenge_'+intentTopic,
                'request_consulting_'+intentTopic
            ]
            const index = array.indexOf(content.intent);
            if (index > -1) { // only splice array when item is found
                array.splice(index, 1); // 2nd parameter means remove one item only
            }
            //console.log('intenTopic', intentTopic, array, intentText, category, qualities)
            var counter = 0;
            array.forEach( item => {
                this.props.firebase
                .agentIntentClasses()
                .where('intent', '==', item)
                .where('active', '==', true)
                .get().then(snapshot => {
                    if(snapshot.empty){
                        console.log('no examples')
                        return false;
                    }
                    else{
                        snapshot.forEach((doc) =>{
                            const setData = doc.data();
                            var newUpdateSet = {}
                            var checkUpdateNeed = false;
                            if(setData.intentText === undefined || overwrite){
                                checkUpdateNeed = true;
                                newUpdateSet['intentText'] = intentText;
                            }
                            if(setData.intentTitle === undefined || overwrite){
                                checkUpdateNeed = true;
                                newUpdateSet['intentTitle'] = intentTitle;
                            }
                            if(category !== '' && (setData.category === undefined || overwrite)){
                                checkUpdateNeed = true;
                                newUpdateSet['category'] = category;
                            }
                            if((setData.qualities === undefined || overwrite)){
                                checkUpdateNeed = true;
                                newUpdateSet['qualities'] = qualities;
                            }
                            if(checkUpdateNeed){
                                //console.log(newUpdateSet, doc.data())
                                this.props.firebase.agentIntent(doc.id).update(newUpdateSet);
                                counter += 1;
                            }
                        })
                        this.setState({errorMessage: 'Updated '+ counter +' intents.'})
                    }
                })
            })
        }
    }
}

AdminQuestionIntentsPage.propTypes = {
    classes: PropTypes.object.isRequired
}

export const QuestionIntentsItemDialog = withFirebase(QuestionIntentsItemBaseDialog);

const condition = authUser =>
    authUser && authUser.roles && (authUser.roles?.includes(ROLES.ADMIN) || authUser.roles?.includes(ROLES.TEAM) || authUser.adminIntents !== undefined);

export default compose(
    withStyles(styles),
    // withEmailVerification,
    withAuthorization(condition),
)(AdminQuestionIntentsPage);

